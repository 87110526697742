import { JemConfiguration } from "../../JemConfiguration";
import { getRequest, getValidUrl, IUserProviderState } from "../../Shared/utilities/RequestUtilities";
import { FiscalPeriodStringTransformations } from "./FiscalPeriodStringTransformations";

export interface FCWChecklistObject {
  checklistName: string;
  checklistId: number;
  closeChecklistTaskId: number;
  checklistRefGuid: string;
  opsDetailsId: number;
  opsDetailsName: string;
  isActive: boolean;
  companyCodes: string;
  posting: string;
  significantReview: string;
  regularReview: string;
  lowValueReview: string;
  backupPosters: string;
  backupReviewers: string;
  signOffStatus: string;
}

export interface FCWChecklistModel {
  checklistName: string;
  checklistId: number;
  checklistRefGuid: string;
  postingPeriod: string;
  opsDetailsName: string;
  backupPosters: string;
  backupReviewers: string;
}

function fcwChecklistObjectToChecklistModel(checklist: FCWChecklistObject, fiscalYear: string, fiscalMonth: string) {
  // clean default reviewers
  const backupReviewers = (checklist.backupReviewers || "").split(";").filter((reviewer: string) => reviewer !== "");
  const backupPosters = (checklist.backupPosters || "").split(";").filter((poster: string) => poster !== "");

  const newModel: FCWChecklistModel = {
    checklistName: checklist.checklistName,
    checklistId: checklist.checklistId,
    checklistRefGuid: checklist.checklistRefGuid,
    backupReviewers: backupReviewers.join(";"),
    backupPosters: backupPosters.join(";"),
    postingPeriod: FiscalPeriodStringTransformations.FiscalYearAndFiscalMonthToString(
      Number(fiscalYear),
      Number(fiscalMonth)
    ),
    opsDetailsName: checklist.opsDetailsName
  };
  return newModel;
}

export async function FetchUserChecklists(
  configuration: JemConfiguration["GeneralLedgerApi"],
  fiscalYear: string,
  fiscalPeriod: string,
  accessToken: IUserProviderState["accessToken"]
): Promise<FCWChecklistModel[]> {
  const endpoint = `${configuration.baseApiUrl}${configuration.endpoints.fcwGetChecklistsForJE}`
    .replace("{fiscalYear}", fiscalYear)
    .replace("{fiscalPeriod}", fiscalPeriod);
  const endpointUrl = getValidUrl(endpoint);
  try {
    const payload = await getRequest<FCWChecklistObject[]>(endpointUrl, accessToken);

    if (!payload) {
      return [];
    }

    return payload
      .filter((checklist) => checklist.signOffStatus.toUpperCase() !== "SIGNED OFF")
      .map((checklist) => fcwChecklistObjectToChecklistModel(checklist, fiscalYear, fiscalPeriod));
  } catch (error) {
    // TODO: REMOVE THIS ONCE API IS FINISHED
    return [];
  }
}
