import { IDropdownOption } from "@fluentui/react";

export function CommonDropdownOnChangeHandler<T>(opts: {
  option: IDropdownOption;
  keyForAll: T;
  keyForNone: T;
  currentOptions: T[];
  totalOptions: number;
}): T[] {
  const { option, keyForAll, keyForNone, currentOptions, totalOptions } = opts;
  const keyIsAll = option.key === keyForAll;
  if (keyIsAll) {
    if (option.selected) {
      // set the selected option to All only
      return [keyForAll];
    } else {
      // if All was selected, then set it to everything else
      return [keyForNone];
    }
  } else {
    // remove All and none from the selected options
    const interSelectedKeys = currentOptions.filter((i) => {
      if (typeof i === "string") {
        return !i.includes(keyForAll as string) && !i.includes(keyForNone as string);
      }
      if (typeof i === "number") {
        return i !== keyForAll && i !== keyForNone;
      }
      return false;
    });
    const newOptions = option.selected
      ? [...interSelectedKeys, option.key as T]
      : interSelectedKeys.filter((key) => key !== option.key);

    // if all options are selected, then set it to All
    if (newOptions.length === totalOptions) {
      return [keyForAll];
    } else {
      return newOptions;
    }
  }
}
