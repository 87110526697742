import { ObjectKeys } from "../../../../Shared/utilities/TypeUtils";
import { BlankLineItem } from "./GLCreateLineItems.types";

export function lineItemIsNotEmpty(item: BlankLineItem): boolean {
  const mustNotContain = new Set(["readonly", "processingstatus", "jeid", "linenum", "refguid", "innerrownumber"]);
  // remove keys with readOnly prefix or _ prefix
  const itemWithoutReadOnly = ObjectKeys(item).filter((key) => mustNotContain.has(key.toLowerCase()) === false);
  // check if all values are undefined
  const allValuesUndefined = itemWithoutReadOnly.every((key) => item[key] === undefined);
  if (allValuesUndefined) {
    return false;
  }
  return true;
}
