import { Stack } from "@fluentui/react";
import { PageHeading, RefetchButton, useQuery } from "@jem/components";

import React from "react";

interface GLSearchHeaderProps {
  lastRefreshed: ReturnType<typeof useQuery>["dataUpdatedAt"];
  onRefresh: () => void;
  hidden: boolean;
  disabled: boolean;
}

const GLSearchHeader: React.FC<GLSearchHeaderProps> = (props) => {
  return (
    <>
      <Stack
        horizontalAlign="start"
        verticalAlign="start"
        styles={{
          root: {
            width: "100%",
            padding: "0"
          }
        }}
      >
        <Stack
          horizontal
          verticalAlign="center"
          horizontalAlign="space-between"
          wrap
          tokens={{ childrenGap: 10 }}
          styles={{
            root: {
              width: "100%"
            }
          }}
        >
          <Stack.Item grow>
            <PageHeading>
              <h2>JE Search</h2>
            </PageHeading>
          </Stack.Item>
          <Stack.Item shrink>
            <RefetchButton
              lastRefreshed={props.lastRefreshed}
              onRefetch={props.onRefresh}
              disabled={props.disabled}
              hidden={props.hidden}
            />
          </Stack.Item>
        </Stack>
      </Stack>
    </>
  );
};

GLSearchHeader.displayName = "GLSearchHeader";
export default GLSearchHeader;
