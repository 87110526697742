export class FiscalPeriodStringTransformations {
  public static FiscalYearAndFiscalMonthToString(fiscalYear: number, fiscalMonth: number): string {
    return `${fiscalYear} FP ${fiscalMonth}`;
  }
  public static FiscalPeriodStringToFiscalYearAndFiscalMonth(fiscalPeriod: string): {
    fiscalYear: number;
    fiscalMonth: number;
  } {
    const fiscalYear = parseInt(fiscalPeriod.split(" ")[0], 10);
    const fiscalMonth = parseInt(fiscalPeriod.split(" ")[2], 10);
    return { fiscalYear, fiscalMonth };
  }
}
