import * as React from "react";
import { CommandBar } from "@fluentui/react/lib/CommandBar";
import { IButtonProps } from "@fluentui/react/lib/Button";
import { JemConfiguration } from "@jem/components";
import { css } from "@emotion/css";

const overflowProps: IButtonProps = { ariaLabel: "More commands" };

import { ICommandBarItemProps } from "@fluentui/react";

export interface JemChecklistDashboardCommandBarItemsProps {
  openInNewTab: () => void;
}

const JemChecklistDashboardCommandBarItems = (
  props: JemChecklistDashboardCommandBarItemsProps
): ICommandBarItemProps[] => {
  return [
    {
      key: "newChecklist",
      text: "New Checklist",
      cacheKey: "myCacheKey", // changing this key will invalidate this item's cache
      iconProps: { iconName: "Add" },
      onClick: () => props.openInNewTab()
    }
  ];
};

export const JEMChecklistDashboardCommandBar: React.FunctionComponent<{
  configuration: JemConfiguration["FCWNewJemChecklistLink"];
}> = (props) => {
  const openInNewTab = () => {
    window.open(props.configuration, "_blank", "noopener,noreferrer");
  };
  return (
    <div
      className={css`
        width: 100%;
        min-height: 1px;
        box-sizing: content-box;
        overflow: hidden;
      `}
    >
      <CommandBar
        items={JemChecklistDashboardCommandBarItems({ openInNewTab })}
        styles={{
          root: {
            paddingLeft: 0
          }
        }}
        overflowButtonProps={overflowProps}
        ariaLabel="Inbox actions"
        primaryGroupAriaLabel="Email actions"
        farItemsGroupAriaLabel="More actions"
      />
    </div>
  );
};
