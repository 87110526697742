import { GLCreateOverrides } from "@jem/components";
import React from "react";
import { useNavigate } from "react-router-dom";
import { JECreateDummyForm } from "./JECreateDummyForm";

const NewJEScenario: React.FC = () => {
  const navigate = useNavigate();

  const handleSubmit = (values: GLCreateOverrides) => {
    navigate("newje-microfrontend", { state: values });
  };

  return (
    <>
      <JECreateDummyForm onSubmit={handleSubmit} />
    </>
  );
};

export default NewJEScenario;
