import React, { PropsWithChildren, useContext, useEffect } from "react";
import { ActionResult, ActionTypes } from "../ActionManager/ActionsManager.types";
import { LoadingSpinner } from "../LoadingSpinner/LoadingSpinner";
import { ActionsContext } from "./Actions.ActionsContext";
import { ActionTitle } from "./Actions.ActionTitle";
import { ActionOnSubmitHandlerMap, BaseActionProps, CommonPropsOnRows } from "./Actions.types";

interface SaveToSAPProps<ListType, ResultType> extends BaseActionProps<ListType, ResultType> {
  onSubmitHandler: ActionOnSubmitHandlerMap<ListType, ResultType>[ActionTypes.saveToSAP];
}

const SaveToSAP = <ListType extends CommonPropsOnRows, ResultType extends ActionResult>(
  props: PropsWithChildren<SaveToSAPProps<ListType, ResultType>>
) => {
  const { styles } = useContext(ActionsContext);
  useEffect(() => {
    const trigger = async () => {
      const result = await props.onSubmitHandler(props.items);
      props.onSubmit(result);
    };
    trigger();
  }, []);
  return (
    <>
      <ActionTitle onCloseButton={() => props.onCancel()} name={"Save To SAP"}></ActionTitle>
      <div className={styles.contentStyles.body}>
        <LoadingSpinner label="Saving To SAP" />
      </div>
    </>
  );
};

SaveToSAP.displayName = "SaveToSAP";

export { SaveToSAP };
