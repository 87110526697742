import { Link, TextField } from "@fluentui/react";
import { FatalError, JemConfiguration, PageHeading, PageStyles, UserContext } from "@jem/components";
import React, { useContext } from "react";

interface ProfileProps {
  configuration: JemConfiguration;
}

const Profile: React.FC<ProfileProps> = () => {
  const userContext = useContext(UserContext);
  if (!userContext) {
    throw new FatalError("Please use a UserContext Provider.");
  }
  function removeDuplicates(name: string): string {
    const nameArray = name?.split(";");
    const uniqueNames = Array.from(new Set(nameArray));
    return uniqueNames?.join(";");
  }
  return (
    <div className={PageStyles.rootDiv}>
      <PageHeading>
        <h2>JEM Profile</h2>
      </PageHeading>

      <div className="ms-Grid">
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12 ms-md10 ms-lg6">
            <TextField
              underlined
              label="JEM Roles"
              placeholder={userContext.jemUser.roles.join(", ")}
              multiline
              // base rows on userContext.jemUser.roles, assume each element is 20 chars
              rows={userContext.jemUser.roles.length < 2 ? 1 : userContext.jemUser.roles.length < 4 ? 3 : 4}
              readOnly
            />
          </div>
        </div>

        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12 ms-md10 ms-lg6">
            <TextField underlined label="Manager" placeholder={userContext.jemUser.supervisor} readOnly />
          </div>
        </div>

        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12 ms-md10 ms-lg6">
            <TextField
              underlined
              label="Delegate From"
              placeholder={removeDuplicates(userContext.jemUser.delegatedManager)}
              readOnly
            />
          </div>
        </div>

        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12 ms-md10 ms-lg6">
            <PageHeading>
              <h2>SAP Profile</h2>
            </PageHeading>
          </div>
        </div>
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12 ms-md10 ms-lg6">
            <Link target="_blank" href="http://sapaccess">
              Request SAP Permissions
            </Link>
          </div>
        </div>
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12 ms-md10 ms-lg6">
            <TextField
              underlined
              label="SAP GLJE Permissions"
              defaultValue={userContext.jemUser.hasSapAccess ? "Yes" : "No"}
              readOnly
            />
          </div>
        </div>
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12 ms-md10 ms-lg6">
            <TextField
              underlined
              label="SAP IHCC Permissions"
              defaultValue={userContext.jemUser.hasIhccAccess ? "Yes" : "No"}
              readOnly
            />
          </div>
        </div>

        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12 ms-md10 ms-lg6">
            <TextField underlined label="Currency Format" placeholder={userContext.jemUser.currencyFormat} readOnly />
          </div>
        </div>

        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12 ms-md10 ms-lg6">
            <TextField underlined label="Date Format" placeholder={userContext.jemUser.dateFormat} readOnly />
          </div>
        </div>
      </div>
    </div>
  );
};

Profile.displayName = "Profile";
export { Profile };
