import { ApiParsingUtilities } from "@jem/components";
import { SanitizedTreasuryRow, TreasuryRow } from "./TreasuryDashboard.types";

export function sanitizeTreasuryRow(row: TreasuryRow): SanitizedTreasuryRow {
  const requestorUpper = row.requestor ? row.requestor.toUpperCase() : row.requestor;
  const reversal = row.reversalItemIndicator === "X" ? "Y" : "N";

  const newSanitizedRow: SanitizedTreasuryRow = {
    approvedDate: ApiParsingUtilities.parseDate(row.approvedDate),
    comment: ApiParsingUtilities.addDefault(row.comment),
    companyCode: ApiParsingUtilities.parseNumber(row.companyCode),
    fiscalPeriod: ApiParsingUtilities.addDefault(row.fiscalPeriod),
    fiscalYear: ApiParsingUtilities.addDefault(row.fiscalYear),
    isEditable: row.isEditable,
    opAccount: ApiParsingUtilities.addDefault(row.opAccount),
    opAmount: ApiParsingUtilities.parseNumber(row.opAmount),
    opCurrency: ApiParsingUtilities.addDefault(row.opCurrency),
    opTier: ApiParsingUtilities.addDefault(row.opTier),
    opType: ApiParsingUtilities.addDefault(row.opType),
    poId: ApiParsingUtilities.addDefault(row.poId),
    poNumber: ApiParsingUtilities.addDefault(row.poNumber),
    receivingPartyAmountInAccountCurrency: ApiParsingUtilities.addDefault(row.receivingPartyAmountInAccountCurrency),
    receivingPartyCurrency: ApiParsingUtilities.addDefault(row.receivingPartyCurrency),
    refGuid: ApiParsingUtilities.addDefault(row.refGuid),
    releaseDate: ApiParsingUtilities.parseDate(row.releaseDate),
    releasedBy: ApiParsingUtilities.addDefault(row.releaseDate),
    requestor: ApiParsingUtilities.addDefault(requestorUpper),
    reversalDate: ApiParsingUtilities.parseDate(row.reversalDate),
    reversalItemIndicator: ApiParsingUtilities.addDefault(reversal),
    reviewer: ApiParsingUtilities.addDefault(row.reviewer),
    rowVer: row.rowVer,
    rpAccount: ApiParsingUtilities.addDefault(row.rpAccount),
    rpAmount: ApiParsingUtilities.addDefault(row.rpAmount),
    rpTier: ApiParsingUtilities.addDefault(row.rpTier),
    rpType: ApiParsingUtilities.addDefault(row.rpType),
    significance: ApiParsingUtilities.addDefault(row.significance),
    status: ApiParsingUtilities.parseNumber(row.status),
    statusCode: ApiParsingUtilities.addDefault(row.statusCode),
    transAmount: ApiParsingUtilities.parseNumber(row.transAmount),
    transDesc: ApiParsingUtilities.addDefault(row.transDesc),
    transactionCurrency: ApiParsingUtilities.addDefault(row.transactionCurrency),
    usdAmount: ApiParsingUtilities.parseNumber(row.usdAmount),
    processingStatus: "",
    isPosted: true
  };
  return newSanitizedRow;
}
