import { Stack } from "@fluentui/react";
import React from "react";
import { formatDate, getCalendarColors } from "./Calendars";
import { ICloseCalendarInfo } from "./Calendars.requests";

interface CalendarLegendProps {
  calendar: "close" | "ihcc";
  monthStart: Date;
  monthEnd: Date;
  calendarInfo: ICloseCalendarInfo;
}

const CalendarLegend: React.FC<CalendarLegendProps> = (props) => {
  const currentMonthEvents = props.calendarInfo.calendarInfo.filter((event) => {
    const eventDate = new Date(formatDate(event.eventDate));
    return eventDate >= props.monthStart && eventDate <= props.monthEnd;
  });

  return (
    <>
      <Stack
        wrap
        horizontalAlign="center"
        style={{
          marginTop: "10px"
        }}
      >
        <table>
          <tbody>
            {currentMonthEvents.map((event, index) => (
              <tr key={index}>
                <td
                  key={index + 1}
                  style={{
                    backgroundColor: getCalendarColors(event.class),
                    width: "30px"
                  }}
                />
                <td
                  style={{
                    alignItems: "center"
                  }}
                >
                  {event.description}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Stack>
    </>
  );
};

CalendarLegend.displayName = "CalendarLegend";
export { CalendarLegend };
