import { DEFAULT_NULL_CHARACTER, isButtonEnabled } from "../../../../Shared";
import { GLActionTypes } from "../../../hooks";
import { GLJeDetailsState } from "../GLDetailsForm.types";

export function jeDetailsStateFlags(state: GLJeDetailsState, actionWeightages: Record<GLActionTypes, number>) {
  const isBulk = state.postingType === 7;
  const isNotReversalJe =
    state.detailsTab.reversalJe === DEFAULT_NULL_CHARACTER ||
    parseInt(state.detailsTab.documentNumber, 10) < parseInt(state.detailsTab.reversalJe, 10);
  const isReleaseAllowed = isButtonEnabled(actionWeightages["approve"], state.rowWeightage);

  return {
    isBulk,
    isNotReversalJe,
    isReleaseAllowed
  };
}
