import { BatchSelected } from "./GLBatchSearchResults/GLBatchSearchResults";

export class GLBatchSearchCriteria {
  AliasType: "P" | "R"; // 1 = P, 2 = R
  Alias: string;
  FiscalYear: number;
  FiscalMonth: number[];
  OpsDetailId: number[] | null;
  BatchName: string;
  constructor(opts: {
    AliasType: "P" | "R"; // 1 = P, 2 = R
    Alias: string;
    FiscalYear: number;
    FiscalMonth: number[];
    OpsDetailId: number[] | null;
    BatchName: string;
  }) {
    this.AliasType = opts.AliasType;
    this.Alias = opts.Alias;
    this.FiscalYear = opts.FiscalYear;
    this.FiscalMonth = opts.FiscalMonth;
    this.OpsDetailId = opts.OpsDetailId;
    this.BatchName = opts.BatchName;

    if (
      [this.AliasType, this.Alias, this.FiscalYear, this.FiscalMonth, this.OpsDetailId, this.BatchName].some(
        (val) => val === undefined
      )
    ) {
      throw new Error("GLBatchSearchCriteria does not accept undefined values");
    }
  }

  toRecord(): Record<string, string> {
    const record: Record<string, string> = {};

    Object.entries(this).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        record[key] = value.join(",");
      } else if (value !== null && typeof value !== "undefined") {
        record[key] = value.toString();
      }
    });

    return record;
  }
}
export const InitialSearchState = new GLBatchSearchCriteria({
  AliasType: "P",
  Alias: "",
  FiscalYear: 0,
  FiscalMonth: [],
  OpsDetailId: null,
  BatchName: ""
});

export function batchSearchCriteriaFromURLSearchParams(record: URLSearchParams): {
  searchCriteria: GLBatchSearchCriteria;
  selectedBatch: BatchSelected | null;
} {
  const AliasType = record.get("AliasType") === "R" ? "R" : "P";
  const Alias = record.get("Alias") || "";
  const FiscalYear = Number(record.get("FiscalYear")) || 0;
  const FiscalMonth = (record.get("FiscalMonth") || "")
    .split(",")
    .filter((x) => x)
    .map(Number);
  const OpsDetailId =
    (record.get("OpsDetailId") || "")
      .split(",")
      .filter((x) => x)
      .map(Number) || null;
  const BatchName = record.get("BatchName") || "";
  const searchCriteria = new GLBatchSearchCriteria({
    AliasType,
    Alias: Alias,
    FiscalYear,
    FiscalMonth,
    OpsDetailId,
    BatchName
  });
  const BatchRefGuid = record.get("cBatchRefGuid") || null;
  const BatchNameFromURL = record.get("cBatchName") || null;
  if (BatchRefGuid && BatchNameFromURL) {
    return {
      searchCriteria,
      selectedBatch: {
        refGuid: BatchRefGuid,
        batchName: BatchNameFromURL
      }
    };
  }
  return { searchCriteria, selectedBatch: null };
}
