import {
  JemConfiguration,
  IUserProviderState,
  JemNotification,
  getValidUrl,
  postRequest,
  ApiError,
  IAttachment,
  tryParseJson
} from "@jem/components";
import { IHCCResponse } from "./IHCC.types";

export async function ValidateReviewers(
  configuration: JemConfiguration["IhccApi"],
  accessToken: IUserProviderState["accessToken"],
  reviewers: string
): Promise<JemNotification> {
  try {
    const base = configuration.baseApiUrl;
    const url = getValidUrl(`${base}${configuration.endpoints.createPageValidateReviewers}`);
    const response = await postRequest<IHCCResponse>(url, reviewers, accessToken);
    if (response === null) {
      return {
        type: "Success",
        subjectHeader: "All reviewers are valid"
      };
    } else {
      return {
        type: "Error",
        subjectHeader: ""
      };
    }
  } catch (e) {
    if (e instanceof ApiError) {
      const error = e as ApiError;
      const incorrectReviewers = tryParseJson(error.message);
      return {
        type: "Error",
        subjectHeader: incorrectReviewers && Array.isArray(incorrectReviewers) ? incorrectReviewers.join(",") : ""
      };
    }
    throw e;
  }
}

export async function addAttachmentToPoArray(
  configuration: JemConfiguration["IhccApi"],
  accessToken: IUserProviderState["accessToken"],
  poIds: string[],
  attachments: IAttachment[]
): Promise<JemNotification> {
  const base = configuration.baseApiUrl;
  const endpoint = getValidUrl(`${base}${configuration.endpoints.actionAddAttachment}`);
  let successCount = attachments.length;
  const affectedEntires: string[] = [];
  for (const file of attachments) {
    try {
      const payload = poIds.map((poId) => ({
        Attachments: {},
        poId: Number(poId),
        id: 0,
        size: file.fileSize,
        status: 1,
        typeId: 1,
        fileName: file.fileName,
        URL: file.blobName,
        uploadDatetime: file.uploadedOn
      }));
      const response = await postRequest<any>(endpoint, payload, accessToken);
      response.result.forEach((x: { poId: { toString: () => string } }) => {
        affectedEntires.push(x.poId.toString());
      });
    } catch (e) {
      successCount = successCount - 1;
    }
  }
  if (successCount === attachments.length) {
    return {
      subjectHeader: "IHCC: Add Attachment",
      summaryBodyText: `Attached ${successCount} Files Successfully`,
      type: "Success",
      affectedEntries: affectedEntires,
      payloadEntries: poIds
    };
  } else {
    return {
      subjectHeader: "IHCC: Add Attachment",
      summaryBodyText: `Failed to attach ${attachments.length - successCount}`,
      type: "Error",
      affectedEntries: affectedEntires,
      payloadEntries: poIds
    };
  }
}

export function reversalIndicatorHandler(reversalInd: string) {
  return reversalInd == "X" ? "Y" : "N";
}
