import { useContext } from "react";
import { AnonymousCredential, BlockBlobClient, newPipeline } from "@azure/storage-blob";

import { JemConfiguration } from "../../JemConfiguration";
import { UserContext } from "../../Shared/contexts/UserContext/UserContext";
import { ApiError, RuntimeError } from "../../Shared/utilities/ErrorHelpers";
import { getRequest, getValidUrl, postRequest } from "../../Shared/utilities/RequestUtilities";
import { FiscalPeriodModel } from "../components/GLCreate/GLCreate.types";
import { GLAttachmentEndpointMap, GLAttachmentRequiredItems } from "./GL.Attachments";

import { FiscalPeriodStringTransformations } from "../utilities";
import { LoggingContext, ObjectKeys } from "../../Shared";

export interface IGLPostAttachmentResponse {
  readonly inputFileName: string;
  readonly fileStorageUri: string;
  readonly blobName: string;
  readonly fileSize: number;
  readonly uploadedOn: string; // <-- THIS IS USELESS
  readonly blobUriPath: string;
}

export function useGLAttachmentActionsRequests(
  attachmentsConfiguration: JemConfiguration["DocumentsApi"],
  deleteAttachmentEndpoint: string
): GLAttachmentEndpointMap {
  const user = useContext(UserContext);
  const logger = useContext(LoggingContext);

  const makeUrl = (endpoint: string) => {
    const base = attachmentsConfiguration.baseApiUrl;
    return getValidUrl(`${base}${endpoint}`);
  };

  const pipeline = newPipeline(new AnonymousCredential(), {
    // httpClient: MyHTTPClient, // A customized HTTP client implementing IHttpClient interface
    retryOptions: { maxTries: 4 },
    userAgentOptions: { userAgentPrefix: "AdvancedSample V1.0.0" },
    keepAliveOptions: {
      // Keep alive is enabled by default, disable keep alive by setting false
      enable: false
    }
  });

  return {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    deleteAttachment: (_regionKey, _regionName, items) => async (_id: number, _blobName: string) => {
      if (!_id) return;

      const endpoint = getValidUrl(deleteAttachmentEndpoint);
      const payload: {
        FileIds: number[];
        JeIds: number[];
      } = {
        FileIds: [_id],
        JeIds: items.map((item) => Number(item.jeId))
      };
      const response = await postRequest(endpoint, payload, user.accessToken);
      if (!response) {
        throw new ApiError("Could not delete file.");
      }

      return;
    },
    downloadAttachment: (_items) => async (blobName, _filename, region?) => {
      if (!region) {
        logger.appInsights?.trackException({
          name: "deleteAction",
          exception: new Error("Attachment Region is undefined"),
          properties: {
            blobName,
            filename: _filename
          }
        });
        throw new RuntimeError("File has no region for a GLAttachment");
      }
      const pathToBlob =
        blobName.indexOf("/corpacctjestore/") !== -1
          ? new URL(blobName).pathname.split("/corpacctjestore/")[1]
          : blobName;
      const initialEndpoint = makeUrl(
        attachmentsConfiguration.endpoints.downloadAttachment
          .replace("{regionName}", region.name)
          .replace("{pathToFile}", pathToBlob)
      );
      // const endpoint = initialEndpoint.replace("{blobName}", url);
      const response = await getRequest<string>(initialEndpoint, user.accessToken);
      if (!response) {
        throw new ApiError("Could not fetch file metadata.");
      }
      // // seems like we don't need a token with the signed url
      // // const token = await AuthorizationToken(accessToken);
      return fetch(response, {
        method: "GET"
        //   // headers: {
        //   //   Authorization: token
        //   // }
      }) as unknown as File;
    },
    uploadAttachment: (_regionKey: number, regionName: string, items) => async (file: File) => {
      const uploadSingleAttachment = async (
        file: File,
        fiscalPeriod: Pick<FiscalPeriodModel, "fiscalMonth" | "fiscalYear" | "fiscalPeriod">,
        item: Pick<GLAttachmentRequiredItems, "refGuid" | "format">
      ) => {
        const endpoint = makeUrl(
          attachmentsConfiguration.endpoints.getSignatureUrl.replace("{regionName}", regionName)
        );
        const result = await postRequest<{ storageFile: IGLPostAttachmentResponse[] }>(
          endpoint,
          {
            StorageFile: [
              {
                inputFileName: file.name,
                blobUriPath: `${fiscalPeriod.fiscalYear}/${fiscalPeriod.fiscalMonth}`
              }
            ]
          },
          user.accessToken
        );
        if (!result || result.storageFile.length === 0) {
          throw new ApiError("Documents API Attachment returned null", {
            fileName: file.name
          });
        }

        const attachment = result.storageFile[0];
        const blockBlobClient = new BlockBlobClient(attachment.fileStorageUri, pipeline);
        await blockBlobClient.uploadData(file, {
          blockSize: 4 * 1024 * 1024, // 4MB block size
          concurrency: 20, // 20 concurrency
          metadata: {
            RefGuid: encodeURIComponent(`${item.refGuid}`),
            UploadedBy: user.user.alias,
            FiscalYear: encodeURIComponent(`${fiscalPeriod.fiscalYear}`),
            FiscalPeriod: encodeURIComponent(`${fiscalPeriod.fiscalMonth}`),
            FileName: encodeURIComponent(file.name),
            Format: item.format || "Unknown"
          }
        });
        return {
          blobName: attachment.blobName,
          inputFileName: attachment.inputFileName,
          fileStorageUri: attachment.fileStorageUri,
          fileSize: file.size,
          uploadedOn: new Date().toISOString(),
          fiscalPeriod: fiscalPeriod.fiscalPeriod,
          blobUriPath: `${fiscalPeriod.fiscalYear}/${fiscalPeriod.fiscalMonth}`,
          regStoreID: _regionKey
        };
      };

      const consolidatedItemsPerFp = items.reduce(
        (ctr, item) => {
          const fp = FiscalPeriodStringTransformations.FiscalYearAndFiscalMonthToString(
            Number(item.fiscalYear),
            Number(item.fiscalPeriod)
          );
          if (ctr[fp]) {
            // push only if it doesn't exist
            if (ctr[fp].refGuids.indexOf(item.refGuid) === -1) {
              ctr[fp].refGuids.push(item.refGuid);
            }
            if (typeof item.format === "string" && ctr[fp].formats.indexOf(item.format) === -1) {
              ctr[fp].formats.push(item.format);
            }
            return ctr;
          }

          ctr[fp] = {
            fp: { fiscalMonth: Number(item.fiscalPeriod), fiscalYear: Number(item.fiscalYear), fiscalPeriod: fp },
            refGuids: [item.refGuid],
            formats: [item.format || "Unknown"]
          };
          return ctr;
        },
        {} as Record<
          string,
          {
            fp: Pick<FiscalPeriodModel, "fiscalMonth" | "fiscalYear" | "fiscalPeriod">;
            refGuids: string[];
            formats: string[];
          }
        >
      );

      const individualFiles = await Promise.all(
        ObjectKeys(consolidatedItemsPerFp).map((itemKey) =>
          uploadSingleAttachment(file, consolidatedItemsPerFp[itemKey].fp, {
            refGuid: consolidatedItemsPerFp[itemKey].refGuids.join(","),
            format: consolidatedItemsPerFp[itemKey].formats.join(",")
          })
        )
      );
      return individualFiles;
    }
  };
}
