import React from "react";
import { Icon, Link, Stack, Text } from "@fluentui/react";

const helpSectionHeaderStyles = {
  root: { fontWeight: "600", paddingBottom: 10 }
};
interface IHelpSectionLink {
  href: string;
  name: string;
}
const resourcesConfig = [
  {
    href: "https://microsoft.sharepoint.com/teams/JEMHelp/SitePages/JEMHelpHome.aspx",
    name: "Help and Support"
  },
  {
    href: "http://go.microsoft.com/fwlink/?LinkId=518021",
    name: "Microsoft Data Privacy Notice"
  },
  {
    href: "https://onefinance.microsoftcrmportals.com/finsuphome/",
    name: "Contact FinSup"
  }
] as IHelpSectionLink[];

const getExternalLink = (link: IHelpSectionLink) => {
  return (
    <Link key={link.name} target="_blank" rel="noopener noreferrer" href={link.href}>
      {link.name} <Icon iconName="NavigateExternalInline" />
    </Link>
  );
};
const HelpPanel = (
  <Stack>
    <Text variant="mediumPlus" style={helpSectionHeaderStyles}>
      Resources
    </Text>

    <Stack>
      {resourcesConfig.map((link) => {
        return getExternalLink(link);
      })}
    </Stack>
  </Stack>
);

export default HelpPanel;
