import { CommandBar, Text } from "@fluentui/react";
import {
  DashboardGrid,
  FatalError,
  IDashboardGridProps,
  IDashboardGridRef,
  JemConfiguration,
  LoggingContext,
  MockDataFn,
  PageHeading,
  PageStyles,
  TenantResponseFromAPI,
  TenantRowDetails,
  UserContext,
  LoadingStatus,
  LoadingSpinner
} from "@jem/components";

import React, { useContext, useEffect, useRef, useState } from "react";
import TenantDetailsPanel from "../../components/TenantDashboard/TenantDetailsPanel";
import { getTenantDetailsColumns } from "../../components/TenantDashboard/TenantDetails.Columns";
import {
  getTenantDetails,
  onBoardNewTenantDetails,
  updateTenantDetails
} from "../../components/TenantDashboard/TenantDetails.Requests";

export interface TenantDashboardProps {
  configuration: JemConfiguration["GeneralLedgerApi"];
  mockDashboardDataFn?: MockDataFn<unknown>;
}

export const defaultTenantDetails = {
  name: "",
  appId: "",
  primaryPoster: "",
  additionalPosters: "",
  defaultReviewers: "",
  emailContacts: "",
  tenantOwner: "",
  allowAutoReview: true,
  overrideTenantSetting: false,
  allowedPostingInJEM: false,
  description: "",
  batchNamePrefix: "",
  batchDescription: "",
  batchPurposeSuffix: "",
  reasonCode: "",
  jeReferenceNo: "",
  docType: "",
  serviceBus: "",
  sbRetryQueueName: "",
  allowedMaxValidationsErrorCount: "",
  sapUserId: "",
  emailNeedsToBeBlocked: "1"
};

const TenantDashboardPage: React.FC<TenantDashboardProps> = (props) => {
  const { appInsights } = useContext(LoggingContext);
  if (!appInsights) {
    throw new FatalError("Please use a LoggingContext Provider.");
  }

  const userContext = useContext(UserContext);

  const [openTenantPanel, setOpenTenantPanel] = useState(false);
  const [onboardNewTenantFlag, setOnboardNewTenantFlag] = useState(false);
  const [gridItems, setGridItems] = useState<TenantResponseFromAPI[]>([]);
  const [tenantDetails, setTenantDetails] = useState<TenantRowDetails>(
    JSON.parse(JSON.stringify(defaultTenantDetails))
  );
  const [tenantApiErrors, setTenantApiErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.Pending);
  const dashboardGridRef = useRef<IDashboardGridRef>(null);

  const getTenantDetailsAPI = async () => {
    const items = await getTenantDetails(props.configuration, userContext.accessToken);
    setGridItems(items);
  };

  useEffect(() => {
    let mounted = true;
    const getGrid = async () => {
      try {
        const items = await getTenantDetails(props.configuration, userContext.accessToken);
        if (mounted) {
          setGridItems(items);
          setLoadingStatus(LoadingStatus.Resolved);
        }
      } catch (err) {
        setLoadingStatus(LoadingStatus.Rejected);
      }
    };
    getGrid();
    return () => {
      mounted = false;
    };
    //
  }, []);

  const onEditClick = (editItem: TenantResponseFromAPI) => {
    setOnboardNewTenantFlag(false);
    setTenantDetails({
      name: editItem.name,
      appId: editItem.appId,
      primaryPoster: editItem.primaryPoster,
      additionalPosters: editItem.additionalPosters,
      defaultReviewers: editItem.defaultReviewers,
      emailContacts: editItem.emailContacts,
      tenantOwner: editItem.tenantOwner,
      allowAutoReview: editItem.allowAutoReview,
      overrideTenantSetting: editItem.overrideTenantSetting,
      allowedPostingInJEM: editItem.allowedPostingInJEM,
      description: editItem.description ? editItem.description : "",
      batchNamePrefix: editItem.miscConfig.batchNamePrefix,
      batchDescription: editItem.miscConfig.batchDescription,
      batchPurposeSuffix: editItem.miscConfig.batchPurposeSuffix,
      reasonCode: editItem.miscConfig.reasonCode,
      jeReferenceNo: editItem.miscConfig.jeReferenceNo,
      docType: editItem.miscConfig.docType,
      serviceBus: editItem.miscConfig.serviceBus,
      sbRetryQueueName: editItem.miscConfig.sbRetryQueueName,
      allowedMaxValidationsErrorCount: editItem.miscConfig.allowedMaxValidationsErrorCount,
      sapUserId: editItem.miscConfig.sapUserId,
      emailNeedsToBeBlocked: editItem.miscConfig.emailNeedsToBeBlocked
    });
    setOpenTenantPanel(true);
  };

  const saveOrUpdateTenantDetails = (details: TenantRowDetails) => {
    setLoading(true);
    if (onboardNewTenantFlag) {
      onBoardNewTenantDetails(props.configuration, userContext.accessToken, details).then((res) => {
        if (res.status === 400) setTenantApiErrors([res.detail]);
        else {
          setOpenTenantPanel(false);
          getTenantDetailsAPI();
        }
        setLoading(false);
      });
    } else {
      updateTenantDetails(props.configuration, userContext.accessToken, details).then((res) => {
        if (res.status === 400) setTenantApiErrors([res.detail]);
        else {
          setOpenTenantPanel(false);
          getTenantDetailsAPI();
        }
        setLoading(false);
      });
    }
  };

  const columnGenerator: IDashboardGridProps["columnGenerator"] = getTenantDetailsColumns({ onEditClick });

  return (
    <>
      <div className={PageStyles.rootDiv}>
        <PageHeading>
          <h2>Tenant Dashboard</h2>
        </PageHeading>
        <div>
          <CommandBar
            items={[
              {
                key: "onboardNewTenant",
                text: "Onboard New Tenant",
                iconProps: { iconName: "Add" },
                onClick: () => {
                  setOnboardNewTenantFlag(true);
                  setOpenTenantPanel(true);
                }
              }
            ]}
            styles={{
              root: {
                paddingLeft: 0
              }
            }}
            ariaLabel="Tenant actions"
          />
        </div>
        {loadingStatus === LoadingStatus.Pending ? (
          <LoadingSpinner label={`Loading Tenant Details`} />
        ) : loadingStatus === LoadingStatus.Rejected ? (
          <Text
            variant="xLarge"
            style={{
              color: "var(--accent-font-color, black)"
            }}
          >
            Error Loading Tenant Details
          </Text>
        ) : (
          <>
            <div>
              <DashboardGrid
                idForLocalStorage={"tenantDetails"}
                columnGenerator={columnGenerator}
                isSortedIndex={10}
                items={gridItems}
                isSortedDescending={true}
                customRef={dashboardGridRef}
                height={`500px`}
                onRenderRow={(props, defaultRender) => {
                  if (!defaultRender || !props) return null;
                  return (
                    <div>
                      {defaultRender({
                        ...props
                      })}
                    </div>
                  );
                }}
              ></DashboardGrid>
            </div>
            {openTenantPanel && (
              <TenantDetailsPanel
                configuration={props.configuration}
                loading={loading}
                error={tenantApiErrors}
                tenantDetails={tenantDetails}
                onClosePanel={() => {
                  setTenantDetails(JSON.parse(JSON.stringify(defaultTenantDetails)));
                  setOpenTenantPanel(false);
                  setTenantApiErrors([]);
                }}
                onSaveItem={(details) => {
                  setTenantDetails(details);
                  saveOrUpdateTenantDetails(details);
                  setTenantApiErrors([]);
                }}
                onboardNewTenantFlag={onboardNewTenantFlag}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

TenantDashboardPage.displayName = "TenantDashboardPage";

export default TenantDashboardPage;
