import React from "react";
import { Link, useLocation } from "react-router-dom";
import { IColumn, TooltipHost, Text, Theme } from "@fluentui/react";
import { SanitizedRow } from "./IHCCDashboard.types";
import { FilterState, SignificanceTypeAndIcon } from "@jem/components";
import { IExtendedColumn } from "@jem/components";
import { significanceIconMapping } from "../../../Shared/utilities/significanceIconMapping";

interface IhccColumn extends IExtendedColumn {
  fieldName: keyof SanitizedRow;
}

export const getIhccColumns =
  (location: ReturnType<typeof useLocation>, filterState: null | FilterState, theme: Theme) => (): IhccColumn[] => {
    const cols: IhccColumn[] = [
      {
        key: "poNumber",
        name: "PO Number",
        fieldName: "poNumber",
        type: "string",
        minWidth: 80,
        onRender: (
          item?: SanitizedRow,
          index?: number | undefined,
          column?: IColumn | undefined
        ): JSX.Element | React.ReactText | null => {
          if (!column || !item) {
            return null;
          }
          return (
            <Link
              to={`/ihcc/podetails/${item.poId}`}
              state={{
                origin: location.pathname,
                state: {
                  filterState
                }
              }}
            >
              {item.poNumber}
            </Link>
          );
        }
      },
      {
        key: "significance",
        name: "!",
        nameForExport: "Significance",
        fieldName: "significance",
        type: "string",
        minWidth: 10,
        onRender: (item: SanitizedRow) => {
          const typeAndIcon = SignificanceTypeAndIcon(item.significance, significanceIconMapping);
          return <TooltipHost content={typeAndIcon.significanceType}>{typeAndIcon.icon}</TooltipHost>;
        },
        onExport: (item: SanitizedRow) => {
          const typeAndIcon = SignificanceTypeAndIcon(item.significance, significanceIconMapping);
          return typeAndIcon.significanceType;
        }
      },
      {
        key: "status",
        name: "Status",
        fieldName: "statusCode",
        type: "string",
        minWidth: 115,
        sortDescendingAriaLabel: "Sort by status"
      },
      {
        key: "requestor",
        name: "Requestor",
        fieldName: "requestor",
        type: "string",
        minWidth: 90,
        sortDescendingAriaLabel: "Sorted by requestor"
      },
      {
        key: "reviewer",
        name: "Reviewer",
        fieldName: "reviewer",
        type: "string",
        minWidth: 90,
        sortDescendingAriaLabel: "Sorted by reviewer"
      },
      {
        key: "coCode",
        name: "Co Code",
        fieldName: "companyCode",
        type: "number",
        minWidth: 40
      },
      {
        key: "fy",
        name: "FY",
        fieldName: "fiscalYear",
        type: "number",
        minWidth: 30
      },
      {
        key: "fp",
        name: "FP",
        fieldName: "fiscalPeriod",
        type: "number",
        minWidth: 20
      },
      {
        key: "format",
        name: "Format",
        fieldName: "format",
        type: "string",
        minWidth: 50
      },
      {
        key: "bankArea",
        name: "Bank Area",
        fieldName: "bankArea",
        type: "string",
        minWidth: 35
      },
      {
        key: "reversalInd",
        name: "Reversal Ind",
        fieldName: "reversalInd",
        type: "string",
        minWidth: 60
      },
      {
        key: "comments",
        name: "Comments",
        fieldName: "comment",
        type: "string",
        minWidth: 75
      },
      {
        key: "rpCurrency",
        name: "RP Currency",
        fieldName: "receivingPartyCurrency",
        type: "string",
        minWidth: 60
      },
      {
        key: "rpAmount",
        name: "RP Amount",
        fieldName: "rpAmountInAccountCurrency",
        type: "number",
        minWidth: 90
      },
      {
        key: "transDesc",
        name: "Trans Desc",
        fieldName: "transDesc",
        type: "string",
        minWidth: 90
      },
      {
        key: "nextReleaseDate",
        name: "Next Release Date to SAP",
        fieldName: "dueDate",
        type: "date",
        minWidth: 105
      },
      {
        key: "processingStatus",
        name: "Processing Status",
        fieldName: "processingStatus",
        type: "string",
        minWidth: 60,
        onRender: (item: SanitizedRow) => {
          const procColor = item.procStatType == "Success" ? theme.palette.green : theme.palette.red;
          return (
            <Text
              variant="small"
              style={{
                color: procColor
              }}
            >
              <TooltipHost content={item.processingStatus}>{item.processingStatus}</TooltipHost>
            </Text>
          );
        }
      }
    ];

    return cols;
  };
