// import { useConst } from "@uifabric/react-hooks";
import {
  Checkbox,
  DefaultButton,
  Icon,
  IContextualMenuItem,
  IContextualMenuItemProps,
  IContextualMenuListProps,
  IContextualMenuProps,
  IRenderFunction,
  SearchBox
} from "@fluentui/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  dropdownButtonStyles,
  calculateCheckboxState,
  IDropdownContextualMenu,
  onClickMultiSelectHandler,
  onSelectionChangeHandler
} from "./DropdownContextualMenu";

// need dueDateDropdown based on data
// need significance based on data
// need Co. code based on data
// need JE type based on data
const filterRowsBasedOnSearch = (newValue: string) => (item: IContextualMenuItem) =>
  item.text && item.text.toLowerCase().indexOf(newValue.toLowerCase()) !== -1;

const DropdownSearchContextualMenu: React.FunctionComponent<Omit<IDropdownContextualMenu, "singleSelection">> = (
  props
) => {
  const [checkedStates, setCheckedStates] = useState<boolean[]>(
    calculateCheckboxState(props.available, props.selected, false)
  );

  const onCheckboxChange = useCallback(
    (index: number) => {
      return (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        ev?.preventDefault();
        ev?.stopPropagation();

        const newState = [...checkedStates];
        newState[index] = checked ?? false;

        if (props.onSelectionChange && props.available) {
          const newSelection = onSelectionChangeHandler(false, props.available, newState);
          props.onSelectionChange(newSelection);
        }

        setCheckedStates(newState);
      };
    },
    [checkedStates, props]
  );

  const onClick = useCallback(
    (ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, item?: IContextualMenuItem) => {
      if (item) {
        const index = Number(item.key);
        const newState = onClickMultiSelectHandler(index, false, checkedStates);
        if (props.onSelectionChange && props.available) {
          const newSelection = onSelectionChangeHandler(false, props.available, newState);
          props.onSelectionChange(newSelection);
        }
        setCheckedStates(newState);

        // Prevent the dropdown from closing when the Checkbox is clicked
        ev?.preventDefault();
        ev?.stopPropagation();
      }
    },
    [checkedStates, props]
  );

  const [menuItems, setMenuItems] = useState(
    props.available
      ? props.available.map((option, i) => ({
          key: `${i}`,
          text: option === null || option === undefined ? "" : option.toString(),
          onClick,
          onRenderContent: (props: IContextualMenuItemProps) => (
            <Checkbox label={props.item.text} checked={checkedStates[i]} onChange={onCheckboxChange(i)} />
          )
        }))
      : []
  );

  useEffect(() => {
    setCheckedStates(calculateCheckboxState(props.available, props.selected, false));
  }, [props]);

  useEffect(() => {
    const newMenuItems = props.available
      ? props.available.map((option, i) => ({
          key: `${i}`,
          text: option === null || option === undefined ? "" : option.toString(),
          onClick,
          onRenderContent: (props: IContextualMenuItemProps) => (
            <Checkbox label={props.item.text} checked={checkedStates[i]} onChange={onCheckboxChange(i)} />
          )
        }))
      : [];
    setMenuItems(newMenuItems);
  }, [checkedStates, onClick, props]);

  const [items, setItems] = useState(menuItems);

  useEffect(() => {
    setItems(menuItems);
  }, [menuItems]);

  const onAbort = useCallback(() => {
    setItems(menuItems);
  }, [menuItems]);

  const onChange = useCallback(
    (ev?: React.ChangeEvent<HTMLInputElement>, newValue?: string) => {
      if (newValue !== undefined) {
        const filteredItems = menuItems.filter(filterRowsBasedOnSearch(newValue));
        if (!filteredItems || !filteredItems.length) {
          filteredItems.push({
            key: "no_results",
            onRender: () => (
              <div
                key="no_results"
                style={{
                  width: "100%",
                  height: "100px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <Icon iconName="SearchIssue" title="No actions found" />
                <span>No actions found</span>
              </div>
            )
          } as any);
        }
        setItems(filteredItems);
      } else {
        setItems(menuItems);
      }
    },
    [menuItems]
  );

  const renderMenuList = useCallback<IRenderFunction<IContextualMenuListProps>>(
    (menuListProps, defaultRender) => {
      return (
        <div>
          <div style={{ borderBottom: "1px solid #ccc" }}>
            <SearchBox
              ariaLabel={`Filter ${props.name} by text`}
              placeholder={`Filter ${props.name}`}
              onAbort={onAbort}
              onChange={onChange}
              styles={{
                root: { margin: "8px" }
              }}
            />
          </div>
          {defaultRender ? defaultRender(menuListProps) : null}
        </div>
      );
    },
    [onAbort, onChange, props.name]
  );

  const menuProps = useMemo<IContextualMenuProps>(
    () => ({
      onRenderMenuList: renderMenuList,
      shouldFocusOnMount: true,
      onMenuDismissed: onAbort,
      styles: {
        subComponentStyles: {},
        root: {
          backgroundColor: "transparent",
          fontWeight: "normal"
        }
      },
      items
    }),
    [items, onAbort, renderMenuList]
  );

  return <DefaultButton styles={dropdownButtonStyles} text={props.name} menuProps={menuProps} />;
};
DropdownSearchContextualMenu.displayName = "DropdownSearchContextualMenu";
export { DropdownSearchContextualMenu };
