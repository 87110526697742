import { css } from "@emotion/css";
import { DefaultButton, Text, useTheme } from "@fluentui/react";
import {
  OpsDashboardApi,
  MockDataFn,
  LoadingStatus,
  UserContext,
  getMockUserContextObj,
  LoadingSpinner
} from "@jem/components";
import React, { useContext, useEffect, useState } from "react";
import { Else, If } from "react-condition";
import { For } from "react-loops";
import { createSearchParams, useNavigate } from "react-router-dom";
import NestedActions from "./Actions.NestedActions";
import { getOpsDashboardActionsData } from "./Actions.requests";
import { OpsDashboardActionElement, OpsDashboardActionElementContextualMenu } from "./Actions.types";

export interface IOpsDashboardActions {
  props: {
    configuration: OpsDashboardApi;
    mockFn?: MockDataFn<OpsDashboardActionElement[]>;
  };
}

const Actions: React.FC<IOpsDashboardActions["props"]> = (props) => {
  const [dashboardGridStatus, setDashboardGridStatus] = useState<LoadingStatus>(LoadingStatus.Idle);
  const [actions, setActions] = useState<OpsDashboardActionElement[]>([]);
  const theme = useTheme();
  const context = useContext(UserContext);
  const navigate = useNavigate();
  const { accessToken } = !context ? getMockUserContextObj() : context;

  useEffect(() => {
    let mounted = true;
    setDashboardGridStatus(LoadingStatus.Pending);
    const getActions = async () => {
      try {
        const items = await getOpsDashboardActionsData(props.configuration, {
          getTokenFn: accessToken,
          mockFn: props.mockFn
        });
        if (mounted) {
          setActions(items);
          setDashboardGridStatus(LoadingStatus.Resolved);
        }
      } catch (err) {
        setDashboardGridStatus(LoadingStatus.Rejected);
      }
    };
    getActions();
    return () => {
      mounted = false;
    };
    //
  }, []);

  return (
    <>
      <div
        className={css`
          display: inline-flex;
          flex-direction: column;
          border: 1px solid ${theme.palette.neutralPrimary};
          padding: 16px;
          gap: 8px;
          height: 100%;
        `}
      >
        {actions.length === 0 && dashboardGridStatus !== LoadingStatus.Pending ? (
          <Text
            variant="xLarge"
            style={{
              color: "var(--accent-font-color, black)"
            }}
          >
            No Actions to show.
          </Text>
        ) : dashboardGridStatus === LoadingStatus.Resolved ? (
          <>
            <Text variant="large">Available Actions</Text>
            <For of={actions}>
              {(singleAction) => (
                <If test={"actions" in singleAction}>
                  <>
                    {/* ContextualMenu here with nested options */}
                    <NestedActions
                      action={singleAction as OpsDashboardActionElementContextualMenu}
                      onClick={(action, event) => {
                        if (event) {
                          event.preventDefault();
                        }
                        navigate(
                          {
                            pathname: `/ops/dashboard/requests`,
                            search: `?${createSearchParams({
                              action: `${action.actionId}`
                            }).toString()}`
                          },
                          {
                            state: {
                              origin: "/ops/dashboard",
                              state: action
                            }
                          }
                        );
                      }}
                    />
                  </>
                  <Else>
                    <>
                      {/* Single button */}
                      <DefaultButton
                        onClick={(event) => {
                          event.preventDefault();
                          navigate(
                            {
                              pathname: `/ops/dashboard/requests`,
                              search: `?${createSearchParams({
                                action: `${singleAction.actionId}`
                              }).toString()}`
                            },
                            {
                              state: {
                                origin: "/ops/dashboard",
                                state: singleAction
                              }
                            }
                          );
                        }}
                      >
                        {singleAction.name}
                      </DefaultButton>
                    </>
                  </Else>
                </If>
              )}
            </For>
          </>
        ) : (
          <LoadingSpinner label="Loading Actions" />
        )}
      </div>
    </>
  );
};

export default Actions;
