import { Selection, SelectionMode, Text, useTheme } from "@fluentui/react";
import React, { useContext, useEffect, useRef, useState } from "react";

import {
  ActionResult,
  ActionsManager,
  ActionsProvider,
  ActionTypes,
  DashboardGrid,
  DashboardListActions,
  DashboardListActionsRef,
  exportToExcel,
  GridCommandToggle,
  IActionsManagerRef,
  IDashboardGridRef,
  IDashboardListActionsState,
  isButtonEnabled,
  JemConfiguration,
  JemNotification,
  LoadingSpinner,
  LoadingStatus,
  LoggingContext,
  MockDataFn,
  PageHeading,
  PageStyles,
  UserContext
} from "@jem/components";
import { useLocation } from "react-router-dom";

import { GridAction, IHCCDashboardData, SanitizedRow } from "../../components/IHCCDashboard/IHCCDashboard.types";
import { getIHCCSearchColumns } from "../../components/IHCCSearch/IHCCSearch.columns";
import { IHCCSanitizedSearchGrid, InitialSearchState, SearchState } from "../../components/IHCCSearch/IHCCSearch.types";
import IHCCSearchForm, { getGridItemsFromPayload } from "../../components/IHCCSearch/IHCCSearchForm";
import { useIHCCActions } from "../../shared/IHCC.Actions";
import { useIHCCAttachmentActions } from "../../shared/IHCC.Attachments";

export interface IHCCSearchProps {
  configuration: JemConfiguration["IhccApi"];
  //change this to IHCC Search
  mockDashboardDataFn?: MockDataFn<IHCCDashboardData>;
}

function ActionHandler(
  actionManagerRef: React.RefObject<IActionsManagerRef<SanitizedRow>>,
  dashboardGridRef: React.RefObject<IDashboardGridRef>,
  actionName: ActionTypes
): () => void {
  return () => {
    if (actionManagerRef.current && dashboardGridRef.current) {
      const selection = dashboardGridRef.current.getSelection();
      const items = selection.items as SanitizedRow[];
      actionManagerRef.current.open(actionName, items);
    }
  };
}

function SearchActions(
  actionManagerRef: React.RefObject<IActionsManagerRef<SanitizedRow>>,
  dashboardGridRef: React.RefObject<IDashboardGridRef>
) {
  return {
    save: ActionHandler(actionManagerRef, dashboardGridRef, ActionTypes.save),
    approve: ActionHandler(actionManagerRef, dashboardGridRef, ActionTypes.approve),
    releaseForSignoff: ActionHandler(actionManagerRef, dashboardGridRef, ActionTypes.releaseForSignoff),
    needsClarification: ActionHandler(actionManagerRef, dashboardGridRef, ActionTypes.needsClarification),
    addPoster: ActionHandler(actionManagerRef, dashboardGridRef, ActionTypes.addPoster),
    addReviewer: ActionHandler(actionManagerRef, dashboardGridRef, ActionTypes.addReviewer),
    recall: ActionHandler(actionManagerRef, dashboardGridRef, ActionTypes.recall),
    rescind: ActionHandler(actionManagerRef, dashboardGridRef, ActionTypes.rescind),
    addAttachment: ActionHandler(actionManagerRef, dashboardGridRef, ActionTypes.addAttachment)
  };
}

const calculateButtonStates =
  (actions: GridAction[]) =>
  (rows: SanitizedRow[]): IDashboardListActionsState => {
    const initialState: IDashboardListActionsState = {
      retryDisabled: GridCommandToggle.Removed,
      deleteActionDisabled: GridCommandToggle.Removed,
      saveDisabled: GridCommandToggle.Disabled,
      sendToPosterDisabled: GridCommandToggle.Removed,
      approveDisabled: GridCommandToggle.Disabled,
      releaseForSignoffDisabled: GridCommandToggle.Disabled,
      needsClarificationDisabled: GridCommandToggle.Disabled,
      addPosterDisabled: GridCommandToggle.Disabled,
      addReviewerDisabled: GridCommandToggle.Disabled,
      recallDisabled: GridCommandToggle.Disabled,
      rescindDisabled: GridCommandToggle.Disabled,
      addAttachmentDisabled: GridCommandToggle.Disabled,
      releaseToSAPDisabled: GridCommandToggle.Removed,
      sendBackFromTreasuryDisabled: GridCommandToggle.Removed
    };
    if (!actions || rows.length === 0) {
      return initialState;
    }
    const firstStatus = rows[0].txtStatus;
    if (rows.some((x) => x.txtStatus !== firstStatus)) {
      return initialState;
    }
    const actionNameToFlag = {
      SaveSAP: GridCommandToggle.Enabled,
      // addAttachment
      Attach: GridCommandToggle.Enabled,
      // addReviewer
      AddReviewer: GridCommandToggle.Enabled,
      // releaseForSignoff
      InitReview: GridCommandToggle.Enabled,
      // approve
      Approve: GridCommandToggle.Enabled,
      // needsClarification
      Clarify: GridCommandToggle.Enabled,
      // addPoster
      AddPoster: GridCommandToggle.Enabled,
      // rescind
      Rescind: GridCommandToggle.Enabled,
      // recall
      Recall: GridCommandToggle.Enabled,
      // deleteAction
      DeletePo: GridCommandToggle.Enabled
    };
    const simplifiedActions = actions.reduce((ctr, action) => {
      ctr[action.actionName] = action.weightage;
      return ctr;
    }, {} as { [key: string]: number });
    for (const row of rows) {
      for (const actionName of Object.keys(actionNameToFlag)) {
        if (!(actionName in simplifiedActions)) {
          actionNameToFlag[actionName as keyof typeof actionNameToFlag] = GridCommandToggle.Disabled;
        } else {
          const currentFlagState = actionNameToFlag[actionName as keyof typeof actionNameToFlag];
          if (currentFlagState === GridCommandToggle.Enabled) {
            const buttonWeightage = simplifiedActions[actionName as keyof typeof simplifiedActions] as number;
            const flagState = isButtonEnabled(buttonWeightage, row.actionWeightage);
            if (!flagState) {
              actionNameToFlag[actionName as keyof typeof actionNameToFlag] = GridCommandToggle.Disabled;
              // todo: remove action from array actionsToUse
            }
          }
        }
      }
    }
    return {
      retryDisabled: GridCommandToggle.Removed,
      deleteActionDisabled: GridCommandToggle.Removed,
      saveDisabled: actionNameToFlag.SaveSAP,
      sendToPosterDisabled: GridCommandToggle.Removed,
      approveDisabled: actionNameToFlag.Approve,
      releaseForSignoffDisabled: actionNameToFlag.InitReview,
      needsClarificationDisabled: actionNameToFlag.Clarify,
      addPosterDisabled: actionNameToFlag.AddPoster,
      addReviewerDisabled: actionNameToFlag.AddReviewer,
      recallDisabled: actionNameToFlag.Recall,
      rescindDisabled: actionNameToFlag.Rescind,
      addAttachmentDisabled: actionNameToFlag.Attach,
      releaseToSAPDisabled: GridCommandToggle.Removed,
      sendBackFromTreasuryDisabled: GridCommandToggle.Removed
    };
  };

const ihccSearchUserConfigName = "ihccSearchUserConfigName";

const IHCCSearch: React.FC<IHCCSearchProps> = (props) => {
  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.Idle);
  const [currentSearchState, setCurrentSearchState] = useState<SearchState | undefined>(undefined);
  const logger = useContext(LoggingContext);
  const { accessToken } = useContext(UserContext);
  const actionManagerRef = useRef<IActionsManagerRef<SanitizedRow>>(null);
  const dashboardActionsRef = useRef<DashboardListActionsRef>(null);
  const dashboardGridRef = useRef<IDashboardGridRef>(null);
  const actions = useIHCCActions<SanitizedRow>(props.configuration);
  const attachmentActions = useIHCCAttachmentActions(props.configuration);
  const location = useLocation();
  const [searchStateForNavigation, setSearchState] = useState<SearchState | null>(null);
  const theme = useTheme();
  const [gridItems, setGridItems] = useState<SanitizedRow[]>([]);
  const [gridActions, setGridActions] = useState<GridAction[]>([]);

  const processNewGridItems = (searchResult: IHCCSanitizedSearchGrid, searchState: SearchState) => {
    setSearchState(searchState);
    setCurrentSearchState(searchState);
    setGridActions(searchResult.gridActions);
    setGridItems(searchResult.result);
  };

  useEffect(() => {
    setLoadingStatus(LoadingStatus.Resolved);
  }, [gridItems]);

  const onCancelAction = () => {
    if (dashboardGridRef.current) {
      dashboardGridRef.current.clearSelection();
    }
  };

  const refreshTrigger = async (search: SearchState, notification: JemNotification) => {
    setLoadingStatus(LoadingStatus.Pending);
    try {
      const gridData = await getGridItemsFromPayload(accessToken, search, props.configuration);
      gridData.result.map((x) => {
        if (notification && notification.payloadEntries && notification.affectedEntries) {
          const unaffectedEntries = notification.payloadEntries.filter(
            (value) => !notification.affectedEntries?.includes(value)
          );
          const entry =
            notification.subjectHeader &&
            (notification.subjectHeader.includes("rescind") || notification.subjectHeader.includes("Add Attachment"))
              ? x.poId
              : x.refGuid;
          if (unaffectedEntries.includes(entry)) {
            x.processingStatus =
              notification.summaryBodyText && notification.summaryBodyText !== " "
                ? notification.summaryBodyText
                : "Action failed to perform.";
            x.procStatType = "Error";
          }
          if (notification.affectedEntries.includes(entry)) {
            x.processingStatus = notification.summaryBodyText ? notification.summaryBodyText : "";
            x.procStatType = notification.type;
          }
        }
      });
      processNewGridItems(gridData, search);
    } catch (err) {
      setLoadingStatus(LoadingStatus.Rejected);
    }
  };

  const onSubmitAction = (actionResult: ActionResult) => {
    if (dashboardGridRef.current) {
      dashboardGridRef.current.clearSelection();
    }
    logger.addNotification(actionResult.notification);
    refreshTrigger(currentSearchState ? currentSearchState : InitialSearchState, actionResult.notification);
  };

  return (
    <>
      <ActionsProvider>
        <ActionsManager<SanitizedRow, ActionResult>
          customRef={actionManagerRef}
          onCancel={onCancelAction}
          onSubmit={onSubmitAction}
          attachmentsActionsMap={attachmentActions}
          endpointMap={actions}
        ></ActionsManager>
      </ActionsProvider>
      <div className={PageStyles.rootDiv}>
        <PageHeading>
          <h2>Payment Orders Search</h2>
        </PageHeading>
        <IHCCSearchForm
          configuration={props.configuration}
          searchState={currentSearchState}
          triggerRefreshFn={refreshTrigger}
        />
        {loadingStatus !== LoadingStatus.Pending && gridItems.length === 0 ? (
          <Text
            variant="xLarge"
            style={{
              color: "var(--accent-font-color, black)"
            }}
          >
            No Items to show.
          </Text>
        ) : loadingStatus === LoadingStatus.Resolved ? (
          <>
            <DashboardGrid
              idForLocalStorage={ihccSearchUserConfigName}
              customRef={dashboardGridRef}
              columnGenerator={getIHCCSearchColumns(location, searchStateForNavigation, theme)}
              items={gridItems}
              height={"500px"}
              selectionMode={SelectionMode.multiple}
              onSelectionChanged={(selection: Selection) => {
                if (dashboardActionsRef.current) {
                  const userSelectedItems = selection.getSelection();
                  dashboardActionsRef.current.refreshSelection(userSelectedItems as SanitizedRow[]);
                }
              }}
              onExport={(rows) => {
                exportToExcel({
                  sheetName: "Search Results",
                  rowsWithHeader: rows,
                  fileName: "PaymentOrders.xlsx"
                });
              }}
            >
              <DashboardListActions
                customRef={dashboardActionsRef}
                hide={false}
                buttonCalculator={calculateButtonStates(gridActions)}
                preventCollapse={true}
                deleteActionDisabled={GridCommandToggle.Removed}
                {...SearchActions(actionManagerRef, dashboardGridRef)}
              ></DashboardListActions>
            </DashboardGrid>
          </>
        ) : (
          <LoadingSpinner label="Loading Search Results" />
        )}
      </div>
    </>
  );
};

IHCCSearch.displayName = "IHCCSearch";

export default IHCCSearch;
