import React, { forwardRef, useContext, useEffect, useImperativeHandle, useRef } from "react";
import {
  ApiError,
  AttachmentEndpointMap,
  Attachments,
  AuthorizationToken,
  IAttachmentEntryFormRef,
  IAttachmentEntryFormSingleUploadProps,
  JemConfiguration,
  UserContext,
  getRequest,
  getValidUrl,
  postRequest
} from "@jem/components";
import { IPostAttachmentResponse } from "../../../PaymentOrders/shared/IHCC.Attachments";
import { PrimaryButton } from "@fluentui/react";
import { ReconProcessSearchOptions } from "../../components/ReconFYFPSelector/ReconFYFPSelector";

export interface IReconAttachment {
  id: number;
  blobName: string;
  fileName: string;
  url: string;
  fileSize: number;
  uploadedOn: string;
  documentType: string;
  fiscalPeriod?: string;
  fiscalYear: string;
}

export interface ReconAttachmentsProps {
  configuration: JemConfiguration["OpsDashboardApi"];
  onUpload: () => void;
  onChange: IAttachmentEntryFormSingleUploadProps["onChange"];
  onDelete: IAttachmentEntryFormSingleUploadProps["onDelete"];
  fY: string;
  fP: string;
}

export interface IReconProcessAttachmentsRef {
  getAttachments: IAttachmentEntryFormRef<IReconAttachment>["saveAndGetAttachments"];
  reset: () => void;
}

export function useReconAttachmentAction(
  configuration: JemConfiguration["OpsDashboardApi"],
  args: ReconProcessSearchOptions
): AttachmentEndpointMap {
  const { accessToken } = useContext(UserContext);

  const makeUrl = (endpoint: string) => {
    const base = configuration.baseApiUrl;
    return getValidUrl(`${base}${endpoint}`);
  };

  return {
    uploadAttachment: async (file: File) => {
      console.log(file);
      const endpoint = makeUrl(
        `${configuration.endpoints.uploadReconFiles}`.replace(
          "{reconId}",
          `?reconId=FY${args.fiscalYear}P${args.fiscalPeriod.toString().padStart(2, "0")}`
        )
      );
      const formData = new FormData();
      formData.append(file.name, file);
      const result = await postRequest<string>(endpoint, formData, accessToken, {});
      if (!result) {
        throw new ApiError("Recon Attachment returned null", {
          fileName: file.name
        });
      }
      return {
        blobName: result,
        inputFileName: file.name,
        fileStorageUri: result
      } as IPostAttachmentResponse;
    },
    downloadAttachment: async (url: string) => {
      const initialEndpoint = makeUrl(configuration.endpoints.downloadReconFile);
      const endpoint = initialEndpoint.replace("{blobName}", url);
      const response = await getRequest<{
        blobName: string;
        inputFileName: string;
        fileStorageUri: string;
      }>(endpoint, accessToken);
      if (!response) {
        throw new ApiError("Could not fetch file metadata.");
      }
      // seems like we don't need a token with the signed url
      // const token = await AuthorizationToken(accessToken);
      return fetch("", {
        method: "GET"
        // headers: {
        //   Authorization: token
        // }
      }) as unknown as File;
    },
    deleteAttachment: async (id: number, blobName: string) => {
      const initialEndpoint = makeUrl(configuration.endpoints.deleteReconFile);
      const endpoint = initialEndpoint.replace("{attachmentId}", `${id}`).replace("{blobName}", blobName);
      const token = await AuthorizationToken(accessToken);
      await fetch(endpoint, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: token
        }
      });
      return;
    }
  };
}

const ReconProcessAttachments = forwardRef<IReconProcessAttachmentsRef, ReconAttachmentsProps>((props, ref) => {
  const attachmentsRef = useRef<IAttachmentEntryFormRef<IReconAttachment>>(null);

  const attachmentActions = useReconAttachmentAction(props.configuration, {
    fiscalYear: Number(props.fY), //Number("2024"),
    fiscalPeriod: Number(props.fP) //Number("5")
  });
  useEffect(() => {
    if (attachmentsRef.current) {
      attachmentsRef.current.setAttachments([], []);
    }
  }, []);

  useImperativeHandle(ref, () => ({
    getAttachments() {
      if (attachmentsRef.current) {
        return attachmentsRef.current.saveAndGetAttachments();
      }
      return Promise.resolve([
        {
          attachments: [],
          recentlyUploadedAttachments: []
        },
        null
      ]);
    },
    reset() {
      if (attachmentsRef.current) {
        return attachmentsRef.current.reset();
      }
    }
  }));

  return (
    <>
      <div className="ms-Grid" dir="ltr">
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12">
            <Attachments<IReconAttachment>
              onUploadFile={attachmentActions.uploadAttachment}
              onDeleteFile={attachmentActions.deleteAttachment}
              onDownloadFile={attachmentActions.downloadAttachment}
              customRef={attachmentsRef}
              onChange={props.onChange}
              onDelete={props.onDelete}
              disabled={false}
              maxAttachments={999}
            ></Attachments>
            <PrimaryButton text="Upload" onClick={props.onUpload} allowDisabledFocus disabled={false} />
          </div>
        </div>
      </div>
    </>
  );
});

ReconProcessAttachments.displayName = "ReconProcessAttachments";
export default ReconProcessAttachments;
