import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import React, { createContext, useCallback, useState } from "react";

export type NotificationType = "Error" | "Success" | "Information";

export interface JemNotification {
  type: NotificationType;
  exception?: any;
  subjectHeader: string;
  actionName?: string;
  messageBodyText?: string;
  // https://coherence-portal.azurewebsites.net/Styles/UIIcons#Examples&Status
  subjectIcon?: string;
  subjectIconColor?: string;
  senderName?: string;
  senderImageUrl?: string;
  eventTimeBlock?: string;
  eventLocation?: string;
  actionRequiredText?: string;
  actionRequiredLink?: string;
  summaryBodyText?: string;
  affectedEntries?: string[];
  payloadEntries?: string[];
}

export const initialNotification: JemNotification = {
  type: "Information",
  subjectHeader: "",
  affectedEntries: [],
  payloadEntries: []
};

export interface ILoggingProviderState {
  appInsights: ApplicationInsights | undefined;
  notifications: JemNotification[];
  addNotification: (newNotification: JemNotification) => void;
  clearNotifications: () => void;
}
const initialState: ILoggingProviderState = {
  appInsights: undefined,
  notifications: [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  addNotification: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  clearNotifications: () => {}
};
const LoggingContext = createContext(initialState);

interface ILoggingProviderProps {
  appInsights: ApplicationInsights;
  children: React.ReactNode;
}

const LoggingProvider = (props: ILoggingProviderProps): JSX.Element => {
  const [notifications, setNotifications] = useState<JemNotification[]>([]);

  const addNotification = (newNotification: JemNotification) => {
    if (newNotification.type === "Error") {
      props.appInsights.trackException({ exception: newNotification.exception });
    } else {
      props.appInsights.trackEvent(
        {
          name: `${newNotification.subjectHeader}`
        },
        {
          actionName: newNotification.actionName
        }
      );
    }
    setNotifications((prev) => [...prev, newNotification]);
  };
  const clearNotifications = () => setNotifications([]);

  const contextValue: ILoggingProviderState = {
    appInsights: props.appInsights,
    notifications,
    addNotification: useCallback((newNotification: JemNotification) => addNotification(newNotification), []),
    clearNotifications: useCallback(() => clearNotifications(), [])
  };

  return <LoggingContext.Provider value={contextValue}>{props.children}</LoggingContext.Provider>;
};

export { LoggingContext, LoggingProvider };
