import {
  CheckboxVisibility,
  ConstrainMode,
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  SelectionMode,
  Text,
  useTheme
} from "@fluentui/react";
import React, { forwardRef, useImperativeHandle, useReducer, useRef, useState } from "react";
import { AsyncSubject } from "rxjs";

import {
  AutomatedDashboardConfiguration,
  AutomatedJeDashboardElement
} from "../AutomatedJeDashboard/AutomatedJeDashboard.requests";
import { S2SActionTypes, s2sSettingsReducer, IActionConfigurationsPerActionType } from "./ActionsManager.types";
import { css } from "@emotion/css";
import { getAutomatedDashboardColumns } from "../AutomatedJeDashboard/AutomatedJeDashboard.columns";
import { onProceedCheckbox } from "./Actions";
import { Notification } from "../../../Shared/components/NotificationsManager/NotificationsManager";
import { IUserProviderState } from "../../../Shared/utilities/RequestUtilities";
import { IPageOverlay, PageOverlay } from "../../../Shared/components/PageOverlay/PageOverlay";
import { LoadingStatus } from "../../../Shared/utilities/Utilities";
import { LoadingSpinner } from "../../../Shared/components/LoadingSpinner/LoadingSpinner";
import {
  onRenderDetailsHeader,
  onRenderItemColumn
} from "../../../Shared/components/DashboardGrid/DashboardGrid.cells";
import { CheckBoxSubmission, ICheckboxProps } from "../../../Shared/components/Actions/Actions.BottomButtons";
import { Section } from "../../../Shared/components/Section/Section";

export interface IActionsManager {
  props: {
    noop?: string;
    configuration: AutomatedDashboardConfiguration;
    getTokenFn?: IUserProviderState["accessToken"];
  };
  ref: {
    open: (actionName: S2SActionTypes, selectedItems: AutomatedJeDashboardElement[]) => void;
    getOnSubmitAsyncSubject: () => AsyncSubject<Notification[] | null>;
  };
}

// type dictionary = Record<string, string>;
// const actionMapper: dictionary = {
//   Retry: "Retry"
// };

const ActionConfigurations: IActionConfigurationsPerActionType = {
  Retry: {
    confirmText: "Retry",
    labelText: "Void Entry(-ies) and Retry",
    type: "checkBox",
    subText: "Please confirm action: Void and Retry Entry(-ies)."
  }
};

async function loadItemsOnActionManager(
  items: AutomatedJeDashboardElement[],
  actionName: string,
  dispatchLoadingStatus: React.Dispatch<React.SetStateAction<LoadingStatus>>,
  dispatchItems: React.Dispatch<React.SetStateAction<AutomatedJeDashboardElement[]>>,
  dispatchMessage: React.Dispatch<React.SetStateAction<string>>,
  dispatchErrorMessage: React.Dispatch<React.SetStateAction<string>>,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getTokenFn?: IUserProviderState["accessToken"]
) {
  try {
    dispatchLoadingStatus(LoadingStatus.Pending);

    dispatchLoadingStatus(LoadingStatus.Resolved);
    dispatchMessage(`Showing ${items.length} Entries of ${items.length} Selected`);
    dispatchItems(items);
  } catch (e) {
    dispatchLoadingStatus(LoadingStatus.Rejected);
    dispatchErrorMessage("Could not load entries correctly.");
    dispatchItems([]);
  }
}

let onSubmitAsyncSubject: null | AsyncSubject<Notification[] | null> = null;

const S2SActionsManager = forwardRef((props: IActionsManager["props"], ref: React.Ref<IActionsManager["ref"]>) => {
  const theme = useTheme();
  const pageOverlayRef = useRef<IPageOverlay["ref"]>(null);
  const [settings, defineSettings] = useReducer<s2sSettingsReducer>((state, newState) => {
    return newState;
  }, null);
  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.Idle);
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const [itemsForUI, setItemsForUi] = useState<AutomatedJeDashboardElement[]>([]);
  const [userSelectedItems, setUserSelectedItems] = useState<AutomatedJeDashboardElement[]>([]);
  const [message, setMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  useImperativeHandle(ref, () => ({
    open(name, selectedItems) {
      const configuration = ActionConfigurations[name];
      defineSettings({
        name,
        configuration
      });
      setUserSelectedItems(selectedItems);
      loadItemsOnActionManager(selectedItems, name, setLoadingStatus, setItemsForUi, setMessage, setErrorMessage);
      if (pageOverlayRef.current) {
        pageOverlayRef.current.open();
        window.scrollTo(0, 0);
      }
    },
    getOnSubmitAsyncSubject() {
      onSubmitAsyncSubject = new AsyncSubject<Notification[] | null>();
      return onSubmitAsyncSubject;
    }
  }));

  const closeOverlay = () => {
    defineSettings(null);
    setMessage("");
    setErrorMessage("");
    setItemsForUi([]);
    setUserSelectedItems([]);
    if (pageOverlayRef.current) {
      pageOverlayRef.current.close();
    }
  };

  const onSubmit: ICheckboxProps["onSubmit"] = async (type) => {
    if (type === "cancel") {
      if (onSubmitAsyncSubject) {
        onSubmitAsyncSubject.next(null);
        onSubmitAsyncSubject.complete();
        onSubmitAsyncSubject = null;
      }
      closeOverlay();
    } else {
      if (errorMessage.length <= 0) {
        if (settings) {
          const action = settings.name;
          const result = await onProceedCheckbox(action, userSelectedItems, props.configuration, props.getTokenFn);
          if (onSubmitAsyncSubject) {
            onSubmitAsyncSubject.next(result);
            onSubmitAsyncSubject.complete();
            onSubmitAsyncSubject = null;
          }
          closeOverlay();
        }
      } else {
        alert("Can't perform this action at this moment. Please check the error message.");
      }
    }
  };

  return (
    <React.Fragment>
      <PageOverlay
        ref={pageOverlayRef}
        label="Action"
        closeLabel="Cancel"
        onClose={() => {
          if (onSubmitAsyncSubject) {
            onSubmitAsyncSubject.next(null);
            onSubmitAsyncSubject.complete();
            onSubmitAsyncSubject = null;
          }
          closeOverlay();
        }}
      >
        {settings === null ? null : (
          <React.Fragment>
            <div
              className={css`
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
              `}
            >
              <div className="label-container">
                <Text
                  nowrap
                  variant="medium"
                  block
                  style={{
                    marginTop: "4px",
                    color: "var(--accent-font-color, gray)"
                  }}
                >
                  {message}
                </Text>
              </div>
            </div>
            <div>
              <div className="label-container">
                <Text
                  nowrap
                  variant="medium"
                  block
                  style={{
                    marginTop: "4px",
                    color: "var(--error-font-color, red)",
                    whiteSpace: "pre-line"
                  }}
                >
                  {errorMessage}
                </Text>
              </div>
            </div>
            <Section label="SL Entries">
              {loadingStatus === LoadingStatus.Pending ? (
                <LoadingSpinner label="Loading Details" />
              ) : loadingStatus === LoadingStatus.Rejected ? (
                <Text variant="xLarge">Unable to load details for entries</Text>
              ) : itemsForUI.length === 0 ? (
                <Text
                  variant="xLarge"
                  style={{
                    color: "var(--accent-font-color, gray)",
                    textAlign: "center"
                  }}
                >
                  No items to show.
                </Text>
              ) : (
                <DetailsList
                  items={itemsForUI}
                  columns={getAutomatedDashboardColumns()() as IColumn[]}
                  selectionMode={SelectionMode.none}
                  checkboxVisibility={CheckboxVisibility.hidden}
                  onRenderDetailsHeader={onRenderDetailsHeader(theme)}
                  onRenderItemColumn={onRenderItemColumn}
                  getRowAriaLabel={(item) => {
                    return `Entry ${item.docNumber}`;
                  }}
                  constrainMode={ConstrainMode.horizontalConstrained}
                  layoutMode={DetailsListLayoutMode.justified}
                  ariaLabelForGrid={`AutomatedJe Entries`}
                />
              )}
            </Section>
            <Section label={settings.configuration.labelText}>
              <CheckBoxSubmission
                {...settings.configuration}
                checkboxText={settings.configuration.subText}
                submitButtonText={settings.configuration.confirmText}
                onSubmit={onSubmit}
              />
            </Section>
          </React.Fragment>
        )}
      </PageOverlay>
    </React.Fragment>
  );
});

export { S2SActionsManager };
