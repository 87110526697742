import { css } from "@emotion/css";
import { BaseButton, Button, DefaultButton, Selection, SelectionMode, Stack, Text, useTheme } from "@fluentui/react";
import {
  ActionResult,
  ActionsManager,
  ActionsProvider,
  ActionTypes,
  DashboardFilter,
  DashboardGrid,
  DashboardListActions,
  DashboardListActionsRef,
  exportToExcel,
  FilterSchema,
  FilterState,
  GridCommandToggle,
  IActionsManagerRef,
  IDashboardGridRef,
  IDashboardListActionsState,
  IDashboardTileRef,
  initialNotification,
  isButtonEnabled,
  JemConfiguration,
  JemNotification,
  LoadingSpinner,
  LoadingStatus,
  LoggingContext,
  MockDataFn,
  PageHeading,
  PageStyles
} from "@jem/components";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { getIhccColumns } from "../../components/IHCCDashboard/IHCCDashboard.columns";
import { ihccFilterRows } from "../../components/IHCCDashboard/IHCCDashboard.filterRows";
import { IHCCDashboardData, SanitizedRow } from "../../components/IHCCDashboard/IHCCDashboard.types";
import { UseIHCCDashboardApi } from "../../components/IHCCDashboard/IHCCDashboard.useDashboardApi";
import { useIHCCActions } from "../../shared/IHCC.Actions";
import { useIHCCAttachmentActions } from "../../shared/IHCC.Attachments";

export interface IHCCDashboardProps {
  configuration: JemConfiguration["IhccApi"];
  mockDashboardDataFn?: MockDataFn<IHCCDashboardData>;
}

const PaymentOrderHeader: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const navigateCallback = (
    event: React.MouseEvent<
      HTMLDivElement | BaseButton | Button | HTMLAnchorElement | HTMLButtonElement | HTMLSpanElement,
      MouseEvent
    >
  ) => {
    event.preventDefault();
    navigate("/ihcc/create", {
      state: {
        origin: location.pathname,
        state: location.state
      }
    });
  };
  return (
    <Stack
      horizontal
      verticalAlign="center"
      horizontalAlign="space-between"
      wrap
      tokens={{ childrenGap: 20 }}
      styles={{
        root: {
          width: "100%"
        }
      }}
    >
      <Stack.Item grow align="stretch">
        <h2>Payment Orders Action Dashboard</h2>
      </Stack.Item>
      <Stack.Item align="end">
        <Stack horizontalAlign="center" tokens={{ childrenGap: 5 }}>
          <Stack.Item>
            <Text variant="small">Quickstart</Text>
          </Stack.Item>
          <Stack.Item>
            <DefaultButton
              iconProps={{ iconName: "Add" }}
              ariaLabel="Create New Payment Order"
              onClick={navigateCallback}
            >
              Create New Payment Order
            </DefaultButton>
          </Stack.Item>
        </Stack>
      </Stack.Item>
    </Stack>
  );
};

const calculateButtonStates =
  (actions: IHCCDashboardData["actions"] | null) =>
  (rows: SanitizedRow[]): IDashboardListActionsState => {
    const initialState: IDashboardListActionsState = {
      retryDisabled: GridCommandToggle.Removed,
      saveDisabled: GridCommandToggle.Removed,
      sendToPosterDisabled: GridCommandToggle.Removed,
      approveDisabled: GridCommandToggle.Disabled,
      releaseForSignoffDisabled: GridCommandToggle.Disabled,
      needsClarificationDisabled: GridCommandToggle.Disabled,
      addPosterDisabled: GridCommandToggle.Disabled,
      addReviewerDisabled: GridCommandToggle.Disabled,
      recallDisabled: GridCommandToggle.Disabled,
      rescindDisabled: GridCommandToggle.Disabled,
      addAttachmentDisabled: GridCommandToggle.Disabled,
      deleteActionDisabled: GridCommandToggle.Disabled,
      releaseToSAPDisabled: GridCommandToggle.Removed,
      sendBackFromTreasuryDisabled: GridCommandToggle.Removed
    };
    if (!actions || rows.length === 0) {
      return initialState;
    }
    const firstStatus = rows[0].txtStatus;
    if (rows.some((x) => x.txtStatus !== firstStatus)) {
      return initialState;
    }
    const actionNameToFlag = {
      // SaveSAP: false Not used here
      // addAttachment
      Attach: GridCommandToggle.Enabled,
      // addReviewer
      AddReviewer: GridCommandToggle.Enabled,
      // releaseForSignoff
      InitReview: GridCommandToggle.Enabled,
      // approve
      Approve: GridCommandToggle.Enabled,
      // needsClarification
      Clarify: GridCommandToggle.Enabled,
      // addPoster
      AddPoster: GridCommandToggle.Enabled,
      // rescind
      Rescind: GridCommandToggle.Enabled,
      // recall
      Recall: GridCommandToggle.Enabled,
      // deleteAction
      DeletePo: GridCommandToggle.Enabled
    };
    const actionsToUse = actions[rows[0].txtStatus as keyof typeof actions];
    const simplifiedActions = actionsToUse.reduce((ctr, action) => {
      ctr[action.actionName] = action.weightage;
      return ctr;
    }, {} as { [key: string]: number });
    for (const row of rows) {
      for (const actionName of Object.keys(actionNameToFlag)) {
        if (!(actionName in simplifiedActions)) {
          actionNameToFlag[actionName as keyof typeof actionNameToFlag] = GridCommandToggle.Disabled;
        } else {
          const currentFlagState = actionNameToFlag[actionName as keyof typeof actionNameToFlag];
          if (currentFlagState === GridCommandToggle.Enabled) {
            const buttonWeightage = simplifiedActions[actionName as keyof typeof simplifiedActions] as number;
            const flagState = isButtonEnabled(buttonWeightage, row.actionWeightage);
            if (!flagState) {
              actionNameToFlag[actionName as keyof typeof actionNameToFlag] = GridCommandToggle.Disabled;
              // todo: remove action from array actionsToUse
            }
          }
        }
      }
    }
    return {
      retryDisabled: GridCommandToggle.Removed,
      saveDisabled: GridCommandToggle.Removed,
      sendToPosterDisabled: GridCommandToggle.Removed,
      approveDisabled: actionNameToFlag.Approve,
      releaseForSignoffDisabled: actionNameToFlag.InitReview,
      needsClarificationDisabled: actionNameToFlag.Clarify,
      addPosterDisabled: actionNameToFlag.AddPoster,
      addReviewerDisabled: actionNameToFlag.AddReviewer,
      recallDisabled: actionNameToFlag.Recall,
      rescindDisabled: actionNameToFlag.Rescind,
      addAttachmentDisabled: actionNameToFlag.Attach,
      deleteActionDisabled: actionNameToFlag.DeletePo,
      releaseToSAPDisabled: GridCommandToggle.Removed,
      sendBackFromTreasuryDisabled: GridCommandToggle.Removed
    };
  };

const ihccDashboardUserConfigName = "ihccDashboardConfiguration";
const filters: FilterSchema<SanitizedRow>[] = [
  {
    filterName: "Status",
    columnName: "txtStatus"
  },
  {
    filterName: "Requestor",
    columnName: "requestor"
  },
  {
    filterName: "Co Code",
    columnName: "companyCode"
  },
  {
    filterName: "FY",
    columnName: "fiscalYear"
  },
  {
    filterName: "FP",
    columnName: "fiscalPeriod"
  },
  {
    filterName: "Reversal Ind",
    columnName: "reversalInd"
  },
  {
    filterName: "Due Date",
    columnName: "dueDate"
  }
];

function ActionHandler(
  actionManagerRef: React.RefObject<IActionsManagerRef<SanitizedRow>>,
  dashboardGridRef: React.RefObject<IDashboardGridRef>,
  actionName: ActionTypes
): () => void {
  return () => {
    if (actionManagerRef.current && dashboardGridRef.current) {
      const selection = dashboardGridRef.current.getSelection();
      const items = selection.items as SanitizedRow[];
      actionManagerRef.current.open(actionName, items);
    }
  };
}

function DashboardActions(
  actionManagerRef: React.RefObject<IActionsManagerRef<SanitizedRow>>,
  dashboardGridRef: React.RefObject<IDashboardGridRef>
) {
  return {
    approve: ActionHandler(actionManagerRef, dashboardGridRef, ActionTypes.approve),
    releaseForSignoff: ActionHandler(actionManagerRef, dashboardGridRef, ActionTypes.releaseForSignoff),
    needsClarification: ActionHandler(actionManagerRef, dashboardGridRef, ActionTypes.needsClarification),
    addPoster: ActionHandler(actionManagerRef, dashboardGridRef, ActionTypes.addPoster),
    addReviewer: ActionHandler(actionManagerRef, dashboardGridRef, ActionTypes.addReviewer),
    recall: ActionHandler(actionManagerRef, dashboardGridRef, ActionTypes.recall),
    rescind: ActionHandler(actionManagerRef, dashboardGridRef, ActionTypes.rescind),
    addAttachment: ActionHandler(actionManagerRef, dashboardGridRef, ActionTypes.addAttachment),
    deleteAction: ActionHandler(actionManagerRef, dashboardGridRef, ActionTypes.deleteAction)
  };
}

function addProcStat(gridData: SanitizedRow[], notification: JemNotification) {
  const gridDataWithProcStat = gridData ? gridData : [];
  const procStat = notification ? notification : initialNotification;

  gridDataWithProcStat.map((x) => {
    if (procStat && procStat.payloadEntries && procStat.affectedEntries) {
      const unaffectedEntries = procStat.payloadEntries.filter((value) => !procStat.affectedEntries?.includes(value));
      const entry =
        procStat.subjectHeader &&
        (procStat.subjectHeader.includes("rescind") || procStat.subjectHeader.includes("Add Attachment"))
          ? x.poId
          : x.refGuid;
      if (unaffectedEntries.includes(entry)) {
        x.processingStatus =
          notification.summaryBodyText && notification.summaryBodyText !== " "
            ? notification.summaryBodyText
            : "Action failed to perform.";
        x.procStatType = "Error";
      }
      if (procStat.affectedEntries.includes(entry)) {
        x.processingStatus = notification.summaryBodyText ? notification.summaryBodyText : "";
        x.procStatType = notification.type;
      }
    }
  });
  return gridDataWithProcStat;
}

const IHCCDashboard: React.FC<IHCCDashboardProps> = (props) => {
  const logger = useContext(LoggingContext);
  const tilesRef = useRef<IDashboardTileRef<SanitizedRow>>(null);
  const dashboardGridRef = useRef<IDashboardGridRef>(null);
  const actionManagerRef = useRef<IActionsManagerRef<SanitizedRow>>(null);
  const dashboardActionsRef = useRef<DashboardListActionsRef>(null);
  const actions = useIHCCActions<SanitizedRow>(props.configuration);
  const attachmentActions = useIHCCAttachmentActions(props.configuration);
  const location = useLocation();
  const theme = useTheme();

  const [apiState] = UseIHCCDashboardApi({
    apiConfiguration: props.configuration,
    mockDashboardDataFn: props.mockDashboardDataFn
  });

  const [gridItems, setGridItems] = useState<SanitizedRow[]>([]);
  const [filteredItems, setFilteredItems] = useState<SanitizedRow[]>([]);
  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.Idle);
  const [filterState, setFilterState] = useState<FilterState | null>(null);
  const [notification, setNotification] = useState<JemNotification>({
    subjectHeader: " ",
    summaryBodyText: " ",
    type: "Information",
    affectedEntries: [],
    payloadEntries: []
  });

  useEffect(() => {
    const mainContainer = document.querySelector("main");
    if (mainContainer) {
      mainContainer.scrollTo(0, 0);
    }
  }, []);

  useEffect(() => {
    if (filterState) {
      location.state = {
        filterState
      };
    }
  }, [filterState]);

  useEffect(() => {
    if (apiState.loadingStatus === LoadingStatus.Resolved && apiState.rows) {
      const rowsWithProcStat = addProcStat(apiState.rows, notification);
      setGridItems(rowsWithProcStat);

      tilesRef.current?.refreshTilesAndFilters(rowsWithProcStat as SanitizedRow[], "txtStatus");
    }
  }, [apiState.loadingStatus]);

  useEffect(() => {
    // trigger showing the page only when the data has been loaded
    // and the grid items are finished loading
    if (apiState.loadingStatus === LoadingStatus.Resolved || apiState.loadingStatus === LoadingStatus.Rejected) {
      setLoadingStatus(LoadingStatus.Resolved);
    }
  }, [gridItems]);

  const filterData = useCallback(
    (newFilterState?: FilterState) => {
      if (newFilterState) {
        const newItems = ihccFilterRows(newFilterState, filters, gridItems);
        setFilteredItems(newItems);
        setFilterState(newFilterState);
      }
    },
    [gridItems]
  );

  const onCancelAction = () => {
    if (dashboardGridRef.current) {
      dashboardGridRef.current.clearSelection();
    }
  };

  const onSubmitAction = (actionResult: ActionResult) => {
    if (dashboardGridRef.current) {
      dashboardGridRef.current.clearSelection();
    }
    setNotification(actionResult.notification);
    logger.addNotification(actionResult.notification);
  };

  return (
    <>
      <ActionsProvider>
        <ActionsManager<SanitizedRow, ActionResult>
          customRef={actionManagerRef}
          onCancel={onCancelAction}
          onSubmit={onSubmitAction}
          attachmentsActionsMap={attachmentActions}
          endpointMap={actions}
        ></ActionsManager>
      </ActionsProvider>
      <div className={PageStyles.rootDiv}>
        <PageHeading>
          <PaymentOrderHeader />
        </PageHeading>
        <div
          className={css`
            display: inline-flex;
            gap: 16px;
            flex-direction: column;
            flex-wrap: wrap;
            justify-content: center;
            align-items: stretch;
            width: 100%;
            margin: 16px 0 16px 0;
          `}
        >
          <div
            className={css`
              position: relative;
              flex-grow: 1;
              width: 100%;
            `}
          >
            <DashboardFilter<SanitizedRow>
              filters={filters}
              singleSelectionFilters={["Status"]}
              searchEnabledFilters={["Co Code"]}
              onFilterChanged={filterData}
              loadingStatus={apiState.loadingStatus}
              customRef={tilesRef}
              location={location}
            />
          </div>
          {loadingStatus === LoadingStatus.Resolved && gridItems.length === 0 ? (
            <Text
              variant="xLarge"
              style={{
                color: "var(--accent-font-color, black)"
              }}
            >
              No Items to show.
            </Text>
          ) : loadingStatus === LoadingStatus.Resolved ? (
            <>
              <DashboardGrid
                idForLocalStorage={ihccDashboardUserConfigName}
                customRef={dashboardGridRef}
                columnGenerator={getIhccColumns(location, filterState, theme)}
                items={filteredItems}
                isSortedIndex={11}
                height={`500px`}
                isSortedDescending={true}
                selectionMode={SelectionMode.multiple}
                onSelectionChanged={(selection: Selection) => {
                  if (dashboardActionsRef.current) {
                    const userSelectedItems = selection.getSelection();
                    dashboardActionsRef.current.refreshSelection(userSelectedItems as SanitizedRow[]);
                  }
                }}
                onExport={(rows) => {
                  exportToExcel({
                    sheetName: "dashboardPaymentOrders",
                    rowsWithHeader: rows,
                    fileName: "PaymentOrders.xlsx"
                  });
                }}
              >
                <DashboardListActions
                  customRef={dashboardActionsRef}
                  hide={false}
                  buttonCalculator={calculateButtonStates(apiState.actions)}
                  preventCollapse={true}
                  retryDisabled={GridCommandToggle.Removed}
                  saveDisabled={GridCommandToggle.Removed}
                  {...DashboardActions(actionManagerRef, dashboardGridRef)}
                ></DashboardListActions>
              </DashboardGrid>
            </>
          ) : (
            <LoadingSpinner label="Loading Payment Orders Dashboard" />
          )}
        </div>
      </div>
    </>
  );
};

IHCCDashboard.displayName = "IHCCDashboard";

export default IHCCDashboard;
