import { SelectionMode, Stack, Text } from "@fluentui/react";
import React, { useRef } from "react";
import { DashboardGrid } from "../../../../Shared/components/DashboardGrid/DashboardGrid";
import { IDashboardGridRef, IExtendedColumn } from "../../../../Shared/components/DashboardGrid/DashboardGrid.types";
import { exportToExcel } from "../../../../Shared/components/DashboardGrid/DashboardGrid.utilities";
import { gridStyle } from "../../../shared/GL.styles";
import { GLJeSapDetailsLineItem, GLJeSapDetailsState } from "../GLDetailsForm.types";

export interface GLDetailsLineItemsProps {
  state: GLJeSapDetailsState;
  templateId?: number;
}

interface GLJeSapDetailsColumn extends IExtendedColumn {
  fieldName: keyof GLJeSapDetailsLineItem;
}

export const getJeSapLineItemsColumns = (templateId: number) => (): IExtendedColumn[] => {
  let cols: GLJeSapDetailsColumn[] = [
    {
      key: "item",
      name: "Item",
      fieldName: "item",
      type: "string",
      minWidth: 40
    },
    {
      key: "pos",
      name: "Pos",
      fieldName: "pos",
      type: "string",
      minWidth: 40
    },
    {
      key: "account",
      name: "Account",
      fieldName: "account",
      type: "string",
      minWidth: 60
    },
    {
      key: "accountDesc",
      name: "Account Desc",
      fieldName: "accountDescription",
      type: "string",
      minWidth: 100
    },
    {
      key: "assignmentId",
      name: "Assignment Id",
      fieldName: "assignmentId",
      type: "string",
      minWidth: 80
    },
    {
      key: "ccIo",
      name: "CC/IO",
      fieldName: "ccIo",
      type: "string",
      minWidth: 40
    },
    {
      key: "so",
      name: "SO",
      fieldName: "so",
      type: "string",
      minWidth: 30
    },
    {
      key: "soli",
      name: "SOLI",
      fieldName: "soli",
      type: "string",
      minWidth: 40
    },
    {
      key: "tp",
      name: "TP",
      fieldName: "tp",
      type: "string",
      minWidth: 30
    },
    {
      key: "tc",
      name: "TC",
      fieldName: "tc",
      type: "string",
      minWidth: 30
    },
    {
      key: "pc",
      name: "PC",
      fieldName: "pc",
      type: "string",
      minWidth: 50
    },
    {
      key: "transAmt",
      name: "Trans Amt",
      fieldName: "transAmt",
      type: "string",
      minWidth: 60
    },
    {
      key: "transCurr",
      name: "Trans Curr",
      fieldName: "transCurr",
      type: "string",
      minWidth: 60
    },
    {
      key: "statAmount",
      name: "Stat Amt",
      fieldName: "statAmount",
      type: "string",
      minWidth: 50
    },
    {
      key: "statCurr",
      name: "Stat Curr",
      fieldName: "statCurr",
      type: "string",
      minWidth: 50
    },
    {
      key: "gcAmt",
      name: "GC Amt",
      fieldName: "gcAmt",
      type: "string",
      minWidth: 50
    },
    {
      key: "gcCurr",
      name: "GC Curr",
      fieldName: "gcCurr",
      type: "string",
      minWidth: 50
    },
    {
      key: "lineItemDesc",
      name: "Line Item Description",
      fieldName: "lineItemDescription",
      type: "string",
      minWidth: 80
    }
  ];

  const curr4cols: GLJeSapDetailsColumn[] = [
    // apply logic to show only if templateid = 4 , add tempalteId to endpoint (MSSales)
    {
      key: "localcurramT2",
      name: "LC2 Amt",
      fieldName: "localcurramT2",
      type: "string",
      minWidth: 50
    },
    {
      key: "localcurrkeY2",
      name: "LC2 Curr",
      fieldName: "localcurrkeY2",
      type: "string",
      minWidth: 50
    },
    {
      key: "localcurramT3",
      name: "LC3 Amt",
      fieldName: "localcurramT3",
      type: "string",
      minWidth: 50
    },
    {
      key: "localcurrkeY3",
      name: "LC3 Curr",
      fieldName: "localcurrkeY3",
      type: "string",
      minWidth: 50
    }
  ];

  if (templateId === 4) {
    cols = cols.concat(curr4cols);
  }
  return cols;
};

const SapDetailsLineItems: React.FC<GLDetailsLineItemsProps> = (props) => {
  const columns = getJeSapLineItemsColumns(props.templateId || 1);

  const dashboardGridRef = useRef<IDashboardGridRef>(null);
  return (
    <>
      <Stack horizontal wrap {...{ tokens: gridStyle }}>
        {props.state.lineItems.length === 0 ? (
          <Text
            variant="xLarge"
            style={{
              color: "var(--accent-font-color, black)"
            }}
          >
            No Items to show.
          </Text>
        ) : (
          <DashboardGrid
            idForLocalStorage={"glJeDetailsSapLineItems"}
            customRef={dashboardGridRef}
            columnGenerator={columns}
            items={props.state.lineItems}
            isSortedIndex={0}
            height={`500px`}
            isSortedDescending={false}
            selectionMode={SelectionMode.multiple}
            onExport={(rows) => {
              exportToExcel({
                sheetName: "jeDetailsSapLineItems",
                rowsWithHeader: rows,
                fileName: "jeDetailsSapLineItems.xlsx"
              });
            }}
            disablePagination={true}
          ></DashboardGrid>
        )}
      </Stack>
    </>
  );
};

SapDetailsLineItems.displayName = "SapDetailsLineItems";
export { SapDetailsLineItems };
