export function rootStyles(): string {
  return `font-family: "Segoe UI Web (West European)",Segoe UI,-apple-system,BlinkMacSystemFont,Roboto,Helvetica Neue,sans-serif;`;
}

// TODO: MOVE THIS TO CSS VARIABLES
export const globalColors = {
  filterCommandBarBackground: "#E5E5E5",
  filterTileBackground: "#FAFAFA",
  filterTileCountFont: "#0064BF",
  filterTileTextFont: "#171717",
  filterByButtonBackground: "#F2F2F2",
  fontError: "#A80000",
  fontRegular: "#171717",
  fontNeutral: "#e5e5e5",
  jeDetailsHeadingBackground: "#FAFAFA",
  jeDetailsHeadingLabelFont: "#646464",
  jeDetailsHeadingTextFont: "#171717",
  accent: "#0078D4",
  hrLineLight: "#e5e5e5",
  hrLineDark: "#949494"
};
