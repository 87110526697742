import { JemConfiguration } from "../../../JemConfiguration";
import { APIAnomalyError, ApiError } from "../../utilities/ErrorHelpers";
import { getArrayFromCacheOrFromEndpoint } from "../../utilities/LocalStorageCache";
import { getRequest, getValidUrl, IUserProviderState } from "../../utilities/RequestUtilities";
import { DomainDataEnum, DomainDataObjects, JemConfigurationDomainDataApiSubset } from "./JEMContext.domainData.types";
import { ObjectKeys } from "../../utilities/TypeUtils";

interface DomainDataConfigurationItem {
  nameForLocalStorage: string;
  endpoint: string;
}

class ConfigurationItem implements DomainDataConfigurationItem {
  nameForLocalStorage: string;
  endpoint: string;
  constructor(nameForLocalStorage: string, endpoint: string) {
    this.nameForLocalStorage = nameForLocalStorage;
    this.endpoint = endpoint;
  }
}

type ConfigurationObject<T extends any[]> = {
  [k in T[number]]: ConfigurationItem;
};

type ConfigurationObjectMap<T> = ConfigurationObject<Array<keyof T>>;

export function FullConfigurationDomainDataConfiguration(apiConfiguration: JemConfiguration["DomainDataAPI"]) {
  const base = apiConfiguration.baseApiUrl;
  const allValidDomainDataKeys = ObjectKeys(DomainDataEnum);
  const baseConfig: ConfigurationObjectMap<DomainDataObjects> = ObjectKeys(apiConfiguration.endpoints).reduce(
    (config, domainDataEndpoint) => {
      if (!allValidDomainDataKeys.includes(domainDataEndpoint)) {
        return config;
      }

      config[domainDataEndpoint] = new ConfigurationItem(
        domainDataEndpoint,
        `${base}${apiConfiguration.endpoints[domainDataEndpoint]}`
      );
      return config;
    },
    {} as ConfigurationObjectMap<DomainDataObjects>
  );

  return baseConfig;
}

export function SubsetDomainDataConfiguration(
  requiredDomainDataKeys: DomainDataEnum[],
  apiConfiguration: JemConfigurationDomainDataApiSubset
) {
  const base = apiConfiguration.baseApiUrl;
  const allValidDomainDataKeys = ObjectKeys(DomainDataEnum);

  const baseConfig: Partial<ConfigurationObjectMap<DomainDataObjects>> = requiredDomainDataKeys.reduce(
    (config, domainDataEndpoint) => {
      if (!allValidDomainDataKeys.includes(domainDataEndpoint)) {
        return config;
      }
      config[domainDataEndpoint] = new ConfigurationItem(
        domainDataEndpoint,
        `${base}${apiConfiguration.endpoints[domainDataEndpoint]}`
      );
      return config;
    },
    {} as ConfigurationObjectMap<DomainDataObjects>
  );

  return baseConfig;
}

export class DomainDataManager {
  private _configuration: Partial<ConfigurationObjectMap<DomainDataObjects>>;

  constructor(
    apiConfiguration: JemConfigurationDomainDataApiSubset | JemConfiguration["DomainDataAPI"],
    subset?: DomainDataEnum[]
  ) {
    if (subset) {
      this._configuration = SubsetDomainDataConfiguration(subset, apiConfiguration);
    } else {
      const baseConfig = FullConfigurationDomainDataConfiguration(
        apiConfiguration as JemConfiguration["DomainDataAPI"]
      );
      this._configuration = Object.freeze(baseConfig);
    }
  }

  public get configuration() {
    return this._configuration;
  }

  public domainDataKey(keyName: DomainDataEnum): ConfigurationItem | null {
    const configItem = this._configuration[keyName];
    if (configItem) {
      return configItem;
    }
    return null;
  }

  *[Symbol.iterator]() {
    for (const key in Object.keys(this._configuration)) {
      yield this._configuration[key as DomainDataEnum];
    }
  }
}

async function fetchDomainData<T extends keyof DomainDataObjects>(
  endpoint: string,
  getTokenFn: IUserProviderState["accessToken"]
): Promise<DomainDataObjects[T] | null> {
  const endpointUrl = getValidUrl(endpoint);
  const payload = await getRequest<DomainDataObjects[T] | null>(endpointUrl, getTokenFn);

  return payload;
}

export async function getDomainData<T extends keyof DomainDataObjects>(
  configuration: JemConfiguration["DomainDataAPI"] | JemConfigurationDomainDataApiSubset,
  getTokenFn: IUserProviderState["accessToken"],
  domainDataConfiguration: ConfigurationItem,
  transform?: (data: DomainDataObjects[T]) => DomainDataObjects[T]
): Promise<DomainDataObjects[T]> {
  const fetcher = (
    configuration: JemConfiguration["DomainDataAPI"] | JemConfigurationDomainDataApiSubset,
    getTokenFn: IUserProviderState["accessToken"]
  ) => fetchDomainData<T>(domainDataConfiguration.endpoint, getTokenFn);

  try {
    let domainDataArray: DomainDataObjects[T] | null = null;
    domainDataArray = await getArrayFromCacheOrFromEndpoint<
      DomainDataObjects[T] | null,
      JemConfiguration["DomainDataAPI"] | JemConfigurationDomainDataApiSubset
    >(configuration, domainDataConfiguration.nameForLocalStorage, fetcher, getTokenFn, configuration.cacheTimeInHours);
    // TODO: Handle 500 Error from API as not an error as follows:
    //  - Log something in the notifications
    //  - Return empty array instead of throwing error
    if (domainDataArray === null || domainDataArray === undefined || Object.keys(domainDataArray).length === 0) {
      throw new APIAnomalyError(
        `Received Empty Array for ${domainDataConfiguration.nameForLocalStorage} from Domain Data API`
      );
    }
    if (transform) {
      return transform(domainDataArray);
    }
    return domainDataArray;
  } catch (error) {
    if (error instanceof APIAnomalyError) {
      throw error;
    }
    if (error instanceof ApiError) {
      throw new APIAnomalyError(
        `Error fetching ${domainDataConfiguration.nameForLocalStorage} from Domain Data API: ${error.message}`
      );
    }
    throw new APIAnomalyError(
      `Error fetching ${domainDataConfiguration.nameForLocalStorage} from Domain Data API: ${
        (error as Error).message || ""
      }`
    );
  }
}
