import { JemConfiguration, JEMContext, LoadingSpinner, LoadingStatus, PageStyles } from "@jem/components";
import React, { useContext, useEffect, useState } from "react";
import {
  AAG3DashboardFormData,
  AAG3DashboardSelector
} from "../../components/AAG3DashboardSelector/AAG3DashboardSelector";
import { ComponentProvider } from "@micro-frontend-react/core/lib/ComponentProvider/ComponentProvider";

interface AAG3DashboardProps {
  configuration: JemConfiguration;
}

const AAG3Dashboard: React.FC<AAG3DashboardProps> = (props) => {
  const [showLoading, setShowLoading] = useState(false);
  const [searchKey, setSearchKey] = useState<AAG3DashboardFormData | null>(null);

  const [domainDataStatus, setDomainDataStatus] = useState<LoadingStatus>(LoadingStatus.Idle);
  const jemContext = useContext(JEMContext);
  useEffect(() => {
    if (
      jemContext.initInfo.status === LoadingStatus.Rejected ||
      jemContext.initInfo.status === LoadingStatus.Resolved
    ) {
      setDomainDataStatus(LoadingStatus.Resolved);
    }
  }, [jemContext.initInfo.values]);

  if (domainDataStatus !== LoadingStatus.Resolved || jemContext.initInfo.values === null) {
    return <LoadingSpinner label="Loading Domain Data" />;
  }

  // const componentsUrl = "http://localhost:8050";
  const componentsUrl = props.configuration.jemUiComponentsEndpoint;
  const pathToComponents =
    componentsUrl.indexOf("localhost") !== -1
      ? ""
      : `/jem-microfrontends/${props.configuration.jemUiComponentsVersion}`;
  const pathToConfig = componentsUrl.indexOf("localhost") !== -1 ? "" : "/jem-microfrontends";
  return (
    <>
      <div className={PageStyles.rootDiv}>
        <AAG3DashboardSelector
          onUpdate={function (formData: AAG3DashboardFormData): void {
            setSearchKey(formData);
          }}
          configuration={props.configuration.GeneralLedgerApi}
          domainData={jemContext.initInfo.values}
        ></AAG3DashboardSelector>
        {showLoading && <LoadingSpinner label="Loading Component" />}
        {searchKey && (
          <ComponentProvider
            config={{
              script: `${componentsUrl}${pathToComponents}/JEMAAG3Dashboard/JEMAAG3Dashboard.js`,
              name: "JEMAAG3Dashboard"
            }}
            onError={() => {
              setShowLoading(false);
            }}
            onLoad={() => {
              setShowLoading(true);
            }}
            onLoaded={() => {
              setShowLoading(false);
            }}
            data={{
              baseName: "/jemaag3dashboard",
              filters: searchKey,
              baseUrl: `${componentsUrl}${pathToConfig}`
            }}
          />
        )}
      </div>
    </>
  );
};

AAG3Dashboard.displayName = "AAG3Dashboard";
export { AAG3Dashboard };
