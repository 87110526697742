import { PrimaryButton, TextField, Text, Checkbox } from "@fluentui/react";
import {
  FatalError,
  IIndexedTile,
  JemConfiguration,
  JEMContext,
  LoadingSpinner,
  LoadingStatus,
  LoggingContext,
  MockDataFn,
  PageHeading,
  PageStyles,
  useQuery,
  UserContext
} from "@jem/components";

import { OrderedMap } from "immutable";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import UserDashboard from "../../components/UserDashboard/UserDashboard";
import { dashboardButtonStates } from "../../../GeneralLedger/components/GLDashboard/GLDashboard.Actions";
import {
  getDraftColumns,
  getUserColumns,
  getPreReviewColumns,
  notPostedPostingRequestedColumns
} from "../../components/UserDashboard/UserDashboard.Columns";
import { fetchDashboardCounts, getUserDashboardData } from "../../components/UserDashboard/UserDashboard.Requests";

import {
  GLTileNames,
  glStubToTileName,
  glTileNameToStub,
  GLTileNamesStub
} from "../../../GeneralLedger/components/GLDashboard/GLDashboard.Requests";

export interface UserDashboardProps {
  configuration: JemConfiguration["GeneralLedgerApi"];
  mockDashboardDataFn?: MockDataFn<unknown>;
}

const UserDashboardPage: React.FC<UserDashboardProps> = (props) => {
  const { appInsights } = useContext(LoggingContext);
  if (!appInsights) {
    throw new FatalError("Please use a LoggingContext Provider.");
  }

  const navigate = useNavigate();

  const userContext = useContext(UserContext);
  const jemContext = useContext(JEMContext);
  const logger = useContext(LoggingContext);

  const [inputSearchValue, setInputSearchValue] = useState("");
  const [userAlias, setUserAlias] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [adminCheck, setAdminCheck] = useState(false);

  const [searchParams] = useSearchParams();

  const tileName = Object.values(GLTileNamesStub).includes(searchParams.get("tilename") as GLTileNamesStub)
    ? (searchParams.get("tilename") as GLTileNamesStub)
    : GLTileNamesStub.MyDraftJEs;

  useEffect(() => {
    const tileNameFromParams = searchParams.get("tilename") as GLTileNamesStub;
    const tilesAreDifferent = tileNameFromParams !== tileName;
    if (tilesAreDifferent && !Object.values(GLTileNamesStub).includes(tileNameFromParams)) {
      navigate(
        {
          search: `?tilename=${GLTileNamesStub.MyDraftJEs}`
        },
        { replace: true }
      );
    }
  }, [searchParams]);

  const {
    data: dashboardPayload,
    refetch: refetchTileData,
    error,
    isFetching,
    isLoading
  } = useQuery({
    queryKey: ["tiles", tileName, "userAlias", userAlias, "isAdmin", isAdmin],
    queryFn: (opts) => {
      const theTileName = (opts.queryKey as [string, string, string, string])[1] as GLTileNamesStub;
      const fetchTileName = glStubToTileName(theTileName);
      const theUserAlias = (opts.queryKey as [string, string, string, string])[3] as string;

      return getUserDashboardData(
        props.configuration,
        userContext.accessToken,
        fetchTileName,
        theUserAlias,
        `${isAdmin}`
      );
    },
    select: (data) => {
      return data;
    },
    enabled: userAlias.length > 0,
    staleTime: 1000 * 60 * 60
  });

  const {
    data: tiles,
    refetch: refetchTileCounts,
    isFetching: areTilesFetching,
    isLoading: areTilesLoading,
    error: tilesError
  } = useQuery({
    queryKey: ["tiles", "counts", userAlias],
    queryFn: async () => {
      return fetchDashboardCounts(props.configuration, userContext.accessToken, userAlias);
    },
    enabled: userAlias.length > 0,
    staleTime: 0
  });

  const tilesStatus =
    areTilesLoading || areTilesFetching
      ? LoadingStatus.Pending
      : tilesError
      ? LoadingStatus.Rejected
      : LoadingStatus.Resolved;

  const dashboardStatus =
    isLoading || isFetching
      ? LoadingStatus.Pending
      : error !== undefined && error !== null
      ? LoadingStatus.Rejected
      : LoadingStatus.Resolved;

  const currentTile = glStubToTileName(tileName);

  return (
    <>
      <div className={PageStyles.rootDiv}>
        <PageHeading>
          <h2>User Dashboard</h2>
        </PageHeading>
        <div className="ms-Grid" dir="ltr">
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
              <div className="ms-Grid-row" style={{ display: "flex", alignItems: "center" }}>
                <TextField
                  className="ms-Grid-col ms-sm12 ms-md6 ms-lg3 ms-xl3 ms-xxl2"
                  label="User alias"
                  value={inputSearchValue}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onChange={(e: any) => {
                    setInputSearchValue(e.target.value);
                  }}
                  description="Enter correct user alias"
                />
                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3 ms-xl3 ms-xxl2">
                  <Checkbox
                    label="Is Admin"
                    checked={adminCheck}
                    onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
                      setAdminCheck(!!checked);
                    }}
                  />
                </div>
                <PrimaryButton
                  text="Search"
                  allowDisabledFocus
                  onClick={() => {
                    setUserAlias(inputSearchValue);
                    setIsAdmin(adminCheck);
                  }}
                  style={{ top: "5px" }}
                  type="submit"
                  disabled={false}
                />
              </div>
            </div>
          </div>
          {dashboardStatus === LoadingStatus.Pending ? (
            <LoadingSpinner label={`Loading User dashboard Details`} />
          ) : dashboardStatus === LoadingStatus.Rejected ? (
            <Text
              variant="xLarge"
              style={{
                color: "var(--accent-font-color, black)"
              }}
            >
              Error Loading User Dashboard Details
            </Text>
          ) : (
            <>
              <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                  {jemContext.initInfo.values && dashboardPayload !== undefined && (
                    <UserDashboard
                      userAlias={userAlias}
                      configuration={props.configuration}
                      domainData={jemContext.initInfo.values}
                      items={dashboardPayload !== undefined ? dashboardPayload?.items : []}
                      tilesAreLoading={tilesStatus}
                      dataIsLoading={dashboardStatus}
                      {...(tileName === GLTileNamesStub.MyDraftJEs
                        ? {
                            uniqueIdForDashboardsColumnGenerator: "glDraftsDashboardConfig",
                            columnGenerator: getDraftColumns(jemContext.initInfo.values),
                            theIndexFromTheColumnsThatIsInitallySortedWith: 11
                          }
                        : tileName === GLTileNamesStub.NotPostedPostingRequested
                        ? {
                            uniqueIdForDashboardsColumnGenerator: "glNotPostedPostingReqDashboardConfig",
                            columnGenerator: notPostedPostingRequestedColumns(),
                            theIndexFromTheColumnsThatIsInitallySortedWith: 10
                          }
                        : tileName === GLTileNamesStub.PosterPreReviewRequested ||
                          tileName === GLTileNamesStub.PreReviewerActionRequired
                        ? {
                            uniqueIdForDashboardsColumnGenerator: "glPreReviewDashboardConfig",
                            columnGenerator: getPreReviewColumns(jemContext.initInfo.values),
                            theIndexFromTheColumnsThatIsInitallySortedWith: 12
                          }
                        : {
                            uniqueIdForDashboardsColumnGenerator: "PostedSignOffRequested",
                            columnGenerator: getUserColumns(jemContext.initInfo.values),
                            theIndexFromTheColumnsThatIsInitallySortedWith: 14
                          })}
                      tiles={tiles || OrderedMap<string, IIndexedTile>({})}
                      currentTile={currentTile}
                      onTileChange={(tileName: GLTileNames) => {
                        navigate({
                          search: `?tilename=${glTileNameToStub(tileName)}`
                        });
                      }}
                      buttonCalculator={dashboardButtonStates(currentTile)}
                      onAction={async (actionResult) => {
                        logger.addNotification(actionResult.notification);
                        refetchTileCounts();
                        refetchTileData();
                      }}
                    />
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

UserDashboardPage.displayName = "UserDashboardPage";

export default UserDashboardPage;
