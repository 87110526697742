import { MessageBar, MessageBarType, Separator } from "@fluentui/react";
import React, { RefObject, useContext, useEffect, useReducer, useRef } from "react";
import IHCCCreateActions, { CreateActions } from "../../components/IHCCCreate/IHCCCreate.Actions";
import IHCCCreateAttachments, { IHCCCreateAttachmentsRef } from "../../components/IHCCCreate/IHCCCreate.Attachments";
import IHCCCreateComments, {
  CommentErrorMessage,
  IHCCCreateCommentsRef
} from "../../components/IHCCCreate/IHCCCreate.Comments";
import IHCCCreateReadOnly from "../../components/IHCCCreate/IHCCCreate.ReadOnly";
import IHCCCreateReviewers, {
  IHCCCreateReviewersRef,
  ReviewerErrorMessage,
  Reviewers
} from "../../components/IHCCCreate/IHCCCreate.Reviewers";

import { IHCCDraftContext } from "../../contexts/IHCCDraftContext/IHCCDraftContext";
import { CreateActionPayload, IPaymentOrderObject } from "../../contexts/IHCCDraftContext/IHCCDraftContext.actions";
import { useLocation, useNavigate } from "react-router-dom";
import { IAttachmentToPoAttachmentForCreate, PoAttachment } from "../../shared/IHCC.types";
import {
  JemConfiguration,
  MockDataFn,
  IAttachment,
  AttachmentErrorMessage,
  LoadingStatus,
  LocalMessage,
  PageStyles,
  PageHeading,
  redirectMeToTheOrigin,
  delay,
  UserContext,
  ErrorHelper
} from "@jem/components";
import IHCCCreateGrid, {
  IHCCCreateTableRef,
  TableErrorMessage
} from "../../components/IHCCCreate/IHCCCreate.Grid/IHCCCreate.Grid";
import { IPaymentOrderTableObject } from "../../components/IHCCCreate/IHCCCreate.Grid/IHCCCreate.ValidatePaymentOrder";
import { IHCCDraftContextData } from "../../contexts/IHCCDraftContext/IHCCDraftContext.types";

export interface IHCCCreateProps {
  configuration: JemConfiguration["IhccApi"];
  mockDashboardDataFn?: MockDataFn<any>;
}

interface LocalState {
  paymentOrders: IPaymentOrderTableObject[];
  attachments: IAttachment[];
  comments: string;
  isBulk: boolean;
  reviewers: Reviewers;
  tableErrors: TableErrorMessage;
  reviewersError: ReviewerErrorMessage;
  commentsError: CommentErrorMessage;
  attachmentsError: AttachmentErrorMessage;
}

const getState = async (
  attachmentsRef: RefObject<IHCCCreateAttachmentsRef>,
  commentsRef: RefObject<IHCCCreateCommentsRef>,
  reviewersRef: RefObject<IHCCCreateReviewersRef>,
  tableRef: RefObject<IHCCCreateTableRef>
): Promise<LocalState | null> => {
  if (tableRef.current && attachmentsRef.current && commentsRef.current && reviewersRef.current) {
    const [tablePaymentOrders, tableErrorMessage] = await tableRef.current.getData();
    const [attachments, attachmentsErrorMessage] = await attachmentsRef.current.getAttachments();
    const [comments, commentsErrorMessage] = commentsRef.current.getNewComment();
    const isBulk = commentsRef.current.getIsBulk();
    const [reviewers, errorMessage] = await reviewersRef.current.getReviewers();

    return {
      paymentOrders: tablePaymentOrders,
      attachments: attachments.attachments,
      comments,
      isBulk,
      reviewers,
      tableErrors: tableErrorMessage,
      reviewersError: errorMessage,
      commentsError: commentsErrorMessage,
      attachmentsError: attachmentsErrorMessage
    };
  } else {
    return null;
  }
};

async function performActionAsyncAndGetMessageForFrontend(
  draftContext: NonNullable<IHCCDraftContextData>,
  attachmentsRef: RefObject<IHCCCreateAttachmentsRef>,
  commentsRef: RefObject<IHCCCreateCommentsRef>,
  reviewersRef: RefObject<IHCCCreateReviewersRef>,
  tableRef: RefObject<IHCCCreateTableRef>,
  actionName: CreateActions
): Promise<LocalMessage | null> {
  const stateObjects = await getState(attachmentsRef, commentsRef, reviewersRef, tableRef);
  if (!stateObjects) {
    // show fatal error, please refresh page
    return {
      message: "Please refresh page and try again",
      type: MessageBarType.error
    };
  }

  const state = stateObjects;

  // even after checking so much, there can still be errors in attachments
  if (state.attachmentsError) {
    return {
      message: state.attachmentsError,
      type: MessageBarType.error
    };
  }

  // if no comments block action
  if (state.commentsError) {
    return {
      message: state.commentsError,
      type: MessageBarType.error
    };
  }

  if (state.reviewersError) {
    return {
      message: state.reviewersError,
      type: MessageBarType.error
    };
  }

  if (state.tableErrors && actionName === "SubmitForReview") {
    return {
      message: state.tableErrors,
      type: MessageBarType.error
    };
  }

  const actions = draftContext.actions as NonNullable<IHCCDraftContextData["actions"]>;
  const actionType = actionName === "Save" ? "SAVE" : "SUBMIT";
  const paymentOrderList = await convertLocalStateToPaymentOrderObject(state);
  const actionPayload: CreateActionPayload = {
    actionType,
    createdDate: new Date().toISOString(),
    PaymentOrderList: paymentOrderList
  };
  if (actionName === "SubmitForReview") {
    if (state.tableErrors === null) {
      const message = await actions.submitForReview(actionPayload);

      if (message.notification.type !== "Error") {
        await draftContext.reloadDraft();
      }

      return {
        message: message.notification.subjectHeader,
        type: message.notification.type === "Error" ? MessageBarType.error : MessageBarType.success
      };
    }

    await actions.save(actionPayload);
    await draftContext.reloadDraft();
    return {
      message: "Please fix errors on Payment Orders.",
      type: MessageBarType.error
    };
  }

  if (actionName === "Save") {
    if (state.tableErrors === null) {
      const message = await actions.save(actionPayload);
      if (message.notification.type === "Error") {
        return {
          message: message.notification.subjectHeader,
          type: MessageBarType.error
        };
      }
      await draftContext.reloadDraft();
      return {
        message: "Draft saved successfully.",
        type: MessageBarType.success
      };
    }
  }
  return {
    message: "Please fix errors on Payment Orders.",
    type: MessageBarType.error
  };
}

function convertLocalStateToPaymentOrderObject(state: LocalState): Promise<IPaymentOrderObject[]> {
  function processState(state: LocalState): IPaymentOrderObject[] {
    const paymentOrders: IPaymentOrderObject[] = [];
    for (const po of state.paymentOrders) {
      paymentOrders.push({
        actionType: po.actionType,
        addtionalReviewersCSVToDB: state.reviewers.additionalReviewers,
        bankArea: po.bankArea,
        comments: state.comments,
        id: po.id,
        isBulk: state.isBulk,
        orderingPartyAccountNumber: po.orderingPartyAccountNumber,
        poApprovers: [],
        poAttachments: state.attachments.map(IAttachmentToPoAttachmentForCreate) as PoAttachment[],
        processingStatus: po.processingStatus,
        purpose: po.purpose,
        receivingPartyAccountNumber: po.receivingPartyAccountNumber,
        receivingPartyAmountInAccountCurrency: po.receivingPartyAmountInAccountCurrency,
        receivingPartyCurrency: po.receivingPartyCurrency,
        transactionType: po.transactionType,
        refGuid: po.refGuid,
        rowVer: po.rowVer,
        supervisorAliasToDB: state.reviewers.primaryReviewer
      });
    }
    return paymentOrders;
  }

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      try {
        resolve(processState(state));
      } catch (e) {
        reject(e);
      }
    }, 1);
  });
}

interface LoadingState {
  status: LoadingStatus;
  message: LocalMessage | null;
  readOnly: boolean;
}

type LoadingAction = {
  type: "SET_LOADING" | "SET_ERROR";
  status: LoadingStatus;
  message: LocalMessage | null;
  readOnly: boolean;
};

const loadingReducer = (state: LoadingState, action: LoadingAction) => {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        status: action.status,
        message: action.message,
        readOnly: action.readOnly || false
      };
    case "SET_ERROR":
      return {
        ...state,
        status: LoadingStatus.Rejected,
        message: action.message,
        readOnly: true
      };
    default:
      return state;
  }
};

type UseLoadingState = {
  state: LoadingState;
  setLoading: (status: LoadingStatus, message: LocalMessage | null, readOnly: boolean) => void;
  setError: (message: LocalMessage["message"], readOnly?: boolean) => void;
  setIdle: () => void;
  setPending: (message: LocalMessage) => void;
};

const useLoadingState = (): UseLoadingState => {
  const [state, dispatch] = useReducer(loadingReducer, {
    status: LoadingStatus.Idle,
    message: null,
    readOnly: true
  });

  const setLoading = (status: LoadingStatus, message: LocalMessage | null, readOnly: boolean) => {
    dispatch({
      type: "SET_LOADING",
      status,
      message,
      readOnly
    });
  };

  const setError = (message: LocalMessage["message"], readOnly = true) => {
    dispatch({
      type: "SET_ERROR",
      status: LoadingStatus.Rejected,
      message: {
        message,
        type: MessageBarType.error
      },
      readOnly
    });
  };

  const setIdle = () => {
    dispatch({
      type: "SET_LOADING",
      status: LoadingStatus.Idle,
      message: null,
      readOnly: true
    });
  };

  const setPending = (message: LocalMessage) => {
    dispatch({
      type: "SET_LOADING",
      status: LoadingStatus.Pending,
      message: message,
      readOnly: true
    });
  };

  return { state, setLoading, setError, setIdle, setPending };
};

const IHCCCreate: React.FC<IHCCCreateProps> = (props) => {
  const draftContext = useContext(IHCCDraftContext);
  const userContext = useContext(UserContext);
  const { state: loadingStatus, setError, setLoading, setPending } = useLoadingState();
  const attachmentsError = useRef<boolean>(false);
  const attachmentsRef = useRef<IHCCCreateAttachmentsRef>(null);
  const commentsRef = useRef<IHCCCreateCommentsRef>(null);
  const reviewersRef = useRef<IHCCCreateReviewersRef>(null);
  const tableRef = useRef<IHCCCreateTableRef>(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (draftContext.initInfo.status === LoadingStatus.Pending) {
      setPending({
        message: "Loading IHCC Domain Data",
        type: MessageBarType.info
      });
      return;
    }

    if (userContext.jemUser.hasIhccAccess === false) {
      setLoading(
        LoadingStatus.Resolved,
        {
          message: ErrorHelper.getMessage("INF1232"),
          type: MessageBarType.severeWarning
        },
        true
      );
      return;
    }

    if (draftContext.draftModel.status === LoadingStatus.Pending) {
      setPending({
        message: "Loading Draft Data",
        type: MessageBarType.info
      });
      return;
    }

    if (draftContext.draftModel.status === LoadingStatus.Resolved) {
      setLoading(LoadingStatus.Resolved, null, false);
      return;
    }

    if (draftContext.draftModel.status === LoadingStatus.Rejected) {
      setError("Unable to retrieve PO Draft. Please refresh page and try again");
      return;
    }

    if (draftContext.initInfo.status === LoadingStatus.Rejected) {
      setError("Unable to retrieve IHCC Domain information. Please refresh page and try again");
      return;
    }
  }, [draftContext]);

  if (loadingStatus.status === LoadingStatus.Idle) {
    return null;
  }
  return (
    <div className={PageStyles.rootDiv}>
      <PageHeading
        onClose={() => {
          let origin = "/ihcc/dashboard";
          const llocState = location.state as unknown as { origin: string; state: any };
          if (location.state && "origin" in llocState) {
            origin = llocState.origin || origin;
          }
          redirectMeToTheOrigin(navigate, location, 0, origin);
        }}
        closeLabel="Back to Dashboard"
      >
        <h2>Create Payment Orders</h2>
      </PageHeading>
      <div className="ms-Grid" dir="ltr">
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12">
            <IHCCCreateActions
              onAction={async function (actionName: CreateActions): Promise<void> {
                if (!draftContext.actions) {
                  setError("Please refresh page and try again");
                  return;
                }
                // clear action first, just call and refresh
                if (actionName === "Clear") {
                  if (
                    confirm(
                      "Clear Form will permanently delete all the records in the spread sheet. Do you want clear this draft?"
                    )
                  ) {
                    await draftContext.actions.clearForm();
                    await draftContext.reloadDraft();
                  }
                  setLoading(LoadingStatus.Resolved, null, false);
                  return;
                }

                if (attachmentsError.current) {
                  setError("Please fix errors on Attachments.", false);
                  return;
                }
                setPending({
                  message: actionName == "Save" ? "Save in progress" : "Submit for Review in progress",
                  type: MessageBarType.info
                });
                const message = await performActionAsyncAndGetMessageForFrontend(
                  draftContext,
                  attachmentsRef,
                  commentsRef,
                  reviewersRef,
                  tableRef,
                  actionName
                );
                // the delay is necessary for showing messages correctly
                await delay(300);
                setLoading(LoadingStatus.Resolved, message ? message : null, false);
              }}
              disabledPage={loadingStatus.readOnly}
            ></IHCCCreateActions>
          </div>
        </div>
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12">
            <IHCCCreateReadOnly></IHCCCreateReadOnly>
          </div>
        </div>
        {!loadingStatus.message ? null : (
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12">
              <MessageBar
                messageBarType={loadingStatus.message.type}
                isMultiline={false}
                onDismiss={() => setLoading(LoadingStatus.Resolved, null, false)}
                dismissButtonAriaLabel="Close"
              >
                {loadingStatus.message.message}
              </MessageBar>
            </div>
          </div>
        )}
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
            <Separator />
          </div>
        </div>
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg8">
            <IHCCCreateAttachments
              ref={attachmentsRef}
              configuration={props.configuration}
              loadingStatus={loadingStatus.status}
              disabled={loadingStatus.readOnly}
              onAttachmentsChange={(lAttachments, rAttachments, errorMessage) => {
                if (errorMessage) {
                  attachmentsError.current = true;
                } else {
                  attachmentsError.current = false;
                }
              }}
            ></IHCCCreateAttachments>
          </div>
          <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4" key={draftContext.draftModel.status}>
            <IHCCCreateReviewers
              customRef={reviewersRef}
              loadingStatus={loadingStatus.status}
              disabled={loadingStatus.readOnly}
            ></IHCCCreateReviewers>
            <IHCCCreateComments
              customRef={commentsRef}
              loadingStatus={loadingStatus.status}
              disabled={loadingStatus.readOnly}
            ></IHCCCreateComments>
          </div>
        </div>
        <div className="ms-Grid-row" key={draftContext.draftModel.status}>
          <div className="ms-Grid-col ms-sm12">
            <Separator>Payment Orders</Separator>
            <IHCCCreateGrid
              loadingStatus={loadingStatus.status}
              customRef={tableRef}
              disabled={loadingStatus.readOnly}
            />
          </div>
        </div>
        {/* FOR DEV TESTING ONLY */}
        {/* <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12  ms-md12 ms-lg12">
            <Separator>References</Separator>
            <ReferenceTable />
          </div>
        </div> */}
      </div>
    </div>
  );
};

IHCCCreate.displayName = "IHCCCreate";
export default IHCCCreate;
