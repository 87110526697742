import { DefaultButton, Label, PrimaryButton, Stack, TextField, Text } from "@fluentui/react";
import { Form, Formik } from "formik";
import React, { PropsWithChildren, useContext } from "react";

import * as Yup from "yup";

import { JemConfiguration } from "../../../JemConfiguration";
import { gridStyle } from "../../../GeneralLedger/shared/GL.styles";
import { ValidationManager } from "../../../GeneralLedger/validation/ValidationManager";
import { UserContext } from "../../contexts/UserContext/UserContext";
import { DEFAULT_NULL_CHARACTER } from "../../utilities/ApiParsingUtilities";
import { CommonApiErrorResponse } from "../../utilities/ErrorHelper";
import { ApiError } from "../../utilities/ErrorHelpers";
import { ProcessCommonRowProps } from "../../utilities/ProcessCommonRowProps";
import { tryParseJson } from "../../utilities/RequestUtilities";
import { removeDuplicateAliases } from "../../utilities/ValidateReviewerAliasesLocally";
import { ActionResult, ActionTypes } from "../ActionManager/ActionsManager.types";
import { JEMAliasPicker } from "../JEMAliasPicker/JEMAliasPicker";
import { ActionsContext } from "./Actions.ActionsContext";
import { ActionTitle } from "./Actions.ActionTitle";
import { ActionOnSubmitHandlerMap, BaseActionProps, CommonPropsOnRows } from "./Actions.types";

interface AddReviewerProps<ListType, ResultType> extends BaseActionProps<ListType, ResultType> {
  onSubmitHandler: ActionOnSubmitHandlerMap<ListType, ResultType>[ActionTypes.addReviewer];
}

export function getAdditionalReviewers(arr: string[] | null): { reviewer: string | null; restOfReviewers: string[] } {
  if (!arr || arr.length === 0) {
    return { reviewer: null, restOfReviewers: [] };
  }

  const [reviewer, ...restOfReviewers] = arr;
  return { reviewer, restOfReviewers };
}

// Returns a Tuple with the reviewers for the Add Reviewer action from N grid items the user selected
// The first element in the tuple is all reviewers associated for a selection
//   It is usually SUPERVISOR;ADDITIONAL_REVIEWER;ADDITIONAL_REVIEWER;ADDITIONAL_REVIEWER
//   The only required one is SUPERVISOR
// The second element in the tuple is the initial value for the text box the user will use to
//  enter new reviewer aliases
// If they are both null it means the grid items has more than 1 combination of Reviewers
function _calculateReviewersForTextBox<T extends CommonPropsOnRows>(
  items: T[],
  isGeneralLedger: boolean
): [string | null, string | null] {
  const allReviewers = items.reduce((objectsWithReviewers, item) => {
    const newItem = item as { reviewer: string };
    if (isGeneralLedger) {
      const reviewer = newItem.reviewer;
      if (reviewer && typeof reviewer === "string") {
        return objectsWithReviewers.concat(reviewer.split(";"));
      } else if (reviewer && Array.isArray(reviewer)) {
        return objectsWithReviewers.concat(reviewer);
      }
    }
    objectsWithReviewers.push(newItem.reviewer);
    return objectsWithReviewers;
  }, [] as string[]);
  const reviewerSet = new Set(allReviewers);
  if (!isGeneralLedger) {
    if (reviewerSet.size === 1 && "reviewerWithoutSupervisor" in items[0]) {
      const toUseForInitialValueOfTextField = items[0] as unknown as { reviewerWithoutSupervisor: string };
      const initialValue =
        toUseForInitialValueOfTextField.reviewerWithoutSupervisor !== "" &&
        toUseForInitialValueOfTextField.reviewerWithoutSupervisor !== null &&
        toUseForInitialValueOfTextField.reviewerWithoutSupervisor !== undefined &&
        toUseForInitialValueOfTextField.reviewerWithoutSupervisor !== "-" &&
        toUseForInitialValueOfTextField.reviewerWithoutSupervisor !== DEFAULT_NULL_CHARACTER
          ? toUseForInitialValueOfTextField.reviewerWithoutSupervisor
          : null;
      return [allReviewers[0], initialValue];
    }
  }
  const all = Array.from(reviewerSet);
  const firstReviewer = all.length > 0 ? all[0] : null;
  const restOfReviewers = all.length > 1 ? all.slice(1).join(";") : null;
  return [firstReviewer, restOfReviewers];
}

const AddReviewer = <ListType extends CommonPropsOnRows, ResultType extends ActionResult>(
  props: PropsWithChildren<AddReviewerProps<ListType, ResultType>>
) => {
  const { styles } = useContext(ActionsContext);
  const { accessToken } = useContext(UserContext);

  // const [reviewersForTextBox, backupReviewers] = calculateReviewersForTextBox(props.items, !!props.configuration);

  const aliasesForState = ProcessCommonRowProps(props.items);
  const { reviewer, restOfReviewers } = getAdditionalReviewers(aliasesForState.reviewer);

  const initialState = {
    author: "",
    postersTabPoster: aliasesForState.poster || "",
    postersTabBackupPosters: aliasesForState.additionalPosters ? aliasesForState.additionalPosters : [],
    postersTabComments: "",
    reviewersTabReviewer: reviewer || "",
    reviewersTabAdditionalReviewers: restOfReviewers,
    error: ""
  };

  return (
    <>
      <ActionTitle onCloseButton={() => props.onCancel()} name={"Change Additional Reviewers"}></ActionTitle>
      <div className={styles.contentStyles.body}>
        <Formik
          initialValues={initialState}
          onSubmit={async (values, { setSubmitting, setFieldError }) => {
            try {
              setSubmitting(true);
              if (props.configuration) {
                await ValidationManager.ReviewerPermissionValidation({
                  configuration: props.configuration as NonNullable<JemConfiguration["GeneralLedgerApi"]>,
                  accessToken
                })
                  .required()
                  .validate(values, {
                    abortEarly: false
                  });
              }

              const result = await props.onSubmitHandler(
                props.items,
                values.reviewersTabReviewer,
                values.reviewersTabAdditionalReviewers
              );
              setSubmitting(false);
              props.onSubmit(result);
            } catch (error) {
              if (error instanceof Yup.ValidationError) {
                setFieldError("error", error.message);
                return;
              } else if (error instanceof ApiError) {
                const err = tryParseJson(error.message);
                if (!err) {
                  setFieldError("error", error.message);
                } else {
                  const theerror = err as unknown as Error;
                  if ("status" in theerror && "message" in theerror && "errors" in theerror && "changes" in theerror) {
                    const e = theerror as unknown as CommonApiErrorResponse;
                    const messagesWithoutDuplicates = Array.from(new Set(e.errors.map((x) => x.message)));
                    setFieldError("error", messagesWithoutDuplicates.join("\n"));
                  }
                }
                setSubmitting(false);
                return;
              } else if (error instanceof Error) {
                setFieldError("error", error.message);
              }
              setSubmitting(false);
              return;
            }
          }}
          validateOnMount={true}
          validationSchema={ValidationManager.PosterAndReviewerOfflineValidation(initialState, {
            posterRequired: false,
            reviewerRequired: aliasesForState.isPosted ? false : true,
            reviewerIsAuthorCheck: false,
            additionalReviewersIsAuthorCheck: false,
            posterCommentsRequired: false,
            backupPostersRequired: false,
            backupReviewersRequired: aliasesForState.isPosted,
            preReviewerRequired: false,
            preReviewerIsAuthorCheck: false
          })}
        >
          {(formik) => (
            <Form onSubmit={formik.handleSubmit}>
              <Stack
                {...{
                  tokens: {
                    childrenGap: 10,
                    padding: 10
                  }
                }}
              >
                {formik.values.reviewersTabReviewer ? (
                  <Stack.Item>
                    <TextField
                      readOnly
                      disabled
                      ariaLabel="Current Reviewers"
                      label="Reviewers:"
                      value={formik.values.reviewersTabReviewer}
                    />
                  </Stack.Item>
                ) : null}
                <Stack.Item>
                  <JEMAliasPicker
                    label="Backup Reviewers"
                    required={true}
                    disabled={false}
                    initialAliases={formik.values.reviewersTabAdditionalReviewers}
                    onChange={(value) => {
                      // remember to remove duplicates from the list
                      const unique = removeDuplicateAliases(value);
                      formik.setFieldValue("reviewersTabAdditionalReviewers", unique);
                    }}
                    errorMessage={
                      formik.errors.reviewersTabAdditionalReviewers
                        ? Array.isArray(formik.errors.reviewersTabAdditionalReviewers)
                          ? formik.errors.reviewersTabAdditionalReviewers.join("\n")
                          : formik.errors.reviewersTabAdditionalReviewers
                        : undefined
                    }
                  />
                  <Text
                    nowrap
                    variant="xSmall"
                    block
                    style={{
                      marginTop: "4px",
                      color: "var(--accent-font-color, black)"
                    }}
                  >
                    A maximum of 75 additional reviewers are supported.
                  </Text>
                </Stack.Item>
                {formik.values.postersTabPoster ? (
                  <Stack.Item>
                    <JEMAliasPicker
                      label="Poster"
                      required={false}
                      disabled={true}
                      initialAliases={formik.values.postersTabPoster ? [formik.values.postersTabPoster] : []}
                    />
                  </Stack.Item>
                ) : null}
                <Stack.Item>
                  {formik.errors.error ? (
                    <Label
                      styles={{
                        root: {
                          color: "red"
                        }
                      }}
                    >
                      {formik.errors.error}
                    </Label>
                  ) : null}
                </Stack.Item>
                <Stack.Item>
                  <Stack horizontal {...{ tokens: gridStyle }}>
                    <Stack.Item align="end">
                      <PrimaryButton text={"Ok"} type="submit" disabled={!formik.isValid || formik.isSubmitting} />
                    </Stack.Item>
                    <Stack.Item align="end">
                      <DefaultButton
                        onClick={(event) => {
                          event.stopPropagation();
                          props.onCancel();
                        }}
                        text="Cancel"
                      />
                    </Stack.Item>
                  </Stack>
                </Stack.Item>
              </Stack>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

AddReviewer.displayName = "AddReviewer";
export { AddReviewer };
