import React from "react";
import { useNavigate } from "react-router-dom";
import { JECreateExistingForm, JECreateExistingValues } from "./JECreateExistingForm";

const ExistingJEScenario: React.FC = () => {
  const navigate = useNavigate();

  const handleSubmit = (values: JECreateExistingValues) => {
    console.log("ExistingJEScenario handleSubmit", values);
    navigate("newje-microfrontend", { state: values });
  };

  return (
    <>
      <JECreateExistingForm onSubmit={handleSubmit} />
    </>
  );
};

export default ExistingJEScenario;
