import { Theme, TooltipHost, IconButton, Label, Stack, Text, TextField } from "@fluentui/react";
import { css } from "@emotion/css";
import { Field, useFormikContext } from "formik";
import React from "react";
import { LoadingStatus } from "../../../Shared";
import { gridStyle } from "../../shared";
import { GLPageState } from "./GLCreate.State";
import { GLCreateFormikState } from "./GLCreateForm.types";

export interface JETitleProps {
  theme: Theme;
  state: GLPageState;
  disabled: boolean;
  loadingStatus: LoadingStatus;
  onNavigate: () => void;
}

const JETitle: React.FC<JETitleProps> = (props) => {
  const formik = useFormikContext<GLCreateFormikState>();

  return (
    <>
      <Stack horizontal {...{ tokens: gridStyle }}>
        <Stack.Item align="center">
          <TooltipHost
            content="Back to Dashboard"
            // This id is used on the tooltip itself, not the host
            // (so an element with this id only exists when the tooltip is shown)
            calloutProps={{ gapSpace: 0 }}
            styles={{ root: { display: "inline-block" } }}
            setAriaDescribedBy={false}
          >
            <IconButton
              aria-label="Back to Dashboard"
              iconProps={{
                iconName: "ArrowUpRightMirrored8",
                styles: {
                  root: {
                    transform: "rotate(-45deg)"
                  }
                }
              }}
              onClick={props.onNavigate}
            />
          </TooltipHost>
        </Stack.Item>
        <Stack.Item
          align="center"
          {...{
            tokens: gridStyle
          }}
        >
          <div
            className={css(`
                    display: flex;
                    flex-direction: row;
                    gap: 8px;
                    align-items: center;
                  `)}
          >
            <Label htmlFor={"theHeaderName"}>
              <Text
                className={css`
                  display: block;
                  margin-top: 0 !important;
                  line-height: 2.25em;
                  margin-top: -4px;
                `}
                variant="xxLarge"
                aria-label={`JE ${props.state.createState.jeName}`}
              >
                JE:
              </Text>
            </Label>
            <Field
              id={"theHeaderName"}
              as={TextField}
              name="headerJeName"
              type="input"
              styles={{
                root: {
                  height: "73px",
                  display: "flex",
                  alignItems: "center",
                  marginTop: "4px",
                  gap: "8px"
                }
              }}
              onChange={(_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                if (!newValue || newValue.length === 0) {
                  formik.setFieldValue("headerJeName", props.state.createState.jeName);
                } else if (!newValue.includes("+") && !newValue.includes("%")) {
                  formik.setFieldValue("headerJeName", newValue);
                }
              }}
              disabled={props.disabled || props.loadingStatus !== LoadingStatus.Resolved}
              errorMessage={props.disabled ? "" : formik.errors.headerJeName}
            />
          </div>
        </Stack.Item>
        <Stack.Item align="center" grow>
          {props.disabled || props.loadingStatus !== LoadingStatus.Resolved ? (
            <Text
              className={css`
                display: block;
                color: ${props.theme.palette.themePrimary};
                float: right;
              `}
              variant="xSmall"
              aria-label={`Page is ${
                props.disabled ? "READONLY" : props.loadingStatus !== LoadingStatus.Resolved ? "LOADING" : ""
              }`}
            >
              {props.disabled ? "READONLY" : props.loadingStatus !== LoadingStatus.Resolved ? "LOADING" : ""}
            </Text>
          ) : null}
        </Stack.Item>
      </Stack>
    </>
  );
};

JETitle.displayName = "JETitle";

export { JETitle };
