import { useState, useEffect } from "react";
import { DomainDataEnum, DomainDataObjects } from "../../../Shared/contexts/JEMContext/JEMContext.domainData.types";
import { isButtonEnabled } from "../../../Shared/utilities/WeightageCalculator";

export enum GLCreateActionsEnum {
  Save = "Save",
  Post = "Post",
  SendToPoster = "SendToPoster",
  Validate = "Validate",
  NeedsClarification = "NeedsClarification",
  AttachFile = "AttachFile",
  NoActivity = "NoActivity",
  Clear = "Clear",
  PreReview = "PreReview"
}

export type GLCreateIsButtonEnabled = Omit<
  { [key in GLCreateActionsEnum]: boolean },
  "AttachFile" | "NoActivity" | "Clear"
>;

function recalculateWeightage(
  rowWeightage: number,
  domainData: Pick<DomainDataObjects, DomainDataEnum.StatusActions | DomainDataEnum.JeStatus>
): GLCreateIsButtonEnabled {
  const saveAction = domainData[DomainDataEnum.StatusActions].find((action) => action.actionDesc === "Save");
  const postAction = domainData[DomainDataEnum.StatusActions].find((action) => action.actionDesc === "Post");
  const sendToPosterAction = domainData[DomainDataEnum.StatusActions].find(
    (action) => action.actionDesc === "SendToPoster"
  );
  const validateAction = domainData[DomainDataEnum.StatusActions].find((action) => action.actionDesc === "Validate");
  const needsClarificationAction = domainData[DomainDataEnum.StatusActions].find(
    (action) => action.actionDesc === "DraftClarify"
  );
  const preReviewAction = domainData[DomainDataEnum.StatusActions].find((action) => action.actionDesc === "PreReview");

  const isEnabledFromWeightage = {
    [GLCreateActionsEnum.Save]: rowWeightage === -1 || isButtonEnabled(saveAction?.weightage || 0, rowWeightage),
    [GLCreateActionsEnum.Post]: rowWeightage === -1 || isButtonEnabled(postAction?.weightage || 0, rowWeightage),
    [GLCreateActionsEnum.SendToPoster]:
      rowWeightage === -1 || isButtonEnabled(sendToPosterAction?.weightage || 0, rowWeightage),
    [GLCreateActionsEnum.Validate]:
      rowWeightage === -1 || isButtonEnabled(validateAction?.weightage || 0, rowWeightage),
    [GLCreateActionsEnum.NeedsClarification]:
      rowWeightage === -1 || isButtonEnabled(needsClarificationAction?.weightage || 0, rowWeightage),
    [GLCreateActionsEnum.PreReview]:
      rowWeightage === -1 || isButtonEnabled(preReviewAction?.weightage || 0, rowWeightage)
  };
  return isEnabledFromWeightage;
}

function useWeightages(
  rowWeightage,
  domainData: Pick<DomainDataObjects, DomainDataEnum.StatusActions | DomainDataEnum.JeStatus>
) {
  const [isEnabledFromWeightage, setIsEnabledFromWeightage] = useState<GLCreateIsButtonEnabled>(() => {
    return recalculateWeightage(rowWeightage, domainData);
  });

  useEffect(() => {
    const newWeightages = recalculateWeightage(rowWeightage, domainData);
    setIsEnabledFromWeightage(newWeightages);
  }, [rowWeightage, domainData]);

  return isEnabledFromWeightage;
}

export { useWeightages };
