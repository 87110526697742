import { ITag, TagPicker } from "@fluentui/react";
import React from "react";

interface FilterPickerProps {
  availableFilters: { name: string; filterNameForUI: string }[];
  onFilterRemoved: (filterName: string) => void;
}

const FilterPicker: React.FC<FilterPickerProps> = ({ availableFilters, onFilterRemoved }) => {
  const tags = availableFilters.map((mapping) => ({
    key: mapping.name,
    name: mapping.filterNameForUI
  }));

  const handleTagPickerChange = (items?: ITag[]): void => {
    if (items) {
      const removedTags = tags.filter((tag) => !items.find((item) => item.key === tag.key));
      removedTags.forEach((tag) => onFilterRemoved(tag.key as string));
    }
  };
  return (
    <div>
      <TagPicker
        onResolveSuggestions={() => []}
        inputProps={{
          disabled: true,
          hidden: true
        }}
        selectedItems={tags}
        onChange={handleTagPickerChange}
        onRenderSuggestionsItem={(props: ITag) => <div style={{ display: "none" }}>{props.name}</div>}
      />
    </div>
  );
};

FilterPicker.displayName = "FilterPicker";
export { FilterPicker };
