import { FilterState, FilterSchema } from "@jem/components";
import { SanitizedRow } from "./IHCCDashboard.types";

// eslint-disable-next-line
const toCamelCase = require("lodash/camelCase");

function filterOnSearchCriteria(searchCriteria: string | null, gridItems: SanitizedRow[]) {
  if (searchCriteria !== null && searchCriteria !== "") {
    const newItems: SanitizedRow[] = [];
    for (const row of gridItems) {
      for (const [type, value] of Object.entries(row)) {
        // everything is a string, if it is a number then avoid
        if (typeof value === "number" || typeof value === "boolean" || typeof value === "object") {
          continue;
        }

        const colKey = type.toLowerCase();
        if (colKey.indexOf("time") !== -1) {
          const theDate = new Date(value).toLocaleDateString();
          if (theDate && theDate.toLowerCase().indexOf(searchCriteria.toLowerCase()) !== -1) {
            newItems.push(row);
            break;
          }
        } else {
          if (value && value.toLowerCase().indexOf(searchCriteria.toLowerCase()) !== -1) {
            newItems.push(row);
            break;
          }
        }
      }
    }
    return newItems;
  }
  return gridItems;
}

function filterOnCommandBarFilters(
  filtersCriteria: FilterState["filterBar"],
  filters: FilterSchema<SanitizedRow>[],
  gridItems: SanitizedRow[]
) {
  let newItems: SanitizedRow[] = gridItems;
  for (const filterType of filters) {
    const camelCaseFilterName = toCamelCase(filterType.filterName);
    const filterValue = filtersCriteria[`${camelCaseFilterName}Selected` as keyof FilterState["filterBar"]];
    if (filterValue !== null) {
      if (!filterValue.length) {
        // user removed all items
        return [];
      } else {
        newItems = newItems.filter((row) => {
          const filterRowName = filterType.columnName;
          if (filterType.filterName.indexOf("dueDate") !== -1 && row[filterRowName as keyof SanitizedRow]) {
            const theDate = row[filterRowName as keyof SanitizedRow] as string;
            return filterValue.indexOf(theDate) !== -1;
          } else {
            const cell = row[filterRowName as keyof SanitizedRow];
            return filterValue.indexOf(cell as string) !== -1;
          }
        });
      }
    }
  }
  return newItems;
}

function filterBasedOnTile(currentTile: string | null, gridItems: SanitizedRow[]): SanitizedRow[] {
  if (currentTile) {
    return gridItems.filter((row) => {
      if (!row.txtStatus) return false;
      return row.txtStatus.toLowerCase().indexOf(currentTile.toLowerCase()) !== -1;
    });
  }
  return gridItems;
}

export function ihccFilterRows(
  filterState: FilterState,
  filters: FilterSchema<SanitizedRow>[],
  gridItems: SanitizedRow[]
): SanitizedRow[] {
  let newItems = gridItems;
  newItems = filterBasedOnTile(filterState.currentTile, newItems);
  newItems = filterOnCommandBarFilters(filterState.filterBar, filters, newItems);
  newItems = filterOnSearchCriteria(filterState.searchCriteria, newItems);
  return newItems;
}
