import { TooltipHost, Text, Theme } from "@fluentui/react";
import { IExtendedColumn, SignificanceTypeAndIcon } from "@jem/components";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { significanceIconMapping } from "../../../Shared/utilities/significanceIconMapping";

import { SanitizedRow } from "../IHCCDashboard/IHCCDashboard.types";
import { SearchState } from "./IHCCSearch.types";

interface IHCCSearchColumn extends IExtendedColumn {
  fieldName: keyof Omit<SanitizedRow, "actionEnabled">;
}

export const getIHCCSearchColumns =
  (location: ReturnType<typeof useLocation>, searchState: null | SearchState, theme: Theme) =>
  (): IExtendedColumn[] => {
    const cols: IHCCSearchColumn[] = [
      {
        key: "poNumber",
        name: "PO Number",
        fieldName: "poNumber",
        type: "string",
        minWidth: 60,
        onRender: (item: SanitizedRow): JSX.Element | React.ReactText | null => {
          return (
            <Link
              to={`/ihcc/podetails/${item.poId}`}
              state={{
                origin: location.pathname,
                state: {
                  searchState
                }
              }}
            >
              {item.poNumber}
            </Link>
          );
        }
      },
      {
        key: "significance",
        name: "!",
        fieldName: "significance",
        nameForExport: "Significance",
        type: "string",
        minWidth: 10,
        onRender: (item: SanitizedRow) => {
          const typeAndIcon = SignificanceTypeAndIcon(item.significance, significanceIconMapping);
          return <TooltipHost content={typeAndIcon.significanceType}>{typeAndIcon.icon}</TooltipHost>;
        },
        onExport: (item: SanitizedRow) => {
          const typeAndIcon = SignificanceTypeAndIcon(item.significance, significanceIconMapping);
          return typeAndIcon.significanceType;
        }
      },
      {
        key: "opAccount",
        name: "OP Account",
        fieldName: "opAccount",
        type: "string",
        minWidth: 65
      },
      {
        key: "jemPoNumber",
        name: "JEM_PO number",
        fieldName: "jemPoNumber",
        type: "string",
        minWidth: 65
      },
      {
        key: "rpAccount",
        name: "RP Account",
        fieldName: "rpAccount",
        type: "string",
        minWidth: 65
      },
      {
        key: "transType",
        name: "Trans Type",
        fieldName: "transType",
        type: "string",
        minWidth: 40
      },
      {
        key: "transDesc",
        name: "Trans Desc",
        fieldName: "transDesc",
        type: "string",
        minWidth: 100
      },
      {
        key: "statusCode",
        name: "Status",
        fieldName: "statusCode",
        type: "string",
        minWidth: 100
      },
      {
        key: "sapIndicator",
        name: "SAP Info",
        fieldName: "isSapInfo",
        type: "string",
        minWidth: 50
      },
      {
        key: "requestor",
        name: "Requestor",
        fieldName: "requestor",
        type: "string",
        minWidth: 50
      },
      {
        key: "reviewer",
        name: "Reviewer",
        fieldName: "reviewer",
        type: "string",
        minWidth: 100
      },
      {
        key: "companyCode",
        name: "Co Code",
        fieldName: "companyCode",
        type: "number",
        minWidth: 40
      },
      {
        key: "fiscalYear",
        name: "FY",
        fieldName: "fiscalYear",
        type: "number",
        minWidth: 35
      },
      {
        key: "fiscalPeriod",
        name: "FP",
        fieldName: "fiscalPeriod",
        type: "number",
        minWidth: 20
      },
      {
        key: "bankArea",
        name: "Bank Area",
        fieldName: "bankArea",
        type: "string",
        minWidth: 40
      },
      {
        key: "format",
        name: "Format",
        fieldName: "format",
        type: "string",
        minWidth: 50
      },
      {
        key: "reversalInd",
        name: "Reversal Ind",
        fieldName: "reversalInd",
        type: "string",
        minWidth: 20
      },
      {
        key: "comment",
        name: "Comments",
        fieldName: "comment",
        type: "string",
        minWidth: 80
      },
      {
        key: "dueDate",
        name: "Release Date",
        fieldName: "dueDate",
        type: "date",
        minWidth: 60
      },
      {
        key: "actualReviewer",
        name: "Actual Reviewer",
        fieldName: "actualReviewer",
        type: "string",
        minWidth: 80
      },
      {
        key: "reviewedDate",
        name: "Reviewed Date",
        fieldName: "reviewedDate",
        type: "date",
        minWidth: 60
      },
      {
        key: "poAmount",
        name: "PO Amount",
        fieldName: "rpAmountInAccountCurrency",
        type: "date",
        minWidth: 60
      },
      {
        key: "transCurrency",
        name: "Trans Currency",
        fieldName: "receivingPartyCurrency",
        type: "date",
        minWidth: 60
      },
      {
        key: "poCreationDate",
        name: "PO Creation Date",
        fieldName: "postingDate",
        type: "date",
        minWidth: 60
      },
      {
        key: "tenantName",
        name: "Tenant",
        fieldName: "tenantName",
        type: "string",
        minWidth: 50
      },
      {
        key: "processingStatus",
        name: "Processing Status",
        fieldName: "processingStatus",
        type: "string",
        minWidth: 60,
        onRender: (item: SanitizedRow) => {
          const procColor = item.procStatType == "Success" ? theme.palette.green : theme.palette.red;
          return (
            <Text
              variant="small"
              style={{
                color: procColor
              }}
            >
              <TooltipHost content={item.processingStatus}>{item.processingStatus}</TooltipHost>
            </Text>
          );
        }
      }
    ];
    return cols;
  };
