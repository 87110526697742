import React, { forwardRef, useImperativeHandle, useState } from "react";
import { css } from "@emotion/css";
import { PageHeading, IPageHeadingProps } from "../PageHeading/PageHeading";

const overlayStyle = css`
  width: 100%;
  background: white;
  overflow-y: auto;
  &[data-state="closed"] {
    visibility: hidden;
    opacity: 0;
  }
  &[data-state="open"] {
    visibility: visible;
  }
`;

interface IPageOverlayRef {
  open: () => void;
  close: () => void;
}

export interface IPageOverlay {
  props: {
    label: string;
    closeLabel: string;
    onClose?: () => void;
    children: React.ReactNode;
  };
  ref: IPageOverlayRef;
}

export enum OverlayState {
  closed = "closed",
  open = "open"
}

const PageOverlay = forwardRef((props: IPageOverlay["props"], ref: React.Ref<IPageOverlay["ref"]>) => {
  const [currentState, setCurrentState] = useState<OverlayState>(OverlayState.closed);

  useImperativeHandle(ref, () => ({
    open() {
      setCurrentState(OverlayState.open);
    },
    close() {
      setCurrentState(OverlayState.closed);
    }
    // setMessage(type, messages) {
    //   // if (notificationsRef.current) {
    //   //   notificationsRef.current.setMessage(type, messages);
    //   // }
    // },
    // reset() {
    //   // if (notificationsRef.current) {
    //   //   notificationsRef.current.reset();
    //   // }
    // }
  }));

  const onClose: IPageHeadingProps["onClose"] = () => {
    setCurrentState(OverlayState.closed);
    if (props.onClose) {
      props.onClose();
    }
  };

  return (
    <div className={overlayStyle} data-state={currentState}>
      <PageHeading onClose={onClose} closeLabel={props.closeLabel}></PageHeading>
      {props.children}
    </div>
  );
});

PageOverlay.displayName = "PageOverlay";

export { PageOverlay };
