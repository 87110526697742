import React, { PropsWithChildren, useContext, useEffect } from "react";
import { ActionResult, ActionTypes } from "../ActionManager/ActionsManager.types";
import { LoadingSpinner } from "../LoadingSpinner/LoadingSpinner";
import { ActionsContext } from "./Actions.ActionsContext";
import { ActionTitle } from "./Actions.ActionTitle";
import { ActionOnSubmitHandlerMap, BaseActionProps, CommonPropsOnRows } from "./Actions.types";

interface DeleteProps<ListType, ResultType> extends BaseActionProps<ListType, ResultType> {
  onSubmitHandler: ActionOnSubmitHandlerMap<ListType, ResultType>[ActionTypes.deleteAction];
}

const Delete = <ListType extends CommonPropsOnRows, ResultType extends ActionResult>(
  props: PropsWithChildren<DeleteProps<ListType, ResultType>>
) => {
  const { styles } = useContext(ActionsContext);
  useEffect(() => {
    const trigger = async () => {
      const result = await props.onSubmitHandler(props.items);
      props.onSubmit(result);
    };
    trigger();
  }, []);
  return (
    <>
      <ActionTitle onCloseButton={() => props.onCancel()} name={"Deleting"}></ActionTitle>
      <div className={styles.contentStyles.body}>
        {/* A Loading Spinner saying "Deleting your Selection" <CheckBoxSubmission {...settings.configuration} onSubmit={onSubmit} /> */}
        <LoadingSpinner label="Deleting" />
      </div>
    </>
  );
};

Delete.displayName = "Delete";

export { Delete };
