import { JEMContext, LoadingSpinner, LoadingStatus, PageStyles } from "@jem/components";
import React, { useContext, useEffect, useState } from "react";

import { Pivot, PivotItem } from "@fluentui/react";
import NewJEScenario from "../../components/JECreate/NewJEScenario";
import ExistingJEScenario from "../../components/JECreate/ExistingJEScenario";

const JECreate: React.FC = () => {
  const [domainDataStatus, setDomainDataStatus] = useState<LoadingStatus>(LoadingStatus.Idle);
  const jemContext = useContext(JEMContext);

  useEffect(() => {
    if (
      jemContext.initInfo.status === LoadingStatus.Rejected ||
      jemContext.initInfo.status === LoadingStatus.Resolved
    ) {
      setDomainDataStatus(LoadingStatus.Resolved);
    }
  }, [jemContext.initInfo.values]);

  if (domainDataStatus !== LoadingStatus.Resolved || jemContext.initInfo.values === null) {
    return <LoadingSpinner label="Loading Domain Data" />;
  }

  return (
    <>
      <div className={PageStyles.rootDiv}>
        <Pivot aria-label="JE Tabs">
          <PivotItem headerText="Create New JE">
            <NewJEScenario />
          </PivotItem>
          <PivotItem headerText="Reference Existing JE">
            <ExistingJEScenario />
          </PivotItem>
        </Pivot>
      </div>
    </>
  );
};

JECreate.displayName = "JECreate";
export { JECreate };
