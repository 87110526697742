import { ApiError, getRequest, getValidUrl, IUserProviderState, JemConfiguration } from "@jem/components";

export interface ICalendarInfo {
  description: string;
  isCurrent: string;
  legend: string;
  class: string;
  eventDate: string;
}
export interface IFiscalPeriod {
  period: string;
  cummulativeMonth: string;
  month: string;
  year: string;
}
export interface ICloseCalendarInfo {
  calendarInfo: ICalendarInfo[];
  fiscalPeriod: IFiscalPeriod[];
}
export interface ICombinedCalendarInfo {
  regularCalendarInfo: ICloseCalendarInfo;
  ihccCalendarInfo: ICloseCalendarInfo;
}

export async function getCalendarInfo(
  configuration: JemConfiguration["GeneralLedgerApi"],
  getTokenFn: IUserProviderState["accessToken"]
): Promise<ICombinedCalendarInfo> {
  const endpoint = `${configuration.baseApiUrl}${configuration.endpoints.getCalendarInfo}`;
  const endpointUrl = getValidUrl(endpoint);

  const regCalendarInfo = await getRequest<any>(endpointUrl, getTokenFn);
  if (!regCalendarInfo) {
    throw new ApiError("No response from server");
  }

  const ihccCalendarInfo = await getIhccCalendarInfo(configuration, getTokenFn);
  if (!regCalendarInfo) {
    throw new ApiError("No response from server");
  }

  return {
    regularCalendarInfo: regCalendarInfo,
    ihccCalendarInfo: ihccCalendarInfo
  } as ICombinedCalendarInfo;
}

export async function getIhccCalendarInfo(
  configuration: JemConfiguration["GeneralLedgerApi"],
  getTokenFn: IUserProviderState["accessToken"]
): Promise<any> {
  const endpoint = `${configuration.baseApiUrl}${configuration.endpoints.getIhccCalendarInfo}`;
  const endpointUrl = getValidUrl(endpoint);
  const response = await getRequest<any>(endpointUrl, getTokenFn);

  if (!response) {
    throw new ApiError("No response from server");
  }

  return response;
}
