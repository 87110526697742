import { css } from "@emotion/css";
import {
  Text,
  SelectionMode,
  Separator,
  Stack,
  Dropdown,
  IDropdownStyles,
  IDropdownOption,
  MessageBar,
  MessageBarType
} from "@fluentui/react";
import {
  ActionsContext,
  ActionTitle,
  DashboardGrid,
  exportToExcel,
  fyFp,
  IDashboardGridRef,
  IUserProviderState,
  JemConfiguration,
  LoadingSpinner,
  LoadingStatus,
  LocalMessage,
  OpsDashboardApi,
  TextFieldSubmission,
  UserContext
} from "@jem/components";
import React, { useContext, useRef, useState } from "react";
import { ReconProcessSearchOptions } from "../../components/ReconFYFPSelector/ReconFYFPSelector";
import { getReconGridColumns } from "./ReconProcess.Columns";
import {
  IReconMessage,
  IReconMissingJEsData,
  checkReconStatus,
  getMissingJEs,
  getReconLogFile,
  startRecon,
  syncJEs,
  updateSystemUsers
} from "./ReconProcess.requests";
import { ReconProcessCommandBar } from "../../../MySettings/components/DelegateReviewers/ReconProcessCommandBar";
import ReconProcessAttachments, { IReconProcessAttachmentsRef } from "./ReconProcess.Attachments";

// eslint-disable-next-line
const isEqual = require("lodash/isEqual");

const noMargins = {
  margin: "0px",
  selectors: {
    ":hover": {
      margin: "0px"
    },
    ":focus": {
      margin: "0px"
    }
  }
};
const dropdownStyles: Partial<IDropdownStyles> = {
  dropdown: { width: "180px" },
  root: {
    display: "flex",
    flexDirection: "horizontal",
    flexWrap: "nowrap",
    justifyContent: "center"
  },
  dropdownItem: noMargins,
  dropdownItemSelected: noMargins,
  dropdownItemDisabled: noMargins,
  dropdownItemSelectedAndDisabled: noMargins,
  label: {
    paddingRight: "8px"
  }
};

const fiscalYearOptions: IDropdownOption[] = Array.from({ length: 10 }, (_, i) => {
  const newFy = fyFp.fiscalYear - 9 + i;
  return {
    key: `${i}`,
    text: `${newFy}`
  };
});

const fiscalPeriodOptions: IDropdownOption[] = Array.from({ length: 12 }, (_, i) => {
  return {
    key: `${i}`,
    text: `${i + 1}`
  };
});

export interface ReconProcessProps {
  configuration: OpsDashboardApi;
  attachmentsConfiguration: JemConfiguration["DocumentsApi"];
  getTokenFn?: IUserProviderState["accessToken"];
}

export interface IReconState {
  fileLocation: string;
  fiscalYear: string;
  fiscalPeriod: string;
  openUpdateSysUsersPanel: boolean;
}

let ReconProcess: React.FC<ReconProcessProps> = (props) => {
  const { styles } = useContext(ActionsContext);
  const userContext = useContext(UserContext);
  const dashboardGridRef = useRef<IDashboardGridRef>(null);
  const [gridItems, setGridItems] = useState<IReconMissingJEsData[]>([]);
  const [dashboardGridStatus, setDashboardGridStatus] = useState<LoadingStatus>(LoadingStatus.Idle);
  const [reconState, setReconSate] = useState<IReconState>({
    fileLocation: "",
    fiscalYear: fiscalYearOptions[fiscalYearOptions.findIndex((x) => x.text === `${fyFp.fiscalYear}`)].text,
    fiscalPeriod: fiscalPeriodOptions[fiscalPeriodOptions.findIndex((x) => x.text === `${fyFp.fiscalMonth}`)].text,
    openUpdateSysUsersPanel: false
  });
  const [newSysUsers, setNewSysUsers] = useState<string>("");
  const [message, setMessage] = useState<LocalMessage | null>(null);

  const attachmentsRef = useRef<IReconProcessAttachmentsRef>(null);

  const performSyncJEs = async () => {
    try {
      if (dashboardGridRef.current) {
        const selection = dashboardGridRef.current.getSelection();
        const items = selection.isAllSelected ? gridItems : (selection.items as IReconMissingJEsData[]);
        const jesSynced = await syncJEs(props.configuration, userContext.accessToken, items);
        console.log("jesSynced", jesSynced);
        if (jesSynced) {
          setMessage({
            message: "You have successfully synced the JE(s).",
            type: MessageBarType.success
          });
        } else {
          setMessage({
            message: "Failed to sync JE(s).",
            type: MessageBarType.error
          });
        }
      }
    } catch (err) {
      setDashboardGridStatus(LoadingStatus.Rejected);
    }
  };

  const getReconLogFileAction = async () => {
    try {
      const fiscalYear = reconState ? reconState.fiscalYear : "";
      const fiscalPeriod = reconState ? reconState.fiscalPeriod.padStart(2, "0") : "";
      const fileName = `FY${fiscalYear}P${fiscalPeriod}_Log.TXT`;
      const response = await getReconLogFile(
        props.attachmentsConfiguration,
        userContext.accessToken,
        fiscalYear,
        fiscalPeriod,
        fileName
      );
      if ("blob" in response) {
        const blob: Blob = await (response as File & { blob: any }).blob();

        // FileDownload(blob, fileName);
        // BEHOLD: SUPER HACK FOR DEALING WITH THE FLUENT DIALOG
        const blobUrl = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = blobUrl;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        // link.click() won't work on Dialog
        link.dispatchEvent(new MouseEvent("click"));
        URL.revokeObjectURL(blobUrl);
        if (link.parentNode) link.parentNode.removeChild(link);
        setMessage({
          message: "You have downloaded the log file.",
          type: MessageBarType.success
        });
      }
    } catch (err) {
      setMessage({
        message: "Error in downloading file",
        type: MessageBarType.error
      });
      setDashboardGridStatus(LoadingStatus.Rejected);
    }
  };

  const getMissingJEsAction = async () => {
    setDashboardGridStatus(LoadingStatus.Pending);
    try {
      const payload: ReconProcessSearchOptions = {
        fiscalYear: reconState ? Number(reconState.fiscalYear) : 0,
        fiscalPeriod: reconState ? Number(reconState.fiscalPeriod) : 0
      };
      const missingJEs = await getMissingJEs(props.configuration, userContext.accessToken, payload);
      console.log("reconStarted", missingJEs);
      setGridItems(missingJEs);
      setDashboardGridStatus(LoadingStatus.Resolved);
    } catch (err) {
      setDashboardGridStatus(LoadingStatus.Rejected);
    }
  };

  const getReconStatus = async () => {
    try {
      const payload: ReconProcessSearchOptions = {
        fiscalYear: reconState ? Number(reconState.fiscalYear) : 0,
        fiscalPeriod: reconState ? Number(reconState.fiscalPeriod) : 0
      };
      const reconStatus = await checkReconStatus(props.configuration, userContext.accessToken, payload);
      console.log("reconStarted", reconStatus);

      if (reconStatus.toUpperCase() === "COMPLETED") {
        setMessage({
          message: "Recon has successfully completed.",
          type: MessageBarType.success
        });
      } else if (reconStatus.toUpperCase() === "PROCESSING") {
        setMessage({
          message: "The recon is still processing.",
          type: MessageBarType.warning
        });
      } else {
        setMessage({
          message: "Failed to start the recon process.",
          type: MessageBarType.error
        });
      }
    } catch (err) {
      setDashboardGridStatus(LoadingStatus.Rejected);
    }
  };

  const performStartRecon = async () => {
    try {
      console.log("reconState", reconState);
      const reconId = "FY" + reconState.fiscalYear + "P" + reconState.fiscalPeriod.padStart(2, "0");
      const startReconPayload: IReconMessage = {
        fileLocation: props.configuration.endpoints.ReconStorageContainer + "/" + reconId + "/ReconFiles",
        reconFiscalYear: reconState.fiscalYear,
        reconPostingPeriod: reconState.fiscalPeriod,
        userName: userContext.user.alias
      };
      const reconStarted = await startRecon(props.configuration, userContext.accessToken, startReconPayload);

      if (reconStarted) {
        setMessage({
          message: "You have successfully started the recon process.",
          type: MessageBarType.success
        });
      } else {
        setMessage({
          message: "Failed to start the recon process.",
          type: MessageBarType.error
        });
      }

      console.log("reconStarted", reconStarted);
    } catch (err) {
      setDashboardGridStatus(LoadingStatus.Rejected);
    }
  };

  const performUpdateSysUsers = async (newUsers: string) => {
    try {
      console.log("newSysUsers", newSysUsers);

      const sysUsersUpdated = await updateSystemUsers(props.configuration, userContext.accessToken, newUsers);
      console.log(sysUsersUpdated);

      setReconSate({
        ...reconState,
        openUpdateSysUsersPanel: false
      });
      if (sysUsersUpdated) {
        setMessage({
          message: "You have successfully updated system users list.",
          type: MessageBarType.success
        });
      } else {
        setMessage({
          message: "Failed to update system users list.",
          type: MessageBarType.error
        });
      }
    } catch (err) {
      setDashboardGridStatus(LoadingStatus.Rejected);
    }
  };

  const onClosePanel = () => {
    setReconSate({
      ...reconState,
      openUpdateSysUsersPanel: false
    });
  };

  const onChangeFY = () => (_: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
    if (option) {
      setReconSate({
        ...reconState,
        fiscalYear: option.text as string
      });
    }
  };
  const onChangeFP = () => (_: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
    if (option) {
      setReconSate({
        ...reconState,
        fiscalPeriod: option.text as string
      });
    }
  };

  const onUpload = async () => {
    if (attachmentsRef.current) {
      const attachment = await attachmentsRef.current.getAttachments();

      if (attachment[1]) {
        return;
      }
      setReconSate({
        ...reconState,
        fileLocation: attachment[0].attachments[0].url
      });
    }
  };

  const updateUsers = () => {
    setReconSate({
      ...reconState,
      openUpdateSysUsersPanel: true
    });
  };

  return (
    <div
      className={css`
        position: relative;
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        grid-template-rows: auto;
        & > * {
          grid-row: 1;
          grid-column: 1;
        }
        & .content {
          width: 100%;
        }
        & > [data-state="open"] ~ .content {
          pointer-events: none;
          visibility: hidden;
          opacity: 0;
        }
      `}
    >
      <div className="content">
        {/* <ReconFYFPSelector ref={selectorRef} triggerRefreshFn={refreshTriggerFn} /> */}
        <Stack styles={{ root: { margin: "10px 0 20px 0 " } }} horizontal tokens={{ childrenGap: 20 }}>
          <Stack.Item align="end">
            <Dropdown
              placeholder="Select an option"
              label="Fiscal Year"
              options={fiscalYearOptions}
              styles={dropdownStyles}
              selectedKey={fiscalYearOptions[fiscalYearOptions.findIndex((x) => x.text === reconState.fiscalYear)].key}
              onChange={onChangeFY()}
            />
          </Stack.Item>
          <Stack.Item align="end">
            <Dropdown
              placeholder="Select an option"
              label="Fiscal Period"
              options={fiscalPeriodOptions}
              styles={dropdownStyles}
              selectedKey={
                fiscalPeriodOptions[fiscalPeriodOptions.findIndex((x) => x.text === reconState.fiscalPeriod)].key
              }
              onChange={onChangeFP()}
            />
          </Stack.Item>
        </Stack>

        <Separator />
        <ReconProcessAttachments
          ref={attachmentsRef}
          configuration={props.configuration}
          onUpload={onUpload}
          onChange={() => {
            // const newCount = localAttachments.length + remoteAttachments.length;
            // setRecentUploads(newCount);
            // const localChangeEqualToLocalState = isEqual(localAttachments, []);
            // if (!localChangeEqualToLocalState) {
            //   poData.setUserChanges({
            //     localAttachments
            //   });
            // }
          }}
          onDelete={() => Promise<void>}
          fY={reconState.fiscalYear}
          fP={reconState.fiscalPeriod}
        />
        <Separator />
        {!message ? null : (
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12">
              <MessageBar
                messageBarType={message.type}
                isMultiline={false}
                onDismiss={() => setMessage(null)}
                dismissButtonAriaLabel="Close"
              >
                {message.message}
              </MessageBar>
            </div>
          </div>
        )}
        <div style={{ position: "relative" }}>
          {gridItems.length === 0 ? (
            <Text
              variant="xLarge"
              style={{
                color: "var(--accent-font-color, gray)",
                textAlign: "center"
              }}
            >
              <ReconProcessCommandBar
                onStartRecon={performStartRecon}
                onUpdateSysUsers={updateUsers}
                onCheckReconStatus={getReconStatus}
                onGetMissingJEs={getMissingJEsAction}
                onGetReconFile={getReconLogFileAction}
                onSyncJE={performSyncJEs}
              />
              No Items to show.
            </Text>
          ) : dashboardGridStatus === LoadingStatus.Resolved ? (
            <DashboardGrid
              idForLocalStorage={"reconGridConfiguration"}
              columnGenerator={getReconGridColumns()}
              isSortedIndex={10}
              items={gridItems}
              isSortedDescending={true}
              customRef={dashboardGridRef}
              selectionMode={SelectionMode.multiple}
              height={`500px`}
              onExport={(rows) => {
                exportToExcel({
                  sheetName: "ReconStatus",
                  rowsWithHeader: rows,
                  fileName: "ReconStatus.xlsx"
                });
              }}
            >
              <ReconProcessCommandBar
                onStartRecon={performStartRecon}
                onUpdateSysUsers={updateUsers}
                onCheckReconStatus={getReconStatus}
                onGetMissingJEs={getMissingJEsAction}
                onGetReconFile={getReconLogFileAction}
                onSyncJE={performSyncJEs}
              />
            </DashboardGrid>
          ) : (
            <LoadingSpinner label="Loading grid" />
          )}

          {reconState.openUpdateSysUsersPanel && (
            <>
              <ActionTitle onCloseButton={() => onClosePanel()} name={"Update System Users"}></ActionTitle>
              <div className={styles.contentStyles.body}>
                <TextFieldSubmission
                  onSubmit={async function (type: "submit" | "cancel", newUsers: string): Promise<void> {
                    if (type === "cancel") {
                      onClosePanel();
                    } else {
                      setNewSysUsers(newUsers);
                      const result = await performUpdateSysUsers(newUsers);
                      console.log(result);
                    }
                  }}
                  autoComplete={"off"}
                  submitButtonText={"Submit"}
                  textFieldLabel={"System Users"}
                ></TextFieldSubmission>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

ReconProcess.displayName = "ReconProcess";
ReconProcess = React.memo(ReconProcess);
export default ReconProcess;
