import { IDropdownProps, MessageBarType } from "@fluentui/react";
import { JemConfiguration } from "../../../JemConfiguration";
import { GLAttachment } from "../../../Shared/components/Attachments/Attachments.types";

import { MockDataFn } from "../../../Shared/utilities/RequestUtilities";
import { FCWChecklistModel } from "../../utilities/FetchUserChecklists";
import { AttachmentInformation } from "../GLCreate/GLCreate.types";
import { ErrorListModel } from "../GLCreate/GLCreateForm.types";

export interface GLBatchProps {
  configuration: JemConfiguration["GeneralLedgerApi"];
  attachmentsConfiguration: JemConfiguration["DocumentsApi"];
  mockBatchDataFn?: MockDataFn<any>;
}

export interface GLBatchState {
  author: string;
  modifiedDate: string;
  poster: string;
  createdDate: string;
  fiscalYear: string;
  fiscalPeriod: string;
  batchDetails: {
    isBPOEntry: boolean;
    isBulkType: boolean;
    batchPurpose: string;
    batchDesc: string;
    batchName: string;
    reasonCode: string;
    option: number[];
    batchFileName: string;
    fiscalYearPeriod: string;
  };
  attachments: GLAttachment[];
  attachments_region: number;
  lock_region: boolean;
  posters: {
    backupPosters: string[];
  };
  reviewers: {
    reviewer: string;
    additionalReviewers: string[];
    preReviewers: string[];
  };
  errorTable: ErrorListModel[];
  postingType: number;
  refGuid: string;
  status: number;
  chkRefGuid: string;
  errorFilePath: string;
  fileUrl: string;
  hdrInfo: string;
  isPostingRequired: boolean;
  mode: number;
  uri: string;
  authorList: string;
  tenantId: number;
  versionNo: number | null;
  processingStatus: number;
  reasonCodesDropDownOptions: IDropdownProps["options"];
  optionsDropDownOptions: IDropdownProps["options"];
  userChecklists: FCWChecklistModel[];
  userSelectedChecklist: FCWChecklistModel | null;
  checklistName: string;
  checklistCompanyCodes: string;
  batchCompanyCode: string;
  isDefaultState: boolean;
  isAdhocDraft: boolean;
  actualTimeTakenMins: number;
  isPreReview: boolean;
}

export interface GLBatchFormikState {
  poster: string;
  createdDate: string;

  batchFile: File | null;

  detailsTabIsBPOEntry: boolean;
  detailsTabIsBulkType: boolean;
  detailsTabBatchPurpose: string;
  detailsTabBatchDesc: string;
  detailsTabBatchName: string;
  detailsTabReasonCode: string;
  detailsTabOption: number[];
  detailsTabBatchFile: string;

  detailsTabFiscalYearPeriod: string;
  detailsTabChecklist: string;
  detailsTabSelectedChecklist: FCWChecklistModel;
  detailsTabCurrentChecklists: FCWChecklistModel[];
  detailsTabActualTimeTaken: number;

  attachments_count: number;
  attachments_error: string;
  attachments_region: string;

  postersTabPosters: string;
  postersTabBackupPosters: string[];

  reviewersTabReviewer: string;
  reviewersTabAdditionalReviewers: string[];
  reviewersTabPreReviewers: string[];

  errorTabErrorTable: ErrorListModel[];
}

export enum GLBatchTabNames {
  batchDetails = "batchDetails",
  attachments = "attachments",
  posters = "posters",
  reviewers = "reviewers",
  errorDetails = "errorDetails"
}

export interface BatchQueryStringParameters {
  RefGuid: string;
  Edit: string;
  Review: string;
}

export interface BatchReasonCodes {
  jeReason: string;
  reasonCode: string;
}

export interface AppParams {
  group: string;
  name: string;
  description: string;
  value: string;
  showUX: boolean;
  allowedValues: string;
  maxLengthAllowed: number;
}

export interface BatchFields {
  displayName: string;
  fieldId: number;
  group: string;
  isSelected: boolean;
  isOptional: boolean;
  optionalFieldName: string;
}

export interface SDData extends AttachmentInformation {
  jeID: string;
  refGuid: string;
  isHBI: string;
  countOfDocuments: string;
  currentMode: string;
  jeType: string;
}

export interface BatchPayloadForSave {
  batchDetailsInfo: BatchDetailsInfo;
  jeBatchInfo: JeBatchInfo;
}

export type BatchResponseErrors = {
  errorMessage: string;
  lineNum: number;
}[];

export interface BatchDetailsInfo {
  isOptionsEnabled: boolean;
  optionsVisibility: string;
  batchName: string;
  fileReceived: string;
  batchExcelTemplate: string;
  isDirty: boolean;
  refGuid: string;
  filePath: string;
  mode: number;
  attachments: number;
  optionalFields: BatchFields[];
  reasonCodes: [];
  sdData: SDData;
  postingStatus: number;
  reasonCode: string;
  description: string;
  purpose: string;
  fileName: string;
  fiscalPeriod: string;
  processingStatus: number;
  status: number;
  errorFileName: string;
  errorFilePath: string;
  sharePointRegularExpression: string;
  isBPOEntry: boolean;
  postingType: number;
  isBPOEnabled: boolean;
  checklistRefGuid: string | null;
  fileExtension: string;
  hrdInfo: string;
  fcwChecklistCoCodes: string | null;
  actualTimeTakenMins: number;
  preReviewers: string;
  isPreReview: boolean;
}

export interface JeBatchInfo {
  isBPOEntry: boolean;
  isBulkType: boolean;
  batchPurpose: string;
  batchDesc: string;
  batchName: string;
  batchFile: {
    fileName: string;
  };
  attachments: number;
  chkRefGuid: string;
  errorFilePath: string;
  fileUrl: string;
  hdrInfo: string;
  isPostingRequired: boolean;
  mode: number;
  postingType: number;
  processingStatus: number;
  refGuid: string;
  status: number;
  uri: string;
  modifiedOn: Date;
  reasonCode: string;
  reviewer: string;
  additionalReviewers: string;
  additionalPosters: string;
  authorList: string;
  createdOn: Date;
  tenantId: number;
  versionNo: number | null;
  fcwChecklistGuid: string | null;
  fcwChecklistName: string;
  fcwChecklistCoCodes: string | null;
  opsDetailsName?: string | null;
  batchCompanyCode: string | null;
  fiscalPeriod: string;
  isAdhocDraft: boolean;
  actualTimeTakenMins: number;
  preReviewers: string;
  isPreReview: boolean;
}

export interface Documents {
  fileName: string;
  blobName: string;
  url: string;
  size: number;
}

export interface BatchJemXML {
  guid: string;
  user: string;
  noOfFiles: number;
  region: string;
  reasonCode: string;
  postingType: number;
  isBpo: boolean;
  fileNames: string[];
  blobNames: string[];
  documents: Documents[];
  purpose: string;
  author: string;
  actualTimeTakenMins: number;
}

export interface BatchPayloadAfterSave {
  fileContent: string;
  uri: string;
  regionName: string;
  guid: string;
  optionalFields: string[];
  jemXML: BatchJemXML;
  type: string;
  fcwChecklistCoCodes: string | null;
  fiscalYear: string | null;
  fiscalPeriod: string | null;
  opsDetailsName: string | null;
  preReviewers: string | null;
  isPreReview: boolean;
}

export interface BatchValidateResponse {
  jeBatchError: JeBatchErrors[];
  jeBatchWarning: JeBatchErrors[];
  version: string;
  allowSAPPosting: boolean;
}

export interface BatchSaveResponse {
  message: string;
  type: MessageBarType;
  batchName: string;
  filepath: string;
  version: string;
}

export interface ValidateResponse {
  message: string;
  type: MessageBarType;
  jeBatchError: JeBatchErrors[];
  version: string;
}
export interface JeBatchErrors {
  lineNo: number;
  message: string;
}
