import { DatePicker, Label, Text, TextField } from "@fluentui/react";
import React, { useContext } from "react";
import { IHCCPODetailsContext } from "../../../contexts/IHCCPODetailsContext/IHCCPODetailsContext";
export interface IHCCPODetailsTabProps {
  saveBtnDisabled: boolean;
}
const PODetailsTab: React.FC<IHCCPODetailsTabProps> = (props) => {
  const poData = useContext(IHCCPODetailsContext);
  const draftModel = poData.model.values.detailsTab;

  return (
    <React.Fragment>
      <div className="ms-Grid" dir="ltr">
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm3">
            <Label>PO Number:</Label>
            <Text
              style={{
                color: "var(--accent-font-color, black)"
              }}
            >
              {draftModel.poNumber}
            </Text>
          </div>
          <div className="ms-Grid-col ms-sm3">
            <Label>Poster:</Label>
            <Text
              style={{
                color: "var(--accent-font-color, black)"
              }}
            >
              {draftModel.poster}
            </Text>
          </div>
          <div className="ms-Grid-col ms-sm3">
            <Label>FiscalYear/Period:</Label>
            <Text
              style={{
                color: "var(--accent-font-color, black)"
              }}
            >
              {draftModel.fiscalYearAndPeriod}
            </Text>
          </div>
          <div className="ms-Grid-col ms-sm3">
            <TextField
              label="PO Purpose:"
              disabled={props.saveBtnDisabled}
              value={poData.userChanges.purpose}
              errorMessage={poData.userChanges.purpose === "" ? "Purpose is required" : undefined}
              onChange={(_, newValue) => {
                poData.setUserChanges({
                  purpose: newValue || ""
                });
              }}
            />
          </div>
        </div>

        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm3">
            <Label>PO Type:</Label>
            <Text
              style={{
                color: "var(--accent-font-color, black)"
              }}
            >
              {draftModel.poType}
            </Text>
          </div>
          <div className="ms-Grid-col ms-sm3">
            <DatePicker
              placeholder=""
              ariaLabel="Select a date"
              label="Reversal Date:"
              value={!isNaN(Date.parse(draftModel.reversalDate)) ? new Date(draftModel.reversalDate) : undefined}
              disabled={true}
            />
          </div>
          <div className="ms-Grid-col ms-sm3">
            <Label>Bank Area:</Label>
            <Text
              style={{
                color: "var(--accent-font-color, black)"
              }}
            >
              {draftModel.bankArea}
            </Text>
          </div>
        </div>

        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm3">
            <Label>Reviewer(s):</Label>
            <Text
              style={{
                color: "var(--accent-font-color, black)"
              }}
            >
              {draftModel.reviewers}
            </Text>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

PODetailsTab.displayName = "PODetailsTab";
export default PODetailsTab;
