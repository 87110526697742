import React from "react";
import { IconButton } from "@fluentui/react";
import { IExtendedColumn, TenantResponseFromAPI } from "@jem/components";

interface ITenantDetailsColumn extends IExtendedColumn {
  fieldName: keyof TenantResponseFromAPI;
}

interface ITenantDetailsColumnsProps {
  onEditClick: (editItem: TenantResponseFromAPI) => void;
}

export const getTenantDetailsColumns = (props: ITenantDetailsColumnsProps) => (): ITenantDetailsColumn[] => {
  const { onEditClick } = props;

  const cols: ITenantDetailsColumn[] = [
    {
      key: "appId",
      name: "App Id",
      fieldName: "appId",
      type: "string",
      minWidth: 130
    },
    {
      key: "Actions",
      name: "Actions",
      fieldName: "appId",
      type: "string",
      minWidth: 30,
      onRender: (item: TenantResponseFromAPI): JSX.Element | React.ReactText | null => {
        return (
          <>
            <IconButton
              iconProps={{ iconName: "Edit" }}
              title="Edit"
              ariaLabel="Edit Tenant"
              styles={{
                root: {
                  padding: 0
                }
              }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onEditClick(item);
              }}
            />
          </>
        );
      }
    },
    {
      key: "name",
      name: "Tenant Name",
      fieldName: "name",
      type: "string",
      minWidth: 80
    },
    {
      key: "tenantOwner",
      name: "Tenant Owner",
      fieldName: "tenantOwner",
      type: "string",
      minWidth: 60
    },
    {
      key: "primaryPoster",
      name: "Primary Poster",
      fieldName: "primaryPoster",
      type: "string",
      minWidth: 90
    },
    {
      key: "defaultReviewers",
      name: "Default Reviewers",
      fieldName: "defaultReviewers",
      type: "string",
      minWidth: 80
    },
    {
      key: "allowAutoReview",
      name: "Auto Review",
      fieldName: "allowAutoReviewString",
      type: "string",
      minWidth: 80
    },
    {
      key: "overrideTenantSetting",
      name: "Allow Override",
      fieldName: "overrideTenantSettingString",
      type: "string",
      minWidth: 80
    },
    {
      key: "prefix",
      name: "Prefix",
      fieldName: "refGuidPrefix",
      type: "string",
      minWidth: 70
    }
  ];
  return cols;
};
