import { Stack, TextField } from "@fluentui/react";
import React, { useContext } from "react";
import { IHCCDraftContext } from "../../contexts/IHCCDraftContext/IHCCDraftContext";

const IHCCCreateReadOnly: React.FC = () => {
  const { draftModel } = useContext(IHCCDraftContext);

  if (draftModel.values.alias === "") {
    return null;
  }
  if (draftModel.values.dateCreated === "") {
    return null;
  }
  return (
    <Stack horizontal disableShrink tokens={{ childrenGap: 18 }}>
      <Stack.Item>
        <TextField label="Author" underlined readOnly value={draftModel.values.alias} />
      </Stack.Item>
      <Stack.Item>
        <TextField id="created" label="Created" underlined readOnly value={draftModel.values.dateCreated} />
      </Stack.Item>
    </Stack>
  );
};

IHCCCreateReadOnly.displayName = "IHCCCreateReadOnly";

export default IHCCCreateReadOnly;
