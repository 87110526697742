import {
  DefaultButton,
  Dropdown,
  IDatePickerStrings,
  IDropdownOption,
  PrimaryButton,
  Stack,
  Text,
  TextField,
  defaultDatePickerStrings
} from "@fluentui/react";
import { FastField, Field, Form, Formik } from "formik";
import React, { PropsWithChildren, useContext, useRef, useState } from "react";
import { gridStyle } from "../../../GeneralLedger/shared/GL.styles";
import { ActionResult, ActionTypes, GLActionEndpointMap } from "../ActionManager/ActionsManager.types";
import { ActionsContext } from "./Actions.ActionsContext";
import { ActionTitle } from "./Actions.ActionTitle";
import { BaseActionProps, CommonPropsOnRows } from "./Actions.types";
import { SimpleShimmerForForms } from "../SimpleShimmerForForms";
import { LoadingStatus } from "../../utilities";
import { useConst } from "@fluentui/react-hooks";
import { DatePickerField } from "../DatePickerField";
import { GLAttachments, GLAttachmentsProps, GLAttachmentsRef, getCurrentFyFp } from "../../../GeneralLedger";
import { convertToReversalReasonsDropdownOptions, JEMContext } from "../../contexts";

interface reverseMainProps<ListType, ResultType> extends BaseActionProps<ListType, ResultType> {
  onSubmitHandler: NonNullable<GLActionEndpointMap<ListType, ResultType>[ActionTypes.reverse]>;
}

export type reverseProps<ListType, ResultType> = reverseMainProps<ListType, ResultType> &
  Omit<
    GLAttachmentsProps,
    "customRef" | "initialAttachments" | "disabled" | "loadingStatus" | "lockRegion" | "initialRegion"
  >;

/*const reasons: string[] = ["Z1"];

export const reversalReasonOptions: IDropdownOption[] = reasons.map((item) => {
  return {
    key: item,
    text: item
  };
});*/

const Reverse = <ListType extends CommonPropsOnRows, ResultType extends ActionResult>(
  props: PropsWithChildren<reverseProps<ListType, ResultType>>
) => {
  const domainContext = useContext(JEMContext);
  const reversalReasons = convertToReversalReasonsDropdownOptions(
    domainContext?.initInfo?.values?.ReversalReasons || []
  );
  const { styles } = useContext(ActionsContext);
  const attachmentsRef = useRef<GLAttachmentsRef>(null);
  const [disabled, setDisabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState("Reversal reason is required");
  const [buttonText, setButtonText] = useState("Reverse");
  const fyfp = getCurrentFyFp(new Date());
  const defaultPostingDate = new Date(`${fyfp.calendarMonth}/15/${fyfp.calendarYear}`).toLocaleDateString("en-US");

  const [postingPeriod, setPostingPeriod] = useState<string>(fyfp.fiscalMonth.toString());
  const [postingDate, setPostingDate] = useState<string>("");
  const [comments, setComments] = useState<string>("");

  const initialState = {
    reversalReason: "",
    postingDate: "",
    postingPeriod: fyfp.fiscalMonth.toString(),
    attachments: []
  };

  let reversalReason;
  const outOfBoundsErrorMessage: IDatePickerStrings = useConst(() => ({
    ...defaultDatePickerStrings,
    isOutOfBoundsErrorMessage: "Posting Date should be in the current Posting Period."
  }));

  const onSubmit = async (values, { setSubmitting }) => {
    //event.stopPropagation();
    setSubmitting(true);
    let attachments, error;
    if (attachmentsRef.current) {
      [attachments, error] = await attachmentsRef.current.saveAndGetAttachments();
    }
    if (error) {
      setSubmitting(false);
      setDisabled(true);
    }
    setButtonText(buttonText === "Reverse" ? "Action is getting performed..." : "Reverse");
    const result = await props.onSubmitHandler(
      props.items,
      values.reversalReason,
      postingDate === "" ? defaultPostingDate : postingDate,
      postingPeriod,
      attachments ? attachments?.attachments : null,
      attachments ? attachments?.region_key : "",
      comments
    );
    props.onSubmit(result);
  };

  return (
    <>
      <ActionTitle onCloseButton={() => props.onCancel()} name={"Reverse JEs"}></ActionTitle>
      <div className={styles.contentStyles.body}>
        <Text>This action is getting performed only on {props.items.length} records. </Text>
        <Formik
          initialValues={initialState}
          onSubmit={onSubmit}
          //validateOnMount={true}
          //validationSchema={ValidationManager.CommentAndValidationCodeValidation(!isGL)}
        >
          {(formik) => (
            <Form onSubmit={formik.handleSubmit}>
              <Stack
                {...{
                  tokens: {
                    childrenGap: 10,
                    padding: 10
                  }
                }}
              >
                {props.configuration && (
                  <Stack.Item
                    {...{
                      styles: {
                        root: {
                          maxWidth: 300
                        }
                      }
                    }}
                  >
                    <FastField
                      label="Reversal reason:"
                      name="reversalReason"
                      as={Dropdown}
                      options={reversalReasons}
                      placeholder="Select an option"
                      onChange={(_event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption) => {
                        reversalReason = item?.key;
                        if (item?.key) {
                          formik.setFieldValue("reversalReason", item?.key);
                          comments !== "" ? setDisabled(false) : setDisabled(true);
                        } else {
                          setDisabled(true);
                          setErrorMessage("Reversal reason is required");
                        }
                      }}
                      errorMessage={errorMessage}
                      required
                    />
                  </Stack.Item>
                )}
                <Stack.Item>
                  <SimpleShimmerForForms height={32} label="Posting Date" loadingStatus={LoadingStatus.Resolved}>
                    <Field
                      name="postingDate"
                      label="Posting Date"
                      placeholder={new Date(`${fyfp.calendarMonth}/15/${fyfp.calendarYear}`).toLocaleDateString(
                        "en-US"
                      )}
                      selected={new Date(`${fyfp.calendarMonth}/15/${fyfp.calendarYear}`)}
                      component={DatePickerField}
                      strings={outOfBoundsErrorMessage}
                      onSelectDate={(date: Date | null | undefined) => {
                        if (!date) return;
                        const fyfp = getCurrentFyFp(date);
                        setPostingDate(date.toLocaleDateString("en-US"));
                        setPostingPeriod(fyfp.fiscalMonth.toString());
                      }}
                    />
                  </SimpleShimmerForForms>
                </Stack.Item>
                <Stack.Item>
                  <TextField
                    name="postingPeriod"
                    label="Posting Period"
                    type="input"
                    errorMessage={formik.errors.postingPeriod}
                    disabled
                    value={postingPeriod}
                  />
                </Stack.Item>
                <Stack.Item>
                  <Text>Please make sure to select the same region as the original JEs attachment </Text>
                </Stack.Item>
                <Stack.Item>
                  <GLAttachments
                    customRef={attachmentsRef}
                    onUploadFile={props.onUploadFile}
                    onDeleteFile={props.onDeleteFile}
                    onDownloadFile={props.onDownloadFile}
                    initialRegion={"2"} // REDMOND
                    onChange={(lAttachments, rAttachments, errorMessage) => {
                      const theresAnErrorInAttachments = errorMessage !== null;
                      if (theresAnErrorInAttachments) {
                        setErrorMessage("Please reattach or try again");
                        setDisabled(true);
                      }
                    }}
                    lockRegion={false}
                    initialAttachments={[]}
                    disabled={false}
                    loadingStatus={LoadingStatus.Resolved}
                    items={props.items}
                  ></GLAttachments>
                </Stack.Item>
                <Stack.Item>
                  <TextField
                    name="comments"
                    required
                    label={"Comments"}
                    errorMessage={"Comment is required"}
                    onChange={async (event, newValue) => {
                      event.stopPropagation();
                      const textBoxVal = newValue;
                      const theresNoTextBoxVal = !textBoxVal || textBoxVal === "";
                      if (theresNoTextBoxVal) {
                        setErrorMessage("Comment is required");
                        setDisabled(true);
                      } else {
                        if (reversalReason !== "") {
                          setDisabled(false);
                        }
                        setComments(textBoxVal);
                      }
                    }}
                  />
                </Stack.Item>
                <Stack.Item>
                  <Stack horizontal {...{ tokens: gridStyle }}>
                    <Stack.Item>
                      <Text>Are you sure you want to reverse this entry? </Text>
                    </Stack.Item>
                    <Stack.Item align="end">
                      <PrimaryButton text={buttonText} type="submit" disabled={disabled || !formik.isValid} />
                    </Stack.Item>
                    <Stack.Item align="end">
                      <DefaultButton
                        onClick={(event) => {
                          event.stopPropagation();
                          props.onCancel();
                        }}
                        text="Cancel"
                      />
                    </Stack.Item>
                  </Stack>
                </Stack.Item>
              </Stack>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

Reverse.displayName = "Reverse JE";

export { Reverse };
