import { v4 } from "uuid";
import { AuthorizationToken, IRequestOptions, IUserProviderState } from "../../../Shared/utilities/RequestUtilities";
import { Notification, NotificationTypes } from "../../../Shared/components/NotificationsManager/NotificationsManager";
import {
  AutomatedDashboardConfiguration,
  AutomatedJeDashboardElement
} from "../AutomatedJeDashboard/AutomatedJeDashboard.requests";

const apiCall = async (
  payload: any,
  configuration: AutomatedDashboardConfiguration,
  getTokenFn?: IRequestOptions<AutomatedJeDashboardElement>["getTokenFn"]
) => {
  const headers = {
    accept: "application/json",
    requestid: v4(),
    "Content-Type": "application/json",
    "cache-control": "no-cache, no-store, must-revalidate"
  };
  if (getTokenFn && getTokenFn instanceof Function) {
    (headers as any).Authorization = await AuthorizationToken(getTokenFn);
  }
  const fullEndpoint = configuration.baseApiUrl + configuration.endpoints.s2sRetryAction;
  const resp = await fetch(fullEndpoint, {
    headers,
    referrerPolicy: "strict-origin-when-cross-origin",
    method: "POST",
    body: JSON.stringify(payload),
    mode: "cors"
  });
  const json = await resp.json();
  return json as { sendNotifyResultMsg: string | null; refGuids: string | null }[];
};

export interface ICommandBarRequestsForCheckbox {
  Retry: (refguids: AutomatedJeDashboardElement[]) => Promise<Notification[]>;
}

export enum S2SErrorMessages {
  RetryException = "Unable to Retry Entry(-ies) - entries not queued."
}

export const checkBoxActions = (
  configuration: AutomatedDashboardConfiguration,
  getTokenFn?: IUserProviderState["accessToken"]
): ICommandBarRequestsForCheckbox => {
  const basicImplementation = async (items: AutomatedJeDashboardElement[]): Promise<Notification[]> => {
    try {
      const response = await apiCall(
        {
          refGuids: items.map((item) => item.refguid).join(",")
        },
        configuration,
        getTokenFn
      );
      const arr: Notification[] = [];
      if (response && response.length !== 0) {
        response.forEach((elem) => {
          if (!elem.refGuids || !elem.sendNotifyResultMsg) {
            return;
          }
          const refGuids = elem.refGuids as string;
          const matched = items.filter(
            (item) => item.refguid.indexOf(refGuids) !== -1 || item.refguid.indexOf(refGuids.toUpperCase()) !== -1
          );
          arr.push({
            type:
              elem.sendNotifyResultMsg.search("Failed") == -1 ? NotificationTypes.success : NotificationTypes.errors,
            messages: elem.sendNotifyResultMsg,
            refguid: elem.refGuids,
            documentId: matched[0].jeId,
            timestamp: new Date()
          });
        });
      }
      return arr;
    } catch (e) {
      return items.map((item) => ({
        type: NotificationTypes.errors,
        messages: "Failed to retry JE.",
        refguid: item.refguid,
        documentId: item.jeId,
        timestamp: new Date()
      }));
    }
  };
  return {
    Retry: (refGuids: AutomatedJeDashboardElement[]) => basicImplementation(refGuids)
  };
};
