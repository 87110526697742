import { ActionButton, Stack } from "@fluentui/react";
import React, { Dispatch, SetStateAction } from "react";
import { Control, Controller, DeepMap, FieldError, FieldValues } from "react-hook-form";
import QueryBasedDropdown from "./QueryBasedDropdown";
import { ITaskFormInputChoice } from "./TaskBulkEdit.config";

export interface IFormEditInputRow {
  selectedInputKey: string;
}

const BulkEditRow = (props: {
  inputRowProps: IFormEditInputRow;
  formInputs: ITaskFormInputChoice[];
  activeFormInputs: string[];
  control: Control<FieldValues>;
  bulkEditState: IFormEditInputRow[];
  setBulkEditState: Dispatch<SetStateAction<IFormEditInputRow[]>>;
  getValues: any;
  errors?: DeepMap<FieldValues, FieldError>;
  showSingleValueOptions: boolean;
}) => {
  const {
    inputRowProps,
    formInputs,
    activeFormInputs,
    control,
    bulkEditState,
    setBulkEditState,
    getValues,
    showSingleValueOptions
  } = props;

  const { selectedInputKey } = inputRowProps;
  const fieldStyles = { root: { flex: "0 0 40%", width: "250px" } };
  const actionStyles = { root: { flex: "0 0 10%", width: "100px" } };
  const getFilteredFormInputs = (currentKey: string) => {
    return formInputs.filter((input) => {
      const unusedInput = input.key.toString() === currentKey || !activeFormInputs.includes(input.key.toString());
      const showSingle = showSingleValueOptions ? true : input.isMultiValue;
      return unusedInput && showSingle;
    });
  };

  const getIndividualEditFormComponent = (formInputKey: string) => {
    const inputType: ITaskFormInputChoice | undefined = formInputs.find((input) => {
      return input.key === formInputKey;
    });
    const componentProps = inputType?.props !== undefined ? Object.assign(inputType?.props()) : {};
    return (
      <>
        <Controller
          control={control}
          as={inputType?.component}
          name={inputType?.key?.toString()}
          value={getValues(selectedInputKey)}
          defaultValue={getValues(selectedInputKey)}
          ariaLabel="Value"
          {...componentProps}
          rules={{
            required: "Required field"
          }}
        />
      </>
    );
  };
  return (
    <Stack horizontal key={selectedInputKey}>
      <Stack.Item styles={fieldStyles}>
        <QueryBasedDropdown
          value={selectedInputKey}
          getFocusOnRender
          ariaLabel="Field"
          dropdownOptionsData={getFilteredFormInputs(selectedInputKey)}
          handleChange={(newValue: ITaskFormInputChoice, changeInputKey: string): void => {
            const currentState = bulkEditState;
            const editedItem = currentState.findIndex((item: IFormEditInputRow) => {
              return item.selectedInputKey === changeInputKey;
            });
            if (currentState[editedItem]) {
              currentState[editedItem].selectedInputKey = newValue.key.toString();
              setBulkEditState([...currentState]);
            }
          }}
          valueKey={selectedInputKey}
        />
      </Stack.Item>

      <Stack horizontalAlign="center" verticalAlign="center" styles={actionStyles}>
        =
      </Stack>
      <Stack.Item styles={fieldStyles}>{getIndividualEditFormComponent(selectedInputKey)}</Stack.Item>
      <Stack horizontalAlign="center" verticalAlign="center" styles={actionStyles}>
        <ActionButton
          ariaLabel="Delete the field row"
          disabled={activeFormInputs.length <= 1}
          iconProps={{ iconName: "Delete" }}
          onClick={() => {
            const currentState = bulkEditState;
            const filteredItems = currentState.filter((item: IFormEditInputRow) => {
              return item.selectedInputKey !== selectedInputKey;
            });
            setBulkEditState([...filteredItems]);
          }}
        />
      </Stack>
    </Stack>
  );
};

export default BulkEditRow;
