import { GLAttachment, IAttachment } from "../../Shared/components/Attachments/Attachments.types";
import { ObjectContainingLegacyAttachments, RegStoreAttachment, RegStoreAttachmentForUpload } from "./GLDashboardRow";

export function singleRegStoreAttToGLAttachment(regStoreAtt: RegStoreAttachment): GLAttachment | null {
  if (regStoreAtt.originalName === null || regStoreAtt.originalName === "") return null;
  if (regStoreAtt.url === null || regStoreAtt.url === "") return null;
  if (regStoreAtt.fileSize === null) return null;
  if (regStoreAtt.uploadedDate === null || regStoreAtt.uploadedDate === "") return null;
  if (regStoreAtt.regStoreId === null || regStoreAtt.regStoreId === undefined) return null;
  if (regStoreAtt.region === null || regStoreAtt.region === "" || regStoreAtt.region === undefined) return null;
  const date = !isNaN(Date.parse(regStoreAtt.uploadedDate))
    ? new Date(Date.parse(regStoreAtt.uploadedDate)).toLocaleString("en-US")
    : regStoreAtt.uploadedDate;

  const theUrl = regStoreAtt.url;
  const urlPieces = theUrl.split("/");
  if (urlPieces.length < 2) return null;

  const blobUriPath = `${urlPieces[0]}/${urlPieces[1]}`;

  const attachmentForUI: GLAttachment = {
    id: regStoreAtt.fileId || 0,
    blobName: regStoreAtt.blobName || "",
    fileName: regStoreAtt.originalName,
    url: regStoreAtt.url,
    fileSize: regStoreAtt.fileSize,
    documentType: regStoreAtt.isRescindEvidence ? "Rescind Evidence" : "Supporting",
    uploadedOn: date,
    region: {
      id: regStoreAtt.regStoreId,
      name: regStoreAtt.region
    },
    isRescindEvidence: !!regStoreAtt.isRescindEvidence,
    blobUriPath
  };

  // TODO: BRING THE LOGGER ALL THE WAY HERE TO MAKE SURE WE LOG IF regStoreAtt.regStoreId IS NULL OR UNDEFINED
  // OR BETTER YET CATCH IT AS SOON AS WE FETCH THE DATA FROM THE API
  return attachmentForUI;
}

export function regStoreAttachmentsToGLAttachments(
  atts: ObjectContainingLegacyAttachments["attachments"]
): GLAttachment[] {
  if (!atts) return [];
  if (!atts.regStoreAttachments) return [];

  const attachments = atts.regStoreAttachments.reduce((attList, att) => {
    const newAttachment = singleRegStoreAttToGLAttachment(att);
    if (!newAttachment) {
      return attList;
    }
    attList.push(newAttachment);
    return attList;
  }, [] as GLAttachment[]);
  return attachments;
}

export function regStoreAttachmentsToAttachmentsRegion(atts: ObjectContainingLegacyAttachments["attachments"]): number {
  if (!atts) return 2;
  if (!atts.regStoreAttachments) return 2;

  const attRegion = atts.regStoreAttachments.reduce((attObj, att) => {
    if (att.blobName === null) return attObj;
    if (att.blobName === "") return attObj;
    if (att.region === null) return attObj;
    if (att.region === "") return attObj;
    if (att.regStoreId === null) return attObj;

    return att.regStoreId;
  }, 2);
  return attRegion;
}

export function iAttachmentToRegStoreAttachment(regionKey: number, att: IAttachment): RegStoreAttachmentForUpload {
  const pathToBlob =
    att.url.indexOf("/corpacctjestore/") !== -1 ? new URL(att.url).pathname.split("/corpacctjestore/")[1] : att.url;
  const regStore: RegStoreAttachmentForUpload = {
    BlobName: att.blobName,
    FileId: -1,
    FileSize: att.fileSize,
    FileStatus: -1,
    OriginalName: att.fileName,
    RegStoreId: regionKey,
    URL: pathToBlob,
    UploadedDate: att.uploadedOn,
    IsRescindEvidence: false
  };
  return regStore;
}
