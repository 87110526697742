import { Label, TextField } from "@fluentui/react";
import { Field, useFormikContext } from "formik";
import React, { useContext } from "react";
import { JEMAliasPicker } from "../../../../Shared/components/JEMAliasPicker/JEMAliasPicker";
import { SimpleShimmerForForms } from "../../../../Shared/components/SimpleShimmerForForms/SimpleShimmerForForms";
import { LoadingStatus } from "../../../../Shared/utilities/Utilities";
import { removeDuplicateAliases } from "../../../../Shared/utilities/ValidateReviewerAliasesLocally";
import { GLBatchFormikState } from "../../GLBatch";
import { GLCreateFormikState } from "../GLCreateForm.types";
import { UserContext } from "../../../../Shared";

const ReviewerColumn: React.FC<{ disabled: boolean; loadingStatus: LoadingStatus }> = (props) => {
  const formik = useFormikContext<GLCreateFormikState | GLBatchFormikState>();
  const userContext = useContext(UserContext);
  const author = userContext.user.alias.toLocaleUpperCase();
  return (
    <>
      <div className="ms-Grid" dir="ltr">
        <div className="ms-Grid-row">
          <Label>Enter the email alias of the additional reviewers.</Label>
        </div>
        <div className="ms-Grid-row">
          <SimpleShimmerForForms loadingStatus={props.loadingStatus} height={32} label="Reviewer:">
            <Field
              name={"reviewersTabReviewer"}
              label="Reviewer:"
              as={TextField}
              type="input"
              readOnly
              errorMessage={props.disabled ? "" : formik.errors.reviewersTabReviewer}
              disabled={props.disabled}
            />
          </SimpleShimmerForForms>
        </div>
        <div className="ms-Grid-row">
          <SimpleShimmerForForms loadingStatus={props.loadingStatus} height={32} label="Additional Reviewers:">
            <JEMAliasPicker
              label="Additional Reviewers"
              required={false}
              disabled={props.disabled}
              initialAliases={formik.values.reviewersTabAdditionalReviewers}
              onChange={(value) => {
                // remember to remove duplicates from the list
                const unique = removeDuplicateAliases(value);
                const removedAuthor = unique.filter((reviewer) => reviewer !== author);
                formik.setFieldValue("reviewersTabAdditionalReviewers", removedAuthor);
              }}
              description="Maximum of 75 user aliases separated by semicolon(;)."
              errorMessage={
                props.disabled
                  ? ""
                  : formik.errors.reviewersTabAdditionalReviewers
                  ? formik.errors.reviewersTabAdditionalReviewers.toString()
                  : ""
              }
            />
          </SimpleShimmerForForms>
        </div>
        <div className="ms-Grid-row">
          <SimpleShimmerForForms loadingStatus={props.loadingStatus} height={32} label="Pre-Reviewers:">
            <JEMAliasPicker
              label="Pre-Reviewers"
              required={false}
              disabled={props.disabled}
              initialAliases={formik.values.reviewersTabPreReviewers}
              onChange={(value) => {
                // remember to remove duplicates from the list
                const unique = removeDuplicateAliases(value);
                formik.setFieldValue("reviewersTabPreReviewers", unique);
              }}
              description="Maximum of 75 user aliases separated by semicolon(;)."
              errorMessage={
                props.disabled
                  ? ""
                  : formik.errors.reviewersTabPreReviewers
                  ? formik.errors.reviewersTabPreReviewers.toString()
                  : ""
              }
            />
          </SimpleShimmerForForms>
        </div>
      </div>
    </>
  );
};

const GLReviewersTab: React.FC<{ disabled: boolean; loadingStatus: LoadingStatus }> = (props) => {
  return (
    <React.Fragment>
      <div className="ms-Grid" dir="ltr">
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12">
            <ReviewerColumn disabled={props.disabled} loadingStatus={props.loadingStatus} />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

GLReviewersTab.displayName = "GLReviewersTab";
export { GLReviewersTab };
