import { Card, CardStandardHeader, IRichTextEditorProps, RichTextEditor } from "@coherence-design-system/controls";
import { DefaultButton, IContextualMenuItem, Stack } from "@fluentui/react";
import {
  getValidUrl,
  JemConfiguration,
  useQuery,
  getRequest,
  LoadingStatus,
  ApiError,
  UserContext,
  postRequest,
  IUserProviderState
} from "@jem/components";
import React, { useEffect, useState } from "react";
import { NewsNotification } from "./Home.types";

export interface NotificationsProps {
  configuration: JemConfiguration;
  isAdmin: boolean;
}

async function saveNotification(
  generalLedgerApi: JemConfiguration["GeneralLedgerApi"],
  userNotification: string,
  userContext: IUserProviderState,
  refetch: () => void,
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>
) {
  const endpoint = generalLedgerApi.endpoints.getNewsNotifications;
  const url = getValidUrl(`${generalLedgerApi.baseApiUrl}${endpoint}`);
  await postRequest<number>(
    url,
    {
      moduleName: "JEMVNextNotification",
      moduleContent: userNotification,
      createdBy: userContext.user.alias
    } as NewsNotification,
    userContext.accessToken
  );
  refetch();
  setIsEditing(false);
}

const Notifications: React.FC<NotificationsProps> = (props) => {
  const userContext = React.useContext(UserContext);
  const [isEditing, setIsEditing] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.Pending);
  const [userNotification, setNotification] = React.useState("No Notifications!");

  const cardHeaderProps = {
    cardTitle: "Notifications",
    contextProps: props.isAdmin
      ? {
          menuItems: [
            {
              key: "1",
              text: "Edit",
              iconProps: { iconName: "Edit" },
              onClick: () => setIsEditing(true)
            },
            {
              key: "2",
              text: "Delete",
              iconProps: { iconName: "Delete" },
              onClick: () => {
                if (confirm("Are you sure you want to delete the notification?")) {
                  saveNotification(props.configuration.GeneralLedgerApi, "", userContext, refetch, setIsEditing);
                }
              }
            }
          ] as IContextualMenuItem[]
        }
      : undefined
  };

  const {
    data: apiNotification,
    error,
    refetch
  } = useQuery({
    queryKey: ["notifications"],
    queryFn: async () => {
      const endpoint = props.configuration.GeneralLedgerApi.endpoints.getNewsNotifications;
      const url = getValidUrl(`${props.configuration.GeneralLedgerApi.baseApiUrl}${endpoint}`);

      const notifications = await getRequest<NewsNotification[]>(url, userContext.accessToken);
      if (!notifications) {
        throw new ApiError("Unable to retrieve notifications");
      }
      const jemVNextNotification = notifications.find((n) => n.moduleName === "JEMVNextNotification");
      if (jemVNextNotification) {
        return jemVNextNotification;
      }
      return null;
    },
    staleTime: 0
  });

  useEffect(() => {
    if (apiNotification) {
      setNotification(apiNotification.moduleContent || "No Notifications!");
      setLoadingStatus(LoadingStatus.Resolved);
    } else if (error) {
      setLoadingStatus(LoadingStatus.Rejected);
    }
  }, [apiNotification, error]);

  const rteProps: IRichTextEditorProps<any> = {
    label: "Edit Mode",
    ariaLabel: "Edit Mode on Notification",
    placeholder: "No Notifications!",
    characterMax: 1000,
    resizable: true,
    value: userNotification,
    onInvalid: () => alert("This input is invalid")
  };

  return (
    <Card
      header={<CardStandardHeader {...cardHeaderProps} />}
      styles={{ root: { marginTop: "16px", width: "100%", maxWidth: "862px", minHeight: "64px", maxHeight: "500px" } }}
      loading={loadingStatus === LoadingStatus.Pending}
    >
      <div className="ms-Grid" dir="ltr">
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12">
            {isEditing ? (
              <RichTextEditor {...rteProps} onChange={(newValue) => setNotification(newValue || "")} />
            ) : (
              // userNotification is html string
              <div dangerouslySetInnerHTML={{ __html: userNotification }} />
            )}
          </div>
        </div>
        {isEditing ? (
          <Stack
            horizontal
            tokens={{ childrenGap: 10 }}
            style={{
              marginTop: "8px"
            }}
          >
            <Stack.Item>
              <DefaultButton
                text="Publish"
                ariaLabel="Publish Notification"
                onClick={async () => {
                  await saveNotification(
                    props.configuration.GeneralLedgerApi,
                    userNotification,
                    userContext,
                    refetch,
                    setIsEditing
                  );
                }}
              />
            </Stack.Item>
            <Stack.Item>
              <DefaultButton text="Cancel" onClick={() => setIsEditing(false)} />
            </Stack.Item>
          </Stack>
        ) : null}
      </div>
    </Card>
  );
};

Notifications.displayName = "Notifications";
export { Notifications };
