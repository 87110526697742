import { Label, SelectionMode, Text } from "@fluentui/react";
import { DashboardGrid, IExtendedColumn, IDashboardGridRef, exportToExcel } from "@jem/components";
import React, { useRef } from "react";

import { DetailsPageModel, SapDetailsModel } from "../../contexts/IHCCPODetailsContext/IHCCPODetailsContext.types";

interface SapPoDetailsColumn extends IExtendedColumn {
  fieldName: keyof SapDetailsModel;
}

const sapPoDetailsConfiguration = "poDetailsConfiguration";
export const getPODetailsColumns = () => (): IExtendedColumn[] => {
  const cols: SapPoDetailsColumn[] = [
    {
      key: "opAccount",
      name: "OP Account",
      fieldName: "opAccount",
      type: "string",
      minWidth: 80
    },
    {
      key: "rpAccount",
      name: "RP Account",
      fieldName: "rpAccount",
      type: "string",
      minWidth: 80
    },
    {
      key: "transType",
      name: "Trans Type",
      fieldName: "transactionType",
      type: "string",
      minWidth: 80
    },
    {
      key: "transCurrency",
      name: "Trans Currency",
      fieldName: "currency",
      type: "string",
      minWidth: 120
    },
    {
      key: "transAmount",
      name: "Trans Amount",
      fieldName: "opCurrency",
      type: "string",
      minWidth: 120
    },
    {
      key: "rpAmount",
      name: "RP Amount",
      fieldName: "rpCurrency",
      type: "string",
      minWidth: 120
    },
    {
      key: "opCurrency",
      name: "OP Currency",
      fieldName: "opCurrencyType",
      type: "string",
      minWidth: 120
    },
    {
      key: "rpCurrency",
      name: "RP Currency",
      fieldName: "rpCurrencyType",
      type: "string",
      minWidth: 120
    }
  ];

  return cols;
};

interface SAPPODetailsProps {
  sapModel: SapDetailsModel;
  details: DetailsPageModel["detailsTab"];
}

let SAPPODetails: React.FC<SAPPODetailsProps> = (props) => {
  const columns = getPODetailsColumns();
  const dashboardGridRef = useRef<IDashboardGridRef>(null);

  const gridItems = !props.sapModel ? [] : [props.sapModel];
  return (
    <>
      <Text variant="large">PO Details</Text>
      <div className="ms-Grid" dir="ltr">
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm3">
            <Label>Posting Date: </Label>
            <Text
              style={{
                color: "var(--accent-font-color, black)"
              }}
            >
              {props.details.postDate}
            </Text>
          </div>
          <div className="ms-Grid-col ms-sm3">
            <Label>Poster:</Label>
            <Text
              style={{
                color: "var(--accent-font-color, black)"
              }}
            >
              {props.details.poster}
            </Text>
          </div>
          <div className="ms-Grid-col ms-sm3">
            <Label>PO Number:</Label>
            <Text
              style={{
                color: "var(--accent-font-color, black)"
              }}
            >
              {props.details.poNumber}
            </Text>
          </div>
          <div className="ms-Grid-col ms-sm3">
            <Label>FiscalYear/Period:</Label>
            <Text
              style={{
                color: "var(--accent-font-color, black)"
              }}
            >
              {props.details.fiscalYearAndPeriod}
            </Text>
          </div>
        </div>

        <div className="ms-Grid-row">
          {gridItems.length === 0 ? (
            <Text
              variant="xLarge"
              style={{
                color: "var(--accent-font-color, black)"
              }}
            >
              No Items to show.
            </Text>
          ) : (
            <DashboardGrid
              idForLocalStorage={sapPoDetailsConfiguration}
              customRef={dashboardGridRef}
              columnGenerator={columns}
              items={gridItems}
              isSortedIndex={1}
              height={`200px`}
              isSortedDescending={true}
              selectionMode={SelectionMode.multiple}
              onExport={(rows) => {
                exportToExcel({
                  sheetName: "paymentOrderSapPoDetails",
                  rowsWithHeader: rows,
                  fileName: "paymentOrderSapPoDetails.xlsx"
                });
              }}
              disablePagination={true}
            ></DashboardGrid>
          )}
        </div>
      </div>
    </>
  );
};

SAPPODetails = React.memo(SAPPODetails);
SAPPODetails.displayName = "SAPPODetails";
export default SAPPODetails;
