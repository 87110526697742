import { IPaymentOrderObject } from "../../../contexts/IHCCDraftContext/IHCCDraftContext.actions";

import * as Yup from "yup";
import { PaymentOrderDraft } from "../../../contexts/IHCCDraftContext/IHCCDraftContext.types";

export type IPaymentOrderTableObject = Pick<
  IPaymentOrderObject,
  | "bankArea"
  | "transactionType"
  | "orderingPartyAccountNumber"
  | "receivingPartyAccountNumber"
  | "receivingPartyCurrency"
  | "receivingPartyAmountInAccountCurrency"
  | "purpose"
  | "refGuid"
  | "actionType"
  | "id"
  | "processingStatus"
  | "rowVer"
>;

export function PaymentOrderValidationSchema(invalidEntries: { key: string; value: string }[]) {
  const invalidEntriesMap = invalidEntries.map((x) => x?.key);

  return Yup.object().shape({
    opAccount: Yup.string()
      .defined("OP Account is required and must not be blank.")
      .required("OP Account is required and must not be blank.")
      .nullable(true)
      .notOneOf([Yup.ref("rpAccount")], "OP Account and RP Account cannot be the same.")
      .notOneOf(invalidEntriesMap),
    rpAccount: Yup.string()
      .defined("RP Account is required and must not be blank.")
      .required("RP Account is required and must not be blank.")
      .nullable(true)
      .notOneOf([Yup.ref("opAccount")], "OP Account and RP Account cannot be the same.")
      .notOneOf(invalidEntriesMap),
    transType: Yup.string()
      .defined("Transaction Type is required and must not be blank.")
      .required("Transaction Type is required and must not be blank.")
      .nullable(true),
    transAmount: Yup.string()
      .matches(/^-?[0-9]\d*(,\d+)?(\.\d+)?$/, "Amount field only accepts numbers")
      .defined("Amount is required and must not be blank.")
      .required("Amount is required and must not be blank.")
      .nullable(true),
    transCurrency: Yup.string()
      .defined("Currency is required and must not be blank.")
      .required("Currency is required and must not be blank.")
      .nullable(true),
    purpose: Yup.string()
      .defined("Purpose is required and must not be blank.")
      .required("Purpose is required and must not be blank.")
      .nullable(true)
  });
}

export function ValidatePaymentOrder(
  newPaymentOrder: PaymentOrderDraft,
  invalidEntries: { key: string; value: string }[]
): string[] {
  const validationSchema = PaymentOrderValidationSchema(invalidEntries);
  try {
    validationSchema.validateSync(newPaymentOrder, { abortEarly: false });
    return [];
  } catch (error) {
    if (error instanceof Yup.ValidationError) {
      const errorMessages = new Set(error.inner.map((err) => err.message));
      return Array.from(errorMessages);
    }
    throw error;
  }
}
