import { css } from "@emotion/css";
import { Icon, IPivotItemProps } from "@fluentui/react";
import React from "react";

export const PivotItemProps = {
  className: css`
    width: 100%;
    position: relative;
    padding: 16px;
  `
};

export function _customRenderer(
  link?: IPivotItemProps,
  defaultRenderer?: (link?: IPivotItemProps) => JSX.Element | null
): JSX.Element | null {
  if (!link || !defaultRenderer) {
    return null;
  }

  return (
    <span
      style={{
        flex: "0 1 100%",
        display: "flex",
        gap: "4px"
      }}
    >
      {defaultRenderer({ ...link, itemIcon: undefined })}
      {link.itemIcon ? <Icon iconName={link.itemIcon} style={{ color: "red" }} /> : null}
    </span>
  );
}
