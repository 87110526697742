import { IdToAttachmentRegion } from "../../../GeneralLedger/GeneralLedger.types";
import {
  AttachmentRegion,
  AttachmentResponseType,
  DownloadFunction,
  GLAttachment,
  GLPostAttachmentResponse,
  IAttachment,
  PostAttachmentResponse
} from "./Attachments.types";
// import FileDownload from "js-file-download";

export enum AttachmentErrors {
  None = "",
  InvalidExtension = "Invalid file type. Please select a file type from the custom list in the file picker.",
  InvalidFile = "No file. Please provide a file."
}

export class AttachmentUtil {
  public static getValidatedFileExtensions = (fileNames: File[], allowedFileTypes: string): boolean => {
    let isValidExtension = true;

    for (let i = 0; i < fileNames.length && isValidExtension; ++i) {
      const attachment = fileNames[i];
      isValidExtension = !!attachment && this.isValidFileExtension(attachment.name, allowedFileTypes);
    }
    return isValidExtension;
  };

  public static getValidatedFileSize = (files: File[]): boolean => {
    let isValidFileSize = true;
    for (let i = 0; i < files.length; ++i) {
      const attachment = files[i];
      if (attachment !== null && attachment.size === 0) {
        alert("Empty(0 KB) file " + attachment.name + " cannot be attached. Please select file/s again");
        isValidFileSize = false;
      }
    }
    return isValidFileSize;
  };

  public static getValidatedFileName = (files: File[]): boolean => {
    let isValidFileName = true;
    for (let i = 0; i < files.length; ++i) {
      const attachment = files[i];
      const fileNameSplit = attachment.name.split(".");
      if (fileNameSplit.length) {
        fileNameSplit.splice(-1, 1);
        const fileName = fileNameSplit.join(".");
        if (attachment !== null && !fileName.match(/^[a-zA-Z0-9 _-]+$/)) {
          alert("File name " + attachment.name + " contains special characters other than Underscore(_) and Hyphen(-)");
          isValidFileName = false;
        }
      } else isValidFileName = false;
    }
    return isValidFileName;
  };

  public static isValidFileExtension = (fileName: string, allowedFileTypes: string): boolean => {
    const allowedFiles = allowedFileTypes.replace(/[. ]/g, "").replace(" ", "").toLowerCase().split(",");

    if (!fileName) {
      return false;
    }

    const [, ext = ""] = fileName.match(/\.([^.]+)$/) || [];

    const isValidExtension = allowedFiles.includes(ext.toLowerCase());
    if (!isValidExtension) {
      alert("File extension '" + ext + "' is not allowed. Please choose from allowed file types " + allowedFileTypes);
      return false;
    }
    return true;
  };

  public static convertFileSizeToKb(fileSize: string): string {
    const fileSizeKb: number = parseFloat(fileSize) / 1024;
    if (fileSizeKb === 0) return "0";
    else if (fileSizeKb < 1) return "1";
    else return fileSizeKb.toFixed(0).toString();
  }

  public static getIconForAttachmentStatusGrid(isVolatile: boolean): string {
    if (isVolatile) {
      return "Delete";
    } else {
      return "";
    }
  }

  public static postAttachmentResponseToIAttachment(file: File, attachment: GLPostAttachmentResponse): GLAttachment;

  public static postAttachmentResponseToIAttachment(file: File, attachment: PostAttachmentResponse): IAttachment;

  public static postAttachmentResponseToIAttachment<AttachmentType extends IAttachment | GLAttachment>(
    file: File,
    attachment: AttachmentResponseType<AttachmentType>
  ): AttachmentType {
    function isGLPostAttachmentResponse(
      attachment: AttachmentResponseType<IAttachment | GLAttachment>
    ): attachment is GLPostAttachmentResponse {
      return "regStoreID" in attachment && typeof attachment.regStoreID === "number" && attachment.regStoreID > 0;
    }

    const iAttachment = {
      blobName: attachment.blobName,
      fileName: attachment.inputFileName,
      url: attachment.fileStorageUri,
      fileSize: Math.ceil(file.size / 1024),
      uploadedOn: new Date().toISOString()
    } as Omit<AttachmentType, "id">;

    if (isGLPostAttachmentResponse(attachment)) {
      (iAttachment as unknown as GLAttachment).region = {
        id: attachment.regStoreID,
        name: IdToAttachmentRegion[attachment.regStoreID] || ""
      } as GLAttachment["region"];
      (iAttachment as unknown as GLAttachment).blobUriPath = attachment.blobUriPath;
    }

    return iAttachment as AttachmentType;
  }

  public static async attachmentDownload<AttachmentType extends GLAttachment | IAttachment>(
    blobName: string,
    fileName: string,
    onDownloadFile: DownloadFunction<AttachmentType>,
    region?: AttachmentRegion<AttachmentType>
  ): Promise<void> {
    const downloadedFilePromise: Promise<File> =
      region !== undefined ? onDownloadFile(blobName, fileName, region) : onDownloadFile(blobName, fileName);
    const response = await downloadedFilePromise;
    if ("blob" in response) {
      const blob: Blob = await (response as File & { blob: any }).blob();

      // FileDownload(blob, fileName);
      // BEHOLD: SUPER HACK FOR DEALING WITH THE FLUENT DIALOG
      const blobUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      // link.click() won't work on Dialog
      link.dispatchEvent(new MouseEvent("click"));
      URL.revokeObjectURL(blobUrl);
      if (link.parentNode) link.parentNode.removeChild(link);
    }
  }
}
