import { getScrollBarWidth } from "@coherence-design-system/controls";
import { IStyle, mergeStyleSets } from "@fluentui/react";

// import { navCollapsedWidth } from "@m365-admin/nav";
const navCollapsedWidth = 48;

const scrollablePaneStyles = {
  position: "fixed",
  top: 48,
  bottom: 0,
  right: 0
};
export const statusConverter = {
  ErrorDraft: -1,
  Draft: 1,
  PendingForReview: 2,
  Redraft: 3,
  Recall: 4,
  Posted: 5,
  SignOffNotRequired: 7,
  DocumentRequired: 8,
  SignOffInitRequired: 9,
  SignOffPending: 10,
  AwaitingClarification: 11,
  SignedOff: 12,
  Completed: 15,
  ReleaseToSAP: 16,
  FailedinSAP: 6
};

let scrollBarWidth = 0;
let currentZoomLevel = 0;

const calculcateScrollBarWidth = () => {
  [scrollBarWidth, currentZoomLevel] = getScrollBarWidth(scrollBarWidth, currentZoomLevel, window.devicePixelRatio);
  return scrollBarWidth;
};

export const PageStyles = mergeStyleSets({
  scrollablePaneCollapsed: {
    ...scrollablePaneStyles,
    left: navCollapsedWidth + calculcateScrollBarWidth() + 10
  } as IStyle,
  scrollablePaneExpand: {
    ...scrollablePaneStyles,
    left: 228 + calculcateScrollBarWidth() + 10
  } as IStyle,
  rootDiv: {
    position: "relative",
    paddingTop: "16px",
    paddingBottom: "16px",
    paddingRight: "16px",
    paddingLeft: "16px"
  } as IStyle,
  glPage: {
    position: "relative",
    padding: "0"
  } as IStyle,
  dividerLine: {
    width: "100%",
    height: "1px",
    backgroundColor: "black",
    marginBottom: "20px"
  } as IStyle,
  rowGap: {
    height: "30px"
  } as IStyle
});
