import React from "react";
import { useLocation } from "react-router-dom";
import { JemUserRoles } from "../../contexts/UserContext/UserContext.roles.main";
import { ILinks } from "./AppNav";
import { AppNavProps } from "./AppNav.types";

export const SubledgerLinks = (
  location: ReturnType<typeof useLocation>,
  onClickHandler: (route: string) => (event: React.MouseEvent | any) => void,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  props: AppNavProps,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  jemUserRoles: JemUserRoles
) => {
  const links: Partial<ILinks>[] = [];

  const openInNewTab = (route: string) => (event: React.MouseEvent | any) => {
    event.preventDefault();
    window.open(route, "_blank");
  };

  links.push({
    name: "SL Dashboard",
    key: "slDashboard",
    ariaLabel: "Subledger Dashboard",
    // "data-id": "automation_id_22235",
    target: "_blank",
    onClick: openInNewTab(props.configuration.SubledgerDashboardLink),
    isSelected: location.pathname === "/sl/dashboard"
  });
  links.push(
    {
      name: "New Subledger Entry",
      key: "slCreate",
      ariaLabel: "Subledger New Journal Entry",
      // "data-id": "automation_id_22235",
      target: "_blank",
      onClick: openInNewTab(props.configuration.SubledgerNewJELink),
      isSelected: location.pathname === "/sl/create"
    },
    {
      name: "New Batch Subledger",
      key: "slBatch",
      ariaLabel: "Subledger New Batch JE",
      // "data-id": "automation_id_22235",
      target: "_blank",
      onClick: openInNewTab(props.configuration.SubledgerNewBatchLink),
      isSelected: location.pathname === "/sl/batch"
    }
  );
  links.push({
    name: "Subledger Search",
    key: "slJESearch",
    ariaLabel: "Subledger JE Search",
    // "data-id": "automation_id_22235",
    target: "_blank",
    onClick: openInNewTab(props.configuration.SubledgerSearchLink),
    isSelected: location.pathname === "/sl/search"
  });

  return {
    name: "Subledger",
    key: "sl",
    ariaLabel: "Subledger",
    icon: "DietPlanNotebook",
    // "data-id": "automation_id_22235",
    target: "_blank",
    links
  };
};
