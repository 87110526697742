import { useContext } from "react";
import { v4 } from "uuid";
import { JemConfiguration } from "../../JemConfiguration";
import {
  ActionTypes,
  GeneralLedgerAction,
  GeneralLedgerActionResult,
  GLActionEndpointMap,
  PreReviewActionPayload,
  SAPReversalActionPayload,
  SAPReversalResponse
} from "../../Shared/components/ActionManager/ActionsManager.types";
import { GLAttachment } from "../../Shared/components/Attachments/Attachments.types";
import { JemNotification, LoggingContext } from "../../Shared/contexts/LoggingContext/LoggingContext";
import { UserContext } from "../../Shared/contexts/UserContext/UserContext";
import { getValidUrl, postRequest } from "../../Shared/utilities/RequestUtilities";
import { LegacyAttachmentUploadPayload } from "../components/GLDetails/Actions/GLDetailsActions";
import { AliasUtilities } from "../utilities/AliasUtilities";
import { toWorkflowPayload, WorkflowActionType } from "./GL.ActionsRequests/WorkflowRequests";
import { GLDashboardRow } from "./GLDashboardRow";
import { iAttachmentToRegStoreAttachment } from "./LegacyAttachmentToIAttachment";

export interface AdditionalData {
  noActivityReason: string;
}

export interface AdditionalReverseJEData {
  reversalReason: string;
  postingDate: string;
  postingPeriod: string;
  attachments: GLAttachment[];
  regionKey: number | 0;
  comments: string;
}
export function useGLActionsRequests(
  configuration: JemConfiguration["GeneralLedgerApi"],
  source: string
): GLActionEndpointMap<GLDashboardRow, GeneralLedgerActionResult> {
  const user = useContext(UserContext);
  const logger = useContext(LoggingContext);

  const makeUrl = (endpoint: string) => {
    const base = configuration.baseApiUrl;
    return getValidUrl(`${base}${endpoint}`);
  };

  const actions: GLActionEndpointMap<GLDashboardRow, GeneralLedgerActionResult> = {
    [ActionTypes.save]: async (_items) => {
      const notification: JemNotification = {
        type: "Error",
        subjectHeader: "Save not implemented for GL"
      };
      const result: GeneralLedgerActionResult = {
        actionType: ActionTypes.save,
        notification,
        response: null
      };
      return result;
    },
    [ActionTypes.approve]: async (items, Comments: string) => {
      logger.appInsights?.trackEvent({
        name: "approve",
        properties: {
          refGuids: items.map((i) => i.refGuid).join(","),
          source
        }
      });
      if (items.length === 0) {
        const notification: JemNotification = {
          type: "Error",
          subjectHeader: "No items selected"
        };
        const result: GeneralLedgerActionResult = {
          actionType: ActionTypes.approve,
          notification,
          response: null
        };
        return result;
      }
      const isDraft = items[0].isDraft;
      if (isDraft) {
        // return error
        const result: GeneralLedgerActionResult = {
          actionType: ActionTypes.approve,
          notification: {
            type: "Error",
            subjectHeader: "Cannot approve draft"
          },
          response: null
        };
        return result;
      }

      const payload = toWorkflowPayload({
        items,
        comments: Comments,
        actionType: WorkflowActionType.Approve,
        alias: user.user.alias,
        clarifications: []
      });
      const endpoint = makeUrl(configuration.endpoints.dashboardWorkflowGuid).replace("{wfGUID}", v4());
      const response = await postRequest<GeneralLedgerAction>(endpoint, payload, user.accessToken);
      if (!response) {
        const result: GeneralLedgerActionResult = {
          actionType: ActionTypes.approve,
          notification: {
            type: "Error",
            subjectHeader: "Error approving"
          },
          response: null
        };
        return result;
      }
      const result: GeneralLedgerActionResult = {
        actionType: ActionTypes.approve,
        notification: {
          type: "Success",
          subjectHeader: "Approved"
        },
        response: response
      };
      return result;
    },
    [ActionTypes.sendToPoster]: async (items, poster: string, backupPosters: string[], comments: string) => {
      logger.appInsights?.trackEvent({
        name: "sendToPoster",
        properties: {
          refGuids: items.map((i) => i.refGuid).join(","),
          source
        }
      });

      if (items.length === 0) {
        const notification: JemNotification = {
          type: "Error",
          subjectHeader: "No items selected"
        };
        const result: GeneralLedgerActionResult = {
          actionType: ActionTypes.sendToPoster,
          notification,
          response: null
        };
        return result;
      }

      const isDraft = items[0].isDraft;
      if (isDraft) {
        const payload = {
          comments: comments,
          guid: items.map((item) => item.refGuid),
          userAliases: AliasUtilities.getSingleAliasesString(poster, backupPosters)
        };
        const url = makeUrl(configuration.endpoints.dashboardDraftSendPoster);
        const response = await postRequest<GeneralLedgerAction>(url, payload, user.accessToken);
        if (!response?.status) {
          const notification: JemNotification = {
            type: "Error",
            subjectHeader: `Error sending to poster -${response?.message}`
          };
          const result: GeneralLedgerActionResult = {
            actionType: ActionTypes.sendToPoster,
            notification,
            response: null
          };
          return result;
        }
        return {
          actionType: ActionTypes.sendToPoster,
          notification: {
            type: "Success",
            subjectHeader: `Successfully sent ${items.length} to poster`
          },
          response: response
        };
      }
      const notification: JemNotification = {
        type: "Error",
        subjectHeader: "Error sending to poster for non drafts"
      };
      const result: GeneralLedgerActionResult = {
        actionType: ActionTypes.sendToPoster,
        notification,
        response: null
      };
      return result;
    },
    [ActionTypes.releaseForSignoff]: async (items, Comments: string) => {
      logger.appInsights?.trackEvent({
        name: "releaseForSignoff",
        properties: {
          refGuids: items.map((i) => i.refGuid).join(","),
          source
        }
      });
      if (items.length === 0) {
        const notification: JemNotification = {
          type: "Error",
          subjectHeader: "No items selected"
        };
        const result: GeneralLedgerActionResult = {
          actionType: ActionTypes.releaseForSignoff,
          notification,
          response: null
        };
        return result;
      }
      const isDraft = items[0].isDraft;
      if (isDraft) {
        // return error
        const result: GeneralLedgerActionResult = {
          actionType: ActionTypes.releaseForSignoff,
          notification: {
            type: "Error",
            subjectHeader: "Cannot release for signoff draft"
          },
          response: null
        };
        return result;
      }

      const payload = toWorkflowPayload({
        items,
        comments: Comments,
        actionType: WorkflowActionType.ReleaseForSignOff,
        alias: user.user.alias,
        clarifications: []
      });
      const endpoint = makeUrl(configuration.endpoints.dashboardWorkflowGuid).replace("{wfGUID}", v4());
      const response = await postRequest<GeneralLedgerAction>(endpoint, payload, user.accessToken);
      if (!response) {
        const result: GeneralLedgerActionResult = {
          actionType: ActionTypes.releaseForSignoff,
          notification: {
            type: "Error",
            subjectHeader: "Error Releasing for Signoff"
          },
          response: null
        };
        return result;
      }
      const result: GeneralLedgerActionResult = {
        actionType: ActionTypes.releaseForSignoff,
        notification: {
          type: "Success",
          subjectHeader: "Released for Signoff"
        },
        response: response
      };
      return result;
    },
    [ActionTypes.needsClarification]: async (items, Comments: string, clarificationCodes: string[]) => {
      logger.appInsights?.trackEvent({
        name: "needsClarification",
        properties: {
          refGuids: items.map((i) => i.refGuid).join(","),
          source
        }
      });
      if (items.length === 0) {
        const notification: JemNotification = {
          type: "Error",
          subjectHeader: "No items selected"
        };
        const result: GeneralLedgerActionResult = {
          actionType: ActionTypes.needsClarification,
          notification,
          response: null
        };
        return result;
      }

      const isDraft = items[0].isDraft;
      if (isDraft) {
        const payload = {
          comments: Comments,
          guid: items.map((item) => item.refGuid)
        };
        const url = makeUrl(configuration.endpoints.cNeedsClarificationDraft);
        const response = await postRequest<GeneralLedgerAction>(url, payload, user.accessToken);
        if (!response) {
          const notification: JemNotification = {
            type: "Error",
            subjectHeader: "Error requesting Clarification"
          };
          const result: GeneralLedgerActionResult = {
            actionType: ActionTypes.needsClarification,
            notification,
            response: null
          };
          return result;
        }
        return {
          actionType: ActionTypes.needsClarification,
          notification: {
            type: "Success",
            subjectHeader: `Successfully requested clarification for ${items.length} records`
          },
          response
        };
      }

      const payload = toWorkflowPayload({
        items,
        comments: Comments,
        actionType: WorkflowActionType.Clarify,
        alias: user.user.alias,
        clarifications: clarificationCodes
      });
      const endpoint = makeUrl(configuration.endpoints.dashboardWorkflowGuid).replace("{wfGUID}", v4());
      const response = await postRequest<GeneralLedgerAction>(endpoint, payload, user.accessToken);
      if (!response) {
        const result: GeneralLedgerActionResult = {
          actionType: ActionTypes.needsClarification,
          notification: {
            type: "Error",
            subjectHeader: "Error sending for Needs Clarification"
          },
          response: null
        };
        return result;
      }
      const result: GeneralLedgerActionResult = {
        actionType: ActionTypes.needsClarification,
        notification: {
          type: "Success",
          subjectHeader: "Sent for Clarification"
        },
        response
      };
      return result;
    },
    [ActionTypes.addPoster]: async (items, poster: string, backupPosters: string[]) => {
      logger.appInsights?.trackEvent({
        name: "addPoster",
        properties: {
          refGuids: items.map((i) => i.refGuid).join(","),
          source
        }
      });

      if (items.length === 0) {
        const notification: JemNotification = {
          type: "Error",
          subjectHeader: "No items selected"
        };
        const result: GeneralLedgerActionResult = {
          actionType: ActionTypes.addPoster,
          notification,
          response: null
        };
        return result;
      }

      const isDraft = items[0].isDraft;
      if (isDraft) {
        const payload = {
          guid: items.map((item) => item.refGuid),
          userAliases: AliasUtilities.getSingleAliasesString(poster, backupPosters)
        };
        const url = makeUrl(configuration.endpoints.dashboardDraftAddPoster);
        const response = await postRequest<GeneralLedgerAction>(url, payload, user.accessToken);
        if (!response) {
          const notification: JemNotification = {
            type: "Error",
            subjectHeader: "Error sending to poster"
          };
          const result: GeneralLedgerActionResult = {
            actionType: ActionTypes.addPoster,
            notification,
            response: null
          };
          return result;
        }
        return {
          actionType: ActionTypes.addPoster,
          notification: {
            type: "Success",
            subjectHeader: `Successfully performed action to Add Posters, please review Processing Status column`
          },
          response
        };
      }

      const payload = {
        ids: items.map((item) => item.jeId),
        newPoster: AliasUtilities.getSingleAliasesString(poster, backupPosters)
      };
      const url = makeUrl(configuration.endpoints.dashboardAddPoster);
      const response = await postRequest<GeneralLedgerAction>(url, payload, user.accessToken);
      if (!response) {
        const notification: JemNotification = {
          type: "Error",
          subjectHeader: "Error sending to poster"
        };
        const result: GeneralLedgerActionResult = {
          actionType: ActionTypes.addPoster,
          notification,
          response: null
        };
        return result;
      }
      return {
        actionType: ActionTypes.addPoster,
        notification: {
          type: "Success",
          subjectHeader: `Successfully performed action to Add Posters, please review Processing Status column`
        },
        response
      };
    },
    [ActionTypes.addReviewer]: async (items, reviewer: string, additionalReviewers: string[]) => {
      logger.appInsights?.trackEvent({
        name: "addReviewer",
        properties: {
          refGuids: items.map((i) => i.refGuid).join(","),
          source
        }
      });

      if (items.length === 0) {
        const notification: JemNotification = {
          type: "Error",
          subjectHeader: "No items selected"
        };
        const result: GeneralLedgerActionResult = {
          actionType: ActionTypes.addReviewer,
          notification,
          response: null
        };
        return result;
      }
      const isDraft = items[0].isDraft;
      if (isDraft) {
        // return error
        const result: GeneralLedgerActionResult = {
          actionType: ActionTypes.addReviewer,
          notification: {
            type: "Error",
            subjectHeader: "Not implemented for draft"
          },
          response: null
        };
        return result;
      }

      const payload = {
        jeIds: items.map((item) => item.jeId),
        reviewer: AliasUtilities.getSingleAliasesString(reviewer, additionalReviewers)
      };
      const url = makeUrl(configuration.endpoints.dashboardAddReviewer);
      const response = await postRequest<GeneralLedgerAction>(url, payload, user.accessToken);
      console.log(response);
      if (!response) {
        const result: GeneralLedgerActionResult = {
          actionType: ActionTypes.addReviewer,
          notification: {
            type: "Error",
            subjectHeader: "Error sending to reviewer"
          },
          response: null
        };
        return result;
      }
      return {
        actionType: ActionTypes.addReviewer,
        notification: {
          type: "Success",
          subjectHeader: `Successfully performed action to Add Reviewers, please review Processing Status column`
        },
        response
      };
    },
    [ActionTypes.recall]: async (items, Comments: string) => {
      logger.appInsights?.trackEvent({
        name: "recall",
        properties: {
          refGuids: items.map((i) => i.refGuid).join(","),
          source
        }
      });

      if (items.length === 0) {
        const notification: JemNotification = {
          type: "Error",
          subjectHeader: "No items selected"
        };
        const result: GeneralLedgerActionResult = {
          actionType: ActionTypes.addPoster,
          notification,
          response: null
        };
        return result;
      }

      const isDraft = items[0].isDraft;
      if (isDraft) {
        const payload = {
          guid: items.map((item) => item.refGuid),
          comments: Comments
        };
        const url = makeUrl(configuration.endpoints.dashboardDraftRecall);
        const response = await postRequest<GeneralLedgerAction>(url, payload, user.accessToken);
        if (!response) {
          const notification: JemNotification = {
            type: "Error",
            subjectHeader: "Error Recalling"
          };
          const result: GeneralLedgerActionResult = {
            actionType: ActionTypes.recall,
            notification,
            response: null
          };
          return result;
        }
        return {
          actionType: ActionTypes.recall,
          notification: {
            type: "Success",
            subjectHeader: `Successfully recalled ${items.length} JEs`
          },
          response
        };
      }

      const payload = toWorkflowPayload({
        items,
        comments: Comments,
        actionType: WorkflowActionType.Recall,
        alias: user.user.alias,
        clarifications: []
      });
      const endpoint = makeUrl(configuration.endpoints.dashboardWorkflowGuid).replace("{wfGUID}", v4());
      const response = await postRequest<GeneralLedgerAction>(endpoint, payload, user.accessToken);
      if (!response) {
        const result: GeneralLedgerActionResult = {
          actionType: ActionTypes.recall,
          notification: {
            type: "Error",
            subjectHeader: "Error recalling"
          },
          response: null
        };
        return result;
      }
      const result: GeneralLedgerActionResult = {
        actionType: ActionTypes.recall,
        notification: {
          type: "Success",
          subjectHeader: `Recalled ${items.length} JEs`
        },
        response: response
      };
      return result;
    },
    [ActionTypes.addAttachment]: async (
      items,
      attachments: GLAttachment[],
      regionKey: number,
      _regionName: string,
      isRescind?: boolean
    ) => {
      logger.appInsights?.trackEvent({
        name: "addAttachment",
        properties: {
          refGuids: items.map((i) => i.refGuid).join(","),
          source
        }
      });
      const uploadItems = async (jeIds: number[], prefix: string) => {
        const attachmentsForPrefix = attachments.filter((attachment) => attachment.blobUriPath === prefix);
        let attachmentPayload = attachmentsForPrefix.map((attachment) =>
          iAttachmentToRegStoreAttachment(regionKey, attachment)
        );
        if (isRescind === true) {
          attachmentPayload = attachmentPayload.map((attachment) => {
            attachment.IsRescindEvidence = true;
            return attachment;
          });
        }

        const payload: LegacyAttachmentUploadPayload = [
          {
            JeIds: jeIds,
            Attachments: {
              RegStoreAttachments: attachmentPayload
            }
          }
        ];
        const endpoint = makeUrl(configuration.endpoints.attachmentUpdate);
        const response = await postRequest(endpoint, payload, user.accessToken);
        if (!response) {
          const result: GeneralLedgerActionResult = {
            actionType: ActionTypes.addAttachment,
            notification: {
              type: "Error",
              subjectHeader: "Error adding attachment"
            },
            response: null
          };
          return result;
        }
        const result: GeneralLedgerActionResult = {
          actionType: ActionTypes.addAttachment,
          notification: {
            type: "Success",
            subjectHeader: `Added attachment to JEs ${jeIds}`
          },
          response: null
        };
        return result;
      };

      // FIRST WE MUST GET ALL PREFIXES (Meaning the Path in Azure Storage)
      const prefixes = new Set<string>();
      for (const att of attachments) {
        prefixes.add(att.blobUriPath);
      }
      // THEN FOR EACH PREFIX WE GET THE SETS OF JEIDS AND ATTACHMENTS THAT WILL GET
      // ATTACHED TO THAT PREFIX
      // Attachments is an array of many
      // items is an array of many as well
      // There's still a possibility of a duplicate if there's a bulk with items in different FYFP
      // To create a duplicate: call the attachment endpoint twice with different JEID that belong to the same bulk
      const results = await Promise.all(
        [...prefixes].map((prefix) => {
          // filter out items that FYFP does not match prefix
          const jeIds = items
            .filter((item) => prefix.indexOf(`${item.fiscalYear}/${item.fiscalPeriod}`) !== -1)
            .map((item) => Number(item.jeId));
          return uploadItems(jeIds, prefix);
        })
      );
      const success = results.every((result) => result.notification.type === "Success");
      if (success) {
        return {
          actionType: ActionTypes.addAttachment,
          notification: {
            type: "Success",
            subjectHeader: `Successfully added attachment${attachments.length === 1 ? "" : "s"}`
          },
          response: null
        };
      }

      return {
        actionType: ActionTypes.addAttachment,
        notification: {
          type: "Error",
          subjectHeader: `Could not attach file${attachments.length === 1 ? "" : "s"}.`
        },
        response: null
      };
    },
    [ActionTypes.deleteAction]: async (items) => {
      logger.appInsights?.trackEvent({
        name: "deleteAction",
        properties: {
          refGuids: items.map((i) => i.refGuid).join(","),
          source
        }
      });

      const allDrafts = items.every((item) => item.isDraft);
      if (!allDrafts) {
        return {
          actionType: ActionTypes.deleteAction,
          notification: {
            type: "Error",
            subjectHeader: "Delete is not supported on Non Drafts"
          },
          response: null
        };
      }

      const guids = items.map((item) => item.refGuid);
      const payload = {
        guid: guids,
        userAliases: "",
        comments: "",
        actionType: "User"
      };
      const endpoint = makeUrl(configuration.endpoints.dashboardDraftDelete);
      const response = await postRequest(endpoint, payload, user.accessToken);
      if (!response) {
        return {
          actionType: ActionTypes.deleteAction,
          notification: {
            type: "Error",
            subjectHeader: "Delete failed"
          },
          response: null
        };
      }
      return {
        actionType: ActionTypes.deleteAction,
        notification: {
          type: "Success",
          subjectHeader: `Deleted ${guids.length} Drafts`
        },
        response: null
      };
    },
    [ActionTypes.approvePreReview]: async (items, Comments: string) => {
      logger.appInsights?.trackEvent({
        name: "approvePreReview",
        properties: {
          refGuids: items.map((i) => i.refGuid).join(","),
          source
        }
      });
      if (items.length === 0) {
        const notification: JemNotification = {
          type: "Error",
          subjectHeader: "No items selected"
        };
        const result: GeneralLedgerActionResult = {
          actionType: ActionTypes.approvePreReview,
          notification,
          response: null
        };
        return result;
      }
      const regularJE = items.filter((item) => item.postingType === "1" || item.postingType === "4");
      const batchJE = items.filter(
        (item) => item.postingType == "3" || item.postingType == "5" || item.postingType == "7"
      );
      const payload: PreReviewActionPayload = {
        jeIds: regularJE ? regularJE.map((item) => item.jeId) : [],
        batchGuids: batchJE ? batchJE.map((item) => item.refGuid) : [],
        comments: Comments
      };
      const url = makeUrl(configuration.endpoints.approvePreReview);
      const response = await postRequest<GeneralLedgerAction>(url, payload, user.accessToken);

      if (!response) {
        const notification: JemNotification = {
          type: "Error",
          subjectHeader: "Error Approving"
        };
        const result: GeneralLedgerActionResult = {
          actionType: ActionTypes.approvePreReview,
          notification,
          response: null
        };
        return result;
      }
      return {
        actionType: ActionTypes.approvePreReview,
        notification: {
          type: "Success",
          subjectHeader: `Successfully approved ${items.length} JEs`
        },
        response
      };
    },
    [ActionTypes.recallPreReview]: async (items, Comments: string) => {
      logger.appInsights?.trackEvent({
        name: "recallPreReview",
        properties: {
          refGuids: items.map((i) => i.refGuid).join(","),
          source
        }
      });
      if (items.length === 0) {
        const notification: JemNotification = {
          type: "Error",
          subjectHeader: "No items selected"
        };
        const result: GeneralLedgerActionResult = {
          actionType: ActionTypes.recallPreReview,
          notification,
          response: null
        };
        return result;
      }
      const regularJE = items.filter((item) => item.postingType === "1" || item.postingType === "4");
      const batchJE = items.filter(
        (item) => item.postingType == "3" || item.postingType == "5" || item.postingType == "7"
      );
      const payload: PreReviewActionPayload = {
        jeDraftGuids: regularJE ? regularJE.map((item) => item.refGuid) : [],
        batchGuids: batchJE ? batchJE.map((item) => item.refGuid) : [],
        comments: Comments
      };
      const url = makeUrl(configuration.endpoints.recallPreReview);
      const response = await postRequest<GeneralLedgerAction>(url, payload, user.accessToken);
      if (!response) {
        const notification: JemNotification = {
          type: "Error",
          subjectHeader: "Error Recalling"
        };
        const result: GeneralLedgerActionResult = {
          actionType: ActionTypes.recallPreReview,
          notification,
          response: null
        };
        return result;
      }
      return {
        actionType: ActionTypes.recallPreReview,
        notification: {
          type: "Success",
          subjectHeader: `Successfully recalled ${items.length} JEs`
        },
        response
      };
    },
    [ActionTypes.needsClarificationPreReview]: async (items, Comments: string, clarificationCodes: string[]) => {
      logger.appInsights?.trackEvent({
        name: "needsClarificationPreReview",
        properties: {
          refGuids: items.map((i) => i.refGuid).join(","),
          source
        }
      });
      if (items.length === 0) {
        const notification: JemNotification = {
          type: "Error",
          subjectHeader: "No items selected"
        };
        const result: GeneralLedgerActionResult = {
          actionType: ActionTypes.needsClarificationPreReview,
          notification,
          response: null
        };
        return result;
      }

      const regularJE = items.filter((item) => item.postingType === "1" || item.postingType === "4");
      const batchJE = items.filter(
        (item) => item.postingType == "3" || item.postingType == "5" || item.postingType == "7"
      );
      const payload: PreReviewActionPayload = {
        jeIds: regularJE ? regularJE.map((item) => item.jeId) : [],
        batchGuids: batchJE ? batchJE.map((item) => item.refGuid) : [],
        comments: Comments,
        clarification: clarificationCodes[0]
      };
      const url = makeUrl(configuration.endpoints.needsClarificationPreReview);
      const response = await postRequest<GeneralLedgerAction>(url, payload, user.accessToken);
      if (!response) {
        const notification: JemNotification = {
          type: "Error",
          subjectHeader: "Error requesting Clarification"
        };
        const result: GeneralLedgerActionResult = {
          actionType: ActionTypes.needsClarificationPreReview,
          notification,
          response: null
        };
        return result;
      }
      return {
        actionType: ActionTypes.needsClarificationPreReview,
        notification: {
          type: "Success",
          subjectHeader: `Successfully requested clarification for ${items.length} records`
        },
        response
      };
    },
    [ActionTypes.reverse]: async (
      items,
      reversalReason: string,
      postingDate: string,
      postingPeriod: string,
      attachments: GLAttachment[],
      regionKey: number,
      comments: string
    ) => {
      logger.appInsights?.trackEvent({
        name: "reverse",
        properties: {
          refGuids: items.map((i) => i.refGuid).join(","),
          source
        }
      });
      if (items.length === 0) {
        const notification: JemNotification = {
          type: "Error",
          subjectHeader: "No items selected"
        };
        const result: GeneralLedgerActionResult = {
          actionType: ActionTypes.reverse,
          notification,
          response: null
        };
        return result;
      }

      const regularJE = items.filter(
        (item) =>
          item.statusCode === 7 ||
          item.statusCode === 8 ||
          item.statusCode === 9 ||
          item.statusCode === 10 ||
          item.statusCode === 12
      );

      let attachmentPayload;

      // FIRST WE MUST GET ALL PREFIXES (Meaning the Path in Azure Storage)
      const prefixes = new Set<string>();
      for (const att of attachments) {
        prefixes.add(att.blobUriPath);
      }
      // THEN FOR EACH PREFIX WE GET THE SETS OF JEIDS AND ATTACHMENTS THAT WILL GET
      // ATTACHED TO THAT PREFIX
      // Attachments is an array of many
      // items is an array of many as well
      // There's still a possibility of a duplicate if there's a bulk with items in different FYFP
      // To create a duplicate: call the attachment endpoint twice with different JEID that belong to the same bulk
      const results = await Promise.all(
        [...prefixes].map((prefix) => {
          // filter out items that FYFP does not match prefix
          const jeIds = regularJE
            .filter((item) => prefix.indexOf(`${item.fiscalYear}/${item.fiscalPeriod}`) !== -1)
            .map((item) => Number(item.jeId));
          const attachmentsForPrefix = attachments.filter((attachment) => attachment.blobUriPath === prefix);
          attachmentPayload = attachmentsForPrefix.map((attachment) =>
            iAttachmentToRegStoreAttachment(regionKey, attachment)
          );
        })
      );

      const payload: SAPReversalActionPayload = {
        jeIds: regularJE ? regularJE.map((item) => item.jeId) : [],
        reversalReason: reversalReason,
        postingDate: postingDate,
        postingPeriod: postingPeriod,
        attachments: attachmentPayload
          ? {
              RegStoreAttachments: attachmentPayload
            }
          : null,
        reversalComment: comments || ""
      };
      const url = makeUrl(configuration.endpoints.postReverseJEs);
      const response = await postRequest<SAPReversalResponse[]>(url, payload, user.accessToken);
      if (!response || response?.length === undefined) {
        const result: GeneralLedgerActionResult = {
          actionType: ActionTypes.reverse,
          notification: {
            type: "Error",
            subjectHeader: "Error posting reversals, please try again"
          },
          response: null
        };
        return result;
      }
      let successCount = 0,
        errorCount = 0;
      let errors = "";
      response?.forEach((item) => {
        item.message === "Document Posted Successfully" || response[0].message === "Send it for background processing"
          ? successCount++
          : errorCount++;
        if (errorCount > 0) {
          errors += item.message;
        }
      });

      if (successCount === regularJE.length) {
        return {
          actionType: ActionTypes.reverse,
          notification: {
            type: "Success",
            subjectHeader: `Successfully send reversals to SAP for ${regularJE.length} records, Please check Reversal Doc Number column after few minutes.`
          },
          response: null
        };
      }
      return {
        actionType: ActionTypes.reverse,
        notification: {
          type: "Error",
          subjectHeader: `Partial/all (${errorCount}/${regularJE.length} JEs) posting for reversals are failed, Please try again. ${errors}`
        },
        response: null
      };
    }
  };
  const addAttachmentAction = actions.addAttachment;
  actions[ActionTypes.rescind] = async (
    items,
    _comments: string,
    _attachments: GLAttachment[],
    regionKey: number,
    regionName: string
  ) => {
    logger.appInsights?.trackEvent({
      name: "rescind",
      properties: {
        refGuids: items.map((i) => i.refGuid).join(","),
        source
      }
    });

    if (items.length === 0) {
      const notification: JemNotification = {
        type: "Error",
        subjectHeader: "No items selected"
      };
      const result: GeneralLedgerActionResult = {
        actionType: ActionTypes.rescind,
        notification,
        response: null
      };
      return result;
    }
    const isDraft = items[0].isDraft;
    if (isDraft) {
      // return error
      const result: GeneralLedgerActionResult = {
        actionType: ActionTypes.rescind,
        notification: {
          type: "Error",
          subjectHeader: "Cannot Rescind"
        },
        response: null
      };
      return result;
    }
    if (!addAttachmentAction) {
      const result: GeneralLedgerActionResult = {
        actionType: ActionTypes.rescind,
        notification: {
          type: "Error",
          subjectHeader: "Cannot Rescind because addAttachmentAction is not defined"
        },
        response: null
      };
      return result;
    }

    const attachmentResult = await addAttachmentAction(items, _attachments, regionKey, regionName, true);
    if (attachmentResult.notification.type !== "Success") {
      return attachmentResult;
    }

    // TODO: Requires Attachment Handling
    const payload = toWorkflowPayload({
      items,
      comments: _comments,
      actionType: WorkflowActionType.Rescind,
      alias: user.user.alias,
      clarifications: []
    });
    const endpoint = makeUrl(configuration.endpoints.dashboardWorkflowGuid).replace("{wfGUID}", v4());
    const response = await postRequest<GeneralLedgerAction>(endpoint, payload, user.accessToken);
    if (!response) {
      const result: GeneralLedgerActionResult = {
        actionType: ActionTypes.rescind,
        notification: {
          type: "Error",
          subjectHeader: "Error rescinding"
        },
        response: null
      };
      return result;
    }
    const result: GeneralLedgerActionResult = {
      actionType: ActionTypes.recall,
      notification: {
        type: "Success",
        subjectHeader: `Rescinded ${items.length} JEs`
      },
      response: response
    };
    return result;
  };

  return actions;
}
