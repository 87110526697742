import { JemConfiguration } from "../../../JemConfiguration";
import { ApiError } from "../../utilities/ErrorHelpers";
import { getValidUrl, IUserProviderState, postRequest } from "../../utilities/RequestUtilities";
import { BulkItemFromApi } from "./BulkDetailsFromJeId.schema";

export async function fetchBulkDetails(
  configuration: JemConfiguration["GeneralLedgerApi"],
  getTokenFn: IUserProviderState["accessToken"],
  jeId: string
): Promise<BulkItemFromApi[] | null> {
  const endpoint = `${configuration.baseApiUrl}${configuration.endpoints.fetchBulkDetails}`;
  const endpointUrl = getValidUrl(endpoint);
  const responseObject = await postRequest<BulkItemFromApi[]>(endpointUrl, jeId, getTokenFn);
  if (!responseObject) {
    throw new ApiError("Can't retrieve Bulk Details.");
  }
  return responseObject;
}
