import { Pivot, PivotItem, useTheme } from "@fluentui/react";
import { useFormikContext } from "formik";
import React, { useContext, useRef } from "react";
import { JemConfiguration } from "../../../JemConfiguration";
import { DomainDataEnum, DomainDataObjects, LoadingStatus, UserContext } from "../../../Shared";
import { AliasChangeModal, AliasChangeModalRef } from "../../../Shared/components/AliasChangeModal/AliasChangeModal";
import { LocalAttachment, IAttachment } from "../../../Shared/components/Attachments/Attachments.types";
import { GenericDialog, GenericDialogRef } from "../../../Shared/components/GenericDialog/GenericDialog";
import { GLAttachmentEndpointMap, GLAttachments, GLAttachmentsRef } from "../../shared";
import { OnChangeChecklistOrPeriodHandlers } from "../../shared/ChecklistPeriodChangeHandler";
import { PivotItemProps, _customRenderer } from "../GLCreate/GLCreatePivotItems.utils";
import { GLReviewersTab } from "../GLCreate/GLCreateTabs/GLCreate.Tab.Reviewers";
import { GLBatchPageState } from "./GLBatch.State";
import { GLBatchFormikState, GLBatchTabNames } from "./GLBatch.types";
import { GLBatchDetails, GLBatchErrorDetailsTab, GLBatchPostersTab } from "./GLBatchTabs";

export interface GLBatchPivotItemsProps {
  configuration: JemConfiguration["GeneralLedgerApi"];
  disabled: boolean;
  loadingStatus: LoadingStatus;
  currentTab: GLBatchTabNames;
  onTabChange: (item: PivotItem) => void;
  initialState: GLBatchPageState;
  domainData: Pick<
    DomainDataObjects,
    DomainDataEnum.JeReasonCodes | DomainDataEnum.FiscalPeriods | DomainDataEnum.JeBatchFields
  >;
  attachmentsRef: React.Ref<GLAttachmentsRef>;
  onUploadFile: GLAttachmentEndpointMap["uploadAttachment"];
  onDeleteFile: GLAttachmentEndpointMap["deleteAttachment"];
  onDownloadFile: GLAttachmentEndpointMap["downloadAttachment"];
}

const GLBatchPivotItems: React.FC<GLBatchPivotItemsProps> = (props) => {
  const formik = useFormikContext<GLBatchFormikState>();
  const theme = useTheme();
  const userContext = useContext(UserContext);
  const genericDialogRef = useRef<GenericDialogRef>(null);
  const aliasChangeModalRef = useRef<AliasChangeModalRef>(null);
  return (
    <>
      <Pivot
        aria-label="Batch JE Input Sections"
        selectedKey={props.currentTab}
        onLinkClick={(item?: PivotItem) => {
          if (item) {
            props.onTabChange(item);
          }
        }}
        styles={{
          root: {
            backgroundColor: theme.palette.neutralLighterAlt,
            ":after": {
              content: "''",
              height: "1px",
              display: "block",
              width: "100%",
              backgroundColor: theme.palette.neutralTertiaryAlt
            }
          }
        }}
      >
        <PivotItem headerText="Batch Details" itemKey={GLBatchTabNames.batchDetails}>
          <div {...PivotItemProps}>
            <GLBatchDetails
              configuration={props.configuration}
              pageState={props.initialState}
              jemUser={userContext.jemUser}
              disabled={props.disabled}
              loadingStatus={props.loadingStatus}
              domainData={props.domainData}
              {...OnChangeChecklistOrPeriodHandlers(
                props.domainData,
                formik,
                true,
                genericDialogRef,
                aliasChangeModalRef
              )}
            />
          </div>
        </PivotItem>
        <PivotItem
          headerText={`Attachments(${formik.values.attachments_count})`}
          itemKey={GLBatchTabNames.attachments}
          alwaysRender={true}
        >
          <div {...PivotItemProps}>
            <GLAttachments
              customRef={props.attachmentsRef}
              initialAttachments={props.initialState.batchState.attachments}
              onDeleteFile={props.onDeleteFile}
              onUploadFile={props.onUploadFile}
              onDownloadFile={props.onDownloadFile}
              disabled={props.disabled}
              loadingStatus={props.loadingStatus}
              initialRegion={props.initialState.batchState.attachments_region.toString()}
              lockRegion={props.initialState.batchState.lock_region}
              onChange={(
                localAttachments: LocalAttachment[],
                remoteAttachments: IAttachment[],
                errorMessage: string | null
              ) => {
                const newCount = localAttachments.length + remoteAttachments.length;
                formik.setFieldValue("attachments_count", newCount);
                if (errorMessage) {
                  formik.setFieldValue("attachments_error", errorMessage);
                }
              }}
              items={[
                {
                  fiscalPeriod: props.initialState.batchState.fiscalPeriod,
                  fiscalYear: props.initialState.batchState.fiscalYear,
                  refGuid: props.initialState.batchState.refGuid,
                  jeId: undefined,
                  format: formik.values.detailsTabIsBulkType ? "bulk" : "batch"
                }
              ]}
            />
          </div>
        </PivotItem>
        <PivotItem headerText="Posters" itemKey={GLBatchTabNames.posters} alwaysRender={true}>
          <div {...PivotItemProps}>
            <GLBatchPostersTab disabled={props.disabled} loadingStatus={props.loadingStatus} />
          </div>
        </PivotItem>
        <PivotItem headerText="Reviewers" itemKey={GLBatchTabNames.reviewers} alwaysRender={true}>
          <div {...PivotItemProps}>
            <GLReviewersTab disabled={props.disabled} loadingStatus={props.loadingStatus} />
          </div>
        </PivotItem>
        <PivotItem
          headerText="Error Details"
          itemKey={GLBatchTabNames.errorDetails}
          alwaysRender={true}
          onRenderItemLink={_customRenderer}
          itemIcon={
            !props.disabled &&
            (Object.keys(formik.errors).length !== 0 || formik.values.errorTabErrorTable.length !== 0)
              ? "Error"
              : undefined
          }
        >
          <div {...PivotItemProps}>
            <GLBatchErrorDetailsTab />
          </div>
        </PivotItem>
      </Pivot>
      <GenericDialog customRef={genericDialogRef} />
      <AliasChangeModal customRef={aliasChangeModalRef} />
    </>
  );
};

export default GLBatchPivotItems;
