import {
  BaseButton,
  Button,
  DefaultButton,
  Dropdown,
  IDropdownOption,
  PrimaryButton,
  Stack,
  TextField
} from "@fluentui/react";
import {
  JemConfiguration,
  JemNotification,
  IUserProviderState,
  postRequest,
  UserContext,
  IHCCContext,
  CompanyCodeSingleSelectorRef,
  IAliasInputRef,
  RefTextFieldRef,
  useSearchStateFromLocation,
  IFiscalPeriodsRef,
  ISignificanceRef,
  LoadingStatus,
  SearchAliasInput,
  RefTextField,
  CompanyCodeSingleSelector,
  FiscalPeriodsDropdown,
  SignificanceDropdown
} from "@jem/components";
import React, { useContext, useEffect, useRef, useState } from "react";
import { sanitizeRow } from "../IHCCDashboard/IHCCDashboard.requests";
import {
  convertToFiscalYearOptionSearch,
  convertToTenantOptionSearch,
  IHCCSanitizedSearchGrid,
  IHCCSearchApiPayload,
  ISearchStateForApi,
  POTypeOptions,
  SearchState
} from "./IHCCSearch.types";
import POStatus, { POStatusRef } from "./userInputFields/POStatus";
import { useSearchParams } from "react-router-dom";

interface ISearchFormProps {
  searchState: SearchState | undefined;
  configuration: JemConfiguration["IhccApi"];
  triggerRefreshFn: (searchState: SearchState, procStatusMessages: JemNotification) => void;
}

const emptyGrid = (): IHCCSanitizedSearchGrid => ({
  gridActions: [],
  result: []
});

function payloadToIhccSearchData(payload: IHCCSearchApiPayload): IHCCSanitizedSearchGrid {
  const sanitized: IHCCSanitizedSearchGrid = {
    gridActions: payload.gridActions,
    result: payload.result.map((x) => sanitizeRow("Search Results", x))
  };
  return sanitized;
}

export async function getGridItemsFromPayload(
  getTokenFn: IUserProviderState["accessToken"],
  {
    Status,
    StartPONumber,
    POType,
    Aliases,
    AliasType,
    EndPONumber,
    Significance,
    CompanyCode,
    FiscalYear,
    FiscalPeriods,
    TenantId
  }: SearchState,
  config: JemConfiguration["IhccApi"]
): Promise<IHCCSanitizedSearchGrid> {
  const data: ISearchStateForApi = {
    POStatus: Status.includes(0) ? [] : Status,
    StartPONumber: StartPONumber,
    POType: POType,
    AliasType: AliasType === "P" ? 1 : "2",
    Alias: Aliases.join(","),
    EndPONumber: EndPONumber,
    POCategory: Significance.includes("all") ? [] : Significance,
    CompanyCode: CompanyCode,
    FiscalYear: FiscalYear,
    FiscalValue: FiscalPeriods.includes(0) ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12] : FiscalPeriods,
    BankArea: "IHCC",
    TenantId: TenantId === -1 ? null : TenantId
  };
  try {
    const url = `${config.baseApiUrl}${config.endpoints.getSearchGrid}`;
    const gridData = await postRequest<IHCCSearchApiPayload>(url, data, getTokenFn);
    if (gridData === null) {
      return emptyGrid();
    }
    return payloadToIhccSearchData(gridData);
  } catch (e) {
    // return empty for now
    console.error(e);
    return emptyGrid();
  }
}

const SearchForm: React.FC<ISearchFormProps> = (props: ISearchFormProps) => {
  const { user } = useContext(UserContext);
  const { initInfo } = useContext(IHCCContext);

  const [searchParams, setSearchParams] = useSearchParams();

  const [fiscalYearsLoading, setFiscalYearsLoading] = useState(true);
  const [fiscalPeriodsLoading, setfiscalPeriodsLoading] = useState(true);
  const [tenantLoading, setTenantLoading] = useState(true);
  const [initSearch, setInitSearch] = useState(true);
  const companyCodesRef = useRef<CompanyCodeSingleSelectorRef>(null);
  const aliasesRef = useRef<IAliasInputRef>(null);
  const poStatusRef = useRef<POStatusRef>(null);
  const startPoRef = useRef<RefTextFieldRef>(null);
  const endPoRef = useRef<RefTextFieldRef>(null);
  const searchStateFromLocation = useSearchStateFromLocation(SearchState, () => {
    return true;
  });
  const fiscalPeriodRef = useRef<IFiscalPeriodsRef>(null);
  const significanceRef = useRef<ISignificanceRef>(null);
  const [fiscalYear, setfiscalYear] = useState<SearchState["FiscalYear"]>(
    initInfo.values ? initInfo.values.currentFiscalYear : new Date().getFullYear() + 1
  );
  const [poType, setPOType] = useState<SearchState["POType"]>("BO");
  const [tenant, setTenant] = useState<SearchState["TenantId"]>(-1);
  const onChangePOType = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void => {
    if (option) {
      setPOType(option.key as string);
    }
  };
  const onChangeFiscalYear = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void => {
    if (option) {
      setfiscalYear(option.key as number);
    }
  };
  const onChangeTenant = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void => {
    if (option) {
      setTenant(option.key as number);
    }
  };

  const reset = () => {
    const currentYear = initInfo.values ? initInfo.values.currentFiscalYear : new Date().getFullYear();

    if (startPoRef.current) {
      startPoRef.current.reset();
    }
    if (endPoRef.current) {
      endPoRef.current.reset();
    }
    setPOType("BO");
    setfiscalYear(currentYear);
    setTenant(-1);
    if (poStatusRef.current) {
      poStatusRef.current.reset();
    }
    if (aliasesRef.current) {
      aliasesRef.current.reset();
    }
    if (companyCodesRef.current) {
      companyCodesRef.current.reset();
    }
    if (fiscalPeriodRef.current) {
      fiscalPeriodRef.current.reset();
    }
    if (significanceRef.current) {
      significanceRef.current.reset();
    }

    const params: any = {};
    setSearchParams(params);
  };

  const submit = async (
    ev?: React.MouseEvent<
      HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | BaseButton | Button | HTMLSpanElement
    >
  ) => {
    ev?.preventDefault();
    setInitSearch(false);
    if (
      aliasesRef.current &&
      companyCodesRef.current &&
      fiscalPeriodRef.current &&
      significanceRef.current &&
      poStatusRef.current &&
      startPoRef.current &&
      endPoRef.current
    ) {
      const Aliases = aliasesRef.current.getAliases();
      const AliasType = aliasesRef.current.getAliasType();
      const CompanyCode = companyCodesRef.current.getCompanyCode();
      const FiscalPeriods = fiscalPeriodRef.current.getFiscalPeriods();
      const Significance = significanceRef.current.getSignificance();
      const statuses = poStatusRef.current.getStatuses();
      const startPoNumber = startPoRef.current.getValue();
      const endPoNumber = endPoRef.current.getValue();
      const currentState = new SearchState({
        Status: statuses,
        StartPONumber: startPoNumber,
        POType: poType,
        Aliases,
        AliasType,
        EndPONumber: endPoNumber,
        Significance,
        CompanyCode: CompanyCode || 0,
        FiscalYear: fiscalYear,
        FiscalPeriods,
        TenantId: tenant
      });
      props.triggerRefreshFn(currentState, {
        subjectHeader: " ",
        summaryBodyText: " ",
        type: "Information"
      });

      const params: any = {};
      if (Aliases.length > 0) params["user"] = Aliases.join(";");
      if (startPoNumber.length > 0) params["startdoc"] = startPoNumber;
      if (endPoNumber.length > 0) params["enddoc"] = endPoNumber;

      setSearchParams(params);
      setInitSearch(false);
    }
  };

  useEffect(() => {
    if (searchStateFromLocation !== undefined) {
      if (companyCodesRef.current) {
        companyCodesRef.current.setInitialCompanyCode(`${searchStateFromLocation.CompanyCode}`);
      }
      if (startPoRef.current) {
        startPoRef.current.setInitialValue(searchStateFromLocation.StartPONumber);
      }
      if (endPoRef.current) {
        endPoRef.current.setInitialValue(searchStateFromLocation.EndPONumber);
      }
      setPOType(searchStateFromLocation.POType);
      setfiscalYear(searchStateFromLocation.FiscalYear);
      setTenant(searchStateFromLocation.TenantId);
      if (poStatusRef.current) {
        poStatusRef.current.setInitialStatuses(searchStateFromLocation.Status);
      }
      if (aliasesRef.current) {
        aliasesRef.current.setInitialAliases({
          aliases: searchStateFromLocation.Aliases,
          aliasType: searchStateFromLocation.AliasType
        });
      }
      if (fiscalPeriodRef.current) {
        fiscalPeriodRef.current.setInitialFiscalPeriods(searchStateFromLocation.FiscalPeriods);
      }
      if (significanceRef.current) {
        significanceRef.current.setInitialSignificance(searchStateFromLocation.Significance);
      }
      props.triggerRefreshFn(searchStateFromLocation, {
        subjectHeader: " ",
        summaryBodyText: " ",
        type: "Information"
      });
    } else {
      if (initSearch) {
        if (startPoRef.current) {
          startPoRef.current.setInitialValue(searchParams.get("startdoc") || "");
        }
        if (endPoRef.current) {
          endPoRef.current.setInitialValue(searchParams.get("enddoc") || "");
        }
        if (aliasesRef.current) {
          const users = searchParams.get("user");
          aliasesRef.current.setInitialAliases({
            aliases: users ? users.split(";") : [user.alias],
            aliasType: "P"
          });
        }
        setTimeout(() => {
          submit();
        }, 100);
      } else {
        if (aliasesRef.current) {
          const users = searchParams.get("user");
          aliasesRef.current.setInitialAliases({
            aliases: users ? users.split(";") : [user.alias],
            aliasType: "P"
          });
        }
      }
    }
  }, [searchStateFromLocation, searchParams]);

  useEffect(() => {
    if (initInfo.status === LoadingStatus.Resolved) {
      initInfo.values && setfiscalYear(initInfo.values.currentFiscalYear);
      setFiscalYearsLoading(false);
      setfiscalPeriodsLoading(false);
      setTenantLoading(false);
    }
  }, [initInfo.status]);

  return (
    <React.Fragment>
      <Stack
        horizontal
        disableShrink
        horizontalAlign="center"
        wrap
        tokens={{ childrenGap: 8 }}
        styles={{
          root: {
            marginBottom: "8px"
          }
        }}
      >
        <Stack.Item
          styles={{
            root: {
              paddingRight: "30px"
            }
          }}
        >
          <Stack tokens={{ childrenGap: 4 }}>
            <Stack.Item>
              <POStatus customRef={poStatusRef} />
            </Stack.Item>
            <Stack.Item>
              <SearchAliasInput ref={aliasesRef} />
            </Stack.Item>
            <Stack.Item>
              <Dropdown
                label="Tenant"
                selectedKey={tenant}
                onChange={onChangeTenant}
                options={convertToTenantOptionSearch(initInfo.values?.tenants.map((x) => x) || [])}
                disabled={tenantLoading}
              />
            </Stack.Item>
          </Stack>
        </Stack.Item>
        <Stack.Item
          styles={{
            root: {
              paddingRight: "30px"
            }
          }}
        >
          <Stack tokens={{ childrenGap: 4 }}>
            <Stack.Item>
              <RefTextField customRef={startPoRef} label="Start PO Number" />
            </Stack.Item>
            <Stack.Item>
              <RefTextField customRef={endPoRef} label="End PO Number" />
            </Stack.Item>
            <Stack.Item>
              <CompanyCodeSingleSelector ref={companyCodesRef} context={"IHCC"} />
            </Stack.Item>
            <Stack.Item>
              <Stack horizontal tokens={{ childrenGap: 8 }}>
                <Stack.Item grow={2}>
                  <Dropdown
                    label="Fiscal Year"
                    selectedKey={fiscalYear}
                    onChange={onChangeFiscalYear}
                    options={convertToFiscalYearOptionSearch(initInfo.values?.fiscalYears.map((x) => x) || [])}
                    disabled={fiscalYearsLoading}
                  />
                </Stack.Item>
                <Stack.Item>
                  <FiscalPeriodsDropdown ref={fiscalPeriodRef} loading={fiscalPeriodsLoading} />
                </Stack.Item>
              </Stack>
            </Stack.Item>
          </Stack>
        </Stack.Item>
        <Stack.Item>
          <Stack tokens={{ childrenGap: 4 }}>
            <Stack.Item>
              <Dropdown label="PO Type" selectedKey={poType} onChange={onChangePOType} options={POTypeOptions} />
            </Stack.Item>
            <Stack.Item>
              <SignificanceDropdown ref={significanceRef} loading={false} />
            </Stack.Item>
            <Stack.Item
              styles={{
                root: {
                  paddingTop: "10px"
                }
              }}
            >
              <TextField label="Bank Area" underlined readOnly value={"IHCC"} />
            </Stack.Item>
            <Stack horizontal tokens={{ childrenGap: 8 }}>
              <Stack.Item
                styles={{
                  root: {
                    paddingTop: "30px"
                  }
                }}
              >
                <PrimaryButton text="Search" allowDisabledFocus onClick={submit} type="submit" disabled={false} />
              </Stack.Item>
              <Stack.Item
                styles={{
                  root: {
                    paddingTop: "30px"
                  }
                }}
              >
                <DefaultButton text="Reset" allowDisabledFocus onClick={reset} />
              </Stack.Item>
            </Stack>
          </Stack>
        </Stack.Item>
      </Stack>
    </React.Fragment>
  );
};

SearchForm.displayName = "SearchForm";

export default SearchForm;
