import React, { createContext, useContext, useEffect, useReducer, useState } from "react";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { fetchAndParseReviewers } from "./OpsDashboardContext.reviewers";
import {
  LoadingStatus,
  IUserProviderState,
  OpsDashboardApi,
  LoggingContext,
  FatalError,
  UserContext
} from "@jem/components";

export interface JEMContextData {
  reviewers: {
    status: LoadingStatus;
    values: string[];
  };
  hasErrors: () => boolean;
  setErrors: (value: { collectorName: string; value: boolean }) => void;
}

const initialState: JEMContextData = {
  reviewers: {
    status: LoadingStatus.Idle,
    values: []
  },
  hasErrors: () => true,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setErrors: () => {}
};

const OpsDashboardContext = createContext(initialState);

async function loader<T>(
  getTokenFn: IUserProviderState["accessToken"],
  appInsights: ApplicationInsights,
  configuration: OpsDashboardApi,
  fetcherFunction: (configuration: OpsDashboardApi, getTokenFn: IUserProviderState["accessToken"]) => Promise<T>,
  loadingDispatch: React.Dispatch<React.SetStateAction<LoadingStatus>>,
  valuesDispatch: React.Dispatch<React.SetStateAction<T>>
) {
  loadingDispatch(LoadingStatus.Pending);
  try {
    const apiData = await fetcherFunction(configuration, getTokenFn);
    valuesDispatch(apiData);
    loadingDispatch(LoadingStatus.Resolved);
  } catch (e: unknown) {
    if (e === undefined || e === null) {
      appInsights.trackException({
        exception: new Error("An undefined or null exception was thrown OpsDashboardContext (please define).")
      });
    } else if (typeof e === "string") {
      appInsights.trackException({
        exception: new Error(e.toUpperCase())
      });
    } else if (e instanceof Error) {
      appInsights.trackException({
        exception: e
      });
    } else {
      appInsights.trackException({
        exception: new Error("Exception thrown in OpsDashboardContext.")
      });
    }
    loadingDispatch(LoadingStatus.Rejected);
  }
}

export interface OpsDashboardContextProps {
  children: React.ReactNode;
  configuration: OpsDashboardApi;
}

interface UIErrors {
  [collectorName: string]: boolean;
}

type UIErrorReducer = (
  state: UIErrors,
  changes: {
    collectorName: string;
    value: boolean;
  }
) => UIErrors;

const OpsDashboardProvider: React.FC<OpsDashboardContextProps> = (props) => {
  const { appInsights } = useContext(LoggingContext);
  if (!appInsights) {
    throw new FatalError("Please use a LoggingContext Provider.");
  }
  const { accessToken } = useContext(UserContext);
  if (!accessToken) {
    throw new FatalError("Please use a UserContext Provider.");
  }

  const [reviewersStatus, setReviewersStatus] = useState<LoadingStatus>(LoadingStatus.Idle);
  const [reviewers, setReviewers] = useState<string[]>([]);
  const [errors, _setErrors] = useReducer<UIErrorReducer>((previousState, { collectorName, value }) => {
    previousState[collectorName] = value;
    return previousState;
  }, {});

  useEffect(() => {
    loader(accessToken, appInsights, props.configuration, fetchAndParseReviewers, setReviewersStatus, setReviewers);
  }, []);

  const setErrors = ({ collectorName, value }: { collectorName: string; value: boolean }) => {
    _setErrors({ collectorName, value });
  };

  const hasErrors = () => {
    for (const error of Object.values(errors)) {
      if (error) {
        return true;
      }
    }
    return false;
  };

  return (
    <OpsDashboardContext.Provider
      value={{
        reviewers: {
          status: reviewersStatus,
          values: reviewers
        },
        hasErrors,
        setErrors
      }}
    >
      {props.children}
    </OpsDashboardContext.Provider>
  );
};

export { OpsDashboardProvider, OpsDashboardContext };
