/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";

export enum LoadingStatus {
  Idle = "Idle",
  Pending = "Pending",
  Resolved = "Resolved",
  Rejected = "Rejected"
}

export function delay(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function useForceUpdate(): () => void {
  return React.useReducer(() => ({}), {})[1] as () => void; // <- paste here
}

export const debounce = (fn: (...params: any[]) => any, n: number, immed = false) => {
  let timer: number | undefined = undefined;
  return function (this: any, ...args: any[]) {
    if (timer === undefined && immed) {
      fn.apply(this, args);
    }
    clearTimeout(timer);
    timer = window.setTimeout(() => fn.apply(this, args), n);
    return timer;
  };
};
