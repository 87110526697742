import * as React from "react";
import { forwardRef, useImperativeHandle, useState } from "react";
import { Stack, DefaultButton, Dropdown, IDropdownStyles, IDropdownOption, IStackTokens } from "@fluentui/react";
import { fyFp } from "../../utilities/FiscalYearFiscalPeriod";

const noMargins = {
  margin: "0px",
  selectors: {
    ":hover": {
      margin: "0px"
    },
    ":focus": {
      margin: "0px"
    }
  }
};

const dropdownStyles: Partial<IDropdownStyles> = {
  dropdown: { width: "180px" },
  root: {
    display: "flex",
    flexDirection: "horizontal",
    flexWrap: "nowrap",
    justifyContent: "center"
  },
  dropdownItem: noMargins,
  dropdownItemSelected: noMargins,
  dropdownItemDisabled: noMargins,
  dropdownItemSelectedAndDisabled: noMargins,
  label: {
    paddingRight: "8px"
  }
};

const fiscalYearOptions: IDropdownOption[] = Array.from({ length: 10 }, (_, i) => {
  const newFy = fyFp.fiscalYear - 9 + i;
  return {
    key: `${i}`,
    text: `${newFy}`
  };
});

const fiscalPeriodOptions: IDropdownOption[] = Array.from({ length: 12 }, (_, i) => {
  return {
    key: `${i}`,
    text: `${i + 1}`
  };
});

const horizontalGapStackTokens: IStackTokens = {
  childrenGap: 24,
  padding: "16px 0 24px 0"
};

interface IStateBundle {
  key: string;
  text: string;
}

interface IInternalSourceSelectorState {
  businessProcess: IStateBundle;
  fiscalYear: IStateBundle;
  fiscalPeriod: IStateBundle;
}

export interface ISourceSelectorState {
  appId: string;
  fiscalYear: number;
  fiscalPeriod: number;
}

export interface ITenantProp {
  tenant: string;
  appId: string;
}

export interface IDashboardSelectorProps {
  triggerRefreshFn: (selectorState: ISourceSelectorState) => void;
  tenants: ITenantProp[];
}

export interface IDashboardSourceSelectorRef {
  getState: () => ISourceSelectorState;
}

const businessProcessToAppId = (businessProcessIndex: number, tenants: ITenantProp[]) => {
  return tenants[businessProcessIndex].appId;
};

const DashboardSourceSelector = forwardRef<IDashboardSourceSelectorRef, IDashboardSelectorProps>((props, ref) => {
  const businessProcessOptions: IDropdownOption[] = props.tenants.map((claim, i) => ({
    key: `${i}`, // don't change the index
    text: claim.tenant
  }));
  const initialSelectorState: IInternalSourceSelectorState = {
    businessProcess: businessProcessOptions[0] as IStateBundle,
    fiscalYear: fiscalYearOptions[fiscalYearOptions.findIndex((x) => x.text === `${fyFp.fiscalYear}`)] as IStateBundle,
    fiscalPeriod: fiscalPeriodOptions[
      fiscalPeriodOptions.findIndex((x) => x.text === `${fyFp.fiscalMonth}`)
    ] as IStateBundle
  };
  const [sourceSelectorState, setSourceSelectorState] = useState<IInternalSourceSelectorState>(initialSelectorState);

  useImperativeHandle(ref, () => ({
    getState() {
      return {
        appId: businessProcessToAppId(Number(sourceSelectorState.businessProcess.key || "0"), props.tenants),
        fiscalYear: Number(sourceSelectorState.fiscalYear.text),
        fiscalPeriod: Number(sourceSelectorState.fiscalPeriod.text)
      };
    }
  }));

  const updateButtonClick = () => {
    props.triggerRefreshFn({
      appId: businessProcessToAppId(Number(sourceSelectorState.businessProcess.key || "0"), props.tenants),
      fiscalYear: Number(sourceSelectorState.fiscalYear.text),
      fiscalPeriod: Number(sourceSelectorState.fiscalPeriod.text)
    });
  };

  const onChange = (type: string) => (_: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
    if (option) {
      setSourceSelectorState({
        ...sourceSelectorState,
        [type]: { ...option }
      });
    }
  };

  return (
    <Stack horizontal disableShrink {...{ tokens: horizontalGapStackTokens }}>
      <Stack.Item>
        <Dropdown
          placeholder="Select an option"
          label="Business Process"
          options={businessProcessOptions}
          styles={dropdownStyles}
          selectedKey={sourceSelectorState.businessProcess.key}
          onChange={onChange("businessProcess")}
        />
      </Stack.Item>
      <Stack.Item>
        <Dropdown
          placeholder="Select an option"
          label="Fiscal Year"
          options={fiscalYearOptions}
          styles={dropdownStyles}
          selectedKey={sourceSelectorState.fiscalYear.key}
          onChange={onChange("fiscalYear")}
        />
      </Stack.Item>
      <Stack.Item>
        <Dropdown
          placeholder="Select an option"
          label="Fiscal Period"
          options={fiscalPeriodOptions}
          styles={dropdownStyles}
          selectedKey={sourceSelectorState.fiscalPeriod.key}
          onChange={onChange("fiscalPeriod")}
        />
      </Stack.Item>
      <Stack.Item>
        <DefaultButton text="Update" onClick={updateButtonClick} allowDisabledFocus disabled={false} checked={false} />
      </Stack.Item>
    </Stack>
  );
});

DashboardSourceSelector.displayName = "DashboardSourceSelector";

export { DashboardSourceSelector };
