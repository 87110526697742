import { MessageBarType } from "@fluentui/react";
import { IUserProviderState, DomainDataObjects, DomainDataEnum, LocalMessage } from "../../../../Shared";
import { GLActionTypes } from "../../../hooks";
import { AdditionalReverseJEData, GLAttachmentsRef } from "../../../shared";
import { CheckForErrorsInJeDetails } from "../../../validation";
import { GLDetailsActionsEnum } from "../GLDetails.Actions";
import { GLJeDetailsProps } from "../GLDetailsForm";
import { GLJeDetailsFormikState, GLJeDetailsState } from "../GLDetailsForm.types";
import { jeDetailsStateFlags } from "../utils/jeDetailsStateFlags";
import { CreateBatchActionOnSubmitHandlerMap } from "./GLDetailsActions";

export type UIActionDelegationOptions = {
  userAction: GLDetailsActionsEnum;
  actionOptions?: AdditionalReverseJEData;
  actions: CreateBatchActionOnSubmitHandlerMap;
  userChanges: GLJeDetailsFormikState;
  attachmentsRef: GLAttachmentsRef;
  initialState: GLJeDetailsState;
  configuration: GLJeDetailsProps["configuration"];
  userContext: Pick<IUserProviderState, "accessToken" | "user">;
  domainData: Pick<DomainDataObjects, DomainDataEnum.JeStatus>;
  actionWeightages: Record<GLActionTypes, number>;
};

export type GLDetailsActionResult = {
  action: GLDetailsActionsEnum;
  message: LocalMessage;
  blockPage: boolean;
};

export async function UIActionDelegation(opts: UIActionDelegationOptions): Promise<GLDetailsActionResult> {
  const {
    userAction,
    actionOptions,
    userChanges,
    attachmentsRef,
    initialState,
    configuration,
    userContext,
    domainData,
    actions,
    actionWeightages
  } = opts;

  const errors = await CheckForErrorsInJeDetails({
    configuration: configuration,
    accessToken: userContext.accessToken,
    formikState: userChanges,
    author: initialState.header.author
  });

  // Deal with the Attachments first, and block any actions
  // if there's any issues with them
  const [changes, attachmentErrors] = await attachmentsRef.saveAndGetAttachments();
  if (attachmentErrors) {
    return {
      action: userAction,
      message: {
        message: "There were errors in the Attachments Tab. Please correct them and try again.",
        type: MessageBarType.error
      },
      blockPage: false
    };
  }

  if (userAction === GLDetailsActionsEnum.SaveSAPInfo && !initialState.jeId) {
    const actionResult = await actions[userAction]({
      formikState: userChanges,
      attachmentObjects: changes,
      state: initialState,
      domainData: domainData
    });

    return {
      action: userAction,
      message: actionResult.message,
      blockPage: actionResult.blockPage
    };
  }

  if (userAction === GLDetailsActionsEnum.ReverseJE) {
    const reversal = await actions[GLDetailsActionsEnum.ReverseJE](
      {
        formikState: userChanges,
        attachmentObjects: changes,
        state: initialState,
        domainData: domainData
      },
      actionOptions as AdditionalReverseJEData
    );
    return {
      action: userAction,
      ...reversal,
      message: reversal.message,
      blockPage: reversal.blockPage
    };
  }

  if (errors !== null) {
    return {
      action: userAction,
      message: {
        message: errors.message,
        type: MessageBarType.error
      },
      blockPage: false
    };
  }

  const saveResult = await actions[GLDetailsActionsEnum.Save]({
    formikState: userChanges,
    attachmentObjects: changes,
    state: initialState,
    domainData: domainData
  });

  if (userAction === GLDetailsActionsEnum.Save) {
    return {
      action: userAction,
      message: saveResult.message,
      blockPage: saveResult.blockPage
    };
  }

  if (changes.attachments.length === 0) {
    return {
      action: userAction,
      message: {
        message: "Please add at least one attachment before submitting.",
        type: MessageBarType.error
      },
      blockPage: false
    };
  }

  if (userAction === GLDetailsActionsEnum.NeedsClarification) {
    if (userChanges.reviewersTabClarificationCode.length === 0) {
      return {
        action: userAction,
        message: {
          message: "Please enter Clarification codes to request Clarification.",
          type: MessageBarType.error
        },
        blockPage: false
      };
    }
  }

  if (userAction === GLDetailsActionsEnum.SignOff) {
    const { isBulk, isNotReversalJe } = jeDetailsStateFlags(initialState, actionWeightages);
    if (isBulk && isNotReversalJe) {
      if (!userChanges.detailsTabBulkAcknowledge) {
        return {
          action: userAction,
          message: {
            message: "Please accept the Bulk JE review policy checkbox in JE Details tab.",
            type: MessageBarType.error
          },
          blockPage: false
        };
      }
    }

    if (!userChanges.detailsTabMapPolicy) {
      return {
        action: userAction,
        message: {
          message: "Please accept the Map Policy by checking the checkbox in JE Details tab.",
          type: MessageBarType.error
        },
        blockPage: false
      };
    }
  }

  const actionResult = await actions[userAction]({
    formikState: userChanges,
    attachmentObjects: changes,
    state: initialState,
    domainData: domainData
  });

  return {
    action: userAction,
    message: actionResult.message,
    blockPage: actionResult.blockPage
  };
}
