const ErrorMessages = {
  ERR1249: 'Please add at least one Additional Reviewer as the main Reviewer "{0}" is not available in JEM',
  ERR1054: "Please accept the Map Policy by checking the checkbox in JE Details tab.",
  INF1053: "Journal Entry details are saved successfully.",
  INF1104: "It is view only mode of JEs/POs. Please go to Dashboard/Search page for performing actions.",
  ERR1274: "Company Code must not be empty.",
  ERR1288: "Additional Reviewers must not be empty.",
  ERR1179: 'Already "{0}" has been delegated to "{1}',
  INF1127: "Company Code {0} does not exist.",
  INF1092: 'Already "{0}"  has delegation on behalf of other person',
  INF1113: 'Already "{0}" has been delegated to other person',
  INF1090: "Saved/Updated successfully",
  ERR1140: "Invalid alias(es): {0}",
  ERR1045: "The following reviewers were not found. Check spelling and try again.",
  INF1101: "SAP profiles refreshed successfully.",
  WRN0001: "{0} added/deleted/updated. To commit the changes please click on Save Button.",
  ERR1260: "The number of additional reviewers must be {0}.",
  ERR1197: "{0} must be unique",
  ERR1191: "There is already a record with Category {0}.",
  ERR1182: "There is a record with the range Minimum Amount and Maximum Amount",
  ERR1001: "At least one search criteria must be selected.",
  ERR1123: "At least one status must be selected.",
  ERR1120: "User cannot add any new Company Codes.",
  ERR1119: "User cannot add any new aliases.",
  ERR1056: "Content validation errors have occurred. Make changes to the highlighted fields.",
  ERR1180: "Minimum Amount is greater than or equal to the maximum amount",
  ERR1181: "Minimum amount and maximum amount is over lapping the other ranges",
  ERR1193: "Review is required only if attachment is required.",
  ERR1077: "Signoff is not required as this entry is set as Insignificant.",
  ERR1076: "You do not have sufficient permissions to perform this action.",
  ERR1088: "Release for Signoff is not allowed as the current entry is set as 'Signoff Not Required'.",
  ERR1078: "Release for Signoff is not allowed as the current entry is already pending with reviewer.",
  ERR1055: "Attachment required for this Journal Entry.",
  ERR1226: "Attachment required for this Payment Order.",
  ERR1091: "This action is not allowed for an entry with current status.",
  ERR1093: "The entry has been updated successfully. Please search again to take any further action.",
  ERR1185: "The entry has been updated successfully. Please refresh to take any further action.",
  ERR1227: "Please Save the SAP Info to JEM system before taking any further action on this entry.",
  ERR1118: "This action can be performed only for the entries which are not available in JEM.",
  ERR1137: "Add Poster is not allowed as the current entry is set as 'Signoff Not Required'.",
  ERR1251: 'The following reviewers "{0}" were not found. Check spelling and try again.',
  ERR1052: "This Operation on this entry cannot be performed.",
  INF1249: "Maximum of {0} entries can be exported to Excel.",
  ERR1107: "No Entries have been selected. Please select entries.",
  INF1235: "You do not have any profiles with Controller Portal. Please create a profile to view this page.",
  ERR1295: "Amount exceeds the maximum limit.",
  ERR1199: "Amount must contain max 4 digits after decimal",
  ERR1200: "Type must allow alpabets only.",
  ERR1176: "JEType Code must be Unique.",
  INF1085: "User alias already exists. Please add a different user",
  INF1088: "Error occured while saving Admin Users",
  INF1086: "Cannot delete the current user profile",
  ERR1003: "Alias must not be empty",
  ERR1236: "Maximum number of posters permitted is 1.",
  ERR1103: "The Poster field does not accept special characters, except for backslash (\\).",
  ERR1008: "The Poster cannot also be the Reviewer.",
  ERR1044:
    "A maximum of {0} additional reviewers are supported. Aliases must be separated by semicolon.  (example: alias1;alias2)",
  ERR0019: "The Reviewer field is required.",
  ERR1259:
    "A maximum of 2 additional reviewers are supported. Aliases must be separated by semicolon.  (example: alias1;alias2)",
  ERR1020:
    "The Additional Reviewer field does not accept special characters except for backslash (\\) and semicolon (;).",
  ERR1262: "{0} is required and must not be empty.",
  ERR1043: "The Comments field is required.",
  ERR1032: "Comment length limited to 250 characters.",
  ERR1087: "This field does not accept special characters like < , > and /",
  ERR1047: "The Poster/Actual Poster cannot also be the Reviewer.",
  INF1048: "Reviewer(s) have been added for this entry successfully.",
  INF1049: "Reversal has been posted for this entry. Please check Reversal Doc Number column.",
  INF1296: "There is an error occured while sending an mail. Add Poster action has been completed successfully.",
  INF1070: "The current entry has been signed-off successfully.",
  INF2071: "The current entry has been rescind successfully.",
  INF1065: "The previous operation is being performed on this JE. Please wait few minutes to take any further action.",
  INF1307: "The current entry has been recalled successfully.",
  INF1043: "The current entry has been sent for clarification successfully.",
  INF1051: "This entry has been initiated for review successfully.",
  ERR1040: "The Status of the current entry has been modified already.",
  ERR1079: "This action can be performed only for the entries posted by you.",
  INF1052: "This entry has been sent for review successfully.",
  INF1789: "This entry has been deleted successfully.",
  ERR1189: 'Already "{0}" has been delegated to other person',
  INF1126: 'Company Code "{0}" is already assigned to another Assistant Financial Controller',
  INF1125: "Please select atleast one Company Code",
  ERR1263: "AFC alias and Backup AFC alias must not be the same.",
  ERR1139: '{0}" is already {1}',
  ERR1059: "The user alias you entered was not found. Check the spelling and try again.",
  ERR1117: "This field does not accept characters other than numbers and ;",
  ERR1110: "Only Journal Entries which are in draft status can be deleted.",
  ERR1278: "Standard drafts cannot be deleted.",
  ERR1320: "Standard batch drafts cannot be deleted.",
  INF1073: "Are you sure you want to delete the selected Journal Entries?",
  ERR1112: "No Journal Entries have been selected. Please select Journal Entries.",
  INF1098: "Are you sure you want to recall the selected Journal Entries?",
  ERR1111: "Journal Entries which are not Sent to Poster cannot be Recalled.",
  INF1297: "{0} draft has been deleted successfully.",
  INF1298: "{0} draft have been deleted successfully.",
  ERR1109: "Posters can be added only to Journal Entries which are in Draft status.",
  ERR1237: "The Poster field does not accept special characters except for backslash (\\) and hyphen(-).",
  INF1287: "Maximum number of posters permitted is 2",
  ERR0020:
    "A maximum of two backup posters can be added. Aliases must be separated by semicolon.  (example: alias1;alias2)",
  ERR0021: "The number of backup posters entered exceeds the allowed amount of {0}",
  ERR1230: "The Add Poster field does not accept special characters except for backslash (\\) and semicolon (;).",
  ERR1238: "Following Poster(s) are not found : {0}",
  ERR1017: "This Company Code was not found. Company Code must be 4 digits long.",
  ERR1018: "The Currency field is required.",
  ERR1019: "Ref Number must be Numeric and 6 digits long.",
  ERR1023: "LineAmount field only accepts numbers;",
  ERR1024: "The Account field is required;",
  ERR1025: "Line item description cannot be empty;",
  ERR1026: "JE line items must have one and only one of either cost center, IO or SO/LI;",
  ERR1027: "Sales Order must have corresponding Line Item;",
  ERR1028: "Sales Order must have corresponding Sales Order Line Item;",
  ERR1029: "This field accepts 6 digit numeric entries only;",
  ERR1030:
    "Debit column amounts must match credit amounts in the Amount column of line items. Net must be zero (0) in the Audit column;",
  ERR1031: "At least one attachment is required.",
  ERR1036: "The JE Purpose field is required.",
  ERR1057: "Assignment can have maximum of 18 characters and contain a combination of numbers and letters;",
  ERR1058: "TaxCode must be alpha-numeric and 2 characters long;",
  ERR1096: "Reversal Date cannot be earlier than the Posting Date.",
  ERR1097: "Ref Number must be 6 digit long and must not have all zeroes (000000).",
  ERR1098: "Reversal Date field is required.",
  ERR1316: "Translation date must be less than or equal to Posting date.",
  ERR1022: "A minimum of two line items must be entered in the JE sheet",
  ERR1204: "SAP allows maximum of {0} Line items. Please remove the remaining line items",
  ERR1247: "LineAmountLC field only accepts numbers;",
  ERR1114: "Line item description can not be more than 50 characters long;",
  ERR1007: "Validation errors have occurred. Make changes to the highlighted fields and click Validate again.",
  ERRGENERIC: "An error occurred in the application. Please contact administrator.",
  ERR1104:
    "The Backup Poster field does not accept spaces or special characters except for backslash (\\) and semicolon (;).",
  ERR1248: "You do not have permissions to view this page. Please contact JEM Administrator.",
  ERR0001:
    "An error has occurred while processing. Contact administrator or click the below GUID to send the error details.",
  ERR0002: "Empty error codes",
  ERR0003: "No resource available. Code:{0}",
  ERR0004: "Error occurred while invoking SAP. Please contact administrator.",
  ERR0005:
    "An error occurred while posting this draft to SAP. Please verify if this JE is created in SAP before posting again from JEM",
  ERR0010: "Draft cannot be Submitted.",
  ERR0011: "Posted Draft(s) cannot be recalled.",
  ERR0012: "Draft(s) cannot be Deleted.",
  ERR0013: "An action has been taken on this draft already.",
  ERR0501: "No access to the action. Please contact administrator.",
  ERR1002: "Document number must not be empty",
  ERR1004: "Document number must not be empty",
  ERR1005: "Document number must be numeric",
  ERR1006: "The Alias field does not require the domain. (example: alias1)",
  ERR1009: "The Poster cannot also be the Reviewer.",
  ERR1010: "Invalid guid format",
  ERR1021: "The Reviewer field does not accept special characters, except for backslash (\\).",
  ERR1035: "Unauthorized Access",
  ERR1037: "Following Reviewer(s) are not found :",
  ERR1041: "The action has already taken on this entry.",
  ERR1042: "The following reviewers were not found. Check spelling and try again.",
  ERR1046: "Given reviewer is not a manager",
  ERR1049: "Review is not required for this entry.",
  ERR1050: "The supervisor alias you entered was not found. Check the spelling and try again.",
  ERR1053: "Caller is niether a Poster nor Creator",
  ERR1555: "Please accept the Bulk JE review policy checkbox in JE Details tab.",
  ERR1556: "Please accept the Bulk PO review policy checkbox in PO Details tab.",
  ERR1070: "This Journal Entry has already been initiated.",
  ERR1071: "This Payment Order has already been initiated.",
  ERR1072: "At least one attachment is required.",
  ERR1073: "There is an error occurred while uploading the document. Please try again.",
  ERR1074: "This entry has already been posted to SAP. Please go back to dashboard to see the latest drafts.",
  ERR1075:
    "The draft has been moved to archive as the JE has been successfully created. Please go back to dashboard to see the latest drafts.",
  ERR1080: "The entry has already been signed off. No further action is required.",
  ERR1081: "Invalid user",
  ERR1082: "At least one attachment is required for this PO.",
  ERR1083: "PO Purpose field is required.",
  ERR1084: "A valid reversal date is required to reverse the PO.",
  ERR1085: "Start PO Number must be less than or same as End PO Number. Please change the value and try again.",
  ERR1086: "Please enter both Start PO Number and End PO Number.",
  ERR1089: "This draft has been recalled by the Author already.",
  ERR1090: "This entry has already been posted to SAP. Please go back to dashboard to see the latest drafts.",
  ERR1092: "The draft has already been sent for clarification.",
  ERR1094: "Invalid file name",
  ERR1095: "This file format is not supported.",
  ERR1099:
    "Total Debit/Credit Amount is larger than the amount allowed by SAP. Change your journal to use smaller amounts.",
  ERR1100: "You do not have sufficient SAP permissions to post the draft. Please Contact SAP administrator.",
  ERR1101:
    "A maximum of two additional reviewers can be added. Aliases must be separated by semicolon.  (example: alias1;alias2)",
  ERR1102: "The Author cannot also be the Poster.",
  ERR1105: "Following Poster(s) are not found :",
  ERR1106: "Validation errors have occured.Please make changes to the highlighted fields to proceed",
  ERR1108: "Error occured while deleting",
  ERR1113: "Bulk attach did not happen for few entries. Please view error tab for details.",
  ERR1115: "Please attach atleast one attachment from Attachments tab",
  ERR1116: "This field does not accept special characters other than - and ;",
  ERR1121: "More than 1 allowed record has to be selected for Bulk Attach",
  ERR1122: "Validation errors have occured for the batch file.Please view error tab for error file path",
  ERR1124: "Required “from” document parameter is missing. Please try again by changing criteria.",
  ERR1125: "Error Occurred. Please Contact support for help.",
  ERR1126: "No authorization to call RFC. Please Contact support for help.",
  ERR1127: "Payment Order number provided contains non numeric characters. Please try again by changing criteria.",
  ERR1128: "“From” order number is greater than “to” order number. Please try again by changing criteria.",
  ERR1129: "Order range must be less than 6,000 numbers. Please try again by changing criteria.",
  ERR1130: "Unknown Error occurred. Please Contact support for help.",
  ERR1131: "The batch has validation errors.Please see the error tab.",
  ERR1132: "Only a financial user can be added as Poster.",
  ERR1133: "The Reviewer cannot also be the Poster.",
  ERR1134: "The poster alias you entered was not found. Check spelling and try again.",
  ERR1135: "Enter character only.",
  ERR1136: "Please enter a valid email Id",
  ERR1138: "Operation Failed",
  ERR1141: "Transaction Type is required and must not be blank",
  ERR1142: "Invalid Transaction Type",
  ERR1143: "Amount is required and must not be blank",
  ERR1144: "Invalid Amount",
  ERR1145: "Currency is required and must not be blank",
  ERR1146: "Invalid Currency",
  ERR1147: "RP Account is required and must not be blank",
  ERR1148: "Invalid RP Account",
  ERR1149: "Purpose is required and must not be blank",
  ERR1150: "Purpose can not be more than 40 characters",
  ERR1151: "OP Account and RP Account must not be same",
  ERR1152: "OP Account is required and must not be blank",
  ERR1153: "Invalid OP Account",
  ERR1154: "Action is in Process. Please try again later",
  ERR1155: "Version changed",
  ERR1156: "JEM - SAP User Id must not be empty.",
  ERR1157: "JEM - JE Number must not be empty.",
  ERR1158: "JEM - Fiscal Year  in document {0} must be numeric and four digit long.",
  ERR1159: "JEM - Fiscal Period must not be empty.",
  ERR1160: "JEM - Currency Code must not be empty.",
  ERR1161: "JEM - Document Type must not be empty.",
  ERR1162: "JEM - Document Description must not be empty.",
  ERR1163: "JEM - Company Code must not be empty.",
  ERR1164: "JEM - Company Code in document {0} must be 4 digit.",
  ERR1165: "JEM - JE must have a minimum of 2 LineItems",
  ERR1166: "JEM - Post Key in document {0} must be numeric.",
  ERR1167: "JEM - PostKey must be either 40 or 50.",
  ERR1168: "JEM - Amount in document {0} must be numeric.",
  ERR1169: "JEM - Account must not be Empty.",
  ERR1170: "JEM - Line Description must not be Empty.",
  ERR1171: "JEM - One of the COSTCENTER or INTERNALORDER or PROFITCENTRE or TRADINGPARTNER are required.",
  ERR1172: "JEM - Element value for {0} doestn't found in the document. Please select correct Optional fields.",
  ERR1173: "JEM - Currency Format in document {0} must be numeric and same as SAP profile Currency format.",
  ERR1174: "JEM - The Currency format in document {0} must match with your SAP Profile Currency format.",
  ERR1175: "JEM - The Date format in document {0} must match with your SAP Profile Date format.",
  ERR1177: "Entered characters are not in allowed list.",
  ERR1178: "Value is required",
  ERR1183: "JEM - Document user {0} must match with  the poster {1}.",
  ERR1184: "JEM - Total Lines in Audit {0} is not equal to Total Line Items in document {1}.",
  ERR1186:
    "Debit column amounts must match credit column amounts in the AmountLC column of line items. Net must be zero (0) in the Audit column;",
  ERR1187: "JEM - Assignment in document {0} must have alphabets, numerics and spaces only.",
  ERR1188: "JEM - TaxCode in document {0}  must have alphabets, numerics and spaces only.",
  ERR1190: "This Batch file format is not supported.",
  ERR1192: "Comment field does not accept special characters like &lt; , &gt; and /",
  ERR1194: "JEM - The {0} format in document {1} must match with your SAP Profile Date format.",
  ERR1195: "JEM - The {0} format in document {1} must match with your SAP Profile Currency format.",
  ERR1196: "Validation errors have occured. Make the changes.",
  ERR1198: "Batch file name already exists",
  ERR1201: "Batch file must not contain special characters",
  ERR1202: "JEM - Reversal Date must not be empty for Document Type SV.",
  ERR1203: "JEM - JE line items must have one and only one of either Cost Center or IO.",
  ERR1205:
    "JEM - A minimum of two line items are required for this  Batch Journal Entry .Total line items provided in audit line {0}.",
  ERR1206: "JEM - Structural validations have occured for an element {0} with value {1}.",
  ERR1207: "JEM - Element {0} with value {1} has exceeded the maximum length of {2} characters.",
  ERR1208: "JEM - Element {0} with value {1} has exceeded the maximum value of {2} .",
  ERR1209: "JEM - Element {0} must be numeric.",
  ERR1210: "JEM - Element {0} with value {1} must be a  minimum value of {2} .",
  ERR1211: "JEM - Element {0} not found in the document.",
  ERR1212: "JEM - Element {0} must not be empty.",
  ERR1213: "JEM - Element {0} already exists in the document.",
  ERR1214: "JEM - Invalid element {0} found in the document.",
  ERR1215: "JEM - JE line items must have one and only one of either Cost Center, InternalOrder or SalesOrder/LI.",
  ERR1216: "JEM - Sales Order must have corresponding Line Item.",
  ERR1217: "JEM - Sales Order must have corresponding Sales Order Line Item.",
  ERR1218: "JEM - Sales Order LineItem  in document {0} must be numeric and six digit long.",
  ERR1219: "JEM - Ref Number in Document {0} must be 6 digit long and must not have all zeroes (000000).",
  ERR1220:
    "This field does not accept special characters like \\ , / , : , *, ? , &lt; , &gt; , | , # , { , } , % , ~ and &amp;",
  ERR1221: "JEM - Posting date {0} does not belong to the fiscal year and period {1} provided in document.",
  ERR1222: "JEM - Please select a valid XML file.",
  ERR1223: "JEM - Number of postings in the document has exceeded maximum number of postings allowed {0} for SAP.",
  ERR1224: "SAP Details did not save as user is not found in JEM system.",
  ERR1225: "The PO cannot be processed in the JEM system. Complete the approval process through Docman.",
  ERR1228: "JEM - Reversal Date is required only for document type SV.",
  ERR1229: "JEM - Reversal Date must be greater than Posting Date.",
  ERR1231: "Please accept the Map Policy by checking the checkbox in Payment Order Details tab.",
  ERR1232: "You do not have​ ​appropriate SAP roles to post POs. Please contact SAP Administrator.",
  ERR1233: "You do not have permissions to post through JEM. Please contact JEM Administrator.",
  ERR1234: "PO Postings are currently not allowed through JEM. Please contact JEM Administrator.",
  ERR1235: "You do not have any profiles with Controller Portal. Please create a profile to view this page.",
  ERR1239: "Total Credit - {0} is not equal to Total Debit - {1} .;",
  ERR1240: "Total Credit in AUDIT - {0} isn't equal to Total Credit in DETAIL - {1} .;",
  ERR1241: "Total Debit in AUDIT - {0} isn't equal to Total Debit in DETAIL - {1} .;",
  ERR1242: "Debits - ({0}) and Credits - ({1}) amounts are not equal in detail lines.;",
  ERR1243: "Total Credit (LC) - {0} is not equal to Total Debit (LC) - {1} .;",
  ERR1244: "Total Credit (LC) in AUDIT - {0} isn't equal to Total Credit (LC) in DETAIL - {1} .;",
  ERR1245: "Total Debit (LC) in AUDIT - {0} isn't equal to Total Debit (LC) in DETAIL - {1} .;",
  ERR1246: "Debits (LC) - ({0}) and Credits (LC) - ({1}) amounts are not equal in detail lines.;",
  ERR1250:
    "The Current entry doesn't have any valid Reviewer(s) to perform this action. Please add a valid Additional Reviewer.",
  ERR1252: "No Entries are present to Export Data. Please search again.",
  ERR1253: "The draft is already allocated to another User.",
  ERR1254: "The Operation could not be completed for this entry. Please try again.",
  ERR1255: "The draft is already ReAssigned to another User",
  ERR1256: "The draft is already Revoked from current User.",
  ERR1257: "The draft is already marked as Posting Not Required.",
  ERR1258: "This entry is already deleted by another user",
  ERR1261: "A checklist has already been created with same Company Code, Fiscal Year and Fiscal Period.",
  ERR1264: "The Author {0} is not found. Check spelling and try again.",
  ERR1265: "{0} must be maximum of {1} characters.",
  ERR1266: "Maximum of 1 reviewer is allowed.",
  ERR1267: "Maximum number of Authors permitted is 1.",
  ERR1268: "The Author field does not accept special characters except for backslash (\\) and hyphen(-).",
  ERR1269: "Validation errors have occured. Make changes to the spreadsheet fields and Publish again.",
  ERR1270: "A minimum of one JE details are required for this new checklist spreadsheet.",
  ERR1271: "This entry cannot be Reassigned to the same Author.",
  ERR1272: "Checklist with only Published or Signed-off status can be copied.",
  ERR1273: "Only one entry can be selected for Copy operation",
  ERR1275: "Validation errors have occured. Make changes to the spreadsheet fields and SignOff again.",
  ERR1276: "JE Draft Details have been modified. Publish the checklist again.",
  ERR1277: "An error occurred while the action is being performed. Please try again after some time.",
  ERR1279:
    "A checklist has already been created and is currently being published. Please refresh the page after sometime for updated checklist details.",
  ERR1280: "Checklist has already been SignedOff. Please refresh the page for updated checklist details.",
  ERR1281: "Sign off is not allowed as the checklist is being published.",
  ERR1282:
    "The Checklist is being published by other user. Please refresh the page after sometime for updated checklist details.",
  ERR1283: "The entry is already modified. Please refresh the page and try again.",
  ERR1284: "The poster alias you entered {0} was not found.",
  ERR1285: "The reviewer alias you entered {0} was not found.",
  ERR1286: "Maximum of 1 poster is allowed.",
  ERR1287: "Value cannot be less than {0}.",
  ERR1289: "{0} is not a valid ReasonCode.",
  ERR1290: "{0} is not a valid Posting Not Reqd code.",
  ERR1291: "Comments must not be empty for Posting Not Reqd reason marked as Other.",
  ERR1292:
    "At least one or more Account/Internal Order/Cost Center/Profit Center values are not entered or invalid. Please enter the values or validate the line items before generating the Mercury Roll up Report.",
  ERR1293: "Validation errors have occurred.  Make changes to the Spread sheet.",
  ERR1294:
    "Any one of the Internal Order/ Cost Center/ Profit Center mandatory for Profit and Loss Account(s). Please validate the line items before generating the Mercury Roll-up Report.",
  ERR1296: "Attachment is not uploaded properly. Please check the status of attachment and post the entry.",
  ERR1297: "JEM - Invalid column {0} found at column number {1}.",
  ERR1298: "JEM - Value entered {0} for column {1} is not allowed. Please select a value from the list.",
  ERR1299: "JEM - Value must not be empty for column {0}.",
  ERR1300: "JEM - Cell must not be empty at column number {0}.",
  ERR1301: "JEM - Column {0} doesn't found at column number {1}. Please upload a valid batch excel file.",
  ERR1302: "JEM - Empty row found at row number {0}.  Please upload a valid batch excel file.",
  ERR1303: "JEM - Column {0} doesn't found at column number {1}. Please upload a valid batch excel file.",
  ERR1304: "JEM - Value entered for  {0} : {1}  must be of {2} format.",
  ERR1305: "JEM - Atleast one(1) JE is required for batch posting. Each JE must have a minimum of two(2) Line Items.",
  ERR1306: "JEM - Invalid excel file is uploaded. Please upload a valid excel batch excel file.",
  ERR1307: "JEM - Amount and Amount LC must be both either Debit or Credit amounts.",
  ERR1308: "JEM - Value for column Header ID must not be empty. Please enter a valid value to identify a JE Header.",
  ERR1309: "JEM - Ref Number must not be empty.",
  ERR1310: "JEM - User ID must not be empty.",
  ERR1311: "JEM -  Doc Type must not be empty.",
  ERR1312: "JEM -  Doc Desc must not be empty.",
  ERR1313: "JEM - Line Amount in document {0} must be numeric.",
  ERR1314: "JEM - Line Desc must not be Empty.",
  ERR1315: "JEM - Line Amount LC in document {0} must be numeric.",
  ERR1317: "JEM - Either Amount or AmountLC must be non zero.",
  ERR1318: "Entered date {0} is not valid date for Translation Date. Please enter valid date.",
  ERR1319: "{0} is not a valid JEType.",
  ERR1321: "The Checklist is currently being published. Please try again after sometime.",
  ERR1322: "Signoff is not allowed as the draft details have been modified. Please republish the checklist.",
  ERR1323: "JEM - Transaction currency amount cannot equal zero.",
  ERR1324: "JEM - Local currency amount cannot equal zero.",
  ERR1325: "JEM - Transaction currency and Local currency amounts cannot equal zero.",
  ERR1326: "JEM - When posting with only one currency amount Transaction Currency amount is required.",
  ERR1327: "JEM - Transaction and Local currency amounts must be equal when Transaction and Local codes are the same.",
  ERR1328: "Signoff is not allowed on a failed checklist. Republish the checklist and signoff",
  ERR1329: "Poster field is required.",
  ERR1330: "One or more Payment Order already synced. Search again for updated results.",
  ERR1339: "PO's > 1000$ requires attachment.",
  INF0001: "Journal Entry Draft is Saved Successfully.",
  INF0002: "This Journal Entry validated and saved successfully.",
  INF0003: "This Journal Entry validated and saved successfully.",
  INF0004:
    "Your Journal Entry details have been submitted to SAP.  Please check your confirmation email for SAP validation details and your SAP document number(s).",
  INF0005: "The Journal Entry draft is loaded.",
  INF0006: "The Journal Entry draft is sent to the Poster.",
  INF0007: "The Journal Entry draft is sent for clarification to the Author.",
  INF0008: "The file(s) are being uploaded.",
  INF0009: "The file(s) are uploaded successfully.",
  INF1011: "The Journal Entry draft is being posted to SAP.",
  INF1012: "Updating the preposting WF.",
  INF1013: "The Journal Entry draft is being saved.",
  INF1014: "Calling WFPersistence",
  INF1015: "WFPersistence is done",
  INF1016: "Update is complete for preposting WF.",
  INF1017: "Reading the line items of the Journal Entry draft from Excel.",
  INF1018: "Excel Line items are read",
  INF1019: "The file(s) are being deleted.",
  INF1040: "Status Updated",
  INF1054: "This entry is not posted through JEM. Complete the approval process through Docman.",
  INF1055: "Searching for the Journal Entry in the JEM system.",
  INF1056:
    "The Journal Entry is not found in JEM system. The same has been retrieved from SAP directly. Please click on Save SAP Info to take any action.",
  INF1057:
    "The PO is not found in JEM system. The same has been retrieved from SAP directly. Please click on Save SAP Info to take any action.",
  INF1058: "The file(s) are deleted successfully.",
  INF1059: "The PO number is not available or cannot be found.",
  INF1060:
    "The PO details are saved successfully to JEM system. Please search again or refresh to take any further action.",
  INF1061: "The PO has been saved successfully.",
  INF1062: "The Journal Entry details are being saved.",
  INF1063: "SAP Details did not save as user is not found in Database.",
  INF1064: "The PO has been reversed successfully.",
  INF1066: "The previous operation is being performed on this PO. Please wait few minutes to take any further action.",
  INF1067: "The Payment Order has been sent for clarification successfully.",
  INF1068: "The Payment Order has been signed-off successfully.",
  INF1069: "The file(s) deleted and the draft saved successfully.",
  INF1071: "The PO cannot be processed in the JEM system. Complete the approval process through Docman.",
  INF1072: "Please click on Fetch button to get the batches created by the user",
  INF1074: "The JE Number is not available or cannot be found",
  INF1075: "Documents saved successfully",
  INF1076: "jemdevsg@microsoft.com",
  INF1077: "The Payment Order details are being saved.",
  INF1078: "PO has been save successfully",
  INF1079: "Batch Validation completed",
  INF1080: "Batch Details saved successfully",
  INF1081: "Details have been already saved.",
  INF1082: "Details have been already validated.",
  INF1083: "Validation in progress",
  INF1084: "Poster has been changed for this entry successfully.",
  INF1150: "Data from sap successfuly saved.",
  INF1087: "{0} saved successfully",
  INF1089: "This draft has been recalled by the Author already.",
  INF1093: "Cache is refreshed successfully.",
  INF1094: "{0} record(s) deleted successfully.",
  INF1095: "Save in progress",
  INF1096: "Exception ref. Guid: {0}",
  INF1097: "Exception occurred in JEM. Find ref. guid in the subject%0A%0ARegards,%0A",
  INF1100: "{0} added/deleted/updated. Do you wish to save the changes?",
  INF1102: "Your Batch Journal Entry has been posted successfully to SAP.",
  INF1103:
    "There are few records retrieved directly from SAP and are marked under SAP Info column. Please Save the SAP Info to JEM system before taking any further action.",
  INF1105: "Validating the Details",
  INF1106: "Updating the Data in Database",
  INF1107: "Posting to SAP",
  INF1108: "Releasing for Sign off",
  INF1109: "Approving the Payment Order",
  INF1110: "Clarifying the Payment Order",
  INF1111: "Reading the Line Items from Excel",
  INF1112: "Clearing the Line Items from Excel",
  INF1114: "Batch posting in progress",
  INF1115: "There is an error occured while sending an mail. Add Reviewer action has been completed successfully.",
  INF1116:
    "For the PO number {0} the poster alias has been changed in JEM. Please search the PO with poster alias {1} to take any further action.",
  INF1117: "This entry has already been posted to SAP. Please go back to dashboard to see the latest drafts.",
  INF1118: "The draft has already been sent for clarification.",
  INF1119:
    "The draft has been moved to archive as the JE has been successfully created. Please go back to dashboard to see the latest drafts.",
  INF1120: "Action is in Process. Please try again later",
  INF1121: "Version changed",
  INF1122: "You do not have sufficient SAP permissions to post the draft. Please Contact SAP administrator.",
  INF1123:
    "The draft has been moved to archive as the JE has been successfully created. Please refresh dashboard to see the latest drafts.",
  INF1124: "User {0} is not a Assistant Financial Controller",
  INF1128: "This entry has been allocated successfully.",
  INF1129: "Are you sure you want to Revoke the draft(s)?",
  INF1130: "Are you sure you want to Delete the checklist(s)?",
  INF1131: "This entry has been ReAssigned successfully.",
  INF1132: "This entry has been Revoked successfully.",
  INF1133: "This entry has been Marked as Posting Not Required successfully.",
  INF1134: "This entry has been deleted successfully.",
  INF1135: "The company code is already added.",
  INF1232: "You do not have​ ​appropriate SAP roles to post POs. Please contact SAP Administrator.",
  INF1233: "You do not have permissions to post through JEM. Please contact JEM Administrator.",
  INF1234: "PO Postings are currently not allowed through JEM. Please contact JEM Administrator.",
  INF1248: "You do not have permissions to view this page. Please contact JEM Administrator.",
  INF1250: "There is an error occurred while exporting data. Please close the selected file and try again.",
  INF1251: "The file format {0} is not allowed. Please change the file format to XML.",
  INF1252: "Are you sure you want to Allocate the draft(s)?",
  INF1253: "Maximum of {0} additional reviewers. Separate by semicolon (;).",
  INF1254: "Checklist has been published successfully.",
  INF1255: "Posting Date and Doc Date fields in the spreadsheet are in US Date format (MM/DD/YYYY).",
  INF1256: "Only the drafts assigned to you are displayed.",
  INF1257: "Signoff is not allowed as JE is neither posted nor marked as Posting not Required.",
  INF1258: "You can view draft details only for the drafts assigned to you.",
  INF1259: "Checklist details have been loaded.",
  INF1260: "Please wait while the checklist is being Published.",
  INF1261: "Please wait while the checklist is being SignedOff.",
  INF1262: "Checklist has been SignedOff successfully.",
  INF1263: "You do not have Permissions to create Checklist. Please contact JEM administrator.",
  INF1264: "The Checklist is being published. Please refresh the page after sometime for updated checklist details.",
  INF1265:
    "The Checklist is being published. Please use the Refresh link on the BPO Dashboard to check the updated status.",
  INF1266: "The entry is already modified. Please refresh the page and try again.",
  INF1267: "The checklist has been deleted by another user.",
  INF1268: "Note: Posted Date and Reviewed Date fields in the spreadsheet are in US date format (MM/DD/YYYY).",
  INF1269: "Updated Poster information can be viewed on the BPO dashboard.",
  INF1270:
    "Data has been added/deleted in the grid. Changing the role will now show the changes in the grid.{0}Click OK to continue or Cancel and then Click on Save button",
  INF1271:
    "Data has been added/deleted in the grid which will be lost.{0}Click OK to continue or Cancel and then Click on Save button",
  INF1272:
    "Data has been added/deleted/edited in the grid which will be lost.{0}Click OK to continue or Cancel and then Click on Save button",
  ERR3609:
    "An error occurred while posting to SAP. Please verify if this JE is created in SAP before posting it from JEM.",
  INF1274: "Maximum number of line items that can be posted is 2000.",
  INF1275: "There are no line items to Post.",
  INF1276: "PO(s) are successfully submitted for review.",
  INF1277:
    "Your IHCC Payment Orders were submitted to SAP. At least one error has been found. The details will be mailed to you.",
  INF1278: "Post Date can not be empty",
  INF1279: "Comments can not be empty",
  INF1280:
    "If you continue clearing this form, the spreadsheet data will be permanently deleted.Do you want to continue with Clear Form?",
  INF1281: "There is unsaved data. Do you wish to close the window?",
  INF1282: "1 JE Draft is successfully recalled.",
  INF1283: "JE Drafts are successfully recalled.",
  INF1284: "1 JE Draft is not recalled.",
  INF1285: "Journal Entries are not recalled.",
  INF1286: "Entry is moved to Archive. Please contact administrator.",
  INF1288: "Author should not be empty",
  INF1289: "Clearing the values",
  INF1290: "Files are deleted successfully",
  INF1291: "Release for sign-off process is in progress",
  INF1292: "Please wait, while the Mercury Roll-Up report is generating.",
  INF1293: "Mercury Roll Up Report has been generated successfully.",
  INF1294: "Exporting is started.",
  INF1295: "Exporting is completed.",
  INF1299: "Optional fields are not considered for batch excel or xml file processing.",
  INF1300: "Clarification process is in progress.",
  INF1301: "Sign-off process is in progress.",
  INF1302: "Document(s) deletion is in progress.",
  INF1303: "Validation started.",
  INF1304: "Completed: Step {0} of {1}",
  INF1305: "There is an error occurred while uploading the file. Please close the selected file and try again.",
  INF1306: "This JE draft has already been open in different tab or browser.",
  INF1308: "Recall process is in progress.",
  INF1309: "Comment should not be empty.",
  INF1310:
    "Posting is not allowed on this Journal Entry as it has been updated as Posting Not Required by another user. Please go back to dashboard to see the latest drafts.",
  INF1311:
    "Posting is not allowed on this entry as this batch draft has been updated as Posting Not Required by another user. Please go back to dashboard to see the latest drafts.",
  INF1312: "Please wait while the checklist is being Saved.",
  INF1313: "The Checklist is published successfully.",
  INF1314: "The Checklist is saved successfully.",
  INF1315: "There are no drafts to Save.",
  INF4001: "No changes to publish",
  INF4002: "No changes to save",
  INF4003: "There are unsaved drafts",
  INF4004: "Any unsaved changes will be lost if you proceed.",
  INF4005: "System user posting are not allowed to sync into JEM.",
  ERR4005: "The company code provided does not exist.",
  ERR5001: "PO has been reversed successfully in SAP, however error occured in JEM. Please contact Administrator.",
  ERR5002: "Invalid Reversal date format.",
  INF9999:
    ".XML,.DOC,.XLS,.PNG,.PPT,.TXT,.PDF,.ZIP,.HTML,.MSG,.TIF,.BMP,.JPG,.RAR,.RTF,.MDI,.DOCX,.PPTX,.XLSX,.XLSB,.OFT,.SNP,.DAT,.MP3,.MP4,.WAV,.TIF",
  INF9998: "Please select a region to upload",
  INF9997: "Please select atleast one file upload",
  INF9996: "There are no newly added files to upload",
  ERR9995: "There are unpublished drafts. Republish the checklist and signoff",
  ERR9996: "Please wait till JEM receives acknowledgements for all JE postings of the Batch from SAP.",
  ERR9997: "Please wait till JEM receives acknowledgements for all PO postings of the Bulk.",
  INF9994: "The PO details are saved successfully. Please search again to take any further action.",
  INF1316: "Any unpublished changes will be lost. Do you want to proceed.",
  ERR1350: "Selected items impacts a larger number of JEs. Please reduce your selected items.",
  ERR1351: "This draft has been modified by different user please refresh the page to get latest Version.",
  ERR1352: "This Batch has been modified by different user please refresh the page to get latest Version.",
  ERR1353: "Cannot Save filtered data. Remove the filter by clicking “Clear Filter” and then “Save”.",
  ERR1354: "Cannot Publish filtered data. Remove the filter by clicking “Clear Filter” and then “Publish”.",
  ERR1355: "Cannot Sign off filtered data. Remove the filter by clicking “Clear Filter” and then “Sign-Off”.",
  ERR1356: "Cannot Export filtered data. Remove the filter by clicking “Clear Filter” and then “Export”.",
  INF1356: "No Filter Selected.",
  INF1357: "Filter has been applied.",
  ERR1357: "JEM - Batch Size exceeded maximum size limit of {0}MB.",
  ERR1358: "You do not have proper permissions to Post.",
  ERR1359: "No Drafts have been selected for deletion. Please select Drafts.",
  INF1360: "{0} draft(s) has been marked as Posting Not Required successfully.",
  ERR1360: "Please select an entry to perform this action.",
  ERR1361: "Drafts in Submitted status cannot be marked as Posting Not Required.",
  ERR1362: "Only standard drafts can be marked as Posting Not Required.",
  ERR1363: "Standard drafts in Submitted status can only be deleted after {0} fiscal periods. ",
  ERR1364: "Regular JE drafts in Submitted status can only be marked as Failed in SAP.",
  INF1364: "The draft is successfully marked as failed in sap. Please refresh the page and try again.",
  ERR1365: "Please attach atleast one evidentiary document for Rescind action",
  ERR1366: "Please attach a supporting document",
  ERR1367: "Please correct the highlighted cells",
  INF1365: "There are no Changes to Save",
  INF1366: "Saved Successfully",
  ERR1368:
    "This draft has already been submitted to SAP. Please wait a few minutes for SAP to respond before refreshing and trying again.",
  ERR1369:
    "Selected POs are either modified by other user or status are changed , Please refresh and perform the action again.",
  ERR1370: "The Supervisor cannot add as additional reviewer.",
  ERR1371: "The JE Type field is required.",
  ERR1372: "The Reason Code field is required.",
  ERR2005: "Unable to delete attachment, Please try again!"
};
export { ErrorMessages };
