import { AnonymousCredential, BlockBlobClient, newPipeline } from "@azure/storage-blob";
import { JemConfiguration } from "../../../JemConfiguration";
import {
  ApiParsingUtilities,
  ILoggingProviderState,
  convertToReasonCodeDropdownOptions,
  currentFyFp,
  fyFp
} from "../../../Shared";
import { DomainDataEnum, DomainDataObjects } from "../../../Shared/contexts/JEMContext/JEMContext.domainData.types";
import { ApiError, FatalError, RuntimeError } from "../../../Shared/utilities/ErrorHelpers";
import { getRequest, getValidUrl, IUserProviderState, postRequest } from "../../../Shared/utilities/RequestUtilities";
import { AliasUtilities, FiscalPeriodStringTransformations } from "../../utilities";
import { EmptyRefGuid } from "../../utilities/EmptyRefGuid";
import { JEMChecklistResponseFromApi } from "./JEMChecklistDetailsRow";
import { FCWChecklistModel, FetchUserChecklists } from "../../utilities/FetchUserChecklists";
import { getRegionDetails, getReviewer, supportingDocumentInformationToGLAttachment } from "../GLCreate/GLCreate.State";
import { convertToBatchOptionsDropdownOptions, GLBatchPageState } from "./GLBatch.State";
import { BatchPayloadForSave, BatchResponseErrors, GLBatchState, JeBatchInfo } from "./GLBatch.types";

export async function fetchBatchFromEndpoint(
  configuration: JemConfiguration["GeneralLedgerApi"],
  domainData: Pick<DomainDataObjects, DomainDataEnum.JeReasonCodes | DomainDataEnum.JeBatchFields> | null,
  userContext: IUserProviderState,
  RefGuid: string,
  logger: ILoggingProviderState["appInsights"]
): Promise<GLBatchPageState> {
  if (RefGuid === "") {
    throw new FatalError("Please provide a valid refguid.");
  }
  if (domainData === null) {
    throw new RuntimeError("Domain Data information did not come in, please contact engineering.");
  }

  const endpoint = `${configuration.baseApiUrl}${configuration.endpoints.getBatch}`.replace("{refguid}", RefGuid);
  const endpointUrl = getValidUrl(endpoint);
  const response = await getRequest<BatchPayloadForSave>(endpointUrl, userContext.accessToken, logger);
  if (!response) {
    throw new ApiError("Batch not found.", {
      refGuid: RefGuid
    });
  }

  const endpointForErrors = `${configuration.baseApiUrl}${configuration.endpoints.getBatchErrors}`.replace(
    "{refguid}",
    RefGuid
  );
  const batchErrorsResponse = await getRequest<BatchResponseErrors>(endpointForErrors, userContext.accessToken);
  if (!batchErrorsResponse) {
    throw new ApiError("Batch Errors not found.", {
      refGuid: RefGuid
    });
  }

  if (response.jeBatchInfo.fcwChecklistGuid != null && response.jeBatchInfo.fcwChecklistGuid != "") {
    const endpointForChecklistOpsDetailsName =
      `${configuration.baseApiUrl}${configuration.endpoints.fcwGetChecklist}`.replace(
        "{RefGuid}",
        response.jeBatchInfo.fcwChecklistGuid
      );

    const endpointUrlForChecklistOpsDetailsName = getValidUrl(endpointForChecklistOpsDetailsName);
    const responseForChecklistOpsDetailsName = await getRequest<JEMChecklistResponseFromApi>(
      endpointUrlForChecklistOpsDetailsName,
      userContext.accessToken
    );
    response.jeBatchInfo.opsDetailsName = responseForChecklistOpsDetailsName?.opsDetailName;
  }

  const fy = fyFp.fiscalYear.toString();
  const fp = fyFp.fiscalMonth.toString();
  let checklists = JSON.parse(sessionStorage.getItem("checklists") || "null");
  if (!checklists) {
    checklists = await FetchUserChecklists(configuration, fy, fp, userContext.accessToken);
    sessionStorage.setItem("checklists", JSON.stringify(checklists));
  }

  //parse API response to formik data
  const batchState = apiResponseToBatchState(
    response,
    batchErrorsResponse,
    userContext.user,
    userContext.jemUser,
    domainData,
    checklists
  );
  return {
    batchState,
    pageConfiguration: {
      readOnly: true,
      refGuid: batchState.refGuid,
      isChecklist:
        batchState.userSelectedChecklist && batchState.userSelectedChecklist.checklistRefGuid !== EmptyRefGuid()
          ? true
          : false,
      maxAdditionalReviewers: 4,
      maxBackupPosters: 4,
      version: batchState.versionNo ? batchState.versionNo.toString() : "1",
      maxPreReviewers: 4
    }
  };
}

function apiResponseToBatchState(
  response: BatchPayloadForSave,
  errors: BatchResponseErrors,
  user: IUserProviderState["user"],
  jemUser: IUserProviderState["jemUser"],
  domainData: Pick<DomainDataObjects, DomainDataEnum.JeReasonCodes | DomainDataEnum.JeBatchFields>,
  checklists: FCWChecklistModel[]
): GLBatchState {
  const reasonCodesDropDownOptions = convertToReasonCodeDropdownOptions(domainData[DomainDataEnum.JeReasonCodes]);
  const optionsDropDownOptions = convertToBatchOptionsDropdownOptions(
    response.batchDetailsInfo.optionalFields,
    domainData
  );

  // check if an All Optional Field isSelected
  let selectedOptionKeys: number[] = [];
  const allOptionalFieldsSelected = response.batchDetailsInfo.optionalFields.filter(
    (option) => option.displayName.toLowerCase() === "all"
  );
  if (allOptionalFieldsSelected.length > 0 && allOptionalFieldsSelected[0].isSelected) {
    selectedOptionKeys = [0];
  } else {
    selectedOptionKeys = response.batchDetailsInfo.optionalFields
      .filter((option) => option.isSelected)
      .map((option) => {
        // find using fieldId
        const found = optionsDropDownOptions.find((item) => option.fieldId === item.key);
        if (found) {
          return found.key as number;
        }
        return null;
      })
      .filter((item) => item !== null) as number[];
  }

  const { primaryReviewer, secondaryReviewer } = getReviewer(response.jeBatchInfo.reviewer, jemUser.supervisor);
  const additionalReviewers = AliasUtilities.getAdditionalReviewers(
    response.jeBatchInfo.additionalReviewers,
    secondaryReviewer
  );
  //reusing getBackupPosters for preReviewers
  const preReviewers = AliasUtilities.getBackupPosters(response.jeBatchInfo.preReviewers, []);
  const backupPosters = AliasUtilities.getBackupPosters(response.jeBatchInfo.additionalPosters, []);

  const fp = !response.batchDetailsInfo.fiscalPeriod
    ? currentFyFp()
    : FiscalPeriodStringTransformations.FiscalPeriodStringToFiscalYearAndFiscalMonth(
        response.batchDetailsInfo.fiscalPeriod
      );

  const { region, lockRegion } = getRegionDetails(response.batchDetailsInfo.sdData);
  const attachments =
    region !== null
      ? supportingDocumentInformationToGLAttachment(response.batchDetailsInfo.sdData?.sdInfos, region)
      : [];
  const batchState: GLBatchState = {
    poster: user.alias,
    author: ApiParsingUtilities.addDefault(response.jeBatchInfo.authorList, user.alias),
    createdDate: new Date(response.jeBatchInfo.createdOn).toLocaleDateString("en-US"),
    modifiedDate: new Date(response.jeBatchInfo.modifiedOn).toLocaleDateString("en-US"),
    fiscalPeriod: fp.fiscalMonth.toString(),
    fiscalYear: fp.fiscalYear.toString(),
    batchDetails: {
      isBPOEntry: response.jeBatchInfo.isBPOEntry,
      isBulkType: response.jeBatchInfo.postingType === 7 ? true : false,
      batchPurpose: ApiParsingUtilities.addDefault(response.batchDetailsInfo.purpose, ""),
      batchDesc: ApiParsingUtilities.addDefault(response.jeBatchInfo.batchDesc, ""),
      batchName: ApiParsingUtilities.addDefault(response.jeBatchInfo.batchName, ""),
      reasonCode: ApiParsingUtilities.addDefault(response.batchDetailsInfo.reasonCode),
      option: selectedOptionKeys,
      batchFileName: ApiParsingUtilities.addDefault(response.batchDetailsInfo.fileName, ""),
      fiscalYearPeriod: ApiParsingUtilities.addDefault(response.jeBatchInfo.fiscalPeriod, "")
    },
    attachments: attachments,
    attachments_region: region !== null ? region.id : 2,
    lock_region: attachments.length > 0 ? lockRegion : false,
    posters: {
      backupPosters: backupPosters
    },
    reviewers: {
      reviewer: primaryReviewer,
      additionalReviewers: additionalReviewers,
      preReviewers: preReviewers
    },
    errorTable: errors.map((error) => ({
      description: error.errorMessage,
      rowNumber: error.lineNum
    })),
    postingType: response.batchDetailsInfo.postingType,
    refGuid: response.jeBatchInfo.refGuid,
    status: response.jeBatchInfo.status,
    chkRefGuid: response.jeBatchInfo.chkRefGuid,
    errorFilePath: response.jeBatchInfo.errorFilePath,
    fileUrl: response.batchDetailsInfo.filePath,
    hdrInfo: response.jeBatchInfo.hdrInfo,
    isPostingRequired: response.jeBatchInfo.isPostingRequired,
    mode: response.jeBatchInfo.mode,
    uri: response.jeBatchInfo.uri,
    authorList: response.jeBatchInfo.authorList,
    tenantId: response.jeBatchInfo.tenantId,
    versionNo: response.jeBatchInfo.versionNo,
    processingStatus: 0,
    reasonCodesDropDownOptions,
    optionsDropDownOptions,
    isAdhocDraft: response.jeBatchInfo.isAdhocDraft,
    userChecklists: checklists,
    userSelectedChecklist: !response.jeBatchInfo.fcwChecklistGuid
      ? null
      : {
          checklistId: 0,
          checklistRefGuid: response.jeBatchInfo.fcwChecklistGuid,
          checklistName: response.jeBatchInfo.fcwChecklistName,
          postingPeriod: response.jeBatchInfo.fiscalPeriod,
          opsDetailsName: response.jeBatchInfo.opsDetailsName ? response.jeBatchInfo.opsDetailsName : "",
          backupReviewers: "",
          backupPosters: ""
        },
    checklistName: response.jeBatchInfo["fcwChecklistName"]
      ? response.jeBatchInfo["fcwChecklistName"]
      : response.jeBatchInfo.fcwChecklistGuid || "NOT APPLICABLE",
    checklistCompanyCodes: response.jeBatchInfo.fcwChecklistCoCodes ? response.jeBatchInfo.fcwChecklistCoCodes : "",
    batchCompanyCode: response.jeBatchInfo.batchCompanyCode ? response.jeBatchInfo.batchCompanyCode : "",
    isDefaultState: false,
    actualTimeTakenMins: response.batchDetailsInfo.actualTimeTakenMins
      ? response.batchDetailsInfo.actualTimeTakenMins
      : 0,
    isPreReview: response.jeBatchInfo.isPreReview
  };
  return batchState;
}
export async function ValidateBatchFile(
  configuration: JemConfiguration["GeneralLedgerApi"],
  accessToken: IUserProviderState["accessToken"],
  batchInfo: JeBatchInfo
): Promise<JeBatchInfo> {
  const endpoint = `${configuration.baseApiUrl}${configuration.endpoints.validateBatchfile}`;
  const endpointUrl = getValidUrl(endpoint);

  const response = await postRequest<JeBatchInfo>(endpointUrl, batchInfo, accessToken);
  if (!response) {
    throw new ApiError("Could not Validate Draft.");
  }

  return response;
}

export async function uploadBatchFile(
  configuration: JemConfiguration["DocumentsApi"],
  userContext: IUserProviderState,
  regionName: string,
  batchName: string,
  bacthFile: File
): Promise<{
  batchUploadUri: string;
  pathToFileWithoutContainerName: string;
}> {
  const currentDate = new Date();
  const monthYearString = `${currentDate.toLocaleString("en-US", { month: "short" })}_${currentDate.getFullYear()}`;

  const endpoint = `${configuration.baseApiUrl}${configuration.endpoints.getBatchUploadUri}`
    .replace("{regionName}", regionName)
    .replace("{currentFiscalMonthAndFiscalYear}", monthYearString)
    .replace("{batchName}", batchName);

  const fileExtension = batchName.slice(batchName.lastIndexOf(".")).toLowerCase();
  let blobContentType = "multipart/form-data";
  if (fileExtension.indexOf("txt") !== -1) {
    blobContentType = "text/plain";
  } else if (
    fileExtension.indexOf("xls") !== -1 ||
    fileExtension.indexOf("xlsx") !== -1 ||
    fileExtension.indexOf("xlsb") !== -1
  ) {
    blobContentType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  } else if (fileExtension.indexOf("xml") !== -1) {
    blobContentType = "text/xml";
  } else {
    blobContentType = "application/x-www-form-urlencoded";
  }

  const pipeline = newPipeline(new AnonymousCredential(), {
    // httpClient: MyHTTPClient, // A customized HTTP client implementing IHttpClient interface
    retryOptions: {
      maxTries: 4,
      maxRetryDelayInMs: 4000,
      retryDelayInMs: 1000
    },
    userAgentOptions: { userAgentPrefix: "JEM VNext Batch File" },
    keepAliveOptions: {
      enable: false
    }
  });

  const getBatchUploadUri = getValidUrl(endpoint);
  const batchUploadUri = await getRequest<string>(getBatchUploadUri, userContext.accessToken);

  if (!batchUploadUri) {
    throw new ApiError("Could not get Batch Upload Uri.");
  }

  const blockBlobClient = new BlockBlobClient(batchUploadUri, pipeline);
  await blockBlobClient.uploadData(bacthFile, {
    blockSize: 4 * 1024 * 1024, // 4MB block size
    concurrency: 5,
    metadata: {
      UploadedBy: encodeURIComponent(userContext.user.alias),
      FileName: encodeURIComponent(bacthFile.name)
    },
    blobHTTPHeaders: {
      blobContentType
    }
  });

  const pathToFile = new URL(batchUploadUri).pathname;
  const pathToFileWithoutContainerName = pathToFile.startsWith("/")
    ? pathToFile.slice(pathToFile.indexOf("/", 1))
    : pathToFile.slice(pathToFile.indexOf("/", 0));
  return {
    batchUploadUri,
    pathToFileWithoutContainerName: pathToFileWithoutContainerName.slice(1)
  };
}
