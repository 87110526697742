import * as React from "react";

import { CreatePageType, PageConfiguration } from "../GLCreate.State";
import { LineItemModel } from "../GLCreateForm.types";
import { getErrorMessagePerCell, LineItemsValidationSchema } from "./GLCreateLineItems.validateLineItem";

import * as wjCore from "@grapecity/wijmo";
import { CellEditEndingEventArgs, CellType, FlexGrid as FlexGridCore } from "@grapecity/wijmo.grid";
import { FlexGrid, FlexGridColumn } from "@grapecity/wijmo.react.grid";

import { Stack, useTheme } from "@fluentui/react";

import { BehaviorSubject, debounceTime } from "rxjs";
import { LoadingSpinner, LoadingStatus } from "../../../../Shared";
import { FlexGridParentProps } from "../../../../Shared/utilities/FlexGridStyles";
import { GLCreateTableProps } from "./GLCreateLineItems.types";
import { getItemsForActionPayload, getDataForGrid } from "./GLCreateLineItems.utilities";

const isEqual = require("lodash/isEqual");

enum EvenType {
  CellEditEnding = "cellEditEnding",
  PastedCell = "pastedCell"
}

export interface OnChangeEvent {
  type: EvenType;
  col?: string;
  precision: number;
  pageConfiguration: PageConfiguration;
}

let onChangeEventsSubject: BehaviorSubject<OnChangeEvent | null> | null = null;

const commonPropsForColumns = (props?: Partial<FlexGridColumn["props"]>): Partial<FlexGridColumn["props"]> => ({
  wordWrap: true,
  isRequired: false,
  ...props
});

let GLCreateLineItems: React.FC<GLCreateTableProps> = (props) => {
  const [flex, setFlex] = React.useState<FlexGridCore | null>(null);
  const theme = useTheme();
  const validationSchema = LineItemsValidationSchema(props.pageConfiguration);
  const originalItems = Array.from(props.lineItems.map((item) => ({ ...item })));
  const [_, setFlexGridHandlers] = React.useState<{
    onCellEditEnding: ((s: FlexGridCore, e: CellEditEndingEventArgs) => void) | null;
    onPastedCell: (() => void) | null;
  }>({
    onCellEditEnding: null,
    onPastedCell: null
  });
  React.useEffect(() => {
    if (!onChangeEventsSubject) {
      onChangeEventsSubject = new BehaviorSubject<OnChangeEvent | null>(null);
    }
    return () => {
      // unmount subject
      if (onChangeEventsSubject) {
        onChangeEventsSubject.complete();
        onChangeEventsSubject = null;
      }
    };
  }, []);

  React.useEffect(
    function setupLineNumbersAndRxJsSubjectGenerator() {
      if (!flex) return;
      if (!onChangeEventsSubject) return;

      onChangeEventsSubject.pipe(debounceTime(300)).subscribe((eventObj) => {
        if (!eventObj) {
          return;
        }
        const allData = flex.collectionView.items;

        const itemsForPayload = getItemsForActionPayload(
          {
            pageConfiguration: eventObj.pageConfiguration,
            precision: eventObj.precision
          },
          originalItems,
          allData
        );

        if (eventObj.type === EvenType.PastedCell && props.onLineItemPasted) {
          props.onLineItemPasted(itemsForPayload, {
            pageConfiguration: eventObj.pageConfiguration,
            precision: eventObj.precision
          });
        }
        if (eventObj.type === EvenType.CellEditEnding && props.onCellDataChanged && eventObj.col) {
          props.onCellDataChanged(eventObj.col as keyof LineItemModel, itemsForPayload, {
            pageConfiguration: eventObj.pageConfiguration,
            precision: eventObj.precision
          });
        }
      });
    },
    [flex, onChangeEventsSubject]
  );

  React.useEffect(
    function changeReadOnlyStatus() {
      if (flex) flex.isReadOnly = props.status !== LoadingStatus.Resolved;
    },
    [flex, props.status]
  );

  React.useEffect(
    function resetDataListenersWhenPrecisionChanges() {
      if (!flex) return;
      if (props.status !== LoadingStatus.Resolved) return;

      flex.itemFormatter = (panel: { cellType: CellType }, r: number, c: any, cell: { textContent: any }) => {
        if (panel.cellType === CellType.RowHeader) {
          cell.textContent = (r + 1).toString();
        }
      };

      setFlexGridHandlers((previousHandlers) => {
        if (previousHandlers.onCellEditEnding) {
          flex.cellEditEnding.removeHandler(previousHandlers.onCellEditEnding);
        }
        if (previousHandlers.onPastedCell) {
          flex.pastedCell.removeHandler(previousHandlers.onPastedCell);
        }
        const onCellEditEnding = (s: FlexGridCore, e: CellEditEndingEventArgs) => {
          const col = s.columns[e.col];
          if (onChangeEventsSubject && col.binding) {
            onChangeEventsSubject.next({
              type: EvenType.CellEditEnding,
              col: col.binding,
              precision: props.precision,
              pageConfiguration: props.pageConfiguration
            });
          }
        };
        const onPastedCell = () => {
          if (onChangeEventsSubject) {
            onChangeEventsSubject.next({
              type: EvenType.PastedCell,
              precision: props.precision,
              pageConfiguration: props.pageConfiguration
            });
          }
        };
        flex.cellEditEnding.addHandler(onCellEditEnding);
        flex.pastedCell.addHandler(onPastedCell);
        return {
          onCellEditEnding,
          onPastedCell
        };
      });
    },
    [flex, props.precision, props.status, props.pageConfiguration]
  );

  React.useImperativeHandle(props.customRef, () => ({
    getData: async (options) => {
      if (!flex) {
        return [
          [],
          [
            {
              rowNumber: -1,
              description: "Table is not ready yet."
            }
          ]
        ];
      }
      return getItemsForActionPayload(
        {
          pageConfiguration: props.pageConfiguration,
          precision: props.precision
        },
        originalItems,
        flex.collectionView.items,
        options
      );
    }
  }));

  React.useEffect(
    function refreshDataInGrid() {
      if (!flex) return;
      if (props.status !== LoadingStatus.Resolved) return;
      const allItems = getDataForGrid(originalItems);
      flex.itemsSource = new wjCore.CollectionView(allItems, {
        getError: getErrorMessagePerCell(props.precision, validationSchema)
      });
      // send event to update the totals
      if (onChangeEventsSubject) {
        onChangeEventsSubject.next({
          type: EvenType.PastedCell,
          precision: props.precision,
          pageConfiguration: props.pageConfiguration
        });
      }
    },
    [props.lineItems, props.status]
  );
  const flexGridParentStyleProps = React.useMemo(() => {
    return FlexGridParentProps(theme, {
      className: "glcreate--lineitems",
      height: 500
    });
  }, [theme]);

  return (
    <>
      <Stack
        {...{
          tokens: {
            maxWidth: "100%",
            childrenGap: 5,
            padding: 10
          }
        }}
      >
        <Stack.Item>
          <div {...flexGridParentStyleProps}>
            {props.status !== LoadingStatus.Resolved && props.status !== LoadingStatus.Rejected && (
              <div className="loading">
                <LoadingSpinner label="Loading Line Items" />
              </div>
            )}
            <FlexGrid
              initialized={(ctl: FlexGridCore) => {
                setFlex(ctl);
                ctl.columnHeaders.rows.defaultSize = 52;

                ctl.beginningEdit.addHandler((s, e) => {
                  const row = e.getRow().dataItem;
                  if (row.isReadOnly) {
                    e.cancel = true;
                  }
                });

                const allItems = getDataForGrid(originalItems);

                const view = new wjCore.CollectionView(allItems, {
                  getError: getErrorMessagePerCell(props.precision, validationSchema)
                });

                ctl.itemsSource = view;

                //Set rows to readOnly after data is loaded
                ctl.loadedRows.addHandler((_s, _e) => {
                  const rows = ctl.rows.filter((row) => row.dataItem.account !== undefined);
                  rows.forEach((row) => {
                    if (row.dataItem.isExtSourceRow === true) {
                      row.isReadOnly = true;
                    }
                  });
                });
              }}
              allowAddNew={false}
              validateEdits={false}
              allowSorting={false}
              allowDragging={false}
              selectionMode="MultiRange"
              showSelectedHeaders="All"
            >
              <FlexGridColumn
                header="Account"
                binding="account"
                dataType={wjCore.DataType.Number}
                width={90}
                {...commonPropsForColumns({ isReadOnly: props.disabled })}
                format="f0"
              />
              <FlexGridColumn
                header="Cost Center"
                binding="costCenter"
                dataType={wjCore.DataType.String}
                width={90}
                {...commonPropsForColumns({ isReadOnly: props.disabled })}
              />
              <FlexGridColumn
                header="Internal Order"
                binding="internalOrder"
                dataType={wjCore.DataType.String}
                width={90}
                {...commonPropsForColumns({ isReadOnly: props.disabled })}
              />
              <FlexGridColumn
                header="Sales Order"
                binding="salesOrder"
                dataType={wjCore.DataType.String}
                width={120}
                {...commonPropsForColumns({ isReadOnly: props.disabled })}
              />
              <FlexGridColumn
                header="SO Line Item"
                binding="soLineItem"
                dataType={wjCore.DataType.String}
                width={90}
                {...commonPropsForColumns({ isReadOnly: props.disabled })}
              />
              <FlexGridColumn
                header="WBS Element"
                binding="wbsElement"
                dataType={wjCore.DataType.String}
                width={90}
                {...commonPropsForColumns({ isReadOnly: props.disabled })}
              />
              <FlexGridColumn
                header="Trading Partner"
                binding="tradingPartner"
                dataType={wjCore.DataType.String}
                width={100}
                {...commonPropsForColumns({ isReadOnly: props.disabled })}
              />
              <FlexGridColumn
                header="Profit Center"
                binding="profitCenter"
                dataType={wjCore.DataType.String}
                width={160}
                {...commonPropsForColumns({ isReadOnly: props.disabled })}
              />
              <FlexGridColumn
                header="Line Amount"
                binding="lineAmount"
                dataType={wjCore.DataType.Number}
                width={110}
                {...commonPropsForColumns({ isReadOnly: props.disabled })}
                format={`n${props.precision}`}
              />
              {(props.pageConfiguration.pageType === CreatePageType.DualCurrency ||
                props.pageConfiguration.pageType === CreatePageType.FourCurrency) && (
                <FlexGridColumn
                  header="Line AmountLC"
                  binding="lineAmountInCurrency"
                  dataType={wjCore.DataType.Number}
                  width={110}
                  {...commonPropsForColumns({ isReadOnly: props.disabled })}
                  format={`n${props.precision}`}
                />
              )}
              {props.pageConfiguration.pageType === CreatePageType.FourCurrency && (
                <FlexGridColumn
                  header="Line AmountLC2"
                  binding="lineAmountLC2"
                  dataType={wjCore.DataType.Number}
                  width={110}
                  {...commonPropsForColumns({ isReadOnly: props.disabled })}
                  format={`n${props.precision}`}
                />
              )}
              {props.pageConfiguration.pageType === CreatePageType.FourCurrency && (
                <FlexGridColumn
                  header="Line AmountLC3"
                  binding="lineAmountLC3"
                  dataType={wjCore.DataType.Number}
                  width={110}
                  {...commonPropsForColumns({ isReadOnly: props.disabled })}
                  format={`n${props.precision}`}
                />
              )}
              <FlexGridColumn
                header="Line Desc"
                binding="lineDescription"
                dataType={wjCore.DataType.String}
                width={110}
                {...commonPropsForColumns({ isReadOnly: props.disabled })}
              />
              <FlexGridColumn
                header="Assignment"
                binding="assignment"
                dataType={wjCore.DataType.String}
                width={110}
                {...commonPropsForColumns({ isReadOnly: props.disabled })}
              />
              <FlexGridColumn
                header="Tax Code"
                binding="taxCode"
                dataType={wjCore.DataType.String}
                width={110}
                {...commonPropsForColumns({ isReadOnly: props.disabled })}
              />
              <FlexGridColumn
                header="Processing Status"
                binding="processingStatus"
                dataType={wjCore.DataType.String}
                width="*"
                minWidth={200}
                isReadOnly={true}
                {...commonPropsForColumns({ isReadOnly: true })}
              />
            </FlexGrid>
          </div>
        </Stack.Item>
      </Stack>
    </>
  );
};

GLCreateLineItems = React.memo(GLCreateLineItems, (prevProps, nextProps) => {
  if (prevProps.status !== nextProps.status) return false;
  if (prevProps.precision !== nextProps.precision) return false;
  if (prevProps.disabled !== nextProps.disabled) return false;
  if (!isEqual(prevProps.pageConfiguration, nextProps.pageConfiguration)) return false;
  if (!isEqual(prevProps.lineItems, nextProps.lineItems)) return false;
  return true;
});

GLCreateLineItems.displayName = "GLCreateLineItems";
export { GLCreateLineItems };
