import { ComboBox, IComboBoxProps } from "@fluentui/react";
import {
  convertToFiscalDropdownOptions,
  DomainDataEnum,
  DomainDataObjects,
  FCWChecklistModel,
  ForceMaxWidthAndHeightOnDropdownStyle,
  JEMChecklistDropdown,
  JemConfiguration
} from "@jem/components";
import React, { RefObject, useImperativeHandle, useState } from "react";

export interface IChecklistCloneSelectorRef {
  getUserSelectedChecklist(checkForErrors: boolean): FCWChecklistModel | null;
}

export interface IChecklistCloneSelectorProps {
  domainData: Pick<DomainDataObjects, DomainDataEnum.FiscalPeriods>;
  customRef: RefObject<IChecklistCloneSelectorRef>;
  currentFiscalPeriod: string;
  configuration: JemConfiguration["GeneralLedgerApi"];
}

const ChecklistCloneSelector: React.FC<IChecklistCloneSelectorProps> = (props) => {
  const fysfps = convertToFiscalDropdownOptions(props.domainData[DomainDataEnum.FiscalPeriods] || []);
  const [selectedPeriodKey, setSelectedPeriodKey] = useState<string>(props.currentFiscalPeriod);
  const [selectedChecklist, setSelectedChecklist] = useState<FCWChecklistModel | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  useImperativeHandle(props.customRef, () => ({
    getUserSelectedChecklist: (checkForErrors: boolean) => {
      if (!selectedChecklist && checkForErrors) {
        setErrorMessage("Please select a checklist to clone");
        return null;
      }
      return selectedChecklist;
    }
  }));

  const onChangeFyFp: IComboBoxProps["onChange"] = async (_event, item, _index, _value) => {
    if (!item) {
      return;
    }
    setSelectedPeriodKey(item.key as string);
  };

  // find props.currentFiscalPeriod in fysfps
  // if found, then get the checklists for that fiscal period
  const currentFyFp = fysfps.find((fysfp) => fysfp.text === selectedPeriodKey);

  return (
    <>
      <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
        <ComboBox
          label="Target Checklist Period"
          onChange={onChangeFyFp}
          options={fysfps}
          styles={ForceMaxWidthAndHeightOnDropdownStyle({ width: "100%", height: "200px" })}
          allowFreeform={true}
          autoComplete="on"
          selectedKey={currentFyFp ? currentFyFp.key : undefined}
        ></ComboBox>
      </div>
      <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
        <JEMChecklistDropdown
          label="Target Checklist"
          configuration={props.configuration}
          fiscalYearFiscalPeriod={currentFyFp ? currentFyFp.text : ""}
          selectedChecklist={selectedChecklist}
          onChecklistChange={(checklist: FCWChecklistModel) => {
            setErrorMessage(undefined);
            setSelectedChecklist(checklist);
          }}
          disabled={false}
          errorMessage={errorMessage}
          onNewChecklists={function (_checklists: FCWChecklistModel[]): void {
            setErrorMessage(undefined);
          }}
        />
      </div>
    </>
  );
};

ChecklistCloneSelector.displayName = "ChecklistCloneSelector";
export { ChecklistCloneSelector };
