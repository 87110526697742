import { css } from "@emotion/css";
import { DefaultButton, MessageBar, MessageBarType, Text } from "@fluentui/react";

import React, { useContext } from "react";
import { ElseIf, If } from "react-condition";
import { JemConfiguration } from "../../../../JemConfiguration";
import { LoadingSpinner } from "../../../../Shared/components/LoadingSpinner/LoadingSpinner";
import { UserContext } from "../../../../Shared/contexts/UserContext/UserContext";
import { MockDataFn } from "../../../../Shared/utilities/RequestUtilities";
import { fetchSapDetails, SapDetailsPayload } from "../GLDetails.jeid.requests";
import { GLJeSapDetailsState } from "../GLDetailsForm.types";
import { SapDetailsHeader } from "./GLDetails.SapDetailsHeader";
import { SapDetailsLineItems } from "./GLDetails.SapDetailsLineItems";
import { useQuery } from "../../../../Shared";

export interface GLSapDetailsProps {
  configuration: JemConfiguration["GeneralLedgerApi"];
  mockDashboardDataFn?: MockDataFn<any>;
  payloadForService: SapDetailsPayload;
  load?: boolean;
  templateId?: number; //making it nullable as actions like bulk edit will not be available from dashboard dialog for s2s JEs
}

const GLSapDetails: React.FC<GLSapDetailsProps> = (props) => {
  const userContext = useContext(UserContext);
  const [isEnabled, setIsEnabled] = React.useState(props.load || false);

  const { isLoading, data, error, isFetching } = useQuery({
    queryKey: ["sapDetails", props.payloadForService],
    queryFn: (queryOptions) => {
      const [, settings] = queryOptions.queryKey as [string, SapDetailsPayload];
      return fetchSapDetails(props.configuration, userContext.accessToken, settings);
    },
    enabled: isEnabled,
    staleTime: 0
  });
  const sapDetails = data as GLJeSapDetailsState | null;

  return (
    <>
      <div className="ms-Grid" dir="ltr">
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12">
            <If test={!data && !isFetching}>
              <DefaultButton
                styles={{ root: { float: "right", marginTop: "16px" } }}
                text="Load Sap Details"
                onClick={() => setIsEnabled(true)}
                disabled={props.payloadForService.docNum === 0}
              />
              <ElseIf test={isLoading && isFetching}>
                <LoadingSpinner label={"Loading SAP Details"} />
              </ElseIf>
              <ElseIf test={error !== undefined}>
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm12">
                    <MessageBar
                      messageBarType={MessageBarType.error}
                      isMultiline={false}
                      dismissButtonAriaLabel="Back to Dashboard"
                    >
                      {(error as Error | undefined)?.message}
                    </MessageBar>
                  </div>
                </div>
              </ElseIf>
              <ElseIf test={sapDetails}>
                <SapDetailsHeader state={sapDetails as NonNullable<GLJeSapDetailsState>} />
                <SapDetailsLineItems
                  state={sapDetails as NonNullable<GLJeSapDetailsState>}
                  templateId={props.templateId}
                />
              </ElseIf>
            </If>
          </div>
        </div>
      </div>
    </>
  );
};

GLSapDetails.displayName = "GLSapDetails";
export { GLSapDetails };
