import { useCallback, useContext, useEffect, useReducer } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";

import { OpsDashboardActionsFromApi } from "../OperationsDashboard/Actions.types";
import { IRequestPayload, IRequestPayloadFromApi } from "./OpsDashboardCreate.types";
import {
  FatalError,
  getRequest,
  IUserProviderState,
  JemNotification,
  LoadingStatus,
  OpsDashboardApi,
  redirectMeToTheOrigin,
  RequireAtLeastOne,
  UserContext
} from "@jem/components";
import { OpsDashboardContext } from "../../contexts/OpsDashboardContext/OpsDashboardContext";

export enum ActionStates {
  ShowAndActive,
  ShowAndReadOnly,
  Hidden
}

export interface ActionsAvailable {
  Create: ActionStates;
  Save: ActionStates;
  SendToApprovers: ActionStates;
  Approve: ActionStates;
  RejectWithComment: ActionStates;
}

function getRequestId(urlParams: any): string | null {
  if ("RowKey" in urlParams && urlParams.RowKey) {
    const requestId = urlParams.RowKey;
    if (requestId && requestId.length !== 0) {
      return requestId;
    }
  }
  return null;
}

function sanitizeRequest(request: IRequestPayloadFromApi | null): IRequestPayload | null {
  if (!request) {
    return null;
  }
  return {
    actionId: request.actionId,
    rowKey: request.rowKey,
    createdBy: request.createdBy,
    approvedBy: request.approvedBy,
    assignReviewers: request.assignReviewers,
    parameters: request.parameters,
    requestId: request.requestId,
    notes: request.notes,
    reviewDate: request.reviewDate,
    runDate: request.runDate,
    title: request.title,
    status: request.status,
    sqlStatement: request.sQlStatment
  };
}

export interface UseOpsDashboardApi {
  actionId: number | null;
  requestId: string | null;
  request: IRequestPayload | null;
  action: OpsDashboardActionsFromApi | null;
  notification: JemNotification | null;
  loadingStatus: LoadingStatus;
  actionStates: ActionsAvailable;
  isEditable: boolean;
}

const defaultActions: ActionsAvailable = {
  Create: ActionStates.Hidden,
  Save: ActionStates.Hidden,
  SendToApprovers: ActionStates.Hidden,
  Approve: ActionStates.Hidden,
  RejectWithComment: ActionStates.Hidden
};

const UseOpsDashboardInitialState: UseOpsDashboardApi = {
  actionId: null,
  requestId: null,
  request: null,
  action: null,
  notification: null,
  actionStates: defaultActions,
  isEditable: false,
  loadingStatus: LoadingStatus.Idle
};

type useSlEntryFromApiStateReducer = (
  state: UseOpsDashboardApi,
  changes: RequireAtLeastOne<UseOpsDashboardApi>
) => UseOpsDashboardApi;

const calculateEditable = (user: IUserProviderState["user"], request?: IRequestPayload): boolean => {
  const alias = user.userName.split("@")[0];
  if (!request) {
    return true;
  }
  const isCreator = request.createdBy.indexOf(alias) !== -1;
  const isDraft = request.status.indexOf("Draft") !== -1;
  const isRejected = request.status.indexOf("Reject") !== -1;
  if (isCreator) {
    if (isDraft || isRejected) {
      return true;
    }
  }
  return false;
};

const calculateSingleActions = (user: IUserProviderState["user"], request?: IRequestPayload): ActionsAvailable => {
  const alias = user.userName.split("@")[0].toLowerCase();
  const actions: ActionsAvailable = { ...defaultActions };
  if (!request) {
    actions.Create = ActionStates.ShowAndActive;
    return actions;
  }

  const isCreator = request.createdBy.toLowerCase().indexOf(alias) !== -1;
  const isReviewer = request.assignReviewers.toLowerCase().indexOf(alias) !== -1;
  const isApprover = request.approvedBy.toLowerCase().indexOf(alias) !== -1;

  const isDraft = request.status.indexOf("Draft") !== -1;
  const isSubmitted = request.status.indexOf("Submitted") !== -1;
  const isInReview = request.status.indexOf("Review") !== -1;
  const isApproved = request.status.indexOf("Approved") !== -1;
  const isRejected = request.status.indexOf("Reject") !== -1;
  const isComplete = request.status.indexOf("Completed") !== -1;

  if (isDraft) {
    if (isCreator) {
      actions.Save = ActionStates.ShowAndActive;
      actions.SendToApprovers = ActionStates.ShowAndActive;
    } else if (isReviewer) {
      actions.Approve = ActionStates.ShowAndReadOnly;
      actions.RejectWithComment = ActionStates.ShowAndReadOnly;
    }
  } else if (isSubmitted) {
    if (isCreator) {
      actions.Save = ActionStates.ShowAndReadOnly;
      actions.SendToApprovers = ActionStates.ShowAndReadOnly;
    } else if (isReviewer) {
      actions.Approve = ActionStates.ShowAndActive;
      actions.RejectWithComment = ActionStates.ShowAndActive;
    }
  } else if (isInReview) {
    if (isCreator) {
      actions.Save = ActionStates.ShowAndReadOnly;
      actions.SendToApprovers = ActionStates.ShowAndReadOnly;
    } else if (isReviewer && isApprover) {
      actions.Approve = ActionStates.ShowAndReadOnly;
      actions.RejectWithComment = ActionStates.ShowAndReadOnly;
    } else if (isReviewer) {
      actions.Approve = ActionStates.ShowAndActive;
      actions.RejectWithComment = ActionStates.ShowAndActive;
    }
  } else if (isApproved) {
    if (isCreator) {
      actions.Save = ActionStates.ShowAndReadOnly;
      actions.SendToApprovers = ActionStates.ShowAndReadOnly;
    } else if (isReviewer) {
      actions.Approve = ActionStates.ShowAndReadOnly;
      actions.RejectWithComment = ActionStates.ShowAndReadOnly;
    }
  } else if (isRejected) {
    if (isCreator) {
      actions.Save = ActionStates.ShowAndActive;
      actions.SendToApprovers = ActionStates.ShowAndActive;
    } else if (isReviewer) {
      actions.Approve = ActionStates.ShowAndReadOnly;
      actions.RejectWithComment = ActionStates.ShowAndReadOnly;
    }
  } else if (isComplete) {
    if (isCreator) {
      actions.Save = ActionStates.ShowAndReadOnly;
      actions.SendToApprovers = ActionStates.ShowAndReadOnly;
    } else if (isReviewer) {
      actions.Approve = ActionStates.ShowAndReadOnly;
      actions.RejectWithComment = ActionStates.ShowAndReadOnly;
    }
  }
  return actions;
};

const useOpsDashboardApi = (
  config: OpsDashboardApi
): [UseOpsDashboardApi, (rowKey: string) => void, (status: LoadingStatus) => void] => {
  const userContext = useContext(UserContext);
  const opsDashboardContext = useContext(OpsDashboardContext);
  const urlParams = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [requestState, dispatch] = useReducer<useSlEntryFromApiStateReducer>(
    (previousState, changes) => ({
      ...previousState,
      ...changes
    }),
    UseOpsDashboardInitialState
  );

  const getFromRowKey = useCallback(
    async (rowKey: string, actionId?: string | null): Promise<RequireAtLeastOne<UseOpsDashboardApi>> => {
      try {
        const endpointForRequest = `${config.baseApiUrl}${config.endpoints.getRequestById.replace(
          "{requestId}",
          rowKey
        )}`;
        const requestFromApi = await getRequest<IRequestPayloadFromApi>(endpointForRequest, userContext.accessToken);
        const request = sanitizeRequest(requestFromApi);
        if (!request) {
          throw new Error("Could not get action from API");
        }
        const endpointForActionTemplate = `${config.baseApiUrl}${config.endpoints.getActionById.replace(
          "{actionId}",
          `${request.actionId}`
        )}`;
        const actionTemplate = await getRequest<OpsDashboardActionsFromApi>(
          endpointForActionTemplate,
          userContext.accessToken
        );
        return {
          actionId: actionId ? Number(actionId) : Number(request.actionId),
          requestId: rowKey,
          request: request,
          action: actionTemplate,
          loadingStatus: LoadingStatus.Resolved,
          actionStates: calculateSingleActions(userContext.user, request),
          isEditable: calculateEditable(userContext.user, request)
        };
      } catch (e) {
        console.error(e);
        return {
          requestId: rowKey,
          notification: {
            type: "Error",
            summaryBodyText: `Cannot connect to OpsDashboard API to fetch ${rowKey}. Please try again later.`,
            exception: e,
            subjectHeader: `Cannot connect to OpsDashboard API to fetch ${rowKey}. Please try again later.`
          },
          loadingStatus: LoadingStatus.Rejected
        };
      }
    },
    [config, userContext.user, userContext.accessToken]
  );

  const resetEntry = useCallback(
    async (rowKey: string) => {
      const newState = await getFromRowKey(rowKey);
      dispatch(newState);
    },
    [config]
  );

  const setLoading = useCallback((status: LoadingStatus) => {
    dispatch({
      loadingStatus: status
    });
  }, []);

  useEffect(() => {
    return () => {
      dispatch(UseOpsDashboardInitialState);
    };
  }, []);

  useEffect(() => {
    if (
      opsDashboardContext.reviewers.status === LoadingStatus.Idle ||
      opsDashboardContext.reviewers.status === LoadingStatus.Pending
    ) {
      return;
    }
    if (opsDashboardContext.reviewers.status === LoadingStatus.Rejected) {
      dispatch({
        requestId: "NoReviewers",
        notification: {
          type: "Error",
          subjectHeader: `Cannot connect to OpsDashboard API to fetch Reviewers. Please try again later.`,
          messageBodyText: `Cannot connect to OpsDashboard API to fetch Reviewers. Please try again later.`
        },
        loadingStatus: LoadingStatus.Rejected
      });
      redirectMeToTheOrigin(navigate, location);
      return;
    }
    let requestCanceled = false;
    const query = new URLSearchParams(location.search);
    const actionId = query.get("action");
    const requestId = getRequestId(urlParams);
    if (requestState.loadingStatus !== LoadingStatus.Pending) {
      dispatch({
        loadingStatus: LoadingStatus.Pending
      });
      if (requestId !== null) {
        // RowKey Available, load Details
        const loadEntry = async (theRequestId: string) => {
          const newState = await getFromRowKey(theRequestId, actionId);
          if (!requestCanceled) {
            dispatch(newState);
            if (newState.notification?.exception && newState.notification.exception instanceof FatalError) {
              redirectMeToTheOrigin(navigate, location);
            }
          }
        };
        loadEntry(requestId);
      } else {
        // no RowKey, but ActionId to Create New
        if (actionId && actionId.length !== 0) {
          const loadAction = async () => {
            const endpointForActionTemplate = `${config.baseApiUrl}${config.endpoints.getActionById.replace(
              "{actionId}",
              `${actionId}`
            )}`;
            try {
              const actionTemplate = await getRequest<OpsDashboardActionsFromApi>(
                endpointForActionTemplate,
                userContext.accessToken
              );
              if (!requestCanceled) {
                dispatch({
                  action: actionTemplate,
                  actionId: Number(actionId),
                  request: null,
                  loadingStatus: LoadingStatus.Resolved,
                  actionStates: calculateSingleActions(userContext.user, undefined),
                  isEditable: calculateEditable(userContext.user, undefined)
                });
              }
            } catch (e) {
              console.error(e);
              if (!requestCanceled) {
                dispatch({
                  requestId: "NoId",
                  notification: {
                    type: "Error",
                    messageBodyText: `Cannot connect to OpsDashboard API to fetch Action to create New. Please try again later.`,
                    subjectHeader: `Cannot connect to OpsDashboard API to fetch Action to create New. Please try again later.`,
                    exception: e
                  },
                  loadingStatus: LoadingStatus.Rejected
                });
                if (e instanceof FatalError) {
                  redirectMeToTheOrigin(navigate, location);
                }
              }
            }
          };
          loadAction();
        }
      }
    }
    return () => {
      requestCanceled = true;
    };
    //
  }, [location.pathname, opsDashboardContext.reviewers.status]);

  return [
    {
      actionId: requestState.actionId,
      action: requestState.action,
      requestId: requestState.requestId,
      request: requestState.request,
      notification: requestState.notification,
      loadingStatus: requestState.loadingStatus,
      actionStates: requestState.actionStates,
      isEditable: requestState.isEditable
    },
    resetEntry,
    setLoading
  ];
};

export { useOpsDashboardApi };
