import { Dropdown, IDropdownOption } from "@fluentui/react";
import React, { useState, useImperativeHandle } from "react";

import { SearchState } from "../IHCCSearch.types";

export const SearchPOStatusOptions = [
  { key: 0, text: "All" },
  { key: 11, text: "Awaiting Clarification" },
  { key: 15, text: "Completed" },
  { key: 8, text: "Doc Required" },
  { key: 6, text: "Failed in SAP" },
  { key: 5, text: "Posted" },
  { key: 16, text: "Release To SAP" },
  { key: 10, text: "Sign Off Pending" },
  { key: 12, text: "Signed Off" },
  { key: 9, text: "Signoff Initiation Req'd" },
  { key: 7, text: "Signoff Not Req’d" }
];

export interface POStatusRef {
  reset: () => void;
  getStatuses: () => SearchState["Status"];
  setInitialStatuses: (statuses: SearchState["Status"]) => void;
}

export interface POStatusProps {
  customRef: React.Ref<POStatusRef>;
}

const POStatus: React.FC<POStatusProps> = (props) => {
  const [status, setStatus] = useState<number[]>([0]);

  useImperativeHandle(props.customRef, () => ({
    reset: () => {
      setStatus([0]);
    },
    getStatuses: () => {
      return status;
    },
    setInitialStatuses: (statuses: SearchState["Status"]) => {
      setStatus(statuses);
    }
  }));
  const onChangeStatus = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void => {
    if (option) {
      const keyIsAll = option.key === 0;
      if (keyIsAll) {
        if (option.selected) {
          // set the selected option to All only
          setStatus([0]);
        } else {
          // if All was selected, then set it to everything else
          setStatus([0]);
        }
      } else {
        // remove All from the selected options
        const interSelectedKeys = status.filter((i) => i !== 0);
        setStatus(
          option.selected
            ? [...interSelectedKeys, option.key as number]
            : interSelectedKeys.filter((key) => key !== option.key)
        );
      }
    }
  };
  return (
    <>
      <Dropdown
        label="PO Status"
        selectedKeys={status}
        onChange={onChangeStatus}
        options={SearchPOStatusOptions}
        multiSelect
        styles={{
          root: {
            maxWidth: "300px"
          }
        }}
      />
    </>
  );
};

POStatus.displayName = "POStatus";
export default POStatus;
