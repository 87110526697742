import { AnonymousCredential, BlockBlobClient, newPipeline } from "@azure/storage-blob";
import { CoherencePanel, CoherencePanelSize } from "@coherence-design-system/controls";
import { css } from "@emotion/css";
import { IconButton, MessageBarType, SelectionMode, Text, TextField } from "@fluentui/react";
import {
  ApiError,
  CommonJEMError,
  DashboardGrid,
  getRequest,
  getValidUrl,
  IExtendedColumn,
  InputFileButton,
  IUserProviderState,
  JemConfiguration,
  LoadingStatus,
  LocalAttachment,
  LocalMessage,
  LoggingContext,
  postRequest,
  RuntimeError,
  SimpleShimmerForForms,
  UserContext
} from "@jem/components";
import React, { useContext } from "react";
import { v4 } from "uuid";
import ActionButtons from "../../copiedItems/ActionButtons";
import InlineFormInputErrorMessage from "./InlineFormInputErrorMessage";

interface JEMChecklistDetailsImportTasksPanelProps {
  checklistName: string;
  checklistPeriod: string;
  checklistRefGUID: string;
  configuration: JemConfiguration;
  onClosePanel: () => void;
  onImportTasks: (message: LocalMessage) => void;
}

export interface BulkUploadSingleResult {
  lineNum: number;
  message: string;
}

export async function uploadFile(
  configuration: JemConfiguration["DocumentsApi"],
  userContext: IUserProviderState,
  regionName: string,
  fileName: string,
  file: File
): Promise<{
  uri: string;
  fileName: string;
}> {
  const base = configuration.baseApiUrl;
  const endpoint = getValidUrl(
    `${base}${configuration.endpoints.getBatchUploadUri.replace("{regionName}", regionName)}`
  )
    .replace("{currentFiscalMonthAndFiscalYear}", "fcwchecklist")
    .replace("{batchName}", fileName);
  const batchUploadUri = await getRequest<string>(endpoint, userContext.accessToken);

  if (!batchUploadUri) {
    throw new ApiError("Could not get Batch Upload Uri.");
  }
  const pipeline = newPipeline(new AnonymousCredential(), {
    // httpClient: MyHTTPClient, // A customized HTTP client implementing IHttpClient interface
    retryOptions: { maxTries: 4 },
    userAgentOptions: { userAgentPrefix: "JEM Checklist Bulk Upload" },
    keepAliveOptions: {
      // Keep alive is enabled by default, disable keep alive by setting false
      enable: false
    }
  });

  const blockBlobClient = new BlockBlobClient(batchUploadUri, pipeline);
  await blockBlobClient.uploadData(file, {
    blockSize: 4 * 1024 * 1024, // 4MB block size
    concurrency: 20, // 20 concurrency
    metadata: {
      UploadedBy: encodeURIComponent(userContext.user.alias),
      FileName: encodeURIComponent(file.name),
      Format: "XSLX"
    },
    blobHTTPHeaders: {
      blobContentType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    }
  });

  return {
    uri: batchUploadUri,
    fileName: fileName
  };
}

async function bulkImportTasks(
  configuration: JemConfiguration["GeneralLedgerApi"],
  userContext: IUserProviderState,
  checklistRefGUID: string,
  payload: { fileName: string; type: string }
) {
  const base = configuration.baseApiUrl;
  const endpoint = getValidUrl(
    `${base}${configuration.endpoints.fcwImportTasks.replace("{checklistRefguid}", checklistRefGUID)}`
  );
  const result = await postRequest<BulkUploadSingleResult[]>(endpoint, payload, userContext.accessToken);
  return result;
}

const ErrorColumns = () => {
  const defaultColumns: IExtendedColumn[] = [
    {
      key: "column1",
      name: "Excel Line Number",
      fieldName: "lineNum",
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      type: "number"
    },
    {
      key: "column2",
      name: "Message",
      fieldName: "message",
      minWidth: 120,
      maxWidth: 140,
      isResizable: true,
      type: "string"
    }
  ];

  return defaultColumns;
};

const JEMChecklistDetailsImportTasksPanel: React.FC<JEMChecklistDetailsImportTasksPanelProps> = (props) => {
  const userContext = useContext(UserContext);
  const logger = useContext(LoggingContext);
  const [loading, setLoading] = React.useState<LoadingStatus>(LoadingStatus.Resolved);
  const [file, setFile] = React.useState<File | undefined>(undefined);
  const [fileName, setFileName] = React.useState<string>("");
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [uploadErrors, setUploadErrors] = React.useState<BulkUploadSingleResult[]>([]);

  const onHandleSubmit = async () => {
    // validation to make sure if its a regular je that only one company code gets sent to API.
    setLoading(LoadingStatus.Pending);
    try {
      if (file === undefined) {
        setErrorMessage("Please select a file to import.");
        return;
      }
      const uploaded = await uploadFile(props.configuration.DocumentsApi, userContext, "Redmond", file.name, file);
      const bulkResult = await bulkImportTasks(
        props.configuration.GeneralLedgerApi,
        userContext,
        props.checklistRefGUID,
        {
          fileName: uploaded.fileName,
          type: "XLSX"
        }
      );
      if (!bulkResult || bulkResult.length === 0) {
        props.onImportTasks({
          message: "Tasks imported successfully.",
          type: MessageBarType.success
        });
      } else {
        logger.appInsights?.trackTrace({
          message: "Bulk Import Tasks Error",
          properties: {
            checklistRefGUID: props.checklistRefGUID,
            fileName: uploaded.fileName
          }
        });
        setErrorMessage("There were errors importing the tasks.");
        setUploadErrors(bulkResult);
      }
    } catch (e) {
      logger.appInsights?.trackException({
        error: e as Error,
        properties: {
          checklistRefGUID: props.checklistRefGUID,
          fileName: file?.name
        }
      });
      if (e instanceof ApiError) {
        if (e.props) {
          const obj = e.props as CommonJEMError;
          setErrorMessage(obj.title + ": " + obj.detail);
        } else {
          setErrorMessage(e.message);
        }
        setUploadErrors([]);
      } else {
        console.error(e);
        setErrorMessage("There were errors importing the tasks.");
        setUploadErrors([]);
      }
    } finally {
      setLoading(LoadingStatus.Resolved);
    }
  };

  const onFileSelected = async (files: File[]) => {
    if (files.length > 1) {
      throw new RuntimeError("Checklist Task Bulk should not allow multiple files.");
    }
    try {
      Array.from(files).map((file) => {
        setErrorMessage(null);
        setUploadErrors([]);
        setFile(file);
        setFileName(file.name);
        return {
          file,
          guid: v4()
        } as LocalAttachment;
      });
    } catch (e) {
      console.error(e);
    }
  };

  const onDismiss = () => {
    setErrorMessage(null);
    setUploadErrors([]);
    setFile(undefined);
    props.onClosePanel();
  };

  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.large}
      titleText={`Import Tasks for Checklist ${props.checklistName} - ${props.checklistPeriod}`}
      isOpen
      onDismiss={onDismiss}
      hasCloseButton
      closeButtonAriaLabel="Close edit JE Task"
      onRenderFooter={(): JSX.Element => (
        <ActionButtons
          mutationLoading={false}
          closePanel={onDismiss}
          handleSubmit={onHandleSubmit}
          saveLabel="Import"
          saveTitle="Import"
          cancelLabel="Cancel"
          cancelTitle="Cancel"
          disabled={loading !== LoadingStatus.Resolved}
        />
      )}
    >
      <InlineFormInputErrorMessage errorMessages={errorMessage ? [errorMessage] : []} />
      <div className="ms-Grid" dir="ltr">
        <div className="ms-Grid-row">
          <div className={`ms-Grid-col ms-sm12 ms-lg6 ${css(`height: 64px;`)}`}>
            <SimpleShimmerForForms loadingStatus={loading} height={32} label="Bulk Tasks File">
              <TextField label="Bulk Tasks File" readOnly={true} value={fileName} />
            </SimpleShimmerForForms>
          </div>
          <div
            className={`ms-Grid-col ms-sm12 ms-lg3 ${css(`
              height: 82px;
              margin-top: 4px;
              display: flex;
              align-items: center;`)}`}
          >
            <InputFileButton
              text="Browse"
              onChange={onFileSelected}
              accept=".xls, .xlsx"
              allowMultipleFiles={false}
              disabled={loading !== LoadingStatus.Resolved}
            />
            <IconButton
              iconProps={{ iconName: "OpenInNewTab" }}
              title="Download Import Tasks Template"
              ariaLabel="Import Tasks Template"
              onClick={() => {
                window.open(props.configuration.fcwImportTasksTemplate, "_blank");
              }}
            />
          </div>
        </div>
        {uploadErrors.length > 0 && (
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12 ms-lg12">
              <Text variant="large">The following errors occurred while importing the tasks:</Text>
              <DashboardGrid
                items={uploadErrors}
                columnGenerator={ErrorColumns}
                idForLocalStorage={"ImportTasksErrorsGrid"}
                isSortedIndex={0}
                height="500px"
                isSortedDescending={false}
                selectionMode={SelectionMode.none}
                customRef={null}
              />
            </div>
          </div>
        )}
      </div>
    </CoherencePanel>
  );
};

JEMChecklistDetailsImportTasksPanel.displayName = "JEMChecklistDetailsImportTasksPanel";
export { JEMChecklistDetailsImportTasksPanel };
