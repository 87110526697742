import { OrderedMap } from "immutable";
import { IIndexedTile } from "@jem/components";
import { GLTileNames, GLTilesAndCount } from "./GLDashboard.Requests";

export const initialTiles = OrderedMap<string, IIndexedTile>({
  "My Draft JEs": {
    tileCount: 0,
    tileIndex: 0
  },
  "Not Posted - Posting Requested": {
    tileCount: 0,
    tileIndex: 1
  },
  "Posted - Sign-Off Requested": {
    tileCount: 0,
    tileIndex: 2
  },
  "Posted - Action Required": {
    tileCount: 0,
    tileIndex: 3
  },
  "Posted - Reviewer Action Required": {
    tileCount: 0,
    tileIndex: 4
  },
  "Posted - Poster Action Required": {
    tileCount: 0,
    tileIndex: 5
  },
  "Pre-Review JE - Pre-Reviewer Action Required": {
    tileCount: 0,
    tileIndex: 6
  },

  "Poster - PreReview Requested": {
    tileCount: 0,
    tileIndex: 7
  }
});

export function convertToTilesInSpecificOrder(theTiles: GLTilesAndCount): OrderedMap<string, IIndexedTile> {
  const specificOrder: GLTileNames[] = [
    GLTileNames.MyDraftJEs,
    GLTileNames.NotPostedPostingRequested,
    GLTileNames.PostedSignOffRequested,
    GLTileNames.PostedActionRequired,
    GLTileNames.PostedReviewerActionRequired,
    GLTileNames.PostedPosterActionRequired,
    GLTileNames.PreReviewerActionRequired,
    GLTileNames.PosterPreReviewRequested
  ];
  let tilesMap = OrderedMap<string, IIndexedTile>({});
  let index = 0;
  for (const theTileName of specificOrder) {
    if (theTileName in theTiles) {
      const tileObj = {
        tileIndex: index,
        tileCount: theTiles[theTileName]
      };
      tilesMap = tilesMap.set(theTileName, tileObj);
      index += 1;
    }
  }

  return tilesMap;
}
