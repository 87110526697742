import { IAttachment } from "@jem/components";

export interface PaymentOrderObjectFromApi {
  bankArea: string | null;
  standardCurrency: string | null;
  id: number;
  poNum: string | null;
  fiscalYear: number;
  postingPeriod: number;
  companyCode: number;
  transactionType: string | null;
  orderingPartyAccountNumber: string | null;
  receivingPartyAccountNumber: string | null;
  receivingPartyCurrency: string | null;
  receivingPartyAmountInAccountCurrency: number;
  purpose: string | null;
  processingStatus: string | null;
  comments: string | null;
  isBulk: boolean;
  poAttachments: PoAttachment[];
  poApprovers: PoApprover[];
  poStatus: string | null;
  poMiscInfo: string | null;
  refGuid: string | null;
  bulkGUID: string | null;
  docNum: string | null;
  createdBy: number;
  createdDate: string | null;
  postDate: string | null;
  lastModifiedBy: number;
  supervisorPersonnelNum: number;
  lastModifiedDate: string | null;
  reversalItemIndicator: string | null;
  supervisorAliasToDB: string | null;
  supervisorAliasFromDB: string | null;
  reviewerWithoutSupervisorToDB: string | null;
  addtionalReviewersCSVFromDB: string | null;
  addtionalReviewersCSVToDB: string | null;
  actualReviewer: string | null;
  lastReviewDate: string | null;
  category: string | null;
  poCategory: string | null;
  paymentPurpose: string | null;
  status: number;
  createdAlias: string | null;
  lastModifiedAlias: string | null;
  actionType: string | null;
  isDocManEntry: boolean | null;
  reversalDate: string | null;
  dueDate: string | null;
  enableAction: boolean | null;
  posterAlias: string | null;
  poComments: PoComment[];
  isValid: boolean | null;
  rowVer: string | null;
  access: number;
  weightage: number;
  dbState: string | null;
  postedByJem: boolean;
  editable: boolean;
}

export interface PoApprover {
  id: number;
  poId: number;
  personalNumber: number;
  createdBy: number;
  createdDate: string | null;
  lastModifiedBy: number;
  lastModifiedDate: string | null;
  rowVer: string | null;
  alias: string | null;
}

export interface PoAttachment {
  id: number;
  poId: number;
  fileName: string | null;
  size: number;
  status: number;
  regStoreId: string | null;
  url: string | null;
  uploadDatetime: string | null;
  typeId: number;
  rowVer: string | null;
  poFileType: string | null;
  createdBy: number | null;
  createdDate: string | null;
  lastModifiedBy: number | null;
  lastModifiedDate: string | null;
}

export interface PoComment {
  id: number;
  poid: number;
  personnelNumber: number;
  alias: string | null;
  persona: number;
  comments: null | string;
  createdBy: number;
  createdDate: string | null;
  lastModifiedBy: number;
  lastModifiedDate: string | null;
  rowVer: string | null;
}

export interface SapPoDetailsFromApi {
  poId: number;
  opAccount: string | null;
  rpAccount: string | null;
  transactionType: string | null;
  currency: string | null;
  opCurrency: number | null;
  rpCurrency: number | null;
  opCurrencyType: string;
  rpCurrencyType: string;
}

export interface DetailsPageActionFromApi {
  id: number;
  actionLinkId: number;
  action: string | null;
  access: number | null;
  searchGridName: string | null;
  description: string | null;
  weightage: number;
}

export interface BulkDetailsFromApi {
  id: number;
  parentId: number;
  parentPONum: string | null;
  poNum: string | null;
  coCode: number;
  fiscalYear: number;
  fiscalPeriod: number;
  bankArea: string | null;
  enteredBy: string | null;
}

export function IAttachmentToPoAttachmentForCreate(attachment: IAttachment): Partial<PoAttachment> {
  return {
    fileName: attachment.fileName,
    size: attachment.fileSize,
    status: 1,
    typeId: 1,
    url: attachment.blobName,
    uploadDatetime: attachment.uploadedOn
  } as Partial<PoAttachment>;
}

export function poAttachmentToIAttachment(poAttachment: PoAttachment): IAttachment | null {
  if (poAttachment.url === null || poAttachment.url === "") return null;
  if (poAttachment.fileName === null || poAttachment.fileName === "") return null;
  if (poAttachment.size === null) return null;
  if (poAttachment.uploadDatetime === null || poAttachment.uploadDatetime === "") return null;
  const date = !isNaN(Date.parse(poAttachment.uploadDatetime))
    ? new Date(Date.parse(poAttachment.uploadDatetime)).toLocaleString("en-US")
    : poAttachment.uploadDatetime;
  const attachmentForUI: IAttachment = {
    id: poAttachment.id,
    blobName: poAttachment.url,
    fileName: poAttachment.fileName,
    url: poAttachment.url,
    fileSize: poAttachment.size,
    documentType: poAttachment.typeId == 2 ? "Evidentiary" : "Supporting",
    uploadedOn: date
  };
  return attachmentForUI;
}

export interface IComment {
  persona: string;
  alias: string;
  comments: string;
  dateAdded: string;
  version: string;
}

export interface AddReviewerResponse extends IHCCResponse {
  affectedPos: {
    poId: number;
    additionalReviewer: string | null;
    supervisor: string | null;
  }[];
}

export interface IHCCResponse {
  affectedRefGuid: string[];
  customMessage: null | string;
  inValidReviewers: null | string;
  responseCode: string;
  status: number;
}

export function poCommentToIComment(comment: PoComment): IComment | null {
  let persona = "";
  switch (comment.persona) {
    case 1:
      persona = "Poster";
      break;
    case 2:
      persona = "Reviewer";
      break;
    case 3:
      persona = "Administrator";
      break;
  }
  return {
    persona,
    alias: comment.alias || "",
    comments: comment.comments || "",
    dateAdded: comment.createdDate || "",
    version: "version" in comment ? (comment as any).version : ""
  };
}
