import React, { useState } from "react";
import { Pivot, PivotItem } from "@fluentui/react";
import { useNavigate, useLocation } from "react-router-dom";

export enum TabType {
  Requests = "requests",
  Jobs = "jobs",
  Attachments = "attachments"
}

export interface ITabs {
  ref: unknown;
  props: {
    onTabChanged: (tabName: TabType) => void;
  };
}

const getTabId = (itemKey: string) => {
  return `OpsDashboardTab_${itemKey}`;
};

interface LocationState {
  origin: string;
  state:
    | {
        [key: string]: any;
      }
    | undefined;
}

const getFromLocation = (location: ReturnType<typeof useLocation>): LocationState | undefined => {
  if (location.state) {
    const st = location.state as any;
    return {
      origin: st.origin,
      state: st.state
    };
  }
  return undefined;
};

const Tabs: React.FC<ITabs["props"]> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = getFromLocation(location);
  const [selectedKey, setSelectedKey] = useState<TabType>(
    locationState && locationState.state && locationState.state.tabName ? locationState.state.tabName : "requests"
  );

  const handleClick = (item?: PivotItem) => {
    if (item) {
      const tabName = item.props.itemKey as TabType;
      navigate("/ops/dashboard", { state: tabName });
      setSelectedKey(tabName);
      if (props.onTabChanged) {
        props.onTabChanged(tabName);
      }
    }
  };

  return (
    <Pivot
      aria-label="Ops Dashboard Grids"
      selectedKey={selectedKey}
      onLinkClick={handleClick}
      headersOnly={true}
      getTabId={getTabId}
    >
      <PivotItem headerText="Requests" itemKey="requests" />
      {/* <PivotItem headerText="Jobs" itemKey="jobs" />
      <PivotItem headerText="Attachments" itemKey="attachments" /> */}
    </Pivot>
  );
};

Tabs.displayName = "OpsDashboardTabs";

export default Tabs;
