import React, { useImperativeHandle, useMemo, useState } from "react";
import { Dropdown, IDropdownOption } from "@fluentui/react";
import {
  CommonDropdownOnChangeHandler,
  DomainDataEnum,
  DomainDataObjects,
  ForceMaxWidthAndHeightOnDropdownStyle
} from "@jem/components";

export interface BusinessGroupsRef {
  getBusinessGroups: () => string[];
  setInitialBusinessGroups: (initialBusinessGroups: string[]) => void;
  reset: () => void;
}

interface BusinessGroupsProps {
  loading: boolean;
  domainData: Pick<DomainDataObjects, DomainDataEnum.JeBusinessGroupInfo>;
  customRef: React.Ref<BusinessGroupsRef>;
}

export const BusinessGroupsOptions = (domainData: Pick<DomainDataObjects, DomainDataEnum.JeBusinessGroupInfo>) => {
  const duplicatePreventer: Record<string, boolean> = {};
  const bgsForDropdown: IDropdownOption<string>[] = domainData.JeBusinessGroupInfo.reduce((bgsArray, bg) => {
    if (!duplicatePreventer[bg.execFunctionOrgSummaryCode]) {
      duplicatePreventer[bg.execFunctionOrgSummaryCode] = true;
      bgsArray.push({
        key: bg.execFunctionOrgSummaryCode,
        text: bg.execFunctionOrgSummaryCode + " - " + bg.execFunctionOrgSummaryDesc
      });
    }
    return bgsArray;
  }, [] as IDropdownOption[]);
  bgsForDropdown.unshift({ key: "all", text: "All" });
  bgsForDropdown.unshift({ key: "none", text: "None Selected", disabled: true });
  return bgsForDropdown;
};

const BusinessGroupsDropdown: React.FC<BusinessGroupsProps> = (props) => {
  const [BusinessGroups, setBusinessGroups] = useState<string[]>(["none"]);
  const bgOptions = useMemo(() => {
    return BusinessGroupsOptions(props.domainData);
  }, [props.domainData]);

  const onChangeBusinessGroups = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void => {
    if (!option) {
      return;
    }

    const newOptions = CommonDropdownOnChangeHandler({
      currentOptions: BusinessGroups,
      option: option,
      keyForAll: "all",
      keyForNone: "none",
      totalOptions: bgOptions.length - 2
    });
    setBusinessGroups(newOptions);
  };

  useImperativeHandle(
    props.customRef,
    (): BusinessGroupsRef => ({
      getBusinessGroups() {
        return BusinessGroups;
      },
      setInitialBusinessGroups(initialBusinessGroups: string[]) {
        setBusinessGroups(initialBusinessGroups);
      },
      reset() {
        setBusinessGroups(["none"]);
      }
    })
  );

  return (
    <React.Fragment>
      <Dropdown
        label="Business Groups:"
        selectedKeys={BusinessGroups}
        onChange={onChangeBusinessGroups}
        options={bgOptions}
        disabled={props.loading}
        multiSelect
        styles={ForceMaxWidthAndHeightOnDropdownStyle({
          height: "300px",
          width: "100%",
          maxWidth: "100%"
        })}
      />
    </React.Fragment>
  );
};

BusinessGroupsDropdown.displayName = "BusinessGroupsDropdown";
export default BusinessGroupsDropdown;
