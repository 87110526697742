import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { DatePicker, TextField } from "@fluentui/react";
import { LoadingStatus, SimpleShimmerForForms } from "@jem/components";
import { addDays } from "date-fns";

export interface Delegates {
  delegateFor: string;
  delegateTo: string;
  startDate: string;
  endDate: string;
}
export type DelegateErrorMessage = string | null;
export interface DelegateReviewersRef {
  getDelegates: () => Delegates;
  setDelegates: (delegateToUser: string) => void;
  reset: () => void;
}
export interface DelegateProps {
  currentUserAlias: string;
  currentDelegatedToAlias: string;
  loadingStatus: LoadingStatus;
  startDate: string;
  endDate: string;
}

const DelegateReviewersForm = forwardRef<DelegateReviewersRef, DelegateProps>((props, ref) => {
  const currentDate = new Date();
  const futureDefaultDate = addDays(currentDate, 90);
  const toDate = props.startDate !== "" ? new Date(props.startDate) : currentDate;
  const fromDate = props.endDate !== "" ? new Date(props.endDate) : futureDefaultDate;
  const [delegateTo, setDelegateTo] = useState<string>(props.currentDelegatedToAlias);
  const [startDate, setStartDate] = useState<Date>(toDate);
  const [endDate, setEndDate] = useState<Date>(fromDate);

  useEffect(() => {
    setDelegateTo(props.currentDelegatedToAlias);
    setStartDate(toDate);
    setEndDate(fromDate);
  }, [props.currentDelegatedToAlias, props.startDate, props.endDate]);

  useImperativeHandle(ref, () => ({
    getDelegates: () => {
      return {
        delegateFor: props.currentUserAlias,
        delegateTo: delegateTo,
        startDate: startDate.toLocaleDateString("en-US"),
        endDate: endDate.toLocaleDateString("en-US")
      };
    },
    setDelegates(delegateToUser) {
      setDelegateTo(delegateToUser);
      setStartDate(startDate);
      setEndDate(endDate);
    },
    reset() {
      setDelegateTo("");
      setStartDate(toDate);
      setEndDate(fromDate);
    }
  }));

  const onChangeFirstTextFieldValue = React.useCallback(
    (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
      setDelegateTo(newValue || "");
    },
    []
  );

  const startDateChangeHandler = (date: Date | null | undefined) => {
    if (date && endDate && date > endDate) {
      setEndDate(date);
    }
    setStartDate(date || currentDate);
  };
  const endDateChangeHandler = (date: Date | null | undefined) => {
    if (date && startDate && date < startDate) {
      setStartDate(date);
    }
    setEndDate(date || currentDate);
  };

  return (
    <>
      <div className="ms-Grid-row">
        <div className="ms-Grid-col ms-sm12 ms-lg6">
          <SimpleShimmerForForms loadingStatus={props.loadingStatus} height={32} label="Start date:">
            <DatePicker
              label="Start Date:"
              ariaLabel="Select a start date."
              value={startDate}
              onSelectDate={startDateChangeHandler}
              minDate={startDate}
            />
          </SimpleShimmerForForms>
        </div>
        <div className="ms-Grid-col ms-sm12 ms-lg6">
          <SimpleShimmerForForms loadingStatus={props.loadingStatus} height={32} label="End date:">
            <DatePicker
              label="End Date:"
              ariaLabel="Select a end date."
              value={endDate}
              onSelectDate={endDateChangeHandler}
              minDate={startDate}
            />
          </SimpleShimmerForForms>
        </div>
      </div>
      <div className="ms-Grid-row">
        <SimpleShimmerForForms loadingStatus={props.loadingStatus} height={32} label="Delegate For:">
          <TextField label="Delegate For:" disabled value={props.currentUserAlias} readOnly />
        </SimpleShimmerForForms>
      </div>
      <div className="ms-Grid-row">
        <SimpleShimmerForForms loadingStatus={props.loadingStatus} height={32} label="Delegate To:">
          <TextField
            label="Delegate To:"
            value={delegateTo}
            onChange={onChangeFirstTextFieldValue}
            description="Delegate permissions to a user on another user's behalf."
          />
        </SimpleShimmerForForms>
      </div>
    </>
  );
});

DelegateReviewersForm.displayName = "DelegateReviewersForm";
export default DelegateReviewersForm;
