import { DatePicker, IDatePickerProps, Stack } from "@fluentui/react";
import { isValid } from "date-fns";
import { FieldProps, getIn } from "formik";

import React from "react";

export function getErrorMessage<V = any>({ field, form }: FieldProps<V>) {
  const error = getIn(form.errors, field.name);
  const touched = getIn(form.touched, field.name);

  return touched ? error : undefined;
}

export function invokeAll(...callbacks: any[]) {
  return () => {
    for (const callback of callbacks) {
      if (callback && typeof callback === "function") {
        callback();
      }
    }
  };
}

export function createFakeEvent({ name }: { name: string }) {
  return { target: { name } };
}

const onFormatDate = (date?: Date): string => {
  let baseDate = date;
  if (!baseDate) {
    baseDate = new Date();
  }
  if (!isValid(baseDate)) {
    baseDate = new Date();
  }
  const DD = (baseDate.getDate() < 10 ? "0" : "") + baseDate.getDate();
  const MM = (baseDate.getMonth() + 1 < 10 ? "0" : "") + (baseDate.getMonth() + 1);
  return MM + "/" + DD + "/" + baseDate.getFullYear();
};

export function mapFieldToDatePicker<V extends Date = Date, FormValues = any>({
  field
}: FieldProps<V, FormValues>): Pick<IDatePickerProps, "value" | "onSelectDate" | "onAfterMenuDismiss"> {
  let v: Date | undefined = field.value;
  if (isNaN(Date.parse(v?.toString())) && v?.toString() !== "") {
    v = undefined;
  }
  return {
    value: v,
    onAfterMenuDismiss: () => field.onBlur(createFakeEvent(field))
  };
}
export type DatePickerFieldProps<V extends Date = Date, FormValues = any> = Omit<
  IDatePickerProps,
  "value" | "onBlur" | "onChange"
> &
  FieldProps<V, FormValues> & {
    errorMessage?: string;
  };

export function DatePickerField<V extends Date = Date, FormValues = any>({
  field,
  form,
  meta,
  ...props
}: DatePickerFieldProps<V, FormValues>) {
  const { onAfterMenuDismiss, ...fieldProps } = mapFieldToDatePicker({
    field,
    form,
    meta
  });

  return (
    <Stack {...{ tokens: { childrenGap: "0" } }}>
      <DatePicker
        formatDate={onFormatDate}
        placeholder={"MM/DD/YYYY"}
        {...props}
        onAfterMenuDismiss={invokeAll(onAfterMenuDismiss, props.onAfterMenuDismiss)}
        {...fieldProps}
        value={isValid(field.value) ? field.value : undefined}
        onSelectDate={(date) => {
          form.setFieldValue(field.name, date);
          if (props.onSelectDate) {
            props.onSelectDate(date);
          }
        }}
        textField={{
          errorMessage: props.errorMessage
        }}
      />
    </Stack>
  );
}
