import React from "react";
import { Text, DefaultButton } from "@fluentui/react";
import { isValid, differenceInMinutes, differenceInHours } from "date-fns";

interface RefetchButtonProps {
  lastRefreshed: number;
  onRefetch: () => void;
  disabled?: boolean;
  hidden?: boolean;
}

const RefetchButton: React.FC<RefetchButtonProps> = ({ lastRefreshed, onRefetch, disabled, hidden }) => {
  const [currentTime, setCurrentTime] = React.useState(new Date());

  React.useEffect(() => {
    const timerId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => {
      clearInterval(timerId);
    };
  }, []);

  if (hidden || !lastRefreshed) {
    return null;
  }

  const now = currentTime;
  const lastRefreshedDate = new Date(lastRefreshed);
  const isDateValid = isValid(lastRefreshed);

  if (!isDateValid) {
    return null;
  }

  const timeDifferenceMinutes = differenceInMinutes(now, lastRefreshedDate);
  const timeDifferenceHours = differenceInHours(now, lastRefreshedDate);

  let formattedLastRefreshed = "";
  if (timeDifferenceMinutes < 30) {
    const remainingSeconds = Math.max(0, Math.floor((now.getTime() - lastRefreshedDate.getTime()) / 1000) % 60);
    formattedLastRefreshed = `${timeDifferenceMinutes} min ${remainingSeconds} sec ago`;
  } else if (timeDifferenceMinutes < 60) {
    formattedLastRefreshed = `${timeDifferenceMinutes} minutes ago`;
  } else if (timeDifferenceHours < 24) {
    formattedLastRefreshed = `${timeDifferenceHours} hours ago`;
  } else {
    formattedLastRefreshed = lastRefreshedDate.toLocaleString("en-US");
  }

  const isStale = lastRefreshedDate < new Date(now.getTime() - 5 * 60 * 1000);

  return (
    <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", width: "100%", marginTop: "4px" }}>
      <Text variant="small" style={{ marginRight: "10px", color: isStale ? "red" : undefined }}>
        Last Refreshed: {formattedLastRefreshed}
      </Text>
      <DefaultButton iconProps={{ iconName: "Refresh" }} ariaLabel="Refresh" onClick={onRefetch} disabled={disabled} />
    </div>
  );
};

export { RefetchButton };
