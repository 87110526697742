import React, { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { IChoiceGroupProps } from "@fluentui/react";
import { v4 } from "uuid";
import { PosterOrReviewerChoice, IAliasTypeRef } from "../PosterOrReviewerChoice/PosterOrReviewerChoice";
import { JEMAliasPicker, JEMAliasPickerRef } from "../JEMAliasPicker";

interface ISearchAliasInputSetter {
  aliases: string[];
  aliasType: "P" | "R";
}

export interface IAliasInputRef {
  getAliases: () => string[];
  getAliasType: () => "P" | "R";
  setInitialAliases: (obj: ISearchAliasInputSetter) => void;
  reset: () => void;
}

interface IAliasInputProps {
  disabled?: boolean;
  optionsForCheckboxes?: IChoiceGroupProps["options"];
}

const searchAliasId = v4();

const SearchAliasInput = forwardRef((props: IAliasInputProps, ref: React.Ref<IAliasInputRef>) => {
  const [aliasType, setAliasType] = useState<"P" | "R" | undefined>("P");
  const aliasTypeRef = useRef<IAliasTypeRef>(null);
  const jemAliasRef = useRef<JEMAliasPickerRef>(null);

  useImperativeHandle(
    ref,
    (): IAliasInputRef => ({
      getAliases() {
        if (jemAliasRef.current) {
          return jemAliasRef.current.getAliases();
        } else {
          return [];
        }
      },
      getAliasType() {
        if (aliasTypeRef.current) {
          return aliasTypeRef.current.getAliasType();
        } else {
          return "P";
        }
      },
      setInitialAliases(obj) {
        if (obj.aliases.length !== 0 && jemAliasRef.current) {
          jemAliasRef.current.setInitialAliases(obj.aliases);
        }
        setAliasType(obj.aliasType);
      },
      reset() {
        if (jemAliasRef.current) {
          jemAliasRef.current.reset();
        }
        if (!aliasTypeRef.current) {
          setAliasType("P");
        } else {
          aliasTypeRef.current.reset();
        }
      }
    })
  );

  return (
    <React.Fragment>
      <JEMAliasPicker
        customRef={jemAliasRef}
        label="Search Alias"
        labeledById={searchAliasId}
        required={false}
        disabled={!!props.disabled}
        labelComponent={
          <PosterOrReviewerChoice
            id={searchAliasId}
            customRef={aliasTypeRef}
            initialSelected={aliasType || "P"}
            options={props.optionsForCheckboxes}
            horizontal={true}
          />
        }
        description="Multiple aliases enabled. Separate by semicolons."
      />
    </React.Fragment>
  );
});

SearchAliasInput.displayName = "SearchAliasInput";

export { SearchAliasInput };
