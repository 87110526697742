import { CoherenceNav } from "@coherence-design-system/controls";
import * as React from "react";
import { useLocation } from "react-router-dom";

import { UserContext } from "../../contexts/UserContext/UserContext";
import { GeneralLedgerLinks } from "./AppNav.GeneralLedger";
import { OpsDashboardLinks } from "./AppNav.OpsDashboard";
import { PaymentOrdersLinks } from "./AppNav.PaymentOrders";
import { AppNavProps } from "./AppNav.types";

import { debounce } from "../../utilities/Utilities";
import { EUARoles } from "../../contexts/UserContext/UserContext.roles.main";
import { JEMChecklistLinks } from "./AppNav.JEMChecklist";
import { MicrofrontendsLinks } from "./AppNav.Microfrontends";
import { MySettingsLinks } from "./AppNav.MySettings";
import { SubledgerLinks } from "./AppNav.SubledgerLinks";
import { JEMContext } from "../../contexts/JEMContext";

export interface ILinks {
  name: string;
  key: string;
  ariaLabel: string;
  icon: string;
  target: string;
  onClick?: (event: React.MouseEvent | any) => void;
  isSelected?: boolean;
}

const AppNav: React.FC<AppNavProps> = (props) => {
  const location = useLocation();
  const userContext = React.useContext(UserContext);
  const domainContext = React.useContext(JEMContext);
  const navRef = React.useRef<HTMLDivElement>(null);
  const [isCollapsed, setIsCollapsed] = React.useState(false);

  const [resizeObserver] = React.useState<ResizeObserver>(
    new ResizeObserver(
      debounce(() => {
        const rootSidePanel = navRef.current?.querySelector("[class^=outerNavWrapper] > [class^=root]");
        if (rootSidePanel) {
          const width = Math.floor(rootSidePanel.getBoundingClientRect().width);
          const height = Math.floor(rootSidePanel.getBoundingClientRect().height);
          const _isCollapsed = width == 0 && height == 0;
          if (_isCollapsed !== isCollapsed) {
            setIsCollapsed(_isCollapsed);
          }
        }
      }, 100)
    )
  );

  React.useLayoutEffect(() => {
    props.onNavCollapsed(isCollapsed);
  }, [isCollapsed]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (navRef.current) {
        const rootSidePanel = navRef.current.querySelector("[class^=outerNavWrapper] > [class^=root]");
        if (rootSidePanel) {
          resizeObserver.observe(rootSidePanel);
          clearInterval(interval);
        }
      }
    }, 300);
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const onClickHandler = (route: string) => (event: React.MouseEvent | any) => {
    event.preventDefault();
    props.onClick(event, route);
  };

  const links: ILinks[] = [
    {
      name: "Home",
      key: "home",
      ariaLabel: "JEM Homepage",
      icon: "Home",
      // "data-id": "automation_id_22230",
      target: "_blank",
      onClick: onClickHandler("/home"),
      isSelected: location.pathname === "/home"
    }
  ];

  links.push(GeneralLedgerLinks(location, onClickHandler, props, userContext.jemUser, domainContext.initInfo.values));

  if (userContext.jemUser.roles.includes(EUARoles.ToolAdministrator)) {
    links.push(OpsDashboardLinks(location, onClickHandler, props, userContext.jemUser));
  }

  links.push(PaymentOrdersLinks(location, onClickHandler, userContext.jemUser));

  links.push(SubledgerLinks(location, onClickHandler, props, userContext.jemUser));

  links.push(JEMChecklistLinks(location, onClickHandler, props, userContext.jemUser));

  if (userContext.jemUser.roles.includes(EUARoles.ToolAdministrator) && props.configuration.environment !== "prod") {
    links.push(MicrofrontendsLinks(location, onClickHandler, props, userContext.jemUser));
  }

  links.push(MySettingsLinks(userContext.jemUser, location, onClickHandler));

  return (
    <div role="navigation" aria-label="Navigation Panel" ref={navRef}>
      <CoherenceNav
        appName={props.appName}
        groups={[
          {
            key: "Left Nav Menu",
            links
          }
        ]}
        styles={{
          root: {
            zIndex: 100
          },
          outerNavWrapper: {
            "[class^=navContainer]": {
              "overflow-y": "scroll"
            }
          }
        }}
        onNavCollapsed={setIsCollapsed}
      />
    </div>
  );
};

export { AppNav };
