/* eslint-disable max-len */
import {
  CoherenceHeader,
  initializeOBFeedback,
  ItemDisplayStatus,
  ItemStatus,
  NotificationListItem
} from "@coherence-design-system/controls";

import dayjs from "dayjs";
import React, { useContext, useEffect, useReducer } from "react";
import { useLocation } from "react-router-dom";
import { JemConfiguration } from "../../../JemConfiguration";
import { LoggingContext } from "../../contexts/LoggingContext/LoggingContext";
import { UserContext } from "../../contexts/UserContext/UserContext";
import { RequireAtLeastOne } from "../../utilities/TypeUtils";
import { AppHeaderUserProfile } from "./AppHeader.UserProfile";
import HelpPanel from "./HelpPanel";

const handleOcvButtonFocusState = () => {
  const overflowButton = document.getElementById("coher-header-overflow-button-id");
  if (overflowButton) {
    // set focus back to overflow button
    overflowButton.focus();
  } else {
    // set focus back to OCV button
    const ocvButton = document.getElementById("coher-header-ocv-button-id");
    if (ocvButton) {
      ocvButton.focus();
    }
  }

  return false;
};

export interface IAppHeader {
  props: {
    configuration: JemConfiguration;
  };
}

interface IAppHeaderState {
  showDismissAllBool: boolean;
  ocvButtonIsFocused: boolean;
  dismissOpenedPanel: boolean | undefined;
  notifications: NotificationListItem[];
  notificationCount: number;
}

const initialState: IAppHeaderState = {
  showDismissAllBool: false,
  ocvButtonIsFocused: false,
  dismissOpenedPanel: undefined,
  notifications: [],
  notificationCount: -1
};

type IAppHeaderReducer = (state: IAppHeaderState, changes: RequireAtLeastOne<IAppHeaderState>) => IAppHeaderState;

let AppHeader: React.FC<IAppHeader["props"]> = (props) => {
  const context = useContext(UserContext);
  const loggingContext = useContext(LoggingContext);
  const location = useLocation();
  const [state, setState] = useReducer<IAppHeaderReducer>(
    (previousState, changes) => ({
      ...previousState,
      ...changes
    }),
    initialState
  );

  initializeOBFeedback(
    // WHY A LIST OF ARGS YARGGGG! https://coherence-portal.azurewebsites.net/Controls/Header-Feedback#Code
    /*appId*/ 2571,
    /*environment */ props.configuration.environment === "prod" ? "Prod" : "Int",
    /*scriptUrl */ `${props.configuration.jemUiComponentsEndpoint}/@ms-ofb/officebrowserfeedback@1.6.2/officebrowserfeedback.min.js`,
    /*stylesUrl */ `${props.configuration.jemUiComponentsEndpoint}/@ms-ofb/officebrowserfeedback@1.6.2/officebrowserfeedback.min.css`,
    /*intlUrl */ "/intl/",
    /*userVoiceUrl: */ undefined,
    /*userEmail */ undefined,
    /*sessionId*/ undefined,
    /*cid*/ undefined,
    /*build*/ "0.0.1",
    /*primaryColour*/ undefined,
    /*secondaryColour*/ undefined,
    /*intlFilename*/ undefined,
    /*locale*/ "en",
    /*onError*/ (error: string) => {
      alert("An error has occured during submission: " + error);
      const ocvButtonIsFocused = handleOcvButtonFocusState();
      setState({
        ocvButtonIsFocused
      });
    },
    /*applicationGroup*/
    undefined,
    /*telemetryGroup*/ {
      platform: "Web",
      featureArea: location.pathname
    },
    /*webGroup*/ undefined,
    /*userEmailConsentDefault*/ undefined,
    /*onDismiss*/ (submitted: boolean) => {
      if (submitted) {
        alert("Your feedback has been submitted");
      }
      const ocvButtonIsFocused = handleOcvButtonFocusState();
      setState({
        ocvButtonIsFocused
      });
    },
    /*bugForm*/ undefined,
    /*screenshot*/ undefined,
    /*eventSampling*/ undefined,
    /*showEmailAddress*/ undefined,
    /*transitionEnabled*/ undefined,
    /*isShowThanks*/ undefined
  );

  useEffect(() => {
    if (loggingContext.notifications.length !== 0) {
      const newNotifications = [...loggingContext.notifications].map((newNotification, i) => ({
        itemKey: `${i}`,
        displayStatus: "new" as ItemDisplayStatus,
        status: "unread" as ItemStatus,
        timeDelivered: dayjs(),
        // making 135 characters message to make sure truncation happens, message caps at 100 characters
        summaryBodyText: newNotification.summaryBodyText,
        subjectIcon: newNotification.subjectIcon || "Mail",
        subjectIconColor: newNotification.subjectIconColor,
        // `${newNotification.type} - ${newNotification.rowKey}`
        subjectHeader: newNotification.subjectHeader,
        messageBodyText: newNotification.messageBodyText,
        senderName: newNotification.senderName,
        senderImageUrl: newNotification.senderImageUrl,
        eventTimeBlock: newNotification.eventTimeBlock,
        eventLocation: newNotification.eventLocation,
        actionRequiredText: newNotification.actionRequiredText,
        actionRequiredLink: newNotification.actionRequiredLink
      }));
      const normalized = [...state.notifications, ...newNotifications].map((notification, i) => {
        notification.itemKey = `${i}`;
        return notification;
      });

      setState({
        notifications: normalized,
        notificationCount: state.notificationCount === -1 ? 1 : state.notificationCount + 1
      });

      loggingContext.clearNotifications();
    }
  }, [loggingContext.notifications]);

  const updateNotificationCount = (): void => {
    // Placeholder for code that updates displayStatus to 'old' for all items listed in the id string array
    // Setting to -1 to see badge disappear when notification panel opens
    setState({ notificationCount: -1 });
  };

  const updateItem = (
    itemKey: string,
    dStatus: NotificationListItem["displayStatus"],
    rStatus?: NotificationListItem["status"]
  ) => {
    const list = [...state.notifications]
      .filter((item) => {
        if (item.itemKey === itemKey && dStatus === "dismiss") {
          return false;
        }
        return true;
      })
      .map((n) => {
        if (n.itemKey === itemKey) {
          n.displayStatus = dStatus;
          if (rStatus) {
            n.status = rStatus;
          }
        }
        return n;
      });
    setState({ notifications: list });
  };

  const onDismissAll = (): void => {
    setState({ notifications: [] });
  };

  return (
    <CoherenceHeader
      headerLabel={"Journal Entry Management"}
      appNameSettings={{
        label: "Journal Entry Management"
      }}
      farRightSettings={{
        helpSettings: {
          panelSettings: {
            titleText: "Help",
            body: HelpPanel,
            hasCloseButton: true,
            closeButtonAriaLabel: "Close Panel"
          },
          buttonSettings: {
            title: "Help",
            ariaLabel: "Help"
          }
        },
        additionalItems: [
          {
            key: "additional-item-1",
            title: "Digital Assistance",
            ariaLabel: "Digital Assistance",
            text: "Ask Finsup",
            iconProps: {
              iconName: "ChatBot"
            },
            onClick: () => {
              (window as any).OpenFDA(`hostappid=${props.configuration.JEMHelpBotHostAppId}`);
            }
          }
        ],
        feedbackSettings: {
          panelSettings: {
            ocvButtonIsFocused: state.ocvButtonIsFocused,
            onClick: () => {
              setState({
                ocvButtonIsFocused: true
              });
              return true;
            }
          }
        },
        notificationsSettings: {
          buttonSettings: {
            title: "Notifications",
            ariaLabel: "Notifications"
          },
          panelSettings: {
            titleText: "Notifications",
            items: state.notifications,
            isUndoDismissAllVisible: false,
            onDismissMultiple: onDismissAll,
            onPanelOpened: updateNotificationCount,
            newNotifications: state.notificationCount,
            panelSize: "600px",
            updateItem
          }
        },
        profileSettings: {
          panelSettings: {
            fullName: context.user.name,
            emailAddress: context.user.userName,
            imageUrl: "",
            logOutLink: "/logout",
            onLogOut: context.logout,
            body: AppHeaderUserProfile
          }
        }
      }}
      dismissOpenedPanel={state.dismissOpenedPanel || false}
      onDismissOpenedPanel={() => {
        setState({ dismissOpenedPanel: undefined });
      }}
    />
  );
};

AppHeader = React.memo(AppHeader);

export { AppHeader };
