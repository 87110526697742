import { css } from "@emotion/css";
import { Text } from "@fluentui/react";
import { OpsDashboardApi, PageStyles } from "@jem/components";
import React, { useState } from "react";
import { Case, Default, Switch } from "react-condition";
import Actions from "../../components/OperationsDashboard/Actions";
import RequestsGrid from "../../components/OperationsDashboard/RequestsGrid";
import Tabs, { TabType } from "../../components/OperationsDashboard/Tabs";
import { OpsDashboardMocks } from "./OpsDashboard.mocks";

export interface OpsDashboardProps {
  mocks?: OpsDashboardMocks;
  configuration: OpsDashboardApi;
}

let OpsDashboard: React.FC<OpsDashboardProps> = (props) => {
  const [tabName, setTabName] = useState<TabType | null>(null);

  return (
    <div className={PageStyles.rootDiv}>
      <Text variant="large">Operations Dashboard</Text>
      <Tabs
        onTabChanged={(tabName) => {
          setTabName(tabName);
        }}
      ></Tabs>
      <div
        className={css`
          display: inline-flex;
          gap: 16px;
          flex-direction: row;
          width: 100%;
          margin: 16px 0 16px 0;

          &:first-of-type(.column) {
            max-width: 300px;
          }
          & .column {
            display: flex;
            flex-direction: column;
            flex-basis: 100%;
          }

          @media screen and (min-width: 800px) {
            & .column:nth-child(2) {
              flex: 3;
            }

            & .column {
              flex: 1;
            }
          }
        `}
      >
        <Switch expression={tabName}>
          <Case value={TabType.Jobs}>
            <p>jobs</p>
          </Case>
          <Case value={TabType.Attachments}>
            <p>Attachments</p>
          </Case>
          <Default>
            <div className="column">
              <Actions configuration={props.configuration} mockFn={props.mocks?.actionsFn}></Actions>
            </div>
            <div className="column">
              <RequestsGrid configuration={props.configuration} mockFn={props.mocks?.gridFn}></RequestsGrid>
            </div>
          </Default>
        </Switch>
      </div>
    </div>
  );
};

OpsDashboard.displayName = "OpsDashboard";
OpsDashboard = React.memo(OpsDashboard);
export default OpsDashboard;
