import {
  ApiError,
  DateStr,
  GLDashboardRowFromApi,
  IUserProviderState,
  getRequest,
  sanitizeDashboardRow,
  SanitizedDashboardRow,
  JemConfiguration
} from "@jem/components";

export interface JESearchResult {
  jeId: number;
  batchName: null;
  docNumber: number;
  jeName: null;
  reverseJENum: number;
  companyCode: number;
  siginificance: string;
  jeType: string;
  jeStatus: number;
  statusCode: string;
  description: string;
  author: string;
  poster: string;
  additionalPosters: string;
  reviewer: string;
  postingType: number;
  fiscalYear: number;
  fiscalPeriod: number;
  dateModified: DateStr;
  dueDate: DateStr | null;
  reasonCode: string;
  isBPO: boolean;
  reasonDescription: string;
  reviewerAction: null;
  reviewerComment: string;
  wfGuid: string;
  refGuid: string;
  access: number;
  mark: string;
  refNum: string;
  version: string;
  entryType: number;
  bgCode: null;
  actualReviewer: string;
  reviewedDate: DateStr | null;
  actionEnabled: boolean;
  attachments: Attachments;
  attachmentInfo: string;
  isTenant: number;
  isPostedByJem: number;
  rowWeightage: number;
}

export interface Attachments {
  regStoreAttachments: RegStoreAttachment[];
  corpStoreAttachments: [];
}

export interface RegStoreAttachment {
  fileId: number;
  originalName: string;
  regStoreId: number;
  url: string;
  uploadedDate: Date;
  fileSize: number;
  fileStatus: number;
  blobName: string;
  region: string;
  isRescindEvidence: boolean;
}

export interface JESearchResultModel {
  docNumber: string;
  significance: string;
  companyCode: string;
  reviewer: string;
  poster: string;
  additionalPosters: string;
  fy: string;
  fp: string;
  header: string;
  hasAttachments: boolean;
  bpo: string;
  dueDate: string;
  dateModified: string;
  processingStatus: string;
}

export async function fetchBatchSearchDetailsResults(
  configuration: JemConfiguration["GeneralLedgerApi"],
  accessToken: IUserProviderState["accessToken"],
  batchGuid: string
): Promise<SanitizedDashboardRow[]> {
  const endpointUrl = `${configuration.baseApiUrl}${configuration.endpoints.getBatchSearchDetailsResults}`.replace(
    "{batchGuid}",
    batchGuid
  );
  const response = await getRequest<GLDashboardRowFromApi[]>(endpointUrl, accessToken);
  if (!response) {
    throw new ApiError("JE not found.", {
      batchGuid
    });
  }

  return response.map(sanitizeDashboardRow);
}
