/* eslint-disable @typescript-eslint/no-explicit-any */
import { FontWeights, mergeStyleSets, Theme, useTheme } from "@fluentui/react";
import React, { createContext } from "react";

function getActionManagerStyles(theme: Theme) {
  const contentStyles = mergeStyleSets({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch"
    },
    header: [
      //
      theme.fonts.xLargePlus,
      {
        flex: "1 1 auto",
        borderTop: `4px solid ${theme.palette.themePrimary}`,
        color: theme.palette.neutralPrimary,
        display: "flex",
        alignItems: "center",
        fontWeight: FontWeights.semibold,
        padding: "12px 12px 14px 24px"
      }
    ],
    body: {
      flex: "4 4 auto",
      padding: "0 24px 24px 24px",
      overflowY: "hidden",
      selectors: {
        p: { margin: "14px 0" },
        "p:first-child": { marginTop: 0 },
        "p:last-child": { marginBottom: 0 }
      }
    }
  });
  const iconButtonStyles = {
    root: {
      color: theme.palette.neutralPrimary,
      marginLeft: "auto",
      marginTop: "4px",
      marginRight: "2px"
    },
    rootHovered: {
      color: theme.palette.neutralDark
    }
  };

  return {
    contentStyles,
    iconButtonStyles
  };
}

export interface ActionsContextData {
  styles: {
    // an attempt to match what is expected by Fluent
    contentStyles: {
      container: any;
      header: any;
      body: any;
    };
    iconButtonStyles: {
      root: any;
      rootHovered: any;
    };
  };
}

const initialState: ActionsContextData = {
  styles: {
    contentStyles: {
      container: undefined,
      header: undefined,
      body: undefined
    },
    iconButtonStyles: {
      root: undefined,
      rootHovered: undefined
    }
  }
};

const ActionsContext = createContext(initialState);

export interface ActionsProviderProps {
  children: React.ReactNode;
}

const ActionsProvider: React.FC<ActionsProviderProps> = (props) => {
  const theme = useTheme();
  const { contentStyles, iconButtonStyles } = getActionManagerStyles(theme);

  return (
    <ActionsContext.Provider
      value={{
        styles: {
          contentStyles,
          iconButtonStyles
        }
      }}
    >
      {props.children}
    </ActionsContext.Provider>
  );
};

ActionsProvider.displayName = "ActionsProvider";

export { ActionsProvider, ActionsContext };
