import { css } from "@emotion/css";
import { IColumn, Icon, Link, SelectionMode, Theme, TooltipHost, useTheme } from "@fluentui/react";
import {
  DashboardGrid,
  DomainDataObjects,
  exportToExcel,
  FatalError,
  IExtendedColumn,
  JemConfiguration,
  JEMContext,
  LoadingStatus,
  MockDataFn,
  UserContext,
  Section,
  SectionRef,
  RefetchButton,
  useQuery,
  LoadingSpinner
} from "@jem/components";
import React, { useContext, useEffect, useImperativeHandle, useRef, useState } from "react";
import GLBatchSearchJEResults from "./GLBatchSearchJEResults";
import { BatchLineInfoModel, fetchSearchResults } from "./GLBatchSearchResults.requests";
import { GLBatchSearchCriteria } from "../GLBatchSearch.types";

interface GLBatchSearchResultsColumns extends IExtendedColumn {
  fieldName: keyof BatchLineInfoModel;
}

export interface BatchSelected {
  batchName: string;
  refGuid: string;
}

export const getGLBatchSearchColumns =
  (theme: Theme, onBatchSelected: (batch: BatchSelected) => void) => (): IExtendedColumn[] => {
    const cols: GLBatchSearchResultsColumns[] = [
      {
        key: "batchName",
        name: "Batch Name",
        fieldName: "batchName",
        type: "string",
        minWidth: 300,
        onRender: (
          item?: BatchLineInfoModel,
          index?: number | undefined,
          column?: IColumn | undefined
        ): JSX.Element | React.ReactText | null => {
          if (!column || !item) {
            return null;
          }
          return (
            <Link
              onClick={() => {
                onBatchSelected({ batchName: item.batchName, refGuid: item.refGuid });
              }}
            >
              {item.batchName}
            </Link>
          );
        }
      },
      {
        key: "postedDate",
        name: "Posted Date",
        fieldName: "postingDate",
        type: "date",
        minWidth: 240
      },
      {
        key: "postedSuccessfully",
        name: "JEs Posted Successfully",
        fieldName: "successPostings",
        type: "number",
        minWidth: 140
      },
      {
        key: "inBatch",
        name: "JEs In Batch",
        fieldName: "totalPostings",
        type: "number",
        minWidth: 140
      },
      {
        key: "postingComplete",
        name: "Posting Complete",
        fieldName: "postingComplete",
        type: "string",

        minWidth: 60,
        onRender: (item?: BatchLineInfoModel) => {
          if (item) {
            return onRenderFunctionForBatchResults(
              theme,
              item.postingComplete ? "Completed" : "Incomplete",
              item.postingComplete
            );
          }
          return null;
        }
      },
      {
        key: "backedUp",
        name: "Backed Up",
        fieldName: "hasAttachments",
        type: "string",
        minWidth: 60,
        onRender: (item?: BatchLineInfoModel) => {
          if (item) {
            return onRenderFunctionForBatchResults(
              theme,
              item.hasAttachments ? "Has Attachments" : "Does not have attachments",
              item.hasAttachments
            );
          }
          return null;
        }
      },
      {
        key: "bpo",
        name: "BPO",
        fieldName: "bpoBatch",
        type: "string",
        minWidth: 40,
        onRender: (item?: BatchLineInfoModel) => {
          if (item) {
            return onRenderFunctionForBatchResults(theme, item.bpoBatch ? "BPO" : "Not BPO", item.bpoBatch);
          }
          return null;
        }
      },
      {
        key: "checkListName",
        name: "CheckList Name",
        fieldName: "checkListName",
        type: "string",
        minWidth: 140
      },
      {
        key: "opsDetailsName",
        name: "Ops Details Name",
        fieldName: "opsDetailsName",
        type: "string",
        minWidth: 140
      },
      {
        key: "isAdhocOrStd",
        name: "JE Task Type",
        fieldName: "isAdhocOrStd",
        type: "string",
        minWidth: 140
      }
    ];

    return cols;
  };

export interface GLBatchSearchResultsRef {
  removeCache: () => void;
}

export interface GLBatchSearchResultsProps {
  configuration: JemConfiguration["GeneralLedgerApi"];
  attachmentsConfiguration: JemConfiguration["DocumentsApi"];
  domainData: DomainDataObjects;
  searchCriteria: GLBatchSearchCriteria;
  mockDashboardDataFn?: MockDataFn<any>;
  selectedBatch: BatchSelected | null;
  onBatchSelected: (batch: BatchSelected) => void;
  customRef: React.MutableRefObject<GLBatchSearchResultsRef | null>;
}

const GLBatchSearchResults: React.FC<GLBatchSearchResultsProps> = (props) => {
  const userContext = useContext(UserContext);
  if (!userContext) {
    throw new FatalError("Please use a UserContext Provider.");
  }
  const domainContext = useContext(JEMContext);
  if (!domainContext) {
    throw new FatalError("Please use a JEMContext Provider.");
  }
  const theme = useTheme();
  const sectionRef = useRef<SectionRef>(null);
  const [selectedBatchRefGuid, setSelectedBatchRefGuid] = useState<BatchSelected | null>(null);

  const {
    data: pageState,
    error,
    isFetching,
    isLoading,
    dataUpdatedAt,
    refetch
  } = useQuery({
    queryKey: ["GLBatchSearchResults", props.searchCriteria],
    queryFn: () => {
      if (sectionRef.current) {
        sectionRef.current.expand();
      }
      return fetchSearchResults(
        props.configuration,
        userContext.accessToken,
        domainContext.initInfo.values,
        props.searchCriteria
      );
    },
    enabled:
      domainContext.initInfo.status === LoadingStatus.Resolved ||
      domainContext.initInfo.status === LoadingStatus.Rejected,
    // set stale to 1 hour
    staleTime: 1000 * 60 * 60
  });

  useImperativeHandle(
    props.customRef,
    () => ({
      removeCache: () => {
        // refetch();
      }
    }),
    []
  );

  useEffect(() => {
    if (sectionRef.current) {
      if (selectedBatchRefGuid) {
        sectionRef.current.collapse();
      } else {
        sectionRef.current.expand();
      }
    }
  }, [selectedBatchRefGuid]);

  useEffect(() => {
    if (isFetching || isLoading) return;
    if (!pageState) return;

    setSelectedBatchRefGuid(props.selectedBatch);
  }, [props.selectedBatch, isFetching, isLoading, pageState]);

  const loadingStatus =
    isLoading || isFetching
      ? LoadingStatus.Pending
      : error !== undefined
      ? LoadingStatus.Rejected
      : LoadingStatus.Resolved;
  const gridItems = pageState ? (pageState as BatchLineInfoModel[]) : [];

  return (
    <>
      <>
        <Section label="Batch Results" maxHeight="370px" customRef={sectionRef}>
          <RefetchButton
            lastRefreshed={dataUpdatedAt}
            onRefetch={() => {
              refetch();
            }}
            disabled={loadingStatus === LoadingStatus.Pending}
          />
          <div
            className={css`
              border-left: 2px solid;
              padding-left: 10px;
            `}
            data-is-scrollable="true"
          >
            {loadingStatus === LoadingStatus.Pending ? (
              <LoadingSpinner label={"Loading search results"} />
            ) : (
              <DashboardGrid
                idForLocalStorage={"glBatchSearchResults"}
                customRef={null}
                columnGenerator={getGLBatchSearchColumns(theme, props.onBatchSelected)}
                items={gridItems}
                loadingStatus={loadingStatus}
                isSortedIndex={1}
                height={`300px`}
                isSortedDescending={true}
                selectionMode={SelectionMode.none}
                onExport={(rows) => {
                  exportToExcel({
                    sheetName: "batchResults",
                    rowsWithHeader: rows,
                    fileName: "batchResults.xlsx"
                  });
                }}
              ></DashboardGrid>
            )}
          </div>
        </Section>
        {selectedBatchRefGuid && (
          <Section label={`Journal Entries in Batch ${selectedBatchRefGuid.batchName}`}>
            <GLBatchSearchJEResults
              configuration={props.configuration}
              attachmentsConfiguration={props.attachmentsConfiguration}
              domainData={props.domainData}
              refGuid={selectedBatchRefGuid.refGuid}
              batchName={selectedBatchRefGuid.batchName}
            />
          </Section>
        )}
      </>
    </>
  );
};

GLBatchSearchResults.displayName = "GLBatchSearchResults";
export default GLBatchSearchResults;

function onRenderFunctionForBatchResults(theme: Theme, label: string, flag: boolean): any {
  return (
    <TooltipHost content={label}>
      {flag ? (
        <Icon
          iconName="checkmark"
          styles={{
            root: {
              color: theme.palette.accent,
              marginRight: "8px"
            }
          }}
        />
      ) : null}
    </TooltipHost>
  );
}
