import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { AuthModuleImpl, BaseRouteProps, EUARoles, UserContext } from "@jem/components";
import { AAG3Dashboard } from "./Microfrontends/pages/AAG3Dashboard/AAG3Dashboard";
import { Context } from "@micro-frontend-react/core/lib/Context";
import { JECreate } from "./Microfrontends/pages/JECreate/JECreate";
import { MicrofrontendAdapter } from "./Microfrontends/pages/JECreate/MicrofrontendAdapter";

const Microfrontends: React.FC<BaseRouteProps> = (props) => {
  const userContext = React.useContext(UserContext);
  const msalProvider = new AuthModuleImpl(userContext);

  return (
    <>
      {userContext.jemUser.roles.includes(EUARoles.ToolAdministrator) ? (
        <Context.Provider
          value={{
            msalProvider
          }}
        >
          <Routes>
            <Route path="/aag3dashboard" element={<AAG3Dashboard configuration={props.config}></AAG3Dashboard>} />
            <Route path="/jecreate">
              <Route index element={<JECreate />} />
              <Route path="newje-microfrontend" element={<MicrofrontendAdapter configuration={props.config} />} />
            </Route>
          </Routes>
        </Context.Provider>
      ) : (
        <Navigate to="/home" />
      )}
    </>
  );
};

Microfrontends.displayName = "Microfrontends";
export default Microfrontends;
