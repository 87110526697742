import { Dropdown, IDropdownOption, IDropdownProps } from "@fluentui/react";
import React, { useContext, useEffect, useImperativeHandle, useState } from "react";
import { ForceMaxWidthAndHeightOnDropdownStyle } from "../../utilities/FluentUIHacks";
import { JemConfiguration } from "../../../JemConfiguration";
import { UserContext } from "../../contexts/UserContext/UserContext";

import { CommonDropdownOnChangeHandler } from "../../utilities/CommonDropdownOnChangeHandler";
import { OpsDetail, getOpsDetails } from "../OpsDetailsDropdown";
import { useQuery } from "../../hooks/useQuery";

const isEqual = require("lodash/isEqual");

interface SearchOpsDetailDropdownRef {
  getOpsDetail: () => number[];
  reset: () => void;
  setInitialOpsDetail: (value: number[]) => void;
}

interface SearchOpsDetailDropdownProps {
  configuration: JemConfiguration["GeneralLedgerApi"];
  onChange?: (opsDetail: string[]) => void;
  customRef: React.Ref<SearchOpsDetailDropdownRef>;
  value?: number;
  styles?: IDropdownProps["styles"];
}

const SearchOpsDetailDropdown: React.FC<SearchOpsDetailDropdownProps> = (props) => {
  const [opsDetail, setOpsDetail] = useState<number[]>([-1]);
  const userContext = useContext(UserContext);

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ["opsDetails"],
    queryFn: () => getOpsDetails(props.configuration, userContext, true)
  });
  const opsDetailsFromApi: OpsDetail[] = data || [];

  useImperativeHandle(props.customRef, () => ({
    getOpsDetail: () => {
      if (opsDetail.length == 1 && opsDetail[0] == 0) {
        return opsDetailsFromApi.map((a) => a.id);
      }
      if (opsDetail.length == 1 && opsDetail[0] == -1) {
        return [];
      }
      if (opsDetail) {
        return opsDetail;
      }
      return [];
    },
    reset: () => {
      setOpsDetail([]);
    },
    setInitialOpsDetail(value: number[]) {
      if (value.length === 0) {
        setOpsDetail([-1]);
        return;
      }
      // the following is to handle the case where the user has selected all the options
      // but we already have the data from the api, if we don't have the data from the api then
      // we only set the value
      if (data !== null) {
        const all = opsDetailsFromApi.map((a) => a.id);
        if (isEqual(value, all)) {
          setOpsDetail([0]);
          return;
        }
      }
      setOpsDetail(value);
    }
  }));

  useEffect(() => {
    // the following is to handle the case where the user has selected all the options
    // but we didn't have the data from the api, now that we have the data from the api
    // we set the value to all
    if (opsDetail.length !== 0 && opsDetail[0] !== -1) {
      const all = opsDetailsFromApi.map((a) => a.id);
      if (isEqual(opsDetail, all)) {
        setOpsDetail([0]);
      }
    }
  }, [data]);

  const onChangeOpsDetail = (_event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void => {
    if (option) {
      const newOptions = CommonDropdownOnChangeHandler({
        currentOptions: opsDetail,
        option: option,
        keyForAll: 0,
        keyForNone: -1,
        totalOptions: opsDetailsFromApi.length
      });
      setOpsDetail(newOptions);
    }
  };
  const opsDetailOptions: IDropdownOption[] = opsDetailsFromApi.map((item) => {
    return {
      key: item.id,
      text: item.name
    };
  });
  opsDetailOptions.unshift({ key: 0, text: "All" });
  opsDetailOptions.unshift({ key: -1, text: "None Selected", disabled: true });
  return (
    <>
      <Dropdown
        label="OpsDetail"
        disabled={isLoading || isFetching}
        selectedKeys={opsDetail}
        onChange={onChangeOpsDetail}
        options={opsDetailOptions}
        multiSelect
        styles={
          props.styles
            ? props.styles
            : ForceMaxWidthAndHeightOnDropdownStyle({
                width: "290px",
                height: "300px"
              })
        }
      />
    </>
  );
};

SearchOpsDetailDropdown.displayName = "SearchOpsDetailDropdown";
export { SearchOpsDetailDropdown, SearchOpsDetailDropdownProps, SearchOpsDetailDropdownRef, OpsDetail };
