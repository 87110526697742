import {
  JemConfiguration,
  IUserProviderState,
  getValidUrl,
  getRequest,
  ApiError,
  getObjectFromCacheOrFromEndpoint
} from "@jem/components";

export interface IhccDraftArgs {
  configuration: JemConfiguration["IhccApi"];
}

export interface IHCCDraftInitInfoResponse {
  accountInfos: string[];
  transTypeInfo: string[];
  currencyCodeInfo: string[];
  poDefaultValueInfo: PoDefaultValueInfo[];
  supervisorAlias: string;
  currentFiscalYear: number;
  currentFiscalMonth: number;
}

export interface PoDefaultValueInfo {
  id: number;
  name: string;
  value: string;
  createdBy: number;
  createdDate: string;
  lastModifiedBy: number;
  lastModifiedDate: Date;
  rowVer: string;
}

export const EMPTY_INIT_INFO: IHCCDraftInitInfoResponse = {
  accountInfos: [],
  transTypeInfo: [],
  currencyCodeInfo: [],
  poDefaultValueInfo: [],
  supervisorAlias: "",
  currentFiscalYear: 0,
  currentFiscalMonth: 0
};

export async function fetchInitInfo(
  configuration: JemConfiguration,
  getTokenFn: IUserProviderState["accessToken"]
): Promise<IHCCDraftInitInfoResponse> {
  const base = configuration.IhccApi.baseApiUrl;
  const endpoint = getValidUrl(`${base}${configuration.IhccApi.endpoints.createPageInitInfo}`);
  const response = await getRequest<IHCCDraftInitInfoResponse>(endpoint, getTokenFn);
  if (!response) {
    throw new ApiError("Could not fetch IHCC Draft Init Information.");
  }
  return response;
}

export async function getInitInfo(
  configuration: JemConfiguration,
  getTokenFn: IUserProviderState["accessToken"]
): Promise<IHCCDraftInitInfoResponse> {
  const initInfo = await getObjectFromCacheOrFromEndpoint<IHCCDraftInitInfoResponse | null, JemConfiguration>(
    configuration,
    "PODraftInitInfo",
    fetchInitInfo,
    getTokenFn,
    configuration.IhccApi.cacheTimeInHours
  );
  if (!initInfo) {
    throw new ApiError("Could not fetch IHCC Draft Init Information.");
  }
  return initInfo;
}
