import React, { useContext } from "react";
import {
  Checkbox,
  ComboBox,
  IDropdownProps,
  Stack,
  TextField,
  Dropdown,
  useTheme,
  Label,
  TooltipHost,
  Icon,
  SpinButton,
  Text
} from "@fluentui/react";
import { v4 } from "uuid";
import { Field, useFormikContext } from "formik";
import { GLBatchFormikState } from "../GLBatch.types";
import { InputFileButton } from "./GLBatch.InputFileButton";
import { IUserProviderState } from "../../../../Shared/utilities/RequestUtilities";
import { LoggingContext } from "../../../../Shared/contexts/LoggingContext/LoggingContext";
import { RuntimeError } from "../../../../Shared/utilities/ErrorHelpers";
import { LocalAttachment } from "../../../../Shared/components/Attachments/Attachments.types";
import { gridStyle, textFieldStyles } from "../../../shared/GL.styles";
import { ForceMaxWidthAndHeightOnDropdownStyle } from "../../../../Shared/utilities/FluentUIHacks";
import { EUARoles } from "../../../../Shared/contexts/UserContext/UserContext.roles.main";
import {
  checkForDisabledChecklist,
  convertToFiscalDropdownOptions
} from "../../../../Shared/contexts/JEMContext/utilities/DomainData.utils";
import { FCWChecklistModel } from "../../../utilities";
import { DomainDataEnum, DomainDataObjects } from "../../../../Shared/contexts/JEMContext/JEMContext.domainData.types";
import { css } from "@emotion/css";
import { GLBatchPageState } from "../GLBatch.State";
import { AsyncSubject } from "rxjs";
import { JEMChecklistDropdown } from "../../../../Shared/components/JEMChecklistDropdown/JEMChecklistDropdown";
import { JemConfiguration } from "../../../../JemConfiguration";
import { SimpleShimmerForForms } from "../../../../Shared/components/SimpleShimmerForForms/SimpleShimmerForForms";
import { LoadingStatus } from "../../../../Shared";
import { getBatchFields } from "./GLBatch.Tab.PopulateField";

export interface GLBatchDetailsProps {
  configuration: JemConfiguration["GeneralLedgerApi"];
  jemUser: IUserProviderState["jemUser"];
  pageState: GLBatchPageState;
  domainData: Pick<DomainDataObjects, DomainDataEnum.FiscalPeriods | DomainDataEnum.JeReasonCodes>;
  disabled: boolean;
  loadingStatus: LoadingStatus;
  onFiscalPeriodChange: (confirmation: AsyncSubject<boolean>, fiscalPeriod: string) => void;
  onChecklistChange: (confirmation: AsyncSubject<boolean>, checklist: FCWChecklistModel) => void;
}

const MAX_SIZE_20MB = 20971520;

const GLBatchDetails: React.FC<GLBatchDetailsProps> = (props) => {
  const { appInsights: _appInsights } = useContext(LoggingContext);
  const theme = useTheme();

  const formik = useFormikContext<GLBatchFormikState>();
  const fysfps = convertToFiscalDropdownOptions(props.domainData.FiscalPeriods || []);

  const batchState = props.pageState.batchState;

  const batchOptionsChange: IDropdownProps["onChange"] = (_event, item) => {
    if (item) {
      const keyIsAll = item.key === 0;
      if (keyIsAll) {
        if (item.selected) {
          // set the selected option to All only
          formik.setFieldValue("detailsTabOption", [0]);
        } else {
          // if All was selected, then set it to everything else
          const withoutAll = batchState.optionsDropDownOptions.filter((i) => i.key !== 0).map((i) => i.key);
          formik.setFieldValue("detailsTabOption", withoutAll);
        }
      } else {
        // remove All from the selected options
        const interSelectedKeys = formik.values.detailsTabOption.filter((i) => !(i === 0));
        formik.setFieldValue(
          "detailsTabOption",
          item.selected ? [...interSelectedKeys, item.key] : interSelectedKeys.filter((key) => key !== item.key)
        );
      }
    }
  };

  const onChange =
    (fieldName: string): IDropdownProps["onChange"] =>
    (_event, item) => {
      formik.setFieldValue(fieldName, item ? `${item.key}` : null);
    };

  const onChangeFyFp = async (_event, item, _index, _value) => {
    const fieldName = "detailsTabFiscalYearPeriod";
    if (item) {
      const subject = new AsyncSubject<boolean>();
      props.onFiscalPeriodChange(subject, item.text);
      subject.subscribe((confirmed) => {
        if (confirmed) {
          formik.setFieldValue(fieldName, item.key);
        }
      });
    } else if (fysfps.length === 0) {
      // There are no fiscal periods, so set the fiscal period to whatever the user entered
      formik.setFieldValue(fieldName, _value);
    } else {
      formik.setFieldValue(fieldName, "");
    }
  };

  const validateFileName = (files: File[]): boolean => {
    let isValidFileName = true;
    const fileNameSplit = files[0].name.split(".");
    if (fileNameSplit.length) {
      fileNameSplit.splice(-1, 1);
      const fileName = fileNameSplit.join(".");
      if (files[0] !== null && !fileName.match(/^[a-zA-Z0-9 _-]+$/)) {
        alert("File name " + files[0].name + " contains special characters other than Underscore(_) and Hyphen(-)");
        isValidFileName = false;
      }
      return isValidFileName;
    }
    return false;
  };

  const onFileSelected = async (files: File[]) => {
    if (files.length > 1) {
      throw new RuntimeError("Batch File Input should not allow multiple files.");
    }
    if (files[0].size > MAX_SIZE_20MB) {
      alert("Max file size limit is 20 MB, Current file size is" + files[0].size);
      return;
    }
    if (!validateFileName(files)) return;
    try {
      Array.from(files).map(async (file) => {
        if (file.name.endsWith(".xlsx")) {
          const batchData = await getBatchFields(file, props.domainData.JeReasonCodes);
          formik.setFieldValue("detailsTabBatchName", batchData.batchName);
          formik.setFieldValue("detailsTabBatchDesc", batchData.batchDescription);
          formik.setFieldValue("detailsTabBatchPurpose", batchData.batchPurpose);
          formik.setFieldValue("detailsTabReasonCode", batchData.batchReasonCode);
        }
        formik.setFieldValue("batchFile", file);
        formik.setFieldValue("detailsTabBatchFile", file.name);
        formik.setFieldValue("errorTabErrorTable", []);
        return {
          file,
          guid: v4()
        } as LocalAttachment;
      });
    } catch (e) {
      console.error(e);
    }
  };
  const checklistShouldBeDisabled = props.pageState.pageConfiguration.isChecklist
    ? checkForDisabledChecklist(batchState.isAdhocDraft, formik.values.detailsTabSelectedChecklist.checklistRefGuid)
    : batchState.batchDetails.isBPOEntry; // if it is a BPO item, then disable checklist

  return (
    <>
      <div className="ms-Grid" dir="ltr">
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12 ms-lg3">
            <TextField styles={textFieldStyles(theme)} label="Author" borderless placeholder={batchState.author} />
          </div>
          <div className="ms-Grid-col ms-sm12 ms-lg3">
            <TextField
              styles={textFieldStyles(theme)}
              label="Modified Date"
              borderless
              placeholder={batchState.modifiedDate}
            />
          </div>
          {!batchState.isAdhocDraft && checklistShouldBeDisabled ? (
            <div className="ms-Grid-col ms-sm12 ms-lg3">
              <TextField
                styles={textFieldStyles(theme)}
                label="Company Codes"
                disabled
                borderless
                placeholder={batchState.checklistCompanyCodes}
                errorMessage={
                  batchState.checklistCompanyCodes == "" ||
                  !batchState.checklistCompanyCodes.includes(batchState.batchCompanyCode)
                    ? "Warning: Company code is missing"
                    : ""
                }
              />
            </div>
          ) : (
            <> </>
          )}
        </div>
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12 ms-lg3">
            <SimpleShimmerForForms loadingStatus={props.loadingStatus} height={32} label="Period">
              <Field
                label="Checklist Period"
                name={"detailsTabFiscalYearPeriod"}
                onChange={onChangeFyFp}
                as={ComboBox}
                onRenderLabel={() => {
                  return (
                    <TooltipHost content="The checklist Period does not affect the Batch Posting Period. The Posting Period from the Journal Entries in the Batch only comes from the Batch File.">
                      <Stack
                        horizontal
                        {...{
                          tokens: {
                            childrenGap: 8
                          },
                          verticalAlign: "center"
                        }}
                      >
                        <Label>Checklist Period</Label>
                        <Icon
                          iconName="Info"
                          style={{
                            color: theme.palette.themePrimary,
                            cursor: "pointer"
                          }}
                        />
                      </Stack>
                    </TooltipHost>
                  );
                }}
                options={fysfps}
                styles={ForceMaxWidthAndHeightOnDropdownStyle({ width: "100%", height: "200px" })}
                allowFreeform={true}
                autoComplete="on"
                selectedKey={formik.values.detailsTabFiscalYearPeriod ? formik.values.detailsTabFiscalYearPeriod : ""}
                disabled={checklistShouldBeDisabled || props.disabled}
                errorMessage={
                  checklistShouldBeDisabled || props.disabled
                    ? ""
                    : props.domainData.FiscalPeriods.length === 0
                    ? "Error: No Fiscal Years and Periods received from API"
                    : ""
                }
              />
            </SimpleShimmerForForms>
          </div>
          <div className="ms-Grid-col ms-sm12 ms-lg3">
            <JEMChecklistDropdown
              configuration={props.configuration}
              fiscalYearFiscalPeriod={formik.values.detailsTabFiscalYearPeriod}
              selectedChecklist={formik.values.detailsTabSelectedChecklist}
              onChecklistChange={function (checklist: FCWChecklistModel): void {
                const subject = new AsyncSubject<boolean>();
                props.onChecklistChange(subject, checklist);
                subject.subscribe((confirmed) => {
                  if (confirmed) {
                    formik.setFieldValue("detailsTabSelectedChecklist", checklist);
                  }
                });
              }}
              disabled={checklistShouldBeDisabled || props.disabled || props.loadingStatus === LoadingStatus.Pending}
              errorMessage={
                checklistShouldBeDisabled || props.disabled
                  ? ""
                  : typeof formik.errors.detailsTabSelectedChecklist === "string"
                  ? formik.errors.detailsTabSelectedChecklist
                  : ""
              }
              onNewChecklists={function (checklists: FCWChecklistModel[]): void {
                formik.setFieldValue("detailsTabCurrentChecklists", checklists);
              }}
            />
          </div>
        </div>
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12 ms-lg6">
            <Label id="actualTimeTakenLabel" required htmlFor="actualTimeTaken">
              Actual Time Taken (mins):
            </Label>
            <SimpleShimmerForForms loadingStatus={props.loadingStatus} height={32}>
              <SpinButton
                id="detailsTabActualTimeTaken"
                ariaDescribedBy={`actualTimeTakenLabel`}
                aria-labelledby={`actualTimeTakenLabel`}
                inputProps={{ "aria-labelledby": "actualTimeTakenLabel" }}
                min={0}
                max={2147483647}
                step={1}
                value={
                  formik.values.detailsTabActualTimeTaken !== -1 && formik.values.detailsTabActualTimeTaken
                    ? formik.values.detailsTabActualTimeTaken.toString()
                    : "0"
                }
                onChange={(e, newValue) => {
                  if (!newValue) {
                    formik.setFieldValue("detailsTabActualTimeTaken", 0);
                    return;
                  }
                  formik.setFieldValue("detailsTabActualTimeTaken", parseInt(newValue));
                }}
                incrementButtonAriaLabel={"Increase Actual Time Taken by 1 minute"}
                decrementButtonAriaLabel={"Decrease Actual Time Taken by 1 minute"}
                styles={
                  !formik.values.detailsTabActualTimeTaken
                    ? { root: { borderColor: theme.semanticColors.errorText } }
                    : undefined
                }
                disabled={
                  formik.values.detailsTabSelectedChecklist.checklistName === "NOT APPLICABLE" || props.disabled
                }
              />
              {!formik.values.detailsTabActualTimeTaken &&
                formik.values.detailsTabSelectedChecklist.checklistName != "NOT APPLICABLE" && (
                  <Text style={{ color: theme.semanticColors.errorText }} variant="small" role="alert">
                    Actual Time Taken (mins) is required.
                  </Text>
                )}
            </SimpleShimmerForForms>
          </div>
        </div>
        <div className="ms-Grid-row">
          <div className={`ms-Grid-col ms-sm12 ms-lg6 ${css(`height: 64px;`)}`}>
            <SimpleShimmerForForms loadingStatus={props.loadingStatus} height={32} label="Batch File">
              <Field
                as={TextField}
                label="Batch File"
                name="detailsTabBatchFile"
                type="input"
                disabled={props.disabled}
                readOnly={true}
                errorMessage={props.disabled ? "" : formik.errors.detailsTabBatchFile}
              />
            </SimpleShimmerForForms>
          </div>
          <div
            className={`ms-Grid-col ms-sm12 ms-lg3 ${css(`
          height: 82px;
          margin-top: 4px;
          display: flex;
          align-items: center;`)}`}
          >
            <InputFileButton
              text="Browse"
              onChange={onFileSelected}
              accept=".txt, .xml, .xls, .xlsx, .xlsb, .dat"
              allowMultipleFiles={false}
              disabled={props.disabled}
            />
          </div>
        </div>
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12 ms-lg6">
            <SimpleShimmerForForms loadingStatus={props.loadingStatus} height={32} label="Reason Code">
              <Field
                label="Reason Code"
                name={"detailsTabReasonCode"}
                onChange={onChange("detailsTabReasonCode")}
                as={ComboBox}
                allowFreeform={true}
                autoComplete="on"
                selectedKey={formik.values.detailsTabReasonCode}
                options={batchState.reasonCodesDropDownOptions}
                styles={ForceMaxWidthAndHeightOnDropdownStyle({
                  width: "100%",
                  height: "200px"
                })}
                errorMessage={
                  props.disabled
                    ? ""
                    : batchState.reasonCodesDropDownOptions.length === 0
                    ? "Error: No Reason Codes found"
                    : formik.errors.detailsTabReasonCode
                }
                disabled={props.disabled}
              />
            </SimpleShimmerForForms>
          </div>
        </div>
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12 ms-lg2">
            <Stack>
              <TooltipHost content="Bulk Posting requires Bulk Poster EUA Role">
                <Label htmlFor="batchClassificationLabel">Classification</Label>
                <SimpleShimmerForForms loadingStatus={props.loadingStatus} height={32}>
                  <Stack horizontal {...{ tokens: gridStyle }}>
                    <Stack.Item align="center">
                      <Field
                        as={Checkbox}
                        label="Bulk"
                        name="detailsTabIsBulkType"
                        type="checkbox"
                        disabled={
                          !props.jemUser.roles.includes(EUARoles.BulkPoster) ||
                          props.disabled ||
                          checklistShouldBeDisabled
                        }
                        onChange={(_event, checked) => {
                          formik.setFieldValue("detailsTabIsBulkType", checked);
                        }}
                      />
                    </Stack.Item>
                  </Stack>
                </SimpleShimmerForForms>
              </TooltipHost>
            </Stack>
          </div>
          <div className="ms-Grid-col ms-sm12 ms-lg4">
            <SimpleShimmerForForms loadingStatus={props.loadingStatus} height={32} label="Options">
              <Field
                label="Options"
                name="detailsTabOption"
                as={Dropdown}
                multiSelect
                options={batchState.optionsDropDownOptions}
                styles={ForceMaxWidthAndHeightOnDropdownStyle({
                  width: "100%",
                  height: "200px"
                })}
                onChange={batchOptionsChange}
                selectedKeys={formik.values.detailsTabOption}
                errorMessage={
                  props.disabled
                    ? ""
                    : batchState.optionsDropDownOptions.length === 0
                    ? "Error: No Batch Options found"
                    : formik.errors.detailsTabOption
                }
                disabled={props.disabled}
              />
              <Label
                className={css`
                  font-size: 10px;
                  font-weight: 400;
                `}
              >
                Before validation, please uncheck the fields that are not part of the Batch File.
              </Label>
            </SimpleShimmerForForms>
          </div>
        </div>
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12 ms-lg3">
            <SimpleShimmerForForms loadingStatus={props.loadingStatus} height={64} label="Purpose">
              <Field
                as={TextField}
                label="Purpose"
                name="detailsTabBatchPurpose"
                type="input"
                defaultValue={formik.values.detailsTabBatchPurpose}
                onChange={(_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                  if (!newValue || newValue.length <= 250) {
                    formik.setFieldValue("detailsTabBatchPurpose", newValue);
                  }
                }}
                description="Max 250 characters"
                multiline
                errorMessage={props.disabled ? "" : formik.errors.detailsTabBatchPurpose}
                disabled={props.disabled}
              />
            </SimpleShimmerForForms>
          </div>
          <div className="ms-Grid-col ms-sm12 ms-lg3">
            <SimpleShimmerForForms loadingStatus={props.loadingStatus} height={64} label="Batch Description">
              <Field
                as={TextField}
                label="Batch Description"
                name="detailsTabBatchDesc"
                defaultValue={formik.values.detailsTabBatchDesc}
                onChange={(_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                  if (!newValue || newValue.length <= 25) {
                    formik.setFieldValue("detailsTabBatchDesc", newValue);
                  }
                }}
                description="Max 25 characters"
                type="input"
                multiline
                errorMessage={props.disabled ? "" : formik.errors.detailsTabBatchDesc}
                disabled={props.disabled}
              />
            </SimpleShimmerForForms>
          </div>
        </div>
      </div>
    </>
  );
};

GLBatchDetails.displayName = "GLBatchDetails";
export { GLBatchDetails };
