import React, { PropsWithChildren, useContext } from "react";
import { ActionResult, ActionTypes } from "../ActionManager/ActionsManager.types";
import { ActionsContext } from "./Actions.ActionsContext";
import { ActionTitle } from "./Actions.ActionTitle";
import { TextFieldSubmission } from "./Actions.BottomButtons";
import { ActionOnSubmitHandlerMap, BaseActionProps, CommonPropsOnRows } from "./Actions.types";

interface RecallProps<ListType, ResultType> extends BaseActionProps<ListType, ResultType> {
  onSubmitHandler:
    | ActionOnSubmitHandlerMap<ListType, ResultType>[ActionTypes.recallPreReview]
    | ActionOnSubmitHandlerMap<ListType, ResultType>[ActionTypes.recall];
}

const Recall = <ListType extends CommonPropsOnRows, ResultType extends ActionResult>(
  props: PropsWithChildren<RecallProps<ListType, ResultType>>
) => {
  const { styles } = useContext(ActionsContext);
  return (
    <>
      <ActionTitle onCloseButton={() => props.onCancel()} name={"Recall"}></ActionTitle>
      <div className={styles.contentStyles.body}>
        <TextFieldSubmission
          onSubmit={async function (type: "submit" | "cancel", comments: string): Promise<void> {
            if (type === "cancel") {
              props.onCancel();
            } else {
              const result = await props.onSubmitHandler(props.items, comments);
              props.onSubmit(result);
            }
          }}
          autoComplete={"off"}
          submitButtonText={"Recall"}
          textFieldLabel={"Comments"}
        ></TextFieldSubmission>
      </div>
    </>
  );
};

Recall.displayName = "Recall";

export { Recall };
