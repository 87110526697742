import { Dropdown, IDropdownOption } from "@fluentui/react";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { ForceMaxWidthAndHeightOnDropdownStyle, MaxWidthHeightOptions } from "../../utilities/FluentUIHacks";
import { CommonDropdownOnChangeHandler } from "../../utilities/CommonDropdownOnChangeHandler";

export interface IFiscalPeriodsRef {
  getFiscalPeriods: () => number[];
  setInitialFiscalPeriods: (initialFiscalPeriods: number[]) => void;
  reset: () => void;
}

interface IFiscalPeriodsProps {
  loading: boolean;
  onChange?: (fiscalPeriods: number[]) => void;
  styles?: MaxWidthHeightOptions;
}

const FiscalPeriodDropdownOptions = [
  { key: -1, text: "None Selected", disabled: true },
  { key: 0, text: "All" },
  { key: 1, text: "1" },
  { key: 2, text: "2" },
  { key: 3, text: "3" },
  { key: 4, text: "4" },
  { key: 5, text: "5" },
  { key: 6, text: "6" },
  { key: 7, text: "7" },
  { key: 8, text: "8" },
  { key: 9, text: "9" },
  { key: 10, text: "10" },
  { key: 11, text: "11" },
  { key: 12, text: "12" }
];

const FiscalPeriodsDropdown = forwardRef((props: IFiscalPeriodsProps, ref) => {
  const [fiscalPeriods, setFiscalPeriods] = useState<number[]>([0]);

  const onChangeFiscalPeriods = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void => {
    if (!option) {
      return;
    }

    const newOptions = CommonDropdownOnChangeHandler({
      currentOptions: fiscalPeriods,
      option: option,
      keyForAll: 0,
      keyForNone: -1,
      totalOptions: FiscalPeriodDropdownOptions.length - 2
    });
    setFiscalPeriods(newOptions);
    if (props.onChange) {
      props.onChange(newOptions);
    }
  };

  useImperativeHandle(
    ref,
    (): IFiscalPeriodsRef => ({
      getFiscalPeriods() {
        return fiscalPeriods;
      },
      setInitialFiscalPeriods(initialFiscalPeriods: number[]) {
        setFiscalPeriods(initialFiscalPeriods);
      },
      reset() {
        setFiscalPeriods([0]);
      }
    })
  );

  return (
    <React.Fragment>
      <Dropdown
        label="Fiscal Period:"
        selectedKeys={fiscalPeriods}
        onChange={onChangeFiscalPeriods}
        options={FiscalPeriodDropdownOptions}
        disabled={props.loading}
        multiSelect
        styles={ForceMaxWidthAndHeightOnDropdownStyle(
          props.styles || {
            width: "100%",
            minWidth: "100px",
            maxWidth: "150px",
            height: "300px"
          }
        )}
      />
    </React.Fragment>
  );
});

FiscalPeriodsDropdown.displayName = "FiscalPeriodsDropdown";

export { FiscalPeriodsDropdown, FiscalPeriodDropdownOptions };
