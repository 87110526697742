import { checkBoxActions } from "./Actions.requests";
import {
  AutomatedDashboardConfiguration,
  AutomatedJeDashboardElement
} from "../AutomatedJeDashboard/AutomatedJeDashboard.requests";

import { Notification } from "../../../Shared/components/NotificationsManager/NotificationsManager";
import { IUserProviderState } from "../../../Shared/utilities/RequestUtilities";

export async function onProceedCheckbox(
  name: "Retry",
  items: AutomatedJeDashboardElement[],
  configuration: AutomatedDashboardConfiguration,
  getTokenFn?: IUserProviderState["accessToken"]
): Promise<Notification[]> {
  const RequestMap = checkBoxActions(configuration, getTokenFn);
  const notifications = await RequestMap[name](items);
  return notifications;
}
