import * as Yup from "yup";
import { OptionalObjectSchema, TypeOfShape } from "yup/lib/object";
import { RequiredStringSchema } from "yup/lib/string";
import { AnyObject } from "yup/lib/types";
import { JemConfiguration } from "../../JemConfiguration";
import { DomainDataEnum, DomainDataObjects } from "../../Shared/contexts/JEMContext/JEMContext.domainData.types";
import { getRequest, getValidUrl, IUserProviderState } from "../../Shared/utilities/RequestUtilities";
import { GLPageState } from "../components/GLCreate/GLCreate.State";
import { GLCreateActionsEnum } from "../components/GLCreate/useWeightage";

// eslint-disable-next-line
const toCamelCase = require("lodash/camelCase");

export interface UserValidationPayloadItem {
  emailName: string;
  firstName: string;
  fullName: string;
  lastName: string;
  personnelNum: number;
  supervisorEmailName: string;
  supervisorPersonnelNum: number;
}

// any alias might come in as DOMAIN\alias and in different case
export const normalizeAliases = (alias: string | undefined): string => {
  if (!alias) return "";
  if (!alias.includes("\\")) return alias.toUpperCase();

  return alias.toUpperCase().split("\\")[1];
};
export async function checkUsersPermissions(
  opts: {
    configuration: JemConfiguration["GeneralLedgerApi"];
    accessToken: IUserProviderState["accessToken"];
  },
  poster?: string | undefined,
  reviewer?: string | undefined,
  backupPosters?: string[] | undefined,
  additionalReviewers?: string[] | undefined,
  preReviewers?: string[] | undefined
): Promise<boolean | string> {
  const allUsersNotNormalized = [
    poster,
    reviewer,
    ...(backupPosters && backupPosters.length !== 0 ? backupPosters.filter((user) => user) : []),
    ...(additionalReviewers && additionalReviewers.length !== 0 ? additionalReviewers.filter((user) => user) : []),
    ...(preReviewers && preReviewers.length !== 0 ? preReviewers.filter((user) => user) : [])
  ];
  const allUsers = allUsersNotNormalized.map(normalizeAliases).filter((user) => user);
  console.log("allUsers", allUsers);
  const allUsersString = allUsers.join(";");
  // call persona endpoint to check all users
  const endpoint = getValidUrl(
    `${opts.configuration.baseApiUrl}${opts.configuration.endpoints.checkUsersPersona}`
  ).replace("{users}", allUsersString);
  let users: UserValidationPayloadItem[] | null = null;
  try {
    users = await getRequest<UserValidationPayloadItem[]>(endpoint, opts.accessToken);
  } catch (error) {
    return "Could not check users permissions due to no connection.";
  }

  if (users === null) {
    return "Could not check users permissions.";
  }
  const safeUsers: NonNullable<typeof users> = users;
  // Iterate allUsers, if alias is not in users[].emailName then it is not a valid user
  // aggregate all invalid users, return in a single error message
  if (allUsers.length !== users.length) {
    const invalidUsers = allUsers.filter(
      (user) => !safeUsers.some((userValidation) => normalizeAliases(userValidation.emailName) === user)
    );
    const message =
      invalidUsers.length > 1
        ? `Invalid users: ${invalidUsers.join(", ")}`
        : `User ${invalidUsers.join(", ")} is invalid`;
    return message;
  }
  return true;
}

/**
 * @description - This object is meant to manage the validation of the form.
 * Please note that this object is not meant to have any state.
 * @export - The ValidationManager object.
 */
export class ValidationManager {
  public static specialCharactersCheck(fieldName: string, schema: Yup.StringSchema<string | undefined, AnyObject>) {
    const containsInvalidCharacters = (str: string) => /[<>/]/.test(str);
    return schema.test(
      `${fieldName} must not contain special characters like < , > and /.`,
      `${fieldName} must not contain special characters like < , > and /.`,
      (value: string | undefined) => {
        return !containsInvalidCharacters(value || "");
      }
    );
  }

  public static companyCodeValidation(domainData: Pick<DomainDataObjects, DomainDataEnum.JeCompanyCodes>) {
    const cCodeValidation = Yup.string()
      .matches(/^\d{4}$/, "Company Code must be in valid Company Codes.")
      .required("Company Code must not be empty.");
    if (domainData?.JeCompanyCodes) {
      return cCodeValidation.test(
        "companyCodeInDomainData",
        "Company Code must be in valid Company Codes.",
        (value: string | undefined) => {
          return domainData.JeCompanyCodes.some(
            (cCode: DomainDataObjects[DomainDataEnum.JeCompanyCodes][number]) => cCode.glCompanyCode === Number(value)
          );
        }
      );
    }
    return cCodeValidation;
  }

  public static refNumberValidation() {
    return Yup.string()
      .matches(
        /^(?!000000)[0-9]{6}$/,
        "Ref Number must be Numeric and 6 digits long and must not have all zeroes (000000)."
      )
      .required("The Ref Number field is required.");
  }

  public static descriptionValidation() {
    return ValidationManager.specialCharactersCheck("Description", Yup.string())
      .max(25, "Description must be 25 characters or less.")
      .required("The Description field is required.");
  }

  public static jePurposeValidation() {
    return ValidationManager.specialCharactersCheck("JE Purpose", Yup.string())
      .max(250, "Purpose must be 250 characters or less.")
      .required("JE Purpose must not be empty.");
  }

  public static jeTypeValidation(domainData: Pick<DomainDataObjects, DomainDataEnum.JeTypes>) {
    const jeTypeValidation = Yup.string().required("The JE Type field is required.");
    if (domainData?.JeTypes) {
      return jeTypeValidation.test(
        "jeTypeInDomainData",
        "JE Type must be in valid JE Types.",
        (value: string | undefined) => {
          return domainData.JeTypes.some((cCode: DomainDataObjects[DomainDataEnum.JeTypes][0]) => cCode.code === value);
        }
      );
    }
    return jeTypeValidation;
  }

  public static reasonCodeValidation(domainData: Pick<DomainDataObjects, DomainDataEnum.JeReasonCodes>) {
    const rCodeValidation = Yup.string().required("Reason Code must not be empty.");
    if (domainData.JeReasonCodes) {
      return rCodeValidation.test(
        "reasonCodeInDomainData",
        "Reason Code must be in valid Reason Codes.",
        (value: string | undefined) => {
          return domainData.JeReasonCodes.some(
            (cCode: DomainDataObjects[DomainDataEnum.JeReasonCodes][number]) => value && cCode.code.indexOf(value) > -1
          );
        }
      );
    }
    return rCodeValidation;
  }

  public static currencyValidation(domainData: Pick<DomainDataObjects, DomainDataEnum.CurrencyCodes>) {
    const currCodeValidation = Yup.string().required("Currency Code must not be empty.");
    if (domainData?.CurrencyCodes) {
      return currCodeValidation.test(
        "currencyCodeInDomainData",
        "Currency Code must be in valid Currency Codes.",
        (value: string | undefined) => {
          return domainData.CurrencyCodes.some(
            (cCode: DomainDataObjects[DomainDataEnum.CurrencyCodes][0]) => cCode.currencyCode === value
          );
        }
      );
    }
    return currCodeValidation;
  }

  public static JEDetailsValidation(
    domainData: Pick<
      DomainDataObjects,
      | DomainDataEnum.JeCompanyCodes
      | DomainDataEnum.JeTypes
      | DomainDataEnum.JeReasonCodes
      | DomainDataEnum.CurrencyCodes
      | DomainDataEnum.FiscalPeriods
    >,
    initialState: GLPageState["createState"]
  ) {
    return Yup.object({
      detailsTabRefNumber: ValidationManager.refNumberValidation(),
      detailsTabCompanyCode: ValidationManager.companyCodeValidation(domainData),
      detailsTabJeType: ValidationManager.jeTypeValidation(domainData),
      detailsTabReasonCode: ValidationManager.reasonCodeValidation(domainData),
      detailsTabCurrency: ValidationManager.currencyValidation(domainData),
      detailsTabFiscalYearPeriod: Yup.string().required("Fiscal Period must not be empty."),
      detailsTabPostingDate: Yup.date()
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore -- this is a typescript error in the yup library
        // .when(["detailsTabFiscalYearPeriod"], (fiscalYearPeriod, schema) => {
        //   const { fiscalMonth, fiscalYear } =
        //     FiscalPeriodStringTransformations.FiscalPeriodStringToFiscalYearAndFiscalMonth(fiscalYearPeriod);
        //   const { calendarMonth, calendarYear } = getCalendayMonthAndYearFromFiscalMonthAndFiscalYear(
        //     domainData,
        //     fiscalMonth,
        //     fiscalYear
        //   );
        //   const fiscalPeriodStartDate = new Date(calendarYear, calendarMonth - 1, 1);
        //   const fiscalPeriodEndDate = new Date(calendarYear, calendarMonth, 0);

        //   return schema
        //     .min(fiscalPeriodStartDate, "Posting Date should be in the current Posting Period.")
        //     .max(fiscalPeriodEndDate, "Posting Date should be in the current Posting Period.");
        // })
        .required("The Posting Date field is required."),
      // translation date and reversal date are mutually exclusive
      // and both depend on the JE Type
      detailsTabTranslationDate: Yup.string().when(
        ["detailsTabJeType", "detailsTabPostingDate"],
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore -- this is a typescript error in the yup library
        (jeType, postingDate, schema) => {
          if (
            jeType !== "SV" &&
            (initialState.jeDetails.translationDate || initialState.jeDetails.translationDate !== "")
          ) {
            const baseSchema = Yup.date().typeError("Please select a valid Translation Date.");
            // .required("The Translation Date field is required.");

            if (!postingDate) {
              return baseSchema;
            }
            return baseSchema.max(postingDate, "Translation Date should be less than or equal to Posting Date.");
          }
          return schema;
        }
      ),
      detailsTabReversalDate: Yup.string().when(
        ["detailsTabJeType", "detailsTabPostingDate"],
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore -- this is a typescript error in the yup library
        (jeType, postingDate, schema) => {
          if (jeType === "SV") {
            const baseSchema = Yup.date()
              .typeError("Please select a valid Reversal Date.")
              .required("The Reversal Date field is required.");
            if (!postingDate) return baseSchema;
            return baseSchema.min(postingDate, "Reversal Date should be greater than or equal to Posting Date.");
          }
          return schema;
        }
      ),
      detailsTabDescription: ValidationManager.descriptionValidation(),
      detailsTabJePurpose: ValidationManager.jePurposeValidation(),
      detailsTabActualTimeTaken:
        initialState.jeDetails.checklistName != "NOT APPLICABLE"
          ? Yup.number().moreThan(0, "Actual Time Taken should be greater than 0")
          : Yup.number(),
      detailsTabIsBpo: Yup.boolean(),
      detailsTabIsLongTerm: Yup.boolean(),
      // total must be zero
      detailsTabTotal: Yup.string().test(
        "totalMustBeZero",
        "Debit column amounts must match credit amounts in the Amount column of line items. Net must be zero (0) in the Audit column;",
        (value: string | undefined) => {
          if (!value) return false;
          return Number(value) === 0;
        }
      ),
      detailsTabTotalLC: Yup.string().test(
        "totalMustBeZero",
        "Debit column amounts must match credit amounts in the Amount column of line items. Net must be zero (0) in the Audit column;",
        (value: string | undefined) => {
          if (!value) {
            return true;
          }
          return Number(value) === 0;
        }
      ),
      detailsTabTotalLC2: Yup.string().test(
        "totalMustBeZero",
        "Debit column amounts must match credit amounts in the Amount column of line items. Net must be zero (0) in the Audit column;",
        (value: string | undefined) => {
          if (!value) {
            return true;
          }
          return Number(value) === 0;
        }
      ),
      detailsTabTotalLC3: Yup.string().test(
        "totalMustBeZero",
        "Debit column amounts must match credit amounts in the Amount column of line items. Net must be zero (0) in the Audit column;",
        (value: string | undefined) => {
          if (!value) {
            return true;
          }
          return Number(value) === 0;
        }
      )
    });
  }

  public static CommonPosterOrReviewerCheck(
    maxSize: number,
    fieldName: string,
    stringSchema: Yup.StringSchema<string | undefined, AnyObject, string | undefined>,
    isRequired = true
  ) {
    if (isRequired) {
      return stringSchema
        .test(
          `${toCamelCase(fieldName)}LessThan${maxSize}`,
          `${fieldName} must be less than ${maxSize}.`,
          (value: string | undefined) => {
            return value ? value.split(";").length <= maxSize : true;
          }
        )
        .matches(
          /^[a-zA-Z-\\\\;0-9]*$/,
          `The ${fieldName} field does not accept spaces or special characters except for backslash (\\) and semicolon (;).`
        );
    } else {
      return stringSchema.matches(
        /^[a-zA-Z-\\\\;0-9]*$/,
        `The ${fieldName} field does not accept spaces or special characters except for backslash (\\) and semicolon (;).`
      );
    }
  }

  public static CommonPosterOrReviewerCheckForArrays(
    maxSize: number,
    fieldName: string,
    arraySchema: Yup.ArraySchema<
      Yup.StringSchema<string | undefined, AnyObject, string | undefined>,
      AnyObject,
      (string | undefined)[] | undefined,
      (string | undefined)[] | undefined
    >,
    isRequired = true
  ) {
    const schema = isRequired
      ? arraySchema.test(
          `${toCamelCase(fieldName)}LessThan${maxSize}`,
          `${fieldName} must contain less than or equal to ${maxSize} items.`,
          (value: (string | undefined)[] | undefined) => {
            return !value || value.length <= maxSize;
          }
        )
      : arraySchema;

    return schema.test(
      "arrayRegex",
      `The ${fieldName} field does not accept spaces or special characters except for backslash (\\) and semicolon (;).`,
      (value: (string | undefined)[] | undefined) => {
        if (!value) return true;
        return value.every((item) => /^[a-zA-Z-\\;0-9]*$/.test(item || ""));
      }
    );
  }

  /**
   * Unfortunately all alias fields are cross dependent. So we need to check for each alias field
   * @param schema
   * @returns Yup Schema
   */
  public static PosterReviewerCrossValidations(
    schema: OptionalObjectSchema<
      {
        postersTabPoster: Yup.StringSchema<string | undefined, AnyObject, string | undefined>;
        postersTabBackupPosters: Yup.ArraySchema<
          Yup.StringSchema<string | undefined, AnyObject, string | undefined>,
          AnyObject,
          (string | undefined)[] | undefined,
          (string | undefined)[] | undefined
        >;
        postersTabComments: Yup.StringSchema<string | undefined, AnyObject, string | undefined>;
        reviewersTabReviewer: Yup.StringSchema<string | undefined, AnyObject, string | undefined>;
        reviewersTabAdditionalReviewers: Yup.ArraySchema<
          Yup.StringSchema<string | undefined, AnyObject, string | undefined>,
          AnyObject,
          (string | undefined)[] | undefined,
          (string | undefined)[] | undefined
        >;
        reviewersTabPreReviewers: Yup.ArraySchema<
          Yup.StringSchema<string | undefined, AnyObject, string | undefined>,
          AnyObject,
          (string | undefined)[] | undefined,
          (string | undefined)[] | undefined
        >;
      },
      AnyObject,
      TypeOfShape<{
        postersTabPoster: Yup.StringSchema<string | undefined, AnyObject, string | undefined>;
        postersTabBackupPosters: Yup.ArraySchema<
          Yup.StringSchema<string | undefined, AnyObject, string | undefined>,
          AnyObject,
          (string | undefined)[] | undefined,
          (string | undefined)[] | undefined
        >;
        postersTabComments: Yup.StringSchema<string | undefined, AnyObject, string | undefined>;
        reviewersTabReviewer: Yup.StringSchema<string | undefined, AnyObject, string | undefined>;
        reviewersTabAdditionalReviewers: Yup.ArraySchema<
          Yup.StringSchema<string | undefined, AnyObject, string | undefined>,
          AnyObject,
          (string | undefined)[] | undefined,
          (string | undefined)[] | undefined
        >;
        reviewersTabPreReviewers: Yup.ArraySchema<
          Yup.StringSchema<string | undefined, AnyObject, string | undefined>,
          AnyObject,
          (string | undefined)[] | undefined,
          (string | undefined)[] | undefined
        >;
      }>
    >,
    loggedInUser?: string
  ) {
    return schema.test({
      name: "crossAliasesCheck",
      test: function (value) {
        const { postersTabPoster, reviewersTabReviewer, reviewersTabAdditionalReviewers, reviewersTabPreReviewers } =
          value;
        const normalizedLoggedInUser = normalizeAliases(loggedInUser);

        const posterAlias = normalizeAliases(postersTabPoster);
        const reviewerAlias = normalizeAliases(reviewersTabReviewer);
        const additionalReviewers = (reviewersTabAdditionalReviewers || []).map((alias) => normalizeAliases(alias));
        const preReviewers = (reviewersTabPreReviewers || []).map((alias) => normalizeAliases(alias));
        const errors: Yup.ValidationError[] = [];

        // check if poster is the reviewer
        if (posterAlias && posterAlias === reviewerAlias) {
          const posterErrors = new Yup.ValidationError(
            `Poster ${postersTabPoster} cannot also be the Reviewer.`,
            posterAlias,
            "postersTabPoster"
          );
          const reviewerError = new Yup.ValidationError(
            `Poster ${postersTabPoster} cannot also be the Reviewer.`,
            posterAlias,
            "reviewersTabReviewer"
          );

          errors.push(posterErrors, reviewerError);
        }

        //check if poster is in additional reviewers
        if (
          posterAlias &&
          additionalReviewers &&
          additionalReviewers.find((alias) => alias.indexOf(posterAlias) !== -1)
        ) {
          const additionalReviewerErrors = new Yup.ValidationError(
            `Poster ${postersTabPoster} cannot also be in the Additional Reviewers.`,
            posterAlias,
            "reviewersTabAdditionalReviewers"
          );
          errors.push(additionalReviewerErrors);
        }

        if (normalizedLoggedInUser && reviewerAlias && normalizedLoggedInUser.indexOf(reviewerAlias) !== -1) {
          const reviewerErrors = new Yup.ValidationError(
            `Author ${normalizedLoggedInUser} cannot also be reviewer.`,
            posterAlias,
            "reviewersTabReviewer"
          );
          errors.push(reviewerErrors);
        }
        ////check if poster is in pre-reviewers
        if (posterAlias && preReviewers && preReviewers.find((alias) => alias.indexOf(posterAlias) !== -1)) {
          const preReviewersErrors = new Yup.ValidationError(
            `Poster ${postersTabPoster} cannot also be in the Pre-Reviewers.`,
            posterAlias,
            "reviewersTabPreReviewers"
          );
          errors.push(preReviewersErrors);
        }

        return errors.length === 0 ? true : new Yup.ValidationError(errors);
      }
    });
  }

  /**
   * Gets every alias in poster, reviewer, backup posters, and additional reviewers
   * And goes to JEM and checks they are valid JEM users
   * @param opts { configuration: JemConfiguration["GeneralLedgerApi"]; accessToken: IUserProviderState["accessToken"]; }
   * @returns Yup Schema
   */
  public static PosterAndReviewerPermissionValidation(opts: {
    configuration: JemConfiguration["GeneralLedgerApi"];
    accessToken: IUserProviderState["accessToken"];
  }) {
    return Yup.object()
      .shape<{
        postersTabPoster: Yup.StringSchema<string | undefined, AnyObject>;
        postersTabBackupPosters: Yup.ArraySchema<
          RequiredStringSchema<string | undefined, AnyObject>,
          AnyObject,
          (string | undefined)[] | undefined,
          string[] | undefined
        >;
        reviewersTabReviewer: Yup.StringSchema<string | undefined, AnyObject>;
        // additional reviewers can't be more than then ; separated reviewers
        reviewersTabAdditionalReviewers: Yup.ArraySchema<
          RequiredStringSchema<string | undefined, AnyObject>,
          AnyObject,
          (string | undefined)[] | undefined,
          string[] | undefined
        >;
        reviewersTabPreReviewers: Yup.ArraySchema<
          RequiredStringSchema<string | undefined, AnyObject>,
          AnyObject,
          (string | undefined)[] | undefined,
          string[] | undefined
        >;
      }>({
        postersTabPoster: Yup.string(),
        postersTabBackupPosters: Yup.array().of(Yup.string().required()),
        reviewersTabReviewer: Yup.string(),
        reviewersTabAdditionalReviewers: Yup.array().of(Yup.string().required()),
        reviewersTabPreReviewers: Yup.array().of(Yup.string().required())
      })
      .test({
        name: "userPermissionValidation",
        test: async function (value) {
          const {
            postersTabPoster: poster,
            reviewersTabReviewer: reviewer,
            postersTabBackupPosters: backupPosters,
            reviewersTabAdditionalReviewers: additionalReviewers,
            reviewersTabPreReviewers: preReviewers
          } = value;
          // concatenate all users in a single string
          const possibleErrorMessage = await checkUsersPermissions(
            opts,
            poster,
            reviewer,
            backupPosters ? (backupPosters.filter((item) => item) as string[]) : [],
            additionalReviewers ? (additionalReviewers.filter((item) => item) as string[]) : [],

            preReviewers ? (preReviewers.filter((item) => item) as string[]) : []
          );

          if (typeof possibleErrorMessage === "string") {
            return this.createError({
              path: "postersTabPoster",
              message: possibleErrorMessage
            });
          }
          return true;
        }
      });
  }
  public static PosterPermissionValidation(opts: {
    configuration: JemConfiguration["GeneralLedgerApi"];
    accessToken: IUserProviderState["accessToken"];
  }) {
    return Yup.object()
      .shape<{
        postersTabBackupPosters: Yup.ArraySchema<
          RequiredStringSchema<string | undefined, AnyObject>,
          AnyObject,
          (string | undefined)[] | undefined,
          string[] | undefined
        >;
      }>({
        postersTabBackupPosters: Yup.array().of(Yup.string().required())
      })
      .test({
        name: "userPermissionValidation",
        test: async function (value) {
          const { postersTabBackupPosters: backupPosters } = value;
          // concatenate all users in a single string
          const possibleErrorMessage = await checkUsersPermissions(
            opts,
            "",
            "",
            backupPosters ? (backupPosters.filter((item) => item) as string[]) : []
          );
          if (typeof possibleErrorMessage === "string") {
            return this.createError({
              path: "postersTabAdditionalPoster",
              message: possibleErrorMessage
            });
          }
          return true;
        }
      });
  }
  public static ReviewerPermissionValidation(opts: {
    configuration: JemConfiguration["GeneralLedgerApi"];
    accessToken: IUserProviderState["accessToken"];
  }) {
    return Yup.object()
      .shape<{
        reviewersTabAdditionalReviewers: Yup.ArraySchema<
          RequiredStringSchema<string | undefined, AnyObject>,
          AnyObject,
          (string | undefined)[] | undefined,
          string[] | undefined
        >;
      }>({
        reviewersTabAdditionalReviewers: Yup.array().of(Yup.string().required())
      })
      .test({
        name: "userPermissionValidation",
        test: async function (value) {
          const { reviewersTabAdditionalReviewers: additionalReviewers } = value;
          // concatenate all users in a single string
          const possibleErrorMessage = await checkUsersPermissions(
            opts,
            "",
            "",
            additionalReviewers ? (additionalReviewers.filter((item) => item) as string[]) : []
          );
          if (typeof possibleErrorMessage === "string") {
            return this.createError({
              path: "reviewersTabAdditionalReviewers",
              message: possibleErrorMessage
            });
          }
          return true;
        }
      });
  }

  public static CommentsValidation(commentsRequired: boolean, userAction?: GLCreateActionsEnum) {
    let commentsValidation = ValidationManager.specialCharactersCheck("Comments", Yup.string());
    if (
      commentsRequired ||
      userAction === GLCreateActionsEnum.NeedsClarification ||
      userAction === GLCreateActionsEnum.SendToPoster
    ) {
      commentsValidation = commentsValidation.required("Comments must not be empty.");
    }
    return commentsValidation;
  }

  /**
   * Validates poster, reviewer, backupPosters, additionalReviewers and comments
   * Checks there's no duplicate users in fields
   * @param userAction Save, Send to Poster, Validate, Post
   * @returns Yup.ObjectSchema
   */
  public static PosterAndReviewerOfflineValidation<
    T extends {
      author?: string;
      postersTabPoster?: string;
      postersTabBackupPosters?: string[];
      reviewersTabAdditionalReviewers?: string[];
      reviewersTabPreReviewer?: string[];
    }
  >(
    initialState: T,
    opts: {
      posterRequired: boolean;
      reviewerRequired: boolean;
      reviewerIsAuthorCheck: boolean;
      additionalReviewersIsAuthorCheck: boolean;
      posterCommentsRequired: boolean;
      backupPostersRequired: boolean;
      backupReviewersRequired: boolean;
      preReviewerRequired?: boolean;
      preReviewerIsAuthorCheck?: boolean;
      posterIsAuthorCheck?: boolean;
    } = {
      posterRequired: true,
      reviewerRequired: true,
      reviewerIsAuthorCheck: true,
      additionalReviewersIsAuthorCheck: true,
      posterCommentsRequired: true,
      backupPostersRequired: true,
      backupReviewersRequired: true,
      preReviewerRequired: true,
      preReviewerIsAuthorCheck: true,
      posterIsAuthorCheck: true
    },
    userAction?: GLCreateActionsEnum,
    loggedInUser?: string
  ) {
    // comments validation
    const commentsValidation = ValidationManager.CommentsValidation(opts.posterCommentsRequired, userAction);

    let reviewerValidation = Yup.string();
    // check if reviewer is not the same as author from initialState

    if (opts.reviewerIsAuthorCheck) {
      reviewerValidation = reviewerValidation.test({
        name: "reviewerNotAuthor",
        test: function (value) {
          if (
            (value && initialState.author && initialState.author.indexOf(value) !== -1) ||
            loggedInUser?.toLowerCase === value
          ) {
            return this.createError({
              message: `The Author cannot also be the Reviewer.`,
              path: "reviewersTabReviewer"
            });
          }
          if (loggedInUser && value && loggedInUser.toLowerCase().indexOf(value.toLowerCase()) !== -1) {
            return this.createError({
              message: `The Author cannot also be the Reviewer.`,
              path: "reviewersTabReviewer"
            });
          }
          return true;
        }
      });
    }

    if (opts.reviewerRequired) {
      reviewerValidation = reviewerValidation.required("The Reviewer field is required.");
    }

    let posterValidation = Yup.string();
    if (opts.posterRequired || userAction === GLCreateActionsEnum.SendToPoster) {
      posterValidation = posterValidation.required("The Poster field is required.");
    }
    if (opts.posterIsAuthorCheck) {
      posterValidation = posterValidation.test({
        name: "posterNotAuthor",
        test: function (value) {
          if (value && initialState.author && initialState.author.indexOf(value) !== -1) {
            return this.createError({
              message: `The Author cannot also be the Poster.`,
              path: "postersTabPoster"
            });
          }
          return true;
        }
      });
    }
    const backupPostersType = Yup.array().of(Yup.string());
    const reviewerType = Yup.array().of(Yup.string());
    let backupPostersValidation = ValidationManager.CommonPosterOrReviewerCheckForArrays(
      75,
      "Additional Posters",
      backupPostersType,
      opts.posterRequired
    );
    if (opts.backupPostersRequired) {
      backupPostersValidation = backupPostersValidation.test({
        name: "backupPostersRequired",
        test: function (value) {
          if (!value || value.length === 0) {
            return this.createError({
              message: `Backup Posters field is required..`,
              path: "postersTabBackupPosters"
            });
          }
          return true;
        }
      });
    }
    const preReviewerType = Yup.array().of(Yup.string().required());
    let preReviewerValidation = ValidationManager.CommonPosterOrReviewerCheckForArrays(
      75,
      "PreReviewers",
      preReviewerType,
      opts.preReviewerRequired
    );
    if (opts.preReviewerRequired) {
      preReviewerValidation = preReviewerValidation.test({
        name: "preReviewerRequired",
        test: function (value) {
          if (!value || value.length === 0) {
            return this.createError({
              message: `Pre-Reviewer field is required.`,
              path: "reviewersTabPreReviewers"
            });
          }
          return true;
        }
      });
    }
    if (opts.preReviewerIsAuthorCheck) {
      preReviewerValidation = preReviewerValidation.test({
        name: "preReviewerNotAuthor",
        test: function (value) {
          if (
            value &&
            initialState.author &&
            value.find((alias) => alias?.indexOf(initialState.author?.toUpperCase() ?? "") !== -1)
          ) {
            return this.createError({
              message: `The Author ${initialState.author} cannot also be the Pre-Reviewer.`,
              path: "reviewersTabPreReviewers"
            });
          }
          return true;
        }
      });
    }
    let additionalReviewersValidation = ValidationManager.CommonPosterOrReviewerCheckForArrays(
      75,
      "Additional Reviewers",
      reviewerType,
      opts.reviewerRequired
    );
    if (opts.backupReviewersRequired) {
      additionalReviewersValidation = additionalReviewersValidation.required("Backup Reviewers field is required.");
    }
    if (opts.additionalReviewersIsAuthorCheck) {
      additionalReviewersValidation = additionalReviewersValidation.test({
        name: "additionalReviewersNotAuthor",
        test: function (value) {
          if (
            value &&
            initialState.author &&
            value.find((alias) => alias?.indexOf(initialState.author?.toUpperCase() ?? "") !== -1)
          ) {
            return this.createError({
              message: `The Author ${initialState.author} cannot also be the Additional Reviewer.`,
              path: "reviewersTabAdditionalReviewers"
            });
          }
          return true;
        }
      });
    }

    const posterReviewerSchema = Yup.object({
      postersTabPoster: posterValidation,
      postersTabBackupPosters: backupPostersValidation,
      // ; separated string must check that poster string is NOT in it
      postersTabComments: commentsValidation,
      reviewersTabReviewer: reviewerValidation,
      // additional reviewers can't be more than then ; separated reviewers
      reviewersTabAdditionalReviewers: additionalReviewersValidation,
      reviewersTabPreReviewers: preReviewerValidation
    });

    return ValidationManager.PosterReviewerCrossValidations(posterReviewerSchema, loggedInUser);
  }

  public static CommentAndValidationCodeValidation(optionalClarificationCodes: boolean) {
    const commentsValidation = ValidationManager.specialCharactersCheck("Comments", Yup.string()).required(
      "Comments must not be empty."
    );
    let clarificationCodes;
    if (optionalClarificationCodes) {
      clarificationCodes = Yup.array();
    } else {
      clarificationCodes = Yup.array()
        .nullable()
        .min(1, "At least one clarification Code is required.")
        .required("Clarification Code is required.");
    }
    return Yup.object().shape({
      reviewersTabComments: commentsValidation,
      reviewersTabClarificationCodes: clarificationCodes
    });
  }
}
