import {
  ApiParsingUtilities,
  GetClassification,
  JemConfiguration,
  IRequestOptions,
  getValidUrl,
  postRequest
} from "@jem/components";
import { isValid } from "date-fns";
import {
  DashboardRow,
  IHCCDashboardApiPayload,
  IHCCDashboardData,
  POStatus,
  SanitizedRow
} from "./IHCCDashboard.types";

export function sanitizeRow(textStatus: POStatus, row: DashboardRow): SanitizedRow {
  const requestorUpper = row.requestor ? row.requestor.toUpperCase() : row.requestor;
  const reversal = row.reversalInd === "X" ? "Y" : "N";

  const newSanitizedRow: SanitizedRow = {
    access: ApiParsingUtilities.addDefault(row.access),
    actionEnabled: row.actionEnabled,
    actualReviewer: ApiParsingUtilities.addDefault(row.actualReviewer),
    bankArea: ApiParsingUtilities.addDefault(row.bankArea),
    bulkGuid: ApiParsingUtilities.addDefault(row.bulkGuid),
    comment: ApiParsingUtilities.addDefault(row.comment),
    isSapInfo: row.isSapInfo,
    companyCode: ApiParsingUtilities.addDefault(row.companyCode),
    dateModified: ApiParsingUtilities.parseDate(row.dateModified),
    dueDate: ApiParsingUtilities.parseDate(row.dueDate),
    enterBy: ApiParsingUtilities.addDefault(row.enterBy),
    fiscalPeriod: ApiParsingUtilities.addDefault(row.fiscalPeriod),
    fiscalYear: ApiParsingUtilities.addDefault(row.fiscalYear),
    poId: ApiParsingUtilities.addDefault(row.poId),
    mark: ApiParsingUtilities.addDefault(row.mark),
    poNumber: ApiParsingUtilities.addDefault(row.poNumber),
    format: GetClassification(row.postingType),
    postingType: ApiParsingUtilities.addDefault(row.postingType),
    refGuid: ApiParsingUtilities.addDefault(row.refGuid),
    reversalInd: ApiParsingUtilities.addDefault(reversal),
    reviewedDate: ApiParsingUtilities.parseDate(row.reviewedDate),
    reviewer: ApiParsingUtilities.addDefault(row.reviewer),
    reviewerWithoutSupervisor: ApiParsingUtilities.addDefault(row.reviewerWithoutSupervisor),
    significance: ApiParsingUtilities.addDefault(row.significance),
    status: ApiParsingUtilities.parseNumber(row.status),
    statusCode: ApiParsingUtilities.addDefault(row.statusCode),
    supervisor: ApiParsingUtilities.addDefault(row.supervisor),
    rowVersion: row.rowVersion,
    opAccount: ApiParsingUtilities.addDefault(row.opAccount),
    rpAccount: ApiParsingUtilities.addDefault(row.rpAccount),
    transType: ApiParsingUtilities.addDefault(row.transType),
    transDesc: ApiParsingUtilities.addDefault(row.transDesc),
    requestor: ApiParsingUtilities.addDefault(requestorUpper),
    isReverse: row.isReverse,
    actionWeightage: row.actionWeightage,
    createdBy: ApiParsingUtilities.addDefault(row.createdBy),
    createdDate: ApiParsingUtilities.parseDate(row.createdDate),
    receivingPartyCurrency: ApiParsingUtilities.addDefault(row.receivingPartyCurrency),
    rpAmountInAccountCurrency: ApiParsingUtilities.parseNumber(row.rpAmountInAccountCurrency),
    postingDate: ApiParsingUtilities.parseDate(row.postingDate),
    txtStatus: textStatus,
    processingStatus: "",
    procStatType: "Information",
    isPosted: typeof row.postingDate === "string" && isValid(row.postingDate) ? true : false,
    tenantName: ApiParsingUtilities.addDefault(row.tenanatName),
    jemPoNumber: ApiParsingUtilities.addDefault(row.poId)
  };
  return newSanitizedRow;
}

export function sanitizeResultSet(results: DashboardRow[], textStatus: POStatus): Promise<SanitizedRow[]> {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      try {
        const sanitizedResults = results.map((row) => sanitizeRow(textStatus, row));
        resolve(sanitizedResults);
      } catch (error) {
        reject(error);
      }
    }, 1);
  });
}

export function payloadToRowsForIhccDashboard(payload: IHCCDashboardApiPayload): Promise<IHCCDashboardData> {
  return new Promise((resolve, reject) => {
    setTimeout(async () => {
      try {
        let rows: SanitizedRow[] = [];

        const sanitizedRequestorPending = await sanitizeResultSet(
          payload.requestorPendingClarifiction.result,
          "Requestor Pending Clarification"
        );
        rows = rows.concat(sanitizedRequestorPending);

        const sanitizedRequestorSignedOff = await sanitizeResultSet(
          payload.requestorSignedOff.result,
          "Requestor Sign-Off Requested"
        );
        rows = rows.concat(sanitizedRequestorSignedOff);

        const sanitizedReviewerPending = await sanitizeResultSet(
          payload.reviewerPendingClarification.result,
          "Reviewer Pending Clarification"
        );
        rows = rows.concat(sanitizedReviewerPending);

        const sanitizedReviewerSignedOff = await sanitizeResultSet(
          payload.reviewerSignedOff.result,
          "Reviewer Sign-Off Requested"
        );
        rows = rows.concat(sanitizedReviewerSignedOff);

        const model = {
          actions: {
            "Requestor Pending Clarification": payload.requestorPendingClarifiction.gridActions,
            "Requestor Sign-Off Requested": payload.requestorSignedOff.gridActions,
            "Reviewer Pending Clarification": payload.reviewerPendingClarification.gridActions,
            "Reviewer Sign-Off Requested": payload.reviewerSignedOff.gridActions
          },
          rows
        };
        resolve(model);
      } catch (error) {
        reject(error);
      }
    }, 0);
  });
}

function emptyIhccDashboard() {
  return {
    actions: {
      "Requestor Pending Clarification": [],
      "Requestor Sign-Off Requested": [],
      "Reviewer Pending Clarification": [],
      "Reviewer Sign-Off Requested": []
    },
    rows: []
  };
}

export interface DashboardDataArgs {
  configuration: JemConfiguration["IhccApi"];
}

export async function getIhccDashboardData(
  args: DashboardDataArgs,
  opts: IRequestOptions<IHCCDashboardData>
): Promise<IHCCDashboardData> {
  try {
    if (opts.mockFn) {
      return opts.mockFn(args);
    }
    const endpoint = `${args.configuration.baseApiUrl}${args.configuration.endpoints.getDashboard}`;
    const endpointUrl = getValidUrl(endpoint);
    const payload = await postRequest<IHCCDashboardApiPayload>(endpointUrl, undefined, opts.getTokenFn);
    if (!payload) {
      return emptyIhccDashboard();
    }
    const dataForActions = await payloadToRowsForIhccDashboard(payload);
    return dataForActions;
  } catch (e) {
    console.error(e);
    return emptyIhccDashboard();
  }
}
