import { GLCommentsModel } from "./SharedModels";

/**
 * @description - This function is used to parse the xml comments and return the parsed object.
 * @param xmlString The XML string to parse
 * @returns The parsed object
 */
function parseXML(xmlString: string): Document {
  const parser = new DOMParser();

  // Get namespace of parsererror
  const docError = parser.parseFromString("INVALID", "text/xml");
  const parsererrorNS = docError.getElementsByTagName("parsererror")[0].namespaceURI;

  // Check for parsererror
  const doc = parser.parseFromString(xmlString, "text/xml");
  if (doc.getElementsByTagNameNS(parsererrorNS, "parsererror").length > 0) {
    throw new Error("Error parsing XML");
  }
  return doc;
}

/**
 * @description - This function is used to parse the xml comments and return the parsed object.
 * @param xmlComment The XML string to parse
 * @throws Error if the xmlComment is not valid
 * @returns Array of CommentsModel
 */
export function parseXmlFromComments(
  persona: "Poster" | "Reviewer" | "Administrator",
  xmlComment: string
): GLCommentsModel[] {
  const xmlString = parseXML(xmlComment);
  return Array.from(xmlString.documentElement.children).reduce((container, possibleChildComment) => {
    let alias = possibleChildComment.getAttribute("Alias");
    if (alias === null) return container;
    if (alias.indexOf("\\") > -1) {
      alias = alias.split("\\")[1];
    }
    container.push({
      persona,
      clarification: possibleChildComment.getAttribute("Clarifications") || "",
      version: possibleChildComment.getAttribute("Version") || "",
      alias: possibleChildComment.getAttribute("Alias") || "",
      dateAdded: possibleChildComment.getAttribute("Date") || "",
      comments: possibleChildComment.getAttribute("Value") || ""
    });
    return container;
  }, [] as GLCommentsModel[]);
}
