import { Text } from "@fluentui/react";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { v4 } from "uuid";
import { TagsInput, defaultBreakKeysOnTagsInputs } from "../TagsInput/TagsInput";

interface AliasInputProps {
  disabled?: boolean;
}

export interface AliasInputRef {
  getAliases: () => string[];
  setInitialAliases: (newAliases: string[]) => void;
  reset: () => void;
}

const searchAliasId = v4();

const AliasInput = forwardRef<AliasInputRef, AliasInputProps>((props, ref) => {
  const [aliases, setAliases] = useState<string[]>([]);

  useImperativeHandle(
    ref,
    (): AliasInputRef => ({
      getAliases() {
        return aliases;
      },
      setInitialAliases(newAliases) {
        if (newAliases.length !== 0) {
          setAliases(newAliases);
        }
      },
      reset() {
        setAliases([]);
      }
    })
  );

  return (
    <React.Fragment>
      <TagsInput
        ariaLabelledBy={searchAliasId}
        value={aliases}
        onChange={(t: string[]) => setAliases(t)}
        addKeys={defaultBreakKeysOnTagsInputs()}
        disabled={!!props.disabled}
        uppercase={true}
      />
      <Text
        nowrap
        variant="xSmall"
        block
        style={{
          marginTop: "4px",
          color: "var(--accent-font-color, gray)"
        }}
      >
        Multiple alias enabled. Separate by semicolons.
      </Text>
    </React.Fragment>
  );
});

AliasInput.displayName = "AliasInput";

export { AliasInput };
