import React from "react";
import { Pivot, PivotItem, Separator, Text } from "@fluentui/react";
import { UserProfile } from "../UserProfile/UserProfile";
import { css } from "@emotion/css";

declare const window: any;

const profileTabStyles = css`
  flex-grow: 1;
`;

const AppHeaderUserProfile: JSX.Element = (
  <div
    className={css`
      display: flex;
      flex-direction: column;
      margin-top: -12px !important;
    `}
  >
    <div
      className={css`
        flex-grow: 1;
        display: flex;
        flex-direction: column;
      `}
    >
      <Pivot>
        <PivotItem headerText="Profile">
          <div className={profileTabStyles}>
            <UserProfile />
          </div>
        </PivotItem>
      </Pivot>
    </div>
    <Separator />
    <Text variant="small" nowrap block>
      Version: {window.JemVersion || ""}
    </Text>
  </div>
);

export { AppHeaderUserProfile };
