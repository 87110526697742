import { DefaultButton, Label, PrimaryButton, Stack, TextField, Text } from "@fluentui/react";
import { Field, Form, Formik } from "formik";
import React, { PropsWithChildren, useContext } from "react";

import * as Yup from "yup";

import { JemConfiguration } from "../../../JemConfiguration";
import { gridStyle } from "../../../GeneralLedger/shared/GL.styles";
import { ValidationManager } from "../../../GeneralLedger/validation/ValidationManager";
import { UserContext } from "../../contexts/UserContext/UserContext";
import { ApiError, CommonApiErrorResponse, tryParseJson } from "../../utilities";
import { ProcessCommonRowProps } from "../../utilities/ProcessCommonRowProps";
import { removeDuplicateAliases } from "../../utilities/ValidateReviewerAliasesLocally";
import { ActionResult, ActionTypes } from "../ActionManager/ActionsManager.types";
import { JEMAliasPicker } from "../JEMAliasPicker/JEMAliasPicker";
import { ActionsContext } from "./Actions.ActionsContext";
import { ActionTitle } from "./Actions.ActionTitle";
import { ActionOnSubmitHandlerMap, BaseActionProps, CommonPropsOnRows } from "./Actions.types";
import { getAdditionalReviewers } from "./AddReviewer";

interface AddPosterProps<ListType, ResultType> extends BaseActionProps<ListType, ResultType> {
  onSubmitHandler: ActionOnSubmitHandlerMap<ListType, ResultType>[ActionTypes.addPoster];
}

const AddPoster = <ListType extends CommonPropsOnRows, ResultType extends ActionResult>(
  props: PropsWithChildren<AddPosterProps<ListType, ResultType>>
) => {
  const { styles } = useContext(ActionsContext);
  const { accessToken } = useContext(UserContext);

  const aliasPickerLimit = !props.configuration ? 1 : 75;

  // any item isPosted
  const aliasesForState = ProcessCommonRowProps(props.items);
  const { reviewer, restOfReviewers } = getAdditionalReviewers(aliasesForState.reviewer);

  const initialState = {
    author: "",
    // always blank if it is not posted
    postersTabPoster: aliasesForState.poster || "",
    postersTabBackupPosters: aliasesForState.additionalPosters ? aliasesForState.additionalPosters : [],
    postersTabComments: "",
    reviewersTabReviewer: aliasesForState.isPosted ? reviewer || "" : "",
    reviewersTabAdditionalReviewers: aliasesForState.isPosted ? restOfReviewers : aliasesForState.reviewer || [],
    error: ""
  };
  return (
    <>
      <ActionTitle onCloseButton={() => props.onCancel()} name={"Add Backup Posters"}></ActionTitle>
      <div className={styles.contentStyles.body}>
        <Formik
          initialValues={initialState}
          onSubmit={async (values, { setSubmitting, setFieldError }) => {
            try {
              setSubmitting(true);

              // because we're adding poster, we only care about posters
              // make a copy of values without reviewers and additional reviewers
              // and set those to blank string and []
              const valuesWithoutReviewers = { ...values };
              valuesWithoutReviewers.reviewersTabReviewer = "";
              valuesWithoutReviewers.reviewersTabAdditionalReviewers = [];

              if (props.configuration) {
                await ValidationManager.PosterPermissionValidation({
                  configuration: props.configuration as NonNullable<JemConfiguration["GeneralLedgerApi"]>,
                  accessToken
                })
                  .required()
                  .validate(valuesWithoutReviewers, {
                    abortEarly: false
                  });
              }

              const result = await props.onSubmitHandler(
                props.items,
                values.postersTabPoster,
                values.postersTabBackupPosters
              );
              setSubmitting(false);
              props.onSubmit(result);
            } catch (error) {
              if (error instanceof Yup.ValidationError) {
                setFieldError("error", error.message);
                return;
              } else if (error instanceof ApiError) {
                const err = tryParseJson(error.message);
                if (!err) {
                  setFieldError("error", error.message);
                } else {
                  const theerror = err as unknown as Error;
                  if ("status" in theerror && "message" in theerror && "errors" in theerror && "changes" in theerror) {
                    const e = err as unknown as CommonApiErrorResponse;
                    const messagesWithoutDuplicates = Array.from(new Set(e.errors.map((x) => x.message)));
                    setFieldError("error", messagesWithoutDuplicates.join("\n"));
                  }
                }
                setSubmitting(false);
                return;
              } else if (error instanceof Error) {
                setFieldError("error", error.message);
              }
              setSubmitting(false);
              return;
            }
          }}
          validateOnMount={true}
          validationSchema={ValidationManager.PosterAndReviewerOfflineValidation(initialState, {
            posterRequired: aliasesForState.isPosted ? false : true,
            reviewerRequired: false,
            reviewerIsAuthorCheck: false,
            additionalReviewersIsAuthorCheck: false,
            posterCommentsRequired: false,
            backupPostersRequired: aliasesForState.isPosted,
            backupReviewersRequired: false
          })}
        >
          {(formik) => (
            <Form onSubmit={formik.handleSubmit}>
              <Stack
                {...{
                  tokens: {
                    childrenGap: 10,
                    padding: 10
                  }
                }}
              >
                {formik.values.postersTabPoster || !aliasesForState.isPosted ? (
                  <Stack.Item>
                    <Field
                      name={"postersTabPoster"}
                      label="Poster:"
                      as={TextField}
                      type="input"
                      errorMessage={formik.errors.postersTabPoster || ""}
                      description={
                        aliasesForState.isPosted
                          ? aliasesForState.poster === null
                            ? "Multiple Posters selected."
                            : ""
                          : ""
                      }
                      disabled={aliasesForState.isPosted}
                    />
                  </Stack.Item>
                ) : null}
                <Stack.Item>
                  <JEMAliasPicker
                    label="Backup Posters"
                    required={true}
                    disabled={false}
                    initialAliases={formik.values.postersTabBackupPosters}
                    onChange={(value) => {
                      // remember to remove duplicates from the list
                      const unique = removeDuplicateAliases(value);
                      formik.setFieldValue("postersTabBackupPosters", unique);
                    }}
                    itemLimit={aliasPickerLimit}
                  />
                  <Text
                    nowrap
                    variant="xSmall"
                    block
                    style={{
                      marginTop: "4px",
                      color: "var(--accent-font-color, black)"
                    }}
                  >
                    A maximum of {aliasPickerLimit} backup posters are supported.
                  </Text>
                </Stack.Item>
                {formik.values.reviewersTabAdditionalReviewers.length !== 0 ? (
                  <Stack.Item>
                    <JEMAliasPicker
                      label="Backup Reviewers"
                      required={false}
                      disabled={true}
                      initialAliases={formik.values.reviewersTabAdditionalReviewers}
                    />
                  </Stack.Item>
                ) : null}
                <Stack.Item>
                  {formik.errors.error ? (
                    <Label
                      styles={{
                        root: {
                          color: "red"
                        }
                      }}
                    >
                      {formik.errors.error}
                    </Label>
                  ) : null}
                </Stack.Item>
                <Stack.Item>
                  <Stack horizontal {...{ tokens: gridStyle }}>
                    <Stack.Item align="end">
                      <PrimaryButton text={"Ok"} type="submit" disabled={!formik.isValid || formik.isSubmitting} />
                    </Stack.Item>
                    <Stack.Item align="end">
                      <DefaultButton
                        onClick={(event) => {
                          event.stopPropagation();
                          props.onCancel();
                        }}
                        text="Cancel"
                      />
                    </Stack.Item>
                  </Stack>
                </Stack.Item>
              </Stack>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

AddPoster.displayName = "AddPoster";

export { AddPoster };
