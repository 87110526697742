import React, { forwardRef, useContext, useEffect, useImperativeHandle, useReducer, useState } from "react";
import { css } from "@emotion/css";
import { Dropdown, IDropdownOption, Label, Text, TextField } from "@fluentui/react";

import { Case, Switch } from "react-condition";
import { For } from "react-loops";
import { v4 as uuidv4 } from "uuid";
import { OpsDashboardContext } from "../../contexts/OpsDashboardContext/OpsDashboardContext";
import { OpsDashboardActionsFromApi, TemplateParameter } from "../OperationsDashboard/Actions.types";
import { IRequestPayload } from "./OpsDashboardCreate.types";
import RowCount from "../../pages/OpsDashboardCreate/OpsDashboardCreate.RowCount";
import { OpsDashboardApi, RequireAtLeastOne, trimNullableString } from "@jem/components";

export interface SetterOptions {
  isEditable: boolean;
}

export interface FormDataForApi {
  rowKey?: string;
  requestId: string;
  comments: string;
  reviewers: string;
  params: Parameter[];
}

export interface FormData {
  actionId: number;
  actionTitle: string;
  apiPayload: FormDataForApi;
}

export interface ICreateRequestForm {
  ref: {
    getForm: () => FormData;
    setForm: (request: IRequestPayload, action: OpsDashboardActionsFromApi, opts: SetterOptions) => void;
    reset: () => void;
    setAction: (action: OpsDashboardActionsFromApi, opts: SetterOptions) => void;
  };
  props: {
    configuration: OpsDashboardApi;
  };
}

export interface ICreateRequestState {
  actionId: number;
  rowKey: string;
  requestName: string;
  comments: string;
  newComment: string;
  title: string;
  reviewers: string[];
  approvedBy: string[];
  status: string;
  author: string;
  sqlStatement: string;
}

type FormReducer = (
  state: ICreateRequestState,
  newState: RequireAtLeastOne<ICreateRequestState>
) => ICreateRequestState;

interface ParameterValue {
  id: string;
  label: string;
  value: string;
  type: string;
  isRequired: boolean;
}

interface Parameter {
  Name: string;
  Value: string;
}

const CreateRequestFormInitialState: ICreateRequestState = {
  actionId: 0,
  rowKey: "",
  requestName: "",
  comments: "",
  newComment: "",
  title: "",
  status: "",
  author: "",
  reviewers: [],
  approvedBy: [],
  sqlStatement: ""
};

function parameterValuesArrayToObject(parameterArr: Parameter[]) {
  return parameterArr.reduce((ctr, v) => {
    ctr[v.Name] = v.Value;
    return ctr;
  }, {} as { [key: string]: string });
}

function craftParametersForUi(
  parameters: TemplateParameter[],
  parameterValues: { [key: string]: string }
): ParameterValue[] {
  const forUI = [] as ParameterValue[];
  for (const param of parameters) {
    forUI.push({
      id: param.id,
      label: param.label,
      value: param.id in parameterValues ? parameterValues[param.id] : "",
      type: param.type,
      isRequired: param.isRequired
    });
  }
  return forUI;
}

function craftParametersForApi(parameters: ParameterValue[]): Parameter[] {
  return parameters.map((param) => ({
    Name: param.id,
    Value: param.value
  }));
}

const getParameterValues = (
  parameters: OpsDashboardActionsFromApi["parameters"],
  parametersValuesStr: string
): ParameterValue[] => {
  try {
    if (parametersValuesStr.length !== 0 && parameters) {
      const possibleValues = JSON.parse(parametersValuesStr);
      if (Array.isArray(possibleValues)) {
        const parameterValues = parameterValuesArrayToObject(possibleValues as Parameter[]);
        return craftParametersForUi(parameters, parameterValues);
      }
    } else if (parameters) {
      return craftParametersForUi(parameters, {});
    }
    return [];
  } catch (e) {
    console.error(e);
    return [];
  }
};

function assembleBaseErrorObject(nonDynamicParameters: string[], dynamicParameters: string[]): ErrorObjectForUi {
  const errors: ErrorObjectForUi = nonDynamicParameters.reduce((ctr, errorType) => {
    ctr[errorType] = "";
    return ctr;
  }, {} as ErrorObjectForUi);
  for (const paramId of dynamicParameters) {
    errors[paramId] = "";
  }
  return errors;
}

function getApproversError(selectedAliases: string[]) {
  if (selectedAliases.length < 2) {
    return "At least 2 approvers are required";
  } else {
    return "";
  }
}

function getBaseErrors(parametersForUI: ParameterValue[]): ErrorObjectForUi {
  const nonDynamicParameterErrors = ["approvers", "comments"];
  const dynamicParameterIds = parametersForUI.map((param) => param.id);
  const baseNewState = assembleBaseErrorObject(nonDynamicParameterErrors, dynamicParameterIds);
  return baseNewState;
}

function commentsError(newUserComment: string) {
  const remaining = 255 - newUserComment.length;
  if (remaining < 0) {
    return `Comment exceeds max length. 255 max characters`;
  }
  return `${remaining} characters remaining`;
}

type ErrorObjectForUi = { [key: string]: string };

const CreateRequestForm = forwardRef<ICreateRequestForm["ref"], ICreateRequestForm["props"]>((props, ref) => {
  const opsDashboardContext = useContext(OpsDashboardContext);
  const [state, setState] = useReducer<FormReducer>((state, newState) => {
    const cState = { ...state, ...newState };
    return cState;
  }, CreateRequestFormInitialState);
  const [parametersForUI, setParameterForUI] = useState<ParameterValue[]>([]);
  const [isReadOnly, setIsReadOnly] = useState<boolean>(true);
  const [dropdownOptions, setDropdownOptions] = useState<IDropdownOption[]>([]);
  const [selectedAliases, setSelectedAliases] = useState<string[]>([]);

  const onChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void => {
    if (option) {
      setSelectedAliases(
        option.selected
          ? [...selectedAliases, option.key as string]
          : selectedAliases.filter((key) => key !== option.key)
      );
    }
  };

  useImperativeHandle(ref, () => ({
    getForm() {
      const parametersForApi = craftParametersForApi(parametersForUI);
      const payload: FormDataForApi = {
        requestId: uuidv4(),
        comments: state.newComment,
        reviewers: selectedAliases.join(","),
        params: parametersForApi
      };
      if (state.rowKey.length !== 0) {
        payload.rowKey = state.rowKey;
      }
      return {
        actionTitle: state.title,
        actionId: state.actionId,
        apiPayload: payload
      };
    },
    setForm(request, action, opts) {
      setParameterForUI(getParameterValues(action.parameters, request.parameters));
      setState({
        actionId: action.actionId,
        rowKey: request.rowKey,
        reviewers: request.assignReviewers.split(","),
        comments: trimNullableString(request.notes),
        requestName: trimNullableString(request.rowKey),
        title: trimNullableString(request.title),
        status: trimNullableString(request.status),
        author: trimNullableString(request.createdBy),
        approvedBy: request.approvedBy
          ? request.approvedBy
              .split(",")
              .filter((x) => x)
              .map(trimNullableString)
          : [],
        sqlStatement: request.sqlStatement
      });
      const aliasesForDropdown = opsDashboardContext.reviewers.values.map((reviewer) => ({
        key: reviewer,
        text: reviewer
      }));
      setDropdownOptions(aliasesForDropdown);
      const aliases = request.assignReviewers.split(",").filter((x) => x);
      setSelectedAliases(aliases);
      setIsReadOnly(!opts.isEditable);
    },
    setAction(action, opts) {
      setState({
        ...CreateRequestFormInitialState,
        actionId: action.actionId,
        title: action.actionTitle
      });
      setParameterForUI(getParameterValues(action.parameters, ""));
      const aliasesForDropdown = opsDashboardContext.reviewers.values.map((reviewer) => ({
        key: reviewer,
        text: reviewer
      }));
      setDropdownOptions(aliasesForDropdown);
      setSelectedAliases(aliasesForDropdown.map((a) => a.key));
      setIsReadOnly(!opts.isEditable);
    },
    reset() {
      setState(CreateRequestFormInitialState);
      setSelectedAliases([]);
      setIsReadOnly(false);
    }
  }));

  const [errors, setErrors] = useReducer((pState: ErrorObjectForUi, type: string) => {
    const baseNewState = Object.keys(pState).length === 0 ? getBaseErrors(parametersForUI) : { ...pState };
    if (type === "approvers") {
      baseNewState[type] = getApproversError(selectedAliases);
    } else if (type === "comments") {
      baseNewState[type] = commentsError(state.newComment);
    } else if (type === "parameters") {
      for (const paramObj of parametersForUI) {
        if (paramObj.isRequired && paramObj.value === "") {
          baseNewState[paramObj.id] = `Parameter is required.`;
        } else {
          baseNewState[paramObj.id] = "";
        }
      }
    }
    return baseNewState;
  }, {});

  useEffect(() => {
    setErrors("approvers");
  }, [selectedAliases]);
  useEffect(() => {
    setErrors("comments");
  }, [state.newComment]);
  useEffect(() => {
    setErrors("parameters");
  }, [parametersForUI]);

  useEffect(() => {
    const noErrorsInFields = Object.keys(errors)
      .filter((errorKey) => errorKey !== "comments")
      .every((key: string) => errors[key as keyof typeof errors] === "");
    const noErrorInComment = !(state.newComment === "" && state.rowKey === "");
    const noErrorsFound = noErrorsInFields && noErrorInComment;
    opsDashboardContext.setErrors({
      collectorName: "OpsDashboardCreate",
      value: !noErrorsFound
    });
    //
  }, [errors]);

  return (
    <div
      className={css`
        width: 50%;
        margin-left: 16px;
        position: relative;
        display: flex;
        gap: 16px;
        flex-direction: column;
      `}
    >
      <h3>
        <Text variant="xLarge">{state.title}</Text>
      </h3>
      {!state.requestName ? null : (
        <div>
          <TextField name="requestName" label="Request Name:" underlined value={state.requestName} readOnly={true} />
        </div>
      )}
      {!state.status && !state.author ? null : (
        <div
          className={css`
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: no-wrap;
            gap: 16px;
            justify-content: space-between;
            & > div {
              flex-grow: 1;
            }
          `}
        >
          <TextField name="status" label="Status:" underlined value={state.status} readOnly={true} />
          <TextField name="author" label="Author:" underlined value={state.author} readOnly={true} />
        </div>
      )}
      <div>
        <Dropdown
          placeholder="Select at least 2 Approvers"
          options={dropdownOptions}
          label="Approvers"
          selectedKeys={selectedAliases}
          onChange={onChange}
          ariaLabel="Available Approvers"
          multiSelect
          disabled={true}
          errorMessage={errors["approvers"]}
        />
      </div>
      <div>
        <TextField
          name="comments"
          label="Comments"
          value={state.newComment}
          onChange={(_, newValue) => setState({ newComment: newValue || "" })}
          required={true}
          readOnly={isReadOnly}
          multiline
          autoAdjustHeight
          rows={2}
          description={errors["comments"]}
        />
      </div>
      {state.comments ? (
        <div>
          <TextField
            name="commentHistory"
            label="Comment History"
            value={state.comments}
            readOnly={true}
            multiline
            autoAdjustHeight
            rows={2}
          />
        </div>
      ) : null}
      <div>
        <Label>Parameters</Label>
        <For of={parametersForUI} ifEmpty={<Text>Could not parse Parameters.</Text>}>
          {(item) => (
            <Switch expression={item.type}>
              <Case value={"Input.Text"}>
                <TextField
                  name={item.id}
                  label={item.label}
                  value={item.value}
                  onChange={(_, newValue) => {
                    setParameterForUI((pValues) => {
                      const newDevices = pValues.reduce((ds, d) => {
                        let newD = d;
                        if (d.id === item.id) {
                          newD = Object.assign({}, d, { value: newValue });
                        }
                        return ds.concat(newD);
                      }, [] as ParameterValue[]);
                      return newDevices;
                    });
                  }}
                  readOnly={isReadOnly}
                  required={item.isRequired}
                  errorMessage={errors[item.id]}
                />
              </Case>
            </Switch>
          )}
        </For>
      </div>
      {state.rowKey && state.rowKey.length >= 2 ? (
        <>
          <div>
            <TextField
              name="sqlStatement"
              label="SQL Statement"
              value={state.sqlStatement}
              readOnly={true}
              multiline
              autoAdjustHeight
              rows={2}
            />
          </div>
          <RowCount rowKey={state.rowKey} configuration={props.configuration}></RowCount>
        </>
      ) : null}
    </div>
  );
});

CreateRequestForm.displayName = "CreateRequestForm";

export default CreateRequestForm;
