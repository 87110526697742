import React from "react";
import { Link } from "@fluentui/react";
import { DEFAULT_NULL_CHARACTER } from "../../../Shared/utilities/ApiParsingUtilities";
import { TransactionDetails } from "./JEDetails.schema";
import { IExtendedColumn } from "../../../Shared/components/DashboardGrid/DashboardGrid.types";

export const transDetailsColumns = (): IExtendedColumn[] => [
  {
    key: "System",
    name: "System",
    fieldName: "ItemId",
    isResizable: false,
    type: "string",
    minWidth: 40
  },
  {
    key: "LineItemDescription",
    name: "Line Item Description",
    fieldName: "LineItemDescription",
    type: "string",
    minWidth: 120
  },
  {
    key: "Account",
    name: "Account",
    fieldName: "Account",
    type: "number",
    minWidth: 50
  },
  {
    key: "AccountDescription",
    name: "Account Des.",
    fieldName: "AccountDescription",
    type: "string",
    minWidth: 130
  },
  {
    key: "TransAmnt",
    name: "Trans Amt.",
    fieldName: "TransAmnt",
    type: "number",
    minWidth: 70
  },
  {
    key: "TransCurr",
    name: "Trans Curr.",
    fieldName: "TransCurr",
    type: "string",
    minWidth: 25
  },
  {
    key: "StatAmnt",
    name: "Stat Amt.",
    fieldName: "StatAmnt",
    type: "number",
    minWidth: 60
  },
  {
    key: "StatCurr",
    name: "Stat Curr.",
    fieldName: "StatCurr",
    type: "string",
    minWidth: 25
  },
  {
    key: "GCAmnt",
    name: "GC Amt.",
    fieldName: "GCAmnt",
    type: "number",
    minWidth: 60
  },
  {
    key: "CC",
    name: "CC",
    fieldName: "CC",
    type: "string",
    minWidth: 25
  },
  {
    key: "IO",
    name: "IO",
    fieldName: "IO",
    type: "string",
    minWidth: 25
  },
  {
    key: "SO",
    name: "SO",
    fieldName: "SO",
    type: "string",
    minWidth: 25
  },
  {
    key: "SOL",
    name: "SOL",
    fieldName: "SOL",
    type: "string",
    minWidth: 25
  },
  {
    key: "TP",
    name: "TP",
    fieldName: "TP",
    type: "string",
    minWidth: 25
  },
  {
    key: "TC",
    name: "TC",
    fieldName: "TC",
    type: "string",
    minWidth: 25
  },
  {
    key: "PC",
    name: "PC",
    fieldName: "PC",
    type: "string",
    minWidth: 25
  },
  {
    key: "POS",
    name: "POS",
    fieldName: "POS",
    type: "number",
    minWidth: 30
  },
  {
    key: "assignmentId",
    name: "Assignment ID",
    fieldName: "assignmentId",
    type: "string",
    minWidth: 120,
    onRender: (item: TransactionDetails) => {
      if (item.AssignmentId === DEFAULT_NULL_CHARACTER) {
        return DEFAULT_NULL_CHARACTER;
      }
      return (
        <Link
          href="https://msit.powerbi.com/groups/4f8f826c-bfbf-48c6-880b-8da3798da25d/reports/de10c93c-6b56-405a-8dcb-38a771be638e/ReportSection9a202bb0678c94a8424b"
          target="_blank"
        >
          {item.AssignmentId}
        </Link>
      );
    }
  }
];

export const headerDetailsColumns = (): IExtendedColumn[] => [
  {
    key: "System",
    name: "System",
    fieldName: "System",
    type: "string",
    minWidth: 30,
    ariaLabel: "Source System"
  },
  {
    key: "DocNumber",
    name: "Doc. Number",
    fieldName: "DocNumber",
    type: "string",
    minWidth: 70
  },
  {
    key: "RefNumber",
    name: "Ref. Number",
    fieldName: "RefNumber",
    type: "string",
    minWidth: 70
  },
  {
    key: "TotalAmount",
    name: "Total Amt.",
    fieldName: "TotalAmount",
    type: "string",
    minWidth: 80
  },
  {
    key: "DebitAmount",
    name: "Debit Amt.",
    fieldName: "DebitAmount",
    type: "string",
    minWidth: 80
  },
  {
    key: "CreditAmount",
    name: "Credit Amt.",
    fieldName: "CreditAmount",
    type: "string",
    minWidth: 80
  },
  {
    key: "Description",
    name: "Description",
    fieldName: "Description",
    type: "string",
    minWidth: 110
  },
  {
    key: "PostingDate",
    name: "Posting Date",
    fieldName: "PostingDate",
    type: "string",
    minWidth: 70
  },
  {
    key: "FiscalYear",
    name: "Fiscal yr./pd.",
    fieldName: "FiscalYearAndPeriod",
    type: "string",
    minWidth: 50
  },
  {
    key: "Currency",
    name: "Currency",
    fieldName: "Currency",
    type: "string",
    minWidth: 70
  },
  {
    key: "CompanyCode",
    name: "Co. Code",
    fieldName: "CompanyCode",
    type: "string",
    minWidth: 40
  },
  {
    key: "JEType",
    name: "JE Type",
    fieldName: "JEType",
    type: "string",
    minWidth: 30
  },
  {
    key: "Transaction",
    name: "Transaction",
    fieldName: "Transaction",
    type: "string",
    minWidth: 30
  },
  {
    key: "DocDate",
    name: "Doc. Date",
    fieldName: "DocDate",
    type: "string",
    minWidth: 30
  },
  {
    key: "RequestId",
    name: "Request ID",
    fieldName: "RequestId",
    type: "string",
    minWidth: 70
  }
];
