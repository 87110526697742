import React, { useContext, useEffect, useImperativeHandle, useState } from "react";
import { IconButton, ITag, Label, TagPicker, useTheme } from "@fluentui/react";
import { DomainDataObjects, DomainDataEnum, JEMContext, FiscalPeriod, sortDraftDateCodes } from "../../contexts";
import { v4 } from "uuid";
import { FiscalPeriodStringTransformations } from "../../../GeneralLedger";
import { JemConfiguration } from "../../../JemConfiguration";

export interface DraftDateCodeDropdownProps {
  selectedDraftDateCodes?: string[];
  selectedFiscalPeriod?: Pick<FiscalPeriod, "fiscalMonth" | "fiscalYear">;
  fcwFiscalCalendarLink: JemConfiguration["FCWFiscalCalendarLink"];
  customRef?: React.RefObject<DraftDateCodeDropdownRef>;
  onChange?: (currentDraftDateCodes: DomainDataObjects[DomainDataEnum.PostingDueDates]) => void;
  required?: boolean;
  hideLabel?: boolean;
}

export interface DraftDateCodeDropdownRef {
  getDraftDateCodes: () => DomainDataObjects[DomainDataEnum.PostingDueDates];
  reset: () => void;
  setDraftDateCodes: (draftDateCode: string[]) => void;
}

export const stringToTag = (
  draftDateCode: string,
  allDraftDateCodes: DomainDataObjects[DomainDataEnum.PostingDueDates],
  fiscalPeriod?: Pick<FiscalPeriod, "fiscalMonth" | "fiscalYear">
): ITag | undefined => {
  if (fiscalPeriod) {
    const filteredDraftDateCodes = allDraftDateCodes.filter(
      (cc) => cc.fiscalYear === fiscalPeriod.fiscalYear && cc.fiscalPeriod === fiscalPeriod.fiscalMonth
    );
    const draftDateCodeObject = filteredDraftDateCodes.find((cc) => {
      return cc.draftDateCode === draftDateCode;
    });
    if (draftDateCodeObject) {
      return {
        key: draftDateCodeObject.draftDateCode,
        name: draftDateCodeObject.draftDateCode
      };
    }
  }

  // check if draftDateCode contains FYFP-
  const draftDateCodeArray = draftDateCode.split("-");
  if (draftDateCodeArray.length === 2) {
    const fiscalYear = Number(draftDateCodeArray[0].substring(0, 4));
    const fiscalPeriod = Number(draftDateCodeArray[0].substring(4, 6));
    const draftDateCodeObject = allDraftDateCodes.find((cc) => {
      return (
        cc.fiscalYear === fiscalYear && cc.fiscalPeriod === fiscalPeriod && cc.draftDateCode === draftDateCodeArray[1]
      );
    });
    if (draftDateCodeObject) {
      return {
        key: `${draftDateCodeObject.fiscalYear}${draftDateCodeObject.fiscalPeriod}-${draftDateCodeObject.draftDateCode}`,
        name: `${draftDateCodeObject.fiscalYear}${draftDateCodeObject.fiscalPeriod}-${draftDateCodeObject.draftDateCode}`
      };
    }
  }

  return undefined;
};

function draftDateCodesToStringArray(
  allDraftDateCodes: DomainDataObjects[DomainDataEnum.PostingDueDates],
  selectedFiscalPeriod?: Pick<FiscalPeriod, "fiscalMonth" | "fiscalYear">
): string[] {
  if (!selectedFiscalPeriod) {
    return allDraftDateCodes.map(
      (cc) =>
        `${FiscalPeriodStringTransformations.FiscalYearAndFiscalMonthToString(cc.fiscalYear, cc.fiscalPeriod)}-${
          cc.draftDateCode
        }`
    );
  }
  const filteredDraftDateCodes = allDraftDateCodes.filter(
    (cc) => cc.fiscalYear === selectedFiscalPeriod.fiscalYear && cc.fiscalPeriod === selectedFiscalPeriod.fiscalMonth
  );
  return filteredDraftDateCodes.map((cc) => cc.draftDateCode);
}

function searchInDraftDateCodes(array: string[], searchTerm?: string, limit = 10): string[] {
  if (!searchTerm) {
    return array.slice(0, limit);
  }

  const matchingCodes = array.filter((draftDateCode) => draftDateCode.includes(searchTerm));

  return matchingCodes.slice(0, limit);
}

function tagsToDraftDateCodes(tags: ITag[], allDraftDateCodes: DomainDataObjects[DomainDataEnum.PostingDueDates]) {
  const draftDateCodeObjects = tags.reduce((ctr, tag) => {
    if (typeof tag.key !== "string") return ctr;

    const [fyfpstr, draftDateCode] = tag.key.split("-");
    const fyfp = FiscalPeriodStringTransformations.FiscalPeriodStringToFiscalYearAndFiscalMonth(fyfpstr);
    if (!fyfp) return ctr;

    const draftDateObj = allDraftDateCodes.find((cc) => {
      return (
        cc.fiscalYear === fyfp.fiscalYear && cc.fiscalPeriod === fyfp.fiscalMonth && cc.draftDateCode === draftDateCode
      );
    });
    if (draftDateObj) {
      ctr.push(draftDateObj);
    }
    return ctr;
  }, [] as DomainDataObjects[DomainDataEnum.PostingDueDates]);
  return draftDateCodeObjects;
}

function tagsToDraftDateCodesWithFiscalPeriod(
  tags: ITag[],
  fyfp: Pick<FiscalPeriod, "fiscalMonth" | "fiscalYear">,
  allDraftDateCodes: DomainDataObjects[DomainDataEnum.PostingDueDates]
) {
  const draftDateCodeObjects = tags.reduce((ctr, tag) => {
    const draftDateObj = allDraftDateCodes.find((cc) => {
      return cc.fiscalYear === fyfp.fiscalYear && cc.fiscalPeriod === fyfp.fiscalMonth && cc.draftDateCode === tag.key;
    });
    if (draftDateObj) {
      ctr.push(draftDateObj);
    }
    return ctr;
  }, [] as DomainDataObjects[DomainDataEnum.PostingDueDates]);
  return draftDateCodeObjects;
}

let DraftDateCodeDropdown: React.FC<DraftDateCodeDropdownProps> = (props) => {
  const jemContext = useContext(JEMContext);
  const [selectedDraftDateCodes, setSelectedDraftDateCodes] = useState<ITag[]>([]);
  const allDraftDateCodes = jemContext.initInfo.values
    ? jemContext.initInfo.values[DomainDataEnum.PostingDueDates].sort(sortDraftDateCodes)
    : [];
  const theme = useTheme();

  const isInDraftDateCodes = (prefix?: string, limit = 100): ITag[] => {
    const draftDateCodesArray = draftDateCodesToStringArray(allDraftDateCodes, props.selectedFiscalPeriod);

    const allItems = searchInDraftDateCodes(draftDateCodesArray, prefix, limit).map((ccName) => ({
      key: ccName,
      name: ccName
    }));

    return allItems.filter((item) => !selectedDraftDateCodes.find((tag) => tag.key === item.key));
  };

  useEffect(() => {
    if (jemContext.initInfo.values && props.onChange !== undefined) {
      const allDraftDateCodes = jemContext.initInfo.values.PostingDueDates;
      const initialDraftDateCodes = props.selectedDraftDateCodes
        ? props.selectedDraftDateCodes
            .map((co) => stringToTag(co, allDraftDateCodes, props.selectedFiscalPeriod) as ITag)
            .filter((co) => co !== undefined)
        : [];
      setSelectedDraftDateCodes(initialDraftDateCodes);
    }
  }, [jemContext.initInfo.values, props.selectedDraftDateCodes, props.selectedFiscalPeriod]);

  useImperativeHandle(props.customRef, () => ({
    getDraftDateCodes() {
      const draftDateCodeObjects = getSelectedCodes(selectedDraftDateCodes);
      return draftDateCodeObjects;
    },
    reset() {
      setSelectedDraftDateCodes([]);
    },
    setDraftDateCodes(draftDateCodes: string[]) {
      const newTags = draftDateCodes
        .map((co) => stringToTag(co, allDraftDateCodes, props.selectedFiscalPeriod))
        .filter((co) => co !== undefined) as ITag[];
      setSelectedDraftDateCodes(newTags);
    }
  }));

  const getSelectedCodes = (items: ITag[]) => {
    if (!props.selectedFiscalPeriod) {
      // the tag key is FYFP-DraftDateCode
      // we must get all the draft date codes for the selected fiscal period, get the objects for the selected draft date code
      // and return all the objects
      const draftDateCodeObjects = tagsToDraftDateCodes(items, allDraftDateCodes);
      return draftDateCodeObjects;
    }
    const theFyFp = props.selectedFiscalPeriod;
    // find the draft date code object using the selectedFiscalPeriod and the draft date code string
    const draftDateCodeObjects = tagsToDraftDateCodesWithFiscalPeriod(items, theFyFp, allDraftDateCodes);
    return draftDateCodeObjects;
  };

  const handleOnChange = (items: ITag[]) => {
    if (props.onChange) {
      const draftDateCodeObjects = getSelectedCodes(items);
      props.onChange(draftDateCodeObjects);
    }
  };

  const id = React.useMemo(() => `draftdatecodepicker-${v4().slice(0, 8)}`, []);
  const label = "Draft Date Code:";
  return (
    <>
      {!props.hideLabel ? (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <Label htmlFor={id}>{label}</Label>
          <IconButton
            required={props.required}
            iconProps={{ iconName: "OpenInNewTab" }}
            title={`Go to FCW Fiscal Calendar`}
            ariaLabel={`Go to FCW Fiscal Calendar`}
            onClick={() => {
              window.open(props.fcwFiscalCalendarLink, "_blank");
            }}
          />
        </div>
      ) : null}
      <TagPicker
        onResolveSuggestions={(p) => isInDraftDateCodes(p, 100)}
        removeButtonAriaLabel="Remove Draft Date Code"
        pickerSuggestionsProps={{
          noResultsFoundText: "No results found"
        }}
        inputProps={{
          id: id,
          "aria-label": "Search Draft Date Codes",
          placeholder: "Search Draft Date Codes",
          onKeyDown: async (e) => {
            if (e.ctrlKey && e.code === "KeyV") {
              // Copy the selected tags to the clipboard when Ctrl+C is pressed
              e.preventDefault();
              const clipboardText = await navigator.clipboard.readText();
              const tagNames = clipboardText.split(";");
              const newTags = tagNames.map((name) => ({
                key: name.trim().toUpperCase(),
                name: name.trim().toUpperCase()
              }));
              const uniqueTags = newTags.filter((upperCaseTag) => {
                return !selectedDraftDateCodes.some((t) => t.key === upperCaseTag.key);
              });
              const newTagsFinal = [...selectedDraftDateCodes, ...uniqueTags];
              setSelectedDraftDateCodes(newTagsFinal);
              handleOnChange(newTagsFinal);
            }
          }
        }}
        itemLimit={1}
        resolveDelay={300}
        selectedItems={selectedDraftDateCodes}
        onChange={(items?: ITag[]) => {
          if (items) {
            setSelectedDraftDateCodes(items);
            handleOnChange(items);
          }
        }}
        onEmptyResolveSuggestions={() => isInDraftDateCodes("", 300)}
        styles={{ root: { marginTop: "0 !important", minWidth: 200 } }}
        disabled={!jemContext.initInfo.values}
      />
      {props.required && selectedDraftDateCodes.length === 0 && (
        <Label
          styles={{
            root: {
              fontSize: "12px",
              fontWeight: "400",
              color: theme.palette.redDark,
              margin: "0px !important",
              paddingTop: "5px"
            }
          }}
        >
          Draft Date Code is required
        </Label>
      )}
    </>
  );
};

DraftDateCodeDropdown = React.memo(DraftDateCodeDropdown, (prev, next) => {
  return (
    prev.selectedDraftDateCodes === next.selectedDraftDateCodes &&
    prev.required === next.required &&
    prev.selectedFiscalPeriod === next.selectedFiscalPeriod &&
    prev.customRef === next.customRef &&
    prev.onChange === next.onChange
  );
});

DraftDateCodeDropdown.displayName = "DraftDateCodeDropdown";

export { DraftDateCodeDropdown };
