import { IExtendedColumn } from "@jem/components";

export const getTreasuryDashboardColumns = () => (): IExtendedColumn[] => {
  const cols: IExtendedColumn[] = [
    {
      key: "statusCode",
      name: "Status",
      fieldName: "statusCode",
      type: "string",
      minWidth: 100
    },
    {
      key: "approvedDate",
      name: "Approved Date",
      fieldName: "approvedDate",
      type: "date",
      minWidth: 100
    },
    {
      key: "opAccount",
      name: "OP Account",
      fieldName: "opAccount",
      type: "string",
      minWidth: 100
    },
    {
      key: "transactionCurrency",
      name: "Trans Currency",
      fieldName: "transactionCurrency",
      type: "string",
      minWidth: 100
    },
    {
      key: "transAmount",
      name: "Trans Amount",
      fieldName: "transAmount",
      type: "number",
      minWidth: 100
    },
    {
      key: "rpAccount",
      name: "RP Account",
      fieldName: "rpAccount",
      type: "string",
      minWidth: 100
    },
    {
      key: "transDesc",
      name: "Trans Desc",
      fieldName: "transDesc",
      type: "string",
      minWidth: 100
    },
    {
      key: "usdAmount",
      name: "USD Amount",
      fieldName: "usdAmount",
      type: "number",
      minWidth: 100
    },
    {
      key: "opType",
      name: "OP Type",
      fieldName: "opType",
      type: "string",
      minWidth: 100
    },
    {
      key: "opTier",
      name: "OP Tier",
      fieldName: "opTier",
      type: "string",
      minWidth: 100
    },
    {
      key: "opCurrency",
      name: "OP Currency",
      fieldName: "opCurrency",
      type: "string",
      minWidth: 100
    },
    {
      key: "opAmount",
      name: "OP Amount",
      fieldName: "opAmount",
      type: "number",
      minWidth: 100
    },
    {
      key: "rpType",
      name: "RP Type",
      fieldName: "rpType",
      type: "string",
      minWidth: 100
    },
    {
      key: "rpTier",
      name: "RP Tier",
      fieldName: "rpTier",
      type: "string",
      minWidth: 100
    },
    {
      key: "receivingPartyCurrency",
      name: "RP Currency",
      fieldName: "receivingPartyCurrency",
      type: "string",
      minWidth: 100
    },
    {
      key: "rpAmount",
      name: "RP Amount",
      fieldName: "rpAmount",
      type: "number",
      minWidth: 100
    },
    {
      key: "reversalItemIndicator",
      name: "Reversal Indicator",
      fieldName: "reversalItemIndicator",
      type: "string",
      minWidth: 100
    },
    {
      key: "requestor",
      name: "Requestor",
      fieldName: "requestor",
      type: "string",
      minWidth: 100
    },
    {
      key: "reviewer",
      name: "Reviewer",
      fieldName: "reviewer",
      type: "string",
      minWidth: 100
    },
    {
      key: "comment",
      name: "Comments",
      fieldName: "comment",
      type: "string",
      minWidth: 100
    },
    {
      key: "releaseDate",
      name: "Release Date",
      fieldName: "releaseDate",
      type: "date",
      minWidth: 100
    },
    {
      key: "releasedBy",
      name: "Released By",
      fieldName: "releasedBy",
      type: "string",
      minWidth: 100
    },
    {
      key: "poNumber",
      name: "JEM_PO Name",
      fieldName: "poNumber",
      type: "string",
      minWidth: 100
    },
    {
      key: "processingStatus",
      name: "Processing Status",
      fieldName: "processingStatus",
      type: "string",
      minWidth: 60
    }
  ];
  return cols;
};
