import React from "react";
import { css } from "@emotion/css";
import { DefaultButton, Icon, BaseButton, Button, ITheme, useTheme } from "@fluentui/react";

const isEqual = require("lodash/isEqual");

export const pageHeaderStyles = (theme: ITheme, overrideStyles?: string) => css`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
  ${overrideStyles ? overrideStyles : "margin: 16px 0;"}
  & h2 {
    margin: 0 0;
    font-size: 1.5em;
    font-weight: 100;
    color: ${theme.palette.black};
  }
`;

const iconClass = css`
  fontSize: 50,
  height: 50,
  width: 50,
  margin: '0 25px'
`;

export interface IPageHeadingProps {
  onClose?: (
    ev: React.MouseEvent<
      Button | HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | BaseButton | HTMLSpanElement,
      MouseEvent
    >
  ) => void;
  closeLabel?: string;
  closeTooltip?: string;
  children?: React.ReactNode;
  overrideRootStyles?: string;
}

let PageHeading: React.FC<IPageHeadingProps> = (props) => {
  const theme = useTheme();
  const onClick = (
    ev: React.MouseEvent<
      Button | HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | BaseButton | HTMLSpanElement,
      MouseEvent
    >
  ) => {
    ev.stopPropagation();
    if (props.onClose) {
      props.onClose(ev);
    }
  };

  return (
    <>
      <div className={pageHeaderStyles(theme, props.overrideRootStyles)}>
        {!props.children ? null : props.children}
        {props.onClose && props.closeLabel !== undefined ? (
          <DefaultButton
            onClick={onClick}
            ariaLabel="Go Back to Dashboard"
            title="Close Button"
            styles={{
              root: {
                border: "none",
                backgroundColor: "inherit",
                marginLeft: "auto"
              },
              flexContainer: {
                justifyContent: "center",
                flexDirection: "row",
                alignItems: "center",
                alignContent: "center"
              }
            }}
          >
            <Icon
              iconName="Cancel"
              className={iconClass}
              styles={{
                root: {
                  color: theme.palette.accent,
                  marginRight: "8px"
                }
              }}
            />
            {props.closeLabel}
          </DefaultButton>
        ) : null}
      </div>
      <hr
        className={css`
          margin: 0;
          padding: 0;
          border: none;
          background: ${theme.palette.neutralLighterAlt};
          height: 1px;
        `}
      />
    </>
  );
};
PageHeading = React.memo(PageHeading, (prevProps, nextProps) => {
  if (prevProps.closeLabel !== nextProps.closeLabel) return false;
  if (prevProps.onClose !== nextProps.onClose) return false;
  if (!isEqual(prevProps.children, nextProps.children)) return false;
  return true;
});
PageHeading.displayName = "PageHeading";
export { PageHeading };
