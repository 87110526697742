import { Tenant } from "./TenantDropdown.types";

export const getTenantsFromApi = async (): Promise<Tenant[]> => {
  return [
    {
      appId: "",
      name: ""
    }
  ];
};
