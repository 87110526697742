import React, { useRef, useState } from "react";
import { Dropdown, PrimaryButton, Toggle, DatePicker } from "@fluentui/react";
import {
  CompanyCodeMultiSelector,
  CompanyCodeMultiSelectorRef,
  currentFyFp,
  DomainDataEnum,
  DomainDataObjects,
  ForceMaxWidthAndHeightOnDropdownStyle,
  JemConfiguration,
  SearchOpsDetailDropdown,
  SearchOpsDetailDropdownRef
} from "@jem/components";
import FiscalYearFiscalPeriodDropdown, {
  FiscalYearFiscalPeriodDropdownRef
} from "../../../Shared/components/FiscalYearFiscalPeriodDropdown/FiscalYearFiscalPeriodDropdown";

interface AAG3DashboardSelectorProps {
  onUpdate: (formData: AAG3DashboardFormData) => void;
  configuration: JemConfiguration["GeneralLedgerApi"];
  domainData: Pick<
    DomainDataObjects,
    DomainDataEnum.FiscalPeriods | DomainDataEnum.FiscalPeriodsBPO | DomainDataEnum.JeCategoryRules
  >;
}

export interface AAG3DashboardFormData {
  fiscalYear: number;
  fiscalPeriod: number;
  filterByPeriod: boolean;
  dueDateRangeStart?: string;
  dueDateRangeEnd?: string;
  forMeOnly: boolean;
  opsOrgDetailIds: number[];
  companyCodes: number[];
  jeType?: "regular" | "batch" | "bulk" | "insignificant" | "lowvalue" | "optional" | "significant";
  jeStatus?: "overdue" | "pending" | "completed" | "total" | "not req";
  moduleType: "posting" | "review";
}

export interface UserSelection {
  row: string;
  column: string;
  cardName: string;
}

const AAG3DashboardSelector: React.FC<AAG3DashboardSelectorProps> = (props) => {
  const [useFiscalSearch, setUseFiscalSearch] = useState(true);
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const [forMeOnly, setForMeOnly] = useState(false);
  const [jeType, setJeType] = useState<string>("None");
  const [category, setCategory] = useState<string>("None");
  const [isPostingGlanceCard, setIsPostingGlanceCard] = useState(true);
  const [jeStatus, setJeStatus] = useState<string>("Pending");
  const opsDetailRef = useRef<SearchOpsDetailDropdownRef>(null);
  const companyCodeSelector = useRef<CompanyCodeMultiSelectorRef>(null);
  const fyfpRef = useRef<FiscalYearFiscalPeriodDropdownRef>(null);

  const toggleDateSearch = () => {
    setUseFiscalSearch(!useFiscalSearch);
    setStartDate(undefined);
    setEndDate(undefined);
  };

  const handleUpdate = () => {
    console.log("handleUpdate");
    if (!opsDetailRef.current || !companyCodeSelector.current) {
      return;
    }

    const fyfp = useFiscalSearch && fyfpRef.current ? fyfpRef.current.getFiscalPeriod() : currentFyFp();
    const formData: AAG3DashboardFormData = {
      fiscalYear: fyfp.fiscalYear,
      fiscalPeriod: fyfp.fiscalMonth,
      dueDateRangeStart:
        startDate !== undefined ? startDate.toLocaleDateString("en-ZA").replace(/\//g, "-") : undefined,
      dueDateRangeEnd: endDate !== undefined ? endDate.toLocaleDateString("en-ZA").replace(/\//g, "-") : undefined,
      forMeOnly,
      filterByPeriod: useFiscalSearch,
      jeType: (isPostingGlanceCard
        ? jeType && jeType !== "None"
          ? jeType.toString()
          : ""
        : category && category !== "None"
        ? category
        : "") as AAG3DashboardFormData["jeType"],
      jeStatus: jeStatus as AAG3DashboardFormData["jeStatus"],
      opsOrgDetailIds: opsDetailRef.current.getOpsDetail(),
      companyCodes: companyCodeSelector.current.getCompanyCodes(),
      moduleType: isPostingGlanceCard ? "posting" : "review"
    };
    console.log(formData);
    props.onUpdate(formData);
  };

  return (
    <div className="ms-Grid" dir="ltr">
      <div className="ms-Grid-row">
        <div className="ms-Grid-col ms-sm5">
          <SearchOpsDetailDropdown
            configuration={props.configuration}
            customRef={opsDetailRef}
            styles={ForceMaxWidthAndHeightOnDropdownStyle({
              width: "100%",
              height: "300px"
            })}
          />
        </div>
        <div className="ms-Grid-col ms-sm1">
          <Toggle label="For me only" checked={forMeOnly} onChange={(_, checked) => setForMeOnly(checked || false)} />
        </div>
        <div className="ms-Grid-col ms-sm1">
          <Toggle label="Use Fiscal Period" checked={useFiscalSearch} onChange={toggleDateSearch} />
        </div>
        {useFiscalSearch ? (
          <>
            <div className="ms-Grid-col ms-sm4">
              <FiscalYearFiscalPeriodDropdown
                domainData={props.domainData}
                customRef={fyfpRef}
              ></FiscalYearFiscalPeriodDropdown>
            </div>
          </>
        ) : (
          <>
            <div className="ms-Grid-col ms-sm2">
              <DatePicker label="Start Date" value={startDate} onSelectDate={(x) => setStartDate(x || undefined)} />
            </div>
            <div className="ms-Grid-col ms-sm2">
              <DatePicker label="End Date" value={endDate} onSelectDate={(x) => setEndDate(x || undefined)} />
            </div>
          </>
        )}
      </div>
      <div className="ms-Grid-row">
        <div className="ms-Grid-col ms-sm1">
          <Toggle
            label={isPostingGlanceCard ? "Posting" : "Reviewer"}
            checked={isPostingGlanceCard}
            onChange={(_, checked) => setIsPostingGlanceCard(checked || false)}
          />
        </div>
        <div className="ms-Grid-col ms-sm3">
          {!isPostingGlanceCard ? (
            <Dropdown
              label="Row Name: JE Category"
              options={[{ key: "None", text: "None Selected" }].concat(
                props.domainData.JeCategoryRules.map((x) => ({
                  key: x.name,
                  text: x.name
                }))
              )}
              selectedKey={category}
              onChange={(_, option) => setCategory(option ? option.key.toString() : "N")}
            />
          ) : (
            <Dropdown
              label="Row Name: JE Type"
              options={[
                { key: "None", text: "None Selected" },
                { key: "Regular", text: "Regular" },
                { key: "Batch", text: "Batch" },
                { key: "Bulk", text: "Bulk" }
                // Add more options as needed
              ]}
              selectedKey={jeType}
              onChange={(_, option) => setJeType(option ? (option.key as string) : "None")}
            />
          )}
        </div>
        <div className="ms-Grid-col ms-sm2">
          <Dropdown
            label="Column Name: JE Status"
            options={[
              { key: "Overdue", text: "Overdue" },
              { key: "Pending", text: "Pending" },
              { key: "Completed", text: "Complete" },
              { key: "NotRequired", text: "Not Req" },
              { key: "", text: "Total" }
              // Add more options as needed
            ]}
            selectedKey={jeStatus}
            onChange={(_, option) => setJeStatus(option ? (option.key as string) : "Pending")}
          />
        </div>
        <div className="ms-Grid-col ms-sm6">
          <CompanyCodeMultiSelector customRef={companyCodeSelector} selectedCompanies={[]}></CompanyCodeMultiSelector>
        </div>
      </div>

      <div className="ms-Grid-row">
        <div className="ms-Grid-col ms-sm2">
          <PrimaryButton text="Update" onClick={handleUpdate} />
        </div>
      </div>
    </div>
  );
};

AAG3DashboardSelector.displayName = "AAG3DashboardSelector";

export { AAG3DashboardSelector };
