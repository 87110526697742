import { RegStoreAttachmentForUpload } from "../../../GeneralLedger";
import { JemNotification } from "../../contexts/LoggingContext/LoggingContext";
import { ActionOnSubmitHandlerMap } from "../Actions/Actions.types";
import { GLActionAttachmentsProps } from "../Actions/GLAttachmentsAction";
import {
  GLAttachment,
  IAttachmentEntryFormProps,
  IAttachmentEntryFormSingleUploadProps
} from "../Attachments/Attachments.types";

export enum ActionTypes {
  "save" = "save",
  "sendToPoster" = "sendToPoster",
  "approve" = "approve",
  "releaseForSignoff" = "releaseForSignoff",
  "needsClarification" = "needsClarification",
  "addPoster" = "addPoster",
  "addReviewer" = "addReviewer",
  "recall" = "recall",
  "rescind" = "rescind",
  "addAttachment" = "addAttachment",
  "deleteAction" = "deleteAction",
  "saveToSAP" = "saveToSAP",
  "sendBackFromTreasury" = "sendBackFromTreasury",
  "approvePreReview" = "approvePreReview",
  "needsClarificationPreReview" = "needsClarificationPreReview",
  "recallPreReview" = "recallPreReview",
  "reverse" = "reverse"
}

export interface ActionResult {
  actionType: ActionTypes;
  notification: JemNotification;
}

export interface GeneralLedgerAction {
  status: boolean;
  message: string;
  errors: GeneralLedgerActionError[];
  changes: GeneralLedgerActionChange[];
}

export interface GeneralLedgerActionError {
  code: string;
  message: string;
}

export interface GeneralLedgerActionChange {
  jeId: number;
  refGuid: string;
  jeStatus: number | null;
  oldJeStatus: number;
  errorCode: string | null;
}

export interface GeneralLedgerActionResult extends ActionResult {
  response: GeneralLedgerAction | null;
}

export interface SAPReversalResponse {
  refGuid: string;
  item: string;
  messagE_TYPE: string;
  message: string;
  originalJeNum: string;
  companyCode: string;
  fiscalYear: string;
  reversedJeNum: string;
  reversalPeriod: string;
  reversalDocType: string;
  mode: string;
  reversalFiscalYear: string;
  reversalPostingDate: string;
  reversalStatus: boolean;
  jeId: number;
}
export interface BaseAttachmentEndpointMap {
  deleteAttachment: IAttachmentEntryFormProps["onDeleteFile"];
  downloadAttachment: IAttachmentEntryFormProps["onDownloadFile"];
}

export interface SingleAttachmentEndpointMap extends BaseAttachmentEndpointMap {
  uploadAttachment: IAttachmentEntryFormSingleUploadProps["onUploadFile"];
}

export type AttachmentEndpointMap = SingleAttachmentEndpointMap;

export interface BaseActionEndpointMap<ListType, ResultType> {
  [ActionTypes.save]?: ActionOnSubmitHandlerMap<ListType, ResultType>[ActionTypes.save];
  [ActionTypes.sendToPoster]?: ActionOnSubmitHandlerMap<ListType, ResultType>[ActionTypes.sendToPoster];
  [ActionTypes.approve]?: ActionOnSubmitHandlerMap<ListType, ResultType>[ActionTypes.approve];
  [ActionTypes.releaseForSignoff]?: ActionOnSubmitHandlerMap<ListType, ResultType>[ActionTypes.releaseForSignoff];
  [ActionTypes.needsClarification]?: ActionOnSubmitHandlerMap<ListType, ResultType>[ActionTypes.needsClarification];
  [ActionTypes.addPoster]?: ActionOnSubmitHandlerMap<ListType, ResultType>[ActionTypes.addPoster];
  [ActionTypes.addReviewer]?: ActionOnSubmitHandlerMap<ListType, ResultType>[ActionTypes.addReviewer];
  [ActionTypes.recall]?: ActionOnSubmitHandlerMap<ListType, ResultType>[ActionTypes.recall];
  [ActionTypes.deleteAction]?: ActionOnSubmitHandlerMap<ListType, ResultType>[ActionTypes.deleteAction];
  [ActionTypes.saveToSAP]?: ActionOnSubmitHandlerMap<ListType, ResultType>[ActionTypes.saveToSAP];
  [ActionTypes.sendBackFromTreasury]?: ActionOnSubmitHandlerMap<ListType, ResultType>[ActionTypes.sendBackFromTreasury];
  [ActionTypes.approvePreReview]?: ActionOnSubmitHandlerMap<ListType, ResultType>[ActionTypes.approvePreReview];
  [ActionTypes.needsClarificationPreReview]?: ActionOnSubmitHandlerMap<
    ListType,
    ResultType
  >[ActionTypes.needsClarificationPreReview];
  [ActionTypes.recallPreReview]?: ActionOnSubmitHandlerMap<ListType, ResultType>[ActionTypes.recallPreReview];
}

export interface ActionEndpointMap<ListType, ResultType> extends BaseActionEndpointMap<ListType, ResultType> {
  [ActionTypes.addAttachment]?: ActionOnSubmitHandlerMap<ListType, ResultType>[ActionTypes.addAttachment];
  [ActionTypes.rescind]?: ActionOnSubmitHandlerMap<ListType, ResultType>[ActionTypes.rescind];
}

export interface GLActionEndpointMap<ListType, ResultType> extends BaseActionEndpointMap<ListType, ResultType> {
  [ActionTypes.addAttachment]?: GLActionAttachmentsProps<ListType, ResultType>["onSubmitHandler"];
  [ActionTypes.rescind]?: (
    items: ListType[],
    comments: string,
    attachment: GLAttachment[],
    regionKey: number,
    regionName: string
  ) => Promise<ResultType>;
  [ActionTypes.reverse]?: (
    items: ListType[],
    reversalReason: string,
    postingDate: string,
    postingPeriod: string,
    attachments: GLAttachment[],
    regionKey: number,
    comments: string
  ) => Promise<ResultType>;
}

export interface IActionResult {
  refGuid: string;
  tenantId: string;
  queueName: string;
  serviceBusName: string;
  jeHeaderError: string;
  jeAuditError: string;
  lineNumber: string;
  lineError: string;
}

export interface IActionManagerState {
  name: ActionTypes;
}

export type settingsReducer = (
  state: null | IActionManagerState,
  newState: null | IActionManagerState
) => IActionManagerState | null;

export type PreReviewActionPayload = {
  batchGuids?: string[];
  jeDraftGuids?: string[];
  jeIds?: string[];
  comments?: string;
  clarification?: string;
};

interface AttachmentsChanges {
  RegStoreAttachments: RegStoreAttachmentForUpload[];
}

export type SAPReversalActionPayload = {
  jeIds?: string[];
  reversalReason: string;
  postingDate: string;
  postingPeriod: string;
  attachments: AttachmentsChanges | null;
  reversalComment: string;
};
