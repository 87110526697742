import { IExtendedColumn } from "@jem/components";

export interface IReconFileStatus {
  fileName: string;
  reconId: string;
  jeNum: string;
  fiscalYr: string;
  companyCode: string;
  enteredBy: string;
  jeType: string;
}

export const getReconGridColumns = () => (): IExtendedColumn[] => {
  const cols: IExtendedColumn[] = [
    {
      key: "sapjeNum",
      name: "SAP JE Num",
      fieldName: "sapjeNum",
      type: "string",
      minWidth: 70
    },
    {
      key: "fiscalYear",
      name: "Fiscal Year",
      fieldName: "fiscalYear",
      type: "number",
      minWidth: 70,
      sortDescendingAriaLabel: "Sorted by High Year First"
    },
    {
      key: "companyCode",
      name: "Company Code",
      fieldName: "companyCode",
      type: "string",
      minWidth: 100
    },
    {
      key: "enteredBy",
      name: "Entered By",
      fieldName: "enteredBy",
      type: "string",
      minWidth: 30
    },
    {
      key: "entryDate",
      name: "Entry Date",
      fieldName: "entryDate",
      type: "string",
      minWidth: 30
    },
    {
      key: "jeType",
      name: "Je Type",
      fieldName: "jeType",
      type: "string",
      minWidth: 30
    },
    {
      key: "postingPeriod",
      name: "Posting Period",
      fieldName: "postingPeriod",
      type: "number",
      minWidth: 70,
      sortDescendingAriaLabel: "Sorted by High Period First"
    },
    {
      key: "jeHeaderText",
      name: "JE Header Text",
      fieldName: "jeHeaderText",
      type: "string",
      minWidth: 75
    }
  ];
  return cols;
};
