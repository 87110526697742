import {
  OpsDashboardApi,
  IUserProviderState,
  putRequest,
  JemNotification,
  postRequest,
  ApiError,
  RuntimeError
} from "@jem/components";
import { FormData, FormDataForApi } from "./OpsDashboardCreate.Form";
import { ActionTypes, RequestResponse } from "./OpsDashboardCreate.types";

function getEndpoints(actionId: number, rowKey: string, config: OpsDashboardApi) {
  return {
    [ActionTypes.Save]: `${config.baseApiUrl}${config.endpoints.saveRequest.replace("{requestId}", `${rowKey}`)}`,
    [ActionTypes.SendToApprovers]: `${config.baseApiUrl}${config.endpoints.submitRequest.replace(
      "{actionId}",
      `${actionId}`
    )}`.replace("{requestId}", `${rowKey}`),
    [ActionTypes.RejectWithComment]: `${config.baseApiUrl}${config.endpoints.rejectRequest.replace(
      "{requestId}",
      `${rowKey}`
    )}`,
    [ActionTypes.Approve]: `${config.baseApiUrl}${config.endpoints.approveRequest.replace("{requestId}", `${rowKey}`)}`
  };
}

function getActions(
  endpoints: ReturnType<typeof getEndpoints>,
  apiPayload: FormDataForApi,
  getTokenFn: IUserProviderState["accessToken"]
) {
  return {
    [ActionTypes.Save]: async () => {
      const endpoint = endpoints[ActionTypes.Save];
      const r = await putRequest<string>(endpoint, apiPayload, getTokenFn);
      console.log(r);
      return "Saved Action.";
    },
    [ActionTypes.SendToApprovers]: async () => {
      const endpoint = endpoints[ActionTypes.SendToApprovers];
      const r = await putRequest<RequestResponse>(endpoint, undefined, getTokenFn);
      console.log(r);
      return "Sent Action to Approvers.";
    },
    [ActionTypes.RejectWithComment]: async () => {
      const endpoint = endpoints[ActionTypes.RejectWithComment];
      const r = await putRequest<RequestResponse>(endpoint, apiPayload, getTokenFn);
      console.log(r);
      return "Rejected Action with Comment.";
    },
    [ActionTypes.Approve]: async () => {
      const endpoint = endpoints[ActionTypes.Approve];
      const r = await putRequest<RequestResponse>(endpoint, undefined, getTokenFn);
      console.log(r);
      return "Approved Action.";
    }
  };
}

export interface ActionResult {
  actionType: ActionTypes;
  rowKey: string;
  notification: JemNotification;
}

export async function processCreateAction(
  type: ActionTypes,
  formData: FormData,
  config: OpsDashboardApi,
  getTokenFn: IUserProviderState["accessToken"]
): Promise<ActionResult> {
  const endpoint = `${config.baseApiUrl}${config.endpoints.createRequestById.replace(
    "{actionId}",
    `${formData.actionId}`
  )}`;
  const r = await postRequest<RequestResponse>(endpoint, formData.apiPayload, getTokenFn);
  if (!r || (r && !r.requestKey)) {
    throw new ApiError("Could not Create Request.");
  }
  return {
    actionType: type,
    rowKey: r.requestKey,
    notification: {
      summaryBodyText: "Successfully created Action.",
      subjectHeader: `${formData.actionTitle} - ${r.requestKey}`,
      type: "Information",
      actionName: formData.actionTitle
    }
  };
}

export async function processAction(
  type: ActionTypes.Approve | ActionTypes.RejectWithComment | ActionTypes.Save | ActionTypes.SendToApprovers,
  formData: FormData,
  config: OpsDashboardApi,
  getTokenFn: IUserProviderState["accessToken"]
): Promise<ActionResult> {
  if (!formData.apiPayload.rowKey) {
    throw new RuntimeError("RowKey is needed for Action.");
  }
  const endpoints = getEndpoints(formData.actionId, formData.apiPayload.rowKey, config);
  const actions = getActions(endpoints, formData.apiPayload, getTokenFn);
  if (!(type in actions)) {
    throw new RuntimeError("invalid type  of ActionType.");
  }
  const message = await actions[type]();
  return {
    actionType: type,
    rowKey: formData.apiPayload.rowKey,
    notification: {
      summaryBodyText: message,
      subjectHeader: `${formData.actionTitle} - ${formData.apiPayload.rowKey}`,
      actionName: formData.actionTitle,
      type: "Information"
    }
  };
}
