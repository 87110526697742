import React from "react";
import { useLocation } from "react-router-dom";
import { JemUserRoles } from "../../contexts/UserContext/UserContext.roles.main";
import { AppNavProps } from "./AppNav.types";

export const MicrofrontendsLinks = (
  location: ReturnType<typeof useLocation>,
  onClickHandler: (route: string) => (event: React.MouseEvent | any) => void,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  props: AppNavProps,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  jemUserRoles: JemUserRoles
) => {
  const links = [
    {
      name: "AAG3Dashboard",
      key: "AAG3Dashboard",
      ariaLabel: "AAG Level 3 Dashboard",
      // "data-id": "automation_id_22235",
      target: "_blank",
      onClick: onClickHandler("/microfrontends/aag3dashboard"),
      isSelected: location.pathname === "/microfrontends/aag3dashboard"
    },
    {
      name: "JECreate",
      key: "JECreate",
      ariaLabel: "JE Create for Customers",
      // "data-id": "automation_id_22235",
      target: "_blank",
      onClick: onClickHandler("/microfrontends/jecreate"),
      isSelected: location.pathname === "/microfrontends/jecreate"
    }
  ];

  return {
    name: "Microfrontends",
    key: "Microfrontends",
    ariaLabel: "Microfrontends",
    icon: "WebAppBuilderFragment",
    // "data-id": "automation_id_22235",
    target: "_blank",
    links
  };
};
