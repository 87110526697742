import * as Yup from "yup";
import { AnyObject } from "yup/lib/types";
import { DomainDataEnum, DomainDataObjects } from "../../Shared/contexts/JEMContext/JEMContext.domainData.types";
import { ValidationManager } from "./ValidationManager";
import { GLBatchPageState } from "../components";

// eslint-disable-next-line
const toCamelCase = require("lodash/camelCase");

/**
 * @description - This object is meant to manage the validation of the batch form.
 * Please note that this object is not meant to have any state.
 * @export - The BatchValidationManager object.
 */
export class BatchValidationManager {
  public static specialCharactersCheck(fieldName: string, schema: Yup.StringSchema<string | undefined, AnyObject>) {
    const containsInvalidCharacters = (str: string) => /[<>/:*?"|#{}%~&]/.test(str);
    return schema.test(
      `${fieldName} does not accept special characters like / , : , *, ? , " , < , > , | , # , { , } , % , ~ and &.`,
      `${fieldName} does not accept special characters like / , : , *, ? , " , < , > , | , # , { , } , % , ~ and &.`,
      (value: string | undefined) => {
        return !containsInvalidCharacters(value || "");
      }
    );
  }

  public static batchNameValidation() {
    return ValidationManager.specialCharactersCheck("Batch Name", Yup.string())
      .required("Batch Name must not be empty.")
      .max(255, "Batch Name must be 255 characters or less.");
  }

  public static batchFileValidation() {
    return BatchValidationManager.specialCharactersCheck("Batch File", Yup.string()).required(
      "Batch File must not be empty."
    );
  }

  public static BatchDetailsValidations(
    domainData: Pick<DomainDataObjects, DomainDataEnum.JeReasonCodes>,
    initialState: GLBatchPageState["batchState"]
  ) {
    return Yup.object({
      detailsTabBatchName: BatchValidationManager.batchNameValidation(),
      detailsTabReasonCode: ValidationManager.reasonCodeValidation(domainData),
      detailsTabBatchPurpose: ValidationManager.jePurposeValidation(),
      detailsTabBatchDesc: ValidationManager.descriptionValidation(),
      detailsTabBatchFile: BatchValidationManager.batchFileValidation(),
      detailsTabActualTimeTaken:
        initialState.checklistName != "NOT APPLICABLE"
          ? Yup.number().moreThan(0, "Actual Time Taken should be greater than 0")
          : Yup.number()
    });
  }
}
