import { IDropdownOption, IDropdownProps } from "@fluentui/react";
import { v4 } from "uuid";
import {
  convertToReasonCodeDropdownOptions,
  currentFyFp,
  DomainDataEnum,
  DomainDataObjects,
  fyFp
} from "../../../Shared";
import { ConvertToDefaultTimeString } from "../../../Shared/utilities/DateUtilities";
import { IUserProviderState } from "../../../Shared/utilities/RequestUtilities";
import { EMPTY_CHECKLIST } from "../../shared/ChecklistPeriodChangeHandler";
import { FiscalPeriodStringTransformations } from "../../utilities";
import { FCWChecklistModel } from "../../utilities/FetchUserChecklists";
import { ErrorListModel } from "../GLCreate/GLCreateForm.types";
import { GLBatchActionResult } from "./Actions/GLBatch.UIActionDelegation";
import { GLBatchActionsEnum } from "./GLBatch.Actions";
import { BatchFields, GLBatchFormikState, GLBatchState, JeBatchErrors } from "./GLBatch.types";

export interface BatchPageConfiguration {
  readOnly: boolean;
  refGuid: string | null;
  isChecklist: boolean;
  maxAdditionalReviewers: number;
  maxBackupPosters: number;
  version: string;
  maxPreReviewers?: number;
}

export interface GLBatchPageState {
  batchState: GLBatchState;
  pageConfiguration: BatchPageConfiguration;
}

function checklistHandling(initialState: GLBatchState) {
  if (initialState.userSelectedChecklist) {
    const userSelectedChecklist = initialState.userSelectedChecklist;
    const currentChecklist = initialState.userChecklists.find(
      (checklist) => checklist.checklistRefGuid.toLowerCase() === userSelectedChecklist.checklistRefGuid.toLowerCase()
    );
    if (currentChecklist) {
      return {
        checklistName: currentChecklist.checklistName,
        userChecklists: initialState.userChecklists,
        selectedChecklist: currentChecklist
      };
    } else {
      const forDropdown: FCWChecklistModel = userSelectedChecklist;
      const addToChecklist = [forDropdown, ...initialState.userChecklists];

      return {
        checklistName: initialState.checklistName,
        userChecklists: addToChecklist,
        selectedChecklist: userSelectedChecklist
      };
    }
  }
  const selectedChecklist: FCWChecklistModel = EMPTY_CHECKLIST;
  return {
    checklistName: "NOT APPLICABLE",
    userChecklists: initialState.userChecklists,
    selectedChecklist: selectedChecklist
  };
}

export function batchStateToFormikState(
  initialState: GLBatchState,
  previousActionAndResult: GLBatchActionResult | null
): GLBatchFormikState {
  const { checklistName, userChecklists, selectedChecklist } = checklistHandling(initialState);
  const fy = fyFp.fiscalYear;
  const fp = fyFp.fiscalMonth;

  const validationErrors =
    previousActionAndResult && previousActionAndResult.batchErrors
      ? previousActionAndResult.batchErrors
      : initialState.errorTable || [];

  const formikValues: GLBatchFormikState = {
    poster: initialState.poster,
    createdDate: initialState.createdDate,
    attachments_count: initialState.attachments.length,
    attachments_error: "",
    attachments_region: `${initialState.attachments_region}`,

    batchFile: null,

    detailsTabIsBPOEntry: initialState.batchDetails.isBPOEntry,
    detailsTabIsBulkType: initialState.batchDetails.isBulkType,
    detailsTabBatchPurpose: initialState.batchDetails.batchPurpose,
    detailsTabBatchDesc: initialState.batchDetails.batchDesc,
    detailsTabBatchName:
      previousActionAndResult?.action === GLBatchActionsEnum.Clear ? "" : initialState.batchDetails.batchName,
    detailsTabReasonCode: initialState.batchDetails.reasonCode,
    detailsTabOption: initialState.batchDetails.option || [0],
    detailsTabBatchFile: initialState.batchDetails.batchFileName,

    detailsTabFiscalYearPeriod:
      initialState.batchDetails.fiscalYearPeriod === null || initialState.batchDetails.fiscalYearPeriod === ""
        ? FiscalPeriodStringTransformations.FiscalYearAndFiscalMonthToString(fy, fp)
        : initialState.batchDetails.fiscalYearPeriod,

    detailsTabChecklist: checklistName,
    detailsTabSelectedChecklist: selectedChecklist,
    detailsTabCurrentChecklists: userChecklists,
    detailsTabActualTimeTaken: initialState.actualTimeTakenMins,

    postersTabPosters: initialState.poster,
    postersTabBackupPosters: initialState.posters.backupPosters,

    reviewersTabReviewer: initialState.reviewers.reviewer,
    reviewersTabAdditionalReviewers: initialState.reviewers.additionalReviewers,
    reviewersTabPreReviewers: initialState.reviewers.preReviewers,
    errorTabErrorTable: validationErrors
  };
  return formikValues;
}

export const convertToBatchOptionsDropdownOptions = (
  userSelectedOptions: BatchFields[] | null,
  domainData: Pick<DomainDataObjects, DomainDataEnum.JeBatchFields>
): IDropdownProps["options"] => {
  if (userSelectedOptions === null) {
    const batchOptionsObject: IDropdownProps["options"] = [
      {
        key: 0,
        text: "All"
      }
    ];
    domainData[DomainDataEnum.JeBatchFields].forEach((option) => {
      if (!option.optional) {
        batchOptionsObject.push({
          key: option.fieldId,
          text: option.displayName
        });
      }
    });
    return batchOptionsObject;
  }

  const batchOptionsObject: IDropdownProps["options"] = userSelectedOptions.map((batchOptions) => ({
    key: batchOptions.fieldId,
    text: batchOptions.displayName
  }));

  return batchOptionsObject;
};

export function mapDisplayNameTOGroup(
  domainData: Pick<DomainDataObjects, DomainDataEnum.JeBatchFields>,
  displayName: string
) {
  const foundObject = domainData[DomainDataEnum.JeBatchFields].find((obj) => obj.displayName === displayName);
  return foundObject?.group || "Template1";
}

export function mapDisplayNameToFieldID(
  domainData: Pick<DomainDataObjects, DomainDataEnum.JeBatchFields>,
  displayName: string
) {
  const foundObject = domainData[DomainDataEnum.JeBatchFields].find((obj) => obj.displayName === displayName);
  return foundObject?.fieldId as number;
}

export function IDropDownOptionsToBatchFieldsForAPI(
  dropDownOptions: IDropdownOption[],
  domainData: Pick<DomainDataObjects, DomainDataEnum.JeBatchFields>
) {
  const optionalFields: BatchFields[] = [];

  for (let i = 0; i < dropDownOptions.length; i++) {
    optionalFields.push({
      group: mapDisplayNameTOGroup(domainData, dropDownOptions[i].text),
      fieldId: mapDisplayNameToFieldID(domainData, dropDownOptions[i].text),
      displayName: dropDownOptions[i].text,
      isSelected: !!dropDownOptions[i].selected,
      isOptional: false,
      optionalFieldName: dropDownOptions[i].text
    });
  }
  return optionalFields;
}

export function IDropDownOptionsToReasonCodesForAPI(dropDownOptions: IDropdownOption[]) {
  const reasonCodeObject: any[] = [];

  for (let i = 0; i < dropDownOptions.length; i++) {
    reasonCodeObject.push({
      ReasonCode: dropDownOptions[i].key,
      _reasonCode: dropDownOptions[i].key,
      JeReason: dropDownOptions[i].text,
      IsSelected: dropDownOptions[i].selected
    });
  }
  return reasonCodeObject;
}

export function convertBatchErrorsToErrorListModel(jeBatchError: JeBatchErrors[]): ErrorListModel[] {
  const errorList: ErrorListModel[] = [];

  for (let i = 0; i < jeBatchError.length; i++) {
    errorList.push({
      rowNumber: jeBatchError[i].lineNo,
      description: jeBatchError[i].message
    });
  }
  return errorList;
}

export function defaultPageStateForBatch(
  userContext: IUserProviderState,
  domainData: Pick<DomainDataObjects, DomainDataEnum.JeReasonCodes | DomainDataEnum.JeBatchFields> | null
): GLBatchPageState {
  const reasonCodesDropDownOptions = domainData
    ? convertToReasonCodeDropdownOptions(domainData[DomainDataEnum.JeReasonCodes] || [])
    : [];
  const optionsDropDownOptions = domainData ? convertToBatchOptionsDropdownOptions(null, domainData) : [];

  const refguid = v4();
  const fyFp = currentFyFp();

  const batchState: GLBatchState = {
    poster: userContext.user.alias,
    author: userContext.user.alias,
    modifiedDate: ConvertToDefaultTimeString(new Date()),
    createdDate: new Date().toLocaleDateString("en-US"),
    fiscalYear: fyFp.fiscalYear.toString(),
    fiscalPeriod: fyFp.fiscalMonth.toString(),
    batchDetails: {
      isBPOEntry: false,
      isBulkType: false,
      batchPurpose: "",
      batchDesc: "",
      batchName: "",
      reasonCode: "",
      option: [0],
      batchFileName: "",
      fiscalYearPeriod: ""
    },
    attachments: [],
    attachments_region: 2,
    lock_region: false,
    posters: {
      backupPosters: []
    },
    reviewers: {
      reviewer: userContext.jemUser.supervisor,
      additionalReviewers: [],
      preReviewers: []
    },
    errorTable: [],
    reasonCodesDropDownOptions,
    optionsDropDownOptions,
    postingType: 3,
    refGuid: refguid,
    status: 1,
    chkRefGuid: "",
    errorFilePath: "",
    fileUrl: "",
    hdrInfo: "",
    isPostingRequired: false,
    mode: 0,
    uri: "",
    authorList: "",
    tenantId: 0,
    versionNo: null,
    processingStatus: 0,
    userChecklists: [EMPTY_CHECKLIST],
    userSelectedChecklist: null,
    isAdhocDraft: false,
    checklistName: "NOT APPLICABLE",
    checklistCompanyCodes: "",
    batchCompanyCode: "",
    isDefaultState: true,
    actualTimeTakenMins: 0,
    isPreReview: false
  };

  return {
    batchState,
    pageConfiguration: {
      readOnly: false,
      refGuid: null,
      isChecklist: false,
      maxAdditionalReviewers: 4,
      maxBackupPosters: 4,
      version: "1",
      maxPreReviewers: 4
    }
  };
}

export function createBlankBatchState(): GLBatchPageState {
  const refguid = v4();

  const batchState: GLBatchState = {
    isDefaultState: true,
    poster: "",
    author: "",
    modifiedDate: ConvertToDefaultTimeString(new Date()),
    createdDate: new Date().toLocaleDateString("en-US"),
    fiscalYear: "",
    fiscalPeriod: "",
    batchDetails: {
      isBPOEntry: false,
      isBulkType: false,
      batchPurpose: "",
      batchDesc: "",
      batchName: "",
      reasonCode: "",
      option: [0],
      batchFileName: "",
      fiscalYearPeriod: ""
    },
    attachments: [],
    attachments_region: 0,
    lock_region: false,
    posters: {
      backupPosters: []
    },
    reviewers: {
      reviewer: "",
      additionalReviewers: [],
      preReviewers: []
    },
    errorTable: [],
    reasonCodesDropDownOptions: [],
    optionsDropDownOptions: [],
    postingType: 3,
    refGuid: refguid,
    status: 1,
    chkRefGuid: "",
    errorFilePath: "",
    fileUrl: "",
    hdrInfo: "",
    isPostingRequired: false,
    mode: 0,
    uri: "",
    authorList: "",
    tenantId: 0,
    versionNo: null,
    processingStatus: 0,
    isAdhocDraft: false,
    checklistName: "NOT APPLICABLE",
    userSelectedChecklist: null,
    checklistCompanyCodes: "",
    batchCompanyCode: "",
    userChecklists: [EMPTY_CHECKLIST],
    actualTimeTakenMins: 0,
    isPreReview: false
  };

  return {
    batchState,
    pageConfiguration: {
      readOnly: false,
      refGuid: "",
      isChecklist: false,
      maxAdditionalReviewers: 4,
      maxBackupPosters: 4,
      version: "1",
      maxPreReviewers: 4
    }
  };
}
