import { CommonPropsOnRows } from "../components/Actions/Actions.types";

const isEqual = require("lodash/isEqual");

export function ProcessCommonRowProps(items: CommonPropsOnRows[]): {
  poster: string | null;
  additionalPosters: string[] | null;
  reviewer: string[] | null;
  isPosted: boolean;
} {
  const result: {
    poster: string | null;
    additionalPosters: string[] | null;
    reviewer: string[] | null;
    isPosted: boolean;
  } = {
    poster: null,
    additionalPosters: null,
    reviewer: null,
    isPosted: false
  };

  if (items.length === 0) {
    return result;
  }

  result.poster = processPoster(items);
  result.additionalPosters = processAdditionalPosters(items);
  result.reviewer = processReviewer(items);
  result.isPosted = items.some((item) => item.isPosted || item.isReverse);

  return result;
}

export function processAdditionalPosters(items: CommonPropsOnRows[]): string[] | null {
  const firstItem = items[0];
  const isSame = items.every((item) => {
    if (Array.isArray(item.additionalPosters) && Array.isArray(firstItem.additionalPosters)) {
      return isEqual(item.additionalPosters, firstItem.additionalPosters);
    }
    return item.additionalPosters === firstItem.additionalPosters;
  });

  if (isSame) {
    return Array.isArray(firstItem.additionalPosters)
      ? (firstItem.additionalPosters as string[] | null)
      : firstItem.additionalPosters
      ? [firstItem.additionalPosters as string]
      : null;
  }

  return null;
}

export function processPoster(items: CommonPropsOnRows[]): string | null {
  const firstItem = items[0];
  const isSamePoster = items.every((item) => item.poster === firstItem.poster);
  const isSameRequestor = items.every((item) => item.requestor === firstItem.requestor);
  if (isSamePoster && firstItem.poster) {
    return firstItem.poster;
  }
  if (isSameRequestor && firstItem.requestor) {
    return firstItem.requestor;
  }
  return null;
}

export function processReviewer(items: CommonPropsOnRows[]): string[] | null {
  const firstItem = items[0];
  const isSame = items.every((item) => {
    if (Array.isArray(item.reviewer) && Array.isArray(firstItem.reviewer)) {
      return isEqual(item.reviewer, firstItem.reviewer);
    }
    return item.reviewer === firstItem.reviewer;
  });

  if (isSame) {
    return Array.isArray(firstItem.reviewer)
      ? (firstItem.reviewer as string[] | null)
      : [firstItem.reviewer as string] || null;
  }

  return null;
}
