import { DomainDataEnum, DomainDataObjects } from "../../Shared/contexts/JEMContext/JEMContext.domainData.types";

export class LineAmountFormat {
  public static defaultLineAmountFormat = 2;
  public static GetLineAmountFormat(
    domainData: Pick<DomainDataObjects, DomainDataEnum.CurrencyCodes>,
    currency: string
  ): number {
    for (const currencyCodeObj of domainData.CurrencyCodes) {
      if (currencyCodeObj.currencyCode === currency) {
        return currencyCodeObj.currencyFormat;
      }
    }
    return 2;
  }
}
