import {
  ComboBox,
  IComboBox,
  IComboBoxOption,
  Label,
  Shimmer,
  ShimmerElementsGroup,
  ShimmerElementType,
  Text
} from "@fluentui/react";
import React, { useContext, useEffect } from "react";

import { JemConfiguration } from "../../../JemConfiguration";
import { FCWChecklistModel, FetchUserChecklists } from "../../../GeneralLedger/utilities/FetchUserChecklists";
import { FiscalPeriodStringTransformations } from "../../../GeneralLedger/utilities/FiscalPeriodStringTransformations";
import { convertToChecklistsDropdownOptions } from "../../contexts/JEMContext/utilities/DomainData.utils";
import { UserContext } from "../../contexts/UserContext/UserContext";
import { ForceMaxWidthAndHeightOnDropdownStyle } from "../../utilities/FluentUIHacks";
import { useQuery } from "../../hooks/useQuery";

const isEqual = require("lodash/isEqual");

export interface JEMChecklistDropdownProps {
  configuration: JemConfiguration["GeneralLedgerApi"];
  fiscalYearFiscalPeriod: string;
  selectedChecklist: FCWChecklistModel | null;
  onChecklistChange: (checklist: FCWChecklistModel) => void;
  onNewChecklists: (checklists: FCWChecklistModel[]) => void;
  disabled: boolean;
  errorMessage?: string;
  label?: string;
}

let JEMChecklistDropdown: React.FC<JEMChecklistDropdownProps> = (props) => {
  const userContext = useContext(UserContext);

  const fyfp = FiscalPeriodStringTransformations.FiscalPeriodStringToFiscalYearAndFiscalMonth(
    props.fiscalYearFiscalPeriod
  );
  const fy = isNaN(fyfp.fiscalYear) ? "" : fyfp.fiscalYear.toString();
  const fp = isNaN(fyfp.fiscalMonth) ? "" : fyfp.fiscalMonth.toString();

  const {
    isLoading,
    isFetching,
    error,
    data: currentChecklists
  } = useQuery({
    queryKey: ["checklists", fy, fp],
    queryFn: async (opts) => {
      //get fy fp from opts
      const key = opts.queryKey as [string, string, string];
      const fy = key[1] as string;
      const fp = key[2] as string;

      const checklists = await FetchUserChecklists(props.configuration, fy, fp, userContext.accessToken);
      return checklists;
    },
    enabled: !props.fiscalYearFiscalPeriod || isNaN(fyfp.fiscalMonth) || isNaN(fyfp.fiscalYear) ? false : true
  });

  useEffect(() => {
    if (currentChecklists && Array.isArray(currentChecklists)) {
      props.onNewChecklists(currentChecklists);
    }
  }, [currentChecklists]);

  if (isLoading || isFetching)
    return (
      <>
        <Label>{props.label ? props.label : "Checklist"}</Label>
        <Shimmer
          customElementsGroup={
            <ShimmerElementsGroup shimmerElements={[{ type: ShimmerElementType.line, width: "100%", height: 32 }]} />
          }
        ></Shimmer>
      </>
    );

  let errorMessage: undefined | string = props.errorMessage;
  if (error && errorMessage === undefined) {
    if ("message" in (error as any)) {
      errorMessage = "Error fetching checklists: " + (error as unknown as { message: string }).message;
    } else {
      errorMessage = "Unknown error fetching checklists";
    }
  }

  const checklistsForDropdown = convertToChecklistsDropdownOptions(currentChecklists || []);
  if (props.selectedChecklist) {
    const selectedRefguid = props.selectedChecklist.checklistRefGuid;
    const selectedChecklist = checklistsForDropdown.find((c) => c.key === selectedRefguid);
    if (!selectedChecklist) {
      checklistsForDropdown.push({
        key: props.selectedChecklist.checklistRefGuid,
        text: props.selectedChecklist.checklistName,
        data: props.selectedChecklist
      });
    }
  }

  if (checklistsForDropdown.length === 0 && errorMessage === undefined) {
    errorMessage = "Error: No Checklists found";
  }

  return (
    <>
      <ComboBox
        label={props.label ? props.label : "Checklist"}
        onChange={(event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
          if (option && currentChecklists) {
            // find option in currentChecklists
            const selCheck = currentChecklists.find((c) => c.checklistRefGuid === option.key);
            if (selCheck) {
              props.onChecklistChange(selCheck);
            }
          }
        }}
        allowFreeform={true}
        autoComplete="on"
        options={checklistsForDropdown}
        onRenderOption={(option) => {
          return (
            <div>
              <Text
                variant="medium"
                style={{
                  display: "inline-block"
                }}
              >
                {option.text}
              </Text>
            </div>
          );
        }}
        styles={ForceMaxWidthAndHeightOnDropdownStyle({
          width: "100%",
          height: "400px"
        })}
        selectedKey={props.selectedChecklist ? props.selectedChecklist.checklistRefGuid : undefined}
        disabled={props.disabled}
        errorMessage={props.disabled ? "" : errorMessage}
      ></ComboBox>
    </>
  );
};

JEMChecklistDropdown.displayName = "JEMChecklistDropdown";

JEMChecklistDropdown = React.memo(JEMChecklistDropdown, (prevProps, nextProps) => {
  if (prevProps.disabled !== nextProps.disabled) return false;
  if (prevProps.fiscalYearFiscalPeriod !== nextProps.fiscalYearFiscalPeriod) return false;
  if (!isEqual(prevProps.selectedChecklist, nextProps.selectedChecklist)) return false;
  if (prevProps.errorMessage !== nextProps.errorMessage) return false;
  return true;
});

export { JEMChecklistDropdown };
