import { CoherencePanel, CoherencePanelSize } from "@coherence-design-system/controls";
import React from "react";
import ActionButtons from "../../copiedItems/ActionButtons";
import { JEMChecklistDashboardRow } from "../../shared/JEMChecklistDashboardRow";

interface IJEMChecklistEditPanelProps {
  checklistItem: JEMChecklistDashboardRow;
  onClosePanel: () => void;
  onSaveItem: (editedItem: JEMChecklistDashboardRow) => void;
}

const JEMChecklistEditPanel = (props: IJEMChecklistEditPanelProps): JSX.Element => {
  const { checklistItem, onClosePanel, onSaveItem } = props;
  const onHandleSubmit = () => {
    //do something with form data and call onSaveItem
    onSaveItem(checklistItem);
  };
  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.small}
      titleText="Edit checklist"
      isOpen
      onDismiss={onClosePanel}
      hasCloseButton
      closeButtonAriaLabel="Close edit checklist"
      onRenderFooter={(): JSX.Element => (
        <ActionButtons
          mutationLoading={false}
          closePanel={onClosePanel}
          handleSubmit={onHandleSubmit}
          saveLabel="Save"
          saveTitle="Save"
          cancelLabel="Cancel"
          cancelTitle="Cancel"
        />
      )}
    >
      {checklistItem.ChecklistId}
    </CoherencePanel>
  );
};
export default JEMChecklistEditPanel;
