import { OrderedMap } from "immutable";
import {
  ApiError,
  ApiParsingUtilities,
  GetClassification,
  GetDraftStatusString,
  getRequest,
  getValidUrl,
  GLDashboardRow,
  IIndexedTile,
  IUserProviderState,
  JemConfiguration,
  sanitizeDashboardRow,
  SanitizedDashboardRow,
  SanitizedDraftDashboardRow,
  SanitizedPreReviewDashboardRow
} from "@jem/components";
import {
  convertToTilesInSpecificOrder,
  initialTiles
} from "../../../GeneralLedger/components/GLDashboard/GLDashboard.Tiles";
import {
  GLTileNames,
  getDashboardDisplayNames,
  getGridNamesForAPI,
  ITile,
  GLTilesAndCount
} from "../../../GeneralLedger/components/GLDashboard/GLDashboard.Requests";

export const TileNames = Object.freeze(GLTileNames);

export async function getDashboardCounts(
  configuration: JemConfiguration["GeneralLedgerApi"],
  getTokenFn: IUserProviderState["accessToken"],
  userAlias: string
): Promise<ITile[]> {
  const endpoint = `${configuration.baseApiUrl}${configuration.endpoints.getUserDashboardCounts}`.replace(
    "{userAlias}",
    userAlias
  );
  const endpointUrl = getValidUrl(endpoint);
  const response = await getRequest<ITile[]>(endpointUrl, getTokenFn);
  return response as ITile[];
}

export async function fetchDashboardCounts(
  configuration: JemConfiguration["GeneralLedgerApi"],
  getTokenFn: IUserProviderState["accessToken"],
  userAlias: string
): Promise<OrderedMap<string, IIndexedTile>> {
  const tileCounts = await getDashboardCounts(configuration, getTokenFn, userAlias);
  if (!tileCounts) {
    throw new ApiError("Journal Entries not found");
  }
  if (tileCounts.length === 0) {
    return initialTiles;
  }
  const tiles = tileCounts.reduce(
    (ctr, tile) => Object.assign(ctr, { [getDashboardDisplayNames(tile.tileName)]: tile.count }),
    {} as GLTilesAndCount
  );

  return convertToTilesInSpecificOrder(tiles);
}

export function sanitizeDraftDashboardRow(row: any): SanitizedDraftDashboardRow {
  let poster = "";
  let additionalPosters = "";
  if (row.additionalPosters.length !== 0 || row.additionalPosters !== "") {
    poster = row.additionalPosters.includes(";")
      ? (row.additionalPosters.split(";").shift() as string)
      : row.additionalPosters;
    additionalPosters =
      row.additionalPosters.split(";").length > 1 ? row.additionalPosters.split(";").slice(1).join(";") : "";
  }

  const draftName = ApiParsingUtilities.addDefault(row.draftName, "");
  const newSanitizedDraftDashboardRow: SanitizedDraftDashboardRow = {
    idForUI: draftName,
    jeId: ApiParsingUtilities.addDefault(row.id, ""),
    poster: ApiParsingUtilities.addDefault(poster, ""),
    additionalPosters: ApiParsingUtilities.addDefault(additionalPosters, "")
      .split(";")
      .filter((x) => x),
    attachmentStatus: ApiParsingUtilities.addDefault(row.attachmentStatus, ""),
    attachments: ApiParsingUtilities.addDefault(row.attachments, ""),
    checklistRefguid: ApiParsingUtilities.addDefault(row.checklistRefguid, ""),
    companyCode: ApiParsingUtilities.addDefault(row.multipleCompanyCodes, "")
      .split(",")
      .filter((x) => x),
    description: ApiParsingUtilities.addDefault(row.description, ""),
    draftName: draftName,
    fiscalPeriod: ApiParsingUtilities.addDefault(row.fiscalPeriod, ""),
    format: GetClassification(row.postingType),
    isBPOEntry: ApiParsingUtilities.addDefault(row.isBPOEntry, ""),
    jeRefNo: ApiParsingUtilities.addDefault(row.jeRefNo, ""),
    lastComments: ApiParsingUtilities.addDefault(row.lastComments, ""),
    modifiedOn: ApiParsingUtilities.parseDate(row.modifiedOn),
    postingType: ApiParsingUtilities.addDefault(row.postingType, ""),
    refGuid: ApiParsingUtilities.addDefault(row.refGuid, ""),
    reoccuring: ApiParsingUtilities.addDefault(row.reoccuring, ""),
    reviewer: ApiParsingUtilities.addDefault(row.reviewer, "")
      .split(";")
      .filter((x) => x),
    status: GetDraftStatusString(row.status),
    statusCode: row.status as number,
    templateid: ApiParsingUtilities.addDefault(row.templateid, ""),
    id: ApiParsingUtilities.addDefault(row.id, ""),
    postingPeriod: ApiParsingUtilities.addDefault(row.postingPeriod, ""),
    fiscalYear: ApiParsingUtilities.addDefault(row.fiscalYear, ""),
    rowWeightage: row.rowWeightage,
    isDraft: true,
    isPosted: false,
    procStatType: "Information"
  };

  return newSanitizedDraftDashboardRow;
}

export function sanitizePreReviewDashboardRow(row: any): SanitizedPreReviewDashboardRow {
  const draftName = ApiParsingUtilities.addDefault(row.draftName, "");
  const newSanitizedPreReviewDashboardRow: SanitizedPreReviewDashboardRow = {
    idForUI: draftName,
    jeId: ApiParsingUtilities.addDefault(row.id, ""),
    poster: ApiParsingUtilities.addDefault(row.poster, ""),
    additionalPosters: ApiParsingUtilities.addDefault(row.additionalPosters, "")
      .split(";")
      .filter((x) => x),
    attachmentStatus: ApiParsingUtilities.addDefault(row.attachmentStatus, ""),
    attachments: ApiParsingUtilities.addDefault(row.attachments, ""),
    checklistRefguid: ApiParsingUtilities.addDefault(row.checklistRefguid, ""),
    companyCode: ApiParsingUtilities.addDefault(row.multipleCompanyCodes, "")
      .split(",")
      .filter((x) => x),
    description: ApiParsingUtilities.addDefault(row.description, ""),
    draftName: draftName,
    fiscalPeriod: ApiParsingUtilities.addDefault(row.fiscalPeriod, ""),
    format: GetClassification(row.postingType),
    isBPOEntry: ApiParsingUtilities.addDefault(row.isBPOEntry, ""),
    jeRefNo: ApiParsingUtilities.addDefault(row.jeRefNo, ""),
    lastComments: ApiParsingUtilities.addDefault(row.lastComments, ""),
    modifiedOn: ApiParsingUtilities.parseDate(row.modifiedOn),
    postingType: ApiParsingUtilities.addDefault(row.postingType, ""),
    refGuid: ApiParsingUtilities.addDefault(row.refGuid, ""),
    reoccuring: ApiParsingUtilities.addDefault(row.reoccuring, ""),
    reviewer: ApiParsingUtilities.addDefault(row.reviewer, "")
      .split(";")
      .filter((x) => x),
    status: GetDraftStatusString(row.status),
    statusCode: row.status as number,
    templateid: ApiParsingUtilities.addDefault(row.templateid, ""),
    id: ApiParsingUtilities.addDefault(row.id, ""),
    postingPeriod: ApiParsingUtilities.addDefault(row.postingPeriod, ""),
    fiscalYear: ApiParsingUtilities.addDefault(row.fiscalYear, ""),
    rowWeightage: row.rowWeightage,
    isDraft: true,
    isPosted: false,
    procStatType: "Information",
    preReviewer: ApiParsingUtilities.addDefault(row.preReviewer, "")
      .split(";")
      .filter((x) => x),
    statusName: ApiParsingUtilities.addDefault(row.statusName, "")
  };
  return newSanitizedPreReviewDashboardRow;
}

export async function getUserDashboardData(
  configuration: JemConfiguration["GeneralLedgerApi"],
  getTokenFn: IUserProviderState["accessToken"],
  gridName: GLTileNames,
  userAlias: string,
  isAdmin: string
): Promise<{
  gridName: GLTileNames;
  items: GLDashboardRow[];
}> {
  const endpoint = `${configuration.baseApiUrl}${configuration.endpoints.getUserDashboard}`
    .replace("{tileName}", getGridNamesForAPI(gridName))
    .replace("{userAlias}", userAlias)
    .replace("{isAdmin}", `?isAdmin=${isAdmin}`);

  const endpointUrl = getValidUrl(endpoint);
  const response = await getRequest<any[]>(endpointUrl, getTokenFn);
  if (!response) {
    throw new ApiError("No response from server");
  }

  if (gridName === "My Draft JEs") {
    const sanitizedDraftGLResult = await sanitizeDashboardDraftResultSet(response);
    return { gridName, items: sanitizedDraftGLResult as GLDashboardRow[] };
  } else if (
    gridName === "Pre-Review JE - Pre-Reviewer Action Required" ||
    gridName === "Poster - PreReview Requested"
  ) {
    const sanitizedDraftGLResult = await sanitizePreReviewDashboardResultSet(response);
    return { gridName, items: sanitizedDraftGLResult as GLDashboardRow[] };
  } else {
    const sanitizedDraftGLResult = await sanitizeDashboardResultSet(response);
    return {
      gridName,
      items: sanitizedDraftGLResult as GLDashboardRow[]
    };
  }
}

export function sanitizeDashboardResultSet(results: any[]): Promise<SanitizedDashboardRow[]> {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      try {
        const sanitizedResults = results.map((row) => sanitizeDashboardRow(row));
        resolve(sanitizedResults);
      } catch (error) {
        reject(error);
      }
    }, 1);
  });
}

export function sanitizeDashboardDraftResultSet(results: any[]): Promise<SanitizedDraftDashboardRow[]> {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      try {
        const sanitizedResults = results.map((row) => sanitizeDraftDashboardRow(row));

        resolve(sanitizedResults);
      } catch (error) {
        reject(error);
      }
    }, 1);
  });
}
export function sanitizePreReviewDashboardResultSet(results: any[]): Promise<SanitizedPreReviewDashboardRow[]> {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      try {
        const sanitizedResults = results.map((row) => sanitizePreReviewDashboardRow(row));

        resolve(sanitizedResults);
      } catch (error) {
        reject(error);
      }
    }, 1);
  });
}
