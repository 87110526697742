import { ISourceSelectorState } from "../../../Shared/components/DashboardSourceSelector/DashboardSourceSelector";
import { v4 as uuidv4 } from "uuid";
import { IRequestOptions, AuthorizationToken } from "../../../Shared/utilities/RequestUtilities";
import { ApiParsingUtilities } from "../../../Shared/utilities/ApiParsingUtilities";
import { ApiConfiguration } from "../../../Shared/utilities/ApiRules";

export interface AutomatedDashboardConfiguration extends ApiConfiguration {
  endpoints: {
    s2SGetDashboard: string;
    s2sRetryAction: string;
    s2SGetJE: string;
  };
}

export type statusType =
  | "Draft"
  | "Sent To Poster"
  | "Draft (Clarification needed)"
  | "Draft (Recalled)"
  | "Delete"
  | "Signoff Not Req'd"
  | "Unknown"
  | "Validated"
  | "Submitted"
  | "Failed in SAP"
  | "Voided and Retried";

export const ALL_STATUS: statusType[] = [
  "Draft",
  "Sent To Poster",
  "Draft (Clarification needed)",
  "Draft (Recalled)",
  "Delete",
  "Signoff Not Req'd",
  "Unknown",
  "Validated",
  "Submitted",
  "Failed in SAP",
  "Voided and Retried"
];

export interface AutomatedJeDashboardElement {
  jeId: string;
  jeDocNum: string;
  jeName: string;
  jeStatusId: string;
  jeStatus: string;
  companyCode: string;
  dueDate: string;
  jeReason: string;
  jeType: string;
  jeHeaderText: string;
  sapAmount: string;
  sourceAmount: string;
  differenceAmount: string;
  refguid: string;
  jemUrl: string | null;
  fileName: string;
  submittedTime: string;
  rowWeightage: number;
  jeSAPErrorInfo: JeErrorData | null;
  retryPerformedBy: string;
  retryPerformedDate: string;
  postingType: string;
}

export interface DashboardApiPayload {
  jeId: number;
  jeDocNum: string | null | "";
  jeName: string | null | "";
  jeStatusId: number;
  jeStatus: string | "";
  companyCode: string | null | "";
  dueDate: string | null | "";
  jeReason: string | null | "";
  jeType: string | null | "";
  jeHeaderText: string | null | "";
  sapAmount: number | null | 0;
  sourceAmount: number | null | 0;
  differenceAmount: number | null | 0;
  refguid: string | null;
  jemUrl: string | null;
  fileName: string | null;
  submittedTime: string | null | "";
  actionRowWeightage: number | null | 0;
  jeSAPErrorInfo: JeErrorData | null;
  retryPerformedBy: string | null | "";
  retryPerformedDate: string | null | "";
  postingType: string | null | "";
}

export interface JeErrorData {
  jeHeaderError: string | null | "";
  jeLineItemErrors: LineItemErrorInfo[] | null;
}

export interface LineItemErrorInfo {
  lineNumber: string | null;
  description: string | null;
}

interface IDashboardEndpointPayload {
  appId: string;
  autoApprovedJeCount: number;
  failedJeCount: number;
  s2SJes: DashboardApiPayload[];
}

// todo: once we have at least 3 types of request (get post patch delete) for jem convert this into a set of utilities
const getDashboardData = async (
  args: ISourceSelectorState,
  configuration: AutomatedDashboardConfiguration,
  opts?: IRequestOptions<AutomatedJeDashboardElement[]>
) => {
  const headers = {
    accept: "application/json",
    requestid: uuidv4()
  };
  if (opts?.getTokenFn && opts?.getTokenFn instanceof Function) {
    (headers as any).Authorization = await AuthorizationToken(opts.getTokenFn);
  }
  const fullEndpoint =
    configuration.baseApiUrl +
    configuration.endpoints.s2SGetDashboard
      .replace("{fiscalYear}", `${args.fiscalYear}`)
      .replace("{fiscalPeriod}", `${args.fiscalPeriod}`)
      .replace("{appId}", `${args.appId}`);
  const resp = await fetch(fullEndpoint, {
    headers,
    referrerPolicy: "strict-origin-when-cross-origin",
    method: "GET",
    mode: "cors"
  });
  const json = await resp.json();
  return json as IDashboardEndpointPayload;
};

export function singleS2SJeToElement(s2sJe: DashboardApiPayload): AutomatedJeDashboardElement {
  const newEl: AutomatedJeDashboardElement = {
    jeId: ApiParsingUtilities.addDefault(s2sJe.jeId),
    jeDocNum: ApiParsingUtilities.addDefault(s2sJe.jeDocNum),
    jeName: ApiParsingUtilities.addDefault(s2sJe.jeName),
    jeStatusId: ApiParsingUtilities.addDefault(s2sJe.jeStatusId),
    jeStatus: ApiParsingUtilities.addDefault(s2sJe.jeStatus),
    companyCode: ApiParsingUtilities.addDefault(s2sJe.companyCode),
    jeReason: ApiParsingUtilities.addDefault(s2sJe.jeReason),
    jeType: ApiParsingUtilities.addDefault(s2sJe.jeType),
    jeHeaderText: ApiParsingUtilities.parseJeHeader(s2sJe.jeHeaderText),
    dueDate: ApiParsingUtilities.parseDate(s2sJe.dueDate),
    sapAmount: ApiParsingUtilities.parseNumberWithJeStatus(s2sJe.sapAmount, s2sJe.jeStatus),
    sourceAmount: ApiParsingUtilities.parseNumberWithJeStatus(s2sJe.sourceAmount, s2sJe.jeStatus),
    differenceAmount: ApiParsingUtilities.parseNumberWithJeStatus(s2sJe.differenceAmount, s2sJe.jeStatus),
    refguid: ApiParsingUtilities.addDefault(s2sJe.refguid),
    jemUrl: s2sJe.jemUrl,
    fileName: ApiParsingUtilities.addDefault(s2sJe.fileName),
    // submitted time gets parsed to date with timestamp so it can be sorted
    submittedTime: ApiParsingUtilities.parseDateWithTimeStamp(s2sJe.submittedTime),
    rowWeightage: s2sJe.actionRowWeightage || 0,
    jeSAPErrorInfo: s2sJe.jeSAPErrorInfo,
    retryPerformedBy: ApiParsingUtilities.addDefault(s2sJe.retryPerformedBy),
    retryPerformedDate: ApiParsingUtilities.parseDateWithTimeStamp(s2sJe.retryPerformedDate),
    postingType: ApiParsingUtilities.addDefault(s2sJe.postingType)
  };
  return newEl;
}

export const getAutomatedJeDashboardData = async (
  args: ISourceSelectorState,
  configuration: AutomatedDashboardConfiguration,
  opts?: IRequestOptions<AutomatedJeDashboardElement[]>
): Promise<AutomatedJeDashboardElement[]> => {
  try {
    if (opts?.mockFn) {
      return opts.mockFn(args);
    }
    const apiData = await getDashboardData(args, configuration, opts);
    if (!apiData.s2SJes) {
      return [];
    }
    const dataForDashboard = apiData.s2SJes.map(singleS2SJeToElement);
    return dataForDashboard;
  } catch (e) {
    console.error(e);
    return [];
  }
};
