import { useSetTimeout } from "@fluentui/react-hooks";
import { JemConfiguration, PageHeading, PageStyles } from "@jem/components";
import React, { useEffect } from "react";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import JEMChecklistDashboardHeader, {
  JEMChecklistDashboardOptions
} from "../../components/JEMChecklistDashboard/JEMChecklistDashboard.Header";
import JEMChecklistDashboardByCompanyCode from "../../components/JEMChecklistDashboardByCompanyCode/JEMChecklistDashboardByCompanyCode";
import JEMChecklistDashboardByOpsDetail, {
  JEMChecklistDashboardByOpsDetailProps
} from "../../components/JEMChecklistDashboardByOpsDetail/JEMChecklistDashboardByOpsDetail";

export interface JEMChecklistDashboardProps {
  configuration: JemConfiguration["GeneralLedgerApi"];
  attachmentsConfiguration: JemConfiguration["DocumentsApi"];
  fcwCreateTaskUrl: JemConfiguration["FCWNewJemChecklistLink"];
}

const JEMChecklistDashboardPage: React.FC<JEMChecklistDashboardProps> = (props) => {
  // when are the following going to be undefined? on load only, before parsing params
  // when is it going to be null? after parsing params, when we can't make a full set of settings
  // when is it going to be an object? when the params are set and we can make a full set
  const [searchByOpsDetailSettings, setSearchByOpsDetailSettings] = React.useState<
    | {
        tabName: string;
        fiscalMonth: string;
        fiscalYear: string;
        opsDetailId: string;
        opsDetailName: string;
      }
    | null
    | undefined
  >(undefined);
  const [searchByCompanyCodesSettings, setSearchByCompanyCodesSettings] = React.useState<
    | {
        tabName: string;
        fiscalMonth: string;
        fiscalYear: string;
        companyCodes: string[];
      }
    | null
    | undefined
  >(undefined);

  const navigate = useNavigate();
  const { setTimeout } = useSetTimeout();

  const [searchParams] = useSearchParams();
  const [currentTab, setCurrentTab] = React.useState<JEMChecklistDashboardOptions>(
    (searchParams.get("tabName") as JEMChecklistDashboardOptions) || JEMChecklistDashboardOptions.SearchByOpsDetail
  );

  useEffect(
    function searchParamsChanged() {
      const fp = searchParams.get("fiscalMonth");
      const fy = searchParams.get("fiscalYear");
      const tabName = searchParams.get("tabName") || JEMChecklistDashboardOptions.SearchByOpsDetail;
      if (tabName === "opsDetail") {
        const opsDetailId = searchParams.get("opsDetailId");
        const currentOpDetailsSettings =
          fp && fy && opsDetailId !== null
            ? { tabName, fiscalMonth: fp, fiscalYear: fy, opsDetailId, opsDetailName: "" }
            : null;
        setSearchByOpsDetailSettings(currentOpDetailsSettings);
      } else {
        const companyCodes = searchParams.get("companyCodes");
        const currentCompanyCodesSettings =
          fp && fy
            ? { tabName, fiscalMonth: fp, fiscalYear: fy, companyCodes: companyCodes ? companyCodes.split(",") : [] }
            : null;
        setSearchByCompanyCodesSettings(currentCompanyCodesSettings);
      }
    },
    [searchParams]
  );

  const searchByDetailsOptionsChanged: JEMChecklistDashboardByOpsDetailProps["onSearchOptionsChanged"] = (
    newSearchOptions
  ) => {
    setTimeout(() => {
      navigate({
        search: `?${createSearchParams({
          tabName: JEMChecklistDashboardOptions.SearchByOpsDetail,
          fiscalYear: newSearchOptions.fiscalYear,
          fiscalMonth: newSearchOptions.fiscalMonth,
          opsDetailId: newSearchOptions.opsDetailId,
          opsDetailName: newSearchOptions.opsDetailName
        })}`
      });
    }, 100);
  };
  const searchByCompanyCodesChanged = (newSearchOptions: {
    fiscalMonth: string;
    fiscalYear: string;
    companyCodes: string[];
  }) => {
    setTimeout(() => {
      navigate({
        search: `?${createSearchParams({
          tabName: JEMChecklistDashboardOptions.SearchByCompanyCode,
          fiscalYear: newSearchOptions.fiscalYear,
          fiscalMonth: newSearchOptions.fiscalMonth,
          companyCodes: newSearchOptions.companyCodes.join(",")
        })}`
      });
    }, 100);
  };

  return (
    <div className={PageStyles.rootDiv}>
      <PageHeading>
        <JEMChecklistDashboardHeader
          configuration={props.configuration}
          onTabChange={function (tab: JEMChecklistDashboardOptions): void {
            setCurrentTab(tab);
            if (tab === JEMChecklistDashboardOptions.SearchByCompanyCode) {
              const newParams = {
                tabName: JEMChecklistDashboardOptions.SearchByCompanyCode
              } as Record<string, string>;
              if (searchByCompanyCodesSettings) {
                newParams.fiscalYear = searchByCompanyCodesSettings.fiscalYear;
                newParams.fiscalMonth = searchByCompanyCodesSettings.fiscalMonth;
                newParams.companyCodes = searchByCompanyCodesSettings.companyCodes.join(",");
              }
              navigate({
                search: `?${createSearchParams(newParams)}`
              });
            } else if (tab === JEMChecklistDashboardOptions.SearchByOpsDetail) {
              const newParams = {
                tabName: JEMChecklistDashboardOptions.SearchByOpsDetail
              } as Record<string, string>;
              if (searchByOpsDetailSettings) {
                newParams.fiscalYear = searchByOpsDetailSettings.fiscalYear;
                newParams.fiscalMonth = searchByOpsDetailSettings.fiscalMonth;
                newParams.opsDetailId = searchByOpsDetailSettings.opsDetailId;
              }
              navigate({
                search: `?${createSearchParams(newParams)}`
              });
            }
          }}
          currentTab={currentTab}
        ></JEMChecklistDashboardHeader>
      </PageHeading>
      {currentTab === JEMChecklistDashboardOptions.SearchByOpsDetail ? (
        <JEMChecklistDashboardByOpsDetail
          configuration={props.configuration}
          attachmentsConfiguration={props.attachmentsConfiguration}
          fcwCreateTaskUrl={props.fcwCreateTaskUrl}
          searchOptions={searchByOpsDetailSettings}
          onSearchOptionsChanged={searchByDetailsOptionsChanged}
        />
      ) : (
        <JEMChecklistDashboardByCompanyCode
          configuration={props.configuration}
          attachmentsConfiguration={props.attachmentsConfiguration}
          searchOptions={searchByCompanyCodesSettings}
          onSearchOptionsChanged={searchByCompanyCodesChanged}
        />
      )}
    </div>
  );
};

JEMChecklistDashboardPage.displayName = "JEMChecklistDashboardPage";

export default JEMChecklistDashboardPage;
