import {
  IHCCContext,
  JemConfiguration,
  JemNotification,
  LoadingStatus,
  MockDataFn,
  RequireAtLeastOne,
  UserContext
} from "@jem/components";
import { useContext, useEffect, useReducer } from "react";

import { getIhccDashboardData } from "./IHCCDashboard.requests";
import { IHCCDashboardData } from "./IHCCDashboard.types";

export interface UseIHCCDashboardState {
  actions: IHCCDashboardData["actions"] | null;
  rows: IHCCDashboardData["rows"] | null;
  notification: JemNotification | null;
  loadingStatus: LoadingStatus;
}

export interface UseIHCCDashboardData {
  actions: IHCCDashboardData["actions"] | null;
  rows: IHCCDashboardData["rows"] | null;
  notification: JemNotification | null;
  loadingStatus: LoadingStatus;
}

export interface UseIHCCDashboardOptions {
  mockDashboardDataFn?: MockDataFn<IHCCDashboardData>;
  apiConfiguration: JemConfiguration["IhccApi"];
}

const useIhccDashboardInitialState: UseIHCCDashboardState = {
  actions: null,
  rows: null,
  notification: null,
  loadingStatus: LoadingStatus.Idle
};

type UseIHCCDashboardStateReducer = (
  state: UseIHCCDashboardState,
  changes: RequireAtLeastOne<UseIHCCDashboardState>
) => UseIHCCDashboardState;

const UseIHCCDashboardApi = (opts: UseIHCCDashboardOptions): [UseIHCCDashboardData] => {
  const userContext = useContext(UserContext);
  const ihccContext = useContext(IHCCContext);

  const [state, dispatch] = useReducer<UseIHCCDashboardStateReducer>(
    (previousState, changes) => ({
      ...previousState,
      ...changes
    }),
    useIhccDashboardInitialState
  );

  useEffect(() => {
    if (
      ihccContext.initInfo.status !== LoadingStatus.Pending &&
      ihccContext.initInfo.status !== LoadingStatus.Idle &&
      state.loadingStatus !== LoadingStatus.Pending
    ) {
      dispatch({
        loadingStatus: LoadingStatus.Pending
      });
      const getUserData = async () => {
        try {
          const dashboardData = await getIhccDashboardData(
            {
              configuration: opts.apiConfiguration
            },
            {
              getTokenFn: userContext.accessToken,
              mockFn: opts.mockDashboardDataFn
            }
          );
          dispatch({
            actions: dashboardData.actions,
            rows: dashboardData.rows,
            loadingStatus: LoadingStatus.Resolved
          });
        } catch (err) {
          dispatch({
            loadingStatus: LoadingStatus.Rejected
          });
        }
      };
      getUserData();
    }
  }, [ihccContext.initInfo]);

  return [
    {
      actions: state.actions,
      rows: state.rows,
      notification: null,
      loadingStatus: state.loadingStatus
    }
  ];
};

export { UseIHCCDashboardApi };
