import { Dropdown, IDropdownOption, IDropdownStyles, IStackTokens, Stack } from "@fluentui/react";
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { getTenantsFromApi } from "./TenantDropdown.requests";
import { Tenant } from "./TenantDropdown.types";

const noMargins = {
  margin: "0px",
  selectors: {
    ":hover": {
      margin: "0px"
    },
    ":focus": {
      margin: "0px"
    }
  }
};

const dropdownStyles: Partial<IDropdownStyles> = {
  dropdown: { width: "180px" },
  root: {
    display: "flex",
    flexDirection: "horizontal",
    flexWrap: "nowrap",
    justifyContent: "center"
  },
  dropdownItem: noMargins,
  dropdownItemSelected: noMargins,
  dropdownItemDisabled: noMargins,
  dropdownItemSelectedAndDisabled: noMargins,
  label: {
    paddingRight: "8px"
  }
};

const horizontalGapStackTokens: IStackTokens = {
  childrenGap: 24,
  padding: "16px 0 24px 0"
};

interface IStateBundle extends IDropdownOption {
  appId: string;
}

export interface ITenantSelectorState {
  appId: string;
}

export interface ITenantDropdown {
  ref: {
    getState: () => ITenantSelectorState | null;
  };
  props: {
    type: "Royalties" | "GSR";
    mockFn?: () => Promise<Tenant[]>;
    onChange?: (state: ITenantSelectorState) => void;
  };
}

export const TenantDropdown = forwardRef<ITenantDropdown["ref"], ITenantDropdown["props"]>((props, ref) => {
  const [tenants, setTenants] = useState<IStateBundle[]>([]);
  const [selectedTenant, setSelectedTenant] = useState<IStateBundle | null>(null);

  useImperativeHandle(ref, () => ({
    getState() {
      if (selectedTenant) {
        const theTenant = tenants.filter((tenant) => tenant.key === selectedTenant.key);
        return {
          appId: theTenant[0].appId
        };
      } else {
        return null;
      }
    }
  }));

  useEffect(() => {
    let cancelled = false;
    const getTenants = async () => {
      const tenants = props.mockFn ? await props.mockFn() : await getTenantsFromApi();
      const tenantsForDropdown = tenants.map((tenant, i) => {
        return {
          key: `${i}`,
          text: tenant.name,
          appId: tenant.appId
        };
      });
      if (!cancelled) {
        setTenants(tenantsForDropdown);
        setSelectedTenant(tenantsForDropdown[0]);
        if (props.onChange) {
          props.onChange(tenantsForDropdown[0]);
        }
      }
    };
    getTenants();
    return () => {
      cancelled = true;
    };
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onChange = (type: string) => (_: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
    if (option) {
      setSelectedTenant(option as IStateBundle);
      if (props.onChange) {
        props.onChange(option as IStateBundle);
      }
    }
  };

  return (
    <Stack horizontal disableShrink tokens={horizontalGapStackTokens}>
      {selectedTenant !== null ? (
        <Dropdown
          placeholder="Select a Tenant"
          label="Tenant"
          options={tenants}
          styles={dropdownStyles}
          selectedKey={selectedTenant.key}
          onChange={onChange("tenant")}
        />
      ) : null}
    </Stack>
  );
});

export default TenantDropdown;
