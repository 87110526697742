import { CommandBar } from "@fluentui/react";
import React from "react";

export type DelegateReviewerActions = "Save" | "Cancel";

interface DelegateReviewersActionsProps {
  onAction: (actionName: DelegateReviewerActions) => void;
  disabledPage: boolean;
}

const DelegateReviewersActions: React.FC<DelegateReviewersActionsProps> = (props) => {
  const { onAction, disabledPage } = props;
  return (
    <CommandBar
      items={[
        {
          key: "save",
          name: "Save",
          iconProps: { iconName: "Save" },
          onClick: () => onAction("Save"),
          disabled: disabledPage
        },
        {
          key: "cancel",
          name: "Cancel",
          iconProps: { iconName: "Cancel" },
          onClick: () => onAction("Cancel"),
          disabled: disabledPage
        }
      ]}
      styles={{
        root: {
          paddingLeft: 0
        }
      }}
      ariaLabel={"Actions for Delegate Reviewers"}
      overflowButtonProps={{ ariaLabel: "More Actions" }}
    />
  );
};

DelegateReviewersActions.displayName = "DelegateReviewersActions";
export default DelegateReviewersActions;
