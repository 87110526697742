export function GetClassification(postingType: number): string {
  if (postingType == 1 || postingType == 4 || postingType == 8) {
    return "Regular";
  } else if (postingType == 3 || postingType == 5) {
    // Verifying the PostingType=5 to handle the Standard batch
    return "Batch";
  } else if (postingType == 7 || postingType == 9) {
    //Added for the new Bulk type JE.
    return "Bulk";
  }
  return "";
}
