import React from "react";
import { css } from "@emotion/css";
import { Spinner, SpinnerSize } from "@fluentui/react";

const loadingCss = ({ height }) => css`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: ${height ? height : "150px"};} ;
`;

export interface ILoadingSpinnerPros {
  label: string;
  height?: string;
}

const LoadingSpinner: React.FunctionComponent<ILoadingSpinnerPros> = (props) => {
  return (
    <div className={loadingCss({ height: props.height })}>
      <Spinner label={`${props.label}...`} ariaLive="polite" size={SpinnerSize.large} />
    </div>
  );
};
LoadingSpinner.displayName = "LoadingSpinner";
export { LoadingSpinner };
