import { CoherencePanel, CoherencePanelSize } from "@coherence-design-system/controls";
import React from "react";
import ActionButtons from "../../copiedItems/ActionButtons";
import { JEMChecklistDetailsRow } from "@jem/components";
import { useForm } from "react-hook-form";
import { Stack, Label, FocusZone, DetailsList, Text, DetailsListLayoutMode } from "@fluentui/react";
import InlineFormInputErrorMessage from "./InlineFormInputErrorMessage";

interface IJEMChecklistDetailsBulkDeletePanelProps {
  checklistItems: JEMChecklistDetailsRow[];
  checklistName: string;
  checklistPeriod: string;
  checklistRefGUID: string;
  onClosePanel: () => void;
  onSaveItem: (checklistItems: JEMChecklistDetailsRow[] | []) => void;
  loading?: boolean;
  error: string[];
}

const JEMChecklistDetailsBulkDeletePanel = (props: IJEMChecklistDetailsBulkDeletePanelProps): JSX.Element => {
  const { checklistItems, onClosePanel, onSaveItem, error, loading } = props;

  const form = useForm();
  const { handleSubmit } = form;

  const _columns = [
    { key: "column1", name: "JETaskName", fieldName: "jeTaskName", minWidth: 100, maxWidth: 200, isResizable: true },
    {
      key: "column2",
      name: "PublishedState",
      fieldName: "publishedState",
      minWidth: 100,
      maxWidth: 200,
      isResizable: true
    }
  ];

  const onHandleSubmit = () => {
    //do something with form data and call onSaveItem

    onSaveItem(checklistItems);
  };

  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.small}
      titleText="Bulk Delete JE Tasks"
      isOpen
      onDismiss={onClosePanel}
      hasCloseButton
      closeButtonAriaLabel="Close Bulk Delete JE Tasks"
      onRenderFooter={(): JSX.Element => (
        <ActionButtons
          disabled={checklistItems.length == 0 || loading}
          mutationLoading={false}
          closePanel={onClosePanel}
          handleSubmit={handleSubmit(onHandleSubmit)}
          saveLabel="Delete"
          saveTitle="Delete"
          cancelLabel="Cancel"
          cancelTitle="Cancel"
        />
      )}
    >
      <InlineFormInputErrorMessage errorMessages={error} />
      <form onSubmit={onHandleSubmit}>
        <Stack tokens={{ childrenGap: 20 }}>
          <span></span>
          <Label> Checklist {props.checklistName}</Label>
          <Label>Posting Period {props.checklistPeriod}</Label>

          {props.checklistItems.length > 0 ? (
            <>
              <Text
                variant="xLarge"
                style={{
                  color: "var(--accent-font-color, black)"
                }}
              >
                Checklist JE Tasks to be Deleted {/*{checklistItemsJeTasks}*/}
              </Text>
              <FocusZone>
                <div>
                  <DetailsList
                    items={checklistItems}
                    columns={_columns}
                    setKey="set"
                    layoutMode={DetailsListLayoutMode.justified}
                  />
                </div>
              </FocusZone>
            </>
          ) : (
            <Text
              variant="xLarge"
              style={{
                color: "var(--accent-font-color, black)"
              }}
            >
              No Items to show.
            </Text>
          )}
        </Stack>
      </form>
    </CoherencePanel>
  );
};
export default JEMChecklistDetailsBulkDeletePanel;
