import { JemConfiguration } from "../../../JemConfiguration";

export enum DomainDataEnum {
  JeTypes = "JeTypes",
  ReversalReasons = "ReversalReasons",
  JeCompanyCodes = "JeCompanyCodes",
  JeParameters = "JeParameters",
  JeStatus = "JeStatus",
  JeCategoryRules = "JeCategoryRules",
  JeReasonCodes = "JeReasonCodes",
  JeClarificationCodes = "JeClarificationCodes",
  JemTenantConfigs = "JemTenantConfigs",
  JeOperations = "JeOperations",
  StatusActions = "StatusActions",
  JeBatchFields = "JeBatchFields",
  JeDroplistValues = "JeDroplistValues",
  JeBusinessGroupInfo = "JeBusinessGroupInfo",
  BPOActionLinks = "BPOActionLinks",
  FileShareInfo = "FileShareInfo",
  AllowedFileExtensions = "AllowedFileExtensions",
  FiscalPeriods = "FiscalPeriods",
  FiscalPeriodsBPO = "FiscalPeriodsBPO",
  Tenant = "Tenant",
  CurrencyCodes = "CurrencyCodes",
  PostingDueDates = "PostingDueDates"
}

export interface FiscalPeriod {
  fiscalMonth: number;
  fiscalYear: number;
  startDate: string;
  endDate: string;
  calendarMonth: number;
  calendarYear: number;
}

export interface DomainDataObjects {
  [DomainDataEnum.JeTypes]: {
    code: string;
    name: string;
    description: string;
    jemSupports: boolean;
    sapModule: string;
  }[];

  [DomainDataEnum.ReversalReasons]: {
    code: string;
    name: string;
  }[];

  [DomainDataEnum.JeCompanyCodes]: {
    glCompanyCode: number;
    glCompanyName: string;
    currencyCode: string;
    isShadow: boolean;
    shadowNeedReview: boolean;
  }[];

  [DomainDataEnum.JeParameters]: {
    group: string;
    name: string;
    description: string | null;
    value: string;
    showUX: boolean;
    allowedValues: string | null;
    maxLengthAllowed: number | null;
  }[];

  [DomainDataEnum.JeStatus]: {
    statusId: number;
    statusCode: string;
    statusApplied: string;
  }[];

  [DomainDataEnum.JeCategoryRules]: {
    category: string;
    name: string;
    description: string;
    reviewRequired: boolean;
    reviewAttachPeriod: number;
    attachmentRequired: boolean;
    minAmount: number;
    maxAmount: number;
    imageFileName: string;
  }[];

  [DomainDataEnum.JeReasonCodes]: {
    code: string;
    jeReason: string;
    isActive: boolean;
    description: string | null;
  }[];

  [DomainDataEnum.JeClarificationCodes]: {
    code: string;
    jeClarification: string;
  }[];

  [DomainDataEnum.JemTenantConfigs]: {
    id: number;
    name: string;
    appId: string;
    primaryPoster: string;
    additionalPosters: string | null;
    defaultReviewers: string;
    emailContacts: string;
    refGuidPrefix: string;
    tenantOwner: string;
    exclusionConfigurations: string | null;
    description: string | null;
    modifiedDate: string;
    modifiedby: string;
    isActive: boolean;
    allowedPostingInJEM: boolean;
    additionalConfigurations: string;
  }[];

  [DomainDataEnum.JeOperations]: {
    actionLinkId: number;
    action: string;
    description: string;
    access: number;
    name: string;
  }[];

  [DomainDataEnum.StatusActions]: {
    access: number;
    actionId: number;
    statusCode: number;
    actionDesc: string;
    weightage: number;
  }[];

  [DomainDataEnum.JeBatchFields]: {
    fieldId: number;
    displayName: string;
    group: string;
    optional: boolean;
    optionalField: string;
  }[];

  [DomainDataEnum.JeDroplistValues]: {
    id: number;
    groupName: string;
    value: string;
    sortId: number;
  }[];

  [DomainDataEnum.JeBusinessGroupInfo]: {
    execFunctionOrgSummaryCode: string;
    execFunctionOrgSummaryDesc: string;
    execFunctionOrgSummaryLevel1Code: string;
  }[];

  [DomainDataEnum.BPOActionLinks]: {
    roleId: number;
    roleName: string;
    actionLinkId: number;
    action: string;
    description: string;
    access: number;
    name: string;
  }[];

  [DomainDataEnum.FileShareInfo]: {
    fileShareId: number;
    fileShareName: string;
  }[];

  [DomainDataEnum.FiscalPeriods]: FiscalPeriod[];

  [DomainDataEnum.FiscalPeriodsBPO]: DomainDataObjects[DomainDataEnum.FiscalPeriods];

  [DomainDataEnum.Tenant]: {
    appId: string;
    name: string;
  }[];

  [DomainDataEnum.CurrencyCodes]: {
    currencyCode: string;
    currencyDescription: string;
    currencyFormat: number;
    exchangeRates: number;
  }[];

  [DomainDataEnum.AllowedFileExtensions]: string[];

  [DomainDataEnum.PostingDueDates]: {
    fiscalYear: number;
    fiscalPeriod: number;
    draftDateCode: string;
    assignedDate: string;
  }[];
}

export interface JEMProviderCommonProps {
  children: React.ReactNode;
  disabled: boolean;
}

type Identity<T> = { [P in keyof T]: T[P] };
type Replace<T, K extends keyof T, TReplace> = Identity<
  Pick<T, Exclude<keyof T, K>> & {
    [P in K]: TReplace;
  }
>;

export type JemConfigurationDomainDataApiSubset = Replace<
  JemConfiguration["DomainDataAPI"],
  "endpoints",
  Partial<JemConfiguration["DomainDataAPI"]["endpoints"]>
>;

export interface JEMSubsetProps {
  configuration: JemConfigurationDomainDataApiSubset;
  subset: DomainDataEnum[];
}

export interface JEMFullConfigProps {
  configuration: JemConfiguration["DomainDataAPI"];
  subset?: never;
}

export type JEMProviderProps = JEMProviderCommonProps & (JEMSubsetProps | JEMFullConfigProps);
