import { DefaultButton, SelectionMode, Text } from "@fluentui/react";
import {
  DashboardGrid,
  exportToExcel,
  getMockUserContextObj,
  IDashboardGridRef,
  LoadingSpinner,
  LoadingStatus,
  MockDataFn,
  OpsDashboardApi,
  UserContext
} from "@jem/components";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";

import { getOpsDashboardRequestsColumns } from "./RequestsGrid.columns";
import { getOpsDashboardRequestsData } from "./RequestsGrid.requests";
import { OpsDashboardRequestsElement } from "./RequestsGrid.types";

export interface IRequestsGrid {
  props: {
    configuration: OpsDashboardApi;
    mockFn?: MockDataFn<OpsDashboardRequestsElement[]>;
  };
}

const dashboardUserConfigName = "requestGridConfiguration";

const RequestsGrid: React.FC<IRequestsGrid["props"]> = (props) => {
  const dashboardGridRef = useRef<IDashboardGridRef>(null);
  const [gridItems, setGridItems] = useState<OpsDashboardRequestsElement[]>([]);
  const [dashboardGridStatus, setDashboardGridStatus] = useState<LoadingStatus>(LoadingStatus.Idle);

  const context = useContext(UserContext);
  const { accessToken } = !context ? getMockUserContextObj() : context;

  const refreshGrid = useCallback(async () => {
    try {
      setDashboardGridStatus(LoadingStatus.Pending);
      const items = await getOpsDashboardRequestsData(props.configuration, {
        getTokenFn: accessToken,
        mockFn: props.mockFn
      });
      setGridItems(items);
      setDashboardGridStatus(LoadingStatus.Resolved);
    } catch (err) {
      setDashboardGridStatus(LoadingStatus.Rejected);
    }
  }, [props.configuration, props.mockFn]);

  useEffect(() => {
    let mounted = true;
    setDashboardGridStatus(LoadingStatus.Pending);
    const getGrid = async () => {
      try {
        const items = await getOpsDashboardRequestsData(props.configuration, {
          getTokenFn: accessToken,
          mockFn: props.mockFn
        });
        if (mounted) {
          setGridItems(items);
          setDashboardGridStatus(LoadingStatus.Resolved);
        }
      } catch (err) {
        setDashboardGridStatus(LoadingStatus.Rejected);
      }
    };
    getGrid();
    return () => {
      mounted = false;
    };
    //
  }, []);

  return (
    <>
      {gridItems.length === 0 && dashboardGridStatus !== LoadingStatus.Pending ? (
        <Text
          variant="xLarge"
          style={{
            color: "var(--accent-font-color, black)"
          }}
        >
          No Items to show.
        </Text>
      ) : dashboardGridStatus === LoadingStatus.Resolved ? (
        <>
          <DashboardGrid
            idForLocalStorage={dashboardUserConfigName}
            customRef={dashboardGridRef}
            columnGenerator={getOpsDashboardRequestsColumns()}
            items={gridItems}
            isSortedIndex={2}
            height={`500px`}
            isSortedDescending={true}
            selectionMode={SelectionMode.none}
            onExport={(rows) => {
              exportToExcel({
                sheetName: "OpsDashboardRequests",
                rowsWithHeader: rows,
                fileName: "operationsDashboardRequests.xlsx"
              });
            }}
          >
            <DefaultButton
              iconProps={{ iconName: "Refresh" }}
              title="Refresh Grid"
              ariaLabel="Refresh Grid"
              onClick={refreshGrid}
            ></DefaultButton>
          </DashboardGrid>
        </>
      ) : (
        <LoadingSpinner label="Loading Requests" />
      )}
    </>
  );
};

RequestsGrid.displayName = "OpsDashboardRequests";

export default RequestsGrid;
