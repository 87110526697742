import { useMemo } from "react";
import { GridCommandToggle, ActionTypes, DomainDataObjects, DomainDataEnum } from "../../Shared";

/**
 * Don't export this interface from here, they're legacy names that don't match the UI
 */
interface LegacyButtonNames {
  AddPoster: GridCommandToggle;
  AddReviewer: GridCommandToggle;
  Approve: GridCommandToggle;
  Attach: GridCommandToggle;
  Clarify: GridCommandToggle;
  Delete: GridCommandToggle;
  DraftRecalled: GridCommandToggle;
  DraftClarify: GridCommandToggle;
  InitReview: GridCommandToggle;
  Post: GridCommandToggle;
  Recall: GridCommandToggle;
  Rescind: GridCommandToggle;
  Save: GridCommandToggle;
  SaveSAP: GridCommandToggle;
  SendToPoster: GridCommandToggle;
  ReverseJe: GridCommandToggle;
}

export enum GLExtraActionTypes {
  "draftNeedsClarification" = "draftNeedsClarification",
  "draftRecall" = "draftRecall",
  "post" = "post"
}

export type GLActionTypes = ActionTypes | GLExtraActionTypes;

/**
 * Don't export this function from here
 * @param domainData Only the StatusActions is needed
 * @returns A record of the action names and their weightages (names used by the UI, see GLActionTypes)
 */
function getActionWeightagesFromDomainData(
  domainData: Pick<DomainDataObjects, DomainDataEnum.StatusActions>
): Record<GLActionTypes, number> {
  const actions = domainData[DomainDataEnum.StatusActions];
  const actionWeightages = new Set<[string, number]>();
  for (const action of actions) {
    const actionSet: [string, number] = [action.actionDesc, action.weightage];
    if (!actionWeightages.has(actionSet)) {
      actionWeightages.add(actionSet);
    }
  }
  const actionsWeightages = Object.fromEntries(Array.from(actionWeightages)) as Record<keyof LegacyButtonNames, number>;

  const theAppWeightages: Record<GLActionTypes, number> = Object.freeze({
    save: actionsWeightages.Save,
    sendToPoster: actionsWeightages.SendToPoster,
    approve: actionsWeightages.Approve,
    releaseForSignoff: actionsWeightages.InitReview,
    needsClarification: actionsWeightages.Clarify,
    addPoster: actionsWeightages.AddPoster,
    addReviewer: actionsWeightages.AddReviewer,
    recall: actionsWeightages.Recall,
    rescind: actionsWeightages.Rescind,
    addAttachment: actionsWeightages.Attach,
    deleteAction: actionsWeightages.Delete,
    saveToSAP: actionsWeightages.SaveSAP,
    sendBackFromTreasury: 0,
    draftNeedsClarification: actionsWeightages.DraftClarify,
    draftRecall: actionsWeightages.DraftRecalled,
    post: actionsWeightages.Post,
    approvePreReview: actionsWeightages.Approve,
    needsClarificationPreReview: actionsWeightages.Clarify,
    recallPreReview: actionsWeightages.Recall,
    reverse: actionsWeightages.ReverseJe //0
  });
  return theAppWeightages;
}

export function useActionWeightages(
  domainData: Pick<DomainDataObjects, DomainDataEnum.StatusActions>
): Record<GLActionTypes, number> {
  const actionWeightages = useMemo(() => {
    const weightages = getActionWeightagesFromDomainData(domainData);
    return weightages;
  }, [domainData]);

  return actionWeightages;
}
