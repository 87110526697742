const isEqual = require("lodash/isEqual");
const sortBy = require("lodash/sortBy");

function processStringArray(str: string | null, transformFn: (value: string) => string = (x) => x): string[] {
  return (str || "").split(";").filter(Boolean).map(transformFn);
}

export class AliasUtilities {
  public static getSingleAliasesString(posterOrReviewer: string, backups: string[]): string {
    const allAliases = [posterOrReviewer, ...backups];
    const filteredAliases = allAliases.filter((alias) => alias.trim().length > 0);
    return filteredAliases.join(";");
  }
  public static compareArrayWithString(arr: string[], str: string): boolean {
    const strArr = str.split(";").map((item) => item.trim());

    if (arr.length !== strArr.length) {
      return false;
    }
    return isEqual(sortBy(arr), sortBy(strArr));
  }
  // backup poster might be a string with alias;alias;alias or alias;;alias or null
  // we need to convert it to array of strings, remove empty strings, remove duplicates, uppercase every alias
  // and return a nice array of strings with aliases
  public static getBackupPosters(backupPosters: string | null, defaultPosters: string[]): string[] {
    const backupPostersArr = processStringArray(backupPosters, (x) => x.toUpperCase());
    const defaultPostersUpper = defaultPosters.map((x) => x.toUpperCase());
    const allPosters = [...backupPostersArr, ...defaultPostersUpper];
    const uniquePosters = [...new Set(allPosters)];
    return uniquePosters;
  }

  // additionalReviewers might be a string with alias;alias;alias or alias;;alias or null
  // secondaryReviewer is some weird legacy reviewer that we still need to support
  // we need to convert it to array of strings, remove empty strings, remove duplicates, uppercase every alias
  // and return a nice array of strings with aliases
  // secondaryReviewer is always first, then additional reviewers
  public static getAdditionalReviewers(additionalReviewers: string | null, secondaryReviewer: string | null): string[] {
    const additionalReviewersArr = processStringArray(additionalReviewers, (x) => x.toUpperCase());
    const secondaryReviewerArr = secondaryReviewer ? [secondaryReviewer] : [];
    const allReviewers = [...secondaryReviewerArr, ...additionalReviewersArr];
    const uniqueReviewers = [...new Set(allReviewers)];
    return uniqueReviewers;
  }
}
