import {
  JemConfiguration,
  AttachmentEndpointMap,
  UserContext,
  getValidUrl,
  AuthorizationToken,
  getRequest,
  ApiError,
  postRequest
} from "@jem/components";
import { useContext } from "react";

export interface IPostAttachmentResponse {
  readonly blobName: string;
  readonly inputFileName: string;
  readonly fileStorageUri: string;
}

export interface WithId {
  poId?: string;
  id?: string;
}

export function useIHCCAttachmentActions(configuration: JemConfiguration["IhccApi"]): AttachmentEndpointMap {
  const { accessToken } = useContext(UserContext);

  const makeUrl = (endpoint: string) => {
    const base = configuration.baseApiUrl;
    return getValidUrl(`${base}${endpoint}`);
  };
  return {
    deleteAttachment: async (id: number, blobName: string) => {
      const initialEndpoint = makeUrl(configuration.endpoints.actionDeleteAttachment);
      const endpoint = initialEndpoint.replace("{attachmentId}", `${id}`).replace("{blobName}", blobName);
      const token = await AuthorizationToken(accessToken);
      await fetch(endpoint, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: token
        }
      });

      return;
    },
    downloadAttachment: async (url: string) => {
      const initialEndpoint = makeUrl(configuration.endpoints.actionDownloadAttachment);
      const endpoint = initialEndpoint.replace("{blobName}", url);
      const response = await getRequest<{
        blobName: string;
        inputFileName: string;
        fileStorageUri: string;
      }>(endpoint, accessToken);
      if (!response) {
        throw new ApiError("Could not fetch file metadata.");
      }
      // seems like we don't need a token with the signed url
      // const token = await AuthorizationToken(accessToken);
      return fetch(response.fileStorageUri, {
        method: "GET"
        // headers: {
        //   Authorization: token
        // }
      }) as unknown as File;
    },
    uploadAttachment: async (file: File) => {
      const endpoint = makeUrl(configuration.endpoints.actionUploadAttachment);
      const formData = new FormData();
      formData.append(file.name, file);
      const result = await postRequest<IPostAttachmentResponse>(endpoint, formData, accessToken, {});
      if (!result) {
        throw new ApiError("IHCC Attachment returned null", {
          fileName: file.name
        });
      }
      return {
        blobName: result.blobName,
        inputFileName: result.inputFileName,
        fileStorageUri: result.fileStorageUri
      };
    }
  };
}
