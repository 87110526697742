import { DomainDataEnum, DomainDataObjects } from "../../../../Shared/contexts/JEMContext/JEMContext.domainData.types";
import { EmptyRefGuid } from "../../../utilities/EmptyRefGuid";
import { IAttachmentsToSdInfos } from "../../GLCreate/Actions/GLCreateActions.utils";
import { IDropDownOptionsToBatchFieldsForAPI, IDropDownOptionsToReasonCodesForAPI } from "../GLBatch.State";
import { JeBatchInfo, BatchPayloadForSave, BatchDetailsInfo, BatchPayloadAfterSave, Documents } from "../GLBatch.types";
import { BatchActionPayload, BatchValidateAndPostActionsPayload } from "./GLBatchActions";

export function assembleBatchDetailsForValidatingFile(items: BatchActionPayload): JeBatchInfo {
  const batchInfo: Omit<
    JeBatchInfo,
    "fcwChecklistName" | "isAdhocDraft" | "fiscalPeriod" | "fcwChecklistCoCodes" | "opsDetailsName" | "batchCompanyCode"
  > = {
    isBPOEntry: items.formikState.detailsTabIsBPOEntry,
    isBulkType: items.formikState.detailsTabIsBulkType,
    batchPurpose: items.formikState.detailsTabBatchPurpose,
    batchDesc: items.formikState.detailsTabBatchDesc,
    batchName: items.formikState.detailsTabBatchName,
    batchFile: {
      fileName: items.formikState.detailsTabBatchFile
    },
    attachments: items.formikState.attachments_count,
    chkRefGuid: items.state.batchState.chkRefGuid,
    errorFilePath: items.state.batchState.errorFilePath,
    fileUrl: items.state.batchState.fileUrl,
    hdrInfo: items.state.batchState.hdrInfo,
    isPostingRequired: items.state.batchState.isPostingRequired,
    mode: items.state.batchState.mode,
    postingType: items.state.batchState.postingType,
    processingStatus: items.state.batchState.processingStatus,
    refGuid: items.state.batchState.refGuid,
    status: items.state.batchState.status,
    uri: items.state.batchState.uri,
    modifiedOn: new Date(items.state.batchState.modifiedDate),
    reasonCode: items.formikState.detailsTabReasonCode,
    reviewer: items.formikState.reviewersTabReviewer,
    additionalReviewers: items.formikState.reviewersTabAdditionalReviewers.join(";"),
    additionalPosters: items.formikState.postersTabBackupPosters.join(";"),
    authorList: items.state.batchState.authorList,
    createdOn: new Date(items.state.batchState.createdDate),
    tenantId: items.state.batchState.tenantId,
    fcwChecklistGuid: items.formikState.detailsTabSelectedChecklist.checklistRefGuid,
    versionNo: null,
    actualTimeTakenMins: items.formikState.detailsTabActualTimeTaken,
    preReviewers: items.formikState.reviewersTabPreReviewers.join(";"),
    isPreReview: items.state.batchState.isPreReview
  };
  return batchInfo as any;
}

export function assembleBatchDetails(
  items: BatchActionPayload,
  newBatchFileName: string,
  batchFilePath: string,
  domainData: Pick<DomainDataObjects, DomainDataEnum.JeBatchFields>
): BatchPayloadForSave {
  let options = IDropDownOptionsToBatchFieldsForAPI(items.state.batchState.optionsDropDownOptions, domainData);
  const userSelectedOptions = items.formikState.detailsTabOption;
  // if it has 0, then user selected all options
  const isAllOptionsSelected = userSelectedOptions.length === 1 && userSelectedOptions[0] === 0;
  if (isAllOptionsSelected) {
    options = options.map((option) => ({ ...option, isSelected: true }));
  } else {
    options = options.map((option) => {
      const isSelected = userSelectedOptions.includes(option.fieldId);
      return { ...option, isSelected };
    });
  }

  const batchDetailsInfo: BatchDetailsInfo = {
    batchName: items.formikState.detailsTabBatchName,
    refGuid: items.state.batchState.refGuid,
    filePath: items.formikState.detailsTabBatchName,
    attachments: items.formikState.attachments_count,
    optionalFields: options,
    fcwChecklistCoCodes: items.state.batchState.checklistCompanyCodes,
    reasonCodes: IDropDownOptionsToReasonCodesForAPI(items.state.batchState.reasonCodesDropDownOptions) as [],
    sdData: {
      jeID: "0",
      refGuid: items.state.batchState.refGuid,
      isHBI: "false",
      countOfDocuments: items.formikState.attachments_count.toString(),
      currentMode: "PrePostMode",
      jeType: "0",
      selectedStorage: {
        regionName: items.attachmentObjects.region_name,
        regionKey: items.attachmentObjects.region_key
      },
      sdInfos: IAttachmentsToSdInfos(items.attachmentObjects.attachments)
    },
    reasonCode: items.formikState.detailsTabReasonCode,
    description: items.formikState.detailsTabBatchDesc,
    purpose: items.formikState.detailsTabBatchPurpose,
    fileName: newBatchFileName,
    status: items.state.batchState.status,
    isBPOEntry: items.formikState.detailsTabIsBPOEntry,
    postingType: items.state.batchState.postingType,
    postingStatus: 1,
    preReviewers: items.formikState.reviewersTabPreReviewers.join(";"),
    isPreReview: items.state.batchState.isPreReview,
    isBPOEnabled: false,
    sharePointRegularExpression: '[\\/:*?"<>|#{}%~&]',
    isOptionsEnabled: false,
    optionsVisibility: "Collapsed",
    isDirty: false,
    fileReceived: "",
    batchExcelTemplate: "",
    mode: 0,
    fiscalPeriod: "",
    hrdInfo: "",
    processingStatus: 1,
    errorFileName: "",
    errorFilePath: "",
    checklistRefGuid: null,
    fileExtension: "",
    actualTimeTakenMins: items.formikState.detailsTabActualTimeTaken
  };

  const jeBatchInfo: Omit<
    JeBatchInfo,
    "fcwChecklistName" | "isAdhocDraft" | "fiscalPeriod" | "fcwChecklistCoCodes" | "opsDetailsName" | "batchCompanyCode"
  > = {
    isBPOEntry: items.formikState.detailsTabIsBPOEntry,
    isBulkType: items.formikState.detailsTabIsBulkType,
    batchPurpose: items.formikState.detailsTabBatchPurpose,
    batchDesc: items.formikState.detailsTabBatchDesc,
    batchName: items.formikState.detailsTabBatchName,
    batchFile: {
      fileName: newBatchFileName
    },
    attachments: items.formikState.attachments_count,
    chkRefGuid: items.state.batchState.chkRefGuid,
    errorFilePath: items.state.batchState.errorFilePath,
    fileUrl: items.state.batchState.fileUrl,
    hdrInfo: items.state.batchState.hdrInfo,
    isPostingRequired: items.state.batchState.isPostingRequired,
    mode: items.state.batchState.mode,
    postingType: items.formikState.detailsTabIsBulkType ? 7 : 3,
    processingStatus: items.state.batchState.processingStatus,
    modifiedOn: new Date(items.state.batchState.modifiedDate),
    refGuid: items.state.batchState.refGuid,
    status: items.state.batchState.status,
    uri: batchFilePath,
    reasonCode: items.formikState.detailsTabReasonCode,
    reviewer: items.formikState.reviewersTabReviewer,
    additionalReviewers: items.formikState.reviewersTabAdditionalReviewers.join(";"),
    additionalPosters: items.formikState.postersTabBackupPosters.join(";"),
    authorList: items.state.batchState.authorList,
    createdOn: new Date(items.state.batchState.createdDate),
    tenantId: items.state.batchState.tenantId,
    fcwChecklistGuid:
      items.formikState.detailsTabSelectedChecklist.checklistRefGuid === EmptyRefGuid()
        ? null
        : items.formikState.detailsTabSelectedChecklist.checklistRefGuid,
    versionNo: null,
    actualTimeTakenMins: items.formikState.detailsTabActualTimeTaken,
    preReviewers: items.formikState.reviewersTabPreReviewers.join(";"),
    isPreReview: items.state.batchState.isPreReview
  };

  return {
    batchDetailsInfo,
    jeBatchInfo: jeBatchInfo as any
  };
}

export function assembleBatchPayload(
  items: BatchValidateAndPostActionsPayload,
  userAlias: string,
  domainData: Pick<DomainDataObjects, DomainDataEnum.JeBatchFields>
): BatchPayloadAfterSave {
  const fileNamesObject: string[] = [];
  const blobNamesObject: string[] = [];
  const documentsObject: Documents[] = [];
  let attachmentsRegion = "";

  if (items.state.batchState.attachments.length > 0) {
    attachmentsRegion = items.attachmentObjects.region_name;
    items.state.batchState.attachments.forEach((attachment) => {
      fileNamesObject.push(attachment.fileName);
      blobNamesObject.push(attachment.blobName);
      documentsObject.push({
        fileName: attachment.fileName,
        blobName: attachment.blobName,
        url: attachment.url,
        size: attachment.fileSize
      });
    });
  }

  let options = IDropDownOptionsToBatchFieldsForAPI(items.state.batchState.optionsDropDownOptions, domainData);
  const userSelectedOptions = items.formikState.detailsTabOption;
  // if it has 0, then user selected all options
  const isAllOptionsSelected = userSelectedOptions.length === 1 && userSelectedOptions[0] === 0;
  if (isAllOptionsSelected) {
    options = options.map((option) => ({ ...option, isSelected: true }));
  } else {
    options = options.filter((option) => {
      const isSelected = userSelectedOptions.includes(option.fieldId);
      return isSelected;
    });
  }
  // accepted extensions .txt, .xml, .xls, .xlsx, .xlsb, .dat
  // VALID TYPES ARE XML, XLSX, TXT, DAT
  const fileExtension = items.formikState.detailsTabBatchFile
    .slice(items.formikState.detailsTabBatchFile.lastIndexOf("."))
    .toLowerCase();
  let type = "XML";
  if (fileExtension.indexOf("txt") !== -1) {
    type = "TXT";
  } else if (
    fileExtension.indexOf("xls") !== -1 ||
    fileExtension.indexOf("xlsx") !== -1 ||
    fileExtension.indexOf("xlsb") !== -1
  ) {
    type = "XLSX";
  } else if (fileExtension.indexOf("dat") !== -1) {
    type = "DAT";
  }

  const payload: BatchPayloadAfterSave = {
    fileContent: "",
    uri: items.pathToBatchInDocumentApi,
    regionName: "Redmond",
    guid: items.state.batchState.refGuid,
    optionalFields: options.map((x) => x.displayName),
    fiscalYear: items.state.batchState.fiscalYear,
    fiscalPeriod: items.state.batchState.fiscalPeriod,
    fcwChecklistCoCodes: items.state.batchState.checklistCompanyCodes,
    opsDetailsName: items.formikState.detailsTabSelectedChecklist.opsDetailsName,
    jemXML: {
      guid: items.state.batchState.refGuid,
      user: userAlias,
      noOfFiles: items.formikState.attachments_count,
      region: attachmentsRegion,
      reasonCode: items.formikState.detailsTabReasonCode,
      postingType: items.formikState.detailsTabIsBulkType ? 7 : 3,
      isBpo: items.formikState.detailsTabIsBPOEntry,
      fileNames: fileNamesObject,
      blobNames: blobNamesObject,
      documents: documentsObject,
      purpose: items.formikState.detailsTabBatchPurpose,
      author: items.state.batchState.author,
      actualTimeTakenMins: items.formikState.detailsTabActualTimeTaken
    },
    type: type,
    preReviewers: items.formikState.reviewersTabPreReviewers.join(";"),
    isPreReview: items.state.batchState.isPreReview
  };

  return payload;
}

export function assembleEmptyBatchDetails(
  items: Omit<BatchActionPayload, "attachmentObjects">,
  domainData: Pick<DomainDataObjects, DomainDataEnum.JeBatchFields>
): BatchPayloadForSave {
  const batchDetailsInfo: BatchDetailsInfo = {
    attachments: 0,
    batchExcelTemplate: "",
    batchName: items.formikState.detailsTabBatchName,
    description: "",
    errorFileName: "",
    errorFilePath: "",
    fileExtension: "",
    fileName: "",
    filePath: "",
    fileReceived: "",
    fiscalPeriod: "",
    hrdInfo: "",
    isBPOEnabled: false,
    isBPOEntry: false,
    isDirty: false,
    isPreReview: false,
    isOptionsEnabled: false,
    mode: 0,
    optionalFields: IDropDownOptionsToBatchFieldsForAPI(
      items.state.batchState.optionsDropDownOptions,
      domainData
    ) as [],
    fcwChecklistCoCodes: "",
    optionsVisibility: "Collapsed",
    postingStatus: 1,
    postingType: items.state.batchState.postingType,
    preReviewers: "",
    processingStatus: 0,
    purpose: "",
    reasonCode: "",
    reasonCodes: IDropDownOptionsToReasonCodesForAPI(items.state.batchState.reasonCodesDropDownOptions) as [],
    refGuid: items.state.batchState.refGuid,
    sdData: {
      countOfDocuments: "0",
      currentMode: "PrePostMode",
      isHBI: "false",
      jeID: "0",
      jeType: "0",
      refGuid: items.state.batchState.refGuid,
      selectedStorage: {
        regionKey: 0,
        regionName: ""
      }
    } as any,
    sharePointRegularExpression: '[\\/:*?"<>|#{}%~&]',
    status: items.state.batchState.status,
    actualTimeTakenMins: 0
  } as any;

  const jeBatchInfo: JeBatchInfo = {
    attachments: 0,
    batchDesc: "",
    batchFile: {
      fileName: ""
    },
    batchName: items.formikState.detailsTabBatchName,
    batchPurpose: "",
    chkRefGuid: "",
    errorFilePath: "",
    fileUrl: "",
    hdrInfo: "",
    isBPOEntry: false,
    isBulkType: items.formikState.detailsTabIsBulkType,
    isPostingRequired: true,
    mode: 0,
    postingType: items.formikState.detailsTabIsBulkType ? 7 : items.state.batchState.postingType,
    processingStatus: 0,
    reasonCode: "",
    refGuid: items.state.batchState.refGuid,
    status: 1,
    uri: "",
    additionalPosters: "",
    additionalReviewers: "",
    reviewer: items.formikState.reviewersTabReviewer,
    actualTimeTakenMins: 0,
    isPreReview: false,
    preReviewers: ""
  } as any;

  return {
    batchDetailsInfo,
    jeBatchInfo
  };
}
