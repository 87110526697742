import { DomainDataEnum, DomainDataObjects } from "../../../Shared/contexts/JEMContext/JEMContext.domainData.types";
import { ApiParsingUtilities, DEFAULT_NULL_CHARACTER } from "../../../Shared/utilities/ApiParsingUtilities";
import { parseXmlFromComments } from "../../../Shared/utilities/ParseXmlComments";
import { GLCommentsModel } from "../../../Shared/utilities/SharedModels";
import { IconMappingForSignificance, SignificanceTypeAndIcon } from "../../../Shared/utilities/SignificanceTypeAndIcon";
import {
  regStoreAttachmentsToAttachmentsRegion,
  regStoreAttachmentsToGLAttachments
} from "../../shared/LegacyAttachmentToIAttachment";
import { AliasUtilities } from "../../utilities/AliasUtilities";
import { GLDetailsObjectFromAPI, ReviewInformation } from "./GLDetails.types";
import { GLJeDetailsFormikState, GLJeDetailsState, JeDetailsTabs } from "./GLDetailsForm.types";

export interface GLJeDetailsSettingsFromJeId {
  jeid: string;
  tabName: JeDetailsTabs;
}

export interface GLJeDetailsSettingsFromDocNumber {
  DocNumber: string;
  CompanyCode: string;
  FiscalYear: string;
  SAPModule: string;
  RefGuid: string;
}

export function detailsEntityToState(
  domainData: Pick<DomainDataObjects, DomainDataEnum.JeStatus | DomainDataEnum.JeClarificationCodes>,
  responseObject: GLDetailsObjectFromAPI,
  significanceIcons: IconMappingForSignificance,
  sapModule: string
): GLJeDetailsState {
  const { reviewer, additionalReviewers } = getReviewersFromGLDetailsObjectFromAPI(responseObject);
  const backupReviewers = AliasUtilities.getAdditionalReviewers(additionalReviewers, null);
  const backupPosters = AliasUtilities.getBackupPosters(responseObject.additionalPosters, []);
  const attachments = regStoreAttachmentsToGLAttachments(responseObject.attachments);
  const parsed: GLJeDetailsState = {
    refGuid: responseObject.refGuid || "",
    jeId: responseObject.jeId,
    rowWeightage: responseObject.rowWeightage,
    postingType: responseObject.postingType,
    templateId: ApiParsingUtilities.parseNumberToNumber(responseObject.templateId),
    sapModule,
    header: {
      jeNumber: ApiParsingUtilities.addDefault(responseObject.docNumber),
      author: ApiParsingUtilities.addDefault(responseObject.author),
      poster: ApiParsingUtilities.addDefault(responseObject.poster),
      additionalPosters: backupPosters,
      reviewer: ApiParsingUtilities.addDefault(responseObject.reviewer),
      reviewerAction: ApiParsingUtilities.addDefault(responseObject.reviewerAction, ""),
      dateModified: ApiParsingUtilities.parseDate(responseObject.dateModified),
      status: jeStatusToString(domainData, responseObject.jeStatus)
    },
    detailsTab: {
      documentNumber: ApiParsingUtilities.addDefault(responseObject.docNumber),
      significance: !responseObject.siginificance
        ? ""
        : SignificanceTypeAndIcon(responseObject.siginificance, significanceIcons).icon,
      fiscalYear: ApiParsingUtilities.addDefault(responseObject.fiscalYear),
      fiscalPeriod: ApiParsingUtilities.addDefault(responseObject.fiscalPeriod),
      description: ApiParsingUtilities.addDefault(responseObject.description),
      refNumber: ApiParsingUtilities.addDefault(responseObject.refNumber),
      reasonCode: ApiParsingUtilities.addDefault(responseObject.reasonDescription),
      reversalJe: ApiParsingUtilities.addDefault(responseObject.reversalJE, DEFAULT_NULL_CHARACTER),
      jePurpose: ApiParsingUtilities.addDefault(responseObject.purpose, ""),
      companyCode: ApiParsingUtilities.addDefault(responseObject.companyCode),
      isJem: !!responseObject.isJEM,
      isBpo: !!responseObject.isBPO,
      planReversalDate: ApiParsingUtilities.parseDate(responseObject.reversalDate),
      recurringJe: ApiParsingUtilities.addDefault(responseObject.recurringJe),
      recurringJeFiscalYear: ApiParsingUtilities.addDefault(responseObject.recurringJeFiscalYear),
      dueDate: ApiParsingUtilities.parseDate(responseObject.dueDate),
      translationDate: ApiParsingUtilities.parseDate(responseObject.translationDate),
      postersOrg: ApiParsingUtilities.addDefault(responseObject.postersOrg),
      reviewersOrg: ApiParsingUtilities.addDefault(responseObject.reviewersOrg),
      jeName: ApiParsingUtilities.addDefault(responseObject.jeName),
      opsDetailName: ApiParsingUtilities.addDefault(responseObject.opsDetailName),
      fcwChecklistName: ApiParsingUtilities.addDefault(responseObject.fcwChecklistName),
      isAdhocOrStd: ApiParsingUtilities.addDefault(responseObject.isAdhocOrStd),
      jeType: ApiParsingUtilities.addDefault(responseObject.jeType)
    },
    attachments: attachments,
    attachments_region: regStoreAttachmentsToAttachmentsRegion(responseObject.attachments),
    lock_region: attachments.length > 0,
    reviewersTab: {
      reviewer,
      reviewerAction: ApiParsingUtilities.addDefault(responseObject.reviewerAction, ""),
      additionalReviewers: backupReviewers,
      comments: ApiParsingUtilities.addDefault("", ""),
      clarificationCodes: getClarificationCodes(domainData),
      commentItems: reviewInformationArrayToGLComments(responseObject.reviewInformation),
      preReviewers: AliasUtilities.getAdditionalReviewers(responseObject.additionalReviewers, null)
    },
    status: responseObject.jeStatus
  };
  return parsed;
}

export function placeholderStateForJeDetails(
  domainData: Pick<DomainDataObjects, DomainDataEnum.JeStatus | DomainDataEnum.JeClarificationCodes>
): GLJeDetailsState {
  const blankState: GLJeDetailsState = {
    refGuid: "",
    jeId: -1,
    rowWeightage: 0,
    postingType: 0,
    templateId: 1,
    sapModule: "FI",
    header: {
      jeNumber: "",
      author: "",
      poster: "",
      additionalPosters: [],
      reviewer: "",
      reviewerAction: "",
      dateModified: "",
      status: ""
    },
    detailsTab: {
      documentNumber: "",
      significance: "",
      fiscalYear: "",
      fiscalPeriod: "",
      description: "",
      refNumber: "",
      reasonCode: "",
      reversalJe: "",
      jePurpose: "",
      companyCode: "",
      isJem: false,
      isBpo: false,
      planReversalDate: "",
      recurringJe: "",
      recurringJeFiscalYear: "",
      dueDate: "",
      translationDate: "",
      postersOrg: "",
      reviewersOrg: "",
      jeName: "",
      opsDetailName: "",
      fcwChecklistName: "",
      isAdhocOrStd: "",
      jeType: ""
    },
    attachments: [],
    attachments_region: 2,
    lock_region: false,
    reviewersTab: {
      reviewer: "",
      reviewerAction: "",
      additionalReviewers: [],
      comments: "",
      clarificationCodes: getClarificationCodes(domainData),
      commentItems: [],
      preReviewers: []
    },
    status: 0
  };
  return blankState;
}

export function processXmlComments(
  persona: "Poster" | "Reviewer" | "Administrator",
  comments: string | null
): GLCommentsModel[] {
  if (comments) {
    try {
      const newComments = parseXmlFromComments(persona, comments);
      if (newComments) return newComments;
    } catch (e) {
      // todo: log it with appinsights, link it to JEID or RefGuid
      console.error(e);
      return [];
    }
  }
  return [];
}

function reviewInformationToGLComments(reviewInfo: ReviewInformation): GLCommentsModel[] {
  let comments: GLCommentsModel[] = [];
  comments = comments.concat(processXmlComments("Poster", reviewInfo.posterComments));
  comments = comments.concat(processXmlComments("Reviewer", reviewInfo.reviewerComments));
  comments = comments.concat(processXmlComments("Administrator", reviewInfo.adminComments));
  return comments;
}

export function reviewInformationArrayToGLComments(reviewInformation: ReviewInformation[]): GLCommentsModel[] {
  return reviewInformation.reduce((comments, reviewInfo) => {
    const newComments = reviewInformationToGLComments(reviewInfo);
    comments = comments.concat(newComments);
    return comments;
  }, [] as GLCommentsModel[]);
}

export function jeStatusToString(domainData: Pick<DomainDataObjects, DomainDataEnum.JeStatus>, status: number): string {
  const possibleStatus = domainData.JeStatus.filter((jeStatus) => jeStatus.statusId === status).map(
    (jeStatus) => jeStatus.statusCode
  );
  if (possibleStatus.length === 0) return "";
  return possibleStatus[0];
}

export function getClarificationCodes(
  domainData: Pick<DomainDataObjects, DomainDataEnum.JeClarificationCodes>
): string[] {
  return domainData.JeClarificationCodes.map((clarificationCode) => clarificationCode.jeClarification);
}

export function glJeDetailsToFormikValues(pageState: GLJeDetailsState): GLJeDetailsFormikState {
  const formikValues: GLJeDetailsFormikState = {
    detailsTabJePurpose: pageState.detailsTab.jePurpose,
    attachments_count: pageState.attachments.length,
    detailsTabMapPolicy: false,
    detailsTabBulkAcknowledge: false,
    attachments_error: "",
    attachments_region: `${pageState.attachments_region}`,
    reviewersTabReviewer: pageState.reviewersTab.reviewer,
    reviewersTabAdditionalReviewers: pageState.reviewersTab.additionalReviewers,
    reviewersTabComments: pageState.reviewersTab.comments,
    reviewersTabClarificationCode: [],
    postersTabPoster: pageState.header.poster,
    postersTabBackupPosters: pageState.header.additionalPosters,
    reviewersTabPreReviewers: pageState.reviewersTab.preReviewers
  };
  return formikValues;
}

export function getReviewersFromGLDetailsObjectFromAPI(responseObject: GLDetailsObjectFromAPI): {
  reviewer: string;
  additionalReviewers: string;
} {
  if (
    responseObject.additionalReviewers !== null &&
    responseObject.additionalReviewers !== "" &&
    responseObject.reviewer?.indexOf(";") === -1
  ) {
    return {
      reviewer: ApiParsingUtilities.addDefault(responseObject.reviewer, ""),
      additionalReviewers: ApiParsingUtilities.addDefault(responseObject.additionalReviewers, "")
    };
  } else if (responseObject.reviewer) {
    const allReviewers = responseObject.reviewer.split(";");
    return {
      reviewer: allReviewers[0],
      additionalReviewers: allReviewers.slice(1).join(";")
    };
  }
  return {
    reviewer: "",
    additionalReviewers: ""
  };
}
