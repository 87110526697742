import { GLPageState } from "../GLCreate.State";
import { JeDraftLineItemRequest } from "../GLCreate.types";
import { LineItemModel } from "../GLCreateForm.types";

export function DiffLineItems(
  pageConfiguration: GLPageState["pageConfiguration"],
  previousLineItems: LineItemModel[],
  newLineItems: LineItemModel[]
): JeDraftLineItemRequest[] {
  const diffedLineItems: JeDraftLineItemRequest[] = [];
  for (const [lineItemIdx, lineItem] of newLineItems.entries()) {
    // if all fields are blank and it's not new then it is deleted
    if (
      !lineItem.account &&
      lineItem.costCenter === "" &&
      lineItem.internalOrder === "" &&
      lineItem.salesOrder === "" &&
      lineItem.soLineItem === "" &&
      lineItem.wbsElement === "" &&
      lineItem.tradingPartner === "" &&
      lineItem.profitCenter === "" &&
      !lineItem.lineAmount &&
      !lineItem.lineAmountInCurrency &&
      !lineItem.lineAmountLC2 &&
      !lineItem.lineAmountLC3 &&
      lineItem.lineDescription === "" &&
      lineItem.assignment === "" &&
      lineItem.taxCode === ""
    ) {
      const newDiffedLineItem: Partial<JeDraftLineItemRequest> = {
        action: "D",
        lineOrder: lineItemIdx + 1,
        lineRefGUID: lineItem.refGuid,
        lineNum: lineItem.lineNum,
        lineAmount: 0
      };
      diffedLineItems.push(newDiffedLineItem as JeDraftLineItemRequest);
    } else {
      // find refguid in previous line items
      const previousLineItem = previousLineItems.find((prevLineItem) => prevLineItem.refGuid === lineItem.refGuid);
      if (previousLineItem) {
        // check if all fields are the same
        if (
          (previousLineItem.account === lineItem.account || (!previousLineItem.account && !lineItem.account)) &&
          previousLineItem.costCenter === lineItem.costCenter &&
          previousLineItem.internalOrder === lineItem.internalOrder &&
          previousLineItem.salesOrder === lineItem.salesOrder &&
          previousLineItem.soLineItem === lineItem.soLineItem &&
          previousLineItem.wbsElement === lineItem.wbsElement &&
          previousLineItem.tradingPartner === lineItem.tradingPartner &&
          previousLineItem.profitCenter === lineItem.profitCenter &&
          previousLineItem.lineAmount === lineItem.lineAmount &&
          previousLineItem.lineAmountInCurrency === lineItem.lineAmountInCurrency &&
          previousLineItem.lineAmountLC2 === lineItem.lineAmountLC2 &&
          previousLineItem.lineAmountLC3 === lineItem.lineAmountLC3 &&
          previousLineItem.lineDescription === lineItem.lineDescription &&
          previousLineItem.assignment === lineItem.assignment &&
          previousLineItem.taxCode === lineItem.taxCode
        ) {
          // skip
          continue;
        }
      }
      const newDiffedLineItem: JeDraftLineItemRequest = {
        action: !lineItem.lineNum ? "I" : "U",
        lineOrder: lineItemIdx + 1,
        lineRefGUID: lineItem.refGuid,
        account: lineItem.account || 0,
        assignment: lineItem.assignment,
        costCenter: lineItem.costCenter,
        internalOrder: lineItem.internalOrder,
        lineAmount: lineItem.lineAmount,
        lineAmountLC: lineItem.lineAmountInCurrency === null ? undefined : lineItem.lineAmountInCurrency,
        lineAmountLC2: lineItem.lineAmountLC2 === null ? undefined : lineItem.lineAmountLC2,
        lineAmountLC3: lineItem.lineAmountLC3 === null ? undefined : lineItem.lineAmountLC3,
        lineNum: lineItem.lineNum,
        lineDescription: lineItem.lineDescription,
        profitCenter: lineItem.profitCenter,
        sOLineItem: lineItem.soLineItem,
        salesOrder: lineItem.salesOrder,
        taxCode: lineItem.taxCode,
        tradingPartner: lineItem.tradingPartner,
        wBSElement: lineItem.wbsElement
      };
      diffedLineItems.push(newDiffedLineItem as JeDraftLineItemRequest);
    }
  }
  return diffedLineItems;
}

export function GetAllLineItems(newLineItems: LineItemModel[]): JeDraftLineItemRequest[] {
  const diffedLineItems: JeDraftLineItemRequest[] = [];
  for (const [lineItemIdx, lineItem] of newLineItems.entries()) {
    const newDiffedLineItem: Partial<JeDraftLineItemRequest> = {
      action: "D",
      lineOrder: lineItemIdx + 1,
      lineRefGUID: lineItem.refGuid,
      lineNum: lineItem.lineNum
    };
    diffedLineItems.push(newDiffedLineItem as JeDraftLineItemRequest);
  }
  return diffedLineItems;
}
