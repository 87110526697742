import { CoherencePanel, CoherencePanelSize } from "@coherence-design-system/controls";
import React, { useState } from "react";
import ActionButtons from "../../copiedItems/ActionButtons";
import InlineFormInputErrorMessage from "./InlineFormInputErrorMessage";
import {
  DetailsList,
  DetailsListLayoutMode,
  Dropdown,
  FocusZone,
  IDropdownOption,
  Label,
  SpinButton,
  Text,
  TextField,
  useTheme
} from "@fluentui/react";
import { JEMChecklistDetailsRow, SignoffMode, SignoffStatus } from "@jem/components";
import { Controller, useForm } from "react-hook-form";

interface IJEMChecklistDetailsSignOffPanelProps {
  checklistItems: JEMChecklistDetailsRow[];
  checklistName: string;
  checklistPeriod: string;
  missedSLAReasons: IDropdownOption[];
  onClosePanel: () => void;
  onSignOff: (newStatus: SignoffStatus, missedSLAReasonId: number, comment: string, actualTimeTaken: number) => void;
  loading?: boolean;
  error: string[];
  signoffMode: SignoffMode;
  getMissedSLAOptions: () => void;
  getReasonReactivatedOptions: () => void;
  actualTimeTaken: number;
}

const JEMChecklistDetailsSignOffPanel = (props: IJEMChecklistDetailsSignOffPanelProps): JSX.Element => {
  const theme = useTheme();
  const {
    checklistItems,
    checklistName,
    checklistPeriod,
    signoffMode,
    missedSLAReasons,
    actualTimeTaken,
    onClosePanel,
    onSignOff,
    getMissedSLAOptions,
    getReasonReactivatedOptions,
    loading,
    error
  } = props;
  const form = useForm();
  const { control, errors, handleSubmit, getValues, setValue } = form;

  const columns = [
    {
      key: "column1",
      name: "JE Task Name",
      fieldName: "jeTaskName",
      minWidth: 100,
      maxWidth: 200,
      isResizable: true
    },
    {
      key: "column2",
      name: "Posting State",
      fieldName: "status",
      minWidth: 100,
      maxWidth: 200,
      isResizable: true
    }
  ];

  const [actualTimeTakenMins, setActualTimeTakenMins] = useState<number>(actualTimeTaken);

  const onHandleSubmit = () => {
    const { reasonMissedSLA, comments } = getValues();
    onSignOff(
      signoffMode === SignoffMode.SignOff ? SignoffStatus.SignedOff : SignoffStatus.Open,
      reasonMissedSLA,
      comments,
      actualTimeTakenMins
    );
  };

  const signOffDisabled = () => {
    if (loading) {
      return true;
    }
    if (
      checklistItems.findIndex(
        (i) => i.publishedState.toUpperCase() !== "PUBLISHED" && i.publishedState.toUpperCase() !== "NO ACTIVITY"
      ) >= 0
    ) {
      return true;
    }

    if (actualTimeTakenMins <= 0 && signoffMode === SignoffMode.SignOff) {
      return true;
    }
    return false;
  };

  const saveButtonText = signoffMode === SignoffMode.SignOff ? "Sign Off" : "Re-open";

  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.small}
      titleText={`${signoffMode === SignoffMode.SignOff ? "Sign off" : "Re-open"} JE checklist`}
      onDismiss={onClosePanel}
      isOpen
      closeButtonAriaLabel="Close checklist sign off panel"
      onRenderFooter={(): JSX.Element => (
        <ActionButtons
          disabled={signOffDisabled()}
          mutationLoading={false}
          handleSubmit={handleSubmit(onHandleSubmit)}
          saveTitle={saveButtonText}
          saveLabel={saveButtonText}
          cancelTitle={"Cancel"}
          cancelLabel={"Cancel"}
          closePanel={onClosePanel}
        />
      )}
    >
      <InlineFormInputErrorMessage errorMessages={error} />
      <Label>Checklist {checklistName}</Label>
      <Label>Posting Period {checklistPeriod}</Label>
      <Label id="actualTimeTakenLabel" required htmlFor="actualTimeTakenMins">
        Actual Time Taken (mins):
      </Label>
      <SpinButton
        id="actualTimeTakenMins"
        ariaDescribedBy={`actualTimeTakenLabel`}
        aria-labelledby={`actualTimeTakenLabel`}
        inputProps={{ "aria-labelledby": "actualTimeTakenLabel" }}
        min={0}
        max={2147483647}
        step={1}
        value={actualTimeTakenMins !== -1 && actualTimeTakenMins ? actualTimeTakenMins.toString() : "0"}
        onChange={(e, newValue) => {
          if (!newValue) {
            setActualTimeTakenMins(0);
            return;
          }
          setActualTimeTakenMins(parseInt(newValue));
        }}
        incrementButtonAriaLabel={"Increase Estimated Time by 1 minute"}
        decrementButtonAriaLabel={"Decrease Estimated Time by 1 minute"}
        styles={!actualTimeTakenMins ? { root: { borderColor: theme.semanticColors.errorText } } : undefined}
      />
      {actualTimeTakenMins <= 0 && signoffMode === SignoffMode.SignOff && (
        <Text style={{ color: theme.semanticColors.errorText }} variant="small" role="alert">
          Actual Time Taken (mins) is required.
        </Text>
      )}
      {actualTimeTakenMins <= 0 && signoffMode === SignoffMode.Reopen && (
        <Text style={{ color: theme.semanticColors.inputText }} variant="small">
          Actual Time Taken (mins) is optional.
        </Text>
      )}
      <Controller
        id="reasonMissedSLA"
        label={signoffMode === SignoffMode.SignOff ? "Reason Missed SLA" : "Reason Reactivated"}
        name="reasonMissedSLA"
        defaultValue={undefined}
        value={undefined}
        control={control}
        resizable={true}
        rules={{ required: "A reason is required." }}
        render={({ value }) => {
          return (
            <Dropdown
              required
              id="reasonMissedSLA"
              label={signoffMode === SignoffMode.SignOff ? "Reason Missed SLA" : "Reason Reactivated"}
              placeholder="Select an option"
              options={missedSLAReasons}
              onFocus={signoffMode === SignoffMode.SignOff ? getMissedSLAOptions : getReasonReactivatedOptions}
              selectedKey={value}
              errorMessage={errors?.reasonMissedSLA?.message}
              onChange={(e: unknown, newValue: IDropdownOption<unknown> | undefined): void => {
                setValue("reasonMissedSLA", newValue?.key);
              }}
            />
          );
        }}
      />
      <Controller
        as={TextField}
        id="comments"
        label="Comments:"
        name="comments"
        control={control}
        resizable={false}
        defaultValue={""}
        value={""}
        onChange={(e: unknown, newValue: string | undefined): void => {
          setValue("comments", newValue);
        }}
        placeholder="Please provide additional comments here."
        errorMessage={errors?.reasonMissedSLA?.message}
        multiline
        rows={2}
      />
      <br />
      {checklistItems.length > 0 ? (
        <span>
          <Text style={{ color: "var(--accent-font-color, black)" }}>Checklist JE Tasks to be approved:</Text>
          <FocusZone>
            <div>
              <DetailsList
                items={checklistItems}
                columns={columns}
                setKey="set"
                layoutMode={DetailsListLayoutMode.justified}
              />
            </div>
          </FocusZone>
        </span>
      ) : (
        <Text variant="xLarge" style={{ color: "var(--accent-font-color, black)" }}>
          No tasks to sign off on.
        </Text>
      )}
      <Text variant="mediumPlus" style={{ color: "var(--accent-font-color, black)", marginTop: 10, display: "block" }}>
        {signoffMode === SignoffMode.SignOff
          ? 'By clicking "Sign Off," you assert that the above JEs in this checklist have been completed and/or do not require posting.'
          : 'By clicking "Re-open," you will revoke this checklist\'s sign off, requiring it to be approved again.'}
      </Text>
    </CoherencePanel>
  );
};

export default JEMChecklistDetailsSignOffPanel;
