import React from "react";
import { IColumn } from "@fluentui/react";
import { IExtendedColumn, onRenderArray } from "@jem/components";
import { JEMChecklistDashboardRow } from "../../shared/JEMChecklistDashboardRow";
import { Link } from "react-router-dom";

interface JEMChecklistColumn extends IExtendedColumn {
  fieldName: keyof Omit<JEMChecklistDashboardRow, "isDraft">;
}

interface IJemChecklistColumns {
  onEditClick: (editItem: JEMChecklistDashboardRow) => void;
  fcwUrl: string;
  opsDetailId: string | null;
}
export const getJEMChecklistColumns = (props: IJemChecklistColumns) => (): JEMChecklistColumn[] => {
  const cols: JEMChecklistColumn[] = [
    {
      key: "ChecklistName",
      name: "Checklist Name",
      fieldName: "ChecklistName",
      type: "string",
      minWidth: 115,
      onRender: (
        item?: JEMChecklistDashboardRow,
        _index?: number | undefined,
        _column?: IColumn | undefined
      ): JSX.Element | React.ReactText | null => {
        if (!item) return null;
        return (
          <Link
            to={`/jemchecklist/details?checklistRefGuid=${item.ChecklistRefGuid}`}
            state={{
              checklistName: item.ChecklistName,
              fy: item.FY,
              fp: item.FP,
              defaultReviewers: item.defaultReviewers,
              opsDetailId: props.opsDetailId
            }}
          >
            {item.ChecklistName}
          </Link>
        );
      }
    },
    {
      key: "ChecklistId",
      name: "Checklist Id",
      fieldName: "ChecklistId",
      type: "number",
      minWidth: 80,
      sortDescendingAriaLabel: "Sort by id"
    },
    {
      key: "CloseChecklistTaskId",
      name: "Close Checklist Task Id",
      fieldName: "CloseChecklistTaskId",
      type: "number",
      minWidth: 80,
      sortDescendingAriaLabel: "Sort by id",
      onRender: (
        item?: JEMChecklistDashboardRow,
        _index?: number | undefined,
        _column?: IColumn | undefined
      ): JSX.Element | React.ReactText | null => {
        if (!item) return null;
        return (
          <a
            href={`${props.fcwUrl}/tasks/${item.FY}/${item.FP}/edit/${item.CloseChecklistTaskId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {item.CloseChecklistTaskId}
          </a>
        );
      }
    },
    {
      key: "OpsDetail",
      name: "OpsDetail",
      fieldName: "OpsDetail",
      type: "string",
      minWidth: 80,
      sortDescendingAriaLabel: "Sort by id"
    },
    {
      key: "CC",
      name: "Company Code",
      fieldName: "CC",
      type: "object",
      minWidth: 70,
      sortDescendingAriaLabel: "Sorted by Company Code",
      onRender: (item?: JEMChecklistDashboardRow) => {
        if (!item) return null;
        return onRenderArray(item.CC);
      }
    },
    {
      key: "Posting",
      name: "Posting",
      fieldName: "Posting",
      type: "string",
      minWidth: 85
    },
    {
      key: "SignificantReview",
      name: "SignificantReview",
      fieldName: "SignificantReview",
      type: "string",
      minWidth: 85
    },
    {
      key: "RegularReview",
      name: "RegularReview",
      fieldName: "RegularReview",
      type: "string",
      minWidth: 85
    },
    {
      key: "LowValueReview",
      name: "LowValueReview",
      fieldName: "LowValueReview",
      type: "string",
      minWidth: 85
    },
    {
      key: "SignOffStatus",
      name: "Sign Off Status",
      fieldName: "signOffStatus",
      type: "string",
      minWidth: 85
    },
    {
      key: "SignOffDate",
      name: "Sign Off Date",
      fieldName: "signOffDate",
      type: "date",
      minWidth: 85
    },
    {
      key: "SignOffUser",
      name: "Sign Off User",
      fieldName: "signOffUser",
      type: "string",
      minWidth: 85
    }
  ];
  return cols;
};
