import { Card } from "@coherence-design-system/controls";
import { BaseButton, PrimaryButton, Stack, useTheme } from "@fluentui/react";
import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";

export interface LinkCardsProps {
  title: string;
  content: string;
  buttonName: string;
}

const LinkCards: React.FC<LinkCardsProps> = (props) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const onclick = (
    event: React.MouseEvent<
      HTMLDivElement | BaseButton | HTMLAnchorElement | HTMLButtonElement | HTMLSpanElement,
      MouseEvent
    >
  ) => {
    event.preventDefault();
    props.title === "My Dashboard"
      ? navigate("/gl/dashboard", {
          state: {
            origin: location.pathname,
            state: location.state
          }
        })
      : navigate("/gl/create", {
          state: {
            origin: location.pathname,
            state: location.state
          }
        });
  };

  return (
    <div
      style={{
        padding: "20px",
        width: "288px",
        height: "250px",
        minWidth: "150px"
      }}
    >
      <Card
        styles={{
          root: {
            backgroundColor: theme.palette.neutralQuaternaryAlt
          }
        }}
      >
        <Stack horizontal wrap>
          <Stack.Item align="start">
            <div
              style={{
                fontSize: "25px"
              }}
            >
              {props.title}
            </div>
          </Stack.Item>
          <Stack.Item>
            <div>{props.content}</div>
          </Stack.Item>
          <Stack.Item align="end" styles={{ root: { marginTop: "90px" } }}>
            <div>
              <PrimaryButton text={props.buttonName} onClick={onclick} />
            </div>
          </Stack.Item>
        </Stack>
      </Card>
    </div>
  );
};

LinkCards.displayName = "LinkCards";

export default LinkCards;
