import {
  CoherencePageSize,
  CoherencePagination,
  ICoherencePaginationProps,
  IComboBoxNumberOption
} from "@coherence-design-system/controls";
import { Text } from "@fluentui/react";
import React, { useEffect, useState } from "react";

export interface IDashboardGridPaginateProps {
  pageCount: number;
  selectedPage: number;
  defaultPageSize: number;
  itemCount: number;
  onPageChange: ICoherencePaginationProps["onPageChange"];
  onPageSizeChange: (newpageSize: string | number) => void;
}

let DashboardGridPaginate: React.FC<IDashboardGridPaginateProps> = (props) => {
  const [key, setKey] = useState<string>(`k-${props.defaultPageSize}-${props.pageCount}`);
  useEffect(() => {
    const nKey = `k-${props.defaultPageSize}-${props.pageCount}`;
    if (key !== nKey) {
      setKey(nKey);
    }
  }, [props.defaultPageSize, props.pageCount]);
  return (
    <div
      className="ms-Grid"
      dir="ltr"
      style={{
        marginTop: "10px"
      }}
    >
      <div className="ms-Grid-row">
        <div className="ms-Grid-col ms-sm12 ms-md2">
          <Text variant="medium">{props.itemCount} Records</Text>
        </div>
        <div className="ms-Grid-col ms-sm12 ms-md6">
          <CoherencePagination
            key={`${key}`}
            pageCount={props.pageCount}
            selectedPage={props.selectedPage}
            previousPageAriaLabel={"previous page"}
            nextPageAriaLabel={"next page"}
            inputFieldAriaLabel={"page number"}
            onPageChange={props.onPageChange}
          />
        </div>
        <div className="ms-Grid-col ms-sm12 ms-md4">
          <CoherencePageSize
            // an unfortunate hack to force it to re render
            key={`${key}`}
            pageSize={props.defaultPageSize}
            pageSizeList={
              [
                { key: 40, text: "40" },
                { key: 60, text: "60" },
                { key: 80, text: "80" },
                { key: 100, text: "100" },
                { key: 999, text: "All" }
              ] as IComboBoxNumberOption[]
            }
            comboBoxWidth={120}
            endingPageSizeLabelText={"Records"}
            comboBoxAriaLabel={"Select page size"}
            onPageSizeChange={props.onPageSizeChange}
          />
        </div>
      </div>
    </div>
  );
};

DashboardGridPaginate = React.memo(DashboardGridPaginate, (prevProps, nextProps) => {
  if (prevProps.defaultPageSize !== nextProps.defaultPageSize) return false;
  if (prevProps.onPageChange !== nextProps.onPageChange) return false;
  if (prevProps.onPageSizeChange !== nextProps.onPageSizeChange) return false;
  if (prevProps.pageCount !== nextProps.pageCount) return false;
  if (prevProps.selectedPage !== nextProps.selectedPage) return false;

  return true;
});
DashboardGridPaginate.displayName = "DashboardGridPaginate";

export { DashboardGridPaginate };
