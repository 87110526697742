import React from "react";
import { IconMappingForSignificance } from "@jem/components";
import { mergeStyleSets, TooltipHost } from "@fluentui/react";

const classNames = mergeStyleSets({
  fileIconImg: {
    verticalAlign: "middle",
    maxHeight: "16px",
    maxWidth: "16px"
  }
});

export const significanceIconMapping: IconMappingForSignificance = {
  // wrap in tooltip
  I: (significanceType: string) => (
    <TooltipHost content={significanceType}>
      <img src={require(`../assets/Icons/I.png`)} className={classNames.fileIconImg} alt={`${significanceType} icon`} />
    </TooltipHost>
  ),
  O: (significanceType: string) => (
    <TooltipHost content={significanceType}>
      <img src={require(`../assets/Icons/O.png`)} className={classNames.fileIconImg} alt={`${significanceType} icon`} />
    </TooltipHost>
  ),
  L: (significanceType: string) => (
    <TooltipHost content={significanceType}>
      <img src={require(`../assets/Icons/L.png`)} className={classNames.fileIconImg} alt={`${significanceType} icon`} />
    </TooltipHost>
  ),
  N: (significanceType: string) => (
    <TooltipHost content={significanceType}>
      <img src={require(`../assets/Icons/N.png`)} className={classNames.fileIconImg} alt={`${significanceType} icon`} />
    </TooltipHost>
  ),
  X: (significanceType: string) => (
    <TooltipHost content={significanceType}>
      <img src={require(`../assets/Icons/X.png`)} className={classNames.fileIconImg} alt={`${significanceType} icon`} />
    </TooltipHost>
  )
};
