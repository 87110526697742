import { ErrorListModel, LineItemModel } from "../GLCreateForm.types";
import * as Yup from "yup";
import { CreatePageType, GLPageState } from "../GLCreate.State";
import { GLCreateLineItemsOptions, InnerLineItem } from "./GLCreateLineItems.types";
import { lineItemIsNotEmpty } from "./GLCreateLineItems.lineItemIsNotEmpty";
import { LineItemsSettings } from "./GLCreateLineItems.utilities";

export function LineItemsValidationSchema(pageConfiguration: GLPageState["pageConfiguration"]) {
  let taxCodeValidation = Yup.string()
    .nullable(true)
    .transform((v, o) => (o === "" || (typeof o === "string" && !o.trim()) ? null : v));
  console.log(pageConfiguration);
  const isF05Posting = pageConfiguration.isF05Posting && pageConfiguration.pageType === CreatePageType.FourCurrency;
  if (pageConfiguration.featureFlags.disableTaxCodeValidation) {
    taxCodeValidation = taxCodeValidation
      .matches(/^[a-zA-Z0-9]{0,2}$/, "TaxCode must be alpha-numeric and 2 characters long")
      .min(2, "TaxCode must be alpha-numeric and 2 characters long");
  } else {
    taxCodeValidation = taxCodeValidation.test("custom-validation", "TaxCode must be blank or V0", (value) => {
      return value === "" || value === "V0" || value === null || value === undefined;
    });
  }

  return (
    Yup.object()
      .shape({
        account: Yup.string().defined("Account field is required").required("Account field is required").nullable(true),
        //JE line items must have one and only one of either cost center, internal order or soLineItem;
        costCenter: Yup.string().nullable(true),
        internalOrder: Yup.string().nullable(true),
        salesOrder: Yup.string().nullable(true),
        soLineItem: Yup.string()
          .matches(/^[0-9]{0,6}$/, "Sales Order accepts 6 digit numeric entries only")
          .nullable(true),
        wbsElement: Yup.string().nullable(true),
        tradingPartner: Yup.string().nullable(true),
        profitCenter: Yup.string().nullable(true),
        lineAmount: Yup.string()
          .matches(/^-?[0-9]\d*(\.\d+)?$/, "LineAmount field only accepts numbers")
          .required("LineAmount is required"),
        ...(pageConfiguration.pageType === CreatePageType.DualCurrency && {
          lineAmountInCurrency: Yup.string()
            .matches(/^-?[0-9]\d*(\.\d+)?$/, "LineAmountLC field only accepts numbers")
            .nullable(true)
        }),
        ...(pageConfiguration.pageType === CreatePageType.FourCurrency && {
          lineAmount: Yup.string()
            .matches(/^-?[0-9]\d*(\.\d+)?$/, "LineAmount field only accepts numbers")
            .nullable(true),
          lineAmountInCurrency: Yup.string()
            .matches(/^-?[0-9]\d*(\.\d+)?$/, "LineAmountLC field only accepts numbers")
            .nullable(true),
          lineAmountLC2: Yup.string()
            .matches(/^-?[0-9]\d*(\.\d+)?$/, "LineAmountLC2 field only accepts numbers")
            .nullable(true),
          lineAmountLC3: Yup.string()
            .matches(/^-?[0-9]\d*(\.\d+)?$/, "LineAmountLC3 field only accepts numbers")
            .nullable(true)
        }),
        lineDescription: Yup.string()
          .max(50, "Line item description can not be more than 50 characters long")
          .required("Line item description cannot be empty"),
        assignment: Yup.string()
          .matches(
            /^[a-zA-Z0-9 ]{0,18}$/,
            "Assignment can have maximum of 18 characters and contain a combination of numbers and letters"
          )
          .nullable(true),
        taxCode: taxCodeValidation,
        processingStatus: Yup.string()
          .nullable(true)
          .test("processignStatusEmpty", "Processing Status must be empty", function (item) {
            if (item) return this.createError({ message: item });
            return true;
          })
      })
      .test("salesOrderAndSoLineItem", "Sales Order must have corresponding Sales Order Line Item", function (item) {
        if (!item.salesOrder && !item.soLineItem) return true;
        if (item.salesOrder && !item.soLineItem) return false;
        if (item.soLineItem && !item.salesOrder) return false;

        return true;
      })
      // .test("isF05Posting", "F05Posting: LineAmount is required ", function (item) {
      //   if (isF05Posting && Number(item.lineAmount) === 0) return false;
      //   return true;
      // })
      // .test("isF05Posting", "F05Posting: LineAmountLC is required ", function (item) {
      //   if (isF05Posting && Number(item.lineAmountInCurrency) === 0) return false;
      //   return true;
      // })
      // .test("isF05Posting", "F05Posting: LineAmountLC2 is required ", function (item) {
      //   if (isF05Posting && Number(item.lineAmountLC2) === 0) return false;
      //   return true;
      // })
      // .test("isF05Posting", "F05Posting: LineAmountLC3 is required ", function (item) {
      //   if (isF05Posting && !pageConfiguration.isHardCurrency && Number(item.lineAmountLC3) === 0) return false;
      //   return true;
      // })
      .test(
        "isFourCurrencyWarningNeeded",
        "Warning: Are you sure the values in TC, LC1, and LC2 are correct?",
        function (item) {
          if (isF05Posting) {
            if (
              Number(item.lineAmount) !== 0 &&
              Number(item.lineAmountInCurrency) !== 0 &&
              Number(item.lineAmountLC2) !== 0
            )
              return false;
            return true;
          }
          return true;
        }
      )
  );
}

export function validateLineItem(
  pageConfiguration: GLPageState["pageConfiguration"],
  newLineItem: LineItemModel
): string[] {
  const validationSchema = LineItemsValidationSchema(pageConfiguration);
  try {
    validationSchema.validateSync(newLineItem, { abortEarly: false });
    return [];
  } catch (error) {
    if (error instanceof Yup.ValidationError) {
      const errorMessages = error.inner.map((err) => err.message);
      return errorMessages;
    }
    throw error;
  }
}

export function getErrorsFromLineItems(
  opts: LineItemsSettings,
  items: InnerLineItem[],
  actionOptions?: GLCreateLineItemsOptions
) {
  const validationSchema = LineItemsValidationSchema(opts.pageConfiguration);
  const errors: ErrorListModel[] = [];

  try {
    const arrayValidationSchema = Yup.array().of(validationSchema);
    arrayValidationSchema.validateSync(items, {
      abortEarly: false
    });
  } catch (genericError) {
    if (genericError instanceof Yup.ValidationError) {
      for (const error of genericError.inner) {
        //get index from path
        if (error.path) {
          const index = error.path.match(/\[(.*?)\]/);
          if (actionOptions?.fromAction && error.path.indexOf("processingStatus") !== -1) {
            continue;
          }
          // if index is already in array, find it and append to description
          if (index && index[1]) {
            const filteredRowIndex = Number(index[1]);
            const row: InnerLineItem = genericError.value[filteredRowIndex];
            const indexToUse = row.innerRowNumber + 1;
            const existingError = errors.find((e) => e.rowNumber === indexToUse);
            if (existingError) {
              if (existingError.description.indexOf(error.message) === -1) {
                existingError.description += `;${error.message}`;
              }
            } else {
              errors.push({
                rowNumber: indexToUse,
                description: error.message,
                filteredRowNumber: filteredRowIndex + 1
              });
            }
          }
        }
      }
    }
  }
  return errors;
}

export function getErrorMessagePerCell(
  precision: number,
  validationSchema: ReturnType<typeof LineItemsValidationSchema>
) {
  return (item: LineItemModel, prop: keyof LineItemModel, _parsing: boolean) => {
    const propDoesNotRequireValidation =
      !prop ||
      prop.toLowerCase().indexOf("innerrownumber") !== -1 ||
      prop.toLowerCase().indexOf("readonly") !== -1 ||
      prop.toLowerCase().indexOf("processingstatus") !== -1 ||
      prop.toLowerCase().indexOf("refguid") !== -1 ||
      prop.toLowerCase().indexOf("linenum") !== -1 ||
      prop.toLowerCase().indexOf("jeid") !== -1;

    if (propDoesNotRequireValidation) return null;
    const itemIsEmpty = !lineItemIsNotEmpty(item);
    if (itemIsEmpty) return null;

    try {
      validationSchema.validateSyncAt(prop, item);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        return error.message;
      }
    }
    return null;
  };
}
