import { getValidUrl, getRequest, IUserProviderState } from ".";
import { JemConfiguration } from "../../JemConfiguration";
import { ApiError } from "./ErrorHelpers";

export interface CheckStatus {
  jeId: number | null;
  isDraft: boolean | null;
}

export async function GetJeStatusFromRefGuid(
  configuration: JemConfiguration["GeneralLedgerApi"],
  refGuid: string,
  accessToken: IUserProviderState["accessToken"]
): Promise<CheckStatus> {
  const endpoint = `${configuration.baseApiUrl}${configuration.endpoints.isDraft}`.replace("{refGuid}", refGuid);
  const endpointUrl = getValidUrl(endpoint);
  const response = await getRequest<CheckStatus>(endpointUrl, accessToken);
  if (!response) {
    throw new ApiError("No response from server");
  }
  return response;
}
