import { IButtonProps, PrimaryButton } from "@fluentui/react";
import React, { useRef, useState } from "react";
import { AttachmentUtil } from "../../../../Shared/components/Attachments/Attachments.AttachmentUtil";

export interface IInputFileButtonProps {
  text: string;
  accept: string;
  disabled: boolean;
  onChange: (files: File[]) => void | Promise<void>;
  allowMultipleFiles?: boolean;
  styles?: IButtonProps["style"];
}

const InputFileButton: React.FC<IInputFileButtonProps> = (props) => {
  const allowedFileExtension = ".txt, .xml, .xls, .xlsx, .xlsb, .dat";
  const [accept] = useState<string>(props.accept || allowedFileExtension);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const fileSelectedHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files || event.target.files.length !== 1) return;
    const attachments = Array.from(event.target.files).filter((file) =>
      AttachmentUtil.isValidFileExtension(file.name, props.accept)
    );
    props.onChange(attachments);
  };

  const fileInputRefHandler = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <>
      <input
        title="Select File"
        placeholder="Select File"
        type="file"
        onChange={fileSelectedHandler}
        onClick={(event) => {
          event.currentTarget.value = "";
        }}
        name="InputFile"
        style={{ display: "none" }}
        ref={fileInputRef}
        accept={accept}
        multiple={props.allowMultipleFiles}
      />
      <PrimaryButton styles={props.styles} text={props.text} onClick={fileInputRefHandler} disabled={props.disabled} />
    </>
  );
};

InputFileButton.displayName = "InputFileButton";
export { InputFileButton };
