// tslint:disable: max-classes-per-file
export class ApiError<T = undefined> extends Error {
  public props?: T;
  constructor(message: string, props?: T) {
    super(message);
    this.name = this.constructor.name;
    this.props = props;
  }
}

export class FatalError extends Error {
  constructor(message: string) {
    super(message);
    this.name = this.constructor.name;
  }
}

export class InvalidEntryError extends Error {
  constructor(message: string) {
    super(message);
    this.name = this.constructor.name;
  }
}

export class RuntimeError extends Error {
  constructor(message: string) {
    super(message);
    this.name = this.constructor.name;
  }
}

export class APIAnomalyError extends Error {
  constructor(message: string) {
    super(message);
    this.name = this.constructor.name;
  }
}
