import { v4 as uuidv4 } from "uuid";
import * as msal from "@azure/msal-browser";
import { ApiError, RuntimeError } from "./ErrorHelpers";
import { ErrorHelper } from "./ErrorHelper";
import { ErrorMessages } from "./ErrorHelper.messages";
import { JemUserRoles } from "../contexts/UserContext/UserContext.roles.main";
import { LoadingStatus } from "./Utilities";
import * as Yup from "yup";
import { GeneralLedgerAction } from "../components/ActionManager/ActionsManager.types";

import { ILoggingProviderState } from "../contexts/LoggingContext/LoggingContext";
import { logger } from "@azure/storage-blob";

export const AuthorizationToken = async (getTokenPromiseFn: IUserProviderState["accessToken"]): Promise<string> => {
  const msalPayload = await getTokenPromiseFn();
  if (msalPayload) {
    return `Bearer ${msalPayload.accessToken}`;
  } else {
    return "";
  }
};

export enum AuthenticationState {
  Unauthenticated = "Unauthenticated",
  InProgress = "InProgress",
  Authenticated = "Authenticated"
}

export interface IUserProviderState {
  user: {
    alias: string;
    name: string;
    userName: string;
    accountIdentifier: string;
  };
  jemUser: JemUserRoles;
  login: () => void;
  logout: () => void;
  refreshRoles: (flag: boolean) => void;
  accessToken: () => Promise<msal.AuthenticationResult | undefined>;
  status: AuthenticationState;
  jemRolesStatus: LoadingStatus;
}

export type MockDataFn<T> = (args: any) => Promise<T>;

export interface IRequestOptions<T> {
  mockFn?: MockDataFn<T>;
  getTokenFn?: IUserProviderState["accessToken"];
  logger?: ILoggingProviderState["appInsights"];
}

export function getValidUrl(url = "", encodeSpace = false) {
  let newUrl = window.decodeURIComponent(url);

  newUrl = newUrl.trim().replace(/\s/g, encodeSpace ? "%20" : "");

  if (/^(:\/\/)/.test(newUrl)) {
    return `https${newUrl}`;
  }
  if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
    return `https://${newUrl}`;
  }
  return newUrl;
}

type BaseHeader = { accept: string; requestid: string };
type HeaderAuthorization = { Authorization: string };
type HeaderWithAuthorization = BaseHeader & HeaderAuthorization;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type BaseRequestOptions = { headers: BaseHeader; method: string; referrerPolicy: string; mode: string; body?: any };

export async function getRequest<T>(
  endpoint: string,
  getTokenFn?: IUserProviderState["accessToken"],
  logger?: ILoggingProviderState["appInsights"]
): Promise<T | null> {
  try {
    const headers: BaseHeader = {
      accept: "application/json",
      requestid: uuidv4()
    };
    if (getTokenFn) {
      (headers as unknown as HeaderWithAuthorization).Authorization = await AuthorizationToken(getTokenFn);
    }
    const settings: BaseRequestOptions = {
      headers,
      referrerPolicy: "strict-origin-when-cross-origin",
      method: "GET",
      mode: "cors"
    };

    const resp = await fetch(endpoint, settings as RequestInit);
    return handleResponse<T>(resp);
  } catch (error: any) {
    logger?.appInsights?.trackException({
      exception: new Error("An error occurred while getting data"),
      properties: JSON.stringify({ endpoint, error: error.toString() })
    });
    return null;
  }
}

export async function deleteRequest<T>(
  endpoint: string,
  getTokenFn?: IUserProviderState["accessToken"]
): Promise<T | null> {
  const headers: BaseHeader = {
    accept: "application/json",
    requestid: uuidv4()
  };
  if (getTokenFn) {
    (headers as unknown as HeaderWithAuthorization).Authorization = await AuthorizationToken(getTokenFn);
  }
  const settings: BaseRequestOptions = {
    headers,
    referrerPolicy: "strict-origin-when-cross-origin",
    method: "DELETE",
    mode: "cors"
  };

  const resp = await fetch(endpoint, settings as RequestInit);
  return handleResponse<T>(resp);
}

export async function postRequest<T>(
  endpoint: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rBody?: any,
  getTokenFn?: IUserProviderState["accessToken"],
  customHeaders?: Record<string, string>
): Promise<T | null> {
  try {
    let headers: any = {};
    // Set headers based on request body type
    if (rBody instanceof FormData) {
      headers = Object.assign(
        {
          accept: "application/json",
          requestid: uuidv4()
        },
        customHeaders || {}
      );
    } else {
      headers = Object.assign(
        {
          "Content-Type": "application/json",
          accept: "application/json",
          requestid: uuidv4()
        },
        customHeaders || {}
      );
    }
    // Add authorization header if provided
    if (getTokenFn) {
      (headers as unknown as HeaderWithAuthorization).Authorization = await AuthorizationToken(getTokenFn);
    }

    // Set request body
    let body: any = undefined;
    if (rBody instanceof FormData) {
      body = rBody;
    } else if (rBody) {
      body = JSON.stringify(rBody);
    }

    // Make the fetch request
    const resp = await fetch(endpoint, {
      headers,
      referrerPolicy: "strict-origin-when-cross-origin",
      method: "POST",
      mode: "cors",
      body
    });

    // Handle response
    return handleResponse<T>(resp);
  } catch (error: any) {
    // Log exception
    logger?.appInsights?.trackException({
      exception: new Error("An error occurred while doing post request"),
      properties: JSON.stringify({ endpoint, error: error.toString() })
    });
    return null; // Return null to indicate failure
  }
}

export async function putRequest<T>(
  endpoint: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  body?: any,
  getTokenFn?: IUserProviderState["accessToken"]
): Promise<T | null> {
  const headers = {
    "Content-Type": "application/json",
    accept: "application/json",
    requestid: uuidv4()
  };
  const options: BaseRequestOptions = {
    headers,
    referrerPolicy: "strict-origin-when-cross-origin",
    method: "PUT",
    mode: "cors"
  };
  if (getTokenFn) {
    const bearerToken = await AuthorizationToken(getTokenFn);
    (headers as unknown as HeaderWithAuthorization).Authorization = bearerToken;
  }

  if (body) {
    (options as unknown as BaseRequestOptions).body = JSON.stringify(body);
  }

  const resp = await fetch(endpoint, options as RequestInit);
  return handleResponse<T>(resp);
}

export function tryParseJson<T>(jsonStr: string): T | null {
  try {
    const json = JSON.parse(jsonStr);
    return json as T;
  } catch (e) {
    // check if string and if not empty
    if (typeof jsonStr === "string" && jsonStr.length > 0 && jsonStr.toLowerCase().indexOf("hello world") !== -1) {
      throw new RuntimeError("Endpoint is configured incorrectly.");
    }
    return null;
  }
}

export interface CommonJEMError {
  type: "string";
  title: "string";
  status: number;
  detail: "string";
  instance: "string";
}

function jemSpecificHandling(possibleJemPayload: string): [string, boolean] | [GeneralLedgerAction, boolean] {
  const itIsAJEMMessage = JSON.parse(possibleJemPayload);
  try {
    // it's possible the object is IHCCResponse
    const ihccResponseSchema = Yup.object().shape({
      affectedRefGuid: Yup.array().of(Yup.string()).nullable(true),
      customMessage: Yup.string().nullable(true).required(),
      inValidReviewers: Yup.string().nullable(true),
      status: Yup.number().nullable(true),
      responseCode: Yup.string().nullable(true)
    });

    // type debugTypeAbove = Yup.InferType<typeof ihccResponseSchema>;
    ihccResponseSchema.validateSync(itIsAJEMMessage, { strict: true });
    const jemPayload = ihccResponseSchema.cast(itIsAJEMMessage);
    if (jemPayload.responseCode && jemPayload.responseCode in ErrorMessages) {
      const msg = ErrorHelper.getMessage(jemPayload.responseCode as keyof typeof ErrorMessages);
      return [msg, false];
    } else {
      return itIsAJEMMessage.customMessage;
    }
    // eslint-disable-next-line no-empty
  } catch (e) {}

  try {
    // it's possible the object is Domain Data Error
    const jem500ErrorSchema = Yup.object({
      detail: Yup.string().nullable(true).required(),
      extensions: Yup.object({}).nullable(true),
      instance: Yup.string().nullable(true),
      status: Yup.number().nullable(true),
      title: Yup.string().nullable(true).required(),
      type: Yup.string().nullable(true).required()
    });
    // type debugTypeAbove = Yup.InferType<typeof jem500ErrorSchema>;
    jem500ErrorSchema.validateSync(itIsAJEMMessage, { strict: true });
    const jemPayload = jem500ErrorSchema.cast(itIsAJEMMessage);
    throw new ApiError(`${jemPayload.title}: ${jemPayload.detail}`, jemPayload as CommonJEMError);
    // catch ApiError and rethrow, else do nothing
  } catch (e) {
    if (e instanceof ApiError) {
      throw e;
    }
  }

  try {
    // it's possible the object is JEM Action Generic Error (Different from CommonJEMError)
    const jemGenericErrorSchema = Yup.object().shape({
      status: Yup.boolean().required(),
      message: Yup.string().notRequired(),
      errors: Yup.array()
        .of(
          Yup.object().shape({
            code: Yup.string().required(),
            message: Yup.string().required()
          })
        )
        .required()
        .nullable(true),
      changes: Yup.array()
        .of(
          Yup.object().shape({
            jeId: Yup.number().required(),
            refGuid: Yup.string().uuid().required(),
            jeStatus: Yup.number().nullable(),
            oldJeStatus: Yup.number().required(),
            errorCode: Yup.string().required()
          })
        )
        .required()
        .nullable(true)
    });
    // type debugTypeAbove = Yup.InferType<typeof jem500ErrorSchema>;
    jemGenericErrorSchema.validateSync(itIsAJEMMessage, { strict: true });
    const glResponse = jemGenericErrorSchema.cast(itIsAJEMMessage);
    const glAction: GeneralLedgerAction = {
      status: !!glResponse.status,
      message: glResponse.message || "",
      errors: (glResponse.errors || []).map((e) => ({
        code: e.code || "",
        message: e.message || ""
      })),
      changes: (glResponse.changes || []).map((c) => ({
        jeId: c.jeId || 0,
        refGuid: c.refGuid || "",
        jeStatus: c.jeStatus || 0,
        oldJeStatus: c.oldJeStatus || 0,
        errorCode: c.errorCode || ""
      }))
    };
    return [glAction, true];
    // eslint-disable-next-line no-empty
  } catch (e) {}
  console.error(possibleJemPayload);
  return [possibleJemPayload, false];
}

async function handleResponse<T>(response: Response): Promise<T | null> {
  try {
    if (response.status >= 400 && response.status <= 500) {
      const text = await response.text();
      const [jemResponse, isObject] = jemSpecificHandling(text);
      if (isObject) {
        return jemResponse as T;
      } else if (typeof jemResponse === "string") {
        const json = tryParseJson<unknown>(jemResponse);
        if (!json) {
          throw new ApiError(jemResponse, {
            status: response.status,
            statusText: response.statusText
          });
        }
        return json as unknown as T;
      } else {
        return jemResponse as T;
      }
    }
    const text = await response.text();
    if (text.length === 0) {
      return null;
    }
    const json = tryParseJson<T>(text);
    if (!json && !Number.isInteger(json)) {
      return null;
    }
    return json;
  } catch (error: any) {
    logger?.appInsights?.trackException({
      exception: new Error("An error occurred while parsing the response"),
      properties: JSON.stringify({ response: response.toString(), error: error.toString() })
    });
    return null;
  }
}
