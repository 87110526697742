import React from "react";
import { useLocation } from "react-router-dom";
import { JemUserRoles } from "../../contexts";

export const MySettingsLinks = (
  jemUserRoles: JemUserRoles,
  location: ReturnType<typeof useLocation>,
  onClickHandler: (route: string) => (event: React.MouseEvent | any) => void
) => {
  const links = [
    {
      name: "My Profile",
      key: "myProfile",
      ariaLabel: "My Profile",
      target: "_blank",
      onClick: onClickHandler("/mySettings/profile"),
      isSelected: location.pathname === "/mySettings/profile"
    }
  ];

  links.push({
    name: "Delegate Reviewers",
    key: "delegateReviewers",
    ariaLabel: "Delegate Reviewers",
    target: "_blank",
    onClick: onClickHandler("/mySettings/delegateReviewers"),
    isSelected: location.pathname === "/mySettings/delegateReviewers"
  });

  return {
    name: "My Settings",
    key: "mysettings",
    ariaLabel: "My Settings",
    icon: "Settings",
    target: "_blank",
    links
  };
};
