import {
  JemNotification,
  ApiError,
  JemConfiguration,
  UserContext,
  ErrorHelper,
  getValidUrl,
  postRequest,
  FatalError,
  ErrorMessages
} from "@jem/components";
import { useContext } from "react";

import { PoAttachment, IHCCResponse } from "../../shared/IHCC.types";
import { ValidateReviewers } from "../../shared/IHCC.utilities";

export interface IPaymentOrderObject {
  actionType: string;
  addtionalReviewersCSVToDB: string;
  bankArea: string;
  comments: string;
  id: number;
  isBulk: boolean;
  orderingPartyAccountNumber: string | null;
  poApprovers: any[];
  poAttachments: PoAttachment[];
  processingStatus: string;
  purpose: string | null;
  receivingPartyAccountNumber: string | null;
  receivingPartyAmountInAccountCurrency: number;
  receivingPartyCurrency: string | null;
  transactionType: string | null;
  refGuid: string;
  rowVer: string | null;
  supervisorAliasToDB: string;
}

export interface CreateActionPayload {
  PaymentOrderList: IPaymentOrderObject[];
  actionType: string;
  createdDate: string;
}

export interface Result {
  id: number;
  poId: number;
  fileName: string;
  typeId: number;
  status: number;
}

export enum ActionTypesForCreate {
  "save" = "save",
  "submitForReview" = "submitForReview",
  "clearForm" = "clearForm",
  "validateReviewers" = "validateReviewers"
}

export interface CreateActionResult {
  actionType: ActionTypesForCreate;
  notification: JemNotification;
}

export interface CreateActionOnSubmitHandlerMap {
  [ActionTypesForCreate.save]: (payload: CreateActionPayload) => Promise<CreateActionResult>;
  [ActionTypesForCreate.submitForReview]: (payload: CreateActionPayload) => Promise<CreateActionResult>;
  [ActionTypesForCreate.clearForm]: () => Promise<CreateActionResult>;
  [ActionTypesForCreate.validateReviewers]: (payload: string) => Promise<CreateActionResult>;
}

async function handleApiError(
  actionType: ActionTypesForCreate,
  fn: () => Promise<CreateActionResult>
): Promise<CreateActionResult> {
  try {
    const result = await fn();
    return result;
  } catch (e) {
    if (e instanceof ApiError) {
      const message = (e as ApiError).message;
      const result: CreateActionResult = {
        actionType: actionType,
        notification: {
          subjectHeader: message,
          summaryBodyText: message,
          type: "Error"
        }
      };
      return result;
    }
    throw e;
  }
}

export function useIHCCCreateActions(configuration: JemConfiguration["IhccApi"]): CreateActionOnSubmitHandlerMap {
  const { accessToken } = useContext(UserContext);

  const makeUrl = (endpoint: string) => {
    const base = configuration.baseApiUrl;
    return getValidUrl(`${base}${endpoint}`);
  };

  return {
    [ActionTypesForCreate.save]: async (payload: CreateActionPayload) => {
      const action = async (): Promise<CreateActionResult> => {
        const url = makeUrl(configuration.endpoints.createPageSaveDraft);
        const response = await postRequest<IHCCResponse>(url, payload, accessToken);

        if (response === null) {
          throw new FatalError("Failed to Save IHCC draft");
        }
        const msg = ErrorHelper.getMessage(response.responseCode as keyof typeof ErrorMessages);
        return {
          actionType: ActionTypesForCreate.save,
          notification: {
            type: "responseCode" in response && response.responseCode.indexOf("ERR") !== -1 ? "Error" : "Success",
            subjectHeader: msg
          }
        };
      };
      const result = await handleApiError(ActionTypesForCreate.save, action);
      return result;
    },
    [ActionTypesForCreate.submitForReview]: async (payload: CreateActionPayload) => {
      const action = async (): Promise<CreateActionResult> => {
        const url = makeUrl(configuration.endpoints.createPageSubmitForReview);
        const response = await postRequest<IHCCResponse>(url, payload, accessToken);
        if (response === null) {
          throw new FatalError("Failed to Submit for Review");
        }
        const keyCode = response.responseCode as keyof typeof ErrorMessages;
        const msg =
          keyCode == "INF1043" || keyCode == "INF1276"
            ? ErrorHelper.getMessage("INF1276" as keyof typeof ErrorMessages)
            : ErrorHelper.getMessage(response.responseCode as keyof typeof ErrorMessages);
        return {
          actionType: ActionTypesForCreate.submitForReview,
          notification: {
            type: "responseCode" in response && response.responseCode.indexOf("ERR") !== -1 ? "Error" : "Success",
            subjectHeader: msg
          }
        };
      };
      const result = await handleApiError(ActionTypesForCreate.submitForReview, action);
      return result;
    },
    [ActionTypesForCreate.clearForm]: async () => {
      const url = makeUrl(configuration.endpoints.createPageClearDraft);
      await postRequest<any>(url, undefined, accessToken);
      return {
        actionType: ActionTypesForCreate.clearForm,
        notification: {
          type: "Success",
          subjectHeader: "Form Cleared"
        }
      };
    },
    [ActionTypesForCreate.validateReviewers]: async (reviewers: string) => {
      const notification = await ValidateReviewers(configuration, accessToken, reviewers);
      return {
        actionType: ActionTypesForCreate.validateReviewers,
        notification
      };
    }
  };
}
