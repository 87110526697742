import { OrderedMap } from "immutable";
import { useLocation } from "react-router-dom";
import { LoadingStatus } from "../../utilities/Utilities";
import { Filters } from "../DashboardFilterBar/DashboardFilterBar";

export class FilterState {
  currentTile: string | null;
  searchCriteria: string | null;
  filterBar: Filters;
  constructor(opts: { currentTile: string | null; searchCriteria: string | null; filterBar: Filters }) {
    this.currentTile = opts.currentTile;
    this.searchCriteria = opts.searchCriteria;
    this.filterBar = opts.filterBar;
    if ([this.currentTile, this.searchCriteria, this.filterBar].some((val) => val === undefined)) {
      throw new Error("FilterState requires currentTile, searchCriteria, and filterBar");
    }
  }
}

export interface IPivotItem {
  count: number;
  name: string;
}

export interface IIndexedTile {
  tileIndex: number;
  tileCount: number;
}

export interface IDashboardTileRef<ListType> {
  refreshTilesAndFilters: (newItems: ListType[], optionalColumnNameForTiles?: string) => void;
}

export interface FilterSchema<ListType> {
  filterName: string;
  columnName: keyof ListType | null;
  singleSelection?: boolean;
}

export interface FilterSchemaTile {
  filterName: string;
  columnName: null;
  singleSelection: true;
}

export interface IDashboardTilesCommonProps<ListType> {
  singleSelectionFilters: string[];
  searchEnabledFilters: string[];
  loadingStatus: LoadingStatus;
  // Function to trigger when the User changes Tiles or Selects a new filter for the data
  onFilterChanged: (filterState?: FilterState) => void;
  filters: FilterSchema<ListType>[];
  savedFilters?: Filters;
  location?: ReturnType<typeof useLocation>;
}

export interface IDashboardTilesPropsTilesFromColumn<ListType> {
  // Ref allows developer to refresh tiles and filters
  customRef: React.Ref<IDashboardTileRef<ListType>>;
  tilesAndFilters?: never;
}

export interface IDashboardTilesPropsTilesArray<ListType> {
  customRef?: never;
  tilesAndFilters: {
    gridItems: ListType[];
    tiles: OrderedMap<string, IIndexedTile>;
    currentTile: string;
    searchCriteria?: string | null;
  };
}

export type IDashboardTilesProps<ListType> = IDashboardTilesCommonProps<ListType> &
  (IDashboardTilesPropsTilesFromColumn<ListType> | IDashboardTilesPropsTilesArray<ListType>);
