export interface TreasuryRow {
  approvedDate: string;
  comment: string;
  companyCode: number;
  fiscalPeriod: number;
  fiscalYear: number;
  isEditable: boolean;
  opAccount: string;
  opAmount: number;
  opCurrency: string;
  opTier: string;
  opType: string;
  poId: number;
  poNumber: string;
  receivingPartyAmountInAccountCurrency: null;
  receivingPartyCurrency: string;
  refGuid: string;
  releaseDate: string;
  releasedBy: string;
  requestor: string;
  reversalDate: string;
  reversalItemIndicator: string;
  reviewer: string;
  rowVer: string;
  rpAccount: string;
  rpAmount: number;
  rpTier: string;
  rpType: string;
  significance: string;
  status: number;
  statusCode: string;
  transAmount: number;
  transDesc: string;
  transactionCurrency: string;
  usdAmount: number;
}

export interface SanitizedTreasuryRow {
  approvedDate: string;
  comment: string;
  companyCode: string;
  fiscalPeriod: string;
  fiscalYear: string;
  isEditable: boolean;
  opAccount: string;
  opAmount: string;
  opCurrency: string;
  opTier: string;
  opType: string;
  poId: string;
  poNumber: string;
  receivingPartyAmountInAccountCurrency: string;
  receivingPartyCurrency: string;
  refGuid: string;
  releaseDate: string;
  releasedBy: string;
  requestor: string;
  reversalDate: string;
  reversalItemIndicator: string;
  reviewer: string;
  rowVer: string;
  rpAccount: string;
  rpAmount: string;
  rpTier: string;
  rpType: string;
  significance: string;
  status: string;
  statusCode: string;
  transAmount: string;
  transDesc: string;
  transactionCurrency: string;
  usdAmount: string;
  processingStatus: string;
  isPosted: boolean;
}

export interface ITreasuryState {
  Status: number[];
  CompanyCode: number;
  Significance: string[];
  FiscalYears: number[];
  FiscalPeriods: number[];
  TenantId: number;
}

export const InitialTreasuryState = {
  Status: [],
  CompanyCode: 0,
  Significance: [],
  FiscalYears: [new Date().getFullYear(), new Date().getFullYear() + 1],
  FiscalPeriods: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
};

export interface ITreasuryStateForApi {
  POStatus: number[];
  CompanyCode: number;
  POCategory: string[];
  FiscalYear: number[];
  FiscalPeriod: number[];
  TenantId: number | null;
}

export type TreaasuryDashboardApiPayload = TreasuryRow[];

export const POStatusOptions = [
  { key: 0, text: "All" },
  { key: 15, text: "Completed" },
  { key: 6, text: "Failed in SAP" },
  { key: 5, text: "Posted" },
  { key: 16, text: "Release To SAP" },
  { key: 10, text: "Sign Off Pending" },
  { key: 12, text: "Signed Off" },
  { key: 7, text: "Signoff Not Req’d" }
];

export const convertToFiscalYearOptionTreasury = (fiscalYears: number[]) => {
  const fiscalYearDropdownObject = [{ key: 0, text: "All" }];

  for (let i = 0; i < 5; i++) {
    fiscalYearDropdownObject.push({ key: fiscalYears[i], text: `${fiscalYears[i]}` });
  }

  return fiscalYearDropdownObject;
};
