import { css } from "@emotion/css";
import { MessageBar, MessageBarType, Pivot, PivotItem, Separator } from "@fluentui/react";
import {
  JemConfiguration,
  MockDataFn,
  ILoggingProviderState,
  FatalError,
  ActionResult,
  LoggingContext,
  LoadingStatus,
  LoadingSpinner,
  PageStyles,
  PageHeading,
  redirectMeToTheOrigin
} from "@jem/components";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { IHCCDashboardData } from "../../components/IHCCDashboard/IHCCDashboard.types";
import {
  PODetailsActionFunctionMap,
  useIHCCDetailsActions
} from "../../components/IHCCPODetails/IHCCPODetails.Actions";
import IHCCPODetailsActionsBar, { PODetailsActionsEnum } from "../../components/IHCCPODetails/IHCCPODetails.ActionsBar";
import SAPPODetails from "../../components/IHCCPODetails/IHCCPODetails.SAPPODetails";
import IHCCPODetailsAttachments, {
  IHCCPODetailsAttachmentsRef
} from "../../components/IHCCPODetails/Tabs/IHCCPODetails.IHCCPODetailsAttachments";
import OtherDetailsTab from "../../components/IHCCPODetails/Tabs/IHCCPODetails.OtherDetailsTab";
import PODetailsTab from "../../components/IHCCPODetails/Tabs/IHCCPODetails.PODetailsTab";
import ReviewFormTab from "../../components/IHCCPODetails/Tabs/IHCCPODetails.ReviewFormTab";
import {
  IHCCPODetailsContextObject,
  DetailsTabNames,
  IHCCPODetailsContext
} from "../../contexts/IHCCPODetailsContext/IHCCPODetailsContext";
import { ActionsModel, DetailsPageModel } from "../../contexts/IHCCPODetailsContext/IHCCPODetailsContext.types";

// eslint-disable-next-line
const isEqual = require("lodash/isEqual");

export interface IHCCDashboardProps {
  configuration: JemConfiguration["IhccApi"];
  mockDashboardDataFn?: MockDataFn<IHCCDashboardData>;
}

function actionHandler(
  poData: IHCCPODetailsContextObject,
  poActions: PODetailsActionFunctionMap,
  logger: ILoggingProviderState,
  setSelectedKey: React.Dispatch<React.SetStateAction<DetailsTabNames>>,
  setMessage: React.Dispatch<React.SetStateAction<{ type: MessageBarType; message: string } | null>>,
  attachmentsRef: React.RefObject<IHCCPODetailsAttachmentsRef>
): (actionName: PODetailsActionsEnum) => void {
  return async function (actionName: PODetailsActionsEnum) {
    if (!attachmentsRef.current) {
      throw new FatalError("Attachments Ref is not defined");
    }

    if (actionName === PODetailsActionsEnum.Attach) {
      setSelectedKey(DetailsTabNames.attachments);
      return;
    }

    // if new local attachments save them too
    // attachments can't be more than 10 handled by attachments component
    const [attachmentData, errorMessage] = await attachmentsRef.current.getAttachments();
    if (errorMessage) {
      setSelectedKey(DetailsTabNames.attachments);
      setMessage({
        type: MessageBarType.error,
        message: errorMessage
      });
      return;
    }

    const error = poData.getErrorMessage(actionName);
    if (error) {
      setSelectedKey(error.tabName);
      setMessage({
        type: MessageBarType.error,
        message: error.error
      });
      return;
    }

    const commonOptions = {
      poId: poData.model.values.poId,
      refGuid: poData.model.values.refGuid,
      rowVersion: poData.model.values.rowVer,
      bulkGuid: poData.model.values.bulkRefGuid || ""
    };
    if (attachmentData.recentlyUploadedAttachments.length !== 0) {
      const result = await poActions.UpdatePoAttachments(commonOptions, attachmentData.recentlyUploadedAttachments);
      if (result.notification.type === "Error") {
        setMessage({
          type: MessageBarType.error,
          message: result.notification.summaryBodyText || result.notification.subjectHeader
        });
        logger.addNotification(result.notification);
        return;
      }
    }

    // if additionalreviewers changed save them
    const reviewers = poData.getReviewers();
    if (reviewers) {
      // will return error if there's an invalid reviewer
      // otherwise just hide the notification
      const result = await poActions.AddReviewers(commonOptions, reviewers);
      if (result.notification.type === "Error") {
        setMessage({
          type: MessageBarType.error,
          message: result.notification.summaryBodyText || result.notification.subjectHeader
        });
        logger.addNotification(result.notification);
        return;
      }
    }

    let result: ActionResult = {
      actionType: actionName as any,
      notification: {
        type: "Error",
        subjectHeader: "No action taken"
      }
    };
    const comments = poData.getComments();
    if (actionName === PODetailsActionsEnum.Save) {
      result = await poActions.Save(commonOptions, comments === "" ? null : comments);
    } else if (actionName === PODetailsActionsEnum.ReleaseForSignOff) {
      result = await poActions.ReleaseForSignOff(commonOptions, comments);
    } else if (actionName === PODetailsActionsEnum.NeedClarification) {
      result = await poActions.NeedClarification(commonOptions, comments);
    } else if (actionName === PODetailsActionsEnum.SignOff) {
      result = await poActions.SignOff(commonOptions, comments);
    } else if (actionName === PODetailsActionsEnum.SaveSAPInfo) {
      logger.appInsights?.trackTrace({
        message: "SaveSAPInfo triggered and not implemented"
      });
    } else if (actionName === PODetailsActionsEnum.Reverse) {
      if (confirm("Are you sure you want to reverse the PO?")) {
        result = await poActions.Reverse(commonOptions);
      } else {
        result.notification = {
          type: "Information",
          subjectHeader: `PO ${commonOptions.poId} Reversal cancelled`
        };
      }
    }

    setMessage({
      type: result.notification.type === "Error" ? MessageBarType.error : MessageBarType.success,
      message: result.notification.summaryBodyText || result.notification.subjectHeader
    });
    logger.addNotification(result.notification);
    poData.reloadDetails();
  };
}

const IHCCPODetails: React.FC<IHCCDashboardProps> = (props) => {
  const poData = useContext(IHCCPODetailsContext);
  const poActions = useIHCCDetailsActions(props.configuration);
  const logger = useContext(LoggingContext);
  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.Idle);
  const [saveBtnDisabled, setSaveBtnDisabled] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [poActionsModel, setPoActionsModel] = useState<ActionsModel | null>(null);
  const [detailsModel, setDetailsModel] = useState<DetailsPageModel | null>(null);

  const attachmentsRef = useRef<IHCCPODetailsAttachmentsRef>(null);
  const [message, setMessage] = useState<{
    type: MessageBarType;
    message: string;
  } | null>(null);
  const [recentUploads, setRecentUploads] = useState<number>(0);
  const [selectedKey, setSelectedKey] = useState<DetailsTabNames>(DetailsTabNames.poDetails);

  useEffect(() => {
    if (poData.model.status === LoadingStatus.Resolved && poData.actionDetails.status === LoadingStatus.Resolved) {
      setPoActionsModel(poData.actionDetails.values);
      setDetailsModel(poData.model.values);
      setLoadingStatus(LoadingStatus.Resolved);
    }
  }, [poData.actionDetails.status, poData.model.status]);

  useEffect(() => {
    document.body.scrollTop = 0;
  }, []);

  useEffect(() => {
    const localAttachments = poData.userChanges.localAttachments.length;
    const remoteAttachments = poData.model.values.attachmentsTab.length;
    setRecentUploads(localAttachments + remoteAttachments);
  }, [poData.userChanges.localAttachments, poData.model.values.attachmentsTab]);

  const handleLinkClick = (item?: PivotItem) => {
    if (item && item.props.itemKey) {
      setSelectedKey(item.props.itemKey as DetailsTabNames);
    }
    const localAttachments = poData.userChanges.localAttachments.length;
    const remoteAttachments = poData.model.values.attachmentsTab.length;
    setRecentUploads(localAttachments + remoteAttachments);
  };

  if (detailsModel === null || poActionsModel === null) {
    return <LoadingSpinner label="Loading PO Details" />;
  } else {
    return (
      <div className={PageStyles.rootDiv}>
        <PageHeading
          onClose={() => {
            let origin = "/ihcc/dashboard";
            const llocState = location.state as unknown as { origin: string; state: any };
            if (location.state && "origin" in llocState) {
              origin = llocState.origin || origin;
            }
            redirectMeToTheOrigin(navigate, location, 0, origin);
          }}
          closeLabel="Close"
        >
          <h2>Payment Order{detailsModel.poId ? ` ${detailsModel.poId} ` : " "}Details</h2>
        </PageHeading>

        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12">
            <IHCCPODetailsActionsBar
              setSaveButtonDisabledStatus={setSaveBtnDisabled}
              loadingStatus={loadingStatus}
              actionsModel={poActionsModel}
              detailsPageModel={detailsModel}
              onAction={actionHandler(poData, poActions, logger, setSelectedKey, setMessage, attachmentsRef)}
              //same logic used for enabling/disabling add atchment button
              addAttachmentEnabled={poData.model.values.detailsTab.access !== 2}
            ></IHCCPODetailsActionsBar>
          </div>
          {message ? (
            <div>
              <MessageBar
                delayedRender={false}
                messageBarType={message.type}
                dismissButtonAriaLabel="Close"
                onDismiss={() => {
                  setMessage(null);
                }}
              >
                {message.message}
              </MessageBar>
            </div>
          ) : null}
        </div>
        <div
          className={css`
            display: inline-flex;
            gap: 16px;
            flex-direction: column;
            flex-wrap: wrap;
            justify-content: center;
            align-items: stretch;
            width: 100%;
            margin: 16px 0 16px 0;
          `}
        >
          <Pivot
            aria-label="Payment Order Details Sections"
            linkSize="large"
            linkFormat="tabs"
            selectedKey={selectedKey}
            onLinkClick={handleLinkClick}
          >
            <PivotItem headerText="PO Details" itemKey={DetailsTabNames.poDetails}>
              <Separator />
              <PODetailsTab saveBtnDisabled={saveBtnDisabled} />
            </PivotItem>
            <PivotItem
              headerText={`Attachments(${recentUploads})`}
              itemKey={DetailsTabNames.attachments}
              alwaysRender={true}
            >
              <Separator />
              <IHCCPODetailsAttachments
                ref={attachmentsRef}
                configuration={props.configuration}
                onChange={(localAttachments, remoteAttachments) => {
                  const newCount = localAttachments.length + remoteAttachments.length;
                  setRecentUploads(newCount);
                  const localChangeEqualToLocalState = isEqual(localAttachments, poData.userChanges.localAttachments);
                  if (!localChangeEqualToLocalState) {
                    poData.setUserChanges({
                      localAttachments
                    });
                  }
                }}
                onDelete={() => {
                  poData.reloadDetails();
                }}
              />
            </PivotItem>
            <PivotItem headerText="Review" itemKey={DetailsTabNames.review}>
              <Separator />
              <ReviewFormTab saveBtnDisabled={saveBtnDisabled} />
            </PivotItem>
            {poData.bulkDetails.status === LoadingStatus.Resolved ? (
              <PivotItem headerText="Other Details" itemKey={DetailsTabNames.otherDetails}>
                <Separator />
                <OtherDetailsTab />
              </PivotItem>
            ) : null}
          </Pivot>
          <>
            <Separator />
            {(poData.sapPoDetails.status !== LoadingStatus.Rejected &&
              poData.sapPoDetails.status !== LoadingStatus.Resolved) ||
            poData.sapPoDetails.values === null ? (
              <LoadingSpinner label="Loading SAP Details" />
            ) : (
              <SAPPODetails details={poData.model.values.detailsTab} sapModel={poData.sapPoDetails.values} />
            )}
          </>
        </div>
      </div>
    );
  }
};

IHCCPODetails.displayName = "IHCCPODetails";

export default IHCCPODetails;
