import {
  ActionResult,
  ActionTypes,
  FatalError,
  getValidUrl,
  IAttachment,
  JemConfiguration,
  JemNotification,
  postRequest,
  UserContext
} from "@jem/components";
import { useContext } from "react";

import { responseHandler, useIHCCActions } from "../../shared/IHCC.Actions";

export enum PODetailsEndpointActionsEnum {
  Save = "Save",
  SaveSAPInfo = "SaveSAPInfo",
  ReleaseForSignOff = "ReleaseForSignOff",
  SignOff = "SignOff",
  NeedClarification = "NeedClarification",
  UpdatePoAttachments = "UpdatePoAttachments",
  AddReviewers = "AddReviewers",
  Reverse = "Reverse"
}

export const ActionNameToEndpoint = (ihccConfiguration: JemConfiguration["IhccApi"]) => ({
  [PODetailsEndpointActionsEnum.Save]: ihccConfiguration.endpoints.updatePO,
  [PODetailsEndpointActionsEnum.SaveSAPInfo]: "actionSave???? TODO",
  [PODetailsEndpointActionsEnum.ReleaseForSignOff]: ihccConfiguration.endpoints.actionReleaseForSignoff,
  [PODetailsEndpointActionsEnum.SignOff]: ihccConfiguration.endpoints.actionApprove,
  [PODetailsEndpointActionsEnum.NeedClarification]: ihccConfiguration.endpoints.actionNeedsClarification,
  [PODetailsEndpointActionsEnum.UpdatePoAttachments]: ihccConfiguration.endpoints.actionAddAttachment,
  [PODetailsEndpointActionsEnum.AddReviewers]: ihccConfiguration.endpoints.actionAddReviewer,
  [PODetailsEndpointActionsEnum.Reverse]: ihccConfiguration.endpoints.actionReversePo
});

export interface PODetailsActionFunctionMap {
  [PODetailsEndpointActionsEnum.Save]: (opts: CommonDetailsOptions, comments: string | null) => Promise<ActionResult>;
  [PODetailsEndpointActionsEnum.SaveSAPInfo]: (opts: CommonDetailsOptions) => Promise<ActionResult>;
  [PODetailsEndpointActionsEnum.ReleaseForSignOff]: (
    opts: CommonDetailsOptions,
    comments: string
  ) => Promise<ActionResult>;
  [PODetailsEndpointActionsEnum.SignOff]: (
    opts: CommonDetailsOptions,
    comments: string | null
  ) => Promise<ActionResult>;
  [PODetailsEndpointActionsEnum.NeedClarification]: (
    opts: CommonDetailsOptions,
    comments: string
  ) => Promise<ActionResult>;
  [PODetailsEndpointActionsEnum.UpdatePoAttachments]: (
    opts: CommonDetailsOptions,
    attachments: IAttachment[]
  ) => Promise<ActionResult>;
  [PODetailsEndpointActionsEnum.AddReviewers]: (opts: CommonDetailsOptions, reviewers: string) => Promise<ActionResult>;
  [PODetailsEndpointActionsEnum.Reverse]: (opts: CommonDetailsOptions) => Promise<ActionResult>;
}

export interface CommonDetailsOptions {
  poId: string;
  rowVersion: string | null;
  refGuid: string;
  bulkGuid: string;
}

export function useIHCCDetailsActions(configuration: JemConfiguration["IhccApi"]): PODetailsActionFunctionMap {
  const { accessToken } = useContext(UserContext);
  const actions = useIHCCActions<CommonDetailsOptions>(configuration);

  return {
    [PODetailsEndpointActionsEnum.Save]: async (opts: CommonDetailsOptions, comments: string | null) => {
      // call updatepo here
      let payload = {
        Editable: false,
        RefGuids: [opts.refGuid]
      };
      if (comments) {
        payload = Object.assign(
          {
            comments
          },
          payload
        );
      }
      const base = configuration.baseApiUrl;
      const endpoint = getValidUrl(`${base}${configuration.endpoints.updatePO}`);
      const notification = await responseHandler(ActionTypes.save, endpoint, payload, accessToken);
      return {
        actionType: ActionTypes.save,
        notification: {
          ...notification,
          summaryBodyText: "The PO details are saved successfully. Please search again to take any further action."
        }
      };
    },
    // TODO: add saveSAPInfo
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    [PODetailsEndpointActionsEnum.SaveSAPInfo]: async (opts: CommonDetailsOptions) => {
      return Promise.reject({
        type: PODetailsEndpointActionsEnum.SaveSAPInfo,
        notification: {
          type: "error",
          message: "Save SAP info not implemented yet"
        }
      });
    },
    // ihccConfiguration.endpoints.actionReleaseForSignoff
    [PODetailsEndpointActionsEnum.ReleaseForSignOff]: async (opts: CommonDetailsOptions, comments: string) => {
      if (!actions.releaseForSignoff) {
        throw new FatalError("Needs release for signoff action not available");
      }
      return actions.releaseForSignoff([opts], comments);
    },
    // ihccConfiguration.endpoints.actionApprove
    [PODetailsEndpointActionsEnum.SignOff]: async (opts: CommonDetailsOptions, comments: string | null) => {
      if (!actions.approve) {
        throw new FatalError("Signoff action not found");
      }
      return actions.approve([opts], comments || "");
    },
    // ihccConfiguration.endpoints.actionNeedsClarification
    [PODetailsEndpointActionsEnum.NeedClarification]: async (opts: CommonDetailsOptions, comments: string) => {
      if (!actions.needsClarification) {
        throw new FatalError("Needs Clarification action not available");
      }
      return actions.needsClarification([opts], comments, []);
    },
    // ihccConfiguration.endpoints.actionAddAttachment
    [PODetailsEndpointActionsEnum.UpdatePoAttachments]: async (
      opts: CommonDetailsOptions,
      attachments: IAttachment[]
    ) => {
      if (!actions.addAttachment) {
        throw new FatalError("No addAttachment action");
      }
      return actions.addAttachment([opts], attachments);
    },
    // ihccConfiguration.endpoints.actionAddReviewer
    [PODetailsEndpointActionsEnum.AddReviewers]: async (opts: CommonDetailsOptions, reviewers: string) => {
      if (!actions.addReviewer) {
        throw new FatalError("No action for Add Reviewer");
      }
      return actions.addReviewer([opts], "", reviewers.split(";"));
    },
    [PODetailsEndpointActionsEnum.Reverse]: async (opts: CommonDetailsOptions) => {
      const payload = opts.refGuid;
      const endpoint = getValidUrl(`${configuration.baseApiUrl}${configuration.endpoints.actionReversePo}`);
      const response = await postRequest<{ status: boolean }>(endpoint, payload, accessToken);
      return {
        actionType: "Reverse" as ActionTypes,
        notification: {
          subjectHeader: !response || !response.status ? "Error" : "Success",
          summaryBodyText:
            !response || !response.status
              ? "Could not Reverse PO"
              : `The PO ${opts.poId} has been reversed successfully.`,
          type: !response || !response.status ? "Error" : "Success"
        } as JemNotification
      };
    }
  };
}
