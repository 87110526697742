import React, { useImperativeHandle, useMemo, useState } from "react";
import { Dropdown, IDropdownOption } from "@fluentui/react";
import {
  CommonDropdownOnChangeHandler,
  DomainDataEnum,
  DomainDataObjects,
  ForceMaxWidthAndHeightOnDropdownStyle
} from "@jem/components";

export interface JETypeRef {
  getJEType: () => string[];
  setInitialJEType: (initialJEType: string[]) => void;
  reset: () => void;
}

interface IJETypeProps {
  loading: boolean;
  domainData: Pick<DomainDataObjects, DomainDataEnum.JeTypes>;
  customRef: React.Ref<JETypeRef>;
}

export const JETypeOptions = (domainData: Pick<DomainDataObjects, DomainDataEnum.JeTypes>) => {
  const duplicatePreventer: Record<string, boolean> = {};
  const jeTypesForDropdown: IDropdownOption[] = domainData.JeTypes.reduce((bgsArray, bg) => {
    if (!duplicatePreventer[bg.name]) {
      duplicatePreventer[bg.name] = true;
      bgsArray.push({
        key: bg.code,
        text: bg.name.trim().slice(0, 2)
      });
    }
    return bgsArray;
  }, [] as IDropdownOption[]);
  jeTypesForDropdown.unshift({ key: "all", text: "All" });
  jeTypesForDropdown.unshift({ key: "none", text: "None Selected", disabled: true });
  return jeTypesForDropdown;
};

const JETypeDropdown: React.FC<IJETypeProps> = (props) => {
  const [JEType, setJEType] = useState<string[]>(["all"]);
  const jeTypeOptions = useMemo(() => {
    return JETypeOptions(props.domainData);
  }, [props.domainData]);

  const onChangeJEType = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void => {
    if (!option) {
      return;
    }

    const newOptions = CommonDropdownOnChangeHandler({
      currentOptions: JEType,
      option: option,
      keyForAll: "all",
      keyForNone: "none",
      totalOptions: jeTypeOptions.length - 2
    });
    setJEType(newOptions);
  };

  useImperativeHandle(
    props.customRef,
    (): JETypeRef => ({
      getJEType() {
        return JEType;
      },
      setInitialJEType(initialJEType: string[]) {
        setJEType(initialJEType);
      },
      reset() {
        setJEType(["all"]);
      }
    })
  );

  return (
    <React.Fragment>
      <Dropdown
        label="JE Type:"
        selectedKeys={JEType}
        onChange={onChangeJEType}
        options={jeTypeOptions}
        disabled={props.loading}
        multiSelect
        styles={ForceMaxWidthAndHeightOnDropdownStyle({
          height: "300px",
          width: "100%",
          maxWidth: "100%"
        })}
      />
    </React.Fragment>
  );
};

JETypeDropdown.displayName = "JETypeDropdown";
export default JETypeDropdown;
