import { JemConfiguration, IUserProviderState, getValidUrl, ApiError, postRequest } from "@jem/components";
import {
  JEMChecklistTask,
  JEMChecklistTasksResponseFromApi,
  sanitizeChecklistTasksRow
} from "./JEMChecklistDashboardByCompanyCode.schemas";

export async function getChecklistTasksByCompanyCode(
  configuration: JemConfiguration["GeneralLedgerApi"],
  getTokenFn: IUserProviderState["accessToken"],
  fiscalYear: number,
  fiscalPeriod: number,
  companyCodes: string[]
): Promise<JEMChecklistTask[]> {
  const endpoint = `${configuration.baseApiUrl}${configuration.endpoints.fcwSearchTasks}`
    .replace("{fiscalYear}", fiscalYear.toString())
    .replace("{fiscalPeriod}", fiscalPeriod.toString());

  const payload = {
    companyCodes: companyCodes
  };

  const endpointUrl = getValidUrl(endpoint);
  const response = await postRequest<JEMChecklistTasksResponseFromApi>(endpointUrl, payload, getTokenFn);
  if (!response) {
    throw new ApiError("No response from server");
  }

  const sanitizedDraftJEMChecklistResult = await sanitizeChecklistTasksResultSet(response.items);
  return sanitizedDraftJEMChecklistResult;
}

export function sanitizeChecklistTasksResultSet(results: any[]): Promise<JEMChecklistTask[]> {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      try {
        const sanitizedResults = results.map((row) => sanitizeChecklistTasksRow(row));
        resolve(sanitizedResults);
      } catch (error) {
        reject(error);
      }
    }, 1);
  });
}
