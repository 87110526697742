import { ITextFieldProps, TextField, Text } from "@fluentui/react";
import React, { useImperativeHandle, useState } from "react";

export interface RefTextFieldRef {
  getValue: () => string;
  reset: () => void;
  setInitialValue: (theValue: string) => void;
}

export interface RefTextFieldProps {
  customRef: React.Ref<RefTextFieldRef>;
  label: string;
  styles?: ITextFieldProps["styles"];
  required?: boolean;
  type?: string;
}

const RefTextField: React.FC<RefTextFieldProps> = (props) => {
  const [value, setValue] = useState<string>("");

  useImperativeHandle(props.customRef, () => ({
    getValue: () => {
      return value;
    },
    reset: () => {
      setValue("");
    },
    setInitialValue: (theValue: string) => {
      setValue(theValue);
    }
  }));
  return (
    <>
      <TextField
        value={value}
        label={props.label}
        placeholder={props.label === "Doc Number/s:" ? "Multiples allowed seperated by comma (,)" : ""}
        errorMessage=""
        onChange={(_, value) => {
          if (props.type === "number") {
            if (value === "") setValue("");
            if (value && /^\d*$/.test(value)) {
              setValue(value);
            }
            return;
          }
          setValue(value || "");
        }}
        styles={props.styles}
        required={props.required}
      />
      {props.type === "number" && (
        <Text
          nowrap
          variant="xSmall"
          block
          style={{
            marginTop: "4px",
            color: "var(--accent-font-color, black)"
          }}
        >
          Enter only numbers
        </Text>
      )}
    </>
  );
};

RefTextField.displayName = "RefTextField";
export { RefTextField };
