import { GLCreateOverrides, JemConfiguration, JEMContext, LoadingSpinner, LoadingStatus } from "@jem/components";
import React, { useContext, useEffect, useState } from "react";

import { ComponentProvider } from "@micro-frontend-react/core/lib/ComponentProvider/ComponentProvider";
import { useLocation } from "react-router-dom";
import { JECreateExistingValues } from "../../components/JECreate/JECreateExistingForm";

interface MicrofrontendAdapterProps {
  configuration: JemConfiguration;
}

const MicrofrontendAdapter: React.FC<MicrofrontendAdapterProps> = (props) => {
  const [showLoading, setShowLoading] = useState(false);

  const location = useLocation();
  const state = location.state as GLCreateOverrides | JECreateExistingValues | null;

  const [domainDataStatus, setDomainDataStatus] = useState<LoadingStatus>(LoadingStatus.Idle);
  const jemContext = useContext(JEMContext);

  useEffect(() => {
    if (
      jemContext.initInfo.status === LoadingStatus.Rejected ||
      jemContext.initInfo.status === LoadingStatus.Resolved
    ) {
      setDomainDataStatus(LoadingStatus.Resolved);
    }
  }, [jemContext.initInfo.values]);

  if (domainDataStatus !== LoadingStatus.Resolved || jemContext.initInfo.values === null) {
    return <LoadingSpinner label="Loading Domain Data" />;
  }

  // const componentsUrl = "http://localhost:8050";
  const componentsUrl = props.configuration.jemUiComponentsEndpoint;
  const pathToComponents =
    componentsUrl.indexOf("localhost") !== -1
      ? ""
      : `/jem-microfrontends/${props.configuration.jemUiComponentsVersion}`;
  const pathToConfig = componentsUrl.indexOf("localhost") !== -1 ? "" : "/jem-microfrontends";
  return (
    <>
      {showLoading && <LoadingSpinner label="Loading Component" />}
      <ComponentProvider
        config={{
          script: `${componentsUrl}${pathToComponents}/JEMJECreate/JEMJECreate.js`,
          name: "JEMJECreate"
        }}
        onError={() => {
          setShowLoading(false);
        }}
        onLoad={() => {
          setShowLoading(true);
        }}
        onLoaded={() => {
          setShowLoading(false);
        }}
        data={{
          baseName: "/JEMJECreate",
          baseUrl: `${componentsUrl}${pathToConfig}`,
          inputProps: state && "refGuid" in state ? undefined : state,
          refGuid: state && "refGuid" in state ? state.refGuid : undefined
        }}
      />
    </>
  );
};

MicrofrontendAdapter.displayName = "MicrofrontendAdapter";
export { MicrofrontendAdapter };
