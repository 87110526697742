import { JemConfiguration } from "../../../JemConfiguration";
import { DomainDataEnum, DomainDataObjects } from "../../../Shared/contexts/JEMContext/JEMContext.domainData.types";
import { ApiError, FatalError, RuntimeError } from "../../../Shared/utilities/ErrorHelpers";
import { getRequest, getValidUrl, IUserProviderState, postRequest } from "../../../Shared/utilities/RequestUtilities";
import { IconMappingForSignificance } from "../../../Shared/utilities/SignificanceTypeAndIcon";
import { GLDetailsRequestsEntities } from "./GLDetails.types";
import { sapDetailsEntityToState } from "./GLDetails.SapDetails.State";
import { detailsEntityToState } from "./GLDetails.jeid.State";
import { GLJeDetailsState, GLJeSapDetailsState } from "./GLDetailsForm.types";

export async function fetchJeDetails(
  configuration: JemConfiguration["GeneralLedgerApi"],
  getTokenFn: IUserProviderState["accessToken"],
  domainData: Pick<DomainDataObjects, DomainDataEnum.JeStatus | DomainDataEnum.JeClarificationCodes> | null,
  jeId: string,
  iconMapping: IconMappingForSignificance
): Promise<GLJeDetailsState> {
  if (jeId === "") {
    throw new FatalError("Please provide a valid jeId.");
  }

  if (domainData === null) {
    throw new RuntimeError(
      "Something is wrong with JE Statuses and JE Clarification Codes, please contact engineering."
    );
  }

  const endpoint = `${configuration.baseApiUrl}${configuration.endpoints.getDetails}`.replace("{jeId}", jeId);
  const endpointUrl = getValidUrl(endpoint);
  const responseObject = await getRequest<GLDetailsRequestsEntities["GLDetails"]>(endpointUrl, getTokenFn);
  if (!responseObject) {
    throw new ApiError("Details not found.", {
      jeId
    });
  }

  return detailsEntityToState(domainData, responseObject, iconMapping, "FI");
}

export interface SapDetailsPayload {
  compcode: number;
  docNum: number;
  fYear: number;
  Module: string;
  jeType: string;
}

export async function fetchSapDetails(
  configuration: JemConfiguration["GeneralLedgerApi"],
  getTokenFn: IUserProviderState["accessToken"],
  payload: SapDetailsPayload
): Promise<GLJeSapDetailsState | null> {
  if (payload != null && payload.Module === "undefined") {
    payload.Module = "0"; //setting to FI module
  }
  const endpoint = `${configuration.baseApiUrl}${configuration.endpoints.postFetchInfoFromSap}`;
  const endpointUrl = getValidUrl(endpoint);
  const responseObject = await postRequest<GLDetailsRequestsEntities["GLDetailsSap"]>(endpointUrl, payload, getTokenFn);
  if (!responseObject) {
    throw new ApiError("Can't retrieve SAP Details right now.");
  }
  return sapDetailsEntityToState(responseObject);
}
