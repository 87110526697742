import React, { PropsWithChildren, useContext, useRef, useState } from "react";
import { ActionsContext } from "./Actions.ActionsContext";
import { ActionTitle } from "./Actions.ActionTitle";
import { BaseActionProps, CommonPropsOnRows } from "./Actions.types";

import { ActionResult, ActionTypes, GLActionEndpointMap } from "../ActionManager/ActionsManager.types";
import { submissionStyles } from "./Actions.BottomButtons";
import { BaseButton, Button, DefaultButton, ITextField, PrimaryButton, Separator, TextField } from "@fluentui/react";
import { GLAttachments, GLAttachmentsProps, GLAttachmentsRef } from "../../../GeneralLedger/shared/GL.Attachments";
import { Form, Formik } from "formik";
import { LoadingStatus } from "../../utilities";

interface RescindMainProps<ListType, ResultType> extends BaseActionProps<ListType, ResultType> {
  onSubmitHandler: NonNullable<GLActionEndpointMap<ListType, ResultType>[ActionTypes.rescind]>;
}
export type GLRescindProps<ListType, ResultType> = RescindMainProps<ListType, ResultType> &
  Omit<
    GLAttachmentsProps,
    "customRef" | "initialAttachments" | "disabled" | "loadingStatus" | "lockRegion" | "initialRegion"
  >;

const GLRescind = <ListType extends CommonPropsOnRows, ResultType extends ActionResult>(
  props: PropsWithChildren<GLRescindProps<ListType, ResultType>>
) => {
  const { styles } = useContext(ActionsContext);
  const attachmentsRef = useRef<GLAttachmentsRef>(null);
  const textRef = useRef<ITextField>(null);
  const [disabled, setDisabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  const onCancel = (
    event: React.MouseEvent<
      HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | BaseButton | Button | HTMLSpanElement,
      MouseEvent
    >
  ) => {
    event.stopPropagation();
    setErrorMessage("");
    props.onCancel();
  };

  return (
    <>
      <Formik
        initialValues={{
          attachments_count: 0,
          attachments_error: "",
          attachments_region: "2"
        }}
        onSubmit={async (values, { setSubmitting }) => {
          if (textRef.current) {
            if (!textRef.current.value) {
              setErrorMessage("Please add a value");
            } else {
              if (errorMessage) {
                setErrorMessage("");
              }
              setSubmitting(true);
              if (attachmentsRef.current) {
                const [attachments, error] = await attachmentsRef.current.saveAndGetAttachments();
                if (!error) {
                  const result = await props.onSubmitHandler(
                    props.items,
                    textRef.current.value,
                    attachments.attachments,
                    attachments.region_key,
                    attachments.region_name
                  );
                  setSubmitting(false);
                  props.onSubmit(result);
                } else {
                  setSubmitting(false);
                }
              }
            }
          }
        }}
      >
        {(_formik) => (
          <Form>
            <ActionTitle onCloseButton={() => props.onCancel()} name={"Rescind"}></ActionTitle>
            <div className={styles.contentStyles.body}>
              <div className="action-manager--footer-action">
                <Separator
                  styles={{
                    root: {
                      lineHeight: "3px"
                    }
                  }}
                />
                <TextField
                  name="dialogText"
                  label={"Comments"}
                  componentRef={textRef}
                  errorMessage={errorMessage}
                  onChange={async (event, newValue) => {
                    event.stopPropagation();
                    const textBoxVal = newValue;
                    const theresNoAttachments = attachmentsRef.current?.getLocalAttachments().length === 0;
                    const theresAnErrorInAttachments = attachmentsRef.current?.getError() !== null;
                    const theresNoTextBoxVal = !textBoxVal || textBoxVal === "";
                    if (theresNoTextBoxVal) {
                      setErrorMessage("Please add a value");
                      setDisabled(true);
                    } else if (theresNoAttachments || theresAnErrorInAttachments) {
                      setErrorMessage("");
                      setDisabled(true);
                    } else {
                      setErrorMessage("");
                      setDisabled(false);
                    }
                  }}
                />
                <Separator
                  styles={{
                    root: {
                      lineHeight: "3px"
                    }
                  }}
                />
                <GLAttachments
                  customRef={attachmentsRef}
                  onUploadFile={props.onUploadFile}
                  onDeleteFile={props.onDeleteFile}
                  onDownloadFile={props.onDownloadFile}
                  initialRegion={"2"} // REDMOND
                  onChange={(lAttachments, rAttachments, errorMessage) => {
                    const textBoxVal = textRef.current ? textRef.current.value : "";
                    const theresNoAttachments = lAttachments.length === 0;
                    const theresAnErrorInAttachments = errorMessage !== null;
                    const theresNoTextBoxVal = textBoxVal === "";
                    if (theresNoTextBoxVal) {
                      setErrorMessage("Please add a value");
                      setDisabled(true);
                    } else if (theresNoAttachments || theresAnErrorInAttachments) {
                      setErrorMessage("");
                      setDisabled(true);
                    } else {
                      setErrorMessage("");
                      setDisabled(false);
                    }
                  }}
                  lockRegion={false}
                  initialAttachments={[]}
                  disabled={false}
                  loadingStatus={LoadingStatus.Resolved}
                  items={props.items}
                ></GLAttachments>
              </div>
              <div className={submissionStyles}>
                <div className="action-manager--footer-buttons">
                  <PrimaryButton type="submit" text={"Rescind"} disabled={disabled || _formik.isSubmitting} />
                  <DefaultButton onClick={onCancel} text="Cancel" />
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

GLRescind.displayName = "GLRescind";

export { GLRescind };
