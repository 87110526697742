import { Dropdown, IDropdownProps } from "@fluentui/react";
import React, { useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { Attachments, UserContext } from "../../Shared";
import {
  AttachmentChanges,
  AttachmentErrorMessage,
  LocalAttachment,
  IAttachmentEntryFormProps,
  GLPostAttachmentResponse,
  GLAttachment,
  IAttachmentEntryFormRef
} from "../../Shared/components/Attachments/Attachments.types";
import { ObjectKeys } from "../../Shared/utilities/TypeUtils";
import { LoadingStatus } from "../../Shared/utilities/Utilities";
import { IdToAttachmentRegion } from "../GeneralLedger.types";

const isEqual = require("lodash/isEqual");

const SIGNED_OFF_STATUS = 12;
const SIGN_OFF_NOT_REQ_STATUS = 7;
const SIGN_OFF_PENDING = 10;

export interface GLAttachmentChanges extends AttachmentChanges<GLAttachment> {
  region_key: number;
  region_name: string;
}

export type GLAttachmentsGet = () => Promise<[GLAttachmentChanges, AttachmentErrorMessage]>;

export interface GLAttachmentsRef {
  saveAndGetAttachments: GLAttachmentsGet;
  setAttachments: (theAttachments: GLAttachment[], localAttachments: LocalAttachment[]) => void;
  getLocalAttachments: () => LocalAttachment[];
  getError: () => string | null;
  reset: () => void;
}

export interface GLAttachmentRequiredItems {
  fiscalYear: string;
  fiscalPeriod: string;
  refGuid: string;
  jeId?: string;
  format?: string;
}

export interface GLAttachmentEndpointMap {
  uploadAttachment: (
    regionKey: number,
    regionName: string,
    items: GLAttachmentRequiredItems[]
  ) => (file: File) => Promise<GLPostAttachmentResponse[]>;
  deleteAttachment: (
    regionKey: number,
    regionName: string,
    items: GLAttachmentRequiredItems[]
  ) => IAttachmentEntryFormProps["onDeleteFile"];
  downloadAttachment: (items: GLAttachmentRequiredItems[]) => IAttachmentEntryFormProps["onDownloadFile"];
}

export interface GLAttachmentsProps {
  disabled: boolean;
  loadingStatus: LoadingStatus;
  onUploadFile: GLAttachmentEndpointMap["uploadAttachment"];
  onDeleteFile: GLAttachmentEndpointMap["deleteAttachment"];
  onDownloadFile: GLAttachmentEndpointMap["downloadAttachment"];
  onChange?: (
    localAttachments: LocalAttachment[],
    remoteAttachments: GLAttachment[],
    errorMessage: string | null
  ) => void;
  customRef: React.Ref<GLAttachmentsRef>;
  initialRegion: string;
  initialAttachments: GLAttachment[];
  items: GLAttachmentRequiredItems[];
  lockRegion: boolean;
  status?: number;
  reviewer?: string;
}

export interface GLAttachmentsRegion {
  attachments_count: number;
  attachments_error: string;
  attachments_region: string;
}

let GLAttachments: React.FC<GLAttachmentsProps> = (props) => {
  const attachmentsRef = useRef<IAttachmentEntryFormRef<GLAttachment>>(null);
  const userContext = useContext(UserContext);
  const currentUser = userContext?.user?.alias.toLocaleUpperCase();
  console.log("reviewer", props.reviewer);
  const isDisableAttachment =
    props.status === SIGNED_OFF_STATUS ||
    props.status === SIGN_OFF_NOT_REQ_STATUS ||
    (props.status === SIGN_OFF_PENDING && props.reviewer?.includes(currentUser));

  const [state, setState] = useState({
    attachments_count: 0,
    attachments_error: "",
    attachments_region: props.initialRegion
  });

  useEffect(() => {
    if (attachmentsRef.current) {
      attachmentsRef.current.setAttachments(props.initialAttachments, []);
    }
  }, [props.initialAttachments]);

  useImperativeHandle(props.customRef, () => ({
    async saveAndGetAttachments() {
      if (attachmentsRef.current) {
        const [attachments, errors] = await attachmentsRef.current.saveAndGetAttachments();
        return [
          {
            ...attachments,
            region_key: Number(state.attachments_region),
            region_name: IdToAttachmentRegion[Number(state.attachments_region) as keyof typeof IdToAttachmentRegion]
          } as GLAttachmentChanges,
          errors
        ];
      }

      return Promise.resolve([
        {
          attachments: [],
          recentlyUploadedAttachments: [],
          region_key: Number(state.attachments_region),
          region_name: IdToAttachmentRegion[Number(state.attachments_region) as keyof typeof IdToAttachmentRegion]
        },
        state.attachments_region === "" || !state.attachments_region ? "Please select a region" : ""
      ]);
    },
    reset() {
      if (attachmentsRef.current) attachmentsRef.current.reset();
    },
    getError() {
      if (attachmentsRef.current) return attachmentsRef.current.getError();
      return null;
    },
    getLocalAttachments() {
      if (attachmentsRef.current) return attachmentsRef.current.getLocalAttachments();
      return [];
    },
    setAttachments(theAttachments: GLAttachment[], localAttachments: LocalAttachment[]) {
      if (attachmentsRef.current) attachmentsRef.current.setAttachments(theAttachments, localAttachments);
    }
  }));

  const regionChange: IDropdownProps["onChange"] = (_event, item) => {
    setState((pState) => ({ ...pState, attachments_region: item ? `${item.key}` : "" }));
  };

  const optionsForDropdown = ObjectKeys(IdToAttachmentRegion).map((key) => ({
    key: `${key}`,
    text: `${IdToAttachmentRegion[key]}`
  }));

  const regionKey = Number(state.attachments_region);
  const regionName = IdToAttachmentRegion[regionKey as keyof typeof IdToAttachmentRegion];

  const regionDropdown = useMemo(() => {
    return (
      <Dropdown
        label="Upload To:"
        selectedKey={state.attachments_region.trim() !== "" ? state.attachments_region : undefined}
        options={optionsForDropdown}
        onChange={regionChange}
        styles={{
          root: {
            width: "130px"
          }
        }}
        errorMessage={state.attachments_region.trim() === "" || !state.attachments_region ? "Required" : ""}
        disabled={props.disabled || props.lockRegion}
      />
    );
  }, [props.disabled, state.attachments_region, props.lockRegion]);

  useEffect(() => {
    if (props.initialRegion !== "") {
      setState((pState) => ({ ...pState, attachments_region: props.initialRegion }));
    }
  }, [props.initialRegion]);

  return (
    <>
      <div className="ms-Grid" dir="ltr">
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12">
            <Attachments
              onUploadFiles={props.onUploadFile(regionKey, regionName, props.items)}
              onDeleteFile={props.onDeleteFile(regionKey, regionName, props.items)}
              onDownloadFile={props.onDownloadFile(props.items)}
              customRef={attachmentsRef}
              maxAttachments={10}
              onChange={(localAttachments, remoteAttachments, error) => {
                const newCount = localAttachments.length + remoteAttachments.length;
                setState((pState) => ({ ...pState, attachments_count: newCount, attachments_error: error || "" }));

                if (props.onChange) {
                  props.onChange(localAttachments, remoteAttachments, error);
                }
              }}
              onDelete={() => {
                console.log("files deleted");
              }}
              disabled={props.disabled || isDisableAttachment}
            >
              {regionDropdown}
            </Attachments>
          </div>
        </div>
      </div>
    </>
  );
};

GLAttachments = React.memo(GLAttachments, (prevProps, nextProps) => {
  if (!isEqual(prevProps.initialAttachments, nextProps.initialAttachments)) return false;
  if (!isEqual(prevProps.items, nextProps.items)) return false;
  if (prevProps.lockRegion !== nextProps.lockRegion) return false;
  if (prevProps.initialRegion !== nextProps.initialRegion) return false;
  if (prevProps.disabled !== nextProps.disabled) return false;
  if (prevProps.loadingStatus !== nextProps.loadingStatus) return false;
  if (prevProps.onUploadFile.toString() !== nextProps.onUploadFile.toString()) return false;
  if (prevProps.onDeleteFile.toString() !== nextProps.onDeleteFile.toString()) return false;
  if (prevProps.onDownloadFile.toString() !== nextProps.onDownloadFile.toString()) return false;
  if (prevProps.onChange?.toString() !== nextProps.onChange?.toString()) return false;
  if (prevProps.customRef !== nextProps.customRef) return false;

  return true;
});

GLAttachments.displayName = "GLAttachments";
export { GLAttachments };
