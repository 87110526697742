import { IColumn } from "@fluentui/react";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { IExtendedColumn } from "../DashboardGrid/DashboardGrid.types";
import { BulkItemFromApi } from "./BulkDetailsFromJeId.schema";

interface BulkDetailsColumn extends IExtendedColumn {
  fieldName: keyof BulkItemFromApi;
}

export const getBulkDetailsColumn = (location: ReturnType<typeof useLocation>) => (): IExtendedColumn[] => {
  const cols: BulkDetailsColumn[] = [
    {
      key: "jeNumber",
      name: "JE Number",
      fieldName: "jeNum",
      type: "string",
      minWidth: 120,
      onRender: (
        item?: BulkItemFromApi,
        index?: number | undefined,
        column?: IColumn | undefined
      ): JSX.Element | React.ReactText | null => {
        if (!column || !item) {
          return null;
        }

        const from = location.state && (location.state as any).from ? (location.state as any).from : location;
        return (
          <Link
            to={`/gl/details?jeid=${item.id}`}
            state={{
              from: from
            }}
          >
            {item.jeNum}
          </Link>
        );
      }
    },
    {
      key: "companyCode",
      name: "Company Code",
      fieldName: "coCode",
      type: "string",
      minWidth: 120
    },
    {
      key: "fy",
      name: "Fiscal Year",
      fieldName: "fiscalYear",
      type: "string",
      minWidth: 70
    },
    {
      key: "fp",
      name: "Fiscal Period",
      fieldName: "fiscalPeriod",
      type: "string",
      minWidth: 80
    }
  ];

  return cols;
};
