import { css } from "@emotion/css";
import { IconButton, Theme, useTheme } from "@fluentui/react";
import React, { useImperativeHandle, useState } from "react";
import { CSSTransition } from "react-transition-group";

interface SectionRef {
  collapse: () => void;
  expand: () => void;
}

interface SectionProps {
  label: string;
  maxHeight?: string;
  children: React.ReactNode;
  customRef?: React.Ref<SectionRef>;
}

const styles = (theme: Theme, { maxHeight }: Pick<SectionProps, "maxHeight">) => css`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white;
  margin: 8px 0 0 0;

  & .section--button .ms-Icon {
    color: ${theme.palette.accent};
  }

  & .section--header {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: ${theme.palette.neutralLighter};
    width: 100%;
    &:last-child {
      align-self: end;
    }
    & h4 {
      cursor: pointer;
      flex-grow: 1;
      padding: 0;
      margin: 0;
    }
  }

  & .section--content {
    margin-top: 8px;

    &-enter {
      max-height: 1440px;
      transform: scaleY(1);
      transform-origin: top;
      transition: transform 0.25s ease, max-height 0.25s ease-in, opacity 0.5s;
      opacity: 1;
    }

    &-enter-active {
      transform: scaleY(0);
      opacity: 0;
      max-height: 0;
    }

    &-enter-done {
      height: 0;
      visibility: hidden;
    }

    &-exit {
      transform: scaleY(0);
      transform-origin: top;
      transition: max-height 0.5s ease-out, transform 0.5s ease, opacity 0.5s;
      max-height: 0;
      opacity: 0;
    }

    &-exit-active {
      transform: scaleY(1);
      max-height: ${maxHeight ? maxHeight : "1440px"};
      opacity: 1;
      display: block;
    }
  }
`;

const Section: React.FunctionComponent<SectionProps> = (props: SectionProps) => {
  const theme = useTheme();
  const [collapsed, setCollapsed] = useState(false);
  const nodeRef = React.useRef(null);

  useImperativeHandle(props.customRef, () => ({
    collapse: () => setCollapsed(true),
    expand: () => setCollapsed(false)
  }));

  const toggleCollapsed = () => {
    setCollapsed((previousCollapsed: boolean) => !previousCollapsed);
  };

  return (
    <section className={styles(theme, props)}>
      <div className="section--header">
        <IconButton
          className="section--button"
          iconProps={{ iconName: collapsed ? "ChevronUp" : "ChevronDown" }}
          title={collapsed ? "Expand" : "Collapse"}
          onClick={toggleCollapsed}
        />
        <h4 onClick={toggleCollapsed}>{props.label}</h4>
      </div>
      <CSSTransition in={collapsed} timeout={300} classNames="section--content" nodeRef={nodeRef}>
        <div className="section--content" ref={nodeRef}>
          {props.children}
        </div>
      </CSSTransition>
    </section>
  );
};

export { Section, SectionRef, SectionProps };
