import React, { PropsWithChildren, useContext } from "react";
import { ActionResult, ActionTypes } from "../ActionManager/ActionsManager.types";
import { ActionsContext } from "./Actions.ActionsContext";
import { ActionTitle } from "./Actions.ActionTitle";
import { TextFieldSubmission } from "./Actions.BottomButtons";
import { ActionOnSubmitHandlerMap, BaseActionProps, CommonPropsOnRows } from "./Actions.types";

interface ReleaseForSignoffProps<ListType, ResultType> extends BaseActionProps<ListType, ResultType> {
  onSubmitHandler: ActionOnSubmitHandlerMap<ListType, ResultType>[ActionTypes.releaseForSignoff];
}

const ReleaseForSignoff = <ListType extends CommonPropsOnRows, ResultType extends ActionResult>(
  props: PropsWithChildren<ReleaseForSignoffProps<ListType, ResultType>>
) => {
  const { styles } = useContext(ActionsContext);
  return (
    <>
      <ActionTitle onCloseButton={() => props.onCancel()} name={"Initiate Sign-off"}></ActionTitle>
      <div className={styles.contentStyles.body}>
        <TextFieldSubmission
          onSubmit={async function (type: "submit" | "cancel", comments: string): Promise<void> {
            if (type === "cancel") {
              props.onCancel();
            } else {
              const result = await props.onSubmitHandler(props.items, comments);
              props.onSubmit(result);
            }
          }}
          submitButtonText={"Ok"}
          textFieldLabel={"Comments"}
        ></TextFieldSubmission>
      </div>
    </>
  );
};

ReleaseForSignoff.displayName = "ReleaseForSignoff";

export { ReleaseForSignoff };
