import React, { useState } from "react";
import { KeyCodes } from "@fluentui/react";
import { css, cx } from "@emotion/css";

export function defaultBreakKeysOnTagsInputs(): [",", "\t", " ", 13, ";"] {
  return [",", "\t", " ", KeyCodes.enter, ";"];
}

function uniq(arr: any[]) {
  const out: any[] = [];

  for (const item of arr) {
    if (out.indexOf(item) === -1) {
      out.push(item);
    }
  }

  return out;
}

function getClipboardData(e: React.ClipboardEvent<HTMLInputElement>): string {
  if ((window as any).clipboardData) {
    return (window as any).clipboardData.getData("Text");
  }

  if (e.clipboardData) {
    return e.clipboardData.getData("text/plain");
  }

  return "";
}

interface IDefaultRenderTagProps {
  key: number;
  tag: string;
  onRemove: any;
  classNameRemove: string;
  getTagDisplayValue: any;
  disabled: boolean;
}

const renderTag: React.FunctionComponent<IDefaultRenderTagProps> = (props) => {
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const { tag, key, disabled, onRemove, classNameRemove, getTagDisplayValue, ...other } = props;
  return (
    <span key={key} {...other}>
      {getTagDisplayValue(tag)}
      {
        <button className={classNameRemove} onClick={() => onRemove(key)}>
          x
        </button>
      }
    </span>
  );
};

interface IDefaultRenderInput {
  value: string;
  onChange: any;
  addTag: any;
  disabled: boolean;
  ariaLabelledBy: string;
  uppercase: boolean;
}

// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
const renderInput: React.FunctionComponent<IDefaultRenderInput> = ({ addTag, ...props }) => {
  const { onChange, value, ariaLabelledBy, uppercase, ...other } = props;
  const style = css`
    ${uppercase ? "text-transform: uppercase;" : ""}
  `;
  return (
    <input
      className={style}
      type="text"
      onChange={onChange}
      value={value}
      {...other}
      aria-labelledby={ariaLabelledBy}
    />
  );
};

const pasteSplit = (data: any) => {
  const separators = [",", ";", "\n", "\r"];
  const splittedInput = data.split(new RegExp(separators.join("|"))).map((d: any) => d.trim());
  return splittedInput;
};

interface ITagsInputProps {
  onChange: any;
  value: string[];
  ariaLabelledBy?: string;
  uppercase?: boolean;
  className?: string;
  onDownHandler?: any;
  onUpHandler?: any;
  focusedClassName?: string;
  addKeys?: Array<number | string>;
  currentValue?: string;
  inputValue?: string;
  inputProps?: any;
  listenToInputChanges?: any;
  listenToInputBlurChanges?: any;
  listenToInputFocusChanges?: any;
  onChangeInput?: any;
  removeKeys?: Array<number | string>;
  tagProps?: any;
  onlyUnique?: boolean;
  maxTags?: number;
  validate?: any;
  validationRegex?: RegExp;
  disabled?: boolean;
  tagDisplayProp?: string;
  preventSubmit?: boolean;
  onValidationReject?: any;
}

const textInputStyle = css`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: 1px solid rgb(138, 136, 134);
  border-radius: 0;
  background: rgb(255, 255, 255) none repeat scroll 0% 0%;

  & .react-tagsinput {
    background-color: #fff;
    border: 1px solid #ccc;
    overflow: hidden;
    padding-left: 5px;
    padding-top: 5px;
  }
  &[class^="react-tagsinput"][data-disabled="true"] {
    background: rgb(243, 242, 241);
  }

  & .react-tagsinput--focused {
    border-color: #a5d24a;
  }

  & .react-tagsinput-tag {
    background-color: rgb(243, 242, 241);
    border-radius: 2px;
    border: 1px solid rgb(161, 159, 157);
    color: var(--main-font-color, black);
    display: inline-block;
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto",
      "Helvetica Neue", sans-serif;
    font-size: 14px;
    font-weight: 400;
    margin-top: 2px;
    margin-bottom: 5px;
    margin-left: 5px;
    padding: 2px;
  }

  & .react-tagsinput-remove {
    margin-left: 3px;
    color: inherit;
    padding-top: 1px;
    cursor: pointer;
  }

  & .react-tagsinput-input {
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto",
      "Helvetica Neue", sans-serif;
    font-size: 14px;
    background: transparent;
    border: 0;
    color: var(--main-font-color, gray);
    font-weight: 400;
    margin-top: 1px;
    outline: none;
    padding: 5px 8px;
    width: 30%;
  }
`;

const TagsInput: React.FunctionComponent<ITagsInputProps> = (props) => {
  const [theTag, setTheTag] = useState("");

  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const [, setFocused] = useState(false);
  const [input, setInput] = useState<HTMLInputElement | null>(null);
  const [div, setDiv] = useState<HTMLDivElement | null>(null);

  const _getTagDisplayValue = (tag: any) => {
    const { tagDisplayProp } = props;

    if (tagDisplayProp) {
      return tag[tagDisplayProp];
    }

    return tag;
  };

  const _makeTag = (tag: string) => {
    const { tagDisplayProp } = props;

    if (tagDisplayProp) {
      const r = {
        [tagDisplayProp]: tag
      };
      return r;
    }

    return tag;
  };

  const _removeTag = (index: number) => {
    const value = props.value.concat([]);
    if (index > -1 && index < value.length) {
      const changed = value.splice(index, 1);
      props.onChange(value, changed, [index]);
    }
  };

  const _clearInput = () => {
    setTheTag("");
  };

  const _addTags = (t: string[]) => {
    const { onChange, onValidationReject, onlyUnique, maxTags, value } = props;
    let tags = t;
    if (onlyUnique) {
      tags = uniq(tags);
      tags = tags.filter((tag) =>
        value.every((currentTag) => _getTagDisplayValue(currentTag) !== _getTagDisplayValue(tag))
      );
    }

    const rejectedTags = tags.filter((tag) => !_validate(_getTagDisplayValue(tag)));
    tags = tags.filter((tag) => _validate(_getTagDisplayValue(tag)));
    tags = tags.filter((tag) => {
      const tagDisplayValue = _getTagDisplayValue(tag);
      if (typeof tagDisplayValue.trim === "function") {
        return tagDisplayValue.trim().length > 0;
      } else {
        return tagDisplayValue;
      }
    });

    if (maxTags && maxTags >= 0) {
      const remainingLimit = Math.max(maxTags - value.length, 0);
      tags = tags.slice(0, remainingLimit);
    }

    if (onValidationReject && rejectedTags.length > 0) {
      onValidationReject(rejectedTags);
    }

    if (tags.length > 0) {
      const newValue = value.concat(tags);
      const indexes: any[] = [];
      for (let i = 0; i < tags.length; i++) {
        indexes.push(value.length + i);
      }
      onChange(newValue, tags, indexes);
      _clearInput();
      return true;
    }

    if (rejectedTags.length > 0) {
      return false;
    }

    _clearInput();
    return false;
  };

  const _validate = (tag: string) => {
    const { validate, validationRegex } = props;

    return validate(tag) && validationRegex && validationRegex.test(tag);
  };

  const _shouldPreventDefaultEventOnAdd = (added: boolean, empty: boolean, keyCode: KeyCodes) => {
    if (added) {
      return true;
    }

    if (keyCode === 13) {
      return props.preventSubmit || (!props.preventSubmit && !empty);
    }

    return false;
  };

  const accept = () => {
    const unsafeTag = theTag;

    if (unsafeTag !== undefined && unsafeTag !== "") {
      const tag = _makeTag(unsafeTag);
      return _addTags([tag as string]);
    }

    return false;
  };

  const addTag = (tag: string) => {
    return _addTags([tag]);
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();

    const data = getClipboardData(e);
    const tags = pasteSplit(data).map((tag: string) => _makeTag(tag));

    _addTags(tags);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.defaultPrevented) {
      return;
    }

    const { value, removeKeys, addKeys } = props;
    if (e.keyCode === KeyCodes.down && props.onDownHandler) {
      props.onDownHandler(e);
      return;
    }
    if (e.keyCode === KeyCodes.up && props.onUpHandler) {
      props.onUpHandler(e);
      return;
    }
    if (addKeys !== undefined && removeKeys !== undefined) {
      const tag = theTag;
      const empty = tag === "";
      const keyCode = e.keyCode;
      const key = e.key;
      const add = addKeys.indexOf(keyCode) !== -1 || addKeys.indexOf(key) !== -1;
      const remove = removeKeys.indexOf(keyCode) !== -1 || removeKeys.indexOf(key) !== -1;

      if (add) {
        const added = accept();
        if (_shouldPreventDefaultEventOnAdd(added, empty, keyCode)) {
          e.preventDefault();
          e.stopPropagation();
        }
      }

      if (remove && value.length > 0 && empty) {
        e.preventDefault();
        e.stopPropagation();

        _removeTag(value.length - 1);
      }
    }
  };

  const focus = () => {
    if (input && typeof input.focus === "function") {
      input.focus();
    }

    handleOnFocus();
  };

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (e.target === div) {
      focus();
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const tag = e.target.value;

    if (props.listenToInputChanges) {
      props.listenToInputChanges(e);
    }

    setTheTag(tag);
  };

  const handleOnFocus = (e?: any) => {
    if (props.listenToInputFocusChanges) {
      props.listenToInputFocusChanges(e);
    }

    setFocused(true);
  };

  const handleOnBlur = (e?: any) => {
    setFocused(false);

    if (e == null) {
      return;
    }

    if (props.listenToInputBlurChanges) {
      props.listenToInputBlurChanges(e);
    }

    const tag = _makeTag(e.target.value);
    _addTags([tag as string]);
  };

  const inputProps = () => {
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    const { onChange, onFocus, onBlur, placeholder, ...otherInputProps } = props.inputProps;

    const p = {
      className: "react-tagsinput-input",
      placeholder,
      ...otherInputProps
    };

    if (p.disabled) {
      p.disabled = true;
    }

    return p;
  };

  const tagComponents = props.value.map((tag, index) => {
    return renderTag({
      key: index,
      tag,
      onRemove: (t: any) => {
        if (!props.disabled) {
          _removeTag(t);
        }
      },
      disabled: props.disabled,
      getTagDisplayValue: _getTagDisplayValue,
      ...props.tagProps
    });
  });

  const inputComponent = renderInput({
    ref: (r: HTMLInputElement) => {
      setInput(r);
    },
    value: theTag,
    onPaste: handlePaste,
    onKeyDown: handleKeyDown,
    onChange: handleChange,
    onFocus: handleOnFocus,
    onBlur: handleOnBlur,
    disabled: props.disabled,
    ariaLabelledBy: props.ariaLabelledBy,
    uppercase: !!props.uppercase,
    addTag,
    ...inputProps()
  });

  return (
    <div
      className={cx(textInputStyle, props.className)}
      ref={(r) => {
        setDiv(r);
      }}
      onClick={handleClick}
      data-disabled={props.disabled || false}
    >
      <span>
        {tagComponents}
        {inputComponent}
      </span>
    </div>
  );
};

TagsInput.defaultProps = {
  className: "react-tagsinput",
  focusedClassName: "react-tagsinput--focused",
  addKeys: [KeyCodes.enter, KeyCodes.tab, "\t"],
  inputProps: {},
  removeKeys: [KeyCodes.backspace],
  tagProps: {
    className: "react-tagsinput-tag",
    classNameRemove: "react-tagsinput-remove"
  },
  onlyUnique: false,
  maxTags: -1,
  validate: () => true,
  validationRegex: /.*/,
  disabled: false,
  tagDisplayProp: undefined,
  preventSubmit: true
};

export { TagsInput };
