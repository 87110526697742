import { css } from "@emotion/css";
import { Label, SelectionMode, Stack, useTheme } from "@fluentui/react";
import React, { PropsWithChildren, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { v4 } from "uuid";
import { LoadingStatus } from "../../utilities/Utilities";
import { DashboardGrid } from "../DashboardGrid/DashboardGrid";
import { IDashboardGridRef, IExtendedColumn } from "../DashboardGrid/DashboardGrid.types";
import { exportToExcel } from "../DashboardGrid/DashboardGrid.utilities";

import { AttachmentUtil } from "./Attachments.AttachmentUtil";
import { getAttachmentDefaultColumns } from "./Attachments.columns";
import { AttachmentsControls } from "./Attachments.controls";
import {
  AttachmentChanges,
  AttachmentForUI,
  AttachmentRegion,
  DocumentStatus,
  GLAttachment,
  IAttachment,
  IAttachmentEntryFormProps,
  IAttachmentEntryFormRef,
  InternalAttachment,
  LocalAttachment,
  ProcessingStatusForInternalAttachment
} from "./Attachments.types";

function IAttachmentToAttachmentForUI<
  AttachmentType extends (GLAttachment | IAttachment) & ProcessingStatusForInternalAttachment
>(item: InternalAttachment<AttachmentType>): AttachmentForUI<AttachmentType> {
  const newAttachment: AttachmentForUI<AttachmentType> = {
    fileName: item.fileName,
    url: item.url,
    size: item.fileSize,
    uploadDatetime: item.uploadedOn,
    status: DocumentStatus.Uploaded,
    guid: item.guid,
    processingStatus: "",
    procStatType: "Information",
    documentType: item.documentType,
    region: undefined,
    isRescindEvidence: false
  };
  if ("region" in item && item.region !== null) {
    newAttachment.region = item.region as AttachmentRegion<AttachmentType>;
  }
  if ("isRescindEvidence" in item && item.isRescindEvidence !== null) {
    newAttachment.isRescindEvidence = item.isRescindEvidence;
  }
  return newAttachment;
}

export function LocalAttachmentToAttachmentForUI<AttachmentType extends GLAttachment | IAttachment>(
  item: LocalAttachment
): AttachmentForUI<AttachmentType> {
  const newAttachment: AttachmentForUI<AttachmentType> = {
    fileName: item.file.name,
    size: Math.ceil(item.file.size / 1024),
    url: "",
    status: DocumentStatus.NotUploaded,
    uploadDatetime: new Date().toLocaleString(),
    guid: item.guid,
    processingStatus: "",
    procStatType: "Information",
    documentType: "",
    region: undefined,
    isRescindEvidence: false
  };
  return newAttachment;
}

const attachmentUserConfigurationName = "attachmentUserConfigurationName";

const Attachments = <AttachmentType extends GLAttachment | IAttachment>(
  props: PropsWithChildren<IAttachmentEntryFormProps>
) => {
  const [loadingState, setLoadingState] = useState(LoadingStatus.Resolved);
  const theme = useTheme();

  const gridRef = useRef<IDashboardGridRef>(null);
  const [remoteAttachments, _setRemoteAttachments] = useState<InternalAttachment<AttachmentType>[]>([]);
  const [localAttachments, _setLocalAttachments] = useState<LocalAttachment[]>([]);

  const [maxAttachments] = useState<number>(props.maxAttachments || 10);

  const columns = useMemo<IExtendedColumn[]>(() => {
    return getAttachmentDefaultColumns(
      remoteAttachments,
      props.onDownloadFile,
      theme,
      props.onUploadFile === undefined,
      _setRemoteAttachments
    );
  }, [remoteAttachments, props.onDownloadFile, props.onUploadFile]);

  const getError: IAttachmentEntryFormRef<AttachmentType>["getError"] = () => {
    const [_, errorMessage] = getNewUIAttachmentsAndErrorMessage(remoteAttachments, localAttachments, maxAttachments);
    return errorMessage;
  };

  const getLocalAttachments: IAttachmentEntryFormRef<AttachmentType>["getLocalAttachments"] = () => {
    return localAttachments;
  };

  const reset = () => {
    _setLocalAttachments([]);
    _setRemoteAttachments([]);
    if (gridRef.current) {
      gridRef.current.clearSelection();
    }
  };

  useImperativeHandle(
    props.customRef as React.Ref<IAttachmentEntryFormRef<IAttachment> | IAttachmentEntryFormRef<GLAttachment>>,
    () => ({
      setAttachments: (_remoteAttachments: IAttachment[] | GLAttachment[], localAttachments: LocalAttachment[]) => {
        const trackedAttachments = _remoteAttachments.map(
          (item) => ({ ...item, guid: v4() } as unknown as InternalAttachment<AttachmentType>)
        );
        _setRemoteAttachments([...trackedAttachments]);
        _setLocalAttachments([...localAttachments]);
      },
      getError,
      getLocalAttachments,
      reset,
      saveAndGetAttachments: async () => {
        const [attachmentsForUI, errorMessage] = getNewUIAttachmentsAndErrorMessage(
          remoteAttachments,
          localAttachments,
          maxAttachments
        );
        if (errorMessage !== null) {
          return [
            {
              attachments: [...remoteAttachments],
              recentlyUploadedAttachments: []
            } as unknown as AttachmentChanges<AttachmentType>,
            errorMessage
          ];
        }
        if (attachmentsForUI.some((att) => att.processingStatus !== null && att.processingStatus !== "")) {
          // this is for support
          console.log(attachmentsForUI.map((att) => att.processingStatus));
          return [
            {
              attachments: [...remoteAttachments],
              recentlyUploadedAttachments: []
            } as unknown as AttachmentChanges<AttachmentType>,
            "There are errors in the uploaded files. Please correct them and try again."
          ];
        }
        // upload local attachments here
        // add them to the remote attachments
        const newRemoteAttachments: AttachmentType[] = [...remoteAttachments] as unknown as AttachmentType[];
        const recentlyUploadedAttachments: AttachmentType[] = [];
        setLoadingState(LoadingStatus.Pending);
        for (const file of localAttachments) {
          try {
            if (props.onUploadFile) {
              const attachment = await props.onUploadFile(file.file);
              const iAttachment = AttachmentUtil.postAttachmentResponseToIAttachment(file.file, attachment);
              newRemoteAttachments.push(iAttachment as AttachmentType);
              recentlyUploadedAttachments.push(iAttachment as AttachmentType);
              _setRemoteAttachments((previousAttachments) => {
                return [
                  ...previousAttachments,
                  Object.assign(iAttachment, { guid: v4() }) as unknown as InternalAttachment<AttachmentType>
                ];
              });
            } else if (props.onUploadFiles) {
              const attachments = await props.onUploadFiles(file.file);
              const iAttachments = attachments.map((attachment) =>
                AttachmentUtil.postAttachmentResponseToIAttachment(file.file, attachment)
              );
              newRemoteAttachments.push(...(iAttachments as AttachmentType[]));
              recentlyUploadedAttachments.push(...(iAttachments as AttachmentType[]));
              _setRemoteAttachments((previousAttachments) => {
                const objects = iAttachments.map(
                  (iAttachment) =>
                    Object.assign(iAttachment, { guid: v4() }) as unknown as InternalAttachment<AttachmentType>
                );
                return [...previousAttachments, ...objects];
              });
            }
          } catch (err) {
            console.error(err);
            // TODO: Show message
            // TODO: Loading state is used to control fetching attachments
          }
        }
        // clean up local attachments
        _setLocalAttachments([]);

        // refresh attachments for UI
        setLoadingState(LoadingStatus.Resolved);
        return [
          {
            attachments: newRemoteAttachments,
            recentlyUploadedAttachments
          } as unknown as AttachmentChanges<AttachmentType>,
          errorMessage
        ];
      }
    })
  );

  useEffect(() => {
    if (props.onChange) {
      const [_, errorMessage] = getNewUIAttachmentsAndErrorMessage(remoteAttachments, localAttachments, maxAttachments);
      if (props.onUploadFiles !== undefined) {
        props.onChange(localAttachments, remoteAttachments as GLAttachment[], errorMessage);
      } else {
        props.onChange(localAttachments, remoteAttachments as IAttachment[], errorMessage);
      }
    }
  }, [localAttachments, remoteAttachments]);

  const deleteAttachment = async () => {
    if (!gridRef.current) return;

    const attachmentsSelection = gridRef.current.getSelection();
    const attchs = attachmentsSelection.items as AttachmentForUI<AttachmentType>[];
    let dialogMessage = "Are you sure want to delete the selected documents?";
    switch (attchs.length) {
      case 0:
        alert("Please select one or more documents to delete.");
        return;
      default:
        dialogMessage = "Are you sure want to delete the selected document?";
        break;
    }
    const a = confirm(dialogMessage);
    if (a) {
      const selectedLocalAttachments = attchs
        .filter((item) => item.status === DocumentStatus.NotUploaded)
        .map((item) => item.guid);
      const selectedRemoteAttachments = attchs
        .filter((item) => item.status !== DocumentStatus.NotUploaded)
        .map((item) => item.guid);
      setLoadingState(LoadingStatus.Pending);
      const newLocalAttachments: LocalAttachment[] = [];
      const newRemoteAttachments: InternalAttachment<AttachmentType>[] = [];
      for (const attch of localAttachments) {
        if (!selectedLocalAttachments.includes(attch.guid)) {
          newLocalAttachments.push(attch);
        }
      }
      for (const attch of remoteAttachments) {
        if (!selectedRemoteAttachments.includes(attch.guid)) {
          newRemoteAttachments.push(attch);
          continue;
        }

        if ("isRescindEvidence" in attch && attch.isRescindEvidence) {
          // if it's a rescind evidence, we can't delete
          // create a copy of attch with error message
          const newAttch = { ...attch, processingStatus: "Cannot delete rescind evidence.", procStatType: "Error" };
          newRemoteAttachments.push(newAttch);
          continue;
        }

        try {
          await props.onDeleteFile(attch.id, attch.blobName);
        } catch (e) {
          // add generic error message
          const newAttch = { ...attch, processingStatus: "Could not delete file.", procStatType: "Error" };
          newRemoteAttachments.push(newAttch);
        }
      }

      _setRemoteAttachments(newRemoteAttachments);
      _setLocalAttachments(newLocalAttachments);
      if (props.onDelete) {
        props.onDelete();
      }
      setLoadingState(LoadingStatus.Resolved);
    }
  };

  const [attachmentsForUI, errorMessage] = getNewUIAttachmentsAndErrorMessage(
    remoteAttachments,
    localAttachments,
    maxAttachments
  );

  return (
    <>
      <div
        className={css`
          width: 100%;
          position: relative;
          z-index: 999999;
        `}
        dir="ltr"
      >
        <AttachmentsControls
          onDeleteAttachments={function (): void {
            deleteAttachment();
          }}
          onValidFilesDropped={function (files: File[]): void {
            setLoadingState(LoadingStatus.Pending);

            const newAcceptedFiles = files.map((file) => {
              return {
                file,
                guid: v4()
              } as LocalAttachment;
            });
            _setLocalAttachments((prev) => {
              const nondupes = compareFilesAndReturnNonDuplicates(prev, newAcceptedFiles);
              return [...nondupes];
            });
            setLoadingState(LoadingStatus.Resolved);
          }}
          loadingState={loadingState}
          disabled={!!props.disabled}
        >
          {!props.children ? null : <Stack.Item align="center">{props.children}</Stack.Item>}
        </AttachmentsControls>
        <DashboardGrid
          customRef={gridRef}
          idForLocalStorage={attachmentUserConfigurationName}
          columnGenerator={() => {
            return columns;
          }}
          items={attachmentsForUI}
          isSortedIndex={1}
          maxHeight={`400px`}
          height={` ${50 + attachmentsForUI.length * 40}px`}
          isSortedDescending={true}
          selectionMode={SelectionMode.multiple}
          onExport={(rows) => {
            exportToExcel({
              sheetName: "Attachments",
              rowsWithHeader: rows,
              fileName: "Attachments.xlsx"
            });
          }}
          disablePagination={true}
          loadingStatus={loadingState || props.disabled}
        ></DashboardGrid>
        <Stack {...{ tokens: { childrenGap: 16, padding: 10 } }}>
          <Stack.Item>
            <Label>
              You can attach more than one file at a time.Note: Though you can attach multiple files at a time,
              recommendation for uploading the large({">"}9MB) files is one at a time.
            </Label>
          </Stack.Item>
          {errorMessage ? (
            <Stack.Item align="end">
              <Label
                style={{
                  color: "red"
                }}
              >
                {errorMessage}
              </Label>
            </Stack.Item>
          ) : null}
        </Stack>
      </div>
    </>
  );
};

function getNewUIAttachmentsAndErrorMessage<
  AttachmentType extends (GLAttachment | IAttachment) & ProcessingStatusForInternalAttachment
>(
  remoteAttachments: InternalAttachment<AttachmentType>[],
  localAttachments: LocalAttachment[],
  maxAttachments: number
): [AttachmentForUI<AttachmentType>[], string | null] {
  const newAttachments: AttachmentForUI<AttachmentType>[] = [];
  for (const item of remoteAttachments) {
    const theNewAtt = IAttachmentToAttachmentForUI(item);
    if (newAttachments.find((att) => att.fileName === theNewAtt.fileName) !== undefined) {
      theNewAtt.processingStatus = "File Name is Duplicate.";
      theNewAtt.procStatType = "Error";
    } else if ("processingStatus" in item && typeof item.processingStatus === "string") {
      theNewAtt.processingStatus = item.processingStatus;
      theNewAtt.procStatType = item.procStatType;
    }
    newAttachments.push(theNewAtt);
  }
  for (const item of localAttachments) {
    const theNewAtt = LocalAttachmentToAttachmentForUI(item);
    if (newAttachments.find((att) => att.fileName === theNewAtt.fileName) !== undefined) {
      theNewAtt.processingStatus = "File Name is Duplicate.";
      theNewAtt.procStatType = "Error";
    }
    newAttachments.push(theNewAtt);
  }
  const errorMessage =
    newAttachments.length > maxAttachments
      ? `Maximum number of files that can be attached is ${maxAttachments}.`
      : null;
  return [newAttachments, errorMessage];
}

export const compareFilesAndReturnNonDuplicates = (prevFiles: LocalAttachment[], newFiles: LocalAttachment[]) => {
  const newState = [...prevFiles];
  for (const newFile of newFiles) {
    const oneFileIsTheSame = newState.some((previousFile) => {
      if (previousFile.file.name !== newFile.file.name) {
        return false;
      } else {
        if (
          previousFile.file.size !== newFile.file.size &&
          previousFile.file.type !== newFile.file.type &&
          previousFile.file.lastModified !== newFile.file.lastModified
        ) {
          return false;
        }
      }
      return true;
    });
    if (!oneFileIsTheSame) {
      newState.push(newFile);
    }
  }
  return newState;
};

Attachments.displayName = "Attachments";

export { Attachments };
