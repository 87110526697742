import { read, utils, WorkBook } from "xlsx";

export const getBatchFields = async (file: File, domainData: any) => {
  try {
    const buffer = await file.arrayBuffer();
    const wb: WorkBook = read(buffer);
    const ws = wb.Sheets[wb.SheetNames[0]];
    const headerFieldsArray: any[] = utils.sheet_to_json(ws, { header: 1, defval: "", range: "A3:H11" });
    const batchName = headerFieldsArray[1][1];
    const description = headerFieldsArray[2][1];
    const batchDescription = description.substring(0, 25);
    const purpose = headerFieldsArray[3][1];
    const batchPurpose = purpose.substring(0, 250);
    const reason = headerFieldsArray[0][3];
    const reasonCode = domainData.find((x) => x.jeReason === reason);
    const batchReasonCode: string = reasonCode ? reasonCode.code : "";
    return {
      batchName,
      batchDescription,
      batchPurpose,
      batchReasonCode
    };
  } catch (error) {
    throw new Error("Error occurred during file processing");
  }
};
