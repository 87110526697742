import React, { CSSProperties } from "react";
import { MessagingColors } from "@coherence-design-system/styles";
import { For } from "react-loops";

interface IInlineFormInputErrorMessageProps {
  errorMessages: string[];
  errorWrapperStyle?: CSSProperties;
}

const errorStyles = (type: "success" | "failure") => ({
  color: type === "failure" ? MessagingColors.errorBlockIcon : MessagingColors.successIcon,
  fontWeight: 400,
  fontSize: "12px",
  margin: "5px 0 0 0"
});

const InlineFormInputErrorMessage = ({
  errorMessages,
  errorWrapperStyle
}: IInlineFormInputErrorMessageProps): JSX.Element => {
  return (
    <>
      {errorMessages.length !== 0 && (
        <div role="alert" style={errorWrapperStyle}>
          <For of={errorMessages}>
            {(errorMessage) => (
              <p style={errorMessage.indexOf("success") !== -1 ? errorStyles("success") : errorStyles("failure")}>
                <span>{errorMessage}</span>
              </p>
            )}
          </For>
        </div>
      )}
    </>
  );
};

export default InlineFormInputErrorMessage;
