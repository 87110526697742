import { MessageBarType } from "@fluentui/react";
import { useContext } from "react";
import { JemConfiguration } from "../../../../JemConfiguration";
import { UserContext } from "../../../../Shared/contexts/UserContext/UserContext";
import { getValidUrl, postRequest, putRequest } from "../../../../Shared/utilities/RequestUtilities";
import { LocalMessage } from "../../../../Shared/utilities/SharedModels";
import { GLAttachmentChanges } from "../../../shared/GL.Attachments";
import { ErrorListModel } from "../../GLCreate/GLCreateForm.types";
import { GLBatchActionsEnum } from "../GLBatch.Actions";
import { uploadBatchFile, ValidateBatchFile } from "../GLBatch.requests";
import { convertBatchErrorsToErrorListModel, GLBatchPageState } from "../GLBatch.State";
import { BatchSaveResponse, BatchValidateResponse, GLBatchFormikState } from "../GLBatch.types";
import {
  assembleBatchDetails,
  assembleBatchDetailsForValidatingFile,
  assembleBatchPayload,
  assembleEmptyBatchDetails
} from "./GLBatchActions.utils";
import { noActivityAction } from "../../../shared/noActivityAction";
import { AdditionalData } from "../../../shared";
import { DomainDataEnum, DomainDataObjects, LoggingContext } from "../../../../Shared";

export interface BatchActionPayload {
  state: GLBatchPageState;
  formikState: GLBatchFormikState;
  attachmentObjects: GLAttachmentChanges;
}

export interface BatchValidateAndPostActionsPayload extends BatchActionPayload {
  pathToBatchInDocumentApi: string;
}

export interface BatchActionResponse {
  message: LocalMessage;
  refGuid: string;
  batchErrors?: ErrorListModel[];
  blockPage: boolean;
}

export interface BatchSaveActionResponse extends BatchActionResponse {
  pathToBatchInDocumentsApi: string | null;
}

export interface BatchActionOnSubmitHandlerMap {
  [GLBatchActionsEnum.Save]: (payload: BatchActionPayload) => Promise<BatchSaveActionResponse>;
  [GLBatchActionsEnum.Post]: (payload: BatchValidateAndPostActionsPayload) => Promise<BatchActionResponse>;
  [GLBatchActionsEnum.Validate]: (payload: BatchValidateAndPostActionsPayload) => Promise<BatchActionResponse>;
  [GLBatchActionsEnum.Clear]: (payload: Omit<BatchActionPayload, "attachmentObjects">) => Promise<BatchActionResponse>;
  [GLBatchActionsEnum.NoActivity]: (
    payload: Omit<BatchActionPayload, "attachmentObjects" | "formikState">,
    options: AdditionalData
  ) => Promise<BatchActionResponse>;
  [GLBatchActionsEnum.PreReview]: (payload: BatchValidateAndPostActionsPayload) => Promise<BatchActionResponse>;
}

export function useGLBatchActions(
  configuration: JemConfiguration["GeneralLedgerApi"],
  attachmentsConfiguration: JemConfiguration["DocumentsApi"],
  domainData: Pick<
    DomainDataObjects,
    DomainDataEnum.JeReasonCodes | DomainDataEnum.JeBatchFields | DomainDataEnum.JeBatchFields
  >
): BatchActionOnSubmitHandlerMap {
  const userContext = useContext(UserContext);
  const logger = useContext(LoggingContext);

  const makeUrl = (endpoint: string) => {
    console.log("end", endpoint);
    const base = configuration.baseApiUrl;
    return getValidUrl(`${base}${endpoint}`);
  };

  const actionMap: BatchActionOnSubmitHandlerMap = {
    [GLBatchActionsEnum.Save]: async (payload) => {
      let batchFilePath = payload.state.batchState.uri;
      if (payload.formikState.batchFile) {
        const batchDraftData = assembleBatchDetailsForValidatingFile(payload);
        const validatedBatchFileInfo = await ValidateBatchFile(configuration, userContext.accessToken, batchDraftData);
        const uploadParts = await uploadBatchFile(
          attachmentsConfiguration,
          userContext,
          "Redmond",
          validatedBatchFileInfo.batchFile.fileName,
          payload.formikState.batchFile
        );
        batchFilePath = uploadParts.pathToFileWithoutContainerName;
      }

      const batchDraftDataForSave = assembleBatchDetails(
        payload,
        payload.formikState.detailsTabBatchFile,
        batchFilePath,
        domainData
      );
      logger.appInsights?.trackEvent({
        name: "saveBatch",
        properties: {
          refGuid: batchDraftDataForSave.jeBatchInfo.refGuid
        }
      });

      const endpoint = makeUrl(configuration.endpoints.saveBatch);
      const response = await postRequest<BatchSaveResponse>(endpoint, batchDraftDataForSave, userContext.accessToken);
      if (!response) {
        return {
          message: {
            message: "Could not Save Draft.",
            type: MessageBarType.error
          },
          refGuid: payload.state.batchState.refGuid,
          pathToBatchInDocumentsApi: batchFilePath,
          blockPage: false
        };
      }

      return {
        message: {
          message: "Draft Saved.",
          type: MessageBarType.success
        },
        refGuid: batchDraftDataForSave.batchDetailsInfo.refGuid,
        pathToBatchInDocumentsApi: batchFilePath,
        blockPage: false
      };
    },
    [GLBatchActionsEnum.Validate]: async (payload) => {
      logger.appInsights?.trackEvent({
        name: "validateBatch",
        properties: {
          refGuid: payload.state.batchState.refGuid
        }
      });
      const endpoint = makeUrl(configuration.endpoints.validateBatch);
      const validatePayload = assembleBatchPayload(payload, userContext.user.alias, domainData);
      const r = await postRequest<BatchValidateResponse>(endpoint, validatePayload, userContext.accessToken);
      if (!r) {
        logger.appInsights?.trackEvent({
          name: "validateBatchApiError",
          properties: {
            refGuid: payload.state.batchState.refGuid,
            error: "Could not Validate Batch."
          }
        });
        return {
          message: {
            message: "Could not Validate Batch.",
            type: MessageBarType.error
          },
          refGuid: payload.state.batchState.refGuid,
          blockPage: false
        };
      }

      if (r.allowSAPPosting && r.jeBatchWarning.length == 0) {
        logger.appInsights?.trackEvent({
          name: "validateBatchSuccess",
          properties: {
            refGuid: payload.state.batchState.refGuid
          }
        });
        return {
          message: {
            message: "Batch Validated.",
            type: MessageBarType.success
          },
          refGuid: payload.state.batchState.refGuid,
          blockPage: false
        };
      }

      if (r.jeBatchError.length !== 0) {
        logger.appInsights?.trackEvent({
          name: "validateBatchValidationError",
          properties: {
            refGuid: payload.state.batchState.refGuid,
            errors: r.jeBatchError.map((e) => e.message).join(", ")
          }
        });
        return {
          message: {
            message:
              "Validation errors have occurred. Make changes to the highlighted fields and click Validate again.",
            type: MessageBarType.error
          },
          refGuid: payload.state.batchState.refGuid,
          batchErrors: convertBatchErrorsToErrorListModel(r.jeBatchError),
          blockPage: false
        };
      }

      const batchIsValidatedMessage = r.allowSAPPosting ? "Batch Validated." : "SAP Posting not allowed.";
      if (r.jeBatchWarning.length != 0) {
        logger.appInsights?.trackEvent({
          name: "validateBatchWarning",
          properties: {
            refGuid: payload.state.batchState.refGuid,
            errors: r.jeBatchWarning.map((e) => e.message).join(", ")
          }
        });
        return {
          message: {
            message: `${batchIsValidatedMessage} ${r.jeBatchWarning[0].message}`,
            type: r.allowSAPPosting ? MessageBarType.warning : MessageBarType.error
          },
          refGuid: payload.state.batchState.refGuid,
          batchErrors: convertBatchErrorsToErrorListModel(r.jeBatchError),
          blockPage: false
        };
      }

      logger.appInsights?.trackEvent({
        name: "validateBatchSAPBlocked",
        properties: {
          refGuid: payload.state.batchState.refGuid
        }
      });
      return {
        message: {
          message: `${batchIsValidatedMessage} Please try again in a few minutes.`,
          type: MessageBarType.error
        },
        refGuid: payload.state.batchState.refGuid,
        blockPage: false
      };
    },
    [GLBatchActionsEnum.Post]: async (payload) => {
      logger.appInsights?.trackEvent({
        name: "postBatch",
        properties: {
          refGuid: payload.state.batchState.refGuid
        }
      });
      const endpoint = makeUrl(configuration.endpoints.postBatch);
      const postPayload = assembleBatchPayload(payload, userContext.user.alias, domainData);
      const r = await postRequest<string>(endpoint, postPayload, userContext.accessToken);
      if (!r) {
        logger.appInsights?.trackEvent({
          name: "postBatchApiError",
          properties: {
            refGuid: payload.state.batchState.refGuid
          }
        });
        return {
          message: {
            message: "Could not Post Draft.",
            type: MessageBarType.error
          },
          refGuid: payload.state.batchState.refGuid,
          blockPage: false
        };
      }
      return {
        message: {
          message: "Batch Posted.",
          type: MessageBarType.success
        },
        refGuid: payload.state.batchState.refGuid,
        blockPage: true
      };
    },
    [GLBatchActionsEnum.Clear]: async (payload) => {
      logger.appInsights?.trackEvent({
        name: "clearBatch",
        properties: {
          refGuid: payload.state.batchState.refGuid
        }
      });
      const emptyBatchInNameOnly = assembleEmptyBatchDetails(payload, domainData);
      const endpoint = makeUrl(configuration.endpoints.saveBatch);
      const response = await postRequest<BatchSaveResponse>(endpoint, emptyBatchInNameOnly, userContext.accessToken);
      if (!response) {
        return {
          message: {
            message: "Could not Clear Batch.",
            type: MessageBarType.error
          },
          refGuid: payload.state.batchState.refGuid,
          blockPage: false
        };
      }

      return {
        message: {
          message: "Batch Cleared.",
          type: MessageBarType.success
        },
        refGuid: emptyBatchInNameOnly.batchDetailsInfo.refGuid,
        blockPage: false
      };
    },
    [GLBatchActionsEnum.NoActivity]: async (payload, options) => {
      console.log("options in gl batch actions" + options);
      logger.appInsights?.trackEvent({
        name: "noActivityBatch",
        properties: {
          refGuid: payload.state.batchState.refGuid
        }
      });
      const response = await noActivityAction({
        refguid: payload.state.batchState.refGuid,
        type: "batch",
        configuration: configuration,
        accessToken: userContext.accessToken,
        reason: options.noActivityReason
      });
      return {
        message: {
          message: response.message,
          type: response.status ? MessageBarType.success : MessageBarType.error
        },
        blockPage: response.status ? true : false,
        refGuid: payload.state.batchState.refGuid
      };
    },
    [GLBatchActionsEnum.PreReview]: async (payload) => {
      logger.appInsights?.trackEvent({
        name: "preReviewBatch",
        properties: {
          refGuid: payload.state.batchState.refGuid
        }
      });

      const endpoint = makeUrl(
        configuration.endpoints.preReviewBatch?.replace("{batchGuid}", payload.state.batchState.refGuid)
      );

      //const preReviewPayload = assembleBatchPayload(payload, userContext.user.alias, domainData);
      const r = await putRequest<string | object>(endpoint, undefined, userContext.accessToken);
      if (!r || r !== "Success") {
        logger.appInsights?.trackEvent({
          name: "preReviewBatchApiError",
          properties: {
            refGuid: payload.state.batchState.refGuid,
            errors: Array.isArray(r) ? r.map((e) => e.message).join(", ") : ""
          }
        });
        return {
          message: {
            message: "Could not PreReview Batch.",
            type: MessageBarType.error
          },
          refGuid: payload.state.batchState.refGuid,
          blockPage: false
        };
      }
      return {
        message: {
          message: "Batch sent for PreReview.",
          type: MessageBarType.success
        },
        refGuid: payload.state.batchState.refGuid,
        blockPage: true
      };
    }
  };
  return actionMap;
}
