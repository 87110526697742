import { css } from "@emotion/css";
import { Label, SelectionMode, Text } from "@fluentui/react";
import { useFormikContext } from "formik";
import React, { useRef } from "react";
import { For } from "react-loops";
import { IDashboardGridRef, DashboardGrid, exportToExcel } from "../../../../Shared";
import { getErrorColumns } from "../../GLCreate/GLCreateTabs/GLCreate.Tab.ErrorDetails";
import { GLBatchFormikState } from "../GLBatch.types";

const GLBatchErrorDetailsTab: React.FC = () => {
  const dashboardGridRef = useRef<IDashboardGridRef>(null);
  const formik = useFormikContext<GLBatchFormikState>();

  return (
    <div className="ms-Grid" dir="ltr">
      <div className="ms-Grid-row">
        <div className="ms-Grid-col ms-sm5">
          <div className="ms-Grid" dir="ltr">
            <div className="ms-Grid-row">
              <Label>Batch Errors</Label>
            </div>
            <div className="ms-Grid-row">
              <ul
                className={css`
                  padding-left: 10px;
                  list-style-type: none;
                `}
                data-is-scrollable="true"
              >
                <For
                  in={formik.errors}
                  as={(item) => (
                    <li>
                      <Text variant="smallPlus">{item}</Text>
                    </li>
                  )}
                />
              </ul>
            </div>
          </div>
        </div>
        <div className="ms-Grid-col ms-sm7">
          <div className="ms-Grid" dir="ltr">
            <div className="ms-Grid-row">
              <Label>Validation Errors</Label>
            </div>
            <div className="ms-Grid-row">
              <div
                className={css`
                  border-left: 2px solid;
                  padding-left: 10px;
                `}
                data-is-scrollable="true"
              >
                <DashboardGrid
                  idForLocalStorage={"glBatchSpreadsheetErrors"}
                  customRef={dashboardGridRef}
                  columnGenerator={getErrorColumns}
                  items={formik.values.errorTabErrorTable}
                  isSortedIndex={1}
                  height={`250px`}
                  isSortedDescending={true}
                  selectionMode={SelectionMode.none}
                  onExport={(rows) => {
                    exportToExcel({
                      sheetName: "batchErrors",
                      rowsWithHeader: rows,
                      fileName: "batchErrors.xlsx"
                    });
                  }}
                  disablePagination={true}
                ></DashboardGrid>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

GLBatchErrorDetailsTab.displayName = "GLBatchErrorDetailsTab";
export { GLBatchErrorDetailsTab };
