import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Dropdown, IDropdownOption } from "@fluentui/react";
import { SignificanceOptions } from "../../utilities/SignificanceTypeAndIcon";
import { CommonDropdownOnChangeHandler } from "../../utilities/CommonDropdownOnChangeHandler";

export interface ISignificanceRef {
  getSignificance: () => string[];
  setInitialSignificance: (initialSignificance: string[]) => void;
  reset: () => void;
}

interface ISignificanceProps {
  loading: boolean;
  styles?: {
    minWidth: string;
    maxWidth: string;
    width: string;
  };
}

const SignificanceDropdown = forwardRef((props: ISignificanceProps, ref) => {
  const [significance, setSignificance] = useState<string[]>(["all"]);

  const onChangeSignificance = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void => {
    if (!option) {
      return;
    }
    const newOptions = CommonDropdownOnChangeHandler({
      currentOptions: significance,
      option: option,
      keyForAll: "all",
      keyForNone: "none",
      totalOptions: SignificanceOptions.length - 2
    });
    setSignificance(newOptions);
  };

  useImperativeHandle(
    ref,
    (): ISignificanceRef => ({
      getSignificance() {
        return significance;
      },
      setInitialSignificance(initialSignificance: string[]) {
        setSignificance(initialSignificance);
      },
      reset() {
        setSignificance(["all"]);
      }
    })
  );

  return (
    <React.Fragment>
      <Dropdown
        label="Significance:"
        selectedKeys={significance}
        onChange={onChangeSignificance}
        options={SignificanceOptions}
        disabled={props.loading}
        multiSelect
        styles={
          props.styles || {
            root: {
              minWidth: "200px",
              maxWidth: "308px"
            }
          }
        }
      />
    </React.Fragment>
  );
});

SignificanceDropdown.displayName = "SignificanceDropdown";
export { SignificanceDropdown };
