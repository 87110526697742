import React, { useContext } from "react";
import { IconButton } from "@fluentui/react";
import { ActionsContext } from "./Actions.ActionsContext";

interface ActionTitleProps {
  name: string;
  onCloseButton: () => void;
}

const ActionTitle: React.FC<ActionTitleProps> = (props) => {
  const { styles } = useContext(ActionsContext);
  return (
    <div className={styles.contentStyles.header}>
      <span>{props.name}</span>
      <IconButton
        styles={styles.iconButtonStyles}
        iconProps={{ iconName: "Cancel" }}
        ariaLabel="Close popup modal"
        onClick={props.onCloseButton}
      />
    </div>
  );
};

ActionTitle.displayName = "ActionTitle";

export { ActionTitle };
