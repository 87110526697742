import React, { useCallback, useContext, useRef, useState } from "react";
import { Text, Selection, SelectionMode, MessageBar, MessageBarType, Separator } from "@fluentui/react";
import { getTreasuryDashboardColumns } from "../../components/TreasuryDashboard/TreasuryDashboard.columns";
import {
  SanitizedTreasuryRow,
  ITreasuryState,
  InitialTreasuryState
} from "../../components/TreasuryDashboard/TreasuryDashboard.types";
import TreasuryDashboardForm, {
  getGridItemsFromPayload
} from "../../components/TreasuryDashboard/TreasuryDashboardForm";
import { useTreasuryDashboardActions } from "./TreasuryDashboard.actions";
import {
  ActionResult,
  ActionsManager,
  ActionsProvider,
  ActionTypes,
  DashboardGrid,
  IActionsManagerRef,
  JemConfiguration,
  JemNotification,
  LoadingSpinner,
  LoadingStatus,
  LocalMessage,
  LoggingContext,
  PageHeading,
  PageStyles,
  UserContext
} from "@jem/components";
import { IDashboardGridRef } from "@jem/components";
import { exportToExcel } from "@jem/components";
import { DashboardListActions, IDashboardListActionsState } from "@jem/components";
import { GridCommandToggle, DashboardListActionsRef } from "@jem/components";

export interface TreasuryDashboardProps {
  configuration: JemConfiguration["IhccApi"];
}

function ActionHandler(
  actionManagerRef: React.RefObject<IActionsManagerRef<SanitizedTreasuryRow>>,
  dashboardGridRef: React.RefObject<IDashboardGridRef>,
  actionName: ActionTypes
): () => void {
  return () => {
    if (actionManagerRef.current && dashboardGridRef.current) {
      const selection = dashboardGridRef.current.getSelection();
      const items = selection.items as SanitizedTreasuryRow[];
      actionManagerRef.current.open(actionName, items);
    }
  };
}

function TreasuryDashboardActions(
  actionManagerRef: React.RefObject<IActionsManagerRef<SanitizedTreasuryRow>>,
  dashboardGridRef: React.RefObject<IDashboardGridRef>
) {
  return {
    releaseToSAP: ActionHandler(actionManagerRef, dashboardGridRef, ActionTypes.saveToSAP),
    sendBackFromTreasury: ActionHandler(actionManagerRef, dashboardGridRef, ActionTypes.sendBackFromTreasury)
  };
}

const initialButtonStates: IDashboardListActionsState = Object.freeze({
  releaseToSAPDisabled: GridCommandToggle.Disabled,
  sendToPosterDisabled: GridCommandToggle.Removed,
  sendBackFromTreasuryDisabled: GridCommandToggle.Disabled,
  needsClarificationDisabled: GridCommandToggle.Removed,
  releaseForSignoffDisabled: GridCommandToggle.Removed,
  approveDisabled: GridCommandToggle.Removed,
  retryDisabled: GridCommandToggle.Removed,
  saveDisabled: GridCommandToggle.Removed,
  addPosterDisabled: GridCommandToggle.Removed,
  addReviewerDisabled: GridCommandToggle.Removed,
  recallDisabled: GridCommandToggle.Removed,
  rescindDisabled: GridCommandToggle.Removed,
  addAttachmentDisabled: GridCommandToggle.Removed,
  deleteActionDisabled: GridCommandToggle.Removed
});

const calculateButtonStates = (dRows: any): IDashboardListActionsState => {
  const rows = dRows as SanitizedTreasuryRow[];
  if (rows.length === 0) {
    return initialButtonStates;
  }

  const compareString = (str1: string, str2: string) => {
    return str1.toLowerCase().indexOf(str2.toLowerCase());
  };

  const stateToReturn: IDashboardListActionsState = { ...initialButtonStates };
  stateToReturn.releaseToSAPDisabled = GridCommandToggle.Enabled;
  stateToReturn.sendBackFromTreasuryDisabled = GridCommandToggle.Enabled;
  for (const row of rows) {
    const isSignedOffFlag = compareString(row.statusCode, "Signed Off") !== -1;
    if (isSignedOffFlag) {
      continue;
    }
    const isSignoffNotReqdFlag = compareString(row.statusCode, "Signoff Not Req’d") !== -1;
    if (isSignoffNotReqdFlag) {
      continue;
    }
    stateToReturn.releaseToSAPDisabled = GridCommandToggle.Disabled;
    stateToReturn.sendBackFromTreasuryDisabled = GridCommandToggle.Disabled;
  }
  return stateToReturn;
};

const treasuryDashboardConfigName = "treasuryDashboardConfigName";

const TreasuryDashboard: React.FC<TreasuryDashboardProps> = (props) => {
  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.Idle);
  const [currentTreasuryState, setCurrentTreasuryState] = useState<ITreasuryState | undefined>(undefined);
  const { accessToken } = useContext(UserContext);
  const [gridItems, setGridItems] = useState<SanitizedTreasuryRow[]>([]);

  // Actions Configuration
  const logger = useContext(LoggingContext);
  const actionManagerRef = useRef<IActionsManagerRef<SanitizedTreasuryRow>>(null);
  const dashboardActionsRef = useRef<DashboardListActionsRef>(null);
  const treasuryDashboardGridRef = useRef<IDashboardGridRef>(null);
  const actions = useTreasuryDashboardActions(props.configuration);
  const [message, setMessage] = useState<LocalMessage | null>(null);

  const setGridLoading = useCallback(
    (flag: boolean) => {
      if (flag) {
        setLoadingStatus(LoadingStatus.Pending);
      } else {
        setLoadingStatus(LoadingStatus.Resolved);
      }
    },
    [setLoadingStatus]
  );

  const processNewGridItems = (searchResult: SanitizedTreasuryRow[], treasuryState: ITreasuryState) => {
    setCurrentTreasuryState(treasuryState);
    setGridItems(searchResult);
    setLoadingStatus(LoadingStatus.Resolved);
  };

  const refreshTrigger = async (search: ITreasuryState, procStatusMessages: JemNotification) => {
    setLoadingStatus(LoadingStatus.Pending);
    try {
      let gridData = await getGridItemsFromPayload(accessToken, search, props.configuration);
      gridData = gridData.map((x) => {
        if (procStatusMessages.affectedEntries && procStatusMessages.affectedEntries.includes(x.refGuid)) {
          x.processingStatus = procStatusMessages.summaryBodyText ? procStatusMessages.summaryBodyText : "";
        }
        return x;
      });
      processNewGridItems(gridData, search);
      let messageType = MessageBarType.info;
      if (procStatusMessages && procStatusMessages.type === "Error") messageType = MessageBarType.error;
      if (procStatusMessages && procStatusMessages.type === "Success") messageType = MessageBarType.success;
      setMessage({
        message: procStatusMessages.summaryBodyText ? procStatusMessages.summaryBodyText : "",
        type: messageType
      });
    } catch (err) {
      setLoadingStatus(LoadingStatus.Rejected);
    }
  };

  const onCancelAction = () => {
    if (treasuryDashboardGridRef.current) {
      treasuryDashboardGridRef.current.clearSelection();
    }
  };

  const onSubmitAction = (actionResult: ActionResult) => {
    if (treasuryDashboardGridRef.current) {
      treasuryDashboardGridRef.current.clearSelection();
    }
    logger.addNotification(actionResult.notification);
    refreshTrigger(currentTreasuryState ? currentTreasuryState : InitialTreasuryState, actionResult.notification);
  };

  return (
    <>
      {!message || message.type == MessageBarType.info ? null : (
        <MessageBar
          messageBarType={message.type}
          isMultiline={false}
          onDismiss={() => setMessage(null)}
          dismissButtonAriaLabel="Close"
        >
          {message.message}
        </MessageBar>
      )}
      <ActionsProvider>
        <ActionsManager<SanitizedTreasuryRow, ActionResult>
          customRef={actionManagerRef}
          onCancel={onCancelAction}
          onSubmit={onSubmitAction}
          // attachmentsActionsMap={attachmentActions}
          endpointMap={actions}
        ></ActionsManager>
      </ActionsProvider>
      <div className={PageStyles.rootDiv}>
        <PageHeading>
          <h2>Payment Orders Release</h2>
        </PageHeading>
        <TreasuryDashboardForm
          configuration={props.configuration}
          setGridLoading={setGridLoading}
          setGridItems={processNewGridItems}
          searchState={currentTreasuryState}
          triggerRefreshFn={refreshTrigger}
        />
        <Separator />
        {loadingStatus !== LoadingStatus.Pending && gridItems.length === 0 ? (
          <Text
            variant="xLarge"
            style={{
              color: "var(--accent-font-color, black)"
            }}
          >
            No Items to show.
          </Text>
        ) : loadingStatus === LoadingStatus.Resolved ? (
          <>
            <DashboardGrid
              idForLocalStorage={treasuryDashboardConfigName}
              customRef={treasuryDashboardGridRef}
              columnGenerator={getTreasuryDashboardColumns()}
              items={gridItems}
              height={"500px"}
              selectionMode={SelectionMode.multiple}
              onSelectionChanged={(selection: Selection) => {
                if (dashboardActionsRef.current) {
                  const userSelectedItems = selection.getSelection();
                  dashboardActionsRef.current.refreshSelection(userSelectedItems as SanitizedTreasuryRow[]);
                }
              }}
              onExport={(rows) => {
                exportToExcel({
                  sheetName: "Treasury Dashboard Results",
                  rowsWithHeader: rows,
                  fileName: "Payment Orders.xlsx"
                });
              }}
            >
              <DashboardListActions
                customRef={dashboardActionsRef}
                hide={false}
                buttonCalculator={calculateButtonStates}
                preventCollapse={true}
                {...initialButtonStates}
                {...TreasuryDashboardActions(actionManagerRef, treasuryDashboardGridRef)}
              ></DashboardListActions>
            </DashboardGrid>
          </>
        ) : (
          <LoadingSpinner label="Loading Payment Orders to Release" />
        )}
      </div>
    </>
  );
};

TreasuryDashboard.displayName = "TreasuryDashboard";
export default TreasuryDashboard;
