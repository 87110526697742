import {
  JemConfiguration,
  IAttachmentEntryFormRef,
  LoadingStatus,
  Attachments,
  IAttachment,
  IAttachmentEntryFormSingleUploadProps
} from "@jem/components";
import React, { forwardRef, useContext, useEffect, useImperativeHandle, useRef } from "react";

import { IHCCDraftContext } from "../../contexts/IHCCDraftContext/IHCCDraftContext";
import { useIHCCAttachmentActions } from "../../shared/IHCC.Attachments";

export interface IHCCCreateAttachmentsProps {
  configuration: JemConfiguration["IhccApi"];
  onAttachmentsChange: IAttachmentEntryFormSingleUploadProps["onChange"];
  disabled: boolean;
  loadingStatus: LoadingStatus;
}

export interface IHCCCreateAttachmentsRef {
  getAttachments: IAttachmentEntryFormRef<IAttachment>["saveAndGetAttachments"];
  reset: () => void;
}

const IHCCCreateAttachments = forwardRef<IHCCCreateAttachmentsRef, IHCCCreateAttachmentsProps>((props, ref) => {
  const draftContext = useContext(IHCCDraftContext);
  const attachmentsRef = useRef<IAttachmentEntryFormRef<IAttachment>>(null);
  const attachmentActions = useIHCCAttachmentActions(props.configuration);

  useEffect(() => {
    if (draftContext.draftModel.status === LoadingStatus.Resolved) {
      if (attachmentsRef.current) {
        attachmentsRef.current.setAttachments(draftContext.draftModel.values.attachments, []);
      }
    }
  }, [draftContext.draftModel.status]);

  useImperativeHandle(ref, () => ({
    getAttachments: async () => {
      if (attachmentsRef.current) {
        const error = attachmentsRef.current.getError();
        if (!error) {
          const attachments = await attachmentsRef.current.saveAndGetAttachments();
          return attachments;
        } else
          [
            {
              attachments: [],
              recentlyUploadedAttachments: []
            },
            error
          ];
      }
      return [
        {
          attachments: [],
          recentlyUploadedAttachments: []
        },
        null
      ];
    },
    reset() {
      if (attachmentsRef.current) {
        return attachmentsRef.current.reset();
      }
    }
  }));

  return (
    <>
      <Attachments<IAttachment>
        onUploadFile={attachmentActions.uploadAttachment}
        onDeleteFile={attachmentActions.deleteAttachment}
        onDownloadFile={attachmentActions.downloadAttachment}
        onChange={props.onAttachmentsChange}
        disabled={props.disabled || props.loadingStatus === LoadingStatus.Pending}
        customRef={attachmentsRef}
      ></Attachments>
    </>
  );
});

IHCCCreateAttachments.displayName = "IHCCCreateAttachments";

export default IHCCCreateAttachments;
