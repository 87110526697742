import { useLocation, useNavigate } from "react-router-dom";

export type RouteState = () => { origin: string; state: { [key: string]: any } | undefined } | undefined;

export function getStateObjects(location: ReturnType<typeof useLocation>): ReturnType<RouteState> | undefined {
  if (location.state) {
    const st = location.state as any;
    return {
      origin: st.origin,
      state: st.state
    };
  }
  return undefined;
}

export function navigateToOrigin(
  navigate: ReturnType<typeof useNavigate>,
  location: ReturnType<typeof useLocation>,
  origin = "/",
  clearState = false
): void {
  const stateObjects = getStateObjects(location);
  if (!clearState && stateObjects) {
    const uri = stateObjects.origin;
    navigate(uri, {
      state: stateObjects
    });
  } else {
    navigate(origin, undefined);
  }
}

export function redirectMeToTheOrigin(
  navigate: ReturnType<typeof useNavigate>,
  location: ReturnType<typeof useLocation>,
  timeout = 3000,
  origin = "/",
  clearState = false
): void {
  setTimeout(() => {
    // This timeout is for UX purposes, allows the user to see the notifications before navigating away
    navigateToOrigin(navigate, location, origin, clearState);
  }, timeout);
}
