/* eslint-disable */
import { Separator } from "@fluentui/react";
import {
  JemConfiguration,
  JEMContext,
  JemNotification,
  LoadingSpinner,
  LoadingStatus,
  LoggingContext,
  MockDataFn,
  ObjectKeys,
  PageHeading,
  PageStyles
} from "@jem/components";
import React, { useContext, useEffect, useRef, useState } from "react";
import { createSearchParams, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  batchSearchCriteriaFromURLSearchParams,
  GLBatchSearchCriteria
} from "../../components/GLBatchSearch/GLBatchSearch.types";
import GLBatchSearchOptions from "../../components/GLBatchSearch/GLBatchSearchOptions";
import GLBatchSearchResults, {
  BatchSelected,
  GLBatchSearchResultsRef
} from "../../components/GLBatchSearch/GLBatchSearchResults/GLBatchSearchResults";

export interface GLBatchSearchProps {
  configuration: JemConfiguration["GeneralLedgerApi"];
  attachmentsConfiguration: JemConfiguration["DocumentsApi"];
  mockDashboardDataFn?: MockDataFn<any>;
}

const GLBatchSearch: React.FC<GLBatchSearchProps> = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const jemContext = useContext(JEMContext);
  const [domainDataStatus, setDomainDataStatus] = useState<LoadingStatus>(LoadingStatus.Idle);

  const [searchParams, setSearchParams] = useSearchParams();
  const [currentSearchState, setCurrentSearchState] = useState<GLBatchSearchCriteria | undefined>(undefined);
  const [selectedBatch, setSelectedBatch] = useState<BatchSelected | null>(null);
  const logger = useContext(LoggingContext);
  const batchSearchRef = useRef<GLBatchSearchResultsRef | null>(null);

  useEffect(() => {
    if (
      jemContext.initInfo.status === LoadingStatus.Rejected ||
      jemContext.initInfo.status === LoadingStatus.Resolved
    ) {
      setDomainDataStatus(LoadingStatus.Resolved);
    }
  }, [jemContext.initInfo.values]);

  useEffect(
    function getSearchStateFromQuery() {
      if (
        jemContext.initInfo.status === LoadingStatus.Resolved ||
        jemContext.initInfo.status === LoadingStatus.Rejected
      ) {
        console.log("GLBatchSearch: getSearchStateFromQuery");
        const noSearchParams = searchParams.toString() === "";
        if (noSearchParams) {
          return;
        }
        const { searchCriteria, selectedBatch } = batchSearchCriteriaFromURLSearchParams(searchParams);
        setCurrentSearchState(searchCriteria);
        setSelectedBatch(selectedBatch);
      }
    },
    [searchParams, jemContext.initInfo.values]
  );

  const refreshTrigger = async (search: GLBatchSearchCriteria, notification: JemNotification) => {
    console.log("GLBatchSearch: refreshTrigger");
    const searchParams = search.toRecord();
    const queryParams = new URLSearchParams(searchParams).toString();
    if (batchSearchRef.current) {
      batchSearchRef.current.removeCache();
    }
    navigate({
      search: `?${createSearchParams(queryParams)}`
    });
  };

  return (
    <>
      <div className={PageStyles.rootDiv}>
        <PageHeading>
          <h2>Batch JE Search</h2>
        </PageHeading>
        <div className="ms-Grid" dir="ltr">
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
              {domainDataStatus === LoadingStatus.Resolved && jemContext.initInfo.values ? (
                <>
                  <GLBatchSearchOptions
                    configuration={props.configuration}
                    triggerRefreshFn={refreshTrigger}
                    searchState={currentSearchState}
                    domainData={jemContext.initInfo.values}
                  />
                  <Separator />
                </>
              ) : (
                <LoadingSpinner label="Loading Batch Search Form" />
              )}
            </div>
          </div>
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
              {domainDataStatus === LoadingStatus.Resolved && jemContext.initInfo.values && currentSearchState && (
                <GLBatchSearchResults
                  configuration={props.configuration}
                  domainData={jemContext.initInfo.values}
                  attachmentsConfiguration={props.attachmentsConfiguration}
                  searchCriteria={currentSearchState}
                  onBatchSelected={(batch) => {
                    const searchParams = currentSearchState.toRecord();
                    // add batch refguid and batch name to url params
                    searchParams.cBatchRefGuid = batch.refGuid;
                    searchParams.cBatchName = batch.batchName;
                    const queryParams = new URLSearchParams(searchParams).toString();
                    navigate({
                      search: `?${createSearchParams(queryParams)}`
                    });
                  }}
                  selectedBatch={selectedBatch}
                  customRef={batchSearchRef}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

GLBatchSearch.displayName = "GLBatchSearch";
export default GLBatchSearch;
