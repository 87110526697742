import {
  BaseButton,
  Button,
  DefaultButton,
  Dropdown,
  IDropdownOption,
  PrimaryButton,
  Stack,
  Text
} from "@fluentui/react";
import {
  GLNumberSearchState,
  JemConfiguration,
  DomainDataObjects,
  JemNotification,
  RefTextFieldRef,
  RefTextField,
  ForceMaxWidthAndHeightOnDropdownStyle,
  DomainDataEnum,
  currentFyFp,
  CompanyCodeMultiSelector,
  CompanyCodeMultiSelectorRef
} from "@jem/components";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { v4 } from "uuid";
import { fiscalYearAndFiscalPeriodForSearch } from "../GLSearch/GLSearchForm";

// eslint-disable-next-line
const isEqual = require("lodash/isEqual");

interface IGLNumberSearchFormProps {
  numSearchState: GLNumberSearchState | null;
  configuration: JemConfiguration["GeneralLedgerApi"];
  domainData: Pick<DomainDataObjects, DomainDataEnum.FiscalPeriods> | null;
  triggerRefreshFn: (numSearchState: GLNumberSearchState | null, procStatusMessages: JemNotification | null) => void;
  errorMessage?: string;
}

function sapModulesForNumSearch(): IDropdownOption[] {
  const sapModules = ["FI", "ECCS", "PCA"];
  const sapModulesForDropdown = sapModules.map((x) => ({ key: x, text: x } as IDropdownOption));
  return sapModulesForDropdown;
}

const GLNumberSearchForm: React.FC<IGLNumberSearchFormProps> = (props) => {
  const docNumberRef = useRef<RefTextFieldRef>(null);
  const [fiscalYear, setFiscalYear] = useState<GLNumberSearchState["FiscalYear"]>(currentFyFp().fiscalYear);
  const [sapModule, setsSAPModule] = useState<GLNumberSearchState["SAPModule"]>("FI");
  const companyCodeRef = useRef<CompanyCodeMultiSelectorRef>(null);

  const domainData = props.domainData || {
    FiscalPeriods: []
  };

  const fiscalYearSettings = useMemo<{
    fiscalYearsForDropdown: IDropdownOption[];
    currentFiscalYear: number;
    currentFiscalPeriod: number;
  }>(() => {
    return fiscalYearAndFiscalPeriodForSearch(domainData);
  }, [props.domainData]);

  useEffect(
    function searchStateChanged() {
      if (props.numSearchState && docNumberRef.current && companyCodeRef.current) {
        const localState = new GLNumberSearchState({
          DocNumber: docNumberRef.current?.getValue() || "",
          CompanyCode: companyCodeRef.current?.getCompanyCodes()[0] || 0,
          FiscalYear: fiscalYear,
          SAPModule: sapModule,
          RefGuid: props.numSearchState.RefGuid
        });
        if (isEqual(localState, props.numSearchState)) {
          return;
        }
        const commonMessage: JemNotification = {
          subjectHeader: " ",
          summaryBodyText: " ",
          type: "Information"
        };
        if (docNumberRef.current && companyCodeRef.current) {
          docNumberRef.current.setInitialValue(props.numSearchState.DocNumber);
          companyCodeRef.current.setCompanyCodes([props.numSearchState.CompanyCode]);
          setFiscalYear(props.numSearchState.FiscalYear);
          setsSAPModule(props.numSearchState.SAPModule);
          props.triggerRefreshFn(props.numSearchState, commonMessage);
        }
      }
    },
    [props.numSearchState]
  );

  const onChangeFiscalYear = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void => {
    if (option) {
      setFiscalYear(option.key as number);
    }
  };
  const onChangeSAPModule = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void => {
    if (option) {
      setsSAPModule(option.key as string);
    }
  };

  const reset = () => {
    setFiscalYear(currentFyFp().fiscalYear);
    setsSAPModule("FI");
    if (docNumberRef.current) {
      docNumberRef.current.reset();
    }
    if (companyCodeRef.current) {
      companyCodeRef.current.reset();
    }
    props.triggerRefreshFn(null, null);
  };

  const submit = async (
    ev: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | BaseButton | Button | HTMLSpanElement>
  ) => {
    ev.preventDefault();
    if (docNumberRef.current && companyCodeRef.current) {
      const commonMessage: JemNotification = {
        subjectHeader: " ",
        summaryBodyText: " ",
        type: "Information"
      };
      const cc = companyCodeRef.current.getCompanyCodes();
      if (cc.length !== 1) {
        props.triggerRefreshFn(null, commonMessage);
        return;
      }
      const docNumber = docNumberRef.current.getValue();
      if (!docNumber) {
        props.triggerRefreshFn(null, commonMessage);
        return;
      }

      const currentState = new GLNumberSearchState({
        DocNumber: docNumber,
        CompanyCode: cc[0],
        FiscalYear: fiscalYear,
        SAPModule: sapModule,
        RefGuid: v4()
      });

      props.triggerRefreshFn(currentState, {
        subjectHeader: " ",
        summaryBodyText: " ",
        type: "Information"
      });
    }
  };

  return (
    <React.Fragment>
      <Stack
        horizontal
        disableShrink
        horizontalAlign="center"
        wrap
        tokens={{ childrenGap: 8 }}
        styles={{
          root: {
            marginBottom: "8px"
          }
        }}
      >
        <Stack.Item
          styles={{
            root: {
              paddingRight: "30px"
            }
          }}
        >
          <Stack tokens={{ childrenGap: 4 }}>
            <Stack.Item>
              <RefTextField customRef={docNumberRef} label={"Doc Number:"} type={"number"} required />
            </Stack.Item>
            <Stack.Item>
              <CompanyCodeMultiSelector
                customRef={companyCodeRef}
                maxCompanies={1}
                required={true}
              ></CompanyCodeMultiSelector>
            </Stack.Item>
          </Stack>
        </Stack.Item>
        <Stack.Item
          styles={{
            root: {
              paddingRight: "30px"
            }
          }}
        >
          <Stack tokens={{ childrenGap: 4 }}>
            <Stack.Item>
              <Dropdown
                label="Fiscal Year:"
                selectedKey={fiscalYear}
                onChange={onChangeFiscalYear}
                options={fiscalYearSettings.fiscalYearsForDropdown}
                // disabled={fiscalYearsLoading}
                styles={ForceMaxWidthAndHeightOnDropdownStyle({
                  width: "150px",
                  height: "300px"
                })}
              />
            </Stack.Item>
            <Stack.Item>
              <Dropdown
                label="SAP Module:"
                selectedKey={sapModule}
                onChange={onChangeSAPModule}
                options={sapModulesForNumSearch()}
                // disabled={sapModuleLoading}
                styles={ForceMaxWidthAndHeightOnDropdownStyle({
                  width: "150px",
                  height: "300px"
                })}
              />
            </Stack.Item>
          </Stack>
        </Stack.Item>
        <Stack.Item>
          <Stack tokens={{ childrenGap: 4 }}>
            <Stack horizontal tokens={{ childrenGap: 8 }}>
              <Stack.Item
                styles={{
                  root: {
                    paddingTop: "30px"
                  }
                }}
              >
                <PrimaryButton text="Search" allowDisabledFocus onClick={submit} type="submit" disabled={false} />
              </Stack.Item>
              <Stack.Item
                styles={{
                  root: {
                    paddingTop: "30px"
                  }
                }}
              >
                <DefaultButton text="Reset" allowDisabledFocus onClick={reset} />
              </Stack.Item>
            </Stack>
            <Stack.Item>
              {props.errorMessage && (
                <Text variant="small" styles={{ root: { paddingTop: "10px" } }}>
                  {props.errorMessage}
                </Text>
              )}
            </Stack.Item>
          </Stack>
        </Stack.Item>
      </Stack>
    </React.Fragment>
  );
};

GLNumberSearchForm.displayName = "GLNumberSearchForm";
export default GLNumberSearchForm;
