﻿// TODO: Implement this
import { ApplicationInsights /* ITelemetryItem */, ITelemetryItem } from "@microsoft/applicationinsights-web";
import { JemConfiguration } from "../../JemConfiguration";

export function setupAppInsights(envType: string, configuration: JemConfiguration): ApplicationInsights {
  const appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: configuration.instrumentationKey,
      isCookieUseDisabled: true,
      appId: "",
      enableCorsCorrelation: true,
      enableAutoRouteTracking: true
    }
  });
  appInsights.loadAppInsights();
  const telemetryInitializer: (item: ITelemetryItem) => boolean | void = (envelope) => {
    try {
      (envelope as any).context = (envelope as any).context || {};
      (envelope as any).context.application = (envelope as any).context.application || {};
    } catch (e) {
      (envelope as any).context.application.build = "no hash available";
    }
    if (
      envType.indexOf("accessibility") !== -1 ||
      envType.indexOf("integration") !== -1 ||
      envType.indexOf("local") !== -1
    ) {
      // do not log automation to app insights
      return;
    }
    if (envelope.ext && "user" in envelope.ext) {
      delete envelope.ext.user;
    }
  };
  appInsights.addTelemetryInitializer(telemetryInitializer);
  return appInsights;
}

export function getLogger(envType: string, configuration: JemConfiguration): ApplicationInsights {
  return envType === "accessibility" || envType === "test" || envType === "local"
    ? ({
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        trackDependencyData: () => {},
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        trackEvent: () => {},
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        trackPageView: () => {},
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        trackPageViewPerformance: () => {},
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        trackException: () => {},
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        trackTrace: () => {},
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        trackMetric: () => {},
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        startTrackPage: () => {},
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        stopTrackPage: () => {},
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        startTrackEvent: () => {},
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        stopTrackEvent: () => {}
      } as any)
    : setupAppInsights(envType, configuration);
}
