import { ErrorHelper, ErrorMessages, GeneralLedgerAction } from "@jem/components";

// iterate processingStatus.changes and update gridData, jeId in gridData is a string, but in changes it is a number
export function augmentWithProcessingStatus<T extends { jeId: string; processingStatus: string; procStatType: string }>(
  items: T[],
  processingStatus: GeneralLedgerAction
): T[] {
  const gridDataCopy = [...items];
  for (const change of processingStatus.changes) {
    const index = gridDataCopy.findIndex((row) => row.jeId === change.jeId.toString());
    if (index !== -1) {
      const errorMessage = ErrorHelper.getMessage(change.errorCode as keyof typeof ErrorMessages);
      gridDataCopy[index].processingStatus = errorMessage;
      gridDataCopy[index].procStatType =
        change.errorCode && change.errorCode.indexOf("ERR") !== -1 ? "Error" : "Success";
    }
  }
  return gridDataCopy;
}
