import React, { createContext, useContext, useEffect, useState } from "react";
import { DomainDataAPI } from "../../../JemConfiguration";
import { FatalError } from "../../utilities/ErrorHelpers";
import { IUserProviderState } from "../../utilities/RequestUtilities";
import { ObjectKeys } from "../../utilities/TypeUtils";
import { LoadingStatus } from "../../utilities/Utilities";
import { ILoggingProviderState, LoggingContext } from "../LoggingContext/LoggingContext";
import { UserContext } from "../UserContext/UserContext";
import { DomainDataManager, getDomainData } from "./JEMContext.domainData.requests";
import {
  DomainDataEnum,
  DomainDataObjects,
  JemConfigurationDomainDataApiSubset,
  JEMProviderProps
} from "./JEMContext.domainData.types";

export type JEMContextData = {
  initInfo: {
    values: DomainDataObjects | null;
    status: LoadingStatus;
  };
};

const initialState: JEMContextData = {
  initInfo: {
    values: null,
    status: LoadingStatus.Idle
  }
};

const JEMContext = createContext(initialState);

export async function loader<T, Configuration>(
  userContext: IUserProviderState,
  logger: ILoggingProviderState,
  configuration: Configuration,
  fetcherFunction: (
    configuration: Configuration,
    userContext: IUserProviderState["accessToken"],
    logger: ILoggingProviderState,
    subset?: DomainDataEnum[]
  ) => Promise<T>,
  loadingDispatch: React.Dispatch<React.SetStateAction<LoadingStatus>>,
  valuesDispatch: React.Dispatch<React.SetStateAction<T>>,
  subset?: DomainDataEnum[]
) {
  loadingDispatch(LoadingStatus.Pending);
  try {
    const apiData = await fetcherFunction(configuration, userContext.accessToken, logger, subset);
    if (apiData) {
      loadingDispatch(LoadingStatus.Resolved);
      valuesDispatch(apiData);
    } else {
      loadingDispatch(LoadingStatus.Rejected);
    }
  } catch (e) {
    loadingDispatch(LoadingStatus.Rejected);
    throw e;
  }
}

type KeyPromiseTuple<K = keyof DomainDataObjects> = K extends keyof DomainDataObjects
  ? [K, Promise<DomainDataObjects[K]>]
  : never;

type KeyTuple<K = keyof DomainDataObjects> = K extends keyof DomainDataObjects ? [K, DomainDataObjects[K]] : never;

const DomainDataTransforms: Partial<{
  [key in DomainDataEnum]: (data: DomainDataObjects[key]) => DomainDataObjects[key];
}> = {
  JeReasonCodes: (data) => {
    // sort by jeReason property which is a string
    return data.sort((a, b) => {
      if (a.jeReason < b.jeReason) return -1;
      if (a.jeReason > b.jeReason) return 1;
      return 0;
    });
  },
  JeCompanyCodes: (data) => {
    return data.filter((item) => {
      return item.glCompanyName.toLowerCase().indexOf("inactive") === -1;
    });
  }
};

const getTransformConstrainedToType = <K extends keyof DomainDataObjects>(key: K) =>
  DomainDataTransforms[key] as (data: DomainDataObjects[K]) => DomainDataObjects[K];

const getAllDomainData = async (
  configuration: DomainDataAPI | JemConfigurationDomainDataApiSubset,
  getTokenFn: IUserProviderState["accessToken"],
  logger: ILoggingProviderState,
  subset?: DomainDataEnum[]
): Promise<DomainDataObjects | null> => {
  try {
    const domainDataConfigurations = new DomainDataManager(configuration, subset);

    // FIRE THEM ALL
    const domainDataPromises = ObjectKeys(domainDataConfigurations.configuration).map((domainType) => {
      const transformFn = getTransformConstrainedToType(domainType);
      const configurationItem = domainDataConfigurations.domainDataKey(domainType);
      const dataGetterPromise = configurationItem
        ? getDomainData<typeof domainType>(configuration, getTokenFn, configurationItem, transformFn)
        : Promise.resolve([]);
      return [domainType, dataGetterPromise] as KeyPromiseTuple;
    });

    let error = false;
    // WAIT FOR THEM ALL
    const domainDataObjects = await Promise.all(
      domainDataPromises.map(async ([key, promise]) => {
        try {
          const domainData = await promise;
          return [key, domainData] as KeyTuple;
        } catch (e) {
          logger.addNotification({
            type: "Error",
            subjectHeader: `${key} ERROR`,
            summaryBodyText: (e as Error).message,
            actionRequiredText: "Please contact Support.",
            subjectIcon: "Error",
            subjectIconColor: "red",
            exception: e
          });
          error = true;
          return [key, []] as KeyTuple;
        }
      })
    );

    // COMBINE THEM ALL
    const retVal = Object.fromEntries(domainDataObjects) as DomainDataObjects;
    if (!error) {
      logger.clearNotifications();
    }

    // AND IN THE DARKNESS, SPIKE THEM ALL
    return retVal;
  } catch (e) {
    return null;
  }
};

const JEMProvider: React.FC<JEMProviderProps> = (props) => {
  const logger = useContext(LoggingContext);
  if (!logger) {
    throw new FatalError("Setup an ApplicationInsights Provider.");
  }
  const userContext = useContext(UserContext);
  if (!userContext) {
    throw new FatalError("Please use a UserContext Provider.");
  }

  const [domainDataStatus, setDomainDataStatus] = useState<LoadingStatus>(LoadingStatus.Idle);
  const [domainData, setDomainData] = useState<DomainDataObjects | null>(null);

  useEffect(() => {
    if (!props.disabled) {
      loader<DomainDataObjects | null, JemConfigurationDomainDataApiSubset>(
        userContext,
        logger,
        props.configuration,
        getAllDomainData,
        setDomainDataStatus,
        setDomainData,
        props.subset
      );
    }
  }, []);

  return (
    <>
      <JEMContext.Provider
        value={{
          initInfo: {
            values: domainData,
            status: domainDataStatus
          }
        }}
      >
        {props.children}
      </JEMContext.Provider>
    </>
  );
};

export { JEMContext, JEMProvider };
