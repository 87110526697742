import React, { useState, useEffect, useImperativeHandle } from "react";
import { IChoiceGroupProps, IChoiceGroupOption, ChoiceGroup } from "@fluentui/react";

export interface IPosterOrReviewerChoiceProps {
  id: string;
  initialSelected: "P" | "R";
  label?: string;
  options?: IChoiceGroupProps["options"];
  customRef?: React.Ref<IAliasTypeRef>;
  horizontal?: boolean;
  disabled?: boolean;
}

export interface IAliasTypeRef {
  getAliasType: () => "P" | "R";
  reset: () => void;
}

const choiceGroupStyles: IChoiceGroupProps["styles"] = (horizontal: boolean) => {
  const styles = {
    root: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      alignContent: "center"
    },
    label: {
      margin: "0 8px 0 0"
    },
    flexContainer: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr auto",
      gridColumnGap: "16px",
      marginTop: "-8px"
    }
  };
  if (horizontal) {
    styles.root["> div[role=radiogroup]"] = {
      display: "flex"
    };
  }
  return styles;
};

const choicesForChoiceGroup: IChoiceGroupProps["options"] = [
  {
    key: "P",
    text: "Requestor"
  },
  {
    key: "R",
    text: "Reviewer"
  }
];

const PosterOrReviewerChoice: React.FC<IPosterOrReviewerChoiceProps> = (props) => {
  const [choice, setChoice] = useState<"P" | "R">(props.initialSelected);

  useEffect(() => {
    setChoice(props.initialSelected);
  }, [props.initialSelected]);

  useImperativeHandle(
    props.customRef,
    (): IAliasTypeRef => ({
      getAliasType() {
        return choice;
      },
      reset() {
        setChoice("P");
      }
    })
  );

  const onChange = (ev: any, option?: IChoiceGroupOption) => {
    const opt = option && option.key;
    setChoice(opt as "R" | "P");
  };

  return (
    <ChoiceGroup
      className="defaultChoiceGroup"
      styles={choiceGroupStyles(props.horizontal || false)}
      id={props.id}
      defaultSelectedKey={choice}
      options={props.options || choicesForChoiceGroup}
      ariaLabelledBy="posterOrReviewerChoiceLabel"
      label={props.label || "Alias Type:"}
      onChange={onChange}
      disabled={props.disabled}
    />
  );
};

PosterOrReviewerChoice.displayName = "PosterOrReviewerChoice";
export { PosterOrReviewerChoice };
