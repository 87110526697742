import React, { forwardRef, useImperativeHandle, useMemo, useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { css } from "@emotion/css";
import { useTheme, Theme } from "@fluentui/react";

export interface IDragAndDrop {
  ref: {
    getAddedFiles: () => File[];
  };
}

const getStyle = (theme: Theme) => {
  const baseStyle = {
    flex: 1,
    display: "flex",
    alignItems: "center",
    padding: "8px 0 8px 16px",
    fontFamily: theme.fonts.medium.fontFamily,
    fontSize: theme.fonts.medium.fontSize,
    marginTop: "8px",
    marginBottom: "8px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: theme.palette.neutralTertiary,
    borderStyle: "dashed",
    backgroundColor: theme.palette.neutralLighter,
    color: theme.palette.neutralPrimary,
    outline: "none",
    transition: "border .24s ease-in-out"
  };

  const activeStyle = {
    borderColor: theme.palette.neutralLight
  };

  const acceptStyle = {
    borderColor: theme.palette.themePrimary
  };

  const rejectStyle = {
    borderColor: theme.palette.themePrimary
  };
  return {
    baseStyle,
    acceptStyle,
    activeStyle,
    rejectStyle
  };
};

const compareFilesAndReturnNonDuplicates = (prevFiles: File[], newFiles: File[]) => {
  const newState = [...prevFiles];
  for (const newFile of newFiles) {
    const oneFileIsTheSame = newState.some((previousFile) => {
      if (previousFile.name !== newFile.name) {
        return false;
      } else {
        if (
          previousFile.size !== newFile.size &&
          previousFile.type !== newFile.type &&
          previousFile.lastModified !== newFile.lastModified
        ) {
          return false;
        }
      }
      return true;
    });
    if (!oneFileIsTheSame) {
      newState.push(newFile);
    }
  }
  return newState;
};

const DragAndDrop = forwardRef<IDragAndDrop["ref"], Record<string, unknown>>((_props, ref) => {
  const [addedFiles, setAddedFiles] = useState<File[]>([]);
  const theme = useTheme();

  useImperativeHandle(ref, () => ({
    getAddedFiles() {
      return addedFiles;
    }
  }));

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setAddedFiles((prev) => compareFilesAndReturnNonDuplicates(prev, acceptedFiles));
  }, []);
  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    accept: {
      "application/msword": [".doc"],
      "application/vnd.ms-excel": [".xls"],
      "application/vnd.ms-powerpoint": [".ppt"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
      "text/plain": [".txt"],
      "text/csv": [".csv"]
    }
  });

  const style = useMemo(() => {
    const style = getStyle(theme);
    return {
      ...style.baseStyle,
      ...(isDragActive ? style.activeStyle : ({} as Record<string, unknown>)),
      ...(isDragAccept ? style.acceptStyle : ({} as Record<string, unknown>)),
      ...(isDragReject ? style.rejectStyle : ({} as Record<string, unknown>))
    };
  }, [isDragActive, isDragReject, isDragAccept, theme]);

  return (
    <>
      <div className="container">
        <div
          {...getRootProps({
            style
          })}
          className={css``}
        >
          <input {...getInputProps()} />
          {addedFiles.length === 0 ? (
            <p>Drag 'n' drop some files here, or click to select files</p>
          ) : (
            <ul
              className={css`
                padding: 0 0 0 8px;
                & > li {
                  padding: 0;
                }
              `}
            >
              {addedFiles.map((file, i) => (
                <li key={i}>
                  {file.name} - {file.size} bytes
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </>
  );
});

DragAndDrop.displayName = "DragAndDrop";

export { DragAndDrop };
