import { css } from "@emotion/css";
import { Label, SelectionMode, TextField } from "@fluentui/react";
import { Field, useFormikContext } from "formik";
import React, { useRef } from "react";
import { DashboardGrid } from "../../../../Shared/components/DashboardGrid/DashboardGrid";
import { IDashboardGridRef, IExtendedColumn } from "../../../../Shared/components/DashboardGrid/DashboardGrid.types";
import { exportToExcel } from "../../../../Shared/components/DashboardGrid/DashboardGrid.utilities";
import { JEMAliasPicker } from "../../../../Shared/components/JEMAliasPicker/JEMAliasPicker";
import { SimpleShimmerForForms } from "../../../../Shared/components/SimpleShimmerForForms/SimpleShimmerForForms";
import { CommentsModel, GLCommentsModel } from "../../../../Shared/utilities/SharedModels";
import { LoadingStatus } from "../../../../Shared/utilities/Utilities";
import { removeDuplicateAliases } from "../../../../Shared/utilities/ValidateReviewerAliasesLocally";
import { GLCreateFormikState, GLCreateState } from "../GLCreateForm.types";

interface GLCreatePostersColumn extends IExtendedColumn {
  fieldName: keyof GLCreateState["posters"]["table"][0];
}

export const getGLCreatePosterCommentsColumns = (): IExtendedColumn[] => {
  const cols: GLCreatePostersColumn[] = [
    {
      key: "persona",
      name: "Persona",
      fieldName: "persona",
      type: "string",
      minWidth: 80
    },
    {
      key: "alias",
      name: "Alias",
      fieldName: "alias",
      type: "string",
      minWidth: 80
    },
    {
      key: "clarification",
      name: "Clarification",
      fieldName: "clarification",
      type: "string",
      minWidth: 80
    },
    {
      key: "comments",
      name: "Comments",
      fieldName: "comments",
      type: "string",
      minWidth: 80
    },
    {
      key: "dateAdded",
      name: "Date Added",
      fieldName: "dateAdded",
      type: "date",
      minWidth: 80
    },
    {
      key: "version",
      name: "Version",
      fieldName: "version",
      type: "number",
      minWidth: 40
    }
  ];

  return cols;
};

const PostersColumn: React.FC<{ disabled: boolean; loadingStatus: LoadingStatus; isF05Posting: boolean }> = (props) => {
  const formik = useFormikContext<GLCreateFormikState>();
  return (
    <>
      <div className="ms-Grid" dir="ltr">
        <div className="ms-Grid-row">
          <Label>Enter a comment and the email alias of the Poster</Label>
        </div>
        <div className="ms-Grid-row">
          <SimpleShimmerForForms loadingStatus={props.loadingStatus} height={32} label="Poster:">
            <Field
              name={"postersTabPoster"}
              label="Poster:"
              as={TextField}
              type="input"
              errorMessage={props.disabled ? "" : formik.errors.postersTabPoster || ""}
              disabled={props.isF05Posting ? props.isF05Posting : props.disabled}
            />
          </SimpleShimmerForForms>
        </div>
        <div className="ms-Grid-row">
          <SimpleShimmerForForms loadingStatus={props.loadingStatus} height={32} label="Additional Posters:">
            <JEMAliasPicker
              label="Additional Posters"
              required={false}
              disabled={props.disabled}
              initialAliases={formik.values.postersTabBackupPosters}
              onChange={(value) => {
                // remember to remove duplicates from the list
                const unique = removeDuplicateAliases(value);
                formik.setFieldValue("postersTabBackupPosters", unique);
              }}
              description="Maximum of 75 user aliases separated by semicolon(;)."
              errorMessage={
                props.disabled
                  ? ""
                  : formik.errors.postersTabBackupPosters
                  ? formik.errors.postersTabBackupPosters.toString()
                  : ""
              }
            />
          </SimpleShimmerForForms>
        </div>
        <div className="ms-Grid-row">
          <SimpleShimmerForForms loadingStatus={props.loadingStatus} height={64} label="Comments:">
            <Field
              name={"postersTabComments"}
              label="Comments:"
              as={TextField}
              multiline
              rows={2}
              type="input"
              errorMessage={props.disabled ? "" : formik.errors.postersTabComments}
              disabled={props.disabled}
            />
          </SimpleShimmerForForms>
        </div>
      </div>
    </>
  );
};

let PosterComments: React.FC<{ comments: CommentsModel[] }> = (props) => {
  const dashboardGridRef = useRef<IDashboardGridRef>(null);

  return (
    <div
      className={css`
        border-left: 2px solid;
        padding-left: 10px;
      `}
      data-is-scrollable="true"
    >
      <DashboardGrid
        idForLocalStorage={"glCreatepostersAndReviewTable"}
        customRef={dashboardGridRef}
        columnGenerator={getGLCreatePosterCommentsColumns}
        items={props.comments}
        isSortedIndex={1}
        height={`200px`}
        isSortedDescending={true}
        selectionMode={SelectionMode.multiple}
        onExport={(rows) => {
          exportToExcel({
            sheetName: "posterComments",
            rowsWithHeader: rows,
            fileName: "posterComments.xlsx"
          });
        }}
        disablePagination={true}
      ></DashboardGrid>
    </div>
  );
};

PosterComments = React.memo(PosterComments);
PosterComments.displayName = "PosterComments";

export interface PosterFormProps {
  comments: GLCommentsModel[];
  disabled: boolean;
  loadingStatus: LoadingStatus;
  isF05Posting: boolean;
}

const GLPostersTab: React.FC<PosterFormProps> = (props) => {
  return (
    <React.Fragment>
      <div className="ms-Grid" dir="ltr">
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm5">
            <PostersColumn
              disabled={props.disabled}
              loadingStatus={props.loadingStatus}
              isF05Posting={props.isF05Posting}
            />
          </div>
          <div className="ms-Grid-col ms-sm7">
            <PosterComments comments={props.comments} />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

GLPostersTab.displayName = "GLPostersTab";
export { GLPostersTab };
