import { AuthenticationResult } from "@azure/msal-browser";
import { useContext } from "react";
import { addAttachmentToPoArray } from "./IHCC.utilities";
import { IHCCResponse } from "./IHCC.types";
import {
  ActionTypes,
  ErrorHelper,
  JemNotification,
  postRequest,
  ErrorMessages,
  ApiError,
  JemConfiguration,
  ActionEndpointMap,
  UserContext,
  getValidUrl,
  ActionResult,
  IAttachment
} from "@jem/components";

export type HasRefGuidAndRowVer = {
  poId: string;
  refGuid: string;
  rowVersion: string | null;
  bulkGuid: string | null;
};

const makeCommonPayload = <T extends HasRefGuidAndRowVer>(items: T[]) => {
  const RefGuids = items.map((i) => i.refGuid);
  const RowVer = items.map((i) => i.rowVersion);
  return {
    RefGuids,
    RowVer
  };
};

interface AddReviewerResponse extends IHCCResponse {
  affectedPos: {
    poId: number;
    additionalReviewer: string | null;
    supervisor: string | null;
  }[];
}

type IHCCTypeOfResponse = IHCCResponse | AddReviewerResponse | RescindResponse;

export async function responseHandler(
  actionType: ActionTypes,
  endpoint: string,
  payload: any,
  getTokenFn: () => Promise<AuthenticationResult | undefined>
): Promise<JemNotification> {
  try {
    const response = await postRequest<IHCCTypeOfResponse>(endpoint, payload, getTokenFn);
    if (!response) {
      return {
        subjectHeader: "IHCC API Error",
        summaryBodyText: "No response received from Backend",
        type: "Error",
        affectedEntries: [],
        payloadEntries: payload.RefGuids
      } as JemNotification;
    } else {
      if (actionType === ActionTypes.rescind) {
        const r = response as RescindResponse;
        const affectedEntires: string[] = [];
        const payloadEntires: string[] = [];
        payload.attachments.forEach((x: { poId: { toString: () => string } }) =>
          payloadEntires.push(x.poId.toString())
        );
        r.result.forEach((x) => {
          affectedEntires.push(x.poId.toString());
        });
        let message = "";
        let type = "Success";
        if (r.isCompletedSuccessfully) {
          message = "Rescind action completed successfully.";
        } else if (r.isCanceled) {
          message = "Rescind action canceled.";
          type = "Information";
        } else if (r.isFaulted) {
          message = "Rescind action has issues.";
          type = "Error";
        }
        return {
          subjectHeader: `IHCC: ${actionType}`,
          summaryBodyText: message,
          type,
          affectedEntries: affectedEntires,
          payloadEntries: payloadEntires
        } as JemNotification;
      } else if (actionType === ActionTypes.addReviewer) {
        const r = response as AddReviewerResponse;
        const message = ErrorHelper.getMessage(r.responseCode as keyof typeof ErrorMessages);
        return {
          subjectHeader: `IHCC: ${actionType}`,
          summaryBodyText: message,
          type: "Success",
          //affected entries will always be the same as payload entries because add reviewer action is all or nothing response, no mixed
          affectedEntries: payload.RefGuids,
          payloadEntries: payload.RefGuids
        } as JemNotification;
      } else {
        const r = response as IHCCResponse;
        const message = ErrorHelper.getMessage(r.responseCode as keyof typeof ErrorMessages);
        return {
          subjectHeader: `IHCC: ${actionType}`,
          summaryBodyText: message,
          type: "Success",
          affectedEntries: r.affectedRefGuid,
          payloadEntries: payload.RefGuids
        } as JemNotification;
      }
    }
  } catch (e) {
    if (e instanceof ApiError) {
      const message = (e as ApiError).message;
      return {
        subjectHeader: `IHCC: ${actionType}`,
        summaryBodyText: message,
        type: "Error",
        affectedEntries: [],
        payloadEntries: payload.RefGuids
      } as JemNotification;
    } else {
      const err = e as Error;
      return {
        subjectHeader: "IHCC API Reported an Error",
        summaryBodyText: err.message || "No data available",
        type: "Error",
        affectedEntries: [],
        payloadEntries: payload.RefGuids
      } as JemNotification;
    }
  }
}

export interface RescindResponse {
  result: Result[];
  id: number;
  exception: null;
  status: number;
  isCanceled: boolean;
  isCompleted: boolean;
  isCompletedSuccessfully: boolean;
  creationOptions: number;
  asyncState: null;
  isFaulted: boolean;
}

export interface Result {
  id: number;
  poId: number;
  fileName: string;
  typeId: number;
  status: number;
}

export function useIHCCActions<T extends HasRefGuidAndRowVer>(
  configuration: JemConfiguration["IhccApi"]
): ActionEndpointMap<T, ActionResult> {
  const { accessToken } = useContext(UserContext);

  const makeUrl = (endpoint: string) => {
    const base = configuration.baseApiUrl;
    return getValidUrl(`${base}${endpoint}`);
  };

  return {
    [ActionTypes.save]: async () => {
      // TODO: FIND SAVE IN SAP AND IMPLEMENT
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const endpoint = makeUrl(configuration.endpoints.actionSave);
      const result: ActionResult = {
        actionType: ActionTypes.save,
        notification: {
          subjectHeader: "Header",
          summaryBodyText: "Notification Body",
          type: "Success"
        }
      };
      return result;
    },
    [ActionTypes.approve]: async (items, Comments: string) => {
      const endpoint = makeUrl(configuration.endpoints.actionApprove);
      const payload = Object.assign(makeCommonPayload(items), {
        Comments
      });
      const notification = await responseHandler(ActionTypes.approve, endpoint, payload, accessToken);
      return {
        actionType: ActionTypes.approve,
        notification
      };
    },
    [ActionTypes.releaseForSignoff]: async (items, Comments: string) => {
      const endpoint = makeUrl(configuration.endpoints.actionReleaseForSignoff);
      const payload = Object.assign(makeCommonPayload(items), {
        Comments
      });
      const notification = await responseHandler(ActionTypes.releaseForSignoff, endpoint, payload, accessToken);
      return {
        actionType: ActionTypes.releaseForSignoff,
        notification
      };
    },
    [ActionTypes.needsClarification]: async (items, Comments: string) => {
      const endpoint = makeUrl(configuration.endpoints.actionNeedsClarification);
      const payload = Object.assign(makeCommonPayload(items), {
        Comments
      });
      const notification = await responseHandler(ActionTypes.needsClarification, endpoint, payload, accessToken);
      return {
        actionType: ActionTypes.needsClarification,
        notification
      };
    },
    [ActionTypes.addPoster]: async (items, Poster: string, BackupPosters: string[]) => {
      const endpoint = makeUrl(configuration.endpoints.actionAddPoster);
      const payload = Object.assign(makeCommonPayload(items), {
        Poster: BackupPosters.join(";")
      });
      const notification = await responseHandler(ActionTypes.addPoster, endpoint, payload, accessToken);
      return {
        actionType: ActionTypes.addPoster,
        notification
      };
    },
    [ActionTypes.addReviewer]: async (items, Reviewer: string, BackupReviewers: string[]) => {
      const endpoint = makeUrl(configuration.endpoints.actionAddReviewer);
      const payload = Object.assign(makeCommonPayload(items), {
        // typo on Additional is on purpose
        AddtionalApprovers: BackupReviewers.join(";")
      });
      const notification = await responseHandler(ActionTypes.addReviewer, endpoint, payload, accessToken);
      return {
        actionType: ActionTypes.addReviewer,
        notification
      };
    },
    [ActionTypes.recall]: async (items, Comments: string) => {
      const endpoint = makeUrl(configuration.endpoints.actionRecall);
      const payload = Object.assign(makeCommonPayload(items), {
        Comments
      });
      const notification = await responseHandler(ActionTypes.recall, endpoint, payload, accessToken);
      return {
        actionType: ActionTypes.recall,
        notification
      };
    },
    [ActionTypes.rescind]: async (items, comments: string, attachments: IAttachment[]) => {
      const endpoint = makeUrl(configuration.endpoints.actionRescind);
      const payload = {
        comments,
        attachments: [] as any[]
      };
      let idx = 0;
      for (const item of items) {
        for (const file of attachments) {
          payload.attachments.push({
            Attachments: {},
            poId: Number(item.poId),
            id: idx,
            size: file.fileSize,
            status: 1,
            typeId: 1,
            fileName: file.fileName,
            URL: file.url,
            uploadDatetime: file.uploadedOn
          });
        }
        idx = idx + 1;
      }
      const notification = await responseHandler(ActionTypes.rescind, endpoint, payload, accessToken);
      return {
        actionType: ActionTypes.rescind,
        notification
      };
    },
    [ActionTypes.addAttachment]: async (items, attachments: IAttachment[]) => {
      const existingRefGuids = new Set();
      const poIds = items.reduce((thePoIds, item) => {
        // If it has the same BulkRefGuid, then we don't need to add it again
        if (!existingRefGuids.has(item.bulkGuid)) {
          existingRefGuids.add(item.bulkGuid);
          thePoIds.push(item.poId);
        }
        return thePoIds;
      }, [] as string[]);
      const notification = await addAttachmentToPoArray(configuration, accessToken, poIds, attachments);
      const result: ActionResult = {
        actionType: ActionTypes.addAttachment,
        notification: notification
      };
      return result;
    },
    [ActionTypes.deleteAction]: async (items) => {
      const endpoint = makeUrl(configuration.endpoints.actionDelete);
      const RefGuids = items.map((i) => i.refGuid);
      const payload = {
        RefGuids
      };
      const notification = await responseHandler(ActionTypes.deleteAction, endpoint, payload, accessToken);
      return {
        actionType: ActionTypes.deleteAction,
        notification
      };
    }
  };
}
