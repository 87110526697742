import React from "react";
import { useFormik } from "formik";
import { PrimaryButton, TextField, Toggle } from "@fluentui/react";
import { GLCreateOverrides, JEMAliasPicker } from "@jem/components";

export interface JECreateDummyFormProps {
  onSubmit: (values: GLCreateOverrides) => void;
}

const initialState: GLCreateOverrides = {
  jeName: "",
  refNumber: "",
  companyCode: "",
  jeType: "",
  reasonCode: "",
  currency: "",
  postingDate: "",
  translationDate: "",
  reversalDate: "",
  description: "",
  jePurpose: "",
  isLongTerm: false,
  attachments: [],
  poster: "",
  backupPosters: [],
  additionalReviewers: [],
  lineItems: [],
  fiscalYear: 0,
  fiscalMonth: 0,
  preReviewers: []
};

const JECreateDummyForm: React.FC<JECreateDummyFormProps> = (props) => {
  const formik = useFormik({
    initialValues: initialState,
    onSubmit: (values) => {
      props.onSubmit(values);
    }
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="ms-Grid" dir="ltr">
        <div className="ms-Grid-row" style={{ marginBottom: "15px" }}>
          <div className="ms-Grid-col ms-sm6 ms-md3 ms-lg3">
            <TextField label="JE Name" {...formik.getFieldProps("jeName")} />
          </div>
          <div className="ms-Grid-col ms-sm6 ms-md3 ms-lg3">
            <TextField label="Ref Number" {...formik.getFieldProps("refNumber")} />
          </div>
          <div className="ms-Grid-col ms-sm6 ms-md3 ms-lg3">
            <TextField label="Company Code" {...formik.getFieldProps("companyCode")} />
          </div>
          <div className="ms-Grid-col ms-sm6 ms-md3 ms-lg3">
            <TextField label="JE Type" {...formik.getFieldProps("jeType")} />
          </div>
        </div>

        <div className="ms-Grid-row" style={{ marginBottom: "15px" }}>
          <div className="ms-Grid-col ms-sm6 ms-md3 ms-lg3">
            <TextField label="Reason Code" {...formik.getFieldProps("reasonCode")} />
          </div>
          <div className="ms-Grid-col ms-sm6 ms-md3 ms-lg3">
            <TextField label="Currency" {...formik.getFieldProps("currency")} />
          </div>
          <div className="ms-Grid-col ms-sm6 ms-md3 ms-lg3">
            <TextField label="Fiscal Year" {...formik.getFieldProps("fiscalYear")} />
          </div>
          <div className="ms-Grid-col ms-sm6 ms-md3 ms-lg3">
            <TextField label="Fiscal Month" {...formik.getFieldProps("fiscalMonth")} />
          </div>
          <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
            <TextField label="Description" {...formik.getFieldProps("description")} />
          </div>
        </div>

        <div className="ms-Grid-row" style={{ marginBottom: "15px" }}>
          <div className="ms-Grid-col ms-sm6 ms-md3 ms-lg3">
            <TextField label="JE Purpose" {...formik.getFieldProps("jePurpose")} />
          </div>
          <div className="ms-Grid-col ms-sm6 ms-md3 ms-lg3">
            <Toggle
              label="Is Long Term"
              {...formik.getFieldProps("isLongTerm")}
              onChange={(e, checked) => formik.setFieldValue("isLongTerm", checked)}
              checked={formik.values.isLongTerm}
            />
          </div>
          <div className="ms-Grid-col ms-sm6 ms-md3 ms-lg3">
            <TextField label="Poster" {...formik.getFieldProps("poster")} />
          </div>
          <div className="ms-Grid-col ms-sm6 ms-md3 ms-lg3">
            <JEMAliasPicker
              label="Backup Posters:"
              initialAliases={formik.values.backupPosters}
              onChange={(backupPosters) => {
                formik.setFieldValue("backupPosters", backupPosters);
              }}
            />
          </div>
        </div>

        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
            <JEMAliasPicker
              label="Additional Reviewers:"
              initialAliases={formik.values.additionalReviewers}
              onChange={(newAdditionalReviewers) => {
                formik.setFieldValue("additionalReviewers", newAdditionalReviewers);
              }}
            />
          </div>
          <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6" style={{ display: "flex", justifyContent: "flex-end" }}>
            <PrimaryButton type="submit" text="Create" />
          </div>
        </div>
      </div>
    </form>
  );
};

export { JECreateDummyForm };
