import numeral from "numeral";
import { DEFAULT_NULL_CHARACTER } from "../../utilities/ApiParsingUtilities";

function compareFunctionConstructor(
  columnKey: string,
  type: "string" | "number" | "date" | "object",
  isSortedDescending?: boolean
) {
  const isNumberColumn = type === "number";
  const isDateColumn = type === "date";
  const isArrayOrObjectColumn = type === "object";
  function comparingFunction<T>(first: T, second: T) {
    const key = columnKey as keyof T;
    // don't sort if elements are the same
    if (first[key] === second[key]) {
      return 0;
    }

    // nulls sort after anything else if descending
    if (first[key] === null) {
      if (isSortedDescending) {
        return 1;
      } else {
        return -1;
      }
    } else if (second[key] === null) {
      if (isSortedDescending) {
        return -1;
      } else {
        return 1;
      }
    }
    const handleEl = <T,>(x: T): string | number | Date => {
      if (isNumberColumn) {
        if (x === DEFAULT_NULL_CHARACTER) {
          return -Infinity;
        }
        const xIsNumber = numeral(x).value();
        if (xIsNumber === null) {
          return -Infinity;
        }
        return xIsNumber;
      } else if (isDateColumn) {
        if (x === DEFAULT_NULL_CHARACTER) {
          return -Infinity;
        } else {
          const xIsDate = x as Date;
          const retVal = new Date(xIsDate);
          return retVal;
        }
      } else if (isArrayOrObjectColumn) {
        if (x === DEFAULT_NULL_CHARACTER) {
          return -Infinity;
        } else if (Array.isArray(x)) {
          const retVal = x.sort().join(",");
          return retVal;
        } else if (typeof x === "object" && x !== null) {
          const retVal = Object.values(x).sort().join(",");
          return retVal;
        } else {
          return -Infinity;
        }
      } else if (typeof x === "string") {
        return x.toUpperCase();
      } else {
        return -Infinity;
      }
    };
    const a = handleEl(first[key]);
    const b = handleEl(second[key]);
    // otherwise, if we're ascending, lowest sorts first
    if (isSortedDescending) {
      return a < b ? 1 : -1;
    }
    // if descending, highest sorts first
    else {
      return a < b ? -1 : 1;
    }
  }
  return comparingFunction;
}

export function copyAndSort<T>(
  items: T[],
  columnKey: string,
  type: "string" | "number" | "date" | "object",
  isSortedDescending?: boolean
): T[] {
  const sortedItems = items.slice(0).sort(compareFunctionConstructor(columnKey, type, isSortedDescending));
  return sortedItems;
}
