import { BaseRouteProps, IHCCProvider } from "@jem/components";
import React from "react";
import { Route, Routes } from "react-router-dom";

import { IHCCDraftProvider } from "./PaymentOrders/contexts/IHCCDraftContext/IHCCDraftContext";
import { IHCCPODetailsProvider } from "./PaymentOrders/contexts/IHCCPODetailsContext/IHCCPODetailsContext";
import IHCCCreate from "./PaymentOrders/pages/IHCCCreate/IHCCCreate";
import IHCCDashboard from "./PaymentOrders/pages/IHCCDashboard/IHCCDashboard";
import IHCCPODetails from "./PaymentOrders/pages/IHCCPODetails/IHCCPODetails";
import IHCCSearch from "./PaymentOrders/pages/IHCCSearch/IHCCSearch";
import TreasuryDashboard from "./PaymentOrders/pages/TreasuryDashboard/TreasuryDashboard";

const PaymentOrders: React.FC<BaseRouteProps> = (props) => {
  return (
    <>
      <IHCCProvider
        configuration={props.config}
        //mockDashboardConfigFn={mockIhccConfiguration}
      >
        <Routes>
          <Route
            path="/create"
            element={
              <IHCCDraftProvider configuration={props.config}>
                <IHCCCreate configuration={props.config.IhccApi}></IHCCCreate>
              </IHCCDraftProvider>
            }
          />

          <Route
            path="/dashboard"
            element={
              <IHCCDashboard
                configuration={props.config.IhccApi}
                // mockDashboardDataFn={mockIhccRequestorDuplicateBug}
              ></IHCCDashboard>
            }
          />
          <Route
            path="/podetails/:poId"
            element={
              <IHCCPODetailsProvider
                configuration={props.config}
                //mockDashboardDataFn={mockIhccDashboardPOData}
              >
                <IHCCPODetails
                  configuration={props.config.IhccApi}
                  //mockDashboardDataFn={mockIhccDashboardPOData}
                ></IHCCPODetails>
              </IHCCPODetailsProvider>
            }
          />
          <Route path="/search" element={<IHCCSearch configuration={props.config.IhccApi}></IHCCSearch>} />
          <Route
            path="/treasuryDashboard"
            element={<TreasuryDashboard configuration={props.config.IhccApi}></TreasuryDashboard>}
          />
        </Routes>
      </IHCCProvider>
    </>
  );
};

PaymentOrders.displayName = "PaymentOrders";
export default PaymentOrders;
