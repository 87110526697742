import {
  FatalError,
  FromReducer,
  ihccLoaderFromReducer,
  JemConfiguration,
  LoadingStatus,
  LocalAttachment,
  LoggingContext,
  MockDataFn,
  RequireAtLeastOne,
  UserContext
} from "@jem/components";
import React, { useContext, useEffect, useReducer, useState } from "react";
import { useParams } from "react-router-dom";

import { PODetailsActionsEnum } from "../../components/IHCCPODetails/IHCCPODetails.ActionsBar";
import { createFetcherFunctions } from "./IHCCPODetailsContext.requests";
import {
  ActionsModel,
  BulkDetailsModel,
  DetailsPageModel,
  EMPTY_DETAILS_MODEL,
  SapDetailsModel
} from "./IHCCPODetailsContext.types";

export enum DetailsTabNames {
  poDetails = "poDetails",
  attachments = "attachments",
  review = "review",
  otherDetails = "otherDetails"
}

export interface UserChanges {
  comments: string;
  purpose: string;
  localAttachments: LocalAttachment[];
  additionalReviewers: string;
  commentsError?: string;
}
const initialUserChanges: UserChanges = {
  comments: "",
  purpose: "",
  localAttachments: [],
  additionalReviewers: "",
  commentsError: undefined
};

export type UserChangesReducer = (state: UserChanges, changes: RequireAtLeastOne<UserChanges>) => UserChanges;

export interface DetailsError {
  tabName: DetailsTabNames;
  error: string;
}
export type ErrorMessageHandler = (actionName: PODetailsActionsEnum) => DetailsError | null;
export interface IHCCPODetailsContextObject {
  model: {
    status: LoadingStatus;
    values: DetailsPageModel;
  };
  sapPoDetails: {
    status: LoadingStatus;
    values: SapDetailsModel | null;
  };
  bulkDetails: {
    status: LoadingStatus;
    values: BulkDetailsModel;
  };
  actionDetails: {
    status: LoadingStatus;
    values: ActionsModel;
  };
  userChanges: UserChanges;
  getReviewers: () => string | null;
  getComments: () => string;
  getErrorMessage: ErrorMessageHandler;
  setUserChanges: (changes: RequireAtLeastOne<UserChanges, keyof UserChanges>) => void;
  reloadDetails: () => Promise<void>;
}

const baseContext: IHCCPODetailsContextObject = {
  model: {
    values: EMPTY_DETAILS_MODEL,
    status: LoadingStatus.Idle
  },
  sapPoDetails: {
    values: null,
    status: LoadingStatus.Idle
  },
  bulkDetails: {
    values: [],
    status: LoadingStatus.Idle
  },
  actionDetails: {
    values: [],
    status: LoadingStatus.Idle
  },
  getReviewers: () => null,
  getErrorMessage: () => null,
  userChanges: initialUserChanges,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setUserChanges: () => {},
  getComments: () => "",
  reloadDetails: () => Promise.resolve()
};

const IHCCPODetailsContext = React.createContext<IHCCPODetailsContextObject>(baseContext);

export interface IHCCPODetailsContextProps {
  mockDataFn?: MockDataFn<IHCCPODetailsContextObject>;
  configuration: JemConfiguration;
  children: React.ReactNode;
}

interface PODetailsRouteParams {
  poId: string;
}

type DetailsModelState = FromReducer<DetailsPageModel>;
type SapPoDetailsModelState = FromReducer<SapDetailsModel | null>;
type ActionsModelState = FromReducer<ActionsModel>;
type BulkDetailsModelState = FromReducer<BulkDetailsModel>;

export type DetailsModelReducer = (
  state: DetailsModelState,
  changes: RequireAtLeastOne<DetailsModelState>
) => DetailsModelState;

export type SapPoDetailsModelReducer = (
  state: SapPoDetailsModelState,
  changes: RequireAtLeastOne<SapPoDetailsModelState>
) => SapPoDetailsModelState;

export type ActionsModelReducer = (
  state: ActionsModelState,
  changes: RequireAtLeastOne<ActionsModelState>
) => ActionsModelState;

export type BulkDetailsModelReducer = (
  state: BulkDetailsModelState,
  changes: RequireAtLeastOne<BulkDetailsModelState>
) => BulkDetailsModelState;

function commonReducer<T>(previousState: T, changes: RequireAtLeastOne<T, keyof T>): T {
  return {
    ...previousState,
    ...changes
  };
}

// load PO details
// if empty return empty state
// else transform response into state
const IHCCPODetailsProvider = (props: IHCCPODetailsContextProps) => {
  const { appInsights } = useContext(LoggingContext);
  if (!appInsights) {
    throw new FatalError("Please use a LoggingContext Provider.");
  }
  const userContext = useContext(UserContext);
  if (!userContext) {
    throw new FatalError("Please use a UserContext Provider.");
  }

  const { poId } = useParams<keyof PODetailsRouteParams>() as PODetailsRouteParams;
  const [localUserChanges, setUserChanges] = useReducer<UserChangesReducer>(commonReducer, initialUserChanges);
  const [, setLocalChanges] = useState<UserChanges>(initialUserChanges);

  const [detailsState, setDetailsState] = useReducer<DetailsModelReducer>(commonReducer, {
    values: EMPTY_DETAILS_MODEL,
    status: LoadingStatus.Idle
  });
  const [sapPoDetailsState, setSapPoDetailsState] = useReducer<SapPoDetailsModelReducer>(commonReducer, {
    values: null,
    status: LoadingStatus.Idle
  });
  const [actionsState, setActionsState] = useReducer<ActionsModelReducer>(commonReducer, {
    values: [],
    status: LoadingStatus.Idle
  });
  const [bulkDetailsState, setBulkDetailsState] = useReducer<BulkDetailsModelReducer>(commonReducer, {
    values: [],
    status: LoadingStatus.Idle
  });

  const fetcherFunctions = createFetcherFunctions(poId);

  useEffect(() => {
    if (props.mockDataFn) {
      const mockFn = props.mockDataFn as MockDataFn<IHCCPODetailsContextObject>;
      loadMocks(setDetailsState, setSapPoDetailsState, setActionsState, setBulkDetailsState, mockFn);
    } else {
      const loadPoDetails = async () => {
        const details = await ihccLoaderFromReducer<DetailsPageModel>(
          userContext.accessToken,
          appInsights,
          props.configuration,
          fetcherFunctions.fetchModel,
          setDetailsState
        );
        ihccLoaderFromReducer<SapDetailsModel | null>(
          userContext.accessToken,
          appInsights,
          props.configuration,
          fetcherFunctions.fetchSapPoDetails,
          setSapPoDetailsState
        );
        ihccLoaderFromReducer<ActionsModel>(
          userContext.accessToken,
          appInsights,
          props.configuration,
          fetcherFunctions.fetchActionDetails,
          setActionsState
        );
        if (details && details.bulkRefGuid) {
          ihccLoaderFromReducer<BulkDetailsModel>(
            userContext.accessToken,
            appInsights,
            props.configuration,
            fetcherFunctions.fetchBulkDetails,
            setBulkDetailsState
          );
        }
      };
      loadPoDetails();
    }
  }, [poId]);

  useEffect(() => {
    if (detailsState.status === LoadingStatus.Resolved) {
      setUserChanges({
        localAttachments: [],
        comments: detailsState.values.reviewTab.comments,
        purpose: detailsState.values.detailsTab.poPurpose,
        additionalReviewers: detailsState.values.reviewTab.additionalReviewers,
        commentsError: undefined
      });
      setLocalChanges({
        localAttachments: [],
        comments: detailsState.values.reviewTab.comments,
        purpose: detailsState.values.detailsTab.poPurpose,
        additionalReviewers: detailsState.values.reviewTab.additionalReviewers,
        commentsError: undefined
      });
    }
  }, [detailsState]);

  const reloadDetails = async () => {
    if (props.mockDataFn) {
      const mockFn = props.mockDataFn as MockDataFn<IHCCPODetailsContextObject>;
      loadMocks(setDetailsState, setSapPoDetailsState, setActionsState, setBulkDetailsState, mockFn);
    } else {
      ihccLoaderFromReducer<DetailsPageModel>(
        userContext.accessToken,
        appInsights,
        props.configuration,
        fetcherFunctions.fetchModel,
        setDetailsState
      );
    }
  };

  useEffect(() => {
    setLocalChanges({ ...localUserChanges });
  }, [localUserChanges]);

  // purpose can't be empty ever
  // if not save or signoff comments must not be empty
  // send empty comment for signoff (not for save)
  const getErrorMessage: ErrorMessageHandler = (actionName: PODetailsActionsEnum) => {
    let latestChanges = localUserChanges;
    setLocalChanges((pChanges) => {
      latestChanges = pChanges;
      return pChanges;
    });
    if (latestChanges.purpose === "") {
      return {
        tabName: DetailsTabNames.poDetails,
        error: "Purpose cannot be empty"
      };
    }
    if (
      actionName !== PODetailsActionsEnum.Save &&
      actionName !== PODetailsActionsEnum.SignOff &&
      actionName !== PODetailsActionsEnum.Reverse
    ) {
      if (latestChanges.comments === "") {
        setUserChanges({
          commentsError: `Comments cannot be empty for Action: ${actionName}`
        });
        return {
          tabName: DetailsTabNames.review,
          error: "Comments cannot be empty"
        };
      }
    }
    return null;
  };

  const getReviewers = (): string | null => {
    let latestChanges = localUserChanges;
    setLocalChanges((pChanges) => {
      latestChanges = pChanges;
      return pChanges;
    });
    if (latestChanges.additionalReviewers !== detailsState.values.reviewTab.additionalReviewers) {
      return latestChanges.additionalReviewers;
    }
    return null;
  };

  const setUserChangesHandler = (changes: RequireAtLeastOne<UserChanges>) => {
    setUserChanges(changes);
  };
  const getComments = () => {
    let latestChanges = localUserChanges;
    setLocalChanges((pChanges) => {
      latestChanges = pChanges;
      return pChanges;
    });
    return latestChanges.comments;
  };

  return (
    <IHCCPODetailsContext.Provider
      value={{
        model: detailsState,
        sapPoDetails: sapPoDetailsState,
        bulkDetails: bulkDetailsState,
        actionDetails: actionsState,
        userChanges: localUserChanges,
        getErrorMessage: getErrorMessage,
        getReviewers: getReviewers,
        getComments,
        setUserChanges: setUserChangesHandler,
        reloadDetails
      }}
    >
      {props.children}
    </IHCCPODetailsContext.Provider>
  );
};

async function loadMocks(
  setDetailsState: React.Dispatch<RequireAtLeastOne<DetailsModelState, keyof DetailsModelState>>,
  setSapPoDetailsState: React.Dispatch<RequireAtLeastOne<SapPoDetailsModelState, keyof SapPoDetailsModelState>>,
  setActionsState: React.Dispatch<RequireAtLeastOne<ActionsModelState, keyof ActionsModelState>>,
  setBulkDetailsState: React.Dispatch<RequireAtLeastOne<BulkDetailsModelState, keyof BulkDetailsModelState>>,
  mockFn: MockDataFn<any>
) {
  setDetailsState({ status: LoadingStatus.Pending });
  setSapPoDetailsState({ status: LoadingStatus.Pending });
  setActionsState({ status: LoadingStatus.Pending });
  setBulkDetailsState({ status: LoadingStatus.Pending });
  const mockData = await mockFn({});
  setDetailsState({
    status: LoadingStatus.Resolved,
    values: mockData.model.values
  });
  setSapPoDetailsState({
    status: LoadingStatus.Resolved,
    values: mockData.sapDetails.values
  });
  setActionsState({
    status: LoadingStatus.Resolved,
    values: mockData.actionDetails.values
  });
  setBulkDetailsState({
    status: LoadingStatus.Resolved,
    values: mockData.bulkDetails.values
  });
}

export { IHCCPODetailsContext, IHCCPODetailsProvider };
