import { IDropdownStyles } from "@fluentui/react";

export interface MaxWidthHeightOptions {
  width: string;
  height: string;
  minWidth?: string;
  maxWidth?: string;
}

export const ForceMaxWidthAndHeightOnDropdownStyle = (
  { width, height, minWidth, maxWidth }: MaxWidthHeightOptions = { width: "200px", height: "200px" }
): Partial<IDropdownStyles> => ({
  root: {
    width,
    minWidth,
    maxWidth
  },
  callout: {
    maxHeight: "none !important",
    selectors: {
      ".ms-Callout-main": {
        maxHeight: "none !important",
        height
      }
    }
  }
});
