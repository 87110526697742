import { FiscalPeriodStringTransformations } from "../../GeneralLedger/utilities/FiscalPeriodStringTransformations";

interface Item {
  jeType: string;
  docNo: string;
  publishedState: string;
  href: string;
  refGuid: string;
  postedBy: string;
  assignedReviewer: string[];
  postingPeriod: string;
  opsDetailId: number;
  jeTaskName: string;
}

export function GetLinkToDetailsFromChecklistItem<T extends Item>(
  item: T
): {
  stringContent: string;
  url: string | null;
} | null {
  if (!item) return null;
  if (item.jeType.toLowerCase().indexOf("draft") !== -1 && !item.docNo) return null;
  if (!(item.publishedState.toLowerCase() === "published"))
    return {
      stringContent: item.docNo,
      url: null
    };

  let pathname = "";
  let url: string | null = null;
  let stringContent: string = item.docNo;
  if (item.href && item.href !== "") {
    const div = document.createElement("div");
    // replace #// with #/ to make it a valid url
    item.href = item.href.replace("#//", "#/");
    div.innerHTML = item.href;
    const a = div.querySelector("a");
    if (a) {
      const href = a.href;
      const jemHyperLinkUrl = new URL(href);
      const hash = jemHyperLinkUrl.hash;
      const jeIdParam = "jeId=";

      let jeId: string | null = null;
      if (hash && hash.indexOf(jeIdParam) !== -1) {
        const startIndex = hash.indexOf(jeIdParam) + jeIdParam.length;
        const endIndex = hash.indexOf("&", startIndex) !== -1 ? hash.indexOf("&", startIndex) : hash.length;
        jeId = hash.substring(startIndex, endIndex);
        if (jeId) {
          pathname = "/gl/details";
          url = `?jeid=${jeId}`;
        }
      }
      let batchRefGuid: string | null = null;
      const batchParam = "batchnumber=";
      if (hash && hash.indexOf(batchParam) !== -1) {
        const startIndex = hash.indexOf(batchParam) + batchParam.length;
        const endIndex = hash.indexOf("&", startIndex) !== -1 ? hash.indexOf("&", startIndex) : hash.length;
        batchRefGuid = hash.substring(startIndex, endIndex);
        if (batchRefGuid) {
          const urlToBatch = new URLSearchParams();
          pathname = "/gl/search/batch";
          if (item.postedBy) {
            urlToBatch.set("AliasType", "P");
            urlToBatch.set("Alias", item.postedBy.toString());
          } else {
            urlToBatch.set("AliasType", "R");
            urlToBatch.set("Alias", item.assignedReviewer.toString());
          }
          const fyfp = FiscalPeriodStringTransformations.FiscalPeriodStringToFiscalYearAndFiscalMonth(
            item.postingPeriod
          );
          urlToBatch.set("FiscalYear", fyfp.fiscalYear.toString());
          urlToBatch.set("FiscalMonth", fyfp.fiscalMonth.toString());
          urlToBatch.set("OpsDetailId", item.opsDetailId.toString());
          urlToBatch.set("BatchName", item.jeTaskName.toString());
          urlToBatch.set("cBatchName", item.jeTaskName.toString());
          urlToBatch.set("cBatchRefGuid", item.refGuid);
          stringContent = "Link to Batch";
          url = `?${urlToBatch.toString()}`;
        }
      }
    }
  }

  if (url === null) {
    if (item.jeType.indexOf("Batch") !== -1 || item.jeType.indexOf("Bulk") !== -1) {
      pathname = "/gl/batch";
      url = `?batchRefGuid=${item.refGuid}`;
    } else {
      pathname = "/gl/details";
      url = `?jeid=${item.refGuid}`;
    }
  }

  return {
    stringContent,
    url: `${pathname}${url}`
  };
}
