import React, { PropsWithChildren, useContext } from "react";
import { ActionResult, ActionTypes } from "../ActionManager/ActionsManager.types";
import { ActionsContext } from "./Actions.ActionsContext";
import { ActionTitle } from "./Actions.ActionTitle";
import { TextFieldCheckboxSubmission } from "./Actions.BottomButtons";
import { ActionOnSubmitHandlerMap, BaseActionProps, CommonPropsOnRows } from "./Actions.types";

interface ApproveProps<ListType, ResultType> extends BaseActionProps<ListType, ResultType> {
  onSubmitHandler:
    | ActionOnSubmitHandlerMap<ListType, ResultType>[ActionTypes.approve]
    | ActionOnSubmitHandlerMap<ListType, ResultType>[ActionTypes.approvePreReview];
}

const Approve = <ListType extends CommonPropsOnRows, ResultType extends ActionResult>(
  props: PropsWithChildren<ApproveProps<ListType, ResultType>>
) => {
  const { styles } = useContext(ActionsContext);
  return (
    <>
      <ActionTitle onCloseButton={() => props.onCancel()} name={"Approve"}></ActionTitle>
      <div className={styles.contentStyles.body}>
        <TextFieldCheckboxSubmission
          onSubmit={async function (type: "submit" | "cancel", comment: string): Promise<void> {
            if (type === "cancel") {
              props.onCancel();
            } else {
              const result = await props.onSubmitHandler(props.items, comment);
              props.onSubmit(result);
            }
          }}
          submitButtonText={"Approve"}
          textFieldLabel={"Comments"}
          checkboxText={
            "I am accepting the responsibility for these entries and that they are in compliance with MAP policy."
          }
        ></TextFieldCheckboxSubmission>
      </div>
    </>
  );
};

Approve.displayName = "Approve";
export { Approve };
