import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { getCalendarInfo, ICloseCalendarInfo, ICombinedCalendarInfo, IFiscalPeriod } from "./Calendars.requests";
import { FiscalPeriodModel, getCurrentFyFp, IUserProviderState, JemConfiguration, useQuery } from "@jem/components";
import { css } from "@emotion/css";
import { CalendarLegend } from "./CalendarLegend";
import { Stack } from "@fluentui/react";

interface CalendarsDropdownProps {
  config: JemConfiguration["GeneralLedgerApi"];
  getTokenFn: IUserProviderState["accessToken"];
}

const labelStyles = css`
  margin: 0px;
  font-size: 1.5em;
  font-weight: 100;
  color: rgb(0, 0, 0);
  padding-top: 3px;
  padding-left: 20px;
`;

export function formatDate(dateString: string) {
  const parts = dateString.split("/");
  const month = parseInt(parts[0], 10);
  const day = parseInt(parts[1], 10);
  const year = parseInt(parts[2], 10);

  const formattedDate = new Date(year, month - 1, day).toISOString();
  return formattedDate;
}
export function getCalendarColors(colorfromDB: string) {
  switch (colorfromDB) {
    case "PnL":
      return "#BA141A";
    case "Sub-Trans":
      return "#002050";
    case "Fin-Ship":
      return "#9B4F96";
    case "Earn-Release":
      return "#FF8C00";
    case "QE-SgJE-KeyAcRecon":
      return "#0072C6";
    case "QE-RgJE-NnKeyAcRecon":
      return "#007233";
    case "USHol":
      return "#505050";
    case "QE-LW-KeyAcRecon":
      return "#7FBA00";
    case "IM-LW-KeyAcRecon":
      return "#7FBA00";
    case "IM-RgLWJE-KeyAcRecon":
      return "#007233";
    case "IM-SgJE-KeyAcRecon":
      return "#0072C6";
    case "DeadlineToApprove":
      return "#cc0000";
    case "IhccClosed":
      return "#777";
    case "Deadline-UsHoliday":
      return "#cc6600";
    case "Business go live":
      return "#33ccff";
    case "US-Holiday":
      return "#ffdb4d";
    case "TreasuryToSAP":
      return "#339933";
    default:
      return "";
  }
}
export function getEventsForCalendar(calendar: ICloseCalendarInfo): {
  date: string;
  backgroundColor: string;
  borderColor: string;
  allDay: boolean;
}[] {
  const events: {
    date: string;
    backgroundColor: string;
    borderColor: string;
    allDay: boolean;
  }[] = [];
  calendar.calendarInfo.forEach((x: { eventDate: string; class: string }) =>
    events.push({
      date: formatDate(x.eventDate),
      backgroundColor: getCalendarColors(x.class),
      borderColor: getCalendarColors(x.class),
      allDay: true
    })
  );

  return events;
}
export function getCumulativeMonthIfo(
  calendarMonth: string,
  calendarYear: string,
  fiscalPeriodInfo: IFiscalPeriod[]
): { period: string; cumulativeMonth: string } {
  const cumulativeMonthIfo = fiscalPeriodInfo.find((x) => {
    if (x.year === calendarYear && x.month === calendarMonth) {
      return true;
    }
    return false;
  });
  const period: string = cumulativeMonthIfo ? cumulativeMonthIfo.period : "";
  const cumulativeMonth: string = cumulativeMonthIfo ? cumulativeMonthIfo.cummulativeMonth : "";

  return {
    period: period,
    cumulativeMonth: cumulativeMonth
  };
}

const Calendars: React.FC<CalendarsDropdownProps> = (props) => {
  const { data } = useQuery({
    queryKey: ["closeCalendars"],
    queryFn: () => getCalendarInfo(props.config, props.getTokenFn),
    staleTime: 0
  });
  const closeCalendarInfo: ICombinedCalendarInfo = data || {
    regularCalendarInfo: {
      calendarInfo: [],
      fiscalPeriod: []
    },
    ihccCalendarInfo: {
      calendarInfo: [],
      fiscalPeriod: []
    }
  };
  const today = new Date();
  const currentFiscalInfo: FiscalPeriodModel = getCurrentFyFp(today);
  const [currentMonthStart, setCurrentMonthStart] = useState<Date>(new Date(today.getFullYear(), today.getMonth(), 1));
  const [currentMonthEnd, setCurrentMonthEnd] = useState<Date>(new Date(today.getFullYear(), today.getMonth() + 1, 0));
  const [currentIhccMonthStart, setIhccCurrentMonthStart] = useState<Date>(
    new Date(today.getFullYear(), today.getMonth(), 1)
  );
  const [currentIhccMonthEnd, setIhccCurrentMonthEnd] = useState<Date>(
    new Date(today.getFullYear(), today.getMonth() + 1, 0)
  );
  const [cumulativeMonthIfo, setCumulativeMonthIfo] = useState<{ period: string; cumulativeMonth: string }>(
    getCumulativeMonthIfo(
      currentFiscalInfo.calendarMonth.toString(),
      currentFiscalInfo.calendarYear.toString(),
      closeCalendarInfo.regularCalendarInfo.fiscalPeriod
    )
  );
  const [ihccCumulativeMonthIfo, setIhccCumulativeMonthIfo] = useState<{ period: string; cumulativeMonth: string }>(
    getCumulativeMonthIfo(
      currentFiscalInfo.calendarMonth.toString(),
      currentFiscalInfo.calendarYear.toString(),
      closeCalendarInfo.ihccCalendarInfo.fiscalPeriod
    )
  );

  useEffect(() => {
    setCumulativeMonthIfo(
      getCumulativeMonthIfo(
        currentFiscalInfo.calendarMonth.toString(),
        currentFiscalInfo.calendarYear.toString(),
        closeCalendarInfo.regularCalendarInfo.fiscalPeriod
      )
    );
    setIhccCumulativeMonthIfo(
      getCumulativeMonthIfo(
        currentFiscalInfo.calendarMonth.toString(),
        currentFiscalInfo.calendarYear.toString(),
        closeCalendarInfo.regularCalendarInfo.fiscalPeriod
      )
    );
  }, [data]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleDatesSet = (arg: any) => {
    const currentMonthStart = new Date(arg.view.currentStart.getFullYear(), arg.view.currentStart.getMonth(), 1);
    const currentMonthEnd = new Date(arg.view.currentEnd.getFullYear(), arg.view.currentEnd.getMonth(), 0);
    setCurrentMonthStart(currentMonthStart);
    setCurrentMonthEnd(currentMonthEnd);

    const cumulativeMonthIfo = getCumulativeMonthIfo(
      (currentMonthStart.getMonth() + 1).toString(),
      currentMonthStart.getFullYear().toString(),
      closeCalendarInfo.regularCalendarInfo.fiscalPeriod
    );
    setCumulativeMonthIfo(cumulativeMonthIfo);
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleIhccDatesSet = (arg: any) => {
    const currentMonthStart = new Date(arg.view.currentStart.getFullYear(), arg.view.currentStart.getMonth(), 1);
    const currentMonthEnd = new Date(arg.view.currentEnd.getFullYear(), arg.view.currentEnd.getMonth(), 0);
    setIhccCurrentMonthStart(currentMonthStart);
    setIhccCurrentMonthEnd(currentMonthEnd);

    setIhccCumulativeMonthIfo(
      getCumulativeMonthIfo(
        (currentMonthStart.getMonth() + 1).toString(),
        arg.view.currentStart.getFullYear().toString(),
        closeCalendarInfo.ihccCalendarInfo.fiscalPeriod
      )
    );
  };

  return (
    <>
      <div className="ms-Grid" dir="ltr">
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl6">
            <h2 className={labelStyles}>Close Calendar</h2>
            <div>
              <FullCalendar
                headerToolbar={{
                  end: "prev,next"
                }}
                height="auto"
                contentHeight={"auto"}
                datesSet={handleDatesSet}
                plugins={[dayGridPlugin]}
                events={getEventsForCalendar(closeCalendarInfo.regularCalendarInfo)}
              />
            </div>
            {cumulativeMonthIfo ? (
              <div
                style={{
                  color: "#cd2026",
                  fontSize: "14px"
                }}
              >
                PERIOD {cumulativeMonthIfo.period} CUMULATIVE MONTH {cumulativeMonthIfo.cumulativeMonth}
              </div>
            ) : (
              <></>
            )}
            <Stack horizontalAlign="start">
              <CalendarLegend
                calendar="close"
                monthStart={currentMonthStart}
                monthEnd={currentMonthEnd}
                calendarInfo={closeCalendarInfo.regularCalendarInfo}
              />
            </Stack>
          </div>
          <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl6">
            <h2 className={labelStyles}>IHCC Calendar</h2>
            <div>
              <FullCalendar
                headerToolbar={{
                  end: "prev,next"
                }}
                height="auto"
                contentHeight={"auto"}
                datesSet={handleIhccDatesSet}
                plugins={[dayGridPlugin]}
                events={getEventsForCalendar(closeCalendarInfo.ihccCalendarInfo)}
              />
            </div>
            {ihccCumulativeMonthIfo ? (
              <div
                style={{
                  color: "#cd2026",
                  fontSize: "14px"
                }}
              >
                PERIOD {ihccCumulativeMonthIfo.period} CUMULATIVE MONTH {ihccCumulativeMonthIfo.cumulativeMonth}
              </div>
            ) : (
              <></>
            )}
            <Stack horizontalAlign="start">
              <CalendarLegend
                calendar="ihcc"
                monthStart={currentIhccMonthStart}
                monthEnd={currentIhccMonthEnd}
                calendarInfo={closeCalendarInfo.ihccCalendarInfo}
              />
            </Stack>
          </div>
        </div>
      </div>
    </>
  );
};

Calendars.displayName = "Calendars";
export { Calendars };
