import { AuthenticationState, IUserProviderState } from "../../utilities/RequestUtilities";
import { LoadingStatus } from "../../utilities/Utilities";
import { BlankAccessMessage } from "./UserContext.roles.main";

export function getMockUserContextObj(): IUserProviderState {
  return {
    user: {
      name: "No User",
      userName: "NA",
      accountIdentifier: "NA",
      alias: "NA"
    },
    jemUser: {
      supervisor: "NA",
      roles: [],
      euaPortalUrl: "NA",
      accessMessageComponent: BlankAccessMessage,
      hasSapAccess: false,
      hasIhccAccess: false,
      hasSapReversalAccess: false,
      hasF05Access: false,
      hasMsSalesAccess: false,
      currencyFormat: "",
      dateFormat: "",
      delegatedManager: "",
      tenants: []
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    login: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    logout: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    refreshRoles: (flag: boolean) => {},
    accessToken: () => Promise.resolve({ accessToken: "" } as any),
    status: AuthenticationState.Authenticated,
    jemRolesStatus: LoadingStatus.Resolved
  };
}
