import React, { createContext, useContext, useEffect, useState } from "react";
import { JemConfiguration } from "../../../JemConfiguration";
import { IHCCDashboardConfiguration } from "./IHCCContext.types";
import { LoggingContext } from "../../../Shared/contexts/LoggingContext/LoggingContext";
import { UserContext } from "../../../Shared/contexts/UserContext/UserContext";
import { getInitInfo, getUiParams } from "./IHCCContext.requests";
import { ihccLoader } from "./IHCCContext.loader";
import { LoadingStatus } from "../../../Shared/utilities/Utilities";
import { MockDataFn } from "../../../Shared/utilities/RequestUtilities";
import { FatalError } from "../../../Shared/utilities/ErrorHelpers";

export interface IHCCContextData {
  initInfo: {
    status: LoadingStatus;
    values: IHCCDashboardConfiguration["Init"] | null;
  };
  uiParameters: {
    status: LoadingStatus;
    values: IHCCDashboardConfiguration["UIParameters"];
  };
}

const initialState: IHCCContextData = {
  initInfo: {
    status: LoadingStatus.Idle,
    values: null
  },
  uiParameters: {
    status: LoadingStatus.Idle,
    values: []
  }
};

const IHCCContext = createContext(initialState);

export interface IhccProviderProps {
  children: React.ReactNode;
  configuration: JemConfiguration;
  mockDashboardConfigFn?: MockDataFn<IHCCDashboardConfiguration>;
}

const IHCCProvider: React.FC<IhccProviderProps> = (props) => {
  const { appInsights } = useContext(LoggingContext);
  if (!appInsights) {
    throw new FatalError("Please use a LoggingContext Provider.");
  }
  const userContext = useContext(UserContext);
  if (!userContext) {
    throw new FatalError("Please use a UserContext Provider.");
  }

  const [initInfoStatus, setInitInfoStatus] = useState<LoadingStatus>(LoadingStatus.Idle);
  const [initInfo, setInitInfo] = useState<IHCCDashboardConfiguration["Init"] | null>(null);

  const [uiParametersStatus, setUiParametersStatus] = useState<LoadingStatus>(LoadingStatus.Idle);
  const [uiParameters, setUiParameters] = useState<IHCCDashboardConfiguration["UIParameters"]>([]);

  useEffect(() => {
    if (props.mockDashboardConfigFn) {
      const mockFn = props.mockDashboardConfigFn as MockDataFn<IHCCDashboardConfiguration>;
      const loadMocks = async () => {
        setInitInfoStatus(LoadingStatus.Pending);
        setUiParametersStatus(LoadingStatus.Pending);
        const mockData = await mockFn({});
        setInitInfo(mockData.Init);
        setUiParameters(mockData.UIParameters);
        setInitInfoStatus(LoadingStatus.Resolved);
        setUiParametersStatus(LoadingStatus.Resolved);
      };
      loadMocks();
    } else {
      ihccLoader<IHCCDashboardConfiguration["Init"] | null>(
        userContext.accessToken,
        appInsights,
        props.configuration,
        getInitInfo,
        setInitInfoStatus,
        setInitInfo
      );
      ihccLoader<IHCCDashboardConfiguration["UIParameters"]>(
        userContext.accessToken,
        appInsights,
        props.configuration,
        getUiParams,
        setUiParametersStatus,
        setUiParameters
      );
    }
  }, []);

  return (
    <IHCCContext.Provider
      value={{
        initInfo: {
          status: initInfoStatus,
          values: initInfo
        },
        uiParameters: {
          status: uiParametersStatus,
          values: uiParameters
        }
      }}
    >
      {props.children}
    </IHCCContext.Provider>
  );
};

IHCCProvider.displayName = "IHCCProvider";

export { IHCCProvider, IHCCContext };
