import { CommentsModel, IAttachment } from "@jem/components";
import {
  BulkDetailsFromApi,
  DetailsPageActionFromApi,
  PaymentOrderObjectFromApi,
  SapPoDetailsFromApi
} from "../../shared/IHCC.types";

export type DetailsPageBulkResponse = BulkDetailsFromApi[];
export type DetailsPageActionsResponse = DetailsPageActionFromApi[];
export type DetailsPagePaymentOrderResponse = PaymentOrderObjectFromApi;
export type DetailsPageSAPDetailsResponse = SapPoDetailsFromApi;

export interface ReviewersModel {
  primaryReviewer: string;
  additionalReviewers: string;
  comments: string;
  commentsGrid: CommentsModel[];
}

export interface DetailsModel {
  poNumber: string;
  poType: string;
  poster: string;
  fiscalYearAndPeriod: string;
  poPurpose: string;
  reversalDate: string;
  bankArea: string;
  reviewers: string;
  postDate: string;
  access: number;
}

export interface DetailsPageModel {
  detailsTab: DetailsModel;
  attachmentsTab: IAttachment[];
  reviewTab: ReviewersModel;
  bulkRefGuid: string | null;
  weightage: number;
  status: number;
  rowVer: string | null;
  refGuid: string;
  poId: string;
}

export const EMPTY_DETAILS_MODEL: DetailsPageModel = {
  detailsTab: {
    poNumber: "",
    poType: "",
    poster: "",
    fiscalYearAndPeriod: "",
    poPurpose: "",
    reversalDate: "",
    bankArea: "",
    reviewers: "",
    postDate: "",
    access: 0
  },
  bulkRefGuid: null,
  attachmentsTab: [],
  reviewTab: {
    primaryReviewer: "",
    additionalReviewers: "",
    comments: "",
    commentsGrid: []
  },
  status: 0,
  weightage: 0,
  rowVer: null,
  refGuid: "",
  poId: ""
};
export interface BulkDetailsItem {
  poNumber: string;
  companyCode: string;
  fiscalYear: string;
  fiscalPeriod: string;
}
export type BulkDetailsModel = BulkDetailsItem[];

export type ActionsModel = {
  action: string;
  actionLinkId: number;
  description: string;
  id: number;
  weightage: number;
}[];

export interface SapDetailsModel {
  poId: number;
  opAccount: string;
  rpAccount: string;
  transactionType: string;
  currency: string;
  opCurrency: number | string;
  rpCurrency: number | string;
  opCurrencyType: string;
  rpCurrencyType: string;
}
