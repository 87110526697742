import { Dropdown, IDropdownOption } from "@fluentui/react";
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
//import { MaxWidthHeightOptions } from "../../utilities/FluentUIHacks";

export interface IJETypeRef {
  getJEType: () => IDropdownOption<string> | null;
  setInitialJEType: (initialJEType: string) => void;
  reset: () => void;
}

interface IJETypeProps {
  loading: boolean;
  onChange?: (JEType: IDropdownOption<string>) => void;
  //styles?: MaxWidthHeightOptions;
  JEType: string;
}

const JETypeDropdownOptions = [
  { key: 0, text: "Draft JE" },
  { key: 1, text: "Batch JE" },
  { key: 2, text: "Bulk JE" }
];

const JETypeDropdown = forwardRef((props: IJETypeProps, ref) => {
  const [JEType, setJEType] = useState<IDropdownOption<string>>();

  useEffect(() => {
    const ijtype = JETypeDropdownOptions.find((jtype) => jtype.text === props.JEType);
    if (!ijtype) return;

    setJEType(ijtype);
  }, [props.JEType]);

  const onChangeJEType = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void => {
    if (!option) {
      return;
    }

    setJEType(option);
    if (props.onChange) {
      props.onChange(option);
    }
  };

  useImperativeHandle(
    ref,
    (): IJETypeRef => ({
      getJEType() {
        if (!JEType) {
          return null;
        }
        return JEType;
      },
      setInitialJEType(initialJEType: string) {
        // get from options values
        const jtype = JETypeDropdownOptions.find((jtype) => jtype.text === initialJEType);
        if (!JEType) return;

        setJEType(jtype);
      },
      reset() {
        setJEType(JETypeDropdownOptions[0]);
      }
    })
  );

  return (
    <React.Fragment>
      <Dropdown
        selectedKey={JEType?.key}
        onChange={onChangeJEType}
        options={JETypeDropdownOptions}
        disabled={props.loading}
        styles={{
          root: {
            width: "100%",
            marginTop: "4px"
          }
        }}
        required
        errorMessage={JEType?.key === undefined ? "JEType is required" : undefined}
      />
    </React.Fragment>
  );
});

JETypeDropdown.displayName = "JETypeDropdown";

export { JETypeDropdown, IJETypeProps };
