import { IStackStyles, Stack, ITheme, useTheme, TextField, ITextFieldStyles } from "@fluentui/react";
import { LoadingStatus, UserContext } from "@jem/components";
import React, { useContext, useEffect, useImperativeHandle } from "react";

import { IHCCDraftContext } from "../../contexts/IHCCDraftContext/IHCCDraftContext";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const stackStyles = (theme: ITheme): IStackStyles => {
  return {};
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const textFieldStyles = (theme: ITheme): Partial<ITextFieldStyles> => {
  return {};
};

export interface Reviewers {
  additionalReviewers: string;
  primaryReviewer: string;
}

export type ReviewerErrorMessage = string | null;
export interface IHCCCreateReviewersRef {
  getReviewers: () => Promise<[Reviewers, ReviewerErrorMessage]>;
  reset: () => void;
}

export interface IHCCCreateReviewersProps {
  customRef: React.Ref<IHCCCreateReviewersRef>;
  disabled: boolean;
  loadingStatus: LoadingStatus;
}

const IHCCCreateReviewers: React.FC<IHCCCreateReviewersProps> = (props) => {
  const draftContext = useContext(IHCCDraftContext);
  const userData = useContext(UserContext);
  const theme = useTheme();
  const [primaryReviewer, setPrimaryReviewer] = React.useState<string>(
    draftContext.initInfo.values?.supervisorAlias || ""
  );
  const [additionalReviewers, setAdditionalReviewers] = React.useState<string>("");

  useEffect(() => {
    if (draftContext.initInfo.status === LoadingStatus.Resolved) {
      setPrimaryReviewer(draftContext.initInfo.values?.supervisorAlias || "");
    }
  }, [draftContext.initInfo]);

  useEffect(() => {
    if (draftContext.draftModel.status === LoadingStatus.Resolved) {
      setAdditionalReviewers(draftContext.draftModel.values.additionalReviewers);
    }
  }, [draftContext.draftModel]);

  useImperativeHandle(props.customRef, () => ({
    getReviewers: async () => {
      const reviewers = {
        additionalReviewers: additionalReviewers,
        primaryReviewer: primaryReviewer
      };
      if (!draftContext.actions) {
        return [reviewers, "Actions not loaded correctly, please reload the page."];
      }
      let errorMessage = null;
      const userAlias = userData.user.alias;
      if (`${primaryReviewer};${additionalReviewers}`.toUpperCase().indexOf(userAlias.toUpperCase()) !== -1) {
        errorMessage = "The Poster/Actual Poster cannot also be a Reviewer.";
      } else if (additionalReviewers !== draftContext.draftModel.values.additionalReviewers) {
        // validate reviewers
        const errorPayload = await draftContext.actions.validateReviewers(additionalReviewers);
        if (errorPayload.notification.type === "Error") {
          errorMessage = `Additional Reviewers Aliases ${errorPayload.notification.subjectHeader} not found`;
        }
      }
      return [reviewers, errorMessage];
    },
    reset() {
      setAdditionalReviewers("");
    }
  }));

  const onChangeFirstTextFieldValue = React.useCallback(
    (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
      setAdditionalReviewers(newValue || "");
    },
    []
  );

  return (
    <Stack styles={stackStyles(theme)}>
      <TextField label="Primary Reviewer" disabled value={primaryReviewer} readOnly />
      <TextField
        label="Additional Reviewers"
        value={additionalReviewers}
        placeholder="Enter Additional Reviewers"
        onChange={onChangeFirstTextFieldValue}
        styles={textFieldStyles(theme)}
        description="Additional reviewer(s) can be added, each separated by semicolon(;)."
        readOnly={props.disabled}
        disabled={props.loadingStatus === LoadingStatus.Pending}
      />
    </Stack>
  );
};

IHCCCreateReviewers.displayName = "IHCCCreateReviewers";

export default IHCCCreateReviewers;
