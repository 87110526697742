import { Dropdown, IDropdownOption } from "@fluentui/react";
import React, { useState, useImperativeHandle } from "react";
import { GLSearchState } from "../GLSearch.types";
import { CommonDropdownOnChangeHandler } from "@jem/components";

export const SearchJEStatusOptions = [
  { key: -1, text: "None Selected", disabled: true },
  { key: 0, text: "All" },
  { key: 7, text: "Signoff Not Req’d" },
  { key: 8, text: "Doc Req'd" },
  { key: 9, text: "Signoff Initiation Req'd" },
  { key: 10, text: "Sign Off Pending" },
  { key: 11, text: "Awaiting Clarification" },
  { key: 12, text: "Signed Off" }
];

export interface JEStatusRef {
  reset: () => void;
  getStatuses: () => GLSearchState["JEStatus"];
  setInitialStatuses: (statuses: GLSearchState["JEStatus"]) => void;
}

export interface JEStatusProps {
  customRef: React.Ref<JEStatusRef>;
}

const JEStatus: React.FC<JEStatusProps> = (props) => {
  const [status, setStatus] = useState<number[]>([0]);

  useImperativeHandle(props.customRef, () => ({
    reset: () => {
      setStatus([0]);
    },
    getStatuses: () => {
      return status;
    },
    setInitialStatuses: (statuses: GLSearchState["JEStatus"]) => {
      setStatus(statuses);
    }
  }));
  const onChangeStatus = (_event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void => {
    if (!option) {
      return;
    }

    const newOptions = CommonDropdownOnChangeHandler({
      currentOptions: status,
      option: option,
      keyForAll: 0,
      keyForNone: -1,
      totalOptions: SearchJEStatusOptions.length - 2
    });
    setStatus(newOptions);
  };
  return (
    <>
      <Dropdown
        label="JE Status:"
        selectedKeys={status}
        onChange={onChangeStatus}
        options={SearchJEStatusOptions}
        multiSelect
        styles={{
          root: {
            width: "100%",
            maxWidth: "100%"
          }
        }}
      />
    </>
  );
};

JEStatus.displayName = "JEStatus";
export default JEStatus;
