import {
  ActionTypes,
  GLDashboardRow,
  IActionsManagerRef,
  IDashboardGridRef,
  isButtonEnabled,
  GLActionTypes,
  GLExtraActionTypes
} from "@jem/components";
import { IDashboardListActionsState } from "@jem/components";
import { GridCommandToggle } from "@jem/components";
import { GLDashboardDialogRef } from "./GLDashboard.Dialog";
import { GLTileNames } from "./GLDashboard.Requests";

export const actionsPerTile: Partial<{
  [key in GLTileNames]: Partial<IDashboardListActionsState>;
}> = {
  [GLTileNames.MyDraftJEs]: {
    sendToPosterDisabled: GridCommandToggle.Enabled,
    addPosterDisabled: GridCommandToggle.Enabled,
    recallDisabled: GridCommandToggle.Enabled,
    deleteActionDisabled: GridCommandToggle.Enabled
  },
  [GLTileNames.PostedSignOffRequested]: {
    approveDisabled: GridCommandToggle.Enabled,
    needsClarificationDisabled: GridCommandToggle.Enabled,
    addPosterDisabled: GridCommandToggle.Enabled,
    addReviewerDisabled: GridCommandToggle.Enabled
  },
  [GLTileNames.PostedActionRequired]: {
    releaseForSignoffDisabled: GridCommandToggle.Enabled,
    addPosterDisabled: GridCommandToggle.Enabled,
    addReviewerDisabled: GridCommandToggle.Enabled,
    addAttachmentDisabled: GridCommandToggle.Enabled
  },
  [GLTileNames.PreReviewerActionRequired]: {
    approvePreReviewDisabled: GridCommandToggle.Enabled,
    needsClarificationPreReviewDisabled: GridCommandToggle.Enabled
  },
  [GLTileNames.PosterPreReviewRequested]: {
    recallPreReviewDisabled: GridCommandToggle.Enabled
  }
};

export function dashboardButtonStates<T extends { status: string; statusCode: number; rowWeightage: number }>(
  currentTileName: GLTileNames
) {
  return (_rows: T[]): IDashboardListActionsState => {
    const removeAll: IDashboardListActionsState = {
      retryDisabled: GridCommandToggle.Removed,
      saveDisabled: GridCommandToggle.Removed,
      sendToPosterDisabled: GridCommandToggle.Removed,
      approveDisabled: GridCommandToggle.Removed,
      releaseForSignoffDisabled: GridCommandToggle.Removed,
      needsClarificationDisabled: GridCommandToggle.Removed,
      addPosterDisabled: GridCommandToggle.Removed,
      addReviewerDisabled: GridCommandToggle.Removed,
      recallDisabled: GridCommandToggle.Removed,
      rescindDisabled: GridCommandToggle.Removed,
      addAttachmentDisabled: GridCommandToggle.Removed,
      deleteActionDisabled: GridCommandToggle.Removed,
      releaseToSAPDisabled: GridCommandToggle.Removed,
      sendBackFromTreasuryDisabled: GridCommandToggle.Removed,
      approvePreReviewDisabled: GridCommandToggle.Removed,
      needsClarificationPreReviewDisabled: GridCommandToggle.Removed,
      recallPreReviewDisabled: GridCommandToggle.Removed,
      reverseDisabled: GridCommandToggle.Removed
    };

    const standardButtonsForTile = { ...actionsPerTile[currentTileName] };
    if (!standardButtonsForTile) {
      return removeAll;
    }

    return {
      ...removeAll,
      ...standardButtonsForTile
    };
  };
}

export function ActionHandler({
  actionManagerRef,
  dashboardGridRef,
  currentTileName,
  actionName,
  dialogRef,
  allItems,
  actionWeightages
}: {
  actionManagerRef: React.RefObject<IActionsManagerRef<GLDashboardRow>>;
  dashboardGridRef: React.RefObject<IDashboardGridRef>;
  currentTileName: GLTileNames;
  actionName: ActionTypes;
  dialogRef: React.RefObject<GLDashboardDialogRef>;
  allItems: GLDashboardRow[];
  actionWeightages: Record<GLActionTypes, number>;
}): () => void {
  return () => {
    if (actionManagerRef.current && dashboardGridRef.current && dialogRef.current) {
      const selection = dashboardGridRef.current.getSelection();
      const items = selection.isAllSelected ? allItems : (selection.items as GLDashboardRow[]);
      const filteredItems = filteredInvalidAccordingToWeightage(currentTileName, actionName, items, actionWeightages);
      const containsBulks =
        actionName !== ActionTypes.recallPreReview &&
        actionName !== ActionTypes.approvePreReview &&
        actionName !== ActionTypes.needsClarificationPreReview &&
        filteredItems.approved.some((item) => item.postingType.indexOf("7") !== -1);
      if (filteredItems.rejected.length === 0 && filteredItems.approved.length !== 0 && !containsBulks) {
        actionManagerRef.current.open(actionName, items);
      } else {
        // show the user the dialog to let him know some JEs are not valid for this action
        dialogRef.current.open(actionName, filteredItems.approved, filteredItems.rejected);
        // wait for user decision
        const onActionFinished = dialogRef.current.getOnSubmitAsyncSubject();
        onActionFinished.subscribe((result) => {
          // result === true means user clicked on "Yes" button
          // trigger the action to the server at this point
          if (result && actionManagerRef.current) {
            actionManagerRef.current.open(actionName, filteredItems.approved);
          }
        });
      }
    }
  };
}

export function GLDashboardActions(opts: {
  actionManagerRef: React.RefObject<IActionsManagerRef<GLDashboardRow>>;
  dashboardGridRef: React.RefObject<IDashboardGridRef>;
  currentTileName: GLTileNames;
  dialogRef: React.RefObject<GLDashboardDialogRef>;
  allItems: GLDashboardRow[];
  actionWeightages: Record<GLActionTypes, number>;
}) {
  const theArgs = (actionName: ActionTypes) => ({
    ...opts,
    actionName
  });
  return {
    approve: ActionHandler(theArgs(ActionTypes.approve)),
    sendToPoster: ActionHandler(theArgs(ActionTypes.sendToPoster)),
    releaseForSignoff: ActionHandler(theArgs(ActionTypes.releaseForSignoff)),
    needsClarification: ActionHandler(theArgs(ActionTypes.needsClarification)),
    addPoster: ActionHandler(theArgs(ActionTypes.addPoster)),
    addReviewer: ActionHandler(theArgs(ActionTypes.addReviewer)),
    recall: ActionHandler(theArgs(ActionTypes.recall)),
    rescind: ActionHandler(theArgs(ActionTypes.rescind)),
    addAttachment: ActionHandler(theArgs(ActionTypes.addAttachment)),
    deleteAction: ActionHandler(theArgs(ActionTypes.deleteAction)),
    approvePreReview: ActionHandler(theArgs(ActionTypes.approvePreReview)),
    needsClarificationPreReview: ActionHandler(theArgs(ActionTypes.needsClarificationPreReview)),
    recallPreReview: ActionHandler(theArgs(ActionTypes.recallPreReview)),
    reverse: ActionHandler(theArgs(ActionTypes.reverse))
  };
}

export interface ResultsOfFiltering {
  approved: GLDashboardRow[];
  rejected: GLDashboardRow[];
}

// must return valid ProcessingStatuses for Rejected
function filteredInvalidAccordingToWeightage(
  _currentTileName: GLTileNames,
  actionName: GLActionTypes,
  items: GLDashboardRow[],
  actionWeightages: Record<GLActionTypes, number>
): ResultsOfFiltering {
  const resultsOfFiltering: ResultsOfFiltering = {
    approved: [],
    rejected: []
  };
  for (const item of items) {
    if (item.isDraft && actionName === ActionTypes.recall) {
      const recallWeightage = actionWeightages[GLExtraActionTypes.draftRecall];
      const recallEnabled = isButtonEnabled(recallWeightage, item.rowWeightage);
      if (recallEnabled) {
        resultsOfFiltering.approved.push(item);
      } else {
        resultsOfFiltering.rejected.push(item);
      }
      continue;
    }

    if (item.isDraft && actionName === ActionTypes.needsClarification) {
      const needsClarificationWeighage = actionWeightages[GLExtraActionTypes.draftNeedsClarification];
      const needsClarificationEnabled = isButtonEnabled(needsClarificationWeighage, item.rowWeightage);
      if (needsClarificationEnabled) {
        resultsOfFiltering.approved.push(item);
      } else {
        resultsOfFiltering.rejected.push(item);
      }
      continue;
    }
    if (item.isDraft && actionName === ActionTypes.recallPreReview) {
      const recallWeightage = actionWeightages[GLExtraActionTypes.draftRecall];
      const recallEnabled = isButtonEnabled(recallWeightage, item.rowWeightage);
      if (recallEnabled) {
        resultsOfFiltering.approved.push(item);
      } else {
        resultsOfFiltering.rejected.push(item);
      }
      continue;
    }
    const buttonWeightage = actionWeightages[actionName];
    const isValid = isButtonEnabled(buttonWeightage, item.rowWeightage);
    if (isValid) {
      if (
        actionName === ActionTypes.reverse &&
        item.reverseJENum !== "" &&
        typeof Number(item.reverseJENum) === "number" &&
        !isNaN(Number(item.reverseJENum))
      ) {
        resultsOfFiltering.rejected.push(item);
      } else {
        resultsOfFiltering.approved.push(item);
      }
    } else {
      resultsOfFiltering.rejected.push(item);
    }
  }

  return resultsOfFiltering;
}
