import { JEMChecklistDetailsRow } from "@jem/components";
import { INoaCtivityResponseObject } from "./JEMChecklistDetails.Requests";
import { ChecklistPublishSQLResult } from "./JEMChecklistDetails.types";

export function isNoActivityTotalSuccess(responses: INoaCtivityResponseObject[]): boolean {
  responses.forEach((response) => {
    if (response?.data.status === false || response?.data.errorCode?.includes("ERR")) {
      return false;
    }
  });

  return true;
}
export function getErrorMessagesForNoActivity(responses: INoaCtivityResponseObject[]) {
  const errorMessage = responses
    .map((response) => {
      if (response?.data.status === false || response?.data.errorCode?.includes("ERR")) {
        return `${response?.jeTaskName} ${response?.data?.message}`;
      }
    })
    .filter((x) => !!x);
  return errorMessage as string[];
}

export interface PublishedResponse {
  data: ChecklistPublishSQLResult[] | null;
  status: boolean;
  message: string;
  errorCode: string;
}

export interface CloneResponse {
  status: boolean;
  message: string;
  errorCode: string;
  sourceChecklistGuid: string;
  targetChecklistGuid: string;
}

export function parsePublishOrNoActivityResponse(
  response: PublishedResponse,
  checklistItems: JEMChecklistDetailsRow[],
  errorMessage: string
): string[] {
  const itemizedErrors = response.data;
  if (itemizedErrors) {
    return [
      errorMessage,
      ...itemizedErrors.map((x) => {
        const item = checklistItems.find((item) => item.refGuid === x.refguid);
        return `${item?.jeTaskName} - ${x.errorDescription}`;
      })
    ];
  }

  return [response.message];
}

export function parseCloneResponse(response: CloneResponse, _errorMessage: string): string[] {
  return [response.message];
}
