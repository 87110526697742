import { DomainDataEnum, DomainDataObjects } from "../contexts/JEMContext/JEMContext.domainData.types";

export enum StatusCode {
  Draft = 1,
  SentToPoster = 2,
  DraftClarificationNeeded = 3,
  Submitted = 4,
  Posted = 5,
  FailedInSAP = 6,
  SignoffNotRequired = 7,
  DocRequired = 8,
  SignoffInitiationRequired = 9,
  SignOffPending = 10,
  AwaitingClarification = 11,
  SignedOff = 12,
  DraftRecalled = 13,
  Validated = 14,
  Completed = 15,
  ReleaseToSAP = 16
}

export function GetDraftStatusString(statusCode: number) {
  let status = "";
  switch (statusCode) {
    case 1:
      status = "Draft";
      break;
    case 2:
      status = "Sent To Poster";
      break;
    case 3:
      status = "Draft (Clarification needed)";
      break;
    case 4:
      status = "Draft (Recalled)";
      break;
    case 5:
      status = "Delete";
      break;
    case 6:
      status = "Submitted";
      break;
    case 0:
      status = "Unknown";
      break;
    case 7:
      status = "Validated";
      break;
    case 8:
      status = "Submitted";
      break;
    case -1:
      status = "Failed in SAP";
      break;
  }
  return status;
}

export function GetStatusString(statusCode: number) {
  const statusCodeArray = [statusCode];
  const statusCodeSupportedCodeToString = {
    [StatusCode.Draft]: "Draft",
    [StatusCode.SentToPoster]: "Sent To Poster",
    [StatusCode.DraftClarificationNeeded]: "Draft (Clarification Needed)",
    [StatusCode.Submitted]: "Submitted",
    [StatusCode.Posted]: "Posted",
    [StatusCode.FailedInSAP]: "Failed in SAP",
    [StatusCode.SignoffNotRequired]: "Signoff Not Req'd",
    [StatusCode.DocRequired]: "Doc Required",
    [StatusCode.SignoffInitiationRequired]: "Signoff Initiation Req'd",
    [StatusCode.SignOffPending]: "Signoff Pending",
    [StatusCode.AwaitingClarification]: "Awaiting Clarification",
    [StatusCode.SignedOff]: "Signed Off",
    [StatusCode.DraftRecalled]: "Draft (Recalled)",
    [StatusCode.Validated]: "Validated",
    [StatusCode.Completed]: "Completed",
    [StatusCode.ReleaseToSAP]: "Release To SAP"
  };

  const statusString = statusCodeArray.map(
    (status: keyof typeof statusCodeSupportedCodeToString) => statusCodeSupportedCodeToString[status] as string
  );

  return statusString[0];
}

export function stringToStatusCode(
  domainData: Pick<DomainDataObjects, DomainDataEnum.JeStatus>,
  statusString: string
): number {
  const possibleStatusCode: number[] = domainData.JeStatus.filter((status) => status.statusCode === statusString).map(
    (jeStatus) => jeStatus.statusId
  );

  if (possibleStatusCode.length === 0) return 0;
  return possibleStatusCode[0];
}

export const StatusCodesToString = {
  1: "Draft",
  2: "Sent To Poster",
  3: "Draft Clarification Needed",
  4: "Submitted",
  5: "Posted",
  6: "Failed In SAP",
  7: "Signoff Not Required",
  8: "Doc Required",
  9: "Signoff Initiation Required",
  10: "Sign Off Pending",
  11: "Awaiting Clarification",
  12: "Signed Off",
  13: "Draft Recalled",
  14: "Validated",
  15: "Completed",
  16: "Release To SAP"
};

export interface IStatusCodes {
  Draft: 1;
  SentToPoster: 2;
  DraftClarificationNeeded: 3;
  Submitted: 4;
  Posted: 5;
  FailedInSAP: 6;
  SignoffNotRequired: 7;
  DocRequired: 8;
  SignoffInitiationRequired: 9;
  SignOffPending: 10;
  AwaitingClarification: 11;
  SignedOff: 12;
  DraftRecalled: 13;
  Validated: 14;
  Completed: 15;
  ReleaseToSAP: 16;
}
