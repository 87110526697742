import { default as format } from "date-fns/format";

export enum DateStatus {
  Initial,
  None,
  InvalidFormat
}

const isDateInMMDDYYYY = (dateString: string) => /^(0?[1-9]|1[0-2])\/(0?[1-9]|1\d|2\d|3[01])\/\d{4}/.test(dateString);

export const isDateNotInitialAndInMMDDYYYY = (dateString?: string | null): DateStatus => {
  if (dateString === undefined || dateString === null) return DateStatus.Initial;
  if (dateString === "") return DateStatus.Initial;
  if (!isDateInMMDDYYYY(dateString)) return DateStatus.InvalidFormat;
  return DateStatus.None;
};

export function ConvertUTCTimeToLocalTime(UTCDateString: string): string {
  let localDate = new Date(UTCDateString);
  localDate = new Date(
    Date.UTC(
      localDate.getFullYear(),
      localDate.getMonth(),
      localDate.getDate(),
      localDate.getHours(),
      localDate.getMinutes(),
      localDate.getSeconds()
    )
  );
  return format(localDate, "MMM dd, yyyy HH:mm:ss");
}

export function getFormattedDateString(dateString: string | null | undefined): string {
  if (!dateString || dateString === "") return "";
  const validDate = format(new Date(dateString), "MMM dd, yyyy HH:mm:ss");
  return validDate;
}
export function formattedDateString(dateString: string | undefined | null, formatString: string): string | null {
  if (!dateString || dateString === "") return null;
  const validDate = format(new Date(dateString), formatString);
  return validDate;
}
