import React from "react";
import { IColumn } from "@fluentui/react";
import { GetLinkToDetailsFromChecklistItem, IExtendedColumn, onRenderArray } from "@jem/components";
import { Link } from "react-router-dom";
import { JEMChecklistTask } from "./JEMChecklistDashboardByCompanyCode.schemas";
import isValid from "date-fns/isValid";

interface JEMChecklistTaskColumn extends IExtendedColumn {
  fieldName: keyof JEMChecklistTask;
}
export const getJEMChecklistTasksColumns = (options: { location: any }) => (): JEMChecklistTaskColumn[] => {
  const cols: JEMChecklistTaskColumn[] = [
    {
      key: "jeTaskName",
      name: "JE Task Name",
      fieldName: "jeTaskName",
      type: "string",
      minWidth: 115,
      onRender: (
        item?: JEMChecklistTask,
        _index?: number | undefined,
        _column?: IColumn | undefined
      ): JSX.Element | React.ReactText | null => {
        if (!item) return null;
        if (
          item.publishedState.indexOf("Published") === -1 ||
          !(item.publishedState.toLowerCase() === "Published".toLowerCase())
        )
          return item.jeTaskName;
        let url = null;
        if (item.href && item.href !== "") {
          const div = document.createElement("div");
          // replace #// with #/ to make it a valid url
          item.href = item.href.replace("#//", "#/");
          div.innerHTML = item.href;
          const a = div.querySelector("a");
          if (a) {
            const href = a.href;
            const jemHyperLinkUrl = new URL(href);
            const hash = jemHyperLinkUrl.hash;
            const jeIdParam = "jeId=";

            let jeId = null;
            if (hash && hash.indexOf(jeIdParam) !== -1) {
              const startIndex = hash.indexOf(jeIdParam) + jeIdParam.length;
              const endIndex = hash.indexOf("&", startIndex) !== -1 ? hash.indexOf("&", startIndex) : hash.length;
              jeId = hash.substring(startIndex, endIndex);
            }
            if (jeId) {
              url = `/gl/details?jeid=${jeId}`;
            }
          }
        }
        if (url === null) {
          if (item.jeType.indexOf("Batch") !== -1 || item.jeType.indexOf("Bulk") !== -1) {
            url = `/gl/batch?RefGuid=${item.refGuid}`;
          } else {
            url = `/gl/create?RefGuid=${item.refGuid}`;
          }
        }
        return (
          <Link
            to={url}
            state={{
              from: options.location
            }}
            replace
          >
            {item.jeTaskName}
          </Link>
        );
      }
    }
  ];

  // add the following cols too
  cols.push(
    {
      key: "taskId",
      name: "Task Id",
      fieldName: "taskId",
      type: "number",
      minWidth: 80,
      sortDescendingAriaLabel: "Sort by id"
    },
    {
      key: "opsDetails",
      name: "Ops Detail",
      fieldName: "opsDetailName",
      type: "string",
      minWidth: 80,
      sortDescendingAriaLabel: "Sort by Checklist Name"
    },
    {
      key: "parentChecklistName",
      name: "Checklist Name",
      fieldName: "parentChecklistName",
      type: "string",
      minWidth: 80,
      sortDescendingAriaLabel: "Sort by Checklist Name",
      onRender: (
        item?: JEMChecklistTask,
        _index?: number | undefined,
        _column?: IColumn | undefined
      ): JSX.Element | React.ReactText | null => {
        if (!item) return null;
        return (
          <Link
            to={`/jemchecklist/details?checklistRefGuid=${item.parentChecklistRefGuid}`}
            state={{
              checklistName: item.parentChecklistName,
              defaultReviewers: item.assignedReviewer,
              opsDetailId: item.opsDetailId
            }}
          >
            {item.parentChecklistName}
          </Link>
        );
      }
    },
    {
      key: "companycode",
      name: "Company Code",
      fieldName: "companyCode",
      type: "object",
      minWidth: 75,
      onRender: (item?: JEMChecklistTask) => {
        if (!item) return null;
        return onRenderArray(item.companyCode);
      }
    },
    {
      key: "draftDateCode",
      name: "Draft Date Code",
      fieldName: "draftDateCode",
      type: "string",
      minWidth: 90
    },
    {
      key: "frequency",
      name: "Frequency",
      fieldName: "frequency",
      type: "string",
      minWidth: 80
    },
    {
      key: "estimatedTime",
      name: "Estimated Time (mins)",
      fieldName: "estimatedTimeMins",
      type: "number",
      minWidth: 130
    },
    {
      key: "actualTimeTaken",
      name: "Actual Time Taken (mins)",
      fieldName: "actualTimeTakenMins",
      type: "number",
      minWidth: 130
    },
    {
      key: "postingDueDate",
      name: "Posting Due Date",
      fieldName: "postingDueDate",
      type: "string",
      minWidth: 85,
      sortDescendingAriaLabel: "Sorted by posting due date",
      onRender: (item?: JEMChecklistTask) => {
        if (!item) return null;

        return item.postingDueDate && isValid(new Date(item.postingDueDate))
          ? new Date(item.postingDueDate).toLocaleDateString("en-US", {
              day: "numeric",
              month: "numeric",
              year: "numeric"
            })
          : "";
      }
    },
    {
      key: "status",
      name: "Status",
      fieldName: "status",
      type: "string",
      minWidth: 90
    },
    {
      key: "publishedState",
      name: "Published State",
      fieldName: "publishedState",
      type: "string",
      minWidth: 30
    },
    {
      key: "jeType",
      name: "JE Type",
      fieldName: "jeType",
      type: "string",
      minWidth: 80,
      sortDescendingAriaLabel: "Sort by je type"
    },
    {
      key: "taskType",
      name: "Task Type",
      fieldName: "taskType",
      type: "string",
      minWidth: 60,
      sortDescendingAriaLabel: "Sort by task type"
    },
    {
      key: "significance",
      name: "Significance",
      fieldName: "significance",
      type: "string",
      minWidth: 60,
      sortDescendingAriaLabel: "Sorted by significance"
    },
    {
      key: "postingNotRequired",
      name: "Posting Not Required",
      fieldName: "postingNotRequired",
      type: "string",
      minWidth: 95,
      sortDescendingAriaLabel: "Sort by no activity reason"
    },
    {
      key: "assignedPoster",
      name: "Assigned Poster",
      fieldName: "assignedPoster",
      type: "object",
      minWidth: 30,
      onRender: (item?: JEMChecklistTask) => {
        if (!item) return null;
        return onRenderArray(item.assignedPoster);
      }
    },
    {
      key: "docNo",
      name: "Doc Num",
      fieldName: "docNo",
      type: "string",
      minWidth: 80,
      onRender: (
        item?: JEMChecklistTask,
        _index?: number | undefined,
        _column?: IColumn | undefined
      ): JSX.Element | React.ReactText | null => {
        if (!item) return null;
        const urlParts = GetLinkToDetailsFromChecklistItem(item);
        if (!urlParts) return null;
        const { url, stringContent } = urlParts;
        if (!url) return stringContent;

        return <Link to={url}>{stringContent}</Link>;
      }
    },
    {
      key: "assignedReviewer",
      name: "Assigned Reviewer",
      fieldName: "assignedReviewer",
      type: "object",
      minWidth: 90,
      onRender: (item?: JEMChecklistTask) => {
        if (!item) return null;
        return onRenderArray(item.assignedReviewer);
      }
    },
    {
      key: "attachment",
      name: "Attachments",
      fieldName: "attachment",
      type: "string",
      minWidth: 80,
      sortDescendingAriaLabel: "Sort by id"
    },
    {
      key: "author",
      name: "Author",
      fieldName: "author",
      type: "string",
      minWidth: 80,
      sortDescendingAriaLabel: "Sort by id"
    },
    {
      key: "reasonCode",
      name: "Reason Code",
      fieldName: "reasonCodeText",
      type: "string",
      minWidth: 70,
      sortDescendingAriaLabel: "Sorted by poster"
    },
    {
      key: "datemodified",
      name: "Date Modified",
      fieldName: "modifiedOn",
      type: "string",
      minWidth: 60,
      sortDescendingAriaLabel: "Sorted by poster"
    },
    {
      key: "purpose",
      name: "Purpose",
      fieldName: "purpose",
      type: "string",
      minWidth: 30
    },
    {
      key: "period",
      name: "Period",
      fieldName: "postingPeriod",
      type: "string",
      minWidth: 90
    },
    {
      key: "documentType",
      name: "Document Type",
      fieldName: "documentType",
      type: "string",
      minWidth: 30
    },
    {
      key: "referenceNumber",
      name: "Reference Number",
      fieldName: "referenceNumber",
      type: "number",
      minWidth: 90
    },
    {
      key: "description",
      name: "Description",
      fieldName: "jeDescription",
      type: "string",
      minWidth: 90
    },
    {
      key: "noActivityComments",
      name: "No Activity Comments",
      fieldName: "noActivityComments",
      type: "string",
      minWidth: 90
    },
    {
      key: "isF05PostingString",
      name: "Is F05 Posting",
      fieldName: "isF05PostingString",
      type: "string",
      minWidth: 80
    }
  );
  return cols;
};
