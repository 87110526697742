import { Selection } from "@fluentui/react";
import { IExtendedColumn } from "./DashboardGrid.types";

export function rowsAndColumnsToExport<T>(
  columns: IExtendedColumn[],
  items: T[]
): { headers: string[]; rows: string[][] } {
  const itemsToExport = items.reduce((arrayOfArrays, cItem) => {
    const rowItems = columns.map((col) => {
      if (col.onExport) {
        return col.onExport(cItem);
      }
      if (col.fieldName) {
        const value = cItem[col.fieldName];
        if (Array.isArray(value)) {
          return value
            .map(String)
            .filter((x) => x)
            .join(";");
        }
        return value;
      }
      return "";
    });
    arrayOfArrays.push(rowItems as string[]);
    return arrayOfArrays;
  }, [] as string[][]);
  const headers = columns.map((col) => col.nameForExport || col.name);
  return {
    headers,
    rows: itemsToExport
  };
}

export function getItemsFromSelection<T>(selection: Selection, gridItems: T[]): T[] {
  const selectedItems = selection.getSelection();
  const isAllOrNothingSelected = selectedItems.length === 0 || selection.isAllSelected();
  const items = isAllOrNothingSelected
    ? // all items with the filters applied
      gridItems
    : // only items that were carefully selected
      // by the user
      selectedItems;
  return items as T[];
}

export interface ExportFunctions {
  [key: string]: (headerAndRows: ReturnType<typeof rowsAndColumnsToExport>) => any;
}

export const exportFunctions: ExportFunctions = {
  toExcelFile: (headerAndRows) => {
    const excelRows: string[][] = [headerAndRows.headers, ...headerAndRows.rows];
    return excelRows as string[][];
  },
  toClipboard: (headerAndRows) => {
    let text = headerAndRows.headers.join("\t");
    text = `${text}\n${headerAndRows.rows.map((v) => v.join("\t")).join("\n")}`;
    return text as string;
  }
};
