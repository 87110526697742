import { ApiError, IUserProviderState, JemConfiguration, getRequest, getValidUrl, postRequest } from "@jem/components";

export interface IDelegateReviewers {
  action: string;
  delegateId: number;
  delegatedToAlias: string;
  delegatorAlias: string;
  delegateFrom: string;
  delegateUpto: string;
  isActive: boolean;
  tempId: number | null;
}

export interface IDelegateResponse {
  id: number;
  inF_ERR_Code: string;
}

export async function getDelegateReviewers(
  configuration: JemConfiguration["GeneralLedgerApi"],
  getTokenFn: IUserProviderState["accessToken"]
): Promise<IDelegateReviewers[]> {
  const endpoint = `${configuration.baseApiUrl}${configuration.endpoints.getDelegateReviewers}`;
  const endpointUrl = getValidUrl(endpoint);

  const delegateReviewerInfo = await getRequest<IDelegateReviewers[]>(endpointUrl, getTokenFn);
  if (!delegateReviewerInfo) {
    throw new ApiError("No response from server");
  }

  return delegateReviewerInfo;
}

export interface DelegateMessageFromApi {
  inF_ERR_Code: string;
  id: number;
}

export async function saveDelegateReviewers(
  configuration: JemConfiguration["GeneralLedgerApi"],
  getTokenFn: IUserProviderState["accessToken"],
  payload: IDelegateReviewers[]
): Promise<IDelegateResponse> {
  try {
    const endpoint = `${configuration.baseApiUrl}${configuration.endpoints.getDelegateReviewers}`;
    const endpointUrl = getValidUrl(endpoint);

    const saveDelegateResponse = await postRequest<DelegateMessageFromApi>(endpointUrl, payload, getTokenFn);
    if (saveDelegateResponse == null) {
      return {
        id: 0,
        inF_ERR_Code: "Could not fetch delegates from server."
      };
    }

    return saveDelegateResponse;
  } catch (e) {
    console.error(e);
    return {
      id: 0,
      inF_ERR_Code: "Error while performing action."
    };
  }
}
