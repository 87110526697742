import React, { useCallback, useEffect, useReducer, useRef } from "react";
import { LoadingStatus } from "../utilities/Utilities";

const useMountedRef = () => {
  const mountedRef = useRef(false);
  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  });
  return mountedRef;
};

const useSafeDispatch = <T,>(dispatch: (...args: T[]) => void) => {
  const mountedRef = useMountedRef();
  return useCallback(
    (...args: T[]) => {
      return mountedRef.current ? dispatch(...args) : void 0;
    },
    [mountedRef, dispatch]
  );
};

// Example usage:
// const {data, error, status, run} = useAsync()
// React.useEffect(() => {
//   run(fetchData(args))
// }, [args, run])
interface State<T> {
  error: Error | null;
  data: T | null;
  status: LoadingStatus;
}

const defaultInitialState: State<null> = { status: LoadingStatus.Idle, data: null, error: null };
export function useAsync<T>(initialState?: Partial<State<T>>) {
  const initialStateRef = React.useRef<State<T | null>>({
    ...defaultInitialState,
    ...initialState
  });

  const [{ status, data, error }, setState] = useReducer(
    (state: State<T>, action: Partial<State<T>>) => ({ ...state, ...action }),
    initialStateRef.current
  );
  const safeSetState = useSafeDispatch<Partial<State<T>>>(setState);

  const run = React.useCallback(
    (promise: Promise<T>) => {
      if (!promise || !promise.then) {
        throw new Error(
          `The argument passed to useAsync().run must be a promise. Maybe a function that's passed isn't returning anything?`
        );
      }
      safeSetState({ status: LoadingStatus.Pending });
      return promise.then(
        (data: T) => {
          safeSetState({ data, status: LoadingStatus.Resolved });
          return data;
        },
        (error) => {
          safeSetState({ status: LoadingStatus.Rejected, error });
          return error;
        }
      );
    },
    [safeSetState]
  );

  const setData = useCallback((data: any) => safeSetState({ data }), [safeSetState]);
  const setError = useCallback((error: any) => safeSetState({ error }), [safeSetState]);
  const reset = useCallback(() => safeSetState(initialStateRef.current), [safeSetState]);

  return {
    // using the same names that react-query uses for convenience
    isIdle: status === LoadingStatus.Idle,
    isLoading: status === LoadingStatus.Pending,
    isError: status === LoadingStatus.Rejected,
    isSuccess: status === LoadingStatus.Resolved,

    setData,
    setError,
    error,
    status,
    data,
    run,
    reset
  };
}
