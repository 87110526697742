import { CoherenceCustomizations, CoherenceTheme } from "@coherence-design-system/styles";
import { injectGlobal } from "@emotion/css";
import { loadTheme, mergeStyles, ThemeProvider } from "@fluentui/react";
import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";

import GeneralLedger from "./App.GeneralLedger";
import Operations from "./App.Operations";
import PaymentOrders from "./App.PaymentOrders";
import Home from "./Home/pages/Home/Home";
import {
  AppShell,
  AuthenticationState,
  FatalError,
  getLogger,
  JemConfiguration,
  JEMProvider,
  LoggingProvider,
  msalSettings,
  PageProvider,
  UserContext,
  UserProvider
} from "@jem/components";

import * as wjCore from "@grapecity/wijmo";
import JEMChecklist from "./App.JEMChecklist";
import Microfrontends from "./App.Microfrontends";
import MySettings from "./App.MySettings";

injectGlobal`
* { box-sizing: border-box;
}
html, body, #app, #app>div  {
  height: 100%;
  margin: 0;
}
html, body {
  font-size: 100%;
  margin: 0;
  padding: 0;
  width:100%;
  height:100%;
  overflow: auto;
  font-family: "Segoe UI Web (West European)",Segoe UI,-apple-system,BlinkMacSystemFont,Roboto,Helvetica Neue,sans-serif;
}
button#obf-BasicFormCancelButton {
  display:none;
}
`;

const Logout: React.FunctionComponent = () => {
  const { status } = useContext(UserContext);
  if (status === AuthenticationState.Authenticated) {
    return <Navigate to="/" />;
  }
  return <p>You have logged out of JEM successfully. You can close this tab now.</p>;
};

const Wildcard = () => <Navigate to="/home" />;

const ContentNotFound = () => {
  return <p>Page not found 1</p>;
};

mergeStyles({
  ":global(body),  :global(html), :global(#app)": {
    margin: 0,
    padding: 0,
    height: "100vh"
  }
});

// the only thing that works with Customizer
// version 4-dev-10 still needs this
loadTheme(CoherenceTheme);

function ErrorFallback({ error }: { error: Error }) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre style={{ color: "red" }}>{error.message}</pre>
    </div>
  );
}

const loadConfiguration = async (appBuildId: string, rootUrl: string): Promise<JemConfiguration> => {
  console.log("loadConfiguration", appBuildId, rootUrl);
  let configPath = `/bundle/config.${appBuildId}.json`;
  configPath = `${rootUrl}${configPath}`;

  const cfgJson = await fetch(configPath);
  const cfg = await cfgJson.json();
  return cfg;
};

declare global {
  interface Window {
    JemVersion: string;
    JemConfiguration: JemConfiguration;
  }
}

const AsyncApp = () => {
  const [config, setConfig] = useState<JemConfiguration | null>(null);

  useEffect(() => {
    const fetchElement = async () => {
      const appBuildId = window.JemVersion;
      const rootUrl = window.location.origin;
      const JemConfiguration = await loadConfiguration(appBuildId, rootUrl); // replace with your function
      window.JemConfiguration = JemConfiguration;
      setConfig(JemConfiguration);
    };

    fetchElement();
  }, []); // empty dependency array so this effect runs once on component mount

  if (config === null) {
    return <div>Loading...</div>; // or any loading spinner
  }

  return <App configuration={config} />;
};

const App: React.FC<{ configuration: JemConfiguration }> = ({ configuration }) => {
  if (!configuration) {
    throw new FatalError("No JEM Configuration available.");
  }
  if (configuration.wijmoLicense) {
    wjCore.setLicenseKey(configuration.wijmoLicense);
  }
  const environment = window.location.host.indexOf("localhost") != -1 ? "local" : configuration.environment;
  const logger = getLogger(environment, configuration);
  const [isMock, authProvider] = msalSettings(environment, configuration);
  const script = document.createElement("script");
  script.src = configuration?.JEMHelpBot ?? ""; // Use optional chaining to safely access the property
  document.head.appendChild(script);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <BrowserRouter>
        <LoggingProvider appInsights={logger}>
          <UserProvider msalSettings={authProvider} mock={isMock} jemConfiguration={configuration}>
            <ThemeProvider {...CoherenceCustomizations}>
              <PageProvider>
                <JEMProvider configuration={configuration.DomainDataAPI} disabled={false}>
                  <AppShell configuration={configuration}>
                    <Routes>
                      <Route path="/logout" element={<Logout />} />
                      <Route path="/ops/*" element={<Operations config={configuration} />} />
                      <Route path="/ihcc/*" element={<PaymentOrders config={configuration} />} />
                      <Route path="/gl/*" element={<GeneralLedger config={configuration} />} />
                      <Route path="/microfrontends/*" element={<Microfrontends config={configuration} />} />
                      <Route path="/home" element={<Home config={configuration} />} />
                      <Route path="/jemchecklist/*" element={<JEMChecklist config={configuration}></JEMChecklist>} />
                      <Route path="/mySettings/*" element={<MySettings config={configuration}></MySettings>} />
                      <Route path="/#" element={<></>} />
                      <Route path="/*" element={<Wildcard />} />
                      <Route element={<ContentNotFound />} />
                    </Routes>
                  </AppShell>
                </JEMProvider>
              </PageProvider>
            </ThemeProvider>
          </UserProvider>
        </LoggingProvider>
      </BrowserRouter>
    </ErrorBoundary>
  );
};

export default AsyncApp;
