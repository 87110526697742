import { OpsDashboardApi, IUserProviderState, MockDataFn, getValidUrl, getRequest } from "@jem/components";
import { OpsDashboardRequestsElement, OpsDashboardRequestsFromApi } from "./RequestsGrid.types";

export function payloadToUserGrid(payload: OpsDashboardRequestsFromApi[]): OpsDashboardRequestsElement[] {
  return payload;
}

export async function getOpsDashboardRequestsData(
  configuration: OpsDashboardApi,
  opts: {
    getTokenFn: IUserProviderState["accessToken"];
    mockFn: MockDataFn<OpsDashboardRequestsElement[]> | undefined;
  }
): Promise<OpsDashboardRequestsElement[]> {
  try {
    if (opts.mockFn) {
      return opts.mockFn(opts);
    }
    const endpoint = `${configuration.baseApiUrl}${configuration.endpoints.userGrid}`;
    const endpointUrl = getValidUrl(endpoint);
    const payload = await getRequest<OpsDashboardRequestsFromApi[]>(endpointUrl, opts.getTokenFn);
    if (!payload) {
      return [];
    }
    const dataForActions = payloadToUserGrid(payload);
    return dataForActions;
  } catch (e) {
    console.error(e);
    return [];
  }
}
