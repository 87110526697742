import { DefaultButton, Dropdown, IDropdownOption, PrimaryButton, Stack, TextField } from "@fluentui/react";
import { FastField, Form, Formik } from "formik";
import React, { PropsWithChildren, useContext, useEffect, useState } from "react";

import * as Yup from "yup";

import { gridStyle } from "../../../GeneralLedger/shared/GL.styles";
import { ValidationManager } from "../../../GeneralLedger/validation/ValidationManager";
import { JEMContext, JEMContextData } from "../../contexts/JEMContext/JEMContext";
import { ActionResult, ActionTypes } from "../ActionManager/ActionsManager.types";
import { ActionsContext } from "./Actions.ActionsContext";
import { ActionTitle } from "./Actions.ActionTitle";
import { ActionOnSubmitHandlerMap, BaseActionProps, CommonPropsOnRows } from "./Actions.types";

interface NeedsClarificationProps<ListType, ResultType> extends BaseActionProps<ListType, ResultType> {
  onSubmitHandler:
    | ActionOnSubmitHandlerMap<ListType, ResultType>[ActionTypes.needsClarification]
    | ActionOnSubmitHandlerMap<ListType, ResultType>[ActionTypes.needsClarificationPreReview];
}

function getClarificationCodes(jemContext?: JEMContextData) {
  if (!jemContext) {
    return [];
  }
  if (!jemContext.initInfo.values) {
    return [];
  }
  return jemContext.initInfo.values.JeClarificationCodes.map((clarificationCode) => clarificationCode.jeClarification);
}

const NeedsClarification = <ListType extends CommonPropsOnRows, ResultType extends ActionResult>(
  props: PropsWithChildren<NeedsClarificationProps<ListType, ResultType>>
) => {
  const { styles } = useContext(ActionsContext);
  const jemContext = useContext(JEMContext);
  if (props.configuration && !jemContext) {
    throw new Error("JEMContext is required when configuration is provided");
  }
  const [clarificationCodes, setClarificationCodes] = useState<string[]>(getClarificationCodes(jemContext));

  useEffect(() => {
    if (jemContext.initInfo.values && jemContext.initInfo.values.JeClarificationCodes) {
      const cCodes = getClarificationCodes(jemContext);
      setClarificationCodes(cCodes);
    }
  }, [jemContext.initInfo.values]);

  const initialState = {
    reviewersTabComments: "",
    reviewersTabClarificationCodes: []
  };

  const isGL = !!props.configuration;

  return (
    <>
      <ActionTitle onCloseButton={() => props.onCancel()} name={"Reject"}></ActionTitle>
      <div className={styles.contentStyles.body}>
        <Formik
          initialValues={initialState}
          onSubmit={async (values, { setSubmitting, setFieldError }) => {
            try {
              setSubmitting(true);
              const result = await props.onSubmitHandler(
                props.items,
                values.reviewersTabComments,
                values.reviewersTabClarificationCodes
              );
              setSubmitting(false);
              props.onSubmit(result);
            } catch (error) {
              if (error instanceof Yup.ValidationError) {
                setFieldError("error", error.message);
              }
              throw error;
            }
          }}
          validateOnMount={true}
          validationSchema={ValidationManager.CommentAndValidationCodeValidation(!isGL)}
        >
          {(formik) => (
            <Form onSubmit={formik.handleSubmit}>
              <Stack
                {...{
                  tokens: {
                    childrenGap: 10,
                    padding: 10
                  }
                }}
              >
                <Stack.Item>
                  <FastField
                    name="reviewersTabComments"
                    label="Comments:"
                    as={TextField}
                    multiline
                    rows={2}
                    type="input"
                    errorMessage={formik.errors.reviewersTabComments}
                  />
                </Stack.Item>
                {props.configuration && (
                  <Stack.Item
                    {...{
                      styles: {
                        root: {
                          maxWidth: 300
                        }
                      }
                    }}
                  >
                    <FastField
                      label="Clarification Codes:"
                      name="reviewersTabClarificationCodes"
                      as={Dropdown}
                      options={clarificationCodes.map((code, index) => ({
                        key: `${index}`,
                        text: code
                      }))}
                      multiSelect
                      onChange={(_event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption) => {
                        const clarCode = formik.values.reviewersTabClarificationCodes;
                        if (item) {
                          formik.setFieldValue(
                            "reviewersTabClarificationCodes",
                            item.selected ? [...clarCode, item.text] : clarCode.filter((key) => key !== item.key)
                          );
                        }
                      }}
                      errorMessage={formik.errors.reviewersTabClarificationCodes}
                    />
                  </Stack.Item>
                )}
                <Stack.Item>
                  <Stack horizontal {...{ tokens: gridStyle }}>
                    <Stack.Item align="end">
                      <PrimaryButton text={"Clarify"} type="submit" disabled={!formik.isValid} />
                    </Stack.Item>
                    <Stack.Item align="end">
                      <DefaultButton
                        onClick={(event) => {
                          event.stopPropagation();
                          props.onCancel();
                        }}
                        text="Cancel"
                      />
                    </Stack.Item>
                  </Stack>
                </Stack.Item>
              </Stack>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

NeedsClarification.displayName = "NeedsClarification";

export { NeedsClarification };
