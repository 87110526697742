import { css } from "@emotion/css";
import {
  BaseButton,
  Button,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Icon,
  PrimaryButton,
  TextField,
  useTheme
} from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import React, { useState } from "react";
import { ActionTypes, ActionTypesKeys } from "./OpsDashboardCreate.types";
import { ActionStates, UseOpsDashboardApi } from "./OpsDashboardCreate.useOpsDashboardApi";

export interface CreateRequestActionButtonProps {
  label: ActionTypes;
  onClick: (
    type: ActionTypes,
    comment?: string,
    event?: React.MouseEvent<
      HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | BaseButton | Button | HTMLSpanElement,
      MouseEvent
    >
  ) => void;
  iconName: string;
  disabled?: boolean;
}

const iconClass = css`
  fontSize: 50,
  height: 50,
  width: 50,
  margin: '0 25px'
`;

const CreateRequestActionButton: React.FC<CreateRequestActionButtonProps> = (props) => {
  const theme = useTheme();
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
  const [commentTextFieldValue, setCommentTextFieldValue] = useState("");

  const onAction =
    (type: ActionTypes) =>
    (
      event?: React.MouseEvent<
        HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | BaseButton | Button | HTMLSpanElement,
        MouseEvent
      >
    ) => {
      if (type === ActionTypes.RejectWithComment) {
        toggleHideDialog();
      } else {
        props.onClick(type, undefined, event);
      }
    };

  const onChangeCommentTextFieldValue = React.useCallback(
    (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
      setCommentTextFieldValue(newValue || "");
    },
    []
  );
  return (
    <>
      <DefaultButton
        onClick={onAction(props.label)}
        ariaLabel={props.label}
        title={props.label}
        styles={{
          root: {
            border: "none",
            backgroundColor: "inherit"
          },
          flexContainer: {
            justifyContent: "center",
            flexDirection: "row",
            alignItems: "center",
            alignContent: "center"
          }
        }}
        disabled={!!props.disabled}
      >
        <Icon
          iconName={props.iconName}
          className={iconClass}
          styles={{
            root: {
              color: theme.palette.accent,
              marginRight: "8px"
            }
          }}
        />
        {props.label}
      </DefaultButton>
      {props.label === ActionTypes.RejectWithComment ? (
        <>
          <Dialog
            hidden={hideDialog}
            onDismiss={toggleHideDialog}
            dialogContentProps={{
              type: DialogType.close,
              title: "Reject Action with Comment.",
              closeButtonAriaLabel: "Close"
            }}
            modalProps={{
              titleAriaId: "title-rejection",
              isBlocking: false
            }}
          >
            <TextField
              name="Comment"
              label={"Comment to Reject Request"}
              required={true}
              errorMessage={"Comment is required"}
              multiline={true}
              onChange={onChangeCommentTextFieldValue}
              value={commentTextFieldValue}
              autoAdjustHeight
              rows={2}
            />
            <DialogFooter>
              <PrimaryButton
                onClick={(event) => {
                  toggleHideDialog();
                  props.onClick(ActionTypes.RejectWithComment, commentTextFieldValue, event);
                }}
                text="Reject"
              />
              <DefaultButton onClick={toggleHideDialog} text="Cancel" />
            </DialogFooter>
          </Dialog>
        </>
      ) : null}
    </>
  );
};

export interface OpsDashboardCreateActionsProps {
  apiState: UseOpsDashboardApi;
  onAction: (type: ActionTypes, comment?: string) => void;
}

const OpsDashboardCreateActions: React.FC<OpsDashboardCreateActionsProps> = (props) => {
  const onAction: CreateRequestActionButtonProps["onClick"] = (type, comment) => {
    props.onAction(type, comment);
  };

  return (
    <>
      {Object.keys(props.apiState.actionStates).map((key, i) => {
        const item = props.apiState.actionStates[key as keyof typeof props.apiState.actionStates];
        if (item === ActionStates.Hidden) {
          return null;
        }
        if (key === ActionTypesKeys.Create) {
          return (
            <CreateRequestActionButton
              key={i}
              label={ActionTypes.Create}
              onClick={onAction}
              iconName={"Save"}
              disabled={item === ActionStates.ShowAndReadOnly}
            />
          );
        }
        if (key === ActionTypesKeys.Approve) {
          return (
            <CreateRequestActionButton
              key={i}
              label={ActionTypes.Approve}
              onClick={onAction}
              iconName={"ActivateOrders"}
              disabled={item === ActionStates.ShowAndReadOnly}
            />
          );
        }
        if (key === ActionTypesKeys.RejectWithComment) {
          return (
            <CreateRequestActionButton
              key={i}
              label={ActionTypes.RejectWithComment}
              onClick={onAction}
              iconName={"DeactivateOrders"}
              disabled={item === ActionStates.ShowAndReadOnly}
            />
          );
        }
        if (key === ActionTypesKeys.Save) {
          return (
            <CreateRequestActionButton
              key={i}
              label={ActionTypes.Save}
              onClick={onAction}
              iconName={"Save"}
              disabled={item === ActionStates.ShowAndReadOnly}
            />
          );
        }
        if (key === ActionTypesKeys.SendToApprovers) {
          return (
            <CreateRequestActionButton
              key={i}
              label={ActionTypes.SendToApprovers}
              onClick={onAction}
              iconName={"Send"}
              disabled={item === ActionStates.ShowAndReadOnly}
            />
          );
        }
      })}
    </>
  );
};

OpsDashboardCreateActions.displayName = "OpsDashboardCreateActions";

export default OpsDashboardCreateActions;
