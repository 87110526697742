import React from "react";
import { JemConfiguration } from "../../../../JemConfiguration";
import { LoadingStatus } from "../../../../Shared";
import BulkDetailsFromJeId from "../../../../Shared/components/BulkDetailsFromJeId/BulkDetailsFromJeId";

export interface OtherDetailsTabProps {
  jeId: number;
  loadingStatus: LoadingStatus;
  configuration: JemConfiguration["GeneralLedgerApi"];
}

const OtherDetailsTab: React.FC<OtherDetailsTabProps> = (props) => {
  return (
    <React.Fragment>
      <BulkDetailsFromJeId jeId={props.jeId} loadingStatus={props.loadingStatus} configuration={props.configuration} />
    </React.Fragment>
  );
};

OtherDetailsTab.displayName = "OtherDetailsTab";
export { OtherDetailsTab };
