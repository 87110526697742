import { isValid } from "date-fns";
import numeral from "numeral";
import { ConvertToDefaultTimeString } from "./DateUtilities";
import { SignoffStatus } from "../../GeneralLedger";
export const DEFAULT_NULL_CHARACTER = "—";

export const ApiParsingUtilities = {
  addDefault: (x: string | number | boolean | null, overrideNullCharacter?: string): string => {
    const nullChar = overrideNullCharacter === undefined ? DEFAULT_NULL_CHARACTER : overrideNullCharacter;
    if (typeof x === "number") {
      if (x === 0 || x === -1) {
        return nullChar;
      }
      return `${x}`;
    }
    if (typeof x === "boolean") {
      if (!x) {
        return "false";
      }
      return `true`;
    }
    if (x && x.length !== 0 && x !== "0" && x !== "") {
      return x;
    }
    return nullChar;
  },
  parseDateWithTimeStamp: (x: string | null, overrideNullCharacter?: string): string => {
    const nullChar = overrideNullCharacter === undefined ? DEFAULT_NULL_CHARACTER : overrideNullCharacter;
    if (!x) {
      return nullChar;
    }
    const possibleDate = new Date(Date.parse(x));
    if (!isValid(possibleDate)) {
      return nullChar;
    }
    if (possibleDate.getFullYear() === 1 || possibleDate.getFullYear() <= 1900) {
      return nullChar;
    }
    return ConvertToDefaultTimeString(possibleDate);
  },
  parseDate: (x: string | null, overrideNullCharacter?: string): string => {
    const nullChar = overrideNullCharacter === undefined ? DEFAULT_NULL_CHARACTER : overrideNullCharacter;
    if (!x) {
      return nullChar;
    }
    const possibleDate = new Date(Date.parse(x));
    if (!isValid(possibleDate)) {
      return nullChar;
    }
    if (possibleDate.getFullYear() === 1) {
      return nullChar;
    }
    return ConvertToDefaultTimeString(possibleDate);
  },
  parseDateToIsoString: (x: string | null, overrideNullCharacter?: string): string => {
    const nullChar = overrideNullCharacter === undefined ? DEFAULT_NULL_CHARACTER : overrideNullCharacter;
    if (!x) {
      return nullChar;
    }
    const possibleDate = new Date(Date.parse(x));
    if (!isValid(possibleDate)) {
      return nullChar;
    }
    if (possibleDate.getFullYear() === 1) {
      return nullChar;
    }
    return possibleDate.toISOString();
  },
  parseNumberWithJeStatus: (
    x: number | null | string,
    jeStatus: string | null,
    overrideNullCharacter?: string
  ): string => {
    const nullChar = overrideNullCharacter === undefined ? DEFAULT_NULL_CHARACTER : overrideNullCharacter;
    if (typeof x === "string" && x.length === 0) {
      return nullChar;
    }
    if (!x || !jeStatus) {
      return nullChar;
    }
    if (jeStatus.toLowerCase().indexOf("fail") !== -1) {
      return nullChar;
    }
    const newN = Number(x);
    if (isNaN(newN) || x === -1) {
      return nullChar;
    }
    return `${numeral(newN).format("0,0.00")}`;
  },
  parseNumber: (x: number | null | string, overrideNullCharacter?: string): string => {
    const nullChar = overrideNullCharacter === undefined ? DEFAULT_NULL_CHARACTER : overrideNullCharacter;
    if (!x || (typeof x === "string" && x.length === 0)) {
      return nullChar;
    }
    const newN = Number(x);
    if (isNaN(newN) || x === -1) {
      return nullChar;
    }
    return `${numeral(newN).format("0,0.00")}`;
  },
  parseNumberToNumber: (x: number | null | string, overrideNullCharacter?: number): number => {
    const nullChar = overrideNullCharacter === undefined ? 0 : overrideNullCharacter;
    if (!x || (typeof x === "string" && x.length === 0)) {
      return nullChar;
    }
    const newN = Number(x);
    if (isNaN(newN) || x === -1) {
      return nullChar;
    }
    return newN;
  },
  parseTotal: (x: number | null | string, y: number | null | string, overrideNullCharacter?: string): string => {
    const nullChar = overrideNullCharacter === undefined ? DEFAULT_NULL_CHARACTER : overrideNullCharacter;
    if (typeof x === "string" && x.length === 0) {
      return nullChar;
    }
    if (typeof y === "string" && y.length === 0) {
      return nullChar;
    }
    const newX = Number(x);
    if (isNaN(newX) || x === -1) {
      return nullChar;
    }
    const newY = Number(y);
    if (isNaN(newY) || y === -1) {
      return nullChar;
    }
    return `${numeral(newX - Math.abs(newY)).format("0,0.00")}`;
  },
  parseFiscalYearAndFiscalPeriod: (type: "fy" | "fp", x: null | string, overrideNullCharacter?: string): string => {
    const nullChar = overrideNullCharacter === undefined ? DEFAULT_NULL_CHARACTER : overrideNullCharacter;
    if (!x || x.length === 0 || x.indexOf("/") === -1) {
      return nullChar;
    }
    const [fy, fp] = x.split("/");
    return type === "fy" ? fy : fp;
  },
  toDecimal: (number: number, precision = 2): string => {
    return new Intl.NumberFormat("en-US", {
      style: "decimal",
      minimumFractionDigits: precision,
      maximumFractionDigits: precision
    }).format(number);
  },
  parseJeHeader: (x: string | null, overrideNullCharacter?: string): string => {
    const nullChar = overrideNullCharacter === undefined ? DEFAULT_NULL_CHARACTER : overrideNullCharacter;

    if (!x || x.length === 0) {
      return nullChar;
    }

    return x.length <= 25 ? x : x.substring(0, 25);
  },
  parseSAPTransAmountToNumber: (x: number | null | string, overrideNullCharacter?: number): number => {
    const nullChar = overrideNullCharacter === undefined ? 0 : overrideNullCharacter;
    if (!x || (typeof x === "string" && x.length === 0)) {
      return nullChar;
    }
    const newN = Number(x);
    if (isNaN(newN)) {
      return nullChar;
    }
    return newN;
  },
  parseNumberToSignoffStatus: (x: number | null | string, overrideNullCharacter?: string): string => {
    const nullChar = overrideNullCharacter ?? "";
    if (!x || (typeof x === "string" && x.length <= 0)) {
      return nullChar;
    }

    const newN = Number(x);
    if (isNaN(newN)) {
      return nullChar;
    }

    return SignoffStatus[newN];
  },
  parseStatAmtNumberToNumber: (x: number | null | string, overrideNullCharacter?: number): number => {
    const nullChar = overrideNullCharacter === undefined ? 0 : overrideNullCharacter;
    if (!x || (typeof x === "string" && x.length === 0)) {
      return nullChar;
    }
    const newN = Number(x);
    if (isNaN(newN)) {
      return nullChar;
    }
    return newN;
  }
};
