import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getStateObjects } from "../utilities/UrlHelpers";

export function useSearchStateFromLocation<T>(
  constructor: { new (arg: T): T },
  validatorFunction: (obj: any) => boolean
): T | undefined {
  const location = useLocation();

  const [searchState, setSearchState] = useState<T | undefined>(undefined);

  useEffect(() => {
    try {
      const stateObjectsFromLocation = getStateObjects(location);
      if (
        stateObjectsFromLocation &&
        stateObjectsFromLocation.state &&
        "searchState" in stateObjectsFromLocation.state &&
        validatorFunction(stateObjectsFromLocation.state.searchState)
      ) {
        const previousNavigationState = new constructor(stateObjectsFromLocation.state.searchState as T);
        setSearchState(previousNavigationState);
        window.history.replaceState({}, document.title);
        location.state = null;
      }
    } catch (e) {
      // do nothing
    }
  }, []);

  return searchState;
}
