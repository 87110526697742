import * as React from "react";
import { CommandBar, IButtonProps, ICommandBarItemProps, css } from "@fluentui/react";

const overflowProps: IButtonProps = { ariaLabel: "More commands" };

export interface IReconProcessCommandBarItemsProps {
  onStartRecon: () => void;
  onCheckReconStatus: () => void;
  onGetMissingJEs: () => void;
  onUpdateSysUsers: () => void;
  onGetReconFile: () => void;
  onSyncJE: () => void;
}

const ReconProcessCommandBarItems = (props: IReconProcessCommandBarItemsProps): ICommandBarItemProps[] => {
  return [
    {
      key: "startRecon",
      text: "Start Recon",
      iconProps: { iconName: "PlaybackRate1x" },
      onClick: () => props.onStartRecon()
    },
    {
      key: "checkReconStatus",
      text: "Check Recon Status",
      iconProps: { iconName: "CheckList" },
      onClick: () => props.onCheckReconStatus()
    },
    {
      key: "getMissingJEs",
      text: "Get Missing JEs",
      iconProps: { iconName: "EntitlementRedemption" },
      onClick: () => props.onGetMissingJEs()
    },
    {
      key: "updateSysUsers",
      text: "Update Sys Users",
      iconProps: { iconName: "UserSync" },
      onClick: () => props.onUpdateSysUsers()
    },
    {
      key: "getReconFile",
      text: "Get Recon File",
      iconProps: { iconName: "FileRequest" },
      onClick: () => props.onGetReconFile()
    },
    {
      key: "syncJE",
      text: "Sync JE",
      iconProps: { iconName: "Sync" },
      onClick: () => props.onSyncJE()
    }
  ];
};

export const ReconProcessCommandBar: React.FunctionComponent<{
  onStartRecon: () => void;
  onCheckReconStatus: () => void;
  onGetMissingJEs: () => void;
  onUpdateSysUsers: () => void;
  onGetReconFile: () => void;
  onSyncJE: () => void;
  // fiscalYear: string;
  // fiscalPeriod: string;
}> = (props) => {
  return (
    <div
      className={css`
        width: 100%;
        min-height: 1px;
        box-sizing: content-box;
        overflow: hidden;
      `}
    >
      <CommandBar
        items={ReconProcessCommandBarItems({
          onStartRecon: props.onStartRecon,
          onCheckReconStatus: props.onCheckReconStatus,
          onGetMissingJEs: props.onGetMissingJEs,
          onUpdateSysUsers: props.onUpdateSysUsers,
          onGetReconFile: props.onGetReconFile,
          onSyncJE: props.onSyncJE
        })}
        styles={{
          root: {
            paddingLeft: 0
          }
        }}
        overflowButtonProps={overflowProps}
        ariaLabel="Recon actions"
        primaryGroupAriaLabel="Viewable actions"
        farItemsGroupAriaLabel="More actions"
      />
    </div>
  );
};
