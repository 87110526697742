import React, { useCallback, useContext, useEffect, useState } from "react";
import { DefaultButton, Label, TextField } from "@fluentui/react";
import { css } from "@emotion/css";
import { OpsDashboardApi, UserContext, LoadingStatus, getRequest } from "@jem/components";

interface RowCountProps {
  rowKey: string;
  configuration: OpsDashboardApi;
}

interface RowCountResponseItem {
  affectedRows: number;
  sQlQuery: string;
}

const RowCount: React.FC<RowCountProps> = (props) => {
  const userCtx = useContext(UserContext);
  const [counts, setCounts] = useState<string>("");
  const [status, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.Idle);

  const fetchNewCount = useCallback(async () => {
    setLoadingStatus(LoadingStatus.Pending);
    const endpointForCount = `${props.configuration.baseApiUrl}${props.configuration.endpoints.rowCount.replace(
      "{requestId}",
      `${props.rowKey}`
    )}`;
    const rowCount = await getRequest<RowCountResponseItem[]>(endpointForCount, userCtx.accessToken);
    if (Array.isArray(rowCount)) {
      let newCounts = "";
      for (const [index, sqlCount] of rowCount.entries()) {
        newCounts = `${newCounts === "" ? newCounts : `${newCounts}\n`}SQL STATEMENT ${index + 1}\n${
          sqlCount.sQlQuery
        }\nEstimate Rows Affected = ${sqlCount.affectedRows}\n`;
      }
      setCounts(newCounts);
    }
  }, [props]);

  useEffect(() => {
    const fetchCount = async () => {
      try {
        await fetchNewCount();
        setLoadingStatus(LoadingStatus.Resolved);
      } catch (e) {
        setLoadingStatus(LoadingStatus.Rejected);
      }
    };
    fetchCount();
  }, [props.rowKey]);
  return (
    <div
      className={css`
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;
        align-items: center;
      `}
    >
      <div
        className={css`
          display: flex;
          flex-direction: row;
          gap: 16px;
          width: 100%;
          align-items: flex-end;
        `}
      >
        <Label
          styles={{
            root: {
              flexGrow: "1"
            }
          }}
        >
          SQL Affected Row Estimates
        </Label>
        <DefaultButton
          styles={{
            root: {
              flexGrow: "1"
            }
          }}
          onClick={async () => {
            try {
              await fetchNewCount();
              setLoadingStatus(LoadingStatus.Resolved);
            } catch (e) {
              setLoadingStatus(LoadingStatus.Rejected);
            }
          }}
          disabled={status === LoadingStatus.Pending}
        >
          Update Estimates
        </DefaultButton>
      </div>
      <TextField
        name="sqlCounts"
        value={counts}
        readOnly={true}
        styles={{
          root: {
            width: "100%"
          }
        }}
        multiline
        autoAdjustHeight
        rows={2}
        disabled={status === LoadingStatus.Pending}
      />
    </div>
  );
};

RowCount.displayName = "RowCount";

export default RowCount;
