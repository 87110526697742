import { BaseButton, Button, Dropdown, IDropdownOption, PrimaryButton } from "@fluentui/react";
import {
  DomainDataObjects,
  IAliasTypeRef,
  IFiscalPeriodsRef,
  PosterOrReviewerChoice,
  FiscalPeriodsDropdown,
  ForceMaxWidthAndHeightOnDropdownStyle,
  RefTextField,
  RefTextFieldRef,
  SearchOpsDetailDropdown,
  JemConfiguration,
  UserContext,
  SearchOpsDetailDropdownRef,
  JEMContext,
  LoadingStatus,
  DomainDataEnum,
  JemNotification
} from "@jem/components";
import React, { useContext, useEffect, useRef, useState } from "react";
import { v4 } from "uuid";
import { fiscalYearAndFiscalPeriodForSearch } from "../GLSearch/GLSearchForm";
import { GLBatchSearchCriteria } from "./GLBatchSearch.types";

export interface IDashboardSelectorProps {
  triggerRefreshFn: (searchState: GLBatchSearchCriteria, procStatusMessages: JemNotification) => void;
  domainData: Pick<DomainDataObjects, DomainDataEnum.FiscalPeriods>;
  searchState: GLBatchSearchCriteria | undefined;
  configuration: JemConfiguration["GeneralLedgerApi"];
}

const id = v4();

export const GLBatchSearchOptions: React.FC<IDashboardSelectorProps> = (props) => {
  const [aliasType, setAliasType] = useState<"P" | "R">("P");
  const { user } = useContext(UserContext);
  const { initInfo } = useContext(JEMContext);

  const [fiscalYearsLoading, setFiscalYearsLoading] = useState(true);
  const [fiscalPeriodsLoading, setfiscalPeriodsLoading] = useState(true);

  const aliasTypeRef = useRef<IAliasTypeRef>(null);
  const fiscalPeriodRef = useRef<IFiscalPeriodsRef>(null);
  const userAliasRef = useRef<RefTextFieldRef>(null);
  const batchNameRef = useRef<RefTextFieldRef>(null);
  const opsDetailRef = useRef<SearchOpsDetailDropdownRef>(null);

  const [fiscalYearSettings, _setfiscalYearSettings] = useState<{
    fiscalYearsForDropdown: IDropdownOption[];
    currentFiscalYear: number;
    currentFiscalPeriod: number;
  }>(() => {
    return fiscalYearAndFiscalPeriodForSearch(props.domainData);
  });
  const [fiscalYear, setFiscalYear] = useState(fiscalYearSettings.currentFiscalYear);

  useEffect(
    function loadSearchState() {
      if (initInfo.status !== LoadingStatus.Resolved) return;

      setFiscalYearsLoading(false);
      setfiscalPeriodsLoading(false);

      if (props.searchState !== undefined) {
        setFiscalYear(props.searchState.FiscalYear);

        if (userAliasRef.current) {
          userAliasRef.current.setInitialValue(props.searchState.Alias);
        }
        setAliasType(props.searchState.AliasType || "P");
        if (batchNameRef.current) {
          batchNameRef.current.setInitialValue(props.searchState.BatchName);
        }
        if (fiscalPeriodRef.current) {
          fiscalPeriodRef.current.setInitialFiscalPeriods(props.searchState.FiscalMonth);
        }
        if (opsDetailRef.current && props.searchState.OpsDetailId) {
          opsDetailRef.current.setInitialOpsDetail(props.searchState.OpsDetailId);
        }
      } else {
        if (userAliasRef.current) {
          userAliasRef.current.setInitialValue(user.alias);
        }
        const currentFp = fiscalYearSettings.currentFiscalPeriod;
        const lastFp = currentFp - 1 > 0 ? currentFp - 1 : 12;
        if (lastFp === 12) {
          setFiscalYear(fiscalYearSettings.currentFiscalYear - 1);
        }
        if (fiscalPeriodRef.current) {
          fiscalPeriodRef.current.setInitialFiscalPeriods([lastFp]);
        }
      }
    },
    [props.searchState, initInfo.status]
  );

  const submit = async (
    ev: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | BaseButton | Button | HTMLSpanElement>
  ) => {
    ev.preventDefault();
    if (
      userAliasRef.current &&
      aliasTypeRef.current &&
      fiscalPeriodRef.current &&
      opsDetailRef.current &&
      batchNameRef.current
    ) {
      const Aliases = userAliasRef.current.getValue();
      const AliasType = aliasTypeRef.current.getAliasType();
      const FiscalPeriods = fiscalPeriodRef.current.getFiscalPeriods();
      const OpsDetail = opsDetailRef.current.getOpsDetail();
      const BatchName = batchNameRef.current.getValue();

      const currentState = new GLBatchSearchCriteria({
        Alias: Aliases,
        AliasType: AliasType,
        FiscalMonth: FiscalPeriods,
        FiscalYear: fiscalYear,
        OpsDetailId: OpsDetail,
        BatchName
      });

      props.triggerRefreshFn(currentState, {
        subjectHeader: " ",
        summaryBodyText: " ",
        type: "Information"
      });
    }
  };

  const onChangeFiscalYear = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void => {
    if (option) {
      setFiscalYear(option.key as number);
    }
  };

  return (
    <>
      <div className="ms-Grid" dir="ltr">
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg5 ms-xl5 ms-xxl3">
            <RefTextField customRef={batchNameRef} label={"Batch Name:"} />
          </div>
          <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg5 ms-xl5 ms-xxl3">
            <SearchOpsDetailDropdown
              configuration={props.configuration}
              customRef={opsDetailRef}
              styles={{
                root: {
                  width: "100%"
                }
              }}
            />
          </div>
          <div className="ms-Grid-col ms-sm12 ms-md2 ms-lg2 ms-xl2 ms-xxl2 ms-hiddenMdDown">
            <PrimaryButton
              text="Search"
              onClick={submit}
              allowDisabledFocus
              disabled={false}
              checked={false}
              styles={{
                root: {
                  marginTop: "28px",
                  width: "100%"
                }
              }}
            />
          </div>
        </div>
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg4 ms-xl3 ms-xxl2">
            <PosterOrReviewerChoice
              id={id}
              initialSelected={aliasType}
              customRef={aliasTypeRef}
              options={[
                {
                  key: "P",
                  text: "Poster"
                },
                {
                  key: "R",
                  text: "Reviewer"
                }
              ]}
            />
          </div>
          <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3 ms-xl3 ms-xxl2">
            <RefTextField label={"User Alias:"} customRef={userAliasRef} />
          </div>
          <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg4 ms-xl4 ms-xxl2">
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6 ms-xl6 ms-xxl6">
                <Dropdown
                  label="Fiscal Year:"
                  selectedKey={fiscalYear}
                  onChange={onChangeFiscalYear}
                  options={fiscalYearSettings.fiscalYearsForDropdown}
                  disabled={fiscalYearsLoading}
                  styles={ForceMaxWidthAndHeightOnDropdownStyle({
                    width: "100%",
                    minWidth: "75px",
                    height: "300px",
                    maxWidth: "100%"
                  })}
                />
              </div>
              <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6 ms-xl6 ms-xxl6">
                <FiscalPeriodsDropdown
                  ref={fiscalPeriodRef}
                  loading={fiscalPeriodsLoading}
                  styles={{
                    width: "100%",
                    minWidth: "85px",
                    height: "300px",
                    maxWidth: "100%"
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12 ms-md6 ms-hiddenLgUp">
            <PrimaryButton
              text="Search"
              onClick={submit}
              allowDisabledFocus
              disabled={false}
              checked={false}
              styles={{
                root: {
                  width: "100%",
                  marginTop: "16px"
                }
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

GLBatchSearchOptions.displayName = "GLBatchSearchOptions";
export default GLBatchSearchOptions;
