import {
  Checkbox,
  DefaultButton,
  IButtonStyles,
  IconButton,
  IContextualMenuItem,
  IContextualMenuItemProps,
  IContextualMenuProps
} from "@fluentui/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { RuntimeError } from "../../utilities/ErrorHelpers";

export interface IDropdownContextualMenu {
  available: string[] | null;
  selected: string[] | null;
  name: string;
  onSelectionChange?: (newSelection: string[] | null) => void;
  singleSelection: boolean;
  styles?: IButtonStyles | null;
  useIconButton: boolean;
}

export const calculateCheckboxState = (
  available: string[] | null,
  selected: string[] | null,
  singleSelection: boolean
): boolean[] => {
  if (!available) {
    return [];
  }
  if (!selected) {
    if (singleSelection) {
      return available.map((_, i) => (i === 0 ? true : false));
    }
    return available.map(() => true);
  } else {
    if (singleSelection && selected.length > 1) {
      throw new RuntimeError("Only one can be selected at one time for single selection.");
    }
    const newSelected = Array.from({ length: available.length }, () => false);
    for (const opt of selected) {
      const index = available.indexOf(opt);
      if (index !== -1) {
        if (index === 0 && !singleSelection) {
          return available.map(() => true);
        } else {
          newSelected[index] = !newSelected[index];
        }
      }
    }
    return newSelected;
  }
};

// Base Function to handle toggle in Dropdown, must work like excel
// if it is a single selection then one index (and only one) must be true
// if it is a multi select index
//    if it is the first index then it is "All"
//      - if all are true set them all to false
//      - otherwise set all of them to true
//    if it is another index then just toggle that
//      if all items were manually selected an All is false, make sure is true
//      if All was selected and some were deselected, make sure it is false
export const onClickMultiSelectHandler = (
  toggleIndex: number,
  singleSelection: boolean,
  currentStates: boolean[]
): boolean[] => {
  if (singleSelection) {
    const newCheckedStates = currentStates.map(() => false);
    newCheckedStates[toggleIndex] = true;
    return newCheckedStates;
  } else {
    if (toggleIndex === 0) {
      if (currentStates.every((x) => x)) {
        const newStates = currentStates.map(() => false);
        return newStates;
      } else {
        const newStates = currentStates.map(() => true);
        return newStates;
      }
    } else {
      currentStates[toggleIndex] = !currentStates[toggleIndex];
      if (currentStates.slice(1).some((x) => !x)) {
        currentStates[0] = false;
      } else {
        currentStates[0] = true;
      }
      return currentStates;
    }
  }
};

// returning null means all the elements are selected
// returning an empty array means no elements are selected
export const onSelectionChangeHandler = (
  singleSelection: boolean,
  available: string[],
  newState: boolean[]
): null | string[] => {
  if (newState.every((x) => x) || (singleSelection && newState[0])) {
    return null;
  } else {
    const newSelection = newState
      .map((x, i) => ({ index: i, value: x }))
      .filter((x) => x.value)
      .map((x) => available[x.index]);
    return newSelection;
  }
};

export const dropdownButtonStyles: IButtonStyles = {
  root: {
    backgroundColor: "transparent",
    border: " none",
    margin: "0px !important",
    selectors: {
      ":active": {
        margin: "0px"
      }
    }
  },
  label: {
    fontWeight: "normal",
    margin: "0 8px 0 4px",
    lineHeight: "20px"
  },
  menuIcon: {
    margin: "0 4px 0 8px",
    fontWeight: "normal",
    fontSize: "0.7em",
    lineHeight: "18px"
  }
};

const DropdownContextualMenu: React.FunctionComponent<IDropdownContextualMenu> = (props) => {
  const [checkedStates, setCheckedStates] = useState<boolean[]>(
    calculateCheckboxState(props.available, props.selected, props.singleSelection)
  );
  const onClick = useCallback(
    (ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, item?: IContextualMenuItem) => {
      if (item) {
        if (ev) {
          ev.preventDefault();
          ev.stopPropagation();
        }
        const index = Number(item.key);
        const newState = onClickMultiSelectHandler(index, props.singleSelection, checkedStates);
        if (props.onSelectionChange && props.available) {
          const newSelection = onSelectionChangeHandler(props.singleSelection, props.available, newState);
          props.onSelectionChange(newSelection);
        }
        setCheckedStates(newState);
      }
    },
    [checkedStates, props]
  );

  const onCheckboxChange = useCallback(
    (index: number) => {
      return (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        // Prevent the dropdown from closing when the Checkbox is clicked
        ev?.preventDefault();
        ev?.stopPropagation();

        const newState = [...checkedStates];
        newState[index] = checked ?? false;

        if (props.onSelectionChange && props.available) {
          const newSelection = onSelectionChangeHandler(props.singleSelection, props.available, newState);
          props.onSelectionChange(newSelection);
        }

        setCheckedStates(newState);
      };
    },
    [checkedStates, props]
  );

  useEffect(() => {
    const newCheckedState = calculateCheckboxState(props.available, props.selected, props.singleSelection);
    setCheckedStates(newCheckedState);
  }, [props.available, props.selected, props.singleSelection]);

  const menuProps = useMemo<IContextualMenuProps>(
    () => ({
      shouldFocusOnMount: true,
      styles: {
        subComponentStyles: {},
        root: {
          backgroundColor: "transparent",
          fontWeight: "normal"
        }
      },
      items: props.available
        ? props.available.map((option, i) => {
            return {
              key: `${i}`,
              text: option === null || option === undefined ? "" : option.toString(),
              onClick,
              onRenderContent: (props: IContextualMenuItemProps) => {
                return <Checkbox label={props.item.text} checked={checkedStates[i]} onChange={onCheckboxChange(i)} />;
              }
            };
          })
        : []
    }),
    [props.available, onClick, checkedStates]
  );

  return (
    <>
      {props.useIconButton ? (
        <IconButton
          iconProps={{ iconName: "CalendarSettings" }}
          menuProps={menuProps}
          styles={dropdownButtonStyles}
          title={props.name}
        />
      ) : (
        <DefaultButton
          styles={props.styles === null ? undefined : props.styles ? props.styles : dropdownButtonStyles}
          text={props.name}
          menuProps={menuProps}
        />
      )}
    </>
  );
};

DropdownContextualMenu.displayName = "DropdownContextualMenu";
export { DropdownContextualMenu };
