import { FormikContextType } from "formik";
import React from "react";
import { AsyncSubject } from "rxjs";
import { AliasChangeModalRef } from "../../Shared/components/AliasChangeModal/AliasChangeModal";
import { GenericDialogRef } from "../../Shared/components/GenericDialog/GenericDialog";
import { DomainDataEnum, DomainDataObjects } from "../../Shared/contexts/JEMContext/JEMContext.domainData.types";
import { correlateAndGetFiscalMonthAndFiscalYearWithDomainData } from "../components";
import { GLBatchFormikState } from "../components/GLBatch/GLBatch.types";
import { GLBatchDetailsProps } from "../components/GLBatch/GLBatchTabs/GLBatch.Tab.BatchDetails";
import { GLCreateFormikState } from "../components/GLCreate/GLCreateForm.types";
import { getReversalDate, GLCreateJeDetailsProps } from "../components/GLCreate/GLCreateTabs/GLCreate.Tab.JeDetails";
import {
  AliasUtilities,
  FiscalPeriodStringTransformations,
  getCalendayMonthAndYearFromFiscalMonthAndFiscalYear
} from "../utilities";
import { EmptyRefGuid } from "../utilities/EmptyRefGuid";
import { FCWChecklistModel } from "../utilities/FetchUserChecklists";

export const EMPTY_CHECKLIST: FCWChecklistModel = {
  checklistName: "NOT APPLICABLE",
  checklistId: -1,
  checklistRefGuid: EmptyRefGuid(),
  postingPeriod: "",
  opsDetailsName: "",
  backupReviewers: "",
  backupPosters: ""
};

export function OnChangeChecklistOrPeriodHandlers(
  domainData: Pick<DomainDataObjects, DomainDataEnum.FiscalPeriods>,
  formik: FormikContextType<GLCreateFormikState> | FormikContextType<GLBatchFormikState>,
  isBatch: boolean,
  genericDialogRef: React.RefObject<GenericDialogRef>,
  aliasChangeModalRef: React.RefObject<AliasChangeModalRef>
): {
  onFiscalPeriodChange: GLCreateJeDetailsProps["onFiscalPeriodChange"] | GLBatchDetailsProps["onFiscalPeriodChange"];
  onChecklistChange: GLCreateJeDetailsProps["onChecklistChange"] | GLBatchDetailsProps["onChecklistChange"];
  onPostingDateChange: GLCreateJeDetailsProps["onPostingDateChange"];
} {
  return {
    onPostingDateChange: async (confirmationForDetailsTab$: AsyncSubject<boolean>, _postingPeriodDate: Date) => {
      if (!genericDialogRef.current) return;

      const userConfirmation$ = genericDialogRef.current.getUserSubmission({
        title: "Confirm Posting Date",
        subText: "Changing the Posting Date will change the Fiscal Period. Do you want to continue?",
        approveButtonText: "Yes",
        cancelButtonText: "No"
      });
      userConfirmation$.subscribe(async (userConfirmation) => {
        confirmationForDetailsTab$.next(userConfirmation);
        confirmationForDetailsTab$.complete();
      });
    },
    onFiscalPeriodChange: async (confirmationForDetailsTab$: AsyncSubject<boolean>, fiscalPeriod: string) => {
      if (!genericDialogRef.current) return;

      const { fiscalYear, fiscalMonth } =
        FiscalPeriodStringTransformations.FiscalPeriodStringToFiscalYearAndFiscalMonth(fiscalPeriod);
      const { calendarMonth, calendarYear } = getCalendayMonthAndYearFromFiscalMonthAndFiscalYear(
        domainData,
        fiscalMonth,
        fiscalYear
      );

      const newPostingDate = new Date(`${calendarMonth}/15/${calendarYear}`);
      // set new reversal date to first day of next month, handle if month is 12
      const fp = correlateAndGetFiscalMonthAndFiscalYearWithDomainData(
        fiscalMonth,
        fiscalYear,
        fiscalPeriod,
        domainData
      );
      const newReversalDate = getReversalDate(fp, domainData, newPostingDate);

      if (formik.values.detailsTabSelectedChecklist.checklistRefGuid !== EmptyRefGuid() || !isBatch) {
        // find formik.values.detailsTabChecklistRefGuid in formik.values.detailsTabCurrentChecklists
        const checklist = formik.values.detailsTabCurrentChecklists.find(
          (checklist) => checklist.checklistRefGuid === formik.values.detailsTabSelectedChecklist.checklistRefGuid
        );

        let message = "Changing the fiscal period will reset the checklists";
        if (checklist && formik.values.detailsTabSelectedChecklist.checklistRefGuid !== EmptyRefGuid()) {
          message = `Changing the fiscal period will reset checklist ${checklist.checklistName}`;
        }
        const jeExclusiveMessage = isBatch ? "" : " and modify the Posting Date";
        message = `${message}${jeExclusiveMessage}. Do you want to continue?`;
        const userConfirmation$ = genericDialogRef.current.getUserSubmission({
          title: "Reset Checklist",
          subText: message,
          approveButtonText: "Yes",
          cancelButtonText: "No"
        });
        userConfirmation$.subscribe(async (userConfirmation) => {
          confirmationForDetailsTab$.next(userConfirmation);
          confirmationForDetailsTab$.complete();
          if (userConfirmation) {
            formik.setFieldValue("detailsTabSelectedChecklist", EMPTY_CHECKLIST);
            if (!isBatch) {
              formik.setFieldValue("detailsTabPostingDate", newPostingDate);
              formik.setFieldValue("detailsTabReversalDate", newReversalDate);
            }
          }
        });
      } else {
        confirmationForDetailsTab$.next(true);
        confirmationForDetailsTab$.complete();
        formik.setFieldValue("detailsTabSelectedChecklist", EMPTY_CHECKLIST);
        if (!isBatch) {
          formik.setFieldValue("detailsTabPostingDate", newPostingDate);
          formik.setFieldValue("detailsTabReversalDate", newReversalDate);
        }
      }
    },
    onChecklistChange: async (
      confirmationForDetailsTab$: AsyncSubject<boolean>,
      dropdownChecklist: FCWChecklistModel
    ) => {
      const reviewersAreEqual = AliasUtilities.compareArrayWithString(
        formik.values.reviewersTabAdditionalReviewers,
        dropdownChecklist.backupReviewers
      );
      const postersAreEqual = AliasUtilities.compareArrayWithString(
        formik.values.postersTabBackupPosters,
        dropdownChecklist.backupPosters
      );
      if (aliasChangeModalRef.current && (!reviewersAreEqual || !postersAreEqual)) {
        const newPosters = dropdownChecklist.backupPosters.split(";").filter((poster) => poster.trim() !== "");
        const newReviewers = dropdownChecklist.backupReviewers.split(";").filter((poster) => poster.trim() !== "");
        const previousPosters = formik.values.postersTabBackupPosters.filter((poster) => poster.trim() !== "");
        const previousReviewers = formik.values.reviewersTabAdditionalReviewers.filter(
          (poster) => poster.trim() !== ""
        );
        const userConfirmation$ = aliasChangeModalRef.current.getUserSubmission({
          newPosters,
          newReviewers,
          previousPosters,
          previousReviewers
        });
        userConfirmation$.subscribe((userConfirmation) => {
          if (userConfirmation) {
            formik.setFieldValue("reviewersTabAdditionalReviewers", newReviewers);
            formik.setFieldValue("postersTabBackupPosters", newPosters);
          }
          confirmationForDetailsTab$.next(userConfirmation);
          confirmationForDetailsTab$.complete();
        });
      } else {
        confirmationForDetailsTab$.next(true);
        confirmationForDetailsTab$.complete();
      }
    }
  };
}
