import React from "react";
import { DefaultButton, IContextualMenuProps, BaseButton, Button } from "@fluentui/react";
import { OpsDashboardActionElementButton, OpsDashboardActionElementContextualMenu } from "./Actions.types";

interface INestedActionsProps {
  action: OpsDashboardActionElementContextualMenu;
  onClick: (
    action: OpsDashboardActionElementButton,
    event:
      | React.MouseEvent<
          HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | BaseButton | Button | HTMLSpanElement,
          MouseEvent
        >
      | React.KeyboardEvent<HTMLElement>
      | undefined
  ) => void;
}

const getMenuProps = (props: INestedActionsProps): IContextualMenuProps => {
  return {
    items: props.action.actions.map((singleAction, i) => ({
      key: `${i}`,
      text: singleAction.name,
      onClick: (ev) => {
        props.onClick(singleAction, ev);
      }
    }))
  };
};

const NestedActions: React.FC<INestedActionsProps> = (props) => {
  return <DefaultButton text={props.action.name} menuProps={getMenuProps(props)} />;
};

NestedActions.displayName = "OpsDashboardNestedActions";

export default NestedActions;
