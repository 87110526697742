import { ErrorMessages } from "./ErrorHelper.messages";

export class ErrorHelper {
  static getMessage(messageCode: keyof typeof ErrorMessages | null): string {
    if (!messageCode) {
      return ErrorMessages["ERR1130"];
    }
    if (messageCode in ErrorMessages) {
      return ErrorMessages[messageCode];
    }

    return ErrorMessages["ERR1130"];
  }
}

export interface CommonApiErrorResponse {
  status: boolean;
  message: string;
  errors: Error[];
  changes: Change[];
}

export interface Error {
  code: string;
  message: string;
}

export interface Change {
  jeId: number;
  refGuid: string;
  jeStatus: null;
  oldJeStatus: number;
  errorCode: string;
}
