import { css } from "@emotion/css";
import {
  DirectionalHint,
  ExpandingCardMode,
  HoverCard,
  Icon,
  IExpandingCardProps,
  Link,
  mergeStyles,
  mergeStyleSets,
  TooltipHost
} from "@fluentui/react";
import numeral from "numeral";
import React from "react";
import { For } from "react-loops";
import { globalColors } from "../../../globalCss/styles";
import { IExtendedColumn } from "../../../Shared/components/DashboardGrid/DashboardGrid.types";
import { DEFAULT_NULL_CHARACTER } from "../../../Shared/utilities/ApiParsingUtilities";
import { AutomatedJeDashboardElement, JeErrorData } from "./AutomatedJeDashboard.requests";

interface IRenderCompactCardProp {
  message: JeErrorData;
}

const RenderCompactCard: React.FC<IRenderCompactCardProp> = (props) => {
  return (
    <div
      className={css`
        margin-top: 5px;
        margin-left: 5px;
        margin-right: 5px;
        margin-bottom: 5px;
      `}
    >
      Header Errors: {props.message.jeHeaderError}
    </div>
  );
};
const iconClass = mergeStyles({
  fontSize: 10,
  height: 10,
  width: 10,
  marginLeft: 5
});
const classNames = mergeStyleSets({
  deepSkyBlue: [{ color: "deepskyblue" }, iconClass]
});
export const OnHoverErrors: React.FC<{ item: AutomatedJeDashboardElement }> = ({ item }) => {
  const targetElementRef: React.RefObject<HTMLDivElement> = React.useRef<HTMLDivElement>(null);
  const expandingCardProps: IExpandingCardProps = {
    onRenderCompactCard: (item: JeErrorData | null): JSX.Element => {
      if (!item) {
        return <></>;
      }
      return <RenderCompactCard message={item} />;
    },
    onRenderExpandedCard: (item: JeErrorData | null): JSX.Element => {
      if (!item) {
        return <></>;
      }
      const lineItemErrors = item.jeLineItemErrors ? item.jeLineItemErrors : null;
      return (
        <ul>
          <For of={lineItemErrors}>
            {(item) => (
              <li>
                <div>Line Number: {item.lineNumber}</div>
                <div>Description: {item.description}</div>
              </li>
            )}
          </For>
        </ul>
      );
    },
    renderData: item.jeSAPErrorInfo,
    mode: ExpandingCardMode.compact,
    directionalHint: DirectionalHint.rightTopEdge,
    gapSpace: 16,
    calloutProps: {
      isBeakVisible: true
    }
  };
  return (
    <div ref={targetElementRef} data-is-focusable>
      <HoverCard expandingCardProps={expandingCardProps} instantOpenOnClick target={targetElementRef.current}>
        {item.jeStatus} <Icon aria-label="Error Icon" iconName="Error" className={classNames.deepSkyBlue} />
      </HoverCard>
    </div>
  );
};

export const getAutomatedDashboardColumns =
  (onRenderDocumentNumber?: (items: AutomatedJeDashboardElement) => JSX.Element) => (): IExtendedColumn[] => {
    const cols: IExtendedColumn[] = [
      {
        key: "filename",
        name: "Indicator",
        fieldName: "fileName",
        type: "string",
        minWidth: 75
      },
      {
        key: "DocumentNumber",
        name: "Doc Number",
        fieldName: "jeDocNum",
        type: "string",
        minWidth: 75,
        onRender: onRenderDocumentNumber
      },
      {
        key: "DueDate",
        name: "Review Due Date",
        fieldName: "dueDate",
        type: "string",
        minWidth: 70
      },
      {
        key: "submittedTime",
        name: "Submitted Time",
        fieldName: "submittedTime",
        type: "string",
        minWidth: 70,
        sortDescendingAriaLabel: "Sorted by Newer Date First",
        onRender: (item: AutomatedJeDashboardElement) => {
          if (item.submittedTime === DEFAULT_NULL_CHARACTER) {
            return DEFAULT_NULL_CHARACTER;
          }
          //on display we need the date to be without time stamp
          return new Date(Date.parse(item.submittedTime)).toLocaleDateString("en-US");
        }
      },
      {
        key: "Status",
        name: "Status",
        fieldName: "jeStatus",
        type: "string",
        minWidth: 100,
        onRender: (item: AutomatedJeDashboardElement) => {
          if (item.jeStatus === "Draft" || item.jeStatus === "Failed in SAP") {
            return <OnHoverErrors item={item}></OnHoverErrors>;
          } else {
            return <TooltipHost content={item.jeStatus}>{item.jeStatus}</TooltipHost>;
          }
        }
      },
      {
        key: "FollowUp",
        name: "Follow Up",
        type: "string",
        minWidth: 30,
        onRender: (item: AutomatedJeDashboardElement) => {
          const baseUrl = window.location.origin;
          const url =
            item.postingType === "Regular" && item.jeStatus === "Failed in SAP"
              ? `/gl/create?RefGuid=${item.refguid}`
              : item.postingType === "Batch"
              ? `/gl/batch?RefGuid=${item.refguid}`
              : `/gl/details?jeid=${item.jeId}`;
          const jemUrl = baseUrl + url;
          return (
            <Link href={jemUrl} target="_blank">
              <Icon iconName="OpenInNewTab" />
            </Link>
          );
        }
      },
      {
        key: "Variance",
        name: "Variance",
        fieldName: "differenceAmount",
        type: "number",
        minWidth: 75,
        onRender: (gridItem: AutomatedJeDashboardElement) => {
          if (gridItem.differenceAmount === DEFAULT_NULL_CHARACTER) {
            return gridItem.differenceAmount;
          } else {
            const n = numeral(gridItem.differenceAmount).value();
            if (n === 0) {
              return gridItem.differenceAmount;
            }
            return (
              <TooltipHost content={<span style={{ color: globalColors.fontError }}>{gridItem.differenceAmount}</span>}>
                <span style={{ color: globalColors.fontError }}>{gridItem.differenceAmount}</span>
              </TooltipHost>
            );
          }
        }
      },
      {
        key: "SAPDebitAmount",
        name: "SAP Amount",
        fieldName: "sapAmount",
        type: "number",
        minWidth: 75
      },
      {
        key: "SourceDebitAmount",
        name: "Source Amount",
        fieldName: "sourceAmount",
        type: "number",
        minWidth: 75
      },
      {
        key: "Header",
        name: "Header",
        fieldName: "jeHeaderText",
        type: "string",
        minWidth: 110
      },
      {
        key: "CompanyCode",
        name: "Company Code",
        fieldName: "companyCode",
        type: "number",
        minWidth: 45
      },
      {
        key: "JEType",
        name: "JE Type",
        fieldName: "jeType",
        type: "string",
        minWidth: 50
      },
      {
        key: "retryPerformedBy",
        name: "Retried By",
        fieldName: "retryPerformedBy",
        type: "string",
        minWidth: 75
      },
      {
        key: "retryPerformedDate",
        name: "Retried Time",
        fieldName: "retryPerformedDate",
        type: "string",
        minWidth: 70,
        sortDescendingAriaLabel: "Sorted by Newer Date First",
        onRender: (item: AutomatedJeDashboardElement) => {
          if (item.retryPerformedDate === DEFAULT_NULL_CHARACTER) {
            return DEFAULT_NULL_CHARACTER;
          }
          return item.retryPerformedDate;
        }
      },
      {
        key: "refguid",
        name: "Request ID",
        fieldName: "refguid",
        type: "string",
        minWidth: 75
      }
    ];

    // if (isSortedIndex !== undefined) {
    //   (cols[isSortedIndex] as any).isSorted = true;
    //   (cols[isSortedIndex] as any).isSortedDescending = isSortedDescending;
    // }

    return cols;
  };
