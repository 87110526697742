import { css } from "@emotion/css";
import {
  CommandBar,
  DefaultButton,
  Dropdown,
  ICommandBarItemProps,
  ICommandBarStyles,
  IDatePickerStrings,
  IDropdownOption,
  PrimaryButton,
  Theme,
  defaultDatePickerStrings,
  useTheme,
  TextField,
  Stack,
  ContextualMenu,
  Modal
} from "@fluentui/react";
import React, { useEffect, useRef, useState, useContext } from "react";
import {
  ActionTitle,
  ActionsContext,
  DatePickerField,
  GLAttachment,
  LoadingStatus,
  SimpleShimmerForForms,
  debounce
} from "../../../Shared";
import { buttonStyles } from "../../../Shared/utilities/CommonStyles";
import { isButtonEnabled } from "../../../Shared/utilities/WeightageCalculator";
// import { isButtonEnabled } from "../../../Shared/utilities/WeightageCalculator";
import { GLActionTypes } from "../../hooks/useActionWeightages";
//import { popupStyles } from "../../shared/noActivityAction";
//import { reversalReasonOptions } from "../../../Shared/components/Actions/Reverse";
import { FastField, Field, Form, Formik } from "formik";
import { getCurrentFyFp } from "../../utilities";
import { useConst } from "@fluentui/react-hooks";
import {
  AdditionalReverseJEData,
  GLAttachments,
  GLAttachmentsProps,
  GLAttachmentsRef,
  GLAttachmentEndpointMap,
  GLAttachmentRequiredItems,
  gridStyle
} from "../../shared";
import { JEMContext, convertToReversalReasonsDropdownOptions } from "../../../Shared/contexts";

export enum GLDetailsActionsEnum {
  Save = "Save",
  SaveSAPInfo = "SaveSAPInfo",
  ReleaseForSignOff = "ReleaseForSign-Off",
  SignOff = "Sign-off",
  NeedsClarification = "NeedsClarification",
  ReverseJE = "Reverse JE",
  ViewFile = "View File"
}

interface GLDetailsActionsProps {
  onAction: (actionName: GLDetailsActionsEnum, options?: AdditionalReverseJEData) => void;
  rowWeightage: number;
  jeStatusCode: number;
  actionWeightages: Record<GLActionTypes, number>;
  opsDetailsName: string;
  reverseJENumber: string;
  onUploadFile: GLAttachmentEndpointMap["uploadAttachment"];
  onDeleteFile: GLAttachmentEndpointMap["deleteAttachment"];
  onDownloadFile: GLAttachmentEndpointMap["downloadAttachment"];
  initialRegion: string;
  initialAttachments: GLAttachment[];
  items: GLAttachmentRequiredItems[];
  lockRegion: boolean;
}

/*export type GLDetailsExtendedActionsProps = GLDetailsActionsProps &
  Omit<
    GLAttachmentsProps,
    "customRef" | "initialAttachments" | "disabled" | "loadingStatus" | "lockRegion" | "initialRegion"
  >;*/

function CommandsForGLDetails(
  onActionCallback: GLDetailsActionsProps["onAction"],
  setShowReverseJEModal: any,
  theme: Theme,
  statusCode: number,
  reverseJENumber: string,
  rowWeightage: number,
  actionWeightages: Record<GLActionTypes, number>
): ICommandBarItemProps[] {
  //const reversalJEDisabledPerStatus = statusCode !== 12;

  return [
    {
      key: "save",
      name: "Save",
      iconProps: { iconName: "Save" },
      onClick: debounce(() => onActionCallback(GLDetailsActionsEnum.Save), 200) as any,
      disabled: !isButtonEnabled(actionWeightages["save"], rowWeightage),
      buttonStyles: buttonStyles(theme)
    },
    {
      key: "saveSap",
      name: "Save SAP Info",
      iconProps: { iconName: "Save" },
      onClick: debounce(() => onActionCallback(GLDetailsActionsEnum.SaveSAPInfo), 200) as any,
      disabled: !isButtonEnabled(actionWeightages["saveToSAP"], rowWeightage),
      buttonStyles: buttonStyles(theme)
    },
    {
      key: "releaseForSignOff",
      name: "Release For Sign-Off",
      iconProps: { iconName: "PublishContent" },
      onClick: debounce(() => onActionCallback(GLDetailsActionsEnum.ReleaseForSignOff), 200) as any,
      disabled: !isButtonEnabled(actionWeightages["releaseForSignoff"], rowWeightage),
      buttonStyles: buttonStyles(theme)
    },
    {
      key: "signOff",
      name: "Sign-off",
      iconProps: { iconName: "CheckList" },
      onClick: debounce(() => onActionCallback(GLDetailsActionsEnum.SignOff), 200) as any,
      disabled: !isButtonEnabled(actionWeightages["approve"], rowWeightage),
      buttonStyles: buttonStyles(theme)
    },
    {
      key: "needsClarification",
      name: "Needs Clarification",
      iconProps: { iconName: "StatusCircleQuestionMark" },
      onClick: debounce(() => onActionCallback(GLDetailsActionsEnum.NeedsClarification), 200) as any,
      disabled: !isButtonEnabled(actionWeightages["needsClarification"], rowWeightage),
      buttonStyles: buttonStyles(theme)
    },
    {
      key: "reverseJE",
      name: "Reverse JE",
      iconProps: { iconName: "Undo" },
      onClick: debounce(() => setShowReverseJEModal(true), 200) as any,
      disabled:
        !isButtonEnabled(actionWeightages["reverse"], rowWeightage) ||
        (reverseJENumber !== "" && typeof Number(reverseJENumber) === "number" && !isNaN(Number(reverseJENumber))), //false,
      buttonStyles: buttonStyles(theme)
    },
    {
      key: "viewFile",
      name: "View File",
      iconProps: { iconName: "Attach" },
      onClick: () => onActionCallback(GLDetailsActionsEnum.ViewFile),
      disabled: false,
      buttonStyles: buttonStyles(theme)
    }
  ];
}

const GLDetailsActions: React.FC<GLDetailsActionsProps> = (props) => {
  const theme = useTheme();
  const [showReverseJEModal, setShowReverseJEModal] = useState<boolean>(false);
  const [selectedReverseJEReason, setselectedReverseJEReason] = useState<string>("");

  const fyfp = getCurrentFyFp(new Date());
  const defaultPostingDate = new Date(`${fyfp.calendarMonth}/15/${fyfp.calendarYear}`).toLocaleDateString("en-US");
  const [postingPeriod, setPostingPeriod] = useState<string>(fyfp.fiscalMonth.toString());
  const [postingDate, setPostingDate] = useState<string>("");
  const [comments, setComments] = useState<string>("");

  const attachmentsRef = useRef<GLAttachmentsRef>(null);
  let reversalReason;
  const domainContext = useContext(JEMContext);
  const reversalReasons = convertToReversalReasonsDropdownOptions(
    domainContext?.initInfo?.values?.ReversalReasons || []
  );

  const { styles } = useContext(ActionsContext);
  const [disabled, setDisabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState("Reversal reason is required");

  const hidePopup = () => {
    setShowReverseJEModal(false);
  };
  const onAction = (actionName: GLDetailsActionsEnum) => {
    if (props.onAction) {
      props.onAction(actionName);
    }
  };
  const [actions, setActions] = useState<ICommandBarItemProps[]>(() => {
    return CommandsForGLDetails(
      onAction,
      setShowReverseJEModal,
      theme,
      props.jeStatusCode,
      props.reverseJENumber,
      props.rowWeightage,
      props.actionWeightages
    );
  });

  useEffect(() => {
    setActions(
      CommandsForGLDetails(
        onAction,
        setShowReverseJEModal,
        theme,
        props.jeStatusCode,
        props.reverseJENumber,
        props.rowWeightage,
        props.actionWeightages
      )
    );
  }, [props.rowWeightage, props.actionWeightages, props.jeStatusCode]);

  const initialState = {
    reversalReason: "",
    postingDate: "",
    postingPeriod: fyfp.fiscalMonth.toString(),
    attachments: []
  };

  const outOfBoundsErrorMessage: IDatePickerStrings = useConst(() => ({
    ...defaultDatePickerStrings,
    isOutOfBoundsErrorMessage: "Posting Date should be in the current Posting Period."
  }));

  const closeOverlay = () => {
    hidePopup();
  };
  const onSubmit = async () => {
    if (props.onAction) {
      let attachments, error;
      if (attachmentsRef.current) {
        [attachments, error] = await attachmentsRef.current.saveAndGetAttachments();
      }
      if (error) {
        setDisabled(true);
      } else {
        props.onAction(GLDetailsActionsEnum.ReverseJE, {
          reversalReason: selectedReverseJEReason,
          postingDate: postingDate === "" ? defaultPostingDate : postingDate,
          postingPeriod: postingPeriod,
          attachments: attachments ? attachments?.attachments : null,
          regionKey: attachments ? attachments?.region_key : "",
          comments: comments
        });
      }
    }
    hidePopup();
  };

  const containerStyles = styles.contentStyles.container;

  return (
    <>
      <div
        ref={null}
        className={css`
          width: 100%;
          min-height: 1px;
          box-sizing: border-box;
          overflow: hidden;
        `}
      >
        <CommandBar
          items={actions}
          styles={
            {
              root: {
                paddingLeft: "0px",
                backgroundColor: theme.palette.neutralLighterAlt
              }
            } as ICommandBarStyles
          }
          ariaLabel={"Actions for Journal Entry"}
          overflowButtonProps={{
            ariaLabel: "More Actions",
            styles: {
              root: {
                backgroundColor: theme.palette.neutralLighterAlt
              }
            }
          }}
        />
      </div>
      {showReverseJEModal && (
        <>
          <Modal
            isOpen={showReverseJEModal}
            onDismiss={closeOverlay}
            isBlocking={true}
            topOffsetFixed={true}
            containerClassName={containerStyles}
            layerProps={{
              eventBubblingEnabled: true
            }}
            dragOptions={{
              moveMenuItemText: "Move",
              closeMenuItemText: "Close",
              menu: ContextualMenu
            }}
          >
            <p
              className={css`
                font-size: x-large;
                margin: 9px;
              `}
            >
              <b>Reverse JE</b>
            </p>
            <div className={styles.contentStyles.body}>
              <Formik initialValues={initialState} onSubmit={onSubmit}>
                {(formik) => (
                  <Form onSubmit={formik.handleSubmit}>
                    <Stack
                      {...{
                        tokens: {
                          childrenGap: 10,
                          padding: 10
                        }
                      }}
                    >
                      <Stack.Item
                        {...{
                          styles: {
                            root: {
                              maxWidth: 300
                            }
                          }
                        }}
                      >
                        <FastField
                          label="Reversal reason:"
                          name="reversalReason"
                          id="reversalReason"
                          as={Dropdown}
                          options={reversalReasons}
                          placeholder="Select an option"
                          onChange={(_event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption) => {
                            reversalReason = item?.key;
                            if (item?.key) {
                              //formik.setFieldValue("reversalReason", item?.key);
                              setselectedReverseJEReason(item?.key.toString() || "");
                              comments !== "" ? setDisabled(false) : setDisabled(true);
                            } else {
                              setDisabled(true);
                              setErrorMessage("Reversal reason is required");
                            }
                          }}
                          errorMessage={errorMessage}
                          required
                        />
                      </Stack.Item>
                      <Stack.Item>
                        <SimpleShimmerForForms height={32} label="Posting Date" loadingStatus={LoadingStatus.Resolved}>
                          <Field
                            name="postingDate"
                            label="Posting Date"
                            placeholder={new Date(`${fyfp.calendarMonth}/15/${fyfp.calendarYear}`).toLocaleDateString(
                              "en-US"
                            )}
                            selected={new Date(`${fyfp.calendarMonth}/15/${fyfp.calendarYear}`)}
                            component={DatePickerField}
                            strings={outOfBoundsErrorMessage}
                            onSelectDate={(date: Date | null | undefined) => {
                              if (!date) return;
                              const fyfp = getCurrentFyFp(date);
                              setPostingDate(date.toLocaleDateString("en-US"));
                              setPostingPeriod(fyfp.fiscalMonth.toString());
                            }}
                          />
                        </SimpleShimmerForForms>
                      </Stack.Item>
                      <Stack.Item>
                        <TextField
                          name="postingPeriod"
                          label="Posting Period"
                          type="input"
                          disabled
                          value={postingPeriod}
                        />
                      </Stack.Item>
                      <Stack.Item>
                        <TextField
                          name="opsDetails"
                          label="Ops Details"
                          type="input"
                          disabled
                          value={props.opsDetailsName}
                        />
                      </Stack.Item>
                      <Stack.Item>
                        <p>Please make sure to select the same region as the original JEs attachment </p>
                      </Stack.Item>
                      <Stack.Item>
                        <GLAttachments
                          customRef={attachmentsRef}
                          onUploadFile={props.onUploadFile}
                          onDeleteFile={props.onDeleteFile}
                          onDownloadFile={props.onDownloadFile}
                          initialRegion={props.initialRegion} // REDMOND
                          onChange={(lAttachments, rAttachments, errorMessage) => {
                            const theresAnErrorInAttachments = errorMessage !== null;
                            if (theresAnErrorInAttachments) {
                              setErrorMessage("Please reattach or try again");
                              setDisabled(true);
                            }
                          }}
                          lockRegion={props.lockRegion}
                          initialAttachments={[]}
                          disabled={false}
                          loadingStatus={LoadingStatus.Resolved}
                          items={props.items}
                        ></GLAttachments>
                      </Stack.Item>
                      <Stack.Item>
                        <TextField
                          name="comments"
                          required
                          label={"Comments"}
                          errorMessage={"Comment is required"}
                          onChange={async (event, newValue) => {
                            event.stopPropagation();
                            const textBoxVal = newValue;
                            const theresNoTextBoxVal = !textBoxVal || textBoxVal === "";
                            if (theresNoTextBoxVal) {
                              setErrorMessage("Comment is required");
                              setDisabled(true);
                            } else {
                              if (reversalReason !== "") {
                                setDisabled(false);
                              }
                              setComments(textBoxVal);
                            }
                          }}
                        />
                      </Stack.Item>
                      <Stack.Item>
                        <Stack horizontal {...{ tokens: gridStyle }}>
                          <Stack.Item>
                            <p>Are you sure you want to reverse this entry? </p>
                          </Stack.Item>
                          <Stack.Item align="end">
                            <PrimaryButton
                              text="Reverse"
                              type="submit"
                              disabled={
                                !selectedReverseJEReason ||
                                comments === "" ||
                                (props.reverseJENumber !== "" &&
                                  typeof Number(props.reverseJENumber) === "number" &&
                                  !isNaN(Number(props.reverseJENumber)))
                              }
                            />
                          </Stack.Item>
                          <Stack.Item align="end">
                            <DefaultButton
                              onClick={(event) => {
                                event.stopPropagation();
                                hidePopup();
                              }}
                              text="Cancel"
                            />
                          </Stack.Item>
                        </Stack>
                      </Stack.Item>
                    </Stack>
                  </Form>
                )}
              </Formik>
            </div>
          </Modal>
        </>
      )}
    </>
  );
};

GLDetailsActions.displayName = "GLDetailsActions";
export { GLDetailsActions };
