import {
  JemConfiguration,
  IUserProviderState,
  getValidUrl,
  getRequest,
  getArrayFromCacheOrFromEndpoint,
  ApiError,
  IAttachment,
  CommentsModel,
  ApiParsingUtilities
} from "@jem/components";
import { poAttachmentToIAttachment, poCommentToIComment } from "../../shared/IHCC.types";
import {
  ActionsModel,
  BulkDetailsModel,
  DetailsModel,
  DetailsPageActionsResponse,
  DetailsPageBulkResponse,
  DetailsPageModel,
  DetailsPagePaymentOrderResponse,
  DetailsPageSAPDetailsResponse,
  ReviewersModel,
  SapDetailsModel
} from "./IHCCPODetailsContext.types";

async function fetchActionsFromEndpoint(
  configuration: JemConfiguration,
  getTokenFn: IUserProviderState["accessToken"]
): Promise<DetailsPageActionsResponse | null> {
  const base = configuration.IhccApi.baseApiUrl;
  const endpoint = getValidUrl(`${base}${configuration.IhccApi.endpoints.getJEDetails}`);
  const actionConfiguration = await getRequest<DetailsPageActionsResponse>(endpoint, getTokenFn);
  return actionConfiguration;
}

async function getActions(
  poNumber: string,
  configuration: JemConfiguration,
  getTokenFn: IUserProviderState["accessToken"]
): Promise<DetailsPageActionsResponse> {
  const actions = await getArrayFromCacheOrFromEndpoint<DetailsPageActionsResponse | null, JemConfiguration>(
    configuration,
    "PODetailsActions",
    fetchActionsFromEndpoint,
    getTokenFn,
    configuration.IhccApi.cacheTimeInHours
  );
  if (!actions) {
    throw new ApiError(`Could not fetch Action Configuration for PO Details ${poNumber}.`);
  }
  return actions;
}

function assembleAttachmentModel(details: DetailsPagePaymentOrderResponse): IAttachment[] {
  const attachments = !details.poAttachments
    ? []
    : details.poAttachments.reduce((attList, att) => {
        const newAttachment = poAttachmentToIAttachment(att);
        if (!newAttachment) {
          return attList;
        }
        attList.push(newAttachment);
        return attList;
      }, [] as IAttachment[]);
  return attachments;
}

function assembleReviewersModel(details: DetailsPagePaymentOrderResponse): ReviewersModel {
  const commentsGrid = !details.poComments
    ? []
    : details.poComments.reduce((commentList, comment) => {
        const newComment = poCommentToIComment(comment);
        if (!newComment) {
          return commentList;
        }
        commentList.push(newComment);
        return commentList;
      }, [] as CommentsModel[]);
  return {
    primaryReviewer: details.supervisorAliasToDB || "",
    additionalReviewers: details.addtionalReviewersCSVToDB || "",
    comments: "",
    commentsGrid
  };
}

function assembleDetailsModel(details: DetailsPagePaymentOrderResponse): DetailsModel {
  const postDate =
    details.postDate && !isNaN(Date.parse(details.postDate))
      ? new Date(Date.parse(details.postDate)).toLocaleDateString("en-US")
      : "";
  return {
    poNumber: details.docNum || "",
    poType:
      details.reversalItemIndicator != undefined && details.reversalItemIndicator === "X" ? "Reversed" : "Original",
    poster: details.posterAlias || "",
    fiscalYearAndPeriod: `${details.fiscalYear}/${details.postingPeriod}`,
    poPurpose: details.purpose || "",
    reversalDate: details.reversalDate || "",
    bankArea: details.bankArea || "",
    reviewers: `${details.supervisorAliasToDB}${
      details.addtionalReviewersCSVToDB ? `;${details.addtionalReviewersCSVToDB}` : ""
    }`,
    postDate: postDate,
    access: details.access || 0
  };
}

export function createFetcherFunctions(poNumber: string) {
  const fetchModel = async (
    configuration: JemConfiguration,
    getTokenFn: IUserProviderState["accessToken"]
  ): Promise<DetailsPageModel> => {
    const base = configuration.IhccApi.baseApiUrl;
    const endpoint = getValidUrl(
      `${base}${configuration.IhccApi.endpoints.getPODetails.replace("{poNumber}", poNumber)}`
    );
    const details = await getRequest<DetailsPagePaymentOrderResponse>(endpoint, getTokenFn);
    if (!details) {
      throw new ApiError(`Could not fetch IHCC PO Details for PO ${poNumber}.`);
    }

    return {
      detailsTab: assembleDetailsModel(details),
      attachmentsTab: assembleAttachmentModel(details),
      reviewTab: assembleReviewersModel(details),
      rowVer: details.rowVer,
      status: details.status,
      bulkRefGuid: !details.bulkGUID && details.bulkGUID === "" ? null : details.bulkGUID,
      weightage: details.weightage,
      poId: `${details.id}`,
      refGuid: details.refGuid || ""
    };
  };
  const fetchSapPoDetails = async (
    configuration: JemConfiguration,
    getTokenFn: IUserProviderState["accessToken"]
  ): Promise<SapDetailsModel> => {
    const base = configuration.IhccApi.baseApiUrl;
    const endpoint = getValidUrl(
      `${base}${configuration.IhccApi.endpoints.getSapPoDetails.replace("{poNumber}", poNumber)}`
    );
    const sapDetails = await getRequest<DetailsPageSAPDetailsResponse>(endpoint, getTokenFn);
    if (!sapDetails) {
      throw new ApiError(`Could not fetch SAP Details for PO Details ${poNumber}.`);
    }
    return {
      poId: sapDetails.poId,
      opAccount: sapDetails.opAccount || "",
      rpAccount: sapDetails.rpAccount || "",
      transactionType: sapDetails.transactionType || "",
      currency: sapDetails.currency || "",
      opCurrency: ApiParsingUtilities.parseNumber(sapDetails.opCurrency || 0),
      rpCurrency: ApiParsingUtilities.parseNumber(sapDetails.rpCurrency || 0),
      opCurrencyType: sapDetails.opCurrencyType,
      rpCurrencyType: sapDetails.rpCurrencyType
    };
  };
  const fetchBulkDetails = async (
    configuration: JemConfiguration,
    getTokenFn: IUserProviderState["accessToken"]
  ): Promise<BulkDetailsModel> => {
    const base = configuration.IhccApi.baseApiUrl;
    const endpoint = getValidUrl(
      `${base}${configuration.IhccApi.endpoints.getPOBulkDetails.replace("{poNumber}", poNumber)}`
    );
    const bulkInformation = await getRequest<DetailsPageBulkResponse>(endpoint, getTokenFn);
    if (!bulkInformation) {
      throw new ApiError(`Could not fetch bulk information for PO Details ${poNumber}.`);
    }
    return bulkInformation.map((bulkItem) => {
      return {
        poNumber: bulkItem.poNum || "",
        companyCode: bulkItem.coCode ? `${bulkItem.coCode}` : "",
        fiscalYear: bulkItem.coCode ? `${bulkItem.fiscalYear}` : "",
        fiscalPeriod: bulkItem.coCode ? `${bulkItem.fiscalPeriod}` : ""
      };
    });
  };
  const fetchActionDetails = async (
    configuration: JemConfiguration,
    getTokenFn: IUserProviderState["accessToken"]
  ): Promise<ActionsModel> => {
    const actions = await getActions(poNumber, configuration, getTokenFn);
    return actions.map((action) => {
      return {
        action: action.action || "",
        actionLinkId: action.actionLinkId || 0,
        description: action.description || "",
        id: action.id || 0,
        weightage: action.weightage || 0
      };
    });
  };
  return {
    fetchModel,
    fetchSapPoDetails,
    fetchBulkDetails,
    fetchActionDetails
  };
}
