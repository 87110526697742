import { JemConfiguration } from "../../../JemConfiguration";
import { ApiError, FatalError, RuntimeError } from "../../../Shared/utilities/ErrorHelpers";
import { getRequest, getValidUrl, IUserProviderState } from "../../../Shared/utilities/RequestUtilities";
import {
  GLCreateDraftObjectFromApi,
  GLDraftObjectFromAPI,
  GLCreateQueryStringParameters,
  ValidateCallResponse
} from "./GLCreate.types";
import {
  defaultPageStateForCreate,
  assembleCreatePageState,
  GLPageState,
  sanitizeDefaultOverrides
} from "./GLCreate.State";
import { DomainDataEnum, DomainDataObjects } from "../../../Shared/contexts/JEMContext/JEMContext.domainData.types";
import { FetchUserChecklists } from "../../utilities/FetchUserChecklists";
import { GLCreateOverrides } from "./GLCreateForm.types";

export async function fetchDraftFromEndpoint(
  configuration: Pick<JemConfiguration, "GeneralLedgerApi" | "featureFlags" | "environment">,
  domainData: Pick<
    DomainDataObjects,
    | DomainDataEnum.FiscalPeriods
    | DomainDataEnum.JeTypes
    | DomainDataEnum.JeReasonCodes
    | DomainDataEnum.CurrencyCodes
    | DomainDataEnum.JeParameters
    | DomainDataEnum.JeStatus
  > | null,
  userContext: IUserProviderState,
  queryString: Pick<GLCreateQueryStringParameters, "RefGuid" | "Templateid">,
  defaultOverrides?: Partial<GLCreateOverrides>
): Promise<GLPageState | null> {
  if (queryString.RefGuid === "") {
    throw new FatalError("Please provide a valid refguid.");
  }
  if (domainData === null) {
    throw new RuntimeError("Something is wrong with Currency information, please contact engineering.");
  }
  const endpoint =
    `${configuration.GeneralLedgerApi.baseApiUrl}${configuration.GeneralLedgerApi.endpoints.getDraft}`.replace(
      "{refguid}",
      queryString.RefGuid
    );

  const endpointUrl = getValidUrl(endpoint);
  const payload = await getRequest<GLDraftObjectFromAPI>(endpointUrl, userContext.accessToken);
  if (!payload) {
    throw new ApiError("Draft not found.", {
      refGuid: queryString.RefGuid
    });
  }
  if (typeof payload === "string") {
    throw new ApiError(payload, {
      refGuid: queryString.RefGuid
    });
  }

  const emptyErrors: ValidateCallResponse = {
    hasError: false,
    jeHeaderError: null,
    jeAuditError: null,
    jeLineItemErrors: null,
    otherErrors: null,
    sapCallSuccess: false,
    sxModelPrediction: [],
    warningMessage: ""
  };
  const jeErrors: ValidateCallResponse =
    payload.jeDetailsInfo.status === -1
      ? await fetchDraftErrorsFromEndpoint(configuration.GeneralLedgerApi, userContext.accessToken, queryString.RefGuid)
      : emptyErrors;

  let state = assembleCreatePageState(
    configuration,
    userContext,
    queryString,
    payload,
    jeErrors,
    domainData,
    defaultOverrides
  );
  const fy = state.createState.jeDetails.fiscalPeriod.fiscalYear.toString();
  const fp = state.createState.jeDetails.fiscalPeriod.fiscalMonth.toString();
  let checklists = JSON.parse(sessionStorage.getItem("checklists") || "null");
  if (!checklists) {
    checklists = await FetchUserChecklists(configuration.GeneralLedgerApi, fy, fp, userContext.accessToken);
    sessionStorage.setItem("checklists", JSON.stringify(checklists));
  }
  if (checklists.length > 0) {
    state.createState.userChecklists = checklists;
  }
  state = Object.freeze(state);
  return state;
}

export async function fetchDraftErrorsFromEndpoint(
  configuration: JemConfiguration["GeneralLedgerApi"],
  accessToken: IUserProviderState["accessToken"],
  refGuid: string
): Promise<ValidateCallResponse> {
  if (refGuid === "") {
    throw new FatalError("Please provide a valid refguid.");
  }
  const endpoint = `${configuration.baseApiUrl}${configuration.endpoints.getJeError}`.replace("{refguid}", refGuid);
  const endpointUrl = getValidUrl(endpoint);

  const response = await getRequest<ValidateCallResponse>(endpointUrl, accessToken);
  if (!response) {
    throw new ApiError("Draft errors not found.", {
      refGuid: refGuid
    });
  }
  return response;
}

export async function fetchNewDraft(
  configuration: Pick<JemConfiguration, "GeneralLedgerApi" | "featureFlags" | "environment">,
  domainData: Pick<
    DomainDataObjects,
    | DomainDataEnum.FiscalPeriods
    | DomainDataEnum.JeTypes
    | DomainDataEnum.JeReasonCodes
    | DomainDataEnum.CurrencyCodes
    | DomainDataEnum.JeParameters
  > | null,
  userContext: IUserProviderState,
  queryString: Pick<GLCreateQueryStringParameters, "Templateid">
): Promise<GLPageState | null> {
  if (domainData === null) {
    throw new RuntimeError("Something is wrong with Currency information, please contact engineering.");
  }
  const endpoint =
    `${configuration.GeneralLedgerApi.baseApiUrl}${configuration.GeneralLedgerApi.endpoints.createDraft}`.replace(
      "{templateId}",
      queryString.Templateid
    );
  const endpointUrl = getValidUrl(endpoint);
  const payload = await getRequest<GLCreateDraftObjectFromApi>(endpointUrl, userContext.accessToken);
  if (!payload) {
    throw new ApiError("Can't create new Draft.");
  }
  const overrides = sanitizeDefaultOverrides(undefined);
  let state = defaultPageStateForCreate(configuration, userContext, queryString, domainData, payload, overrides);
  const fy = state.createState.jeDetails.fiscalPeriod.fiscalYear.toString();
  const fp = state.createState.jeDetails.fiscalPeriod.fiscalMonth.toString();
  const checklists = await FetchUserChecklists(configuration.GeneralLedgerApi, fy, fp, userContext.accessToken);
  if (checklists.length > 0) {
    state.createState.userChecklists = checklists;
  }
  state = Object.freeze(state);
  return state;
}
