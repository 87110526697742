import { DefaultButton, PrimaryButton, Stack, Theme, useTheme } from "@fluentui/react";
import React, { useMemo, useRef } from "react";
import { useDropzone } from "react-dropzone";
import { LoadingStatus } from "../../utilities/Utilities";
import { LoadingSpinner } from "../LoadingSpinner/LoadingSpinner";
import { AttachmentUtil } from "./Attachments.AttachmentUtil";

const getStyle = (theme: Theme) => {
  const baseStyle = {
    display: "flex",
    alignItems: "center",
    fontFamily: theme.fonts.medium.fontFamily,
    fontSize: theme.fonts.medium.fontSize,
    borderWidth: 1,
    borderRadius: 2,
    borderStyle: "solid",
    borderColor: theme.palette.neutralTertiaryAlt,
    color: theme.palette.neutralPrimary,
    outline: "none",
    transition: "border .24s ease-in-out",
    zIndex: 999
  };

  const activeStyle = {
    borderColor: theme.palette.themeTertiary
  };

  const acceptStyle = {
    borderColor: theme.palette.themeLight
  };

  const rejectStyle = {
    borderColor: theme.palette.themeLight
  };
  return {
    baseStyle,
    acceptStyle,
    activeStyle,
    rejectStyle
  };
};

export function validateFiles(files: File[], defaultAcceptedFiles: string): File[] | undefined {
  if (!files || files.length === 0) return;
  const fileList = files;
  if (
    !AttachmentUtil.getValidatedFileName(fileList) ||
    !AttachmentUtil.getValidatedFileExtensions(fileList, defaultAcceptedFiles) ||
    !AttachmentUtil.getValidatedFileSize(fileList)
  )
    return;

  return fileList;
}

export interface AttachmentsControlsProps {
  onDeleteAttachments: () => void;
  onValidFilesDropped: (files: File[]) => void;
  loadingState: LoadingStatus;
  disabled: boolean;
}

const AttachmentsControls: React.FC<AttachmentsControlsProps> = (props) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const theme = useTheme();

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    onDrop: (acceptedFiles, fileRejections, event) => {
      event.stopPropagation();
      event.preventDefault();
      const files = validateFiles(
        acceptedFiles,
        ".xml,.doc,.docx,.xls,.xlsx,.csv,.xlsb,.ppt,.pptx,.pdf,.txt,.zip,.rar,.jpg,.jpeg,.html,.msg,.tif,.bmp,.rtf,.mdi,.png,.oft,.snp,.dat,.mp3,.mp4,.wav"
      );

      if (files) {
        props.onValidFilesDropped(files);
      }
    },
    accept: {
      "application/xml": [".xml"],
      "application/msword": [".doc", ".docx"],
      "application/vnd.ms-excel": [".xls", ".xlsx"],
      "text/csv": [".csv"],
      "application/vnd.ms-excel.sheet.binary.macroEnabled.12": [".xlsb"],
      "application/vnd.ms-powerpoint": [".ppt", ".pptx"],
      "application/pdf": [".pdf"],
      "text/plain": [".txt"],
      "application/zip": [".zip", ".rar"],
      "image/jpeg": [".jpg", ".jpeg"],
      "text/html": [".html"],
      "application/vnd.ms-outlook.": [".msg"],
      "image/tiff": [".tif"],
      "image/bmp": [".bmp"],
      "application/rtf": [".rtf"],
      "application/vnd.ms-access": [".mdi"],
      "image/png": [".png"],
      "application/ms-tnef": [".oft"],
      "application/octet-stream": [".snp", ".dat"],
      "audio/mpeg": [".mp3"],
      "video/mp4": [".mp4"],
      "audio/wav": [".wav"]
    },
    // esoteric option to allow network drives to be used
    useFsAccessApi: false
  });

  const dragStyle = useMemo(() => {
    const style = getStyle(theme);
    return {
      ...style.baseStyle,
      ...(isDragActive ? style.activeStyle : {}),
      ...(isDragAccept ? style.acceptStyle : {}),
      ...(isDragReject ? style.rejectStyle : {})
    };
  }, [isDragActive, isDragReject, isDragAccept, theme]);

  return (
    <div
      className="container"
      style={{
        position: "relative"
      }}
    >
      <div
        {...getRootProps({
          style: dragStyle,
          className: "dropzone"
        })}
      >
        <input {...getInputProps()} ref={fileInputRef} />
        {props.loadingState !== LoadingStatus.Resolved ? (
          <div
            style={{
              width: "100%",
              height: "100%",
              background: theme.palette.neutralLighterAlt
            }}
          >
            <LoadingSpinner label="Uploading" height="64px" />
          </div>
        ) : (
          <Stack horizontal {...{ tokens: { childrenGap: 16, padding: 8 } }} wrap>
            <Stack horizontal {...{ tokens: { childrenGap: 16, padding: 0 } }} wrap>
              <Stack.Item align="end">
                <PrimaryButton
                  onClick={(e) => {
                    e.preventDefault();
                    fileInputRef.current?.click();
                  }}
                  disabled={props.loadingState !== LoadingStatus.Resolved || props.disabled}
                >
                  Add files
                </PrimaryButton>
              </Stack.Item>
              <Stack.Item align="end">
                <DefaultButton
                  text="Delete Attachment"
                  iconProps={{ iconName: "Delete" }}
                  onClick={(e) => {
                    e.preventDefault();
                    props.onDeleteAttachments();
                  }}
                  disabled={props.disabled || props.loadingState !== LoadingStatus.Resolved}
                />
              </Stack.Item>
              {!props.children ? null : <Stack.Item align="center">{props.children}</Stack.Item>}
            </Stack>
            {!props.disabled ? (
              <Stack.Item align="center">
                <span>Or drag and drop here</span>
              </Stack.Item>
            ) : null}
          </Stack>
        )}
      </div>
    </div>
  );
};

AttachmentsControls.displayName = "AttachmentsControls";
export { AttachmentsControls };
