import {
  DetailsRow,
  IColumn,
  IDetailsColumnRenderTooltipProps,
  IDetailsHeaderProps,
  IDetailsHeaderStyles,
  IDetailsListProps,
  IDetailsRowStyles,
  IRenderFunction,
  ITheme,
  Sticky,
  StickyPositionType,
  TooltipHost
} from "@fluentui/react";
import React from "react";

export const onRenderDetailsHeader =
  (_theme: ITheme): IRenderFunction<IDetailsHeaderProps> =>
  (props, defaultRender) => {
    if (!props) {
      return null;
    }
    const onRenderColumnHeaderTooltip: IRenderFunction<IDetailsColumnRenderTooltipProps> = (tooltipHostProps) => {
      if (tooltipHostProps && tooltipHostProps.column && tooltipHostProps.column.name) {
        return <TooltipHost {...tooltipHostProps} content={tooltipHostProps.column.name} />;
      }
      return <TooltipHost {...tooltipHostProps} />;
    };
    if (!defaultRender) {
      return null;
    }
    return (
      <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced={true}>
        {defaultRender({
          ...props,
          ariaRole: "rowgroup",
          onRenderColumnHeaderTooltip,
          styles: {
            root: {
              ".ms-DetailsHeader-cell": {
                whiteSpace: "normal",
                textOverflow: "clip",
                lineHeight: "normal"
              },
              ".ms-DetailsHeader-cellTitle": {
                height: "100%",
                alignItems: "center"
              },
              // ".ms-DetailsHeader-cell:first-child": {
              //   border: `1px solid ${theme.palette.neutralTertiaryAlt}`
              // },
              // ".ms-DetailsHeader-cell:not(:first-child)": {
              //   borderRight: `1px solid ${theme.palette.neutralTertiaryAlt}`,
              //   borderTop: `1px solid ${theme.palette.neutralTertiaryAlt}`,
              //   borderBottom: `1px solid ${theme.palette.neutralTertiaryAlt}`
              // },
              paddingTop: 0
            }
            // cellSizerStart: {
            //   "::after": {
            //     opacity: 1
            //   }
            // }
          } as Partial<IDetailsHeaderStyles>
        })}
      </Sticky>
    );
  };

export const onRenderItemColumn = (
  //
  item?: any,
  index?: number | undefined,
  column?: IColumn | undefined
): JSX.Element | React.ReactText | null => {
  if (!column) {
    return null;
  }
  return (
    <TooltipHost content={item[column.fieldName || column.key]}>{item[column.fieldName || column.key]}</TooltipHost>
  );
};

export const onRenderRow =
  (_theme: ITheme): IDetailsListProps["onRenderRow"] =>
  (props) => {
    if (props) {
      const customStyles: Partial<IDetailsRowStyles> = {
        // root: {
        //   height: "28px",
        //   minHeight: "28px",
        //   backgroundColor: props.itemIndex % 2 === 0 ? theme.palette.themeLighterAlt : undefined,
        //   ".ms-DetailsRow-cell": {
        //     borderRight: `1px solid ${theme.palette.neutralTertiaryAlt}`,
        //     borderBottom: `1px solid ${theme.palette.neutralTertiaryAlt}`
        //   },
        //   ".ms-DetailsRow-cell.ms-DetailsRow-cellCheck": {
        //     marginTop: 0,
        //     borderLeft: `1px solid ${theme.palette.neutralTertiaryAlt}`
        //   }
        // },
        // cell: {
        //   height: "28px",
        //   minHeight: "28px",
        //   paddingTop: "2px",
        //   paddingRight: "2px",
        //   paddingLeft: "2px",
        //   paddingBottom: "0"
        // },
        // // Sometimes, and only sometimes
        // // fluentui adds the root style last, so we need to override it
        // check: {
        //   height: "28px !important"
        // },
        // checkCell: {
        //   height: "28px !important",
        //   minHeight: "28px",
        //   padding: "0px !important"
        // }
      };
      return <DetailsRow {...props} styles={customStyles} />;
    }
    return null;
  };
