import { Dialog, DialogType, DialogFooter, PrimaryButton, DefaultButton } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import React, { useImperativeHandle } from "react";
import { AsyncSubject } from "rxjs";

export interface GenericDialogContentProps {
  title: string;
  subText: string;
  approveButtonText: string;
  cancelButtonText: string;
}

export interface GenericDialogRef {
  getUserSubmission: (props: GenericDialogContentProps) => AsyncSubject<boolean>;
}

export interface GenericDialogProps {
  customRef: React.RefObject<GenericDialogRef>;
}

let dialogController: AsyncSubject<boolean> | null = null;

const GenericDialog: React.FC<GenericDialogProps> = (props) => {
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
  const currentDialogProps = React.useRef<GenericDialogContentProps>({
    title: "Empty Dialog",
    subText: "Engineering forgot to put information in this dialog",
    approveButtonText: "Acknowledge",
    cancelButtonText: "Cancel"
  });

  useImperativeHandle(props.customRef, () => ({
    getUserSubmission: (props: GenericDialogContentProps) => {
      dialogController = new AsyncSubject<boolean>();
      currentDialogProps.current = props;
      toggleHideDialog();
      return dialogController;
    }
  }));

  return (
    <Dialog
      hidden={hideDialog}
      onDismiss={() => {
        if (dialogController) {
          dialogController.next(false);
          dialogController.complete();
          dialogController = null;
        }
        toggleHideDialog();
      }}
      dialogContentProps={{
        type: DialogType.normal,
        title: currentDialogProps.current.title,
        closeButtonAriaLabel: "Close",
        subText: currentDialogProps.current.subText
      }}
      modalProps={{
        titleAriaId: currentDialogProps.current.title,
        subtitleAriaId: currentDialogProps.current.subText,
        isBlocking: true,
        styles: { main: { maxWidth: 450 } }
      }}
    >
      <DialogFooter>
        <PrimaryButton
          onClick={() => {
            if (dialogController) {
              dialogController.next(true);
              dialogController.complete();
              dialogController = null;
            }
            toggleHideDialog();
          }}
          text={currentDialogProps.current.approveButtonText}
        />
        <DefaultButton
          onClick={() => {
            if (dialogController) {
              dialogController.next(false);
              dialogController.complete();
              dialogController = null;
            }
            toggleHideDialog();
          }}
          text={currentDialogProps.current.cancelButtonText}
        />
      </DialogFooter>
    </Dialog>
  );
};

GenericDialog.displayName = "GenericDialog";

export { GenericDialog };
