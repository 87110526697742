import React, { useContext, useImperativeHandle, useState } from "react";
import { v4 } from "uuid";

import { IHCCDraftContext } from "../../../contexts/IHCCDraftContext/IHCCDraftContext";
import { IHCCDraftInitInfoResponse } from "../../../contexts/IHCCDraftContext/IHCCDraftContext.fetchInitInfo";
import { PaymentOrderDraft } from "../../../contexts/IHCCDraftContext/IHCCDraftContext.types";

import * as wjCore from "@grapecity/wijmo";
import { CellType, DataMap, FlexGrid as FlexGridCore } from "@grapecity/wijmo.grid";
import { FlexGrid, FlexGridColumn } from "@grapecity/wijmo.react.grid";
import { AutoComplete } from "@grapecity/wijmo.input";
import {
  IPaymentOrderTableObject,
  PaymentOrderValidationSchema,
  ValidatePaymentOrder
} from "./IHCCCreate.ValidatePaymentOrder";
import { FlexGridParentProps, LoadingSpinner, LoadingStatus } from "@jem/components";
import { useTheme } from "@fluentui/react";

import * as Yup from "yup";
import { GridItemIsNotEmpty } from "./IHCCCreate.IsNotEmpty";

export interface IHCCCreateTableRef {
  getData: () => Promise<[IPaymentOrderTableObject[], TableErrorMessage]>;
}

export interface IHCCCreateTableProps {
  customRef: React.Ref<IHCCCreateTableRef>;
  loadingStatus: LoadingStatus;
  disabled: boolean;
}

// Merges ids from existing Payment Orders into the Grid Rows
// Must ALWAYS add a Refguid for a valid Payment Order
// Invalid Payment Orders are the ones with
//  - ALL Empty OP Account, Empty RP Account, Empty Amount, Empty Currency, Empty Purpose
// Valid Payment Orders can be Empty by themselves or in combination
//  - To any Payment Order with Empty fields we will add an error message on the Processing Status
// NOTE: @param ApiData will be changed in place
function TableRowToIPaymentOrderTableObject(newRow: PaymentOrderDraft): IPaymentOrderTableObject | null {
  const finalObject: IPaymentOrderTableObject = {
    bankArea: "IHCC", // Bank Area is Always IHCC
    orderingPartyAccountNumber: "",
    transactionType: "",
    receivingPartyAmountInAccountCurrency: 0,
    receivingPartyCurrency: "",
    receivingPartyAccountNumber: "",
    purpose: "",
    processingStatus: "",
    actionType: "I",
    id: 0,
    refGuid: v4(),
    rowVer: null
  };

  finalObject.orderingPartyAccountNumber = newRow.opAccount;
  finalObject.transactionType = newRow.transType;
  finalObject.receivingPartyAmountInAccountCurrency = newRow.transAmount;
  finalObject.receivingPartyCurrency = newRow.transCurrency;
  finalObject.receivingPartyAccountNumber = newRow.rpAccount;
  finalObject.purpose = newRow.purpose;

  // 2 If all the input values are null, just remove the row
  if (
    !finalObject.orderingPartyAccountNumber &&
    !finalObject.transactionType &&
    !finalObject.receivingPartyCurrency &&
    !finalObject.receivingPartyAccountNumber &&
    !finalObject.purpose &&
    !finalObject.receivingPartyAmountInAccountCurrency
  ) {
    return null;
  }

  // 3 If the Amount is null or zero also remove it
  if (finalObject.receivingPartyAmountInAccountCurrency <= 0) {
    return null;
  }

  // 4 Get the Props from the API Data
  // If the index exists in the original rows, we will use:
  //  - the ID from the API Data
  //  - the rowVer from the API Data
  //  - the refGuid from the API Data
  //  - actionType "U" (Update)
  // Otherwise:
  //  - id = 0
  //  - newRefGuid = v4()
  //  - actionType "I" (Insert)
  finalObject.id = newRow.id;
  finalObject.rowVer = newRow.rowVer;
  finalObject.actionType = newRow.refGuid ? "U" : "I";
  finalObject.refGuid = newRow.refGuid || v4(); // at this point this thing should never be blank
  return finalObject;
}

export type TableErrorMessage = string | null;
export function processTableData(
  initInfo: IHCCDraftInitInfoResponse,
  originalRows: PaymentOrderDraft[],
  rows: PaymentOrderDraft[],
  invalidEntries: { key: string; value: string }[]
): Promise<[IPaymentOrderTableObject[], TableErrorMessage]> {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      try {
        const finalRows: IPaymentOrderTableObject[] = [];
        let hasErrors = false;
        if (rows.length === 0) {
          resolve([[], "Please add Payment Orders."]);
        } else {
          for (const row of rows) {
            const newPaymentOrder = TableRowToIPaymentOrderTableObject(row);
            if (!newPaymentOrder) {
              continue;
            }
            const errors = ValidatePaymentOrder(row, invalidEntries);
            if (errors.length > 0) {
              hasErrors = true;
              newPaymentOrder.processingStatus = errors.join("\n");
            } else {
              newPaymentOrder.processingStatus = "";
            }
            // always add to finalRows at this point
            // any error must show up on the processing status
            finalRows.push(newPaymentOrder);
          }
          resolve([finalRows, hasErrors ? "Please fix errors on Payment Orders table." : null]);
        }
      } catch (e) {
        reject(e);
      }
    }, 1);
  });
}

const commonPropsForColumns: Partial<FlexGridColumn["props"]> = {
  wordWrap: true,
  isRequired: false
};

const padItems = (initialItems: PaymentOrderDraft[]) => {
  const maxItems = Math.max(initialItems.length, 2000);
  const padItems = Array.from({ length: maxItems - initialItems.length }, (_) => {
    const blankItem: PaymentOrderDraft = {
      id: 0,
      rowVer: null,
      refGuid: null,
      opAccount: null as never,
      transType: null as never,
      transAmount: null as never,
      transCurrency: null as never,
      rpAccount: null as never,
      purpose: null as never,
      requestedDate: null as never,
      processingStatus: null as never
    };
    return blankItem;
  });
  const allItems = [...initialItems, ...padItems];
  return allItems as PaymentOrderDraft[];
};

const IHCCCreateGrid: React.FC<IHCCCreateTableProps> = (props) => {
  const draftData = useContext(IHCCDraftContext);

  const theme = useTheme();
  const [flex, setFlex] = useState<FlexGridCore | null>(null);

  useImperativeHandle(props.customRef, () => ({
    getData: async () => {
      if (!flex) {
        return [[], "Grid is not ready yet."];
      }

      const originalRows = draftData.draftModel.values.paymentOrderList;
      const r = processTableData(draftData.initInfo.values, originalRows, flex.collectionView.items, invalidEntries);

      return r;
    }
  }));

  const accountInfosSource = draftData.initInfo.values.accountInfos.map((x) => ({ key: x, value: x }));
  const invalidEntries = draftData.initInfo.values.accountInfos
    .filter((x) => x.length > 8)
    .map((x) => x.substring(0, 8))
    .map((x) => ({ key: x, value: x }));
  const accountInfosSourcePlusInvalid = [...accountInfosSource, ...invalidEntries];

  const accountInfos = new DataMap(accountInfosSourcePlusInvalid, "key", "value");
  const transTypes = new DataMap(
    draftData.initInfo.values.transTypeInfo.map((x) => ({ key: x, value: x })),
    "key",
    "value"
  );
  const currencyCodes = new DataMap(
    draftData.initInfo.values.currencyCodeInfo.map((x) => ({ key: x, value: x })),
    "key",
    "value"
  );
  const validationSchema = PaymentOrderValidationSchema(invalidEntries);

  const flexGridParentStyleProps = React.useMemo(() => {
    return FlexGridParentProps(theme, {
      className: "glcreate--lineitems",
      height: 500
    });
  }, [theme]);

  return (
    <>
      <div {...flexGridParentStyleProps}>
        {props.loadingStatus === LoadingStatus.Pending && (
          <div className="loading">
            <LoadingSpinner label="Loading Payment Orders" />
          </div>
        )}
        <FlexGrid
          initialized={(ctl: FlexGridCore) => {
            setFlex(ctl);
            ctl.columnHeaders.rows.defaultSize = 52;

            ctl.beginningEdit.addHandler((s, e) => {
              const row = e.getRow().dataItem;
              if (row.isReadOnly) {
                e.cancel = true;
              }
            });

            ctl.pasting.addHandler((s, e) => {
              const rng = e.range;
              const newValuesArr = e.data.split("\n");
              for (let r = rng.topRow; r <= rng.bottomRow; r++) {
                s.cells.setCellData(
                  r,
                  0,
                  accountInfosSource.findIndex(
                    (accInfo) => accInfo.key === newValuesArr[r - rng.topRow].split("\t")[0].trim()
                  ) != -1
                    ? newValuesArr[r - rng.topRow].split("\t")[0].trim()
                    : ""
                );
                s.cells.setCellData(
                  r,
                  4,
                  accountInfosSource.findIndex(
                    (accInfo) => accInfo.key === newValuesArr[r - rng.topRow].split("\t")[4].trim()
                  ) != -1
                    ? newValuesArr[r - rng.topRow].split("\t")[4].trim()
                    : ""
                );
                s.cells.setCellData(r, 2, newValuesArr[r - rng.topRow].split("\t")[2].trim());
              }
            });

            const items = padItems(draftData.draftModel.values.paymentOrderList);

            const view = new wjCore.CollectionView(items, {
              getError: (item: PaymentOrderDraft, prop: keyof PaymentOrderDraft, _parsing: boolean) => {
                const propDoesNotRequireValidation = !prop || prop.toLowerCase().indexOf("processingstatus") !== -1;
                if (propDoesNotRequireValidation) return;
                const itemIsEmpty = !GridItemIsNotEmpty(item);
                if (itemIsEmpty) return;
                if (
                  (prop === "opAccount" || prop === "rpAccount") &&
                  invalidEntries.some((x) => x.key === item[prop])
                ) {
                  return "Invalid Account Number";
                }

                try {
                  validationSchema.validateSyncAt(prop, item);
                } catch (error) {
                  if (error instanceof Yup.ValidationError) {
                    return error.message;
                  }
                }
              }
            });

            ctl.itemsSource = view;
          }}
          allowAddNew={false}
          validateEdits={false}
          allowSorting={false}
          allowDragging={false}
          selectionMode="MultiRange"
          showSelectedHeaders="All"
          isReadOnly={props.disabled}
          // formatItem={(flex: FlexGridCore, e) => {
          //   if (flex.columns[e.col].binding === "transAmount") {
          //     const cellData = flex.getCellData(e.row, e.col, false);
          //     if (cellData !== null) {
          //       const trimmedData = cellData.trim();
          //       if (cellData !== trimmedData) {
          //         flex.setCellData(e.row, e.col, cellData.trim(), false);
          //       }
          //     }
          //   }
          // }}
          itemFormatter={(panel: { cellType: CellType }, r: number, c: any, cell: { textContent: any; style: any }) => {
            if (panel.cellType === CellType.RowHeader) {
              cell.textContent = (r + 1).toString();
            }
          }}
        >
          <FlexGridColumn
            header="OP Account"
            binding="opAccount"
            dataMap={accountInfos}
            editor={
              new AutoComplete(document.createElement("div"), {
                itemsSource: accountInfosSource,
                selectedValuePath: "key",
                displayMemberPath: "value"
              })
            }
            {...commonPropsForColumns}
            minWidth={140}
          />
          <FlexGridColumn header="Trans Type" binding="transType" dataMap={transTypes} {...commonPropsForColumns} />
          <FlexGridColumn header="Trans Amount" binding="transAmount" {...commonPropsForColumns} format="n2" />
          <FlexGridColumn
            header="Trans Currency"
            binding="transCurrency"
            dataMap={currencyCodes}
            {...commonPropsForColumns}
          />
          <FlexGridColumn
            header="RP Account"
            binding="rpAccount"
            dataMap={accountInfos}
            minWidth={140}
            editor={
              new AutoComplete(document.createElement("div"), {
                itemsSource: accountInfosSource,
                selectedValuePath: "key",
                displayMemberPath: "value"
              })
            }
            {...commonPropsForColumns}
          />
          <FlexGridColumn header="Purpose" binding="purpose" {...commonPropsForColumns} />
          <FlexGridColumn
            header="Requested Date"
            binding="requestedDate"
            format="d"
            minWidth={75}
            {...commonPropsForColumns}
            isReadOnly={true}
          />
          <FlexGridColumn
            header="Processing Status"
            binding="processingStatus"
            width="*"
            minWidth={200}
            {...commonPropsForColumns}
            isReadOnly={true}
          />
        </FlexGrid>
      </div>
    </>
  );
};

IHCCCreateGrid.displayName = "IHCCCreateGrid";

export default IHCCCreateGrid;
