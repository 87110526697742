import React, { forwardRef, useContext, useEffect, useImperativeHandle, useState } from "react";
import { JEMContext } from "../../contexts/JEMContext/JEMContext";
import { DomainDataObjects } from "../../contexts/JEMContext/JEMContext.domainData.types";
import { IHCCContext } from "../../../PaymentOrders/contexts/IHCCContext/IHCCContext";
import { CompanyCode } from "../../../PaymentOrders/contexts/IHCCContext/IHCCContext.types";
import { Autocomplete } from "../Autocomplete/Autocomplete";
import { Label, useTheme } from "@fluentui/react";

export interface CompanyCodeSingleSelectorRef {
  getCompanyCode: () => number | null;
  setInitialCompanyCode: (initialCompanyCode: string) => void;
  reset: () => void;
}

export function searchInArray(array: string[], prefix?: string | null, limit = 10): string[] {
  if (prefix === "") {
    if (limit === 0) {
      return array;
    } else {
      return array.slice(0, limit + 1);
    }
  }
  const chunk: string[] = [];
  for (let i = 0; i < array.length && chunk.length < limit; ++i) {
    if (prefix) {
      if (array[i].startsWith(prefix) || array[i].startsWith(prefix.toUpperCase())) {
        chunk.push(array[i]);
      }
    }
  }

  return chunk;
}

export function companyCodeObjectToStringArray(
  companyCodesArray: CompanyCode[] | DomainDataObjects["JeCompanyCodes"] | undefined
): string[] {
  const compnayCodes = companyCodesArray || [];
  const companyCodeCodes = compnayCodes.map((x) => `${x.glCompanyCode}, ${x.glCompanyName}`);

  return companyCodeCodes;
}

function getFullCompanyCodeName(
  currentCompanyCode: string,
  companyCodes: CompanyCode[] | DomainDataObjects["JeCompanyCodes"]
): string | null {
  const companyCodeArray = companyCodeObjectToStringArray(companyCodes);
  const initialCompanyCode = companyCodeArray.filter((coCode) => {
    return coCode.startsWith(currentCompanyCode) || coCode.startsWith(currentCompanyCode.toUpperCase());
  });
  if (initialCompanyCode.length === 1) {
    return initialCompanyCode[0];
  }
  return null;
}

export interface CompanyCodeSingleSelectorProps {
  context: "IHCC" | "GL";
  selectedCompany?: number;
  onChange?: (currentCompanyCode: number | 0) => void;
  required?: boolean;
}

const CompanyCodeSingleSelector = forwardRef((props: CompanyCodeSingleSelectorProps, ref) => {
  const theme = useTheme();
  const jemContext = useContext(JEMContext);
  const { initInfo } = useContext(IHCCContext);

  const contextCompanyCodes =
    props.context === "IHCC" ? initInfo.values?.companyCodes : jemContext.initInfo.values?.JeCompanyCodes;

  const [currentCompanyCode, setCurrentCompanyCode] = useState<string>(props.selectedCompany?.toString() || "");

  const isInCompanyCodes = (prefix?: string) => {
    const companyCodeArray = companyCodeObjectToStringArray(contextCompanyCodes);
    return searchInArray(companyCodeArray, prefix);
  };

  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (contextCompanyCodes && disabled) {
      if (currentCompanyCode !== "") {
        const coCode = getFullCompanyCodeName(currentCompanyCode, contextCompanyCodes);
        if (coCode) {
          setCurrentCompanyCode(coCode);
          if (props.onChange) {
            const code = coCode.slice(0, 4);
            props.onChange(isNaN(Number(code)) || coCode.length < 4 ? 0 : Number(code));
          }
        }
      }
      setDisabled(false);
    }
  }, [contextCompanyCodes]);

  useImperativeHandle(ref, () => ({
    getCompanyCode() {
      if (currentCompanyCode === "") {
        return null;
      }
      const code = currentCompanyCode.slice(0, 4);
      if (isNaN(Number(code))) {
        return null;
      }
      return currentCompanyCode.length < 4 ? null : Number(code);
    },
    setInitialCompanyCode(theInitialCompanyCode: string) {
      if (theInitialCompanyCode === "0") {
        setDisabled(false);
      } else if (!contextCompanyCodes) {
        setCurrentCompanyCode(theInitialCompanyCode);
      } else {
        const coCode = getFullCompanyCodeName(theInitialCompanyCode, contextCompanyCodes);
        if (coCode) {
          setCurrentCompanyCode(coCode);
        } else {
          setCurrentCompanyCode(theInitialCompanyCode);
        }
        setDisabled(false);
      }
    },
    reset() {
      setCurrentCompanyCode("");
    }
  }));

  return (
    <React.Fragment>
      <Autocomplete
        label="Company Code:"
        name="companyCode"
        value={currentCompanyCode}
        onChange={(value: any) => {
          setCurrentCompanyCode(value || "");
          if (props.onChange) {
            const code = value.slice(0, 4);
            props.onChange(isNaN(Number(code)) || value.length < 4 ? 0 : Number(code));
          }
        }}
        // required
        // errorMessage={""}
        onSuggest={isInCompanyCodes}
        disabled={disabled}
      />
      {props.required ? (
        <Label
          styles={{
            root: {
              fontSize: "12px",
              fontWeight: "400",
              color: theme.palette.redDark,
              margin: "0px",
              paddingTop: "5px"
            }
          }}
        >
          Company Code is required
        </Label>
      ) : (
        <></>
      )}
    </React.Fragment>
  );
});

CompanyCodeSingleSelector.displayName = "CompanyCodeSingleSelector";

export { CompanyCodeSingleSelector };
