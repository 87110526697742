import { Dropdown, IDropdownOption } from "@fluentui/react";
import { convertToCurrecyDropdownOptions, DomainDataObjects, JEMContext } from "@jem/components";
import React, { forwardRef, useContext, useEffect, useImperativeHandle, useState } from "react";
//import { MaxWidthHeightOptions } from "../../utilities/FluentUIHacks";

export interface ICurrencyCodeRef {
  getCurrencyCode: () => IDropdownOption<string> | null;
  setInitialCurrencyCode: (initialCurrencyCode: string) => void;
  reset: () => void;
}

interface ICurrencyCodeProps {
  loading: boolean;
  onChange?: (CurrencyCode: IDropdownOption<string>) => void;
  //styles?: MaxWidthHeightOptions;
  CurrencyCode: string;
}

const CurrencyCodeDropdown = forwardRef((props: ICurrencyCodeProps, ref) => {
  const domainContext = useContext(JEMContext);
  const CurrencyCodeDropdownOptions = convertToCurrecyDropdownOptions(
    domainContext.initInfo.values?.CurrencyCodes as DomainDataObjects["CurrencyCodes"]
  );

  const [CurrencyCode, setCurrencyCode] = useState<IDropdownOption<string>>();

  useEffect(() => {
    const icc = CurrencyCodeDropdownOptions.find((cc) => cc.text === props.CurrencyCode);
    if (!icc) return;

    setCurrencyCode(icc);
  }, [props.CurrencyCode]);

  const onChangeCurrencyCode = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void => {
    if (!option) {
      return;
    }

    setCurrencyCode(option);
    if (props.onChange) {
      props.onChange(option);
    }
  };

  useImperativeHandle(
    ref,
    (): ICurrencyCodeRef => ({
      getCurrencyCode() {
        if (!CurrencyCode) {
          return null;
        }
        return CurrencyCode;
      },
      setInitialCurrencyCode(initialCurrencyCode: string) {
        // get from options values
        const cc = CurrencyCodeDropdownOptions.find((cc) => cc.text === initialCurrencyCode);
        if (!CurrencyCode) return;

        setCurrencyCode(cc);
      },
      reset() {
        setCurrencyCode(CurrencyCodeDropdownOptions[0]);
      }
    })
  );

  return (
    <React.Fragment>
      <Dropdown
        selectedKey={CurrencyCode?.key}
        onChange={onChangeCurrencyCode}
        options={CurrencyCodeDropdownOptions}
        disabled={props.loading}
        styles={{
          root: {
            width: "100%",
            marginTop: "4px"
          }
        }}
        required
        errorMessage={CurrencyCode?.key === undefined ? "CurrencyCode is required" : undefined}
      />
    </React.Fragment>
  );
});

CurrencyCodeDropdown.displayName = "CurrencyCodeDropdown";

export { CurrencyCodeDropdown, ICurrencyCodeProps };
