import { IColumn, IColumnDragDropDetails } from "@fluentui/react";
import React from "react";
import { v4 } from "uuid";
import { RuntimeError } from "../../utilities/ErrorHelpers";
import { RequireAtLeastOne } from "../../utilities/TypeUtils";
import { copyAndSort } from "./DashboardGrid.sort";
import {
  IDashboardGridProps,
  IDetailsState,
  IExtendedColumn,
  IInternalExtendedColumn,
  IRearrangeOptions,
  UserConfiguration
} from "./DashboardGrid.types";

//
export const sliceData = (items: any, currentPage: number, pageSize: number): any[] =>
  items.slice(currentPage * pageSize, currentPage * pageSize + pageSize);

export function assertNoDuplicates(columnGenerator: IDashboardGridProps["columnGenerator"]): void {
  const cols = columnGenerator();
  const columnCheck = new Set(cols.map((col) => col.name));
  if (columnCheck.size !== cols.length) {
    throw new RuntimeError("Dashboard grid does not support duplicate name and fieldName.");
  }
}

export function resizeColumns(
  userConfiguration: UserConfiguration,
  columns: IInternalExtendedColumn[],
  options?: IRearrangeOptions
): IInternalExtendedColumn[] {
  if (options && options.initialLoad && userConfiguration.columnWidths !== null) {
    for (const col of columns) {
      if (col.name in userConfiguration.columnWidths) {
        col.minWidth = userConfiguration.columnWidths[col.name];
        col.maxWidth = userConfiguration.columnWidths[col.name];
      }
    }
  }
  return columns;
}

export function rearrangeColumns(
  userConfiguration: UserConfiguration,
  columns: IInternalExtendedColumn[],
  options?: IRearrangeOptions
): IInternalExtendedColumn[] {
  const lColumns = resizeColumns(userConfiguration, columns, options);

  if (userConfiguration.columnPositions !== null) {
    const rearrangedColumns = lColumns.reduce((rngColumns, column) => {
      // we're already checking if it is not null in line above
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const columnPosition = userConfiguration.columnPositions![column.name];
      rngColumns[columnPosition] = column;
      return rngColumns;
    }, Array.from({ length: lColumns.length }) as IInternalExtendedColumn[]);
    return rearrangedColumns.filter((col) => col !== undefined);
  } else {
    return lColumns;
  }
}

export const ColumnClickListenerConstructor =
  (
    selectedPage: number,
    defaultPageSize: number,
    gridItems: any[],
    setDetailsState: React.Dispatch<RequireAtLeastOne<IDetailsState, keyof IDetailsState>>,
    columnFieldNames: string[],
    columnTypes: Array<"string" | "number" | "date" | "object">
  ) =>
  (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    if (column.fieldName === undefined) {
      throw new RuntimeError("Columns with listeners must have fieldNames.");
    }

    if (column.fieldName !== null || column.fieldName !== undefined) {
      const index = columnFieldNames.indexOf(column.fieldName);
      const type = columnTypes[index];
      // if (type === "object") {
      //   return;
      // }

      const newItems = copyAndSort(gridItems, column.fieldName, type, !column.isSortedDescending);
      const newSlicedItems = sliceData(newItems, selectedPage - 1, defaultPageSize);
      setDetailsState({
        gridItems: newItems,
        currentItems: newSlicedItems,
        isSortedIndex: index,
        isSortedDescending: !column.isSortedDescending,
        defaultPageSize
      });
    }
  };

export function extendColumns(
  columnGenerator: () => IExtendedColumn[],
  selectedPage: number,
  defaultPageSize: number,
  items: any[],
  setDetailsState: React.Dispatch<RequireAtLeastOne<IDetailsState, keyof IDetailsState>>,
  disableSorting: boolean,
  isSortedIndex?: number,
  isSortedDescending?: boolean
): IInternalExtendedColumn[] {
  const columns = columnGenerator();
  const fieldNames = columns.map((col) => col.fieldName || v4());
  const columnTypes = columns.map((col) => col.type);
  const extColumns = columns.map((col, index: number) => {
    const extensions = {
      isCollapsible: true,
      isMultiline: false,
      isRowHeader: false,
      isResizable: true
    } as Pick<
      IColumn,
      | "onColumnClick"
      | "maxWidth"
      | "isCollapsible"
      | "isMultiline"
      | "isRowHeader"
      | "isResizable"
      | "isSorted"
      | "isSortedDescending"
    >;
    if ("fieldName" in col && !disableSorting) {
      if (index === isSortedIndex) {
        extensions.isSorted = true;
        extensions.isSortedDescending = isSortedDescending;
      }

      extensions.onColumnClick = ColumnClickListenerConstructor(
        selectedPage,
        defaultPageSize,
        items,
        setDetailsState,
        fieldNames,
        columnTypes
      );
    }
    const newCol: IInternalExtendedColumn = Object.assign(col, extensions);
    return newCol;
  });
  return extColumns;
}

export const columnReorderingHandling = (
  { draggedIndex, targetIndex }: IColumnDragDropDetails,
  columns: IExtendedColumn[]
): UserConfiguration["columnPositions"] => {
  const draggedItems = columns[draggedIndex];
  const newColumns = [...columns];

  // insert before the dropped item
  newColumns.splice(draggedIndex, 1);
  newColumns.splice(targetIndex, 0, draggedItems);
  const columnPositions = newColumns.reduce((ctr, col, index) => {
    (ctr as any)[col.name] = index;
    return ctr;
  }, {} as UserConfiguration["columnPositions"]);

  return columnPositions;
};
