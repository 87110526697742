import { Text } from "@fluentui/react";
import React, { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { LoadingSpinner } from "../../../Shared/components/LoadingSpinner/LoadingSpinner";
import { IPageOverlay, PageOverlay } from "../../../Shared/components/PageOverlay/PageOverlay";
import { IUserProviderState, MockDataFn } from "../../../Shared/utilities/RequestUtilities";
import { LoadingStatus } from "../../../Shared/utilities/Utilities";
import {
  AutomatedDashboardConfiguration,
  AutomatedJeDashboardElement
} from "../AutomatedJeDashboard/AutomatedJeDashboard.requests";
import { JeDetails } from "../JeDetails/JeDetails";
import { getEntry } from "../JeDetails/JeDetails.requests";
import { FullJeDetails } from "../JeDetails/JEDetails.schema";

export interface IJeDetailsManager {
  props: {
    mockFn?: MockDataFn<FullJeDetails>;
    configuration: AutomatedDashboardConfiguration;
    getTokenFn?: IUserProviderState["accessToken"];
  };
  ref: {
    open: (item: AutomatedJeDashboardElement) => void;
    getOnSubmitAsyncSubject: () => any;
  };
}

const JeDetailsManager = forwardRef((props: IJeDetailsManager["props"], ref: React.Ref<IJeDetailsManager["ref"]>) => {
  const pageOverlayRef = useRef<IPageOverlay["ref"]>(null);
  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.Idle);
  const [details, setDetails] = useState<FullJeDetails | null>(null);
  useImperativeHandle(ref, () => ({
    open(item) {
      setLoadingStatus(LoadingStatus.Pending);
      const getDetails = async () => {
        try {
          if (pageOverlayRef.current) {
            pageOverlayRef.current.open();
            window.scrollTo(0, 0);
          }
          const entry = await getEntry(item, props.configuration, {
            mockFn: props.mockFn,
            getTokenFn: props.getTokenFn
          });
          setDetails(entry);
          setLoadingStatus(LoadingStatus.Resolved);
        } catch (e) {
          setLoadingStatus(LoadingStatus.Rejected);
        }
      };
      getDetails();
    },
    getOnSubmitAsyncSubject() {
      return false;
    }
  }));

  return (
    <React.Fragment>
      <PageOverlay
        ref={pageOverlayRef}
        label="JE Details"
        closeLabel="Close JE"
        onClose={() => {
          setDetails(null);
        }}
      >
        {loadingStatus === LoadingStatus.Pending ? (
          <LoadingSpinner label="Loading Details" />
        ) : loadingStatus === LoadingStatus.Rejected ? (
          <Text variant="xLarge">Unable to load details for entries</Text>
        ) : details === null ? (
          <Text
            variant="xLarge"
            style={{
              color: "var(--accent-font-color, gray)",
              textAlign: "center"
            }}
          >
            Je Details not available.
          </Text>
        ) : (
          <JeDetails details={details}></JeDetails>
        )}
      </PageOverlay>
    </React.Fragment>
  );
});

export { JeDetailsManager };
