export function ConvertToDefaultTimeString(d: Date) {
  const dateOptions: Intl.DateTimeFormatOptions = { day: "2-digit", month: "2-digit", year: "numeric" };
  const timeOptions: Intl.DateTimeFormatOptions = {
    hour12: true,
    hour: "2-digit",
    minute: "2-digit",
    timeZoneName: "short"
  };
  return `${d.toLocaleDateString("en-US", dateOptions)} ${d.toLocaleTimeString("en-US", timeOptions)}`;
}
