import {
  ContextualMenu,
  css,
  DefaultButton,
  Dialog,
  DialogFooter,
  Label,
  Link,
  Pivot,
  PivotItem,
  PrimaryButton,
  ProgressIndicator,
  SelectionMode,
  Separator
} from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import {
  ActionTypes,
  DashboardGrid,
  // exportToExcel,
  GeneralLedgerApi,
  GLDashboardRow,
  GLSapDetails,
  IExtendedColumn,
  IUserProviderState,
  LoadingStatus,
  postRequest,
  SapDetailsPayload,
  useQuery,
  UserContext
} from "@jem/components";
import React, { PropsWithChildren, useContext, useEffect, useImperativeHandle, useState } from "react";
import { For } from "react-loops";
import { AsyncSubject } from "rxjs";

let onSubmitAsyncSubject: null | AsyncSubject<boolean | null> = null;

export interface GLDashboardDialogRef {
  open: (actionName: ActionTypes, items: GLDashboardRow[], rejectedItems: GLDashboardRow[]) => Promise<void>;
  getOnSubmitAsyncSubject: () => AsyncSubject<boolean | null>;
}

interface ToApplyActionColumn extends IExtendedColumn {
  fieldName: keyof GLDashboardRow;
}

export const getToApplyActionColumns = (isDrafts: boolean) => (): IExtendedColumn[] => {
  const cols: ToApplyActionColumn[] = [
    {
      key: "jeNumberOrId",
      name: isDrafts ? "Name" : "JE Number",
      fieldName: "idForUI",
      type: "string",
      minWidth: 175
    },
    {
      key: "status",
      name: "Status",
      fieldName: "status",
      type: "string",
      minWidth: 250
    },
    {
      key: "companyCode",
      name: "Company Code",
      fieldName: "companyCode",
      type: "string",
      minWidth: 200
    },
    {
      key: "fiscalYear",
      name: "Fiscal Year",
      fieldName: "fiscalYear",
      type: "string",
      minWidth: 200
    },
    {
      key: "fiscalPeriod",
      name: "Fiscal Period",
      fieldName: "fiscalPeriod",
      type: "string",
      minWidth: 200
    }
  ];

  return cols;
};

export interface GLDashboardDialogProps {
  customRef: React.RefObject<GLDashboardDialogRef>;
  configuration: GeneralLedgerApi;
}

interface BulkModel {
  coCode: string;
  fiscalYear: string;
  fiscalPeriod: string;
  id: string;
  jeNum: string;
  parentId: string;
  parentJENum: string;
}
interface AffectedBulkFromActionColumn extends IExtendedColumn {
  fieldName: keyof BulkModel;
}

export const getAffectedBulkForActionColumns =
  (
    setPivotKey: React.Dispatch<React.SetStateAction<"ToApplyAction" | "Bulks" | "SAPDetails">>,
    setModelForSapDetails: React.Dispatch<React.SetStateAction<SapDetailsPayload | null>>
  ) =>
  (): IExtendedColumn[] => {
    const cols: AffectedBulkFromActionColumn[] = [
      {
        key: "JENumber",
        name: "JE Number",
        fieldName: "jeNum",
        type: "string",
        minWidth: 125
      },
      {
        key: "companyCode",
        name: "Company Code",
        fieldName: "coCode",
        type: "string",
        minWidth: 120
      },
      {
        key: "fiscalYear",
        name: "Fiscal Year",
        fieldName: "fiscalYear",
        type: "string",
        minWidth: 100
      },
      {
        key: "fiscalPeriod",
        name: "Fiscal Period",
        fieldName: "fiscalPeriod",
        type: "string",
        minWidth: 100
      },
      {
        key: "sapDetails",
        name: "SAP Details",
        fieldName: "parentJENum",
        type: "string",
        minWidth: 200,
        onRender: (item: BulkModel) => {
          return (
            <Link
              onClick={() => {
                setModelForSapDetails({
                  compcode: Number(item.coCode),
                  docNum: Number(item.jeNum),
                  fYear: Number(item.fiscalYear),
                  Module: "",
                  jeType: ""
                });
                setPivotKey("SAPDetails");
              }}
            >
              SAP Details
            </Link>
          );
        }
      }
    ];

    return cols;
  };

async function fetchBulksFromIds(
  configuration: GeneralLedgerApi,
  bulks: string,
  accessToken: IUserProviderState["accessToken"]
) {
  const endpoint = `${configuration.baseApiUrl}${configuration.endpoints.getBulkFromListOfIds}`;
  const payload = bulks;
  const bulkObjects = await postRequest<BulkModel[]>(endpoint, payload, accessToken);
  return bulkObjects;
}

const GLDashboardDialog = (props: PropsWithChildren<GLDashboardDialogProps>) => {
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
  const [itemsForUI, setItemsForUi] = useState<GLDashboardRow[]>([]);
  const [rejectedItemsForUI, setRejectedItemsForUi] = useState<GLDashboardRow[]>([]);
  const [_actionName, setActionName] = useState<ActionTypes | null>(null);
  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.Idle);
  const [bulksJeIds, setBulksJeIds] = useState<string>("");
  const [modelForSapDetails, setModelForSapDetails] = useState<SapDetailsPayload | null>(null);
  const [pivotKey, setPivotKey] = useState<"ToApplyAction" | "Bulks" | "SAPDetails">("ToApplyAction");
  const userContext = useContext(UserContext);

  const {
    isLoading,
    data: bulkObjects,
    isFetching
  } = useQuery({
    queryKey: ["bulks", bulksJeIds],
    queryFn: async (queryOptions): Promise<{ [key: string]: BulkModel[] }> => {
      const [, theBulkJes] = queryOptions.queryKey as [string, string];
      if (theBulkJes === "") {
        return Promise.resolve({});
      }
      const bulks = await fetchBulksFromIds(props.configuration, theBulkJes, userContext.accessToken);
      if (!bulks) return Promise.resolve({});
      // group them in an object by key : ParentJeNum and value array of jes with it
      const bulkObjectsGrouped = bulks.reduce((acc, cur) => {
        if (acc[cur.parentJENum]) {
          acc[cur.parentJENum].push(cur);
        } else {
          acc[cur.parentJENum] = [cur];
        }
        return acc;
      }, {} as { [key: string]: BulkModel[] });

      return bulkObjectsGrouped;
    },
    enabled: bulksJeIds !== ""
  });

  useEffect(() => {
    if (isLoading || isFetching) {
      // we are still loading here
      if (loadingStatus !== LoadingStatus.Pending) setLoadingStatus(LoadingStatus.Pending);
      return;
    }

    const timer = setTimeout(() => {
      setLoadingStatus(LoadingStatus.Resolved);
    }, 150);
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [status, isFetching, isLoading, bulkObjects]);

  useImperativeHandle(props.customRef, () => ({
    getOnSubmitAsyncSubject() {
      onSubmitAsyncSubject = new AsyncSubject<boolean | null>();
      return onSubmitAsyncSubject;
    },
    async open(actionName: ActionTypes, items: GLDashboardRow[], rejectedItems: GLDashboardRow[]) {
      const bulks = items
        .filter((item) => item.postingType === "7")
        .map((item) => item.jeId)
        .filter((bulk) => bulk);
      if (bulks.length > 0) {
        setLoadingStatus(LoadingStatus.Pending);
        const bulkJeIds = bulks.join(";");
        setBulksJeIds(bulkJeIds);
      } else {
        if (bulksJeIds !== "") {
          setBulksJeIds("");
        }
        setLoadingStatus(LoadingStatus.Resolved);
      }

      setActionName(actionName);
      setItemsForUi(items);
      setRejectedItemsForUi(rejectedItems);
      toggleHideDialog();
    }
  }));

  let message = "Please select the records you want to apply the action.";
  if (itemsForUI.length === 0 && rejectedItemsForUI.length !== 0) {
    message = "Selection has no valid records to apply the action.";
  }
  if (itemsForUI.length !== 0 && rejectedItemsForUI.length === 0) {
    message = `This action can be performed only on ${itemsForUI.length} records.`;
  }
  if (itemsForUI.length !== 0 && rejectedItemsForUI.length !== 0) {
    message = `This action can be performed only on ${itemsForUI.length} records. ${rejectedItemsForUI.length} records are ineligible.`;
  }

  return (
    <>
      <Dialog
        hidden={hideDialog}
        onDismiss={() => {
          if (onSubmitAsyncSubject) {
            onSubmitAsyncSubject.next(false);
            onSubmitAsyncSubject.complete();
            onSubmitAsyncSubject = null;
          }
          setBulksJeIds("");
          setModelForSapDetails(null);
          toggleHideDialog();
        }}
        modalProps={{
          isBlocking: true,
          topOffsetFixed: true,
          dragOptions: {
            moveMenuItemText: "Move",
            closeMenuItemText: "Close",
            menu: ContextualMenu
          }
        }}
        minWidth={itemsForUI.length !== 0 ? 1000 : 300}
      >
        <>
          {loadingStatus === LoadingStatus.Pending ? (
            <>
              <ProgressIndicator label="Loading Content of Selected Bulks..." />
              <div style={{ height: "8px" }} />
            </>
          ) : null}
          <Pivot
            aria-label="JEs for Action"
            linkFormat="tabs"
            selectedKey={pivotKey}
            onLinkClick={(item?: PivotItem) => {
              if (item) {
                setPivotKey(item.props.itemKey as "ToApplyAction" | "Bulks" | "SAPDetails");
              }
            }}
          >
            <PivotItem headerText="Eligible JEs" itemKey="ToApplyAction">
              <Label styles={{ root: { marginTop: 10 } }}>{message}</Label>
              {itemsForUI.length === 0 ? null : (
                <div
                  className={css`
                    border-left: 2px solid;
                    padding-left: 10px;
                  `}
                  data-is-scrollable="true"
                >
                  <DashboardGrid
                    idForLocalStorage={"gridForActionsToExport"}
                    items={itemsForUI}
                    isSortedIndex={1}
                    height={`500px`}
                    isSortedDescending={true}
                    selectionMode={SelectionMode.none}
                    // onExport={(rows) => {
                    //   exportToExcel({
                    //     sheetName: `AllowedJEsFor${actionName}`,
                    //     rowsWithHeader: rows,
                    //     fileName: `${actionName}.xlsx`
                    //   });
                    // }}
                    disablePagination={true}
                    columnGenerator={getToApplyActionColumns(itemsForUI[0].isDraft)}
                    customRef={null}
                  ></DashboardGrid>
                </div>
              )}
            </PivotItem>

            {bulkObjects && Object.keys(bulkObjects).length !== 0 && _actionName !== ActionTypes.reverse && (
              <PivotItem headerText={`Bulks`} itemKey={"Bulks"}>
                <For
                  in={bulkObjects}
                  as={(bulks, { key: BulkParentJeNum }) => (
                    <>
                      <Label
                        styles={{ root: { marginTop: 10 } }}
                      >{`Because of selected JENum: ${BulkParentJeNum}, below ${bulks.length} JE${
                        bulks.length === 1 ? "" : "s"
                      } will also be impacted:`}</Label>
                      <DashboardGrid
                        idForLocalStorage={"gridForAffectedBulk"}
                        items={bulks}
                        isSortedIndex={1}
                        height={`${Math.max((bulks.length + 1) * 43 + 22, 62)}px`}
                        isSortedDescending={true}
                        selectionMode={SelectionMode.none}
                        // onExport={(rows) => {
                        //   exportToExcel({
                        //     sheetName: `AffectedBulkJEs${BulkParentJeNum}`,
                        //     rowsWithHeader: rows,
                        //     fileName: `${actionName}Bulk${BulkParentJeNum}.xlsx`
                        //   });
                        // }}
                        disablePagination={true}
                        columnGenerator={getAffectedBulkForActionColumns(setPivotKey, setModelForSapDetails)}
                        customRef={null}
                      ></DashboardGrid>
                      <Separator />
                    </>
                  )}
                />
              </PivotItem>
            )}
            {!modelForSapDetails ? null : (
              <PivotItem
                headerText={`SAP Details ${modelForSapDetails.docNum}`}
                itemKey={"SAPDetails"}
                alwaysRender={true}
              >
                <GLSapDetails configuration={props.configuration} payloadForService={modelForSapDetails} load={true} />
              </PivotItem>
            )}
          </Pivot>
        </>
        <DialogFooter>
          {itemsForUI.length === 0 ? null : (
            <PrimaryButton
              onClick={() => {
                if (onSubmitAsyncSubject) {
                  onSubmitAsyncSubject.next(true);
                  onSubmitAsyncSubject.complete();
                  onSubmitAsyncSubject = null;
                }
                setBulksJeIds("");
                setModelForSapDetails(null);
                toggleHideDialog();
              }}
              text="Apply"
              disabled={loadingStatus === LoadingStatus.Pending}
            />
          )}
          <DefaultButton
            onClick={() => {
              if (onSubmitAsyncSubject) {
                onSubmitAsyncSubject.next(false);
                onSubmitAsyncSubject.complete();
                onSubmitAsyncSubject = null;
              }
              setBulksJeIds("");
              setModelForSapDetails(null);
              toggleHideDialog();
            }}
            text={itemsForUI.length === 0 ? "Close" : "Cancel"}
          />
        </DialogFooter>
      </Dialog>
    </>
  );
};

GLDashboardDialog.displayName = "GLDashboardDialog";
export default GLDashboardDialog;
