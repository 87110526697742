import React from "react";
import { IconButton, TooltipHost } from "@fluentui/react";
import { DropdownContextualMenu } from "../DropdownContextualMenu/DropdownContextualMenu";

export interface IDashboardGridColumnSelectorProps {
  selectedColumns: string[] | null;
  columnsForDropdown: string[];
  onSelectionChange: (newSelection: string[] | null) => void;
  onDefaultClick: () => void;
}

let DashboardGridColumnSelector: React.FC<IDashboardGridColumnSelectorProps> = (props) => {
  return (
    <>
      <DropdownContextualMenu
        name="Column Selector"
        singleSelection={false}
        selected={props.selectedColumns}
        available={props.columnsForDropdown}
        onSelectionChange={props.onSelectionChange}
        styles={null}
        useIconButton={true}
      />
      <TooltipHost content="Back to Default Columns">
        <IconButton
          onClick={props.onDefaultClick}
          iconProps={{ iconName: "Table" }}
          ariaLabel="Back to Default Columns"
        />
      </TooltipHost>
    </>
  );
};

DashboardGridColumnSelector = React.memo(DashboardGridColumnSelector, (prevProps, nextProps) => {
  if (prevProps.selectedColumns !== nextProps.selectedColumns) return false;
  if (prevProps.columnsForDropdown !== nextProps.columnsForDropdown) return false;
  if (prevProps.onSelectionChange !== nextProps.onSelectionChange) return false;
  if (prevProps.onDefaultClick !== nextProps.onDefaultClick) return false;

  return true;
});
DashboardGridColumnSelector.displayName = "DashboardGridColumnSelector";

export { DashboardGridColumnSelector };
