import React, { PropsWithChildren, useContext, useRef, useState } from "react";
import { ActionsContext } from "./Actions.ActionsContext";
import { ActionTitle } from "./Actions.ActionTitle";
import { ActionOnSubmitHandlerMap, BaseActionProps, CommonPropsOnRows } from "./Actions.types";
import { Attachments } from "../Attachments/Attachments";
import { ActionResult, ActionTypes } from "../ActionManager/ActionsManager.types";
import { submissionStyles } from "./Actions.BottomButtons";
import { BaseButton, Button, DefaultButton, ITextField, PrimaryButton, Separator, TextField } from "@fluentui/react";
import {
  IAttachmentEntryFormSingleUploadProps,
  IAttachmentEntryFormProps,
  IAttachmentEntryFormRef,
  GLAttachment
} from "../Attachments/Attachments.types";

interface RescindMainProps<ListType, ResultType> extends BaseActionProps<ListType, ResultType> {
  onSubmitHandler: ActionOnSubmitHandlerMap<ListType, ResultType>[ActionTypes.rescind];
  onUploadFile: IAttachmentEntryFormSingleUploadProps["onUploadFile"];
}
export type RescindProps<ListType, ResultType> = RescindMainProps<ListType, ResultType> &
  Omit<IAttachmentEntryFormProps, "customRef">;

const Rescind = <ListType extends CommonPropsOnRows, ResultType extends ActionResult>(
  props: PropsWithChildren<RescindProps<ListType, ResultType>>
) => {
  const { styles } = useContext(ActionsContext);
  const attachmentsRef = useRef<IAttachmentEntryFormRef<GLAttachment>>(null);
  const textRef = useRef<ITextField>(null);
  const [disabled, setDisabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  const onSubmit = async (
    event: React.MouseEvent<
      HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | BaseButton | Button | HTMLSpanElement,
      MouseEvent
    >
  ) => {
    event.stopPropagation();
    if (textRef.current) {
      if (!textRef.current.value) {
        setErrorMessage("Please add a value");
      } else {
        if (errorMessage) {
          setErrorMessage("");
        }
        if (attachmentsRef.current) {
          const [attachments, error] = await attachmentsRef.current.saveAndGetAttachments();
          if (!error) {
            const result = await props.onSubmitHandler(props.items, textRef.current.value, attachments.attachments);
            props.onSubmit(result);
          } else {
            setDisabled(true);
          }
        }
      }
    }
  };

  const onCancel = (
    event: React.MouseEvent<
      HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | BaseButton | Button | HTMLSpanElement,
      MouseEvent
    >
  ) => {
    event.stopPropagation();
    setErrorMessage("");
    props.onCancel();
  };

  return (
    <>
      <ActionTitle onCloseButton={() => props.onCancel()} name={"Rescind"}></ActionTitle>
      <div className={styles.contentStyles.body}>
        <div className="action-manager--footer-action">
          <Separator
            styles={{
              root: {
                lineHeight: "3px"
              }
            }}
          />
          <TextField
            name="dialogText"
            label={"Comments"}
            componentRef={textRef}
            errorMessage={errorMessage}
            onChange={async (event, newValue) => {
              event.stopPropagation();
              const textBoxVal = newValue;
              const theresNoAttachments = attachmentsRef.current?.getLocalAttachments().length === 0;
              const theresAnErrorInAttachments = attachmentsRef.current?.getError() !== null;
              const theresNoTextBoxVal = !textBoxVal || textBoxVal === "";
              if (theresNoTextBoxVal) {
                setErrorMessage("Please add a value");
                setDisabled(true);
              } else if (theresNoAttachments || theresAnErrorInAttachments) {
                setErrorMessage("");
                setDisabled(true);
              } else {
                setErrorMessage("");
                setDisabled(false);
              }
            }}
          />
          <Separator
            styles={{
              root: {
                lineHeight: "3px"
              }
            }}
          />
          <Attachments
            customRef={attachmentsRef}
            onUploadFile={props.onUploadFile}
            onDeleteFile={props.onDeleteFile}
            onDownloadFile={props.onDownloadFile}
            onChange={(lAttachments, rAttachments, errorMessage) => {
              const textBoxVal = textRef.current ? textRef.current.value : "";
              const theresNoAttachments = lAttachments.length === 0;
              const theresAnErrorInAttachments = errorMessage !== null;
              const theresNoTextBoxVal = textBoxVal === "";
              if (theresNoTextBoxVal) {
                setErrorMessage("Please add a value");
                setDisabled(true);
              } else if (theresNoAttachments || theresAnErrorInAttachments) {
                setErrorMessage("");
                setDisabled(true);
              } else {
                setErrorMessage("");
                setDisabled(false);
              }
            }}
          ></Attachments>
        </div>
        <div className={submissionStyles}>
          <div className="action-manager--footer-buttons">
            <PrimaryButton onClick={onSubmit} text={"Rescind"} disabled={disabled} />
            <DefaultButton onClick={onCancel} text="Cancel" />
          </div>
        </div>
      </div>
    </>
  );
};

Rescind.displayName = "Rescind";

export { Rescind };
