import React from "react";
import { JemConfiguration } from "../../../JemConfiguration";
import { IUserProviderState } from "../../utilities/RequestUtilities";
import { StringWithAutoComplete } from "../../utilities/TypeUtils";
import { getProfile } from "./UserContext.roles.requests";

// TODO: Finalize these names
export enum EUARoles {
  JEAuditor = "JE Auditor",
  ToolAdministrator = "Tool Administrator",
  TreasuryAdministrator = "Treasury Admin",
  ICCOEAnalyst = "ICCOE Analyst",
  BulkPoster = "Bulk Poster",
  SubledgerAdministrator = "Subledger Administrator",
  SubledgerAuditor = "Subledger Auditor",
  SubledgerReviewer = "Subledger Reviewer",
  FinacialControllerPrimary = "Finacial Controller - Primary",
  FinacialControllerSecondary = "Finacial Controller - Secondary",
  TreasuryPOReader = "Treasury-PO Reader"
}

export type JEMRole = StringWithAutoComplete<EUARoles>;

export interface JemUserRoles {
  roles: JEMRole[];
  supervisor: string;
  euaPortalUrl: string;
  accessMessageComponent: React.FC;
  hasSapAccess: boolean;
  hasIhccAccess: boolean;
  hasSapReversalAccess: boolean;
  hasF05Access: boolean;
  hasMsSalesAccess: boolean;
  currencyFormat: string;
  dateFormat: string;
  delegatedManager: string;
  tenants: { tenant: string; appId: string }[];
}

// Before exposing this figure out which
// parts are critical
interface InternalRoles {
  fullName: string;
  supervisor: string;
  alias: string;
  sapJeCurrencyFormat: string;
  backupAfcCompanyCodes: string;
  sapIhccCurrencyFormat: string;
  dregion: string;

  sapJeDateFormat: number;
  sapIhccDateFormat: number;

  isAdmin: boolean;
  hasSapAccess: boolean;
  hasIhccAccess: boolean;
  hasSapReversalAccess: boolean;
  hasF05Access: boolean;
  hasMsSalesAccess: boolean;
  isBackupAfc: boolean;
  isActive: boolean;
  isTreasuryAdmin: boolean;

  fromClaimsCanPostJes: boolean;
  fromClaimsCanPostIhccs: boolean;
  fromClaimsInActive: boolean;

  roles?: string[];
}

interface AccessMessage {
  internalRoles: Pick<
    InternalRoles,
    | "roles"
    | "isActive"
    | "hasIhccAccess"
    | "hasSapAccess"
    | "hasSapReversalAccess"
    | "hasF05Access"
    | "hasMsSalesAccess"
  >;
}

export const BlankAccessMessage: React.FC = () => {
  return (
    <>
      Currently, you are <b>not authorized to post</b>. Please contact{" "}
      <a href="mailto:finsup@microsoft.com?subject=Need%20Help%20With%20JEM" target="_blank">
        FinSup
      </a>{" "}
      for more details.
    </>
  );
};

export const AccessMessage: React.FC<AccessMessage> = ({ internalRoles }) => {
  if (internalRoles.roles === undefined || internalRoles.roles.length === 0) {
    return <></>;
  }

  if (!internalRoles.isActive) {
    return <BlankAccessMessage />;
  }
  const message = "Currently, you can post";
  let jeMessage,
    poMessage,
    reversalMessage,
    f05Message = "";

  if (
    internalRoles.hasIhccAccess ||
    internalRoles.hasSapAccess ||
    internalRoles.hasSapReversalAccess ||
    internalRoles.hasF05Access
  ) {
    if (internalRoles.hasSapAccess) {
      jeMessage = " JEs";
    }
    if (internalRoles.hasIhccAccess) {
      poMessage = " POs";
    }
    if (internalRoles.hasSapReversalAccess) {
      reversalMessage = " reverse JEs";
    }
    if (internalRoles.hasF05Access) {
      f05Message = " F-05 JEs";
    }
    return (
      <>
        {message}{" "}
        <b>
          {jeMessage}, {f05Message}, {poMessage}, {reversalMessage}
        </b>
        .
      </>
    );
  }
  if (!internalRoles.hasIhccAccess && !internalRoles.hasSapAccess) {
    return (
      <>
        Currently, you are <b>not authorized to post</b>.
      </>
    );
  }
  // if (internalRoles.hasIhccAccess && internalRoles.hasSapAccess) {
  //   if (internalRoles.hasSapReversalAccess) {
  //     return (
  //       <>
  //         Currently, you <b>can post JEs and POs and reverse JEs</b>.
  //       </>
  //     );
  //   } else {
  //     return (
  //       <>
  //         Currently, you <b>can post JEs and POs</b>.
  //       </>
  //     );
  //   }
  // }
  // if (internalRoles.hasIhccAccess) {
  //   if (internalRoles.hasSapReversalAccess) {
  //     return (
  //       <>
  //         Currently, you <b>can post POs and reverse JEs</b>.
  //       </>
  //     );
  //   } else {
  //     return (
  //       <>
  //         Currently, you <b>can post POs</b>.
  //       </>
  //     );
  //   }
  // }
  // if (internalRoles.hasSapAccess) {
  //   if (internalRoles.hasSapReversalAccess) {
  //     return (
  //       <>
  //         Currently, you <b>can post JEs and reverse JEs</b>.
  //       </>
  //     );
  //   } else {
  //     return (
  //       <>
  //         Currently, you <b>can post JEs</b>.
  //       </>
  //     );
  //   }
  // }
  // if (internalRoles.hasSapReversalAccess) {
  //   return (
  //     <>
  //       Currently, you <b>can reverse JEs</b>.
  //     </>
  //   );
  // }
  return <></>;
};

export function withRolesAndClaims(
  WrappedComponent: React.ComponentType<AccessMessage>,
  internalRoles: AccessMessage["internalRoles"]
): React.FC {
  return () => {
    return <WrappedComponent internalRoles={internalRoles} />;
  };
}

export async function getRolesForUI(
  configuration: Pick<JemConfiguration, "euaAccessPortal" | "GeneralLedgerApi">,
  getTokenFn: IUserProviderState["accessToken"],
  refetchUserJemRoles: boolean
): Promise<JemUserRoles> {
  const profile = await getProfile(configuration.GeneralLedgerApi, getTokenFn, refetchUserJemRoles);
  // NOTE: DONT RETURN THIS VARIABLE, KEEP IT PRIVATE AND USE IT IN THE COMPONENT
  // RETURN SOMETHING SIMPLER

  const accessMessageComponent = withRolesAndClaims(AccessMessage, profile);

  return {
    roles: profile.roles,
    supervisor: profile.supervisor,
    euaPortalUrl: configuration.euaAccessPortal,
    accessMessageComponent,
    hasSapAccess: profile.hasSapAccess,
    hasIhccAccess: profile.hasIhccAccess,
    hasSapReversalAccess: profile.hasSapReversalAccess,
    hasF05Access: profile.hasF05Access,
    hasMsSalesAccess: profile.hasMsSalesAccess,
    currencyFormat: profile.currencyFormat,
    dateFormat: profile.dateFormat,
    delegatedManager: profile.delegatedManager,
    tenants: profile.tenants
  };
}
