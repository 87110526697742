import { CoherencePanel, CoherencePanelSize } from "@coherence-design-system/controls";
import {
  Checkbox,
  Dropdown,
  IDropdownOption,
  IDropdownProps,
  Label,
  SpinButton,
  Text,
  TextField,
  useTheme
} from "@fluentui/react";
import {
  CompanyCodeMultiSelector,
  convertToReasonCodeDropdownOptions,
  DomainDataEnum,
  DomainDataObjects,
  DraftDateCodeDropdown,
  FiscalPeriodStringTransformations,
  FrequencyDropdown,
  JEMAliasPicker,
  JEMChecklistDetailsRow,
  JemConfiguration,
  JEMContext,
  convertToCurrecyDropdownOptions,
  convertToJeTypeDropdownOptions
} from "@jem/components";
import { isValid } from "date-fns";
import React, { useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import ActionButtons from "../../copiedItems/ActionButtons";
import InlineFormInputErrorMessage from "./InlineFormInputErrorMessage";

interface IJEMChecklistDetailsEditPanelProps {
  checklistItem: JEMChecklistDetailsRow;
  configuration: JemConfiguration;
  checklistPeriod: string;
  onClosePanel: () => void;
  onSaveItem: (editedItem: JEMChecklistDetailsRow) => void;
  loading?: boolean;
  error: string[];
}

const taskTypesOptionsDropdown = (): IDropdownProps["options"] => {
  const TaskTypes: string[] = ["Draft JE", "Batch JE", "Bulk JE"];
  const TaskTypeObject: IDropdownProps["options"] = [];
  for (let i = 0; i < TaskTypes.length; i++) {
    TaskTypeObject.push({
      key: TaskTypes[i],
      text: TaskTypes[i]
    });
  }
  return TaskTypeObject;
};

const JEMChecklistDetailsEditPanel = (props: IJEMChecklistDetailsEditPanelProps): JSX.Element => {
  const { checklistItem, onClosePanel, onSaveItem, error, loading } = props;
  const theme = useTheme();
  const form = useForm<JEMChecklistDetailsRow>();
  const { control, errors, getValues, setValue, handleSubmit } = form;

  const [blockOnCompanyCodeFlag, setBlockOnCompanyCodeFlag] = useState<boolean>(false);
  const [jeType, setJeType] = useState<string>(checklistItem.jeType);
  const [companyCodes, setCompanyCodes] = useState<number[]>(checklistItem.companycode);
  const [backupPosters, setBackupPosters] = useState<string[]>(checklistItem.backupPoster);
  const [backupReviewers, setBackupReviewers] = useState<string[]>(checklistItem.assignedReviewer);
  const [frequency, setFrequency] = useState<string>(checklistItem.frequency);
  const [draftDateCodeObject] = useState<DomainDataObjects[DomainDataEnum.PostingDueDates][0] | undefined>(
    checklistItem.draftDateCodeObject
  );
  const [estimatedTimeMins, setEstimatedTimeMins] = useState<number>(checklistItem.estimatedTimeMins);
  const [draftDateCode, setDraftDateCode] = useState<string>(checklistItem.draftDateCode);
  const [isF05Posting, setIsF05Posting] = useState<boolean>(checklistItem.isF05Posting);

  const [postingDueDate, setPostingDueDate] = useState<string>(
    checklistItem.postingDueDate && isValid(new Date(checklistItem.postingDueDate))
      ? new Date(checklistItem.postingDueDate).toLocaleDateString("en-US", {
          day: "numeric",
          month: "numeric",
          year: "numeric"
        })
      : draftDateCodeObject
      ? new Date(draftDateCodeObject.assignedDate).toLocaleDateString("en-US", {
          weekday: "short",
          month: "short",
          day: "numeric",
          year: "numeric"
        })
      : ""
  );
  const domainContext = useContext(JEMContext);

  const TaskTypesOptions: IDropdownOption[] = taskTypesOptionsDropdown();

  const reasonCodeOptions = convertToReasonCodeDropdownOptions(
    domainContext.initInfo.values?.JeReasonCodes as DomainDataObjects["JeReasonCodes"]
  );

  const currencyCodeOptions = convertToCurrecyDropdownOptions(
    domainContext.initInfo.values?.CurrencyCodes as DomainDataObjects["CurrencyCodes"]
  );

  const jeTypeOptions = convertToJeTypeDropdownOptions(
    domainContext.initInfo.values?.JeTypes as DomainDataObjects["JeTypes"]
  );

  const allowDualCurrency = domainContext.initInfo.values?.JeParameters.filter(
    (item) => item.name === "ShowDualCurrencyTab"
  ).map((item) => item.showUX)[0];

  const validateCoCodeThenSubmit = () => {
    if (!blockOnCompanyCodeFlag) {
      onHandleSubmit();
    }
  };

  const onHandleSubmit = () => {
    const {
      jeTaskName,
      assignedPoster,
      reasonCode,
      purpose,
      description,
      currencycode,
      documentType,
      referenceNumber
    } = getValues();

    // validation to make sure if its a regular je that only one company code gets sent to API.

    checklistItem.assignedPoster = assignedPoster;
    checklistItem.jeType = jeType;
    checklistItem.jeTaskName = jeTaskName;
    checklistItem.companycode = companyCodes;
    checklistItem.purpose = purpose;
    checklistItem.reasonCode = reasonCode;
    checklistItem.assignedReviewer = backupReviewers;
    checklistItem.backupPoster = backupPosters;
    checklistItem.description = description;
    checklistItem.currencycode = currencycode;
    checklistItem.documentType = documentType;
    checklistItem.referenceNumber = referenceNumber;
    checklistItem.isF05Posting = isF05Posting;

    // POSTING DUE DATES BUSINESS FIELDS
    checklistItem.postingDueDate = postingDueDate;
    checklistItem.draftDateCode = draftDateCode;
    checklistItem.frequency = frequency;
    checklistItem.estimatedTimeMins = estimatedTimeMins;

    onSaveItem(checklistItem);
  };

  const titleText = checklistItem.refGuid ? "Edit JE Task" : "Create JE Task";

  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.large}
      titleText={titleText}
      isOpen
      onDismiss={onClosePanel}
      hasCloseButton
      closeButtonAriaLabel="Close edit JE Task"
      onRenderFooter={(): JSX.Element => (
        <ActionButtons
          mutationLoading={false}
          closePanel={onClosePanel}
          handleSubmit={handleSubmit(validateCoCodeThenSubmit)}
          saveLabel="Save"
          saveTitle="Save"
          cancelLabel="Cancel"
          cancelTitle="Cancel"
          disabled={loading}
        />
      )}
    >
      <InlineFormInputErrorMessage errorMessages={error} />
      <form onSubmit={validateCoCodeThenSubmit}>
        <div className="ms-Grid" dir="ltr">
          <div className="ms-Grid-row">
            <Label>{checklistItem.checklist}</Label>
            {checklistItem.postingPeriod && <Label>Posting Period {checklistItem.postingPeriod}</Label>}
          </div>
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
              <Controller
                as={TextField}
                label="JE Task Name"
                id="jeTaskName"
                required
                name="jeTaskName"
                control={control}
                resizable={false}
                defaultValue={checklistItem.jeTaskName}
                value={checklistItem.jeTaskName}
                errorMessage={errors?.jeTaskName?.message}
                rules={{
                  required: {
                    value: true,
                    message: "Please enter a name"
                  },
                  maxLength: {
                    value: 75,
                    message: "Name cannot have more than 75 characters"
                  }
                }}
              />
            </div>
            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
              <Dropdown
                required
                id="jeType"
                label="JE Type"
                options={TaskTypesOptions}
                errorMessage={errors?.jeType?.message}
                selectedKey={jeType}
                onChange={(_e: any, newValue: IDropdownOption<any> | undefined): void => {
                  if (newValue) {
                    setJeType(newValue.text);
                  }
                }}
              />
            </div>
          </div>
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
              <FrequencyDropdown
                loading={!!loading}
                onChange={(frequency) => {
                  setFrequency(frequency.text);
                }}
                frequency={frequency}
              />
            </div>
            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
              <DraftDateCodeDropdown
                selectedFiscalPeriod={FiscalPeriodStringTransformations.FiscalPeriodStringToFiscalYearAndFiscalMonth(
                  props.checklistPeriod
                )}
                fcwFiscalCalendarLink={props.configuration.FCWFiscalCalendarLink}
                required={true}
                selectedDraftDateCodes={draftDateCode ? [draftDateCode] : []}
                onChange={(draftDateCode) => {
                  if (draftDateCode.length === 1) {
                    setDraftDateCode(draftDateCode[0].draftDateCode);
                    setPostingDueDate(
                      new Date(draftDateCode[0].assignedDate).toLocaleDateString("en-US", {
                        day: "numeric",
                        month: "numeric",
                        year: "numeric"
                      })
                    );
                  } else {
                    setPostingDueDate(
                      checklistItem.postingDueDate && isValid(new Date(checklistItem.postingDueDate))
                        ? new Date(checklistItem.postingDueDate).toLocaleDateString("en-US", {
                            day: "numeric",
                            month: "numeric",
                            year: "numeric"
                          })
                        : ""
                    );
                    setDraftDateCode("");
                  }
                }}
              />
            </div>
          </div>
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
              <Label id="estimatedTimeLabel" required htmlFor="estimatedTime">
                Estimated Time (mins):
              </Label>
              <SpinButton
                id="estimatedTime"
                ariaDescribedBy={`estimatedTimeLabel`}
                aria-labelledby={`estimatedTimeLabel`}
                inputProps={{ "aria-labelledby": "estimatedTimeLabel" }}
                min={0}
                max={2147483647}
                step={1}
                value={estimatedTimeMins !== -1 && estimatedTimeMins ? estimatedTimeMins.toString() : "0"}
                onChange={(e, newValue) => {
                  if (!newValue) {
                    setEstimatedTimeMins(0);
                    return;
                  }
                  setEstimatedTimeMins(parseInt(newValue));
                }}
                incrementButtonAriaLabel={"Increase Estimated Time by 1 minute"}
                decrementButtonAriaLabel={"Decrease Estimated Time by 1 minute"}
                styles={!estimatedTimeMins ? { root: { borderColor: theme.semanticColors.errorText } } : undefined}
              />
              {!estimatedTimeMins && (
                <Text style={{ color: theme.semanticColors.errorText }} variant="small" role="alert">
                  Estimated Time (mins) is required.
                </Text>
              )}
            </div>
            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6"></div>
          </div>
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
              <Controller
                label="Reason Code"
                id="reasonCode"
                name="reasonCode"
                control={control}
                resizable={false}
                defaultValue={checklistItem.reasonCode}
                value={checklistItem.reasonCode}
                errorMessage={errors?.reasonCode?.message}
                render={({ value }) => {
                  return (
                    <Dropdown
                      id="reasonCode"
                      label="Reason Code"
                      placeholder="Select One"
                      selectedKey={value}
                      options={reasonCodeOptions}
                      onChange={(e, newValue: IDropdownOption<any> | undefined): void => {
                        setValue("reasonCode", newValue?.key);
                      }}
                    />
                  );
                }}
              />
            </div>
            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
              <TextField label="Posting Due Date" id="postingDueDate" value={postingDueDate} readOnly={true} />
            </div>
          </div>

          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
              <Controller
                as={TextField}
                label="Purpose"
                id="purpose"
                name="purpose"
                control={control}
                resizable={false}
                defaultValue={checklistItem.purpose}
                value={checklistItem.purpose}
                errorMessage={errors?.purpose?.message}
                rules={{
                  maxLength: {
                    value: 300,
                    message: "Name cannot have more than 300 characters"
                  }
                }}
              />
            </div>
            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
              <Controller
                as={TextField}
                label="Description"
                id="description"
                name="description"
                control={control}
                resizable={false}
                defaultValue={checklistItem.description}
                value={checklistItem.description}
                errorMessage={errors?.description?.message}
                rules={{
                  maxLength: {
                    value: 25,
                    message: "Name cannot have more than 25 characters"
                  }
                }}
              />
            </div>
          </div>

          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12">
              <CompanyCodeMultiSelector
                onChange={(ccs) => {
                  setBlockOnCompanyCodeFlag(ccs.length === 0);
                  setCompanyCodes(ccs);
                }}
                selectedCompanies={companyCodes}
                maxCompanies={jeType.includes("Draft") ? 1 : undefined}
                required={true}
              ></CompanyCodeMultiSelector>
            </div>
          </div>
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12">
              <Controller
                as={TextField}
                label="Assigned Poster"
                id="assignedPoster"
                name="assignedPoster"
                control={control}
                resizable={false}
                defaultValue={checklistItem.assignedPoster}
                value={checklistItem.assignedPoster}
                errorMessage={errors?.assignedPoster?.message}
                rules={
                  {
                    //validate: validateUsers as unknown as Record<string, Validate>
                  }
                }
              />
            </div>
          </div>
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
              <JEMAliasPicker
                label="Backup Posters:"
                initialAliases={backupPosters}
                onChange={(newBackupPosters) => {
                  setBackupPosters(newBackupPosters);
                }}
              />
            </div>
            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
              <JEMAliasPicker
                label="Backup Reviewers:"
                initialAliases={backupReviewers}
                onChange={(newBackupReviewers) => {
                  setBackupReviewers(newBackupReviewers);
                }}
              />
            </div>
          </div>
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
              <Controller
                as={TextField}
                label="Reference Number"
                id="referenceNumber"
                name="referenceNumber"
                control={control}
                resizable={false}
                defaultValue={checklistItem.referenceNumber !== 0 ? checklistItem.referenceNumber.toString() : ""}
                value={checklistItem.referenceNumber !== 0 ? checklistItem.referenceNumber.toString() : ""}
                disabled={jeType ? !jeType.includes("Draft JE") : false}
                errorMessage={errors?.referenceNumber?.message}
                rules={{
                  maxLength: {
                    value: 6,
                    message: "Ref Number must be Numeric and 6 digits long and must not have all zeroes (000000)."
                  }
                }}
              />
            </div>
            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
              <Controller
                label="Currency"
                id="currencycode"
                name="currencycode"
                control={control}
                resizable={false}
                defaultValue={checklistItem.currencycode}
                value={checklistItem.currencycode}
                errorMessage={errors?.currencycode?.message}
                render={({ value }) => {
                  return (
                    <Dropdown
                      id="currencyCode"
                      label="Currency Code"
                      placeholder="Select One"
                      selectedKey={value}
                      disabled={checklistItem.jeType != "" ? !checklistItem.jeType?.includes("Draft JE") : false}
                      options={currencyCodeOptions}
                      onChange={(e, newValue: IDropdownOption<any> | undefined): void => {
                        setValue("currencycode", newValue?.key);
                      }}
                    />
                  );
                }}
              />
            </div>
          </div>
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
              <Controller
                label="Document Type"
                id="documentType"
                name="documentType"
                control={control}
                resizable={false}
                defaultValue={checklistItem.documentType}
                value={checklistItem.documentType}
                errorMessage={errors?.documentType?.message}
                render={({ value }) => {
                  return (
                    <Dropdown
                      id="documentType"
                      label="Document Type"
                      placeholder="Select One"
                      selectedKey={value}
                      options={jeTypeOptions}
                      disabled={checklistItem.jeType != "" ? !checklistItem.jeType?.includes("Draft JE") : false}
                      onChange={(e, newValue: IDropdownOption<any> | undefined): void => {
                        setValue("documentType", newValue?.key);
                      }}
                    />
                  );
                }}
              />
            </div>
            <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4" style={{ marginTop: "30px" }}>
              <Checkbox
                label="Allow Dual Currency Posting"
                checked={isF05Posting}
                onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
                  setIsF05Posting(!!checked);
                }}
                disabled={allowDualCurrency ? (jeType?.includes("Draft JE") ? false : true) : true}
              />
            </div>
          </div>
        </div>
      </form>
    </CoherencePanel>
  );
};
export default JEMChecklistDetailsEditPanel;
