import { FilterState } from "../../../Shared/components/DashboardFilter/DashboardFilter.types";
import { AutomatedJeDashboardElement } from "./AutomatedJeDashboard.requests";

function filterOnSearchCriteria(searchCriteria: string | null, gridItems: AutomatedJeDashboardElement[]) {
  if (searchCriteria !== null && searchCriteria !== "") {
    const newItems: AutomatedJeDashboardElement[] = [];
    for (const row of gridItems) {
      for (const [type, value] of Object.entries(row)) {
        // everything is a string, if it is a number then avoid
        if (typeof value === "number") {
          continue;
        }

        if (type === "jeSAPErrorInfo") {
          continue;
        }

        const colKey = type.toLowerCase();
        if (colKey.indexOf("time") !== -1) {
          const theDate = new Date(value).toLocaleDateString();
          if (theDate && theDate.toLowerCase().indexOf(searchCriteria.toLowerCase()) !== -1) {
            newItems.push(row);
            break;
          }
        } else {
          if (value && value.toLowerCase().indexOf(searchCriteria.toLowerCase()) !== -1) {
            newItems.push(row);
            break;
          }
        }
      }
    }
    return newItems;
  }
  return gridItems;
}

function filterOnCommandBarFilters(
  filtersCriteria: FilterState["filterBar"],
  gridItems: AutomatedJeDashboardElement[]
) {
  const commandBarFilters: Array<"dueDate" | "coCode" | "jeType"> = ["dueDate", "coCode", "jeType"];
  let newItems: AutomatedJeDashboardElement[] = gridItems;
  for (const filterType of commandBarFilters) {
    const filterValue = filtersCriteria[`${filterType}Selected` as keyof FilterState["filterBar"]];
    if (filterValue !== null) {
      if (!filterValue.length) {
        // user removed all items
        return [];
      } else {
        newItems = newItems.filter((row) => {
          if (filterType.indexOf("dueDate") !== -1 && row.dueDate) {
            const theDate = row.dueDate;
            return filterValue.indexOf(theDate) !== -1;
          } else {
            const filterToColumnName = {
              coCode: "companyCode",
              jeType: "jeType"
            };
            const columnName = filterToColumnName[filterType as keyof typeof filterToColumnName];
            const cell = row[columnName as keyof AutomatedJeDashboardElement];
            return filterValue.indexOf(cell as string) !== -1;
          }
        });
      }
    }
  }
  return newItems;
}

function filterBasedOnTile(
  currentTile: string | null,
  gridItems: AutomatedJeDashboardElement[]
): AutomatedJeDashboardElement[] {
  if (currentTile) {
    return gridItems.filter((row) => {
      if (!row.jeStatus) return false;
      return row.jeStatus.toLowerCase().indexOf(currentTile.toLowerCase()) !== -1;
    });
  }
  return gridItems;
}

export const filterRows = (
  filterState: FilterState,
  gridItems: AutomatedJeDashboardElement[]
): AutomatedJeDashboardElement[] => {
  let newItems = gridItems;
  const { currentTile, searchCriteria, filterBar } = filterState;
  newItems = filterBasedOnTile(currentTile, newItems);
  newItems = filterOnCommandBarFilters(filterBar, newItems);
  newItems = filterOnSearchCriteria(searchCriteria, newItems);
  return newItems;
};
