import { EUARoles, FatalError, JemConfiguration, PageStyles, UserContext, UserProfile } from "@jem/components";
import React, { useContext } from "react";
import LinkCards from "../../components/Notifications/LinkCards";
// import { getNewsNotificationForHomeMock } from "../../components/textEditor/NewsNotifications.mocks";
// import TextEditor from "../../components/textEditor/TextEditor";
import { HomeMocks } from "./Home.mock";
import { Calendars } from "../../components/Calendars/Calendars";
import { Stack } from "@fluentui/react";
import { Notifications } from "../../components/Notifications/Notifications";

export interface HomeProps {
  mocks?: HomeMocks;
  config: JemConfiguration;
}

let Home: React.FC<HomeProps> = (props) => {
  const userContext = useContext(UserContext);
  if (!userContext) {
    throw new FatalError("Please use a UserContext Provider.");
  }
  const isAdmin = userContext.jemUser.roles.includes(EUARoles.ToolAdministrator);

  return (
    <div className={PageStyles.rootDiv}>
      <div className="ms-Grid" dir="ltr">
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg8 ms-xl9">
            <div className="ms-Grid" dir="ltr">
              <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12">
                  <Notifications configuration={props.config} isAdmin={isAdmin} />
                </div>
              </div>
              <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12">
                  <Stack
                    horizontal
                    wrap
                    tokens={{
                      childrenGap: 10
                    }}
                  >
                    <Stack.Item>
                      <LinkCards title="My Dashboard" content="View all my pending actions" buttonName="Manage" />
                    </Stack.Item>
                    <Stack.Item>
                      <LinkCards
                        title="Journal Entry"
                        content="Start a new JE using templates"
                        buttonName="Create New"
                      />
                    </Stack.Item>
                  </Stack>
                </div>
              </div>
              <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12">
                  <Calendars config={props.config.GeneralLedgerApi} getTokenFn={userContext.accessToken} />
                </div>
              </div>
            </div>
          </div>
          <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4 ms-xl3">
            <UserProfile />
          </div>
        </div>
      </div>
    </div>
  );
};

Home = React.memo(Home);
Home.displayName = "Home";
export default Home;
