import { OpsDashboardApi, IUserProviderState, MockDataFn, getValidUrl, getRequest } from "@jem/components";
import {
  OpsDashboardActionElement,
  OpsDashboardActionElementButton,
  OpsDashboardActionsFromApi
} from "./Actions.types";

export function payloadToActionsForUI(payload: OpsDashboardActionsFromApi[]): OpsDashboardActionElement[] {
  const actionsForUI = payload.reduce((elements, singleAction) => {
    if (singleAction.nested && singleAction.nested !== null) {
      // add the nested ones
      const nestedActions = singleAction.nested.reduce((nestedElements, nestedSingleAction) => {
        nestedElements.push({
          name: nestedSingleAction.actionTitle,
          actionId: nestedSingleAction.actionId,
          ui: nestedSingleAction.parameters
        });
        return nestedElements;
      }, [] as OpsDashboardActionElementButton[]);
      elements.push({
        name: singleAction.actionTitle,
        actionId: singleAction.actionId,
        actions: nestedActions
      });
    } else {
      elements.push({
        name: singleAction.actionTitle,
        actionId: singleAction.actionId,
        ui: singleAction.parameters
      });
    }
    return elements;
  }, [] as OpsDashboardActionElement[]);
  return actionsForUI;
}

export async function getOpsDashboardActionsData(
  configuration: OpsDashboardApi,
  opts: {
    getTokenFn: IUserProviderState["accessToken"];
    mockFn: MockDataFn<OpsDashboardActionElement[]> | undefined;
  }
): Promise<OpsDashboardActionElement[]> {
  try {
    if (opts.mockFn) {
      return opts.mockFn(opts);
    }
    const endpoint = `${configuration.baseApiUrl}${configuration.endpoints.allActions}`;
    const endpointUrl = getValidUrl(endpoint);
    const payload = await getRequest<OpsDashboardActionsFromApi[]>(endpointUrl, opts.getTokenFn);
    if (!payload) {
      return [];
    }
    const dataForActions = payloadToActionsForUI(payload);
    return dataForActions;
  } catch (e) {
    console.error(e);
    return [];
  }
}
