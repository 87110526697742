import React from "react";
import { Spinner, SpinnerSize, ActionButton, IContextualMenuProps, DefaultButton, Stack } from "@fluentui/react";

export interface TeachingBubbleProps {
  showTeachingBubble?: boolean;
  header?: string;
  content?: string;
  toggleTeachingBubble?: () => void;
}

interface IActionButtonsProps {
  mutationLoading?: boolean;
  closePanel?: () => void;
  handleSubmit: () => void;
  saveTitle: string;
  saveLabel: string;
  cancelTitle: string;
  cancelLabel: string;
  className?: string;
  split?: boolean;
  menuProps?: IContextualMenuProps;
  disabled?: boolean;
}

const ActionButtons = (props: IActionButtonsProps): JSX.Element => {
  const {
    mutationLoading,
    closePanel,
    handleSubmit,
    className,
    saveTitle = "Save",
    saveLabel = "Save",
    cancelTitle = "Cancel",
    cancelLabel = "Cancel",
    split = false,
    menuProps,
    disabled
  } = props;

  const splitStyles = {
    splitButtonContainer: {
      selectors: {
        ".ms-Button": {
          marginRight: "0px"
        }
      }
    },
    splitButtonContainerHovered: { border: "none" }
  };

  return (
    <div className={className}>
      <Stack wrap horizontal horizontalAlign="end" verticalAlign="center" tokens={{ childrenGap: 20 }}>
        {mutationLoading && <Spinner size={SpinnerSize.medium} />}
        <ActionButton
          iconProps={{ iconName: "Cancel" }}
          title={cancelTitle}
          ariaLabel={cancelLabel}
          onClick={closePanel}
          disabled={mutationLoading}
        >
          Cancel
        </ActionButton>
        <DefaultButton
          id="primaryButtonBubbleHook"
          iconProps={{ iconName: "Save" }}
          title={saveTitle}
          ariaLabel={saveLabel}
          onClick={handleSubmit}
          disabled={mutationLoading || disabled}
          split={split}
          splitButtonAriaLabel="See 2 options"
          primary
          menuProps={menuProps}
          styles={split ? splitStyles : {}}
        >
          {saveLabel}
        </DefaultButton>
      </Stack>
    </div>
  );
};

export default ActionButtons;
