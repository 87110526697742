import { css } from "@emotion/css";
import { SelectionMode, Text } from "@fluentui/react";
import {
  DashboardFilter,
  DashboardGrid,
  exportToExcel,
  FatalError,
  FilterSchema,
  FilterState,
  IDashboardGridRef,
  IIndexedTile,
  JemConfiguration,
  JEMContext,
  LoadingStatus,
  MockDataFn,
  useQuery,
  UserContext
} from "@jem/components";

import { OrderedMap } from "immutable";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { JEMChecklistDetailsFilterRows } from "../JEMChecklistDetails/JEMChecklistDetails.filterRows";
import { getJEMChecklistTasksColumns } from "./JEMChecklistDashboardByCompanyCode.columns";
import { getChecklistTasksByCompanyCode } from "./JEMChecklistDashboardByCompanyCode.Requests";
import { JEMChecklistTask } from "./JEMChecklistDashboardByCompanyCode.schemas";
import JEMChecklistsCompanyCodeSelector from "./JEMChecklistsCompanyCodeSelector";
import useLocalStorage, { LocalStorageKeys } from "@jem/components/lib/Shared/hooks/useLocalStorage";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const isEqual = require("lodash/isEqual");
// eslint-disable-next-line @typescript-eslint/no-var-requires
const kebabCase = require("lodash/kebabCase");

export interface JEMChecklistDashboardByCompanyCodeProps {
  configuration: JemConfiguration["GeneralLedgerApi"];
  attachmentsConfiguration: JemConfiguration["DocumentsApi"];
  searchOptions:
    | {
        fiscalYear: string;
        fiscalMonth: string;
        companyCodes: string[];
      }
    | null
    | undefined;
  onSearchOptionsChanged: (options: { fiscalYear: string; fiscalMonth: string; companyCodes: string[] }) => void;
  mockDashboardDataFn?: MockDataFn<any>;
}

interface SearchByOpsDetailsKeyOptions {
  fiscalYear: number;
  fiscalMonth: number;
  companyCodes: string[];
}

const allFilters: FilterSchema<JEMChecklistTask>[] = [
  {
    filterName: "Checklist Name",
    columnName: "parentChecklistName"
  },
  {
    filterName: "Ops Detail",
    columnName: "opsDetailName"
  },
  {
    filterName: "Company Code",
    columnName: "companyCode"
  },
  {
    filterName: "Posting Due Date",
    columnName: "postingDueDate"
  },
  {
    filterName: "Status",
    columnName: "status"
  },
  {
    filterName: "Published State",
    columnName: "publishedState"
  },
  {
    filterName: "Task Type",
    columnName: "taskType"
  },
  {
    filterName: "JE Type",
    columnName: "jeType"
  },
  {
    filterName: "Assigned Poster",
    columnName: "assignedPoster"
  },
  {
    filterName: "Assigned Reviewer",
    columnName: "assignedReviewer"
  },
  {
    filterName: "Author",
    columnName: "author"
  },
  {
    filterName: "Reason Code",
    columnName: "reasonCodeText"
  },
  {
    filterName: "Draft Date Code",
    columnName: "draftDateCode"
  },
  {
    filterName: "Frequency",
    columnName: "frequency"
  },
  {
    filterName: "Estimated Time",
    columnName: "estimatedTimeMins"
  },
  {
    filterName: "Actual Time Taken",
    columnName: "actualTimeTakenMins"
  }
];

const JEMChecklistDashboardByCompanyCode: React.FC<JEMChecklistDashboardByCompanyCodeProps> = (props) => {
  const jemContext = useContext(JEMContext);
  const userContext = useContext(UserContext);
  const [searchKey, setSearchKey] = useState<SearchByOpsDetailsKeyOptions | null>(null);
  const location = useLocation();

  if (!jemContext) {
    throw new FatalError("Please use a JEMContext Provider.");
  }

  const dashboardGridRef = useRef<IDashboardGridRef>(null);
  const [filteredItems, setFilteredItems] = useState<JEMChecklistTask[]>([]);
  const [dashboardCache, setDashboardCache] = useLocalStorage<FilterState>(
    `${LocalStorageKeys.checklistDashboardCompanyFilterState}`,
    {} as FilterState
  );
  const [filterState, setFilterState] = useState<FilterState | null>(dashboardCache ?? ({} as FilterState));
  const {
    error: gridItemsError,
    data: gridItems,
    isLoading,
    isFetching
  } = useQuery({
    queryKey: ["byCompanyCode", searchKey],
    queryFn: (queryOptions) => {
      const { queryKey } = queryOptions;
      const [_, theSearchKey] = queryKey as [string, SearchByOpsDetailsKeyOptions];
      if (!theSearchKey) {
        return Promise.resolve([]);
      }
      const { fiscalYear, fiscalMonth, companyCodes } = theSearchKey as SearchByOpsDetailsKeyOptions;
      if (!fiscalYear || !fiscalMonth || companyCodes === undefined) {
        return Promise.resolve([]);
      }
      return getChecklistTasksByCompanyCode(
        props.configuration,
        userContext.accessToken,
        fiscalYear,
        fiscalMonth,
        companyCodes
      );
    },
    enabled: searchKey !== null
  });
  const status = isLoading || isFetching ? LoadingStatus.Pending : LoadingStatus.Resolved;

  useEffect(
    function refreshTasks() {
      if (props.searchOptions) {
        const fyfp = {
          fiscalYear: parseInt(props.searchOptions.fiscalYear, 10),
          fiscalMonth: parseInt(props.searchOptions.fiscalMonth, 10)
        };
        setSearchKey({
          fiscalYear: fyfp.fiscalYear,
          fiscalMonth: fyfp.fiscalMonth,
          companyCodes: props.searchOptions.companyCodes
        });
      }
    },
    [props.searchOptions]
  );

  useEffect(() => {
    if (gridItems) {
      setFilteredItems(gridItems);
    }
  }, [gridItems]);

  const setCache = () => {
    if (filterState) {
      const { currentTile, searchCriteria, filterBar } = filterState;
      setDashboardCache({ currentTile, searchCriteria, filterBar });
    }
  };

  useEffect(() => {
    setCache();
  }, [filterState]);

  const filterItems = (newFilterState?: FilterState) => {
    if (searchKey === null) return;
    if (newFilterState) {
      if (!isEqual(newFilterState, filterState)) {
        setFilterState(newFilterState);
        const newItems = JEMChecklistDetailsFilterRows(newFilterState, allFilters as any, gridItems as any);
        setFilteredItems(newItems as unknown as JEMChecklistTask[]);
      } else {
        setFilteredItems(gridItems || []);
      }
    } else {
      setFilteredItems(gridItems || []);
    }
  };

  const error = gridItemsError;

  return (
    <>
      <JEMChecklistsCompanyCodeSelector
        currentSettings={props.searchOptions}
        domainData={jemContext.initInfo.values}
        onUpdateClicked={(fyfp, companyCodes) => {
          if (
            fyfp.fiscalYear.toString() === props.searchOptions?.fiscalYear &&
            fyfp.fiscalMonth.toString() === props.searchOptions?.fiscalMonth &&
            isEqual(companyCodes, props.searchOptions?.companyCodes)
          )
            return;

          props.onSearchOptionsChanged({
            fiscalYear: `${fyfp.fiscalYear}`,
            fiscalMonth: `${fyfp.fiscalMonth}`,
            companyCodes: companyCodes
          });
        }}
      />

      {status !== LoadingStatus.Pending && gridItems && gridItems.length == 0 ? (
        <>
          <Text
            variant="xLarge"
            style={{
              color: "var(--accent-font-color, black)"
            }}
          >
            No Items to show.
          </Text>
        </>
      ) : error ? (
        <Text
          variant="xLarge"
          style={{
            color: "var(--accent-font-color, black)"
          }}
        >
          Error Loading Checklist Tasks - {(error as any).message}
        </Text>
      ) : (
        <>
          <div
            className={css`
              position: relative;
              flex-grow: 1;
              width: 100%;
            `}
          >
            <DashboardFilter<JEMChecklistTask>
              filters={allFilters}
              savedFilters={dashboardCache.filterBar}
              singleSelectionFilters={[]}
              searchEnabledFilters={["Ops Detail", "Checklist Name"]}
              onFilterChanged={filterItems}
              loadingStatus={LoadingStatus.Resolved}
              tilesAndFilters={{
                currentTile: "",
                tiles: OrderedMap<string, IIndexedTile>({}),
                gridItems: gridItems || []
              }}
            />
          </div>
          <div
            className={css`
              display: inline-flex;
              gap: 16px;
              flex-direction: column;
              flex-wrap: wrap;
              justify-content: center;
              align-items: stretch;
              width: 100%;
              margin: 16px 0 16px 0;
            `}
          >
            <DashboardGrid
              idForLocalStorage={"jemChecklistTasksSearchByCompanyCode"}
              columnGenerator={getJEMChecklistTasksColumns({ location })}
              isSortedIndex={10}
              items={filteredItems || []}
              isSortedDescending={true}
              customRef={dashboardGridRef}
              selectionMode={SelectionMode.multiple}
              height={`500px`}
              onExport={(rows) => {
                exportToExcel({
                  sheetName: "searchByCompanyCode",
                  rowsWithHeader: rows,
                  fileName: "jemChecklistTasksByCC.xlsx"
                });
              }}
              loadingStatus={status}
              onRenderRow={(props, defaultRender) => {
                if (!defaultRender || !props) return null;

                return (
                  <div className="checklist-task">
                    {defaultRender({
                      ...props,
                      className: `${props.item.taskType.toLowerCase()}--${kebabCase(props.item.publishedState)}`
                    })}
                  </div>
                );
              }}
            ></DashboardGrid>
          </div>
        </>
      )}
    </>
  );
};

JEMChecklistDashboardByCompanyCode.displayName = "JEMChecklistDashboardByCompanyCode";

export default JEMChecklistDashboardByCompanyCode;
