import { ApiParsingUtilities, tryParseDate } from "@jem/components";

export interface JEMChecklistTasksResponseFromApi {
  items: JEMChecklistTaskFromApi[];
  pageNumber: number;
  pageSize: number;
  totalCount: number;
  totalPages: number;
}

export interface JEMChecklistTaskFromApi {
  taskId: string;
  jeTaskName: string;
  jeType: string;
  opsDetailName: string;
  opsDetailId: number;
  parentChecklistName: string;
  parentChecklistRefGuid: string;
  fcwChecklistName: string;
  postingDueDate: string;
  significance: string;
  publishedState: string;
  status: string;
  assignedPoster: string;
  docNo: string;
  assignedReviewer: string;
  attachment: string;
  author: string;
  reasonCode: string;
  modifiedOn: string;
  purpose: string;
  postingPeriod: string;
  postedDate: string;
  documentType: string;
  companyCode: string;
  referenceNumber: string;
  jeDescription: string;
  jeRefGuid: string;
  href: string;
  reasonCodeText: string;
  postingNotRequired: string;
  isCloneable: boolean;
  isAdHocDraft: boolean;
  postedBy: string;
  reviewedDate: string;
  refGuid: string;
  frequency: string;
  estimatedTimeMins: number;
  actualTimeTakenMins: number;
  draftDateCode: string;
  comments: string;
  isF05Posting: boolean;
  isF05PostingString: string;
}

export interface JEMChecklistTask
  extends Omit<JEMChecklistTaskFromApi, "assignedPoster" | "assignedReviewer" | "companyCode" | "comments"> {
  taskType: string;
  assignedPoster: string[];
  assignedReviewer: string[];
  companyCode: string[];
  noActivityComments: string;
}

export function sanitizeChecklistTasksRow(row: JEMChecklistTaskFromApi): JEMChecklistTask {
  const postingDueDate = tryParseDate(row.postingDueDate) || "";

  const newSanitizedRow: JEMChecklistTask = {
    taskId: ApiParsingUtilities.addDefault(row.taskId, ""),
    jeTaskName: ApiParsingUtilities.addDefault(row.jeTaskName, ""),
    jeType: ApiParsingUtilities.addDefault(row.jeType, ""),
    opsDetailName: ApiParsingUtilities.addDefault(row.opsDetailName, ""),
    opsDetailId: ApiParsingUtilities.parseNumberToNumber(row.opsDetailId),
    parentChecklistName: ApiParsingUtilities.addDefault(row.parentChecklistName, ""),
    parentChecklistRefGuid: ApiParsingUtilities.addDefault(row.parentChecklistRefGuid, ""),
    fcwChecklistName: ApiParsingUtilities.addDefault(row.fcwChecklistName, ""),
    postingDueDate: postingDueDate,
    significance: ApiParsingUtilities.addDefault(row.significance, ""),
    publishedState: ApiParsingUtilities.addDefault(row.publishedState, ""),
    status: ApiParsingUtilities.addDefault(row.status, ""),
    assignedPoster: ApiParsingUtilities.addDefault(row.assignedPoster, "").split(";"),
    docNo: ApiParsingUtilities.addDefault(row.docNo, ""),
    assignedReviewer: ApiParsingUtilities.addDefault(row.assignedReviewer, "").split(";"),
    attachment: ApiParsingUtilities.addDefault(row.attachment, ""),
    author: ApiParsingUtilities.addDefault(row.author, ""),
    reasonCode: ApiParsingUtilities.addDefault(row.reasonCode, ""),
    modifiedOn: ApiParsingUtilities.addDefault(row.modifiedOn, ""),
    purpose: ApiParsingUtilities.addDefault(row.purpose, ""),
    postingPeriod: ApiParsingUtilities.addDefault(row.postingPeriod, ""),
    postedDate: ApiParsingUtilities.addDefault(row.postedDate, ""),
    documentType: ApiParsingUtilities.addDefault(row.documentType, ""),
    companyCode: ApiParsingUtilities.addDefault(row.companyCode, "").split(","),
    referenceNumber: ApiParsingUtilities.addDefault(row.referenceNumber, ""),
    jeDescription: ApiParsingUtilities.addDefault(row.jeDescription, ""),
    jeRefGuid: ApiParsingUtilities.addDefault(row.jeRefGuid, ""),
    href: ApiParsingUtilities.addDefault(row.href, ""),
    reasonCodeText: ApiParsingUtilities.addDefault(row.reasonCodeText.trim()),
    postingNotRequired: ApiParsingUtilities.addDefault(row.postingNotRequired, ""),
    isCloneable: row.isCloneable,
    isAdHocDraft: row.isAdHocDraft,
    postedBy: ApiParsingUtilities.addDefault(row.postedBy, ""),
    reviewedDate: ApiParsingUtilities.addDefault(row.reviewedDate, ""),
    refGuid: ApiParsingUtilities.addDefault(row.refGuid, ""),
    taskType: row.isAdHocDraft ? "Adhoc" : "Standard",
    frequency: ApiParsingUtilities.addDefault(row.frequency, ""),
    estimatedTimeMins: ApiParsingUtilities.parseNumberToNumber(row.estimatedTimeMins, 0),
    actualTimeTakenMins: ApiParsingUtilities.parseNumberToNumber(row.actualTimeTakenMins, 0),
    draftDateCode: ApiParsingUtilities.addDefault(row.draftDateCode, ""),
    noActivityComments: ApiParsingUtilities.addDefault(row.comments, ""),
    isF05Posting: row.isF05Posting,
    isF05PostingString: row.isF05Posting ? "true" : "false"
  };
  return newSanitizedRow;
}
