import { DefaultButton, Label, PrimaryButton, Stack, TextField, Text } from "@fluentui/react";
import { FastField, Field, Form, Formik } from "formik";
import React, { PropsWithChildren, useContext } from "react";
import * as Yup from "yup";

import { JemConfiguration } from "../../../JemConfiguration";
import { gridStyle } from "../../../GeneralLedger/shared/GL.styles";
import { ValidationManager } from "../../../GeneralLedger/validation/ValidationManager";
import { UserContext } from "../../contexts/UserContext/UserContext";
import { ProcessCommonRowProps } from "../../utilities/ProcessCommonRowProps";
import { removeDuplicateAliases } from "../../utilities/ValidateReviewerAliasesLocally";
import { ActionResult, ActionTypes } from "../ActionManager/ActionsManager.types";
import { JEMAliasPicker } from "../JEMAliasPicker/JEMAliasPicker";
import { ActionsContext } from "./Actions.ActionsContext";
import { ActionTitle } from "./Actions.ActionTitle";
import { ActionOnSubmitHandlerMap, BaseActionProps, CommonPropsOnRows, RowFromListType } from "./Actions.types";
import { getAdditionalReviewers } from "./AddReviewer";

interface SendToPosterProps<ListType, ResultType> extends BaseActionProps<ListType, ResultType> {
  onSubmitHandler: ActionOnSubmitHandlerMap<ListType, ResultType>[ActionTypes.sendToPoster];
}

const SendToPoster = <ListType extends CommonPropsOnRows, ResultType extends ActionResult>(
  props: PropsWithChildren<SendToPosterProps<RowFromListType<ListType>, ResultType>>
) => {
  const { styles } = useContext(ActionsContext);
  const { accessToken } = useContext(UserContext);

  if (!props.configuration) {
    throw new Error("SendToPoster: props.configuration is required");
  }

  const aliasesForState = ProcessCommonRowProps(props.items);
  const { reviewer, restOfReviewers } = getAdditionalReviewers(aliasesForState.reviewer);

  const initialState = {
    author: "",
    postersTabPoster: aliasesForState.poster || "",
    postersTabBackupPosters: aliasesForState.additionalPosters ? aliasesForState.additionalPosters : [],
    postersTabComments: "",
    reviewersTabReviewer: reviewer || "",
    reviewersTabAdditionalReviewers: restOfReviewers,
    error: ""
  };

  return (
    <>
      <ActionTitle onCloseButton={() => props.onCancel()} name={"Send To Poster"}></ActionTitle>
      <div className={styles.contentStyles.body}>
        <Formik
          initialValues={initialState}
          onSubmit={async (values, { setSubmitting, setFieldError }) => {
            try {
              setSubmitting(true);

              await ValidationManager.PosterAndReviewerPermissionValidation({
                configuration: props.configuration as NonNullable<JemConfiguration["GeneralLedgerApi"]>,
                accessToken
              })
                .required()
                .validate(values, {
                  abortEarly: false
                });

              const result = await props.onSubmitHandler(
                props.items,
                values.postersTabPoster,
                values.postersTabBackupPosters,
                values.postersTabComments
              );
              if (result.notification.type === "Error") {
                setFieldError("error", result.notification.subjectHeader);
                return;
              }
              setSubmitting(false);
              props.onSubmit(result);
            } catch (error) {
              if (error instanceof Yup.ValidationError) {
                setFieldError("error", error.message);
                return;
              } else {
                throw error;
              }
            }
          }}
          validateOnMount={true}
          validationSchema={ValidationManager.PosterAndReviewerOfflineValidation(initialState, {
            posterRequired: true,
            reviewerRequired: false,
            reviewerIsAuthorCheck: false,
            additionalReviewersIsAuthorCheck: false,
            posterCommentsRequired: true,
            backupPostersRequired: false,
            backupReviewersRequired: false,
            preReviewerRequired: false,
            preReviewerIsAuthorCheck: false
          })}
        >
          {(formik) => (
            <Form onSubmit={formik.handleSubmit}>
              <Stack
                {...{
                  tokens: {
                    childrenGap: 10,
                    padding: 10
                  }
                }}
              >
                <Stack.Item>
                  <Field
                    name={"postersTabPoster"}
                    label="Poster:"
                    as={TextField}
                    type="input"
                    errorMessage={formik.errors.postersTabPoster || ""}
                    description={
                      aliasesForState.isPosted
                        ? aliasesForState.poster === null
                          ? "Multiple Posters selected."
                          : ""
                        : ""
                    }
                  />
                </Stack.Item>
                <Stack.Item>
                  <JEMAliasPicker
                    label="Backup Posters"
                    required={true}
                    disabled={false}
                    initialAliases={formik.values.postersTabBackupPosters}
                    onChange={(value) => {
                      // remember to remove duplicates from the list
                      const unique = removeDuplicateAliases(value);
                      formik.setFieldValue("postersTabBackupPosters", unique);
                    }}
                  />
                  <Text
                    nowrap
                    variant="xSmall"
                    block
                    style={{
                      marginTop: "4px",
                      color: "var(--accent-font-color, black)"
                    }}
                  >
                    A maximum of 75 additional reviewers are supported. Aliases must be separated by semicolon.
                    (example: alias1;alias2)
                  </Text>
                </Stack.Item>
                <Stack.Item>
                  <FastField
                    name={"postersTabComments"}
                    label="Comments:"
                    as={TextField}
                    multiline
                    rows={2}
                    type="input"
                    errorMessage={formik.errors.postersTabComments}
                  />
                </Stack.Item>
                <Stack.Item>
                  {formik.errors.error ? (
                    <Label
                      styles={{
                        root: {
                          color: "red"
                        }
                      }}
                    >
                      {formik.errors.error}
                    </Label>
                  ) : null}
                </Stack.Item>
                <Stack.Item>
                  <Stack horizontal {...{ tokens: gridStyle }}>
                    <Stack.Item align="end">
                      <PrimaryButton text={"Ok"} type="submit" disabled={!formik.isValid || formik.isSubmitting} />
                    </Stack.Item>
                    <Stack.Item align="end">
                      <DefaultButton
                        onClick={(event) => {
                          event.stopPropagation();
                          props.onCancel();
                        }}
                        text="Cancel"
                      />
                    </Stack.Item>
                  </Stack>
                </Stack.Item>
              </Stack>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

SendToPoster.displayName = "SendToPoster";

export { SendToPoster };
