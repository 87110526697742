import React, { useContext, useEffect, useRef, useState } from "react";

import {
  GLDetailsForm,
  GLNumberSearchState,
  JemConfiguration,
  JEMContext,
  JemNotification,
  LoadingStatus,
  PageHeading,
  PageStyles,
  GLDetailsRef,
  GLJeDetailsSettingsFromDocNumber
} from "@jem/components";
import { createSearchParams, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import GLNumberSearchForm from "../../components/GLNumberSearch/GLNumberSearchForm";
import { significanceIconMapping } from "../../../Shared/utilities/significanceIconMapping";
import { v4 } from "uuid";

export interface GLNumberSearchProps {
  configuration: JemConfiguration["GeneralLedgerApi"];
  attachmentsConfiguration: JemConfiguration["DocumentsApi"];
}

export interface JeNumberSearchQueryStringParameters {
  DocNumber: string;
  CompanyCode: string;
  FiscalYear: string;
  SAPModule: string;
}

function parseGLNumberSearchState(searchParams: URLSearchParams): GLNumberSearchState {
  const docNumber = searchParams.get("DocNumber") ?? "";
  const companyCode = parseInt(searchParams.get("CompanyCode") ?? "0", 10);
  const fiscalYear = parseInt(searchParams.get("FiscalYear") ?? "0", 10);
  const sapModule = searchParams.get("SAPModule") ?? "";

  return new GLNumberSearchState({
    DocNumber: docNumber,
    CompanyCode: companyCode,
    FiscalYear: fiscalYear,
    SAPModule: sapModule,
    RefGuid: v4()
  });
}

function toRecord(search: GLNumberSearchState): Record<string, string> {
  return {
    DocNumber: search.DocNumber,
    CompanyCode: search.CompanyCode.toString(),
    FiscalYear: search.FiscalYear.toString(),
    SAPModule: search.SAPModule
  };
}

function translateToGLJeDetailsSettingsFromDocNumber(search: GLNumberSearchState): GLJeDetailsSettingsFromDocNumber {
  return {
    DocNumber: search.DocNumber,
    CompanyCode: search.CompanyCode.toString(),
    FiscalYear: search.FiscalYear.toString(),
    SAPModule: search.SAPModule,
    RefGuid: search.RefGuid
  };
}

const GLNumberSearch: React.FC<GLNumberSearchProps> = (props) => {
  const domainContext = useContext(JEMContext);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchCriteria, setSearchCriteria] = useState<GLNumberSearchState | null>(null);
  const [invalidSearchQuery, setInvalidSearchQuery] = useState<boolean>(false);
  const detailsRef = useRef<GLDetailsRef>(null);

  const refreshTrigger = async (search: GLNumberSearchState | null, _notification: JemNotification | null) => {
    console.log("refreshTrigger", search);
    if (search === null && _notification === null) {
      // user reset the form
      setSearchCriteria(null);
      navigate({
        search: ``
      });
      return;
    }
    if (search === null) {
      setInvalidSearchQuery(true);
      return;
    }
    setInvalidSearchQuery(false);
    navigate({
      search: `?${createSearchParams(toRecord(search))}`
    });
  };

  useEffect(
    function setSettingsFromQueryString() {
      if (
        domainContext.initInfo.status === LoadingStatus.Resolved ||
        domainContext.initInfo.status === LoadingStatus.Rejected
      ) {
        console.log("setSettingsFromQueryString", searchParams.toString());
        const searchCriteria = parseGLNumberSearchState(searchParams);
        if (searchCriteria.DocNumber.trim() !== "") {
          setSearchCriteria(searchCriteria);
        }
      }
    },
    [searchParams, domainContext.initInfo.values]
  );

  return (
    <>
      <div className={PageStyles.rootDiv}>
        <PageHeading>
          <h2>JE Number Search</h2>
        </PageHeading>
        <GLNumberSearchForm
          configuration={props.configuration}
          numSearchState={!searchCriteria ? null : searchCriteria}
          triggerRefreshFn={refreshTrigger}
          domainData={domainContext.initInfo.values}
          errorMessage={invalidSearchQuery ? "Please add required fields" : undefined}
        />
        {searchCriteria === null ? null : (
          <>
            <GLDetailsForm
              configuration={props.configuration}
              attachmentsConfiguration={props.attachmentsConfiguration}
              domainData={domainContext.initInfo.values}
              significanceIcons={significanceIconMapping}
              settings={translateToGLJeDetailsSettingsFromDocNumber(searchCriteria)}
              onNavigate={() => {
                const from = location.state && (location.state as any).from ? (location.state as any).from : undefined;
                if (from) {
                  navigate(from, { replace: true });
                } else {
                  navigate("/gl/dashboard", { replace: true });
                }
              }}
              customRef={detailsRef}
              onNavigateBack={() => {
                navigate(-1);
              }}
            />
          </>
        )}
      </div>
    </>
  );
};

GLNumberSearch.displayName = "GLNumberSearch";
export default GLNumberSearch;
