import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import { IBasePickerProps, IconButton, ITag, Label, TagPicker, Text } from "@fluentui/react";
import { v4 } from "uuid";
import { IPosterOrReviewerChoiceProps } from "../PosterOrReviewerChoice/PosterOrReviewerChoice";

export interface JEMAliasPickerRef {
  setInitialAliases: (aliases: string[]) => void;
  reset: () => void;
  getAliases: () => string[];
}

export interface JEMAliasPickerProps {
  label: string;
  labeledById?: string;
  labelComponent?: ReturnType<React.FC<IPosterOrReviewerChoiceProps>>;
  onChange?: (aliases: string[]) => void;
  initialAliases?: string[];
  required?: boolean;
  disabled?: boolean;
  customRef?: React.RefObject<IBasePickerProps<ITag>["componentRef"]>;
  description?: string;
  errorMessage?: string;
  hideLabel?: boolean;
  itemLimit?: number | undefined;
}

const JEMAliasPicker: React.FC<JEMAliasPickerProps> = (props) => {
  const [tags, setTags] = useState<ITag[]>([]);
  const pickerRef = useRef<IBasePickerProps<ITag>["componentRef"]>(null);
  const tagPickerId = React.useMemo(() => {
    return `jemaliasp-${v4().slice(0, 8)}`;
  }, []);
  const labelId = React.useMemo(() => {
    if (props.labeledById) {
      return props.labeledById;
    }
    return `jemaliasp-label-${v4().slice(0, 8)}`;
  }, [props.labeledById]);

  useImperativeHandle(props.customRef, () => ({
    setInitialAliases(aliases: string[]) {
      const newTags = new Set(
        aliases.map((alias) => ({
          key: alias.trim().toUpperCase(),
          name: alias.trim().toUpperCase()
        }))
      );
      setTags(Array.from(newTags));
    },
    reset() {
      setTags([]);
    },
    getAliases() {
      return tags.map((tag) => tag.name);
    }
  }));

  const onResolveSuggestions = () => {
    return [];
  };

  const onInputChange = (input: string) => {
    const inputBreakChars = [";", ",", "."];
    if (inputBreakChars.includes(input.slice(-1))) {
      const tag = input.slice(0, -1).trim().toUpperCase();
      const newTags = [...tags, { key: tag, name: tag }];
      setTags(newTags);
      if (props.onChange) {
        const tagNames = newTags.map((tag) => tag.name);
        props.onChange(tagNames);
      }
      return "";
    }
    return input;
  };

  useEffect(() => {
    if (props.initialAliases && props.initialAliases.length > 0) {
      // check if props.initialAliases is the same as tags
      const initialAliases = props.initialAliases.map((alias) => alias.trim().toUpperCase());
      const allAliasesSet = new Set(initialAliases);
      const allAliases = [...allAliasesSet];
      const newTags = allAliases.map((alias) => ({
        key: alias.trim().toUpperCase(),
        name: alias.trim().toUpperCase()
      }));

      setTags(newTags);
    }
  }, [props.initialAliases]);

  const handleCopyTags = () => {
    const tagNames = tags.map((tag) => tag.name).join(";");
    navigator.clipboard.writeText(tagNames);
  };

  return (
    <>
      {!props.hideLabel ? (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
          {props.labelComponent ? props.labelComponent : <Label htmlFor={labelId}>{props.label}</Label>}
          <IconButton
            style={{ isVisible: props.required }}
            iconProps={{ iconName: "ClipboardList" }}
            title={`Copy ${props.label} to Clipboard`}
            ariaLabel={`Copy ${props.label} to Clipboard`}
            onClick={handleCopyTags}
          />
        </div>
      ) : null}
      <TagPicker
        componentRef={pickerRef}
        inputProps={{
          "aria-labelledby": labelId,
          id: tagPickerId,
          "aria-label": "Alias Input",
          placeholder: "Separate Aliases with ;",
          disabled: props.disabled,
          hidden: props.disabled,
          onBlur: (e) => {
            e.preventDefault();
            const tag = e.target.value.trim().toUpperCase();
            if (tag) {
              const newTags = [...tags, { key: tag, name: tag }];
              setTags(newTags);
              if (props.onChange) {
                const tagNames = newTags.map((tag) => tag.name);
                props.onChange(tagNames);
              }
              if (pickerRef.current) {
                try {
                  pickerRef.current.input.current._updateValue("");
                } catch (error) {
                  console.error("An error occurred while updating the value:", error);
                }
              }
            }
          },
          onKeyDown: async (e) => {
            if (e.ctrlKey && e.code === "KeyV") {
              // Copy the selected tags to the clipboard when Ctrl+C is pressed
              e.preventDefault();
              const clipboardText = await navigator.clipboard.readText();
              const tagNames = clipboardText.split(";");
              const newTags = tagNames.map((name) => ({
                key: name.trim().toUpperCase(),
                name: name.trim().toUpperCase()
              }));
              const uniqueTags = newTags.filter((upperCaseTag) => {
                return !tags.some((t) => t.key === upperCaseTag.key);
              });
              const newTagsFinal = [...tags, ...uniqueTags];
              setTags(newTagsFinal);
              if (props.onChange) {
                const tagNames = newTagsFinal.map((tag) => tag.name);
                props.onChange(tagNames);
              }
            }
          }
        }}
        itemLimit={props.itemLimit}
        onChange={(newTags) => {
          if (newTags) {
            setTags(newTags);
            if (props.onChange) {
              const tagNames = newTags.map((tag) => tag.name);
              props.onChange(tagNames);
            }
          }
        }}
        pickerCalloutProps={{}}
        styles={{ root: { marginTop: "0px !important", minWidth: 200 } }}
        onResolveSuggestions={onResolveSuggestions}
        onInputChange={onInputChange}
        selectedItems={tags}
        disabled={props.disabled}
      />
      {props.description && (
        <Text
          nowrap
          variant="xSmall"
          block
          style={{
            marginTop: "4px",
            color: "var(--accent-font-color, black)"
          }}
        >
          {props.description}
        </Text>
      )}
      {props.errorMessage && (
        <Text
          nowrap
          variant="small"
          block
          style={{
            marginTop: "4px",
            color: "rgb(164, 38, 44)"
          }}
        >
          {props.errorMessage}
        </Text>
      )}
    </>
  );
};

export { JEMAliasPicker };
