import { Label, Shimmer, ShimmerElementsGroup, ShimmerElementType } from "@fluentui/react";

import React from "react";
import { LoadingStatus } from "../../utilities";

export interface SimpleShimmerForFormsProps {
  label?: string;
  loadingStatus: LoadingStatus;
  height: number;
}

const SimpleShimmerForForms: React.FC<SimpleShimmerForFormsProps> = (props) => {
  return (
    <>
      {props.loadingStatus !== LoadingStatus.Resolved ? (
        <>
          {props.label && <Label>{props.label}</Label>}
          <Shimmer
            customElementsGroup={
              <ShimmerElementsGroup
                shimmerElements={[{ type: ShimmerElementType.line, width: "100%", height: props.height }]}
              />
            }
          ></Shimmer>
        </>
      ) : (
        props.children
      )}
    </>
  );
};

export { SimpleShimmerForForms };
