import { Dropdown, IDropdownOption } from "@fluentui/react";
import { convertToJeTypeDropdownOptions, DomainDataObjects, JEMContext } from "@jem/components";
import React, { forwardRef, useContext, useEffect, useImperativeHandle, useState } from "react";
//import { MaxWidthHeightOptions } from "../../utilities/FluentUIHacks";

export interface IDocumentTypeRef {
  getDocumentType: () => IDropdownOption<string> | null;
  setInitialDocumentType: (initialDocumentType: string) => void;
  reset: () => void;
}

interface IDocumentTypeProps {
  loading: boolean;
  onChange?: (DocumentType: IDropdownOption<string>) => void;
  //styles?: MaxWidthHeightOptions;
  DocumentType: string;
}

const DocumentTypeDropdown = forwardRef((props: IDocumentTypeProps, ref) => {
  const domainContext = useContext(JEMContext);
  const DocumentTypeDropdownOptions = convertToJeTypeDropdownOptions(
    domainContext.initInfo.values?.JeTypes as DomainDataObjects["JeTypes"]
  );

  const [DocumentType, setDocumentType] = useState<IDropdownOption<string>>();

  useEffect(() => {
    const idt = DocumentTypeDropdownOptions.find((dt) => dt.text === props.DocumentType);
    if (!idt) return;

    setDocumentType(idt);
  }, [props.DocumentType]);

  const onChangeDocumentType = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void => {
    if (!option) {
      return;
    }

    setDocumentType(option);
    if (props.onChange) {
      props.onChange(option);
    }
  };

  useImperativeHandle(
    ref,
    (): IDocumentTypeRef => ({
      getDocumentType() {
        if (!DocumentType) {
          return null;
        }
        return DocumentType;
      },
      setInitialDocumentType(initialDocumentType: string) {
        // get from options values
        const dt = DocumentTypeDropdownOptions.find((dt) => dt.text === initialDocumentType);
        if (!DocumentType) return;

        setDocumentType(dt);
      },
      reset() {
        setDocumentType(DocumentTypeDropdownOptions[0]);
      }
    })
  );

  return (
    <React.Fragment>
      <Dropdown
        selectedKey={DocumentType?.key}
        onChange={onChangeDocumentType}
        options={DocumentTypeDropdownOptions}
        disabled={props.loading}
        styles={{
          root: {
            width: "100%",
            marginTop: "4px"
          }
        }}
        required
        errorMessage={DocumentType?.key === undefined ? "DocumentType is required" : undefined}
      />
    </React.Fragment>
  );
});

DocumentTypeDropdown.displayName = "DocumentTypeDropdown";

export { DocumentTypeDropdown, IDocumentTypeProps };
