import { IButtonStyles, Theme } from "@fluentui/react";

export const labelStyles = {
  root: {
    fontFamily: "Segoe UI",
    fontSize: "14px",
    lineHeight: "20px"
  }
};

export const buttonStyles = (_theme: Theme): Partial<IButtonStyles> => ({
  root: {
    backgroundColor: _theme.palette.neutralLighterAlt
  }
});
