import { isValid, parse } from "date-fns";
import { DomainDataEnum, DomainDataObjects } from "../../../Shared";
import { ApiParsingUtilities } from "../../../Shared/utilities/ApiParsingUtilities";
import { FiscalPeriodStringTransformations } from "../../utilities";

export interface JEMChecklistResponseFromApi {
  fcwChecklistName: string;
  checklistName: string;
  checklistOwners: string;
  defaultReviewers: string;
  fcwTaskId: string;
  fiscalPeriod: number;
  fiscalYear: number;
  companyCodes: string;
  backUpPoster: string;
  backUpReviewer: string;
  opsDetailName: string | null;
  opsDetailId: number;
  checklistTasks: JEMChecklistDetailsRowFromApi[];
  signOffStatus: string;
}

export interface JEMChecklistDetailsRowFromApi {
  taskId: string;
  jEtaskname: string;
  jeType: string;
  checklist: string;
  postingDueDate: string;
  significance: string;
  publishedState: string;
  status: string;
  assignedPoster: string;
  backupPoster: string;
  docNo: string;
  assignedReviewer: string;
  classification: string;
  attachment: string;
  author: string;
  reasonCode: string;
  datemodified: string;
  purpose: string;
  period: string;
  postingdate: string;
  translationDate: string;
  documentType: string;
  companycode: string;
  referenceNumber: string;
  currencycode: string;
  description: string;
  jeRefGUID: string;
  refguid: string;
  checklistGuid: string;
  jeMhyperLink: string;
  reasonCodeText: string;
  dueDate: string;
  isCloneable: boolean;
  fcwChecklistName: string;
  isAdHocDraft: boolean;
  postingNotRequired: string;
  postedBy: string;
  draftDateCode: string;
  frequency: string;
  estimatedTimeMins: number;
  actualTimeTakenMins: number;
  signoffStatus: number;
  preReviewers: string;
  comments: string;
  isF05Posting: boolean;
}

export interface JEMChecklistDetailsRow {
  taskId: string;
  jeTaskName: string;
  jeType: string;
  checklist: string;
  checklistRefGuid: string;
  postingDueDate: string;
  significance: string;
  publishedState: string;
  status: string;
  assignedPoster: string;
  backupPoster: string[];
  docNo: string;
  assignedReviewer: string[];
  attachment: string;
  author: string;
  reasonCode: string;
  datemodified: string;
  purpose: string;
  postingPeriod: string;
  postingdate: string;
  translationDate: string;
  documentType: string;
  companycode: number[];
  referenceNumber: number;
  currencycode: string;
  description: string;
  refGuid: string;
  href: string;
  reasonCodeText: string;
  isCloneable: boolean;
  isAdHocDraft: boolean;
  fcwChecklistName: string;
  postingNotRequired: string;
  taskType: string;
  postedBy: string;
  opsDetailId: number;
  frequency: string;
  draftDateCode: string;
  estimatedTimeMins: number;
  actualTimeTakenMins: number;
  draftDateCodeObject: DomainDataObjects[DomainDataEnum.PostingDueDates][0] | undefined;
  signoffStatus: string;
  preReviewers: string;
  noActivityComments: string;
  isF05Posting: boolean;
  isF05PostingString: string;
}

export function tryParseDate(dateString: string) {
  const possibleFormats = [
    "MMM dd yyyy hh:mma", // Aug 31 2023 12:00AM
    "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'", // ISO 8601 format
    "yyyy-MM-dd HH:mm:ss.SSS", // 2023-08-31 00:00:00.000
    "MM/dd/yyyy", // 08/31/2023
    "MM-dd-yyyy", // 08-31-2023
    "yyyy/MM/dd", // 2023/08/31
    "yyyy-MM-dd", // 2023-08-31
    "dd-MMM-yyyy", // 31-Aug-2023
    "MMMM dd, yyyy", // August 31, 2023
    "do 'of' MMMM, yyyy" // 31st of August, 2023
  ];

  for (const format of possibleFormats) {
    const parsedDate = parse(dateString, format, new Date());

    if (isValid(parsedDate)) {
      return parsedDate.toISOString();
    }
  }

  return null; // Parsing failed for all formats
}

export function sanitizeChecklistDetailsRow(
  row: JEMChecklistDetailsRowFromApi,
  GUID: string,
  opsDetailId: number,
  draftDateCodes: DomainDataObjects[DomainDataEnum.PostingDueDates]
): JEMChecklistDetailsRow {
  const cc =
    row.companycode === null || row.companycode === undefined || row.companycode === ""
      ? []
      : Array.isArray(row.companycode)
      ? (row.companycode as number[])
      : typeof row.companycode === "string"
      ? row.companycode.split(",").map(Number)
      : [];
  const backupPoster =
    row.backupPoster === null || row.backupPoster === undefined || row.backupPoster === ""
      ? []
      : row.backupPoster.split(";").map((x) => x.trim().toUpperCase());
  const assignedReviewer =
    row.assignedReviewer === null || row.assignedReviewer === undefined || row.assignedReviewer === ""
      ? []
      : row.assignedReviewer.split(";").map((x) => x.trim().toUpperCase());

  const fyfpObj = FiscalPeriodStringTransformations.FiscalPeriodStringToFiscalYearAndFiscalMonth(row.period);
  const draftDateCode = ApiParsingUtilities.addDefault(row.draftDateCode, "");

  const draftDateCodeObject =
    draftDateCode !== ""
      ? draftDateCodes.find(
          (x) =>
            x.fiscalYear === fyfpObj.fiscalYear &&
            x.fiscalPeriod === fyfpObj.fiscalMonth &&
            x.draftDateCode === draftDateCode
        )
      : undefined;

  const actualTimeTakenMins = ApiParsingUtilities.parseNumberToNumber(row.actualTimeTakenMins, 0);
  const estimatedTimeMins = ApiParsingUtilities.parseNumberToNumber(row.estimatedTimeMins, 0);
  const possiblePostingDueDate = ApiParsingUtilities.addDefault(row.postingDueDate, "");
  const possibleDueDate = ApiParsingUtilities.addDefault(row.dueDate, "");
  const postingDueDate = tryParseDate(possiblePostingDueDate) || tryParseDate(possibleDueDate) || "";

  const newSanitizedRow: JEMChecklistDetailsRow = {
    taskId: ApiParsingUtilities.addDefault(row.taskId, ""),
    jeTaskName: ApiParsingUtilities.addDefault(row.jEtaskname, ""),
    backupPoster: backupPoster,
    jeType: ApiParsingUtilities.addDefault(row.jeType, ""),
    checklist: ApiParsingUtilities.addDefault(row.checklist, ""),
    postingDueDate: postingDueDate,
    significance: ApiParsingUtilities.addDefault(row.significance, ""),
    publishedState: ApiParsingUtilities.addDefault(row.publishedState, ""),
    status: ApiParsingUtilities.addDefault(row.status, ""),
    assignedPoster: ApiParsingUtilities.addDefault(row.assignedPoster, "").trim().toUpperCase(),
    docNo: ApiParsingUtilities.addDefault(row.docNo, ""),
    assignedReviewer: assignedReviewer,
    attachment: ApiParsingUtilities.addDefault(row.attachment, ""),
    author: ApiParsingUtilities.addDefault(row.author, ""),
    reasonCode: ApiParsingUtilities.addDefault(row.reasonCode, ""),
    datemodified: ApiParsingUtilities.addDefault(row.datemodified, ""),
    purpose: ApiParsingUtilities.addDefault(row.purpose, ""),
    postingPeriod: ApiParsingUtilities.addDefault(row.period, ""),
    postingdate: ApiParsingUtilities.addDefault(row.postingdate, ""),
    translationDate: ApiParsingUtilities.addDefault(row.translationDate, ""),
    documentType: ApiParsingUtilities.addDefault(row.documentType, ""),
    companycode: cc,
    referenceNumber: ApiParsingUtilities.parseNumberToNumber(row.referenceNumber, 0),
    currencycode: ApiParsingUtilities.addDefault(row.currencycode, ""),
    description: ApiParsingUtilities.addDefault(row.description, ""),
    refGuid: ApiParsingUtilities.addDefault(row.refguid || "", ""),
    href: ApiParsingUtilities.addDefault(row.jeMhyperLink, ""),
    checklistRefGuid: row.checklistGuid || GUID,
    isCloneable: row.isCloneable,
    isAdHocDraft: row.isAdHocDraft,
    reasonCodeText: row.reasonCodeText,
    fcwChecklistName: row.fcwChecklistName,
    postingNotRequired: ApiParsingUtilities.addDefault(row.postingNotRequired || "", ""),
    taskType: row.isAdHocDraft ? "Adhoc" : "Standard",
    postedBy: ApiParsingUtilities.addDefault(row.postedBy, ""),
    opsDetailId: opsDetailId,
    frequency: ApiParsingUtilities.addDefault(row.frequency, ""),
    draftDateCode: ApiParsingUtilities.addDefault(row.draftDateCode, ""),
    draftDateCodeObject: draftDateCodeObject,
    estimatedTimeMins,
    actualTimeTakenMins,
    signoffStatus: ApiParsingUtilities.parseNumberToSignoffStatus(row.signoffStatus, ""),
    preReviewers: ApiParsingUtilities.addDefault(row.preReviewers, ""),
    noActivityComments: ApiParsingUtilities.addDefault(row.comments, ""),
    isF05Posting: row.isF05Posting,
    isF05PostingString: row.isF05Posting ? "true" : "false"
  };
  return newSanitizedRow;
}
