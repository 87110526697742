import { CommandBar, ICommandBarItemProps } from "@fluentui/react";
import React, { useEffect, useState } from "react";

export type CreateActions = "Save" | "SubmitForReview" | "Clear";

interface IHCCCreateActionsProps {
  onAction: (actionName: CreateActions) => void;
  disabledPage: boolean;
}

const IHCCCreateActions: React.FC<IHCCCreateActionsProps> = (props) => {
  const onAction = (actionName: CreateActions) => {
    if (props.onAction) {
      props.onAction(actionName);
    }
  };

  const [actions, setActions] = useState<ICommandBarItemProps[]>(() => {
    return [
      {
        key: "save",
        name: "Save",
        iconProps: { iconName: "Save" },
        onClick: () => onAction("Save"),
        disabled: props.disabledPage
      },
      {
        key: "submitForReview",
        name: "Submit For Review",
        iconProps: { iconName: "PublishContent" },
        onClick: () => onAction("SubmitForReview"),
        disabled: props.disabledPage
      },
      {
        key: "clearForm",
        name: "Clear Form",
        iconProps: { iconName: "ClearFilter" },
        onClick: () => onAction("Clear"),
        disabled: props.disabledPage
      }
    ];
  });
  useEffect(() => {
    setActions([
      {
        key: "save",
        name: "Save",
        iconProps: { iconName: "Save" },
        onClick: () => onAction("Save"),
        disabled: props.disabledPage
      },
      {
        key: "submitForReview",
        name: "Submit For Review",
        iconProps: { iconName: "PublishContent" },
        onClick: () => onAction("SubmitForReview"),
        disabled: props.disabledPage
      },
      {
        key: "clearForm",
        name: "Clear Form",
        iconProps: { iconName: "ClearFilter" },
        onClick: () => onAction("Clear"),
        disabled: props.disabledPage
      }
    ]);
  }, [props.disabledPage]);

  return (
    <>
      <CommandBar
        items={actions}
        styles={{
          root: {
            paddingLeft: 0
          }
        }}
        ariaLabel={"Actions for Payment Order"}
        overflowButtonProps={{ ariaLabel: "More Actions" }}
      />
    </>
  );
};

IHCCCreateActions.displayName = "IHCCCreateActions";
export default IHCCCreateActions;
