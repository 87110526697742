import { NotificationType } from "../../contexts/LoggingContext/LoggingContext";

export interface AttachmentForUI<AttachmentType extends GLAttachment | IAttachment> {
  fileName: string;
  url: string;
  size: number;
  uploadDatetime: string;
  status: DocumentStatus;
  documentType: string;
  fiscalPeriod?: string;
  region?: AttachmentRegion<AttachmentType>;
  isRescindEvidence?: boolean;
  guid: string; // only to be used locally
  processingStatus: string; // only to be used locally
  procStatType: NotificationType; // only to be used locally
}

export interface LocalAttachment {
  guid: string;
  file: File;
}
export interface AttachmentChanges<AttachmentType> {
  attachments: AttachmentType[];
  recentlyUploadedAttachments: AttachmentType[];
}
export type AttachmentErrorMessage = string | null;
export interface IAttachmentEntryFormRef<AttachmentType extends IAttachment | GLAttachment> {
  setAttachments: (theAttachments: IAttachment[] | GLAttachment[], localAttachments: LocalAttachment[]) => void;
  saveAndGetAttachments: () => Promise<[AttachmentChanges<AttachmentType>, AttachmentErrorMessage]>;
  reset: () => void;
  getError: () => string | null;
  getLocalAttachments: () => LocalAttachment[];
}

export interface PostAttachmentResponse {
  readonly blobName: string;
  readonly inputFileName: string;
  readonly fileStorageUri: string;
}

export interface GLPostAttachmentResponse extends PostAttachmentResponse {
  readonly fiscalPeriod: string;
  readonly regStoreID: number;
  readonly blobUriPath: string;
}

export type AttachmentResponseType<T extends IAttachment | GLAttachment> = T extends GLAttachment
  ? GLPostAttachmentResponse
  : T extends IAttachment
  ? PostAttachmentResponse
  : never;

export interface IAttachment {
  id: number;
  blobName: string;
  fileName: string;
  url: string;
  fileSize: number;
  uploadedOn: string;
  documentType: string;
  fiscalPeriod?: string;
}

export interface GLAttachment extends IAttachment {
  region: {
    id: number;
    name: string;
  };
  isRescindEvidence: boolean;
  blobUriPath: string;
}

export interface ProcessingStatusForInternalAttachment {
  processingStatus: string;
  procStatType: NotificationType;
}

export type InternalAttachment<AttachmentType> = AttachmentType extends GLAttachment
  ? GLAttachment & { guid: string } & ProcessingStatusForInternalAttachment
  : AttachmentType extends IAttachment
  ? IAttachment & { guid: string } & ProcessingStatusForInternalAttachment
  : never;

export enum DocumentStatus {
  NotUploaded = 1,
  UploadLink = 2,
  UploadInProgress = 3,
  Uploaded = 4
}

export type DownloadFunction<T extends IAttachment | GLAttachment> = T extends GLAttachment
  ? (blobName: string, fileName: string, region?: { id: number; name: string }) => Promise<File>
  : T extends IAttachment
  ? (blobName: string, fileName: string) => Promise<File>
  : never;

export type AttachmentRegion<T extends IAttachment | GLAttachment> = T extends GLAttachment
  ? { id: number; name: string }
  : T extends IAttachment
  ? undefined
  : never;

export interface IAttachmentEntryFormCommonProps<AttachmentType extends IAttachment | GLAttachment> {
  disabled?: boolean;
  onDeleteFile: (id: number, blobName: string) => Promise<void>;
  onDelete?: () => void;
  customRef: React.Ref<IAttachmentEntryFormRef<AttachmentType>>;
  onChange?: (
    localAttachments: LocalAttachment[],
    remoteAttachments: AttachmentType[],
    errorMessage: string | null
  ) => void;
  maxAttachments?: number;
  children?: React.ReactNode;
  onDownloadFile: DownloadFunction<AttachmentType>;
}

export interface IAttachmentEntryFormSingleUploadProps extends IAttachmentEntryFormCommonProps<IAttachment> {
  onUploadFile: (file: File) => Promise<PostAttachmentResponse>;
  onUploadFiles?: never;
}

export interface IAttachmentEntryFormMultiUploadProps extends IAttachmentEntryFormCommonProps<GLAttachment> {
  onUploadFile?: never;
  onUploadFiles: (file: File) => Promise<GLPostAttachmentResponse[]>;
}

export type IAttachmentEntryFormProps = IAttachmentEntryFormSingleUploadProps | IAttachmentEntryFormMultiUploadProps;
