import React from "react";
import { useLocation } from "react-router-dom";
import { JemUserRoles } from "../../contexts/UserContext/UserContext.roles.main";
import { ILinks } from "./AppNav";
import { AppNavProps } from "./AppNav.types";
import { DomainDataEnum, DomainDataObjects } from "../../contexts/JEMContext/JEMContext.domainData.types";

export const GeneralLedgerLinks = (
  location: ReturnType<typeof useLocation>,
  onClickHandler: (route: string) => (event: React.MouseEvent | any) => void,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  props: AppNavProps,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  jemUserRoles: JemUserRoles,
  domainData: Pick<DomainDataObjects, DomainDataEnum.JeParameters> | null
) => {
  const links: Partial<ILinks>[] = [];
  links.push({
    name: "JE Dashboard",
    key: "glDashboard",
    ariaLabel: "General Ledger Dashboard",
    // "data-id": "automation_id_22235",
    target: "_blank",
    onClick: onClickHandler("/gl/dashboard"),
    isSelected: location.pathname === "/gl/dashboard"
  });
  links.push(
    {
      name: "New Journal Entry",
      key: "glCreate",
      ariaLabel: "General Ledger New Journal Entry",
      // "data-id": "automation_id_22235",
      target: "_blank",
      onClick: onClickHandler("/gl/create"),
      isSelected: location.pathname === "/gl/create"
    },
    {
      name: "New Batch JE",
      key: "glBatch",
      ariaLabel: "General Ledger New Batch JE",
      // "data-id": "automation_id_22235",
      target: "_blank",
      onClick: onClickHandler("/gl/batch"),
      isSelected: location.pathname === "/gl/batch"
    }
  );
  if (jemUserRoles.hasF05Access) {
    if (
      domainData !== null &&
      domainData.JeParameters.filter((item) => item.name === "ShowDualCurrencyTab").map((item) => item.showUX)[0]
    ) {
      links.push({
        name: "Dual Currency",
        key: "gldualCurrency",
        ariaLabel: "General Ledger New Dual Currency Journal Entry",
        // "data-id": "automation_id_22235",
        target: "_blank",
        onClick: onClickHandler("/gl/dualCurrency"),
        isSelected: location.pathname === "/gl/dualCurrency"
      });
    }
  }
  links.push(
    {
      name: "JE Search",
      key: "glJESearcg",
      ariaLabel: "General Ledger JE Search",
      // "data-id": "automation_id_22235",
      target: "_blank",
      onClick: onClickHandler("/gl/search"),
      isSelected: location.pathname === "/gl/search"
    },
    {
      name: "Batch JE Search",
      key: "glBatchSearch",
      ariaLabel: "General Ledger Search for Batch JE",
      // "data-id": "automation_id_22235",
      target: "_blank",
      onClick: onClickHandler("/gl/search/batch"),
      isSelected: location.pathname === "/gl/search/batch"
    },
    {
      name: "JE Number Search",
      key: "glNumberSearch",
      ariaLabel: "General Ledger Number Search for JEs",
      // "data-id": "automation_id_22235",
      target: "_blank",
      onClick: onClickHandler("/gl/search/number"),
      isSelected: location.pathname === "/gl/search/number"
    }
  );

  return {
    name: "General Ledger",
    key: "gl",
    ariaLabel: "General Ledger",
    icon: "DietPlanNotebook",
    // "data-id": "automation_id_22235",
    target: "_blank",
    links
  };
};
