import { DefaultButton, Stack } from "@fluentui/react";
import {
  DomainDataEnum,
  DomainDataObjects,
  JemConfiguration,
  OpsDetailDropdown,
  OpsDetailDropdownRef
} from "@jem/components";
import React, { useEffect, useRef } from "react";
import FiscalYearFiscalPeriodDropdown, {
  FiscalYearFiscalPeriodDropdownRef
} from "../../../Shared/components/FiscalYearFiscalPeriodDropdown/FiscalYearFiscalPeriodDropdown";

export interface OpsDetailSearchOptions {
  fiscalYear: string;
  fiscalMonth: string;
  opsDetailId: string;
  opsDetailName: string;
}

export interface JEMChecklistDashboardOpsDetailSelectorProps {
  configuration: JemConfiguration["GeneralLedgerApi"];
  domainData: Pick<DomainDataObjects, DomainDataEnum.FiscalPeriodsBPO> | null;
  onUpdateClicked: (
    fyfp: ReturnType<FiscalYearFiscalPeriodDropdownRef["getFiscalPeriod"]>,
    opsDetail: ReturnType<OpsDetailDropdownRef["getOpsDetail"]>
  ) => void;
  currentSettings: OpsDetailSearchOptions | null | undefined;
}

const JEMChecklistsOpsDetailSelector: React.FC<JEMChecklistDashboardOpsDetailSelectorProps> = (props) => {
  const fyfpRef = useRef<FiscalYearFiscalPeriodDropdownRef>(null);
  const opsDetailRef = useRef<OpsDetailDropdownRef>(null);

  useEffect(() => {
    if (props.currentSettings === null && fyfpRef.current) {
      props.onUpdateClicked(fyfpRef.current.getFiscalPeriod(), {
        id: -1,
        name: "All"
      });
    }
  }, [props.currentSettings]);

  return (
    <Stack styles={{ root: { margin: "10px 0 20px 0 " } }} horizontal tokens={{ childrenGap: 20 }}>
      <Stack.Item align="end">
        <FiscalYearFiscalPeriodDropdown
          showBlankFYFPOption={true}
          domainData={props.domainData}
          customRef={fyfpRef}
          value={
            props.currentSettings
              ? {
                  fiscalYear: parseInt(props.currentSettings.fiscalYear, 10),
                  fiscalMonth: parseInt(props.currentSettings.fiscalMonth, 10)
                }
              : undefined
          }
        ></FiscalYearFiscalPeriodDropdown>
      </Stack.Item>
      <Stack.Item align="end">
        <OpsDetailDropdown
          configuration={props.configuration}
          customRef={opsDetailRef}
          value={props.currentSettings ? parseInt(props.currentSettings.opsDetailId, 10) : undefined}
        ></OpsDetailDropdown>
      </Stack.Item>
      <Stack.Item align="end">
        <DefaultButton
          text="Update"
          onClick={() => {
            if (!fyfpRef.current || !opsDetailRef.current) {
              return;
            }

            props.onUpdateClicked(fyfpRef.current.getFiscalPeriod(), opsDetailRef.current.getOpsDetail());
          }}
        />
      </Stack.Item>
    </Stack>
  );
};

JEMChecklistsOpsDetailSelector.displayName = "JEMChecklistsOpsDetailSelector";

export default JEMChecklistsOpsDetailSelector;
