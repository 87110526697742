import React, { PropsWithChildren, useContext } from "react";
import { ActionResult, ActionTypes } from "../ActionManager/ActionsManager.types";
import { ActionsContext } from "./Actions.ActionsContext";
import { ActionTitle } from "./Actions.ActionTitle";
import { ActionOnSubmitHandlerMap, BaseActionProps, CommonPropsOnRows } from "./Actions.types";

interface SaveProps<ListType, ResultType> extends BaseActionProps<ListType, ResultType> {
  onSubmitHandler: ActionOnSubmitHandlerMap<ListType, ResultType>[ActionTypes.save];
}

const Save = <ListType extends CommonPropsOnRows, ResultType extends ActionResult>(
  props: PropsWithChildren<SaveProps<ListType, ResultType>>
) => {
  const { styles } = useContext(ActionsContext);
  return (
    <>
      <ActionTitle onCloseButton={() => props.onCancel()} name={"Save"}></ActionTitle>
      <div className={styles.contentStyles.body}>
        {/* <CheckBoxSubmission {...settings.configuration} onSubmit={onSubmit} /> */}
      </div>
    </>
  );
};

Save.displayName = "Save";

export { Save };
