import React from "react";
import { useFormikContext } from "formik";
import { Label } from "@fluentui/react";
import { GLBatchFormikState } from "../GLBatch.types";
import { LoadingStatus } from "../../../../Shared/utilities/Utilities";
import { SimpleShimmerForForms } from "../../../../Shared/components/SimpleShimmerForForms/SimpleShimmerForForms";
import { JEMAliasPicker } from "../../../../Shared/components/JEMAliasPicker/JEMAliasPicker";
import { removeDuplicateAliases } from "../../../../Shared/utilities/ValidateReviewerAliasesLocally";

const GLBatchPostersTab: React.FC<{
  disabled: boolean;
  loadingStatus: LoadingStatus;
}> = (props) => {
  const formik = useFormikContext<GLBatchFormikState>();

  return (
    <React.Fragment>
      <div className="ms-Grid" dir="ltr">
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm6">
            <div className="ms-Grid-row">
              <Label>Enter the email alias of the Poster(s)</Label>
            </div>
            <div className="ms-Grid-row">
              <SimpleShimmerForForms loadingStatus={props.loadingStatus} height={32} label="Additional Posters:">
                <JEMAliasPicker
                  label="Additional Posters"
                  required={false}
                  disabled={props.disabled}
                  initialAliases={formik.values.postersTabBackupPosters}
                  onChange={(value) => {
                    // remember to remove duplicates from the list
                    const unique = removeDuplicateAliases(value);
                    formik.setFieldValue("postersTabBackupPosters", unique);
                  }}
                  description="Maximum of 75 user aliases separated by semicolon(;)."
                  errorMessage={
                    props.disabled
                      ? ""
                      : formik.errors.postersTabBackupPosters
                      ? formik.errors.postersTabBackupPosters.toString()
                      : ""
                  }
                />
              </SimpleShimmerForForms>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

GLBatchPostersTab.displayName = "GLBatchPostersTab";
export { GLBatchPostersTab };
