import { useFormikContext } from "formik";
import React, { useContext, useEffect } from "react";
import { Autocomplete } from "../../../../Shared/components/Autocomplete/Autocomplete";
import { searchInArray } from "../../../../Shared/components/CompanyCodeSingleSelector/CompanyCodeSingleSelector";
import { JEMContext } from "../../../../Shared/contexts/JEMContext/JEMContext";
import { DomainDataObjects } from "../../../../Shared/contexts/JEMContext/JEMContext.domainData.types";
import { LoadingStatus } from "../../../../Shared/utilities/Utilities";
import { GLCreateFormikState } from "../GLCreateForm.types";

function companyCodeObjectToStringArray(companyCodesArray: DomainDataObjects["JeCompanyCodes"]): string[] {
  const companyCodes = companyCodesArray || [];
  const companyCodeCodes = companyCodes.map((x) => `${x.glCompanyCode}, ${x.glCompanyName}`);

  return companyCodeCodes;
}

function getFullCompanyCodeName(
  currentCompanyCode: string,
  companyCodes: DomainDataObjects["JeCompanyCodes"]
): string | null {
  const companyCodeArray = companyCodeObjectToStringArray(companyCodes);
  const initialCompanyCode = companyCodeArray.filter((coCode) => {
    return coCode.startsWith(currentCompanyCode) || coCode.startsWith(currentCompanyCode.toUpperCase());
  });
  if (initialCompanyCode.length === 1) {
    return initialCompanyCode[0];
  }
  return null;
}

export function getCurrencyCode(
  currentCompanyCode: string,
  companyCodes: DomainDataObjects["JeCompanyCodes"]
): string | null {
  const companyNCurrencyCodes = companyCodes.find((cc) => cc.glCompanyCode == Number(currentCompanyCode));
  if (companyNCurrencyCodes !== null) {
    return companyNCurrencyCodes?.currencyCode || null;
  }
  return null;
}

const GLCreateCompanyCode: React.FC<{ disabled: boolean; loadingStatus: LoadingStatus; isFourCurrency: boolean }> = (
  props
) => {
  const jemContext = useContext(JEMContext);
  const contextCompanyCodes = jemContext.initInfo.values?.JeCompanyCodes || [];
  const formik = useFormikContext<GLCreateFormikState>();

  const isInCompanyCodes = (prefix?: string) => {
    const companyCodeArray = companyCodeObjectToStringArray(contextCompanyCodes);
    return searchInArray(companyCodeArray, prefix);
  };

  useEffect(() => {
    if (contextCompanyCodes) {
      if (formik.values.detailsTabCompanyCode.substring(0, 4) !== "") {
        const coCode = getFullCompanyCodeName(
          formik.values.detailsTabCompanyCode.substring(0, 4) || "",
          contextCompanyCodes
        );
        if (coCode) {
          const value = coCode ? coCode.substring(0, 4) : "";
          formik.setFieldValue("detailsTabCompanyCode", value);
          if (value !== "" && props.isFourCurrency) {
            const currencyCode = getCurrencyCode(value, contextCompanyCodes);
            formik.setFieldValue("detailsTabCurrency1", currencyCode);
          }
        }
      }
    }
  }, [contextCompanyCodes]);

  useEffect(() => {
    formik.validateField("detailsTabCompanyCode");
  }, [formik.values.detailsTabCompanyCode]);

  return (
    <React.Fragment>
      <Autocomplete
        label="Company Code"
        name="companyCode"
        value={formik.values.detailsTabCompanyCode.substring(0, 4) || ""}
        onChange={(newValue?: string | undefined) => {
          const value = newValue ? newValue.substring(0, 4) : "";
          formik.setFieldValue("detailsTabCompanyCode", value);
          if (value !== "" && props.isFourCurrency) {
            const currencyCode = getCurrencyCode(value, contextCompanyCodes);
            formik.setFieldValue("detailsTabCurrency1", currencyCode);
          }
        }}
        errorMessage={
          props.disabled
            ? ""
            : contextCompanyCodes.length === 0
            ? "Error: No Company Codes found"
            : formik.errors.detailsTabCompanyCode
        }
        transformValue={(value?: string) => {
          if (value) {
            return value.substring(0, 4);
          }
          return "";
        }}
        onSuggest={isInCompanyCodes}
        disabled={formik.values.detailsTabIsBpo || props.disabled}
      />
    </React.Fragment>
  );
};

GLCreateCompanyCode.displayName = "GLCreateCompanyCode";

export { GLCreateCompanyCode };
