import { Dropdown, IDropdownOption } from "@fluentui/react";
import {
  DomainDataObjects,
  FiscalPeriodStringTransformations,
  fyFp,
  convertToFiscalDropdownOptions,
  ForceMaxWidthAndHeightOnDropdownStyle,
  DomainDataEnum
} from "@jem/components";
import React, { useEffect, useImperativeHandle, useState } from "react";

export interface FiscalYearFiscalPeriodDropdownRef {
  getFiscalPeriod: () => {
    fiscalYear: number;
    fiscalMonth: number;
  };
  reset: () => void;
}

export interface FiscalYearFiscalPeriodDropdownProps {
  domainData: Pick<DomainDataObjects, DomainDataEnum.FiscalPeriodsBPO> | null;
  showBlankFYFPOption?: boolean;
  onChange?: (fiscalPeriods: number[]) => void;
  customRef: React.Ref<FiscalYearFiscalPeriodDropdownRef>;
  value?: {
    fiscalYear: number;
    fiscalMonth: number;
  };
}

const FiscalYearFiscalPeriodDropdown: React.FC<FiscalYearFiscalPeriodDropdownProps> = (props) => {
  const [fiscalPeriod, setFiscalPeriod] = useState<string>(
    props.value
      ? FiscalPeriodStringTransformations.FiscalYearAndFiscalMonthToString(
          props.value.fiscalYear,
          props.value.fiscalMonth
        )
      : FiscalPeriodStringTransformations.FiscalYearAndFiscalMonthToString(fyFp.fiscalYear, fyFp.fiscalMonth)
  );

  const fiscalPeriodOptions = convertToFiscalDropdownOptions(
    props.domainData ? props.domainData.FiscalPeriodsBPO : [],
    false,
    props.showBlankFYFPOption ? props.showBlankFYFPOption : false
  );
  useImperativeHandle(props.customRef, () => ({
    getFiscalPeriod: () => {
      return FiscalPeriodStringTransformations.FiscalPeriodStringToFiscalYearAndFiscalMonth(fiscalPeriod);
    },
    reset: () =>
      setFiscalPeriod(
        FiscalPeriodStringTransformations.FiscalYearAndFiscalMonthToString(fyFp.fiscalYear, fyFp.fiscalMonth)
      )
  }));

  const onChangeFiscalPeriods = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void => {
    if (option) {
      setFiscalPeriod(option.key as string);
    }
  };

  useEffect(() => {
    if (props.value) {
      setFiscalPeriod(
        FiscalPeriodStringTransformations.FiscalYearAndFiscalMonthToString(
          props.value.fiscalYear,
          props.value.fiscalMonth
        )
      );
    }
  }, [props.value]);

  return (
    <>
      <Dropdown
        label="Fiscal Year/Period:"
        selectedKey={fiscalPeriod}
        onChange={onChangeFiscalPeriods}
        options={fiscalPeriodOptions}
        styles={ForceMaxWidthAndHeightOnDropdownStyle({
          width: "140px",
          height: "300px"
        })}
        disabled={props.domainData === null}
      />
    </>
  );
};

FiscalYearFiscalPeriodDropdown.displayName = "FiscalYearFiscalPeriodDropdown";
export default FiscalYearFiscalPeriodDropdown;
