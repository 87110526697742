import { JemConfiguration, IUserProviderState, getValidUrl, getRequest, ApiError, IAttachment } from "@jem/components";
import { PaymentOrderObjectFromApi, poAttachmentToIAttachment } from "../../shared/IHCC.types";
import { IHCCCreatePageModel, PaymentOrderDraft } from "./IHCCDraftContext.types";
import { isValid } from "date-fns";
export interface IhccDraftArgs {
  configuration: JemConfiguration["IhccApi"];
}

export type IhccCreatePageApiResponse = PaymentOrderObjectFromApi[];

export const EMPTY_MODEL: IHCCCreatePageModel = {
  alias: "",
  dateCreated: "",
  primaryReviewer: "",
  additionalReviewers: "",
  comments: "",
  isBulk: false,
  paymentOrderList: [],
  attachments: []
};

export async function fetchIhccDraft(
  configuration: JemConfiguration,
  getTokenFn: IUserProviderState["accessToken"]
): Promise<IHCCCreatePageModel> {
  const base = configuration.IhccApi.baseApiUrl;
  const endpoint = getValidUrl(`${base}${configuration.IhccApi.endpoints.createPageGetDraft}`);
  const response = await getRequest<IhccCreatePageApiResponse>(endpoint, getTokenFn);
  if (!response || !Array.isArray(response)) {
    throw new ApiError("Could not fetch IHCC Draft.");
  }
  return responseToPageModel(response);
}

function singlePaymentOrderToModel(paymentOrder: PaymentOrderObjectFromApi): PaymentOrderDraft {
  const amount = paymentOrder.receivingPartyAmountInAccountCurrency;
  return {
    id: paymentOrder.id || 0,
    refGuid: paymentOrder.refGuid,
    rowVer: paymentOrder.rowVer,
    opAccount: (paymentOrder.orderingPartyAccountNumber || "").trim(),
    transType: (paymentOrder.transactionType || "").trim(),
    transAmount: amount || 0,
    transCurrency: (paymentOrder.receivingPartyCurrency || "").trim(),
    rpAccount: (paymentOrder.receivingPartyAccountNumber || "").trim(),
    purpose: (paymentOrder.purpose || "").trim(),
    requestedDate: paymentOrder.createdDate
      ? isValid(new Date(paymentOrder.createdDate))
        ? new Date(paymentOrder.createdDate)
        : null
      : null,
    processingStatus: paymentOrder.processingStatus || ""
  };
}

function createPaymentOrderListFromApiResponse(
  response: IhccCreatePageApiResponse
): IHCCCreatePageModel["paymentOrderList"] {
  if (response.length === 0) {
    return [];
  }
  return response.map(singlePaymentOrderToModel);
}

function createAttachmentsForUi(response: IhccCreatePageApiResponse): IHCCCreatePageModel["attachments"] {
  if (response.length === 0) {
    return [];
  }
  const att = response[0].poAttachments;
  if (att.length === 0) {
    return [];
  }
  const newAttachments = att.reduce((newAtts, singleAttachment) => {
    const possibleNewAttachment = poAttachmentToIAttachment(singleAttachment);
    if (!possibleNewAttachment) {
      return newAtts;
    }
    newAtts.push(possibleNewAttachment);
    return newAtts;
  }, [] as IAttachment[]);
  return newAttachments;
}

export function responseToPageModel(response: IhccCreatePageApiResponse): IHCCCreatePageModel {
  if (response.length === 0) {
    return EMPTY_MODEL;
  }
  const firstItem = response[0];
  return {
    alias: firstItem.posterAlias || "",
    dateCreated: firstItem.createdDate || "",
    primaryReviewer: firstItem.supervisorAliasFromDB || "",
    additionalReviewers: firstItem.addtionalReviewersCSVFromDB || "",
    comments: firstItem.comments || "",
    isBulk: firstItem.bulkGUID != "" && firstItem.bulkGUID != undefined ? true : false,
    paymentOrderList: createPaymentOrderListFromApiResponse(response),
    attachments: createAttachmentsForUi(response)
  };
}
