import { ApiError, RuntimeError } from "./ErrorHelpers";
import { IUserProviderState } from "./RequestUtilities";

export function IsCacheValid(key: string): boolean {
  let isValid = false;
  if (!localStorage.getItem(key)) {
    isValid = false;
  } else {
    const storageExpiry = new Date().getTime();
    const storageData = localStorage.getItem(key);
    if (storageData) {
      isValid = JSON.parse(storageData).validUntil >= storageExpiry;
    }
  }
  return isValid;
}

function handleParsedCached<T>(keyName: string, errorCheck: (parsedCachedData: T) => boolean, parsedCachedData?: T): T {
  if (!parsedCachedData) {
    // cached data is undefined, null, or empty. Refresh local store
    throw new RuntimeError(`Invalid data on cached key ${keyName}`);
  } else {
    if (errorCheck(parsedCachedData)) {
      return parsedCachedData;
    } else {
      throw new RuntimeError(`Invalid data on cached key ${keyName}`);
    }
  }
}

function handleRecursionOrMalformedCachedState<T, U>(
  configuration: U,
  keyName: string,
  getterFn: (configuration: U, getTokenFn: IUserProviderState["accessToken"]) => Promise<T>,
  getTokenFn: IUserProviderState["accessToken"],
  stopRecursion: boolean,
  returnDefault: () => T,
  type: "object" | "array",
  hours: number
) {
  if (stopRecursion) {
    return returnDefault();
  } else {
    // cached data is malformed. Refresh local store
    localStorage.removeItem(keyName);
    if (type === "object") {
      return getObjectFromCacheOrFromEndpoint<T, U>(configuration, keyName, getterFn, getTokenFn, hours, true);
    } else {
      return getArrayFromCacheOrFromEndpoint<T, U>(configuration, keyName, getterFn, getTokenFn, hours, true);
    }
  }
}

/**
 * Standardize fetching from remote endpoint or from local storage
 * @param keyName {string} Name of Key in Local Storage
 * @param getterFn {function} Function to fetch specific store from remote
 * @param getTokenFn {function} Function to fetch a valid token from Auth Library
 * @param stopRecursion {boolean} Default: false, stops recursion
 */
export async function getArrayFromCacheOrFromEndpoint<T, U>(
  configuration: U,
  keyName: string,
  getterFn: (configuration: U, getTokenFn: IUserProviderState["accessToken"]) => Promise<T>,
  getTokenFn: IUserProviderState["accessToken"],
  hours = 48,
  stopRecursion = false
): Promise<T> {
  try {
    if (!IsCacheValid(keyName)) {
      const data = await getterFn(configuration, getTokenFn);
      if (Array.isArray(data) && data.length === 0) {
        // don't cache because we received empty array
        return data;
      }
      const cachedDetailsToSave = {
        data,
        validUntil: new Date().getTime() + hours * 3600000
      };
      console.info("Retrieved: ", keyName, "from Remote");
      localStorage.setItem(keyName, JSON.stringify(cachedDetailsToSave));
      return data as T;
    } else {
      const cachedData = localStorage.getItem(keyName);
      console.info("Retrieved: ", keyName, "from Local");
      const parsedCachedData = cachedData !== null ? (JSON.parse(cachedData).data as T) : undefined;
      return handleParsedCached<T>(keyName, (p) => Array.isArray(p), parsedCachedData);
    }
  } catch (e) {
    if (e instanceof ApiError) {
      throw e;
    }
    return handleRecursionOrMalformedCachedState<T, U>(
      configuration,
      keyName,
      getterFn,
      getTokenFn,
      stopRecursion,
      () => [] as unknown as T,
      "array",
      hours
    );
  }
}

/**
 * Standardize fetching from remote endpoint or from local storage
 * @param keyName {string} Name of Key in Local Storage
 * @param getterFn {function} Function to fetch specific store from remote
 * @param getTokenFn {function} Function to fetch a valid token from Auth Library
 */
export async function getObjectFromCacheOrFromEndpoint<T, U>(
  configuration: U,
  keyName: string,
  getterFn: (configuration: U, getTokenFn: IUserProviderState["accessToken"]) => Promise<T>,
  getTokenFn: IUserProviderState["accessToken"],
  hours = 48,
  stopRecursion = false
): Promise<T> {
  try {
    if (!IsCacheValid(keyName)) {
      const data = await getterFn(configuration, getTokenFn);
      if (Object.keys(data as Record<string, unknown>).length === 0) {
        // don't cache because we received empty object
        return data;
      }
      const cachedDetailsToSave = {
        data,
        validUntil: new Date().getTime() + hours * 3600000
      };
      console.info("Retrieved: ", keyName, "from Remote");
      localStorage.setItem(keyName, JSON.stringify(cachedDetailsToSave));
      return data as T;
    } else {
      const cachedData = localStorage.getItem(keyName);
      console.info("Retrieved: ", keyName, "from Local");
      const parsedCachedData = cachedData !== null ? (JSON.parse(cachedData).data as T) : undefined;
      return handleParsedCached<T>(
        keyName,
        (p) => Object.keys(p as Record<string, unknown>).length !== 0,
        parsedCachedData
      );
    }
  } catch (e) {
    if (e instanceof ApiError) {
      throw e;
    }
    return handleRecursionOrMalformedCachedState<T, U>(
      configuration,
      keyName,
      getterFn,
      getTokenFn,
      stopRecursion,
      () => ({} as unknown as T),
      "object",
      hours
    );
  }
}
