/* eslint-disable @typescript-eslint/no-explicit-any */
export type RequireAtLeastOne<T, Keys extends keyof T = keyof T> = Pick<T, Exclude<keyof T, Keys>> &
  {
    [K in Keys]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<Keys, K>>>;
  }[Keys];

export function validateGeneric<K extends PropertyKey>(key: K, value: any) {
  return {
    [key]: value
  } as { [P in K]?: { otherProp: any } };
}

export const ObjectKeys = <Obj>(obj: Obj): (keyof Obj)[] => {
  return Object.keys(obj as any) as (keyof Obj)[];
};

export type StringWithAutoComplete<T> = T | (string & Record<never, never>);

enum DateStrBrand {}
/**
 * @description
 * DateStr is a string that can be parsed into a date using new Date(theDateStr);
 */
export type DateStr = string & DateStrBrand;

export type NonNullable<T> = Exclude<T, null | undefined>;
