import { BaseButton, Button, DefaultButton, PrimaryButton } from "@fluentui/react";
import React, { PropsWithChildren, useContext, useRef, useState } from "react";
import { GLAttachments, GLAttachmentsProps, GLAttachmentsRef } from "../../../GeneralLedger/shared/GL.Attachments";

import { ActionsContext } from "./Actions.ActionsContext";
import { ActionTitle } from "./Actions.ActionTitle";
import { submissionStyles } from "./Actions.BottomButtons";
import { BaseActionProps, CommonPropsOnRows } from "./Actions.types";

import { LoadingStatus } from "../../utilities";
import { GLAttachment, IAttachment } from "../Attachments/Attachments.types";
import { ActionResult } from "../ActionManager";

interface AttachmentsMainProps<ListType, ResultType, AttachmentType = IAttachment>
  extends BaseActionProps<ListType, ResultType> {
  onSubmitHandler: (
    items: ListType[],
    attachment: AttachmentType[],
    regionKey: number,
    regionName: string,
    isRescind?: boolean
  ) => Promise<ResultType>;
}

export type GLActionAttachmentsProps<ListType, GLActionResult> = AttachmentsMainProps<
  ListType,
  GLActionResult,
  GLAttachment
> &
  Omit<
    GLAttachmentsProps,
    "customRef" | "initialAttachments" | "disabled" | "loadingStatus" | "lockRegion" | "initialRegion"
  >;

const GLAttachmentsAction = <ListType extends CommonPropsOnRows, ResultType extends ActionResult>(
  props: PropsWithChildren<GLActionAttachmentsProps<ListType, ResultType>>
) => {
  const [disabled, setDisabled] = useState(true);

  const { styles } = useContext(ActionsContext);
  const attachmentsRef = useRef<GLAttachmentsRef>(null);

  const onSubmit = async (
    event: React.MouseEvent<
      HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | BaseButton | Button | HTMLSpanElement,
      MouseEvent
    >
  ) => {
    event.stopPropagation();
    setDisabled(true);
    if (attachmentsRef.current) {
      const [attachments, error] = await attachmentsRef.current.saveAndGetAttachments();
      if (!error) {
        const result = await props.onSubmitHandler(
          props.items,
          attachments.attachments,
          attachments.region_key,
          attachments.region_name
        );
        props.onSubmit(result);
      } else {
        setDisabled(false);
      }
    }
  };

  return (
    <>
      <ActionTitle onCloseButton={() => props.onCancel()} name="Add Attachment"></ActionTitle>
      <div className={styles.contentStyles.body}>
        <GLAttachments
          customRef={attachmentsRef}
          onUploadFile={props.onUploadFile}
          onDeleteFile={props.onDeleteFile}
          onDownloadFile={props.onDownloadFile}
          onChange={(lAttachments, rAttachments, errorMessage) => {
            if (lAttachments.length === 0) {
              setDisabled(true);
            } else if (errorMessage) {
              setDisabled(true);
            } else {
              setDisabled(false);
            }
          }}
          initialAttachments={[]}
          initialRegion={"2"} // REDMOND
          disabled={false}
          loadingStatus={LoadingStatus.Resolved}
          items={props.items}
          lockRegion={false}
        ></GLAttachments>
        <div className={submissionStyles}>
          <div className="action-manager--footer-buttons">
            <PrimaryButton onClick={onSubmit} disabled={disabled} text={"Upload"} />
            <DefaultButton
              onClick={(event) => {
                event.stopPropagation();
                props.onCancel();
              }}
              text="Cancel"
            />
          </div>
        </div>
      </div>
    </>
  );
};

GLAttachmentsAction.displayName = "GLAttachments";
export { GLAttachmentsAction };
