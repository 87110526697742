export interface IWeightages {
  Retry: number;
}

export interface IWeightageCalculatorArgs {
  rowWeightage: number;
}

export interface IWeightageCalculator {
  isRetryButtonEnabled(weightages: IWeightages, opts: IWeightageCalculatorArgs): boolean;
}

export function isButtonEnabled(buttonWeightage: number, rowWeightage?: number) {
  // the rowWeightage is an array of Bits, e.g., 1814, which in binary is 11100010110
  // the buttonWeightage is a single Bit, e.g., 512, wich is              01000000000
  // so if the Logical AND of those two is TRUE, then it will return the  -----------
  // following:                                                           01000000000
  // which is the same as the buttonWeightage
  // so if the Logical AND of these is TRUE, then the button must be enabled
  if (rowWeightage !== undefined) {
    const and = rowWeightage & buttonWeightage;
    return and === buttonWeightage;
  }
  return false;
}

function getButtonWeightage(weightages: IWeightages, button: keyof IWeightages): number {
  if (!weightages) {
    console.error("No action weightages available.");
    return 0;
  }
  if (!(button in weightages)) {
    console.error(`No action named ${button} in Action Weightages.`);
    return 0;
  }

  return weightages[button];
}

const WeightageCalculator: IWeightageCalculator = {
  isRetryButtonEnabled(weightages, { rowWeightage }) {
    const buttonWeightage = getButtonWeightage(weightages, "Retry");
    return isButtonEnabled(buttonWeightage, rowWeightage);
  }
};

export { WeightageCalculator };
