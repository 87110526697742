import { css } from "@emotion/css";
import { SelectionMode, Stack, Text } from "@fluentui/react";

import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import { JemConfiguration } from "../../../JemConfiguration";
import { UserContext } from "../../contexts";
import { LoadingStatus } from "../../utilities/Utilities";
import { DashboardGrid } from "../DashboardGrid/DashboardGrid";
import { exportToExcel } from "../DashboardGrid/DashboardGrid.utilities";
import { RefetchButton } from "../RefetchButton/RefetchButton";
import { getBulkDetailsColumn } from "./BulkDetailsFromJeId.columns";
import { fetchBulkDetails } from "./BulkDetailsFromJeId.requests";
import { useQuery } from "../../hooks/useQuery";

export interface OtherDetailsTabProps {
  jeId: number;
  loadingStatus: LoadingStatus;
  configuration: JemConfiguration["GeneralLedgerApi"];
}

const BulkDetailsFromJeId: React.FC<OtherDetailsTabProps> = (props) => {
  const userContext = useContext(UserContext);
  const location = useLocation();

  let cacheKey: null | [string, string] = null;
  if (props.loadingStatus === LoadingStatus.Resolved) {
    cacheKey = ["bulkItems", `${props.jeId}`];
  }

  const {
    data: bulkItems,
    error,
    isFetching,
    isLoading,
    dataUpdatedAt,
    refetch
  } = useQuery({
    queryKey: cacheKey as [string, string],
    queryFn: (queryOptions) => {
      const [, jeId] = queryOptions.queryKey as [string, string];
      return fetchBulkDetails(props.configuration, userContext.accessToken, jeId);
    },
    enabled: cacheKey !== null && props.loadingStatus === LoadingStatus.Resolved
  });

  let message = "Action performed on the current JE will also impact other JEs in the bulk.";
  if (bulkItems) {
    message = "Action performed on the current JE will also impact the below " + bulkItems.length + " JEs in the bulk.";
  }

  let errorMessage: undefined | string = undefined;
  if (error) {
    if ("message" in (error as Error)) {
      errorMessage = "Error fetching Bulk Details: " + (error as unknown as { message: string }).message;
    } else {
      errorMessage = "Unknown error fetching Bulk Details.";
    }
  }

  const loadingStatus =
    props.loadingStatus !== LoadingStatus.Resolved
      ? props.loadingStatus
      : isFetching || isLoading
      ? LoadingStatus.Pending
      : LoadingStatus.Resolved;

  return (
    <>
      <Stack>
        <Stack.Item>
          <div className="ms-Grid" dir="ltr">
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm12 ms-lg6">
                <Text>{message}</Text>
              </div>
              <div className="ms-Grid-col ms-sm12 ms-lg6">
                <RefetchButton
                  lastRefreshed={dataUpdatedAt}
                  onRefetch={refetch}
                  disabled={loadingStatus === LoadingStatus.Pending}
                  hidden={!bulkItems}
                />
              </div>
            </div>
          </div>
        </Stack.Item>
        <Stack.Item>
          {errorMessage !== undefined ? (
            <Text>{errorMessage}</Text>
          ) : (
            <div
              className={css`
                border-left: 2px solid;
                padding-left: 10px;
              `}
              data-is-scrollable="true"
            >
              <DashboardGrid
                idForLocalStorage={"glBulkDetailsFromJeId"}
                customRef={null}
                columnGenerator={getBulkDetailsColumn(location)}
                items={bulkItems || []}
                isSortedIndex={0}
                maxHeight={`350px`}
                height={` ${bulkItems ? 50 + bulkItems.length * 40 : 250}px`}
                isSortedDescending={true}
                loadingStatus={loadingStatus}
                selectionMode={SelectionMode.none}
                onExport={(rows) => {
                  exportToExcel({
                    sheetName: "bulkDetailsFromJeId",
                    rowsWithHeader: rows,
                    fileName: "bulkDetailsFromJeId.xlsx"
                  });
                }}
                disablePagination={true}
              ></DashboardGrid>
            </div>
          )}
        </Stack.Item>
      </Stack>
    </>
  );
};

BulkDetailsFromJeId.displayName = "BulkDetailsFromJeId";
export default BulkDetailsFromJeId;
