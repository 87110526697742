import React, { useImperativeHandle, useMemo, useState } from "react";
import { Dropdown, IDropdownOption } from "@fluentui/react";
import {
  CommonDropdownOnChangeHandler,
  convertToReasonCodeDropdownOptions,
  DomainDataEnum,
  DomainDataObjects,
  ForceMaxWidthAndHeightOnDropdownStyle
} from "@jem/components";

export interface ReasonCodeRef {
  getReasonCode: () => string[];
  setInitialReasonCode: (initialReasonCode: string[]) => void;
  reset: () => void;
}

interface IReasonCodeProps {
  loading: boolean;
  domainData: Pick<DomainDataObjects, DomainDataEnum.JeReasonCodes>;
  customRef: React.Ref<ReasonCodeRef>;
}

export const ReasonCodeOptions = (domainData: Pick<DomainDataObjects, DomainDataEnum.JeReasonCodes>) => {
  const reasonCodesForDropdown: IDropdownOption[] = convertToReasonCodeDropdownOptions(
    domainData[DomainDataEnum.JeReasonCodes],
    true
  );
  reasonCodesForDropdown.unshift({ key: "all", text: "All" });
  reasonCodesForDropdown.unshift({ key: "none", text: "None Selected", disabled: true });

  return reasonCodesForDropdown;
};

const ReasonCodeDropdown: React.FC<IReasonCodeProps> = (props) => {
  const [ReasonCode, setReasonCode] = useState<string[]>(["all"]);
  const reasonCodeOptions = useMemo(() => {
    return ReasonCodeOptions(props.domainData);
  }, [props.domainData]);

  const onChangeReasonCode = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void => {
    if (!option) {
      return;
    }

    const newOptions = CommonDropdownOnChangeHandler({
      currentOptions: ReasonCode,
      option: option,
      keyForAll: "all",
      keyForNone: "none",
      totalOptions: reasonCodeOptions.length - 2
    });
    setReasonCode(newOptions);
  };

  useImperativeHandle(
    props.customRef,
    (): ReasonCodeRef => ({
      getReasonCode() {
        return ReasonCode;
      },
      setInitialReasonCode(initialReasonCode: string[]) {
        setReasonCode(initialReasonCode);
      },
      reset() {
        setReasonCode(["all"]);
      }
    })
  );

  return (
    <React.Fragment>
      <Dropdown
        label="Reason Code:"
        selectedKeys={ReasonCode}
        onChange={onChangeReasonCode}
        options={reasonCodeOptions}
        disabled={props.loading}
        multiSelect
        styles={ForceMaxWidthAndHeightOnDropdownStyle({
          height: "300px",
          width: "100%",
          maxWidth: "100%"
        })}
      />
    </React.Fragment>
  );
};

ReasonCodeDropdown.displayName = "ReasonCodeDropdown";
export default ReasonCodeDropdown;
