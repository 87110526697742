import React from "react";
import { ApiParsingUtilities, DEFAULT_NULL_CHARACTER } from "../../../Shared/utilities/ApiParsingUtilities";
import { AutomatedJeDashboardElement } from "../AutomatedJeDashboard/AutomatedJeDashboard.requests";
import {
  FullJeDetails,
  IJeDetailsEndpointPayload,
  HeaderDetailsEntity,
  TransactionDetailsEntity,
  HeaderDetails,
  TransactionDetails
} from "./JEDetails.schema";

function parseSingleTransactionEntry(item: TransactionDetailsEntity): TransactionDetails {
  return {
    ItemId: ApiParsingUtilities.addDefault(item.item),
    POS: ApiParsingUtilities.addDefault(item.pos),
    Account: ApiParsingUtilities.addDefault(item.account),
    AccountDescription: ApiParsingUtilities.addDefault(item.accountDescription),
    CC: ApiParsingUtilities.addDefault(item.cc),
    IO: ApiParsingUtilities.addDefault(item.io),
    SO: ApiParsingUtilities.addDefault(item.so),
    SOL: ApiParsingUtilities.addDefault(item.sol),
    TP: ApiParsingUtilities.addDefault(item.tp),
    TC: ApiParsingUtilities.addDefault(item.tc),
    PC: ApiParsingUtilities.addDefault(item.pc),
    TransAmnt: ApiParsingUtilities.parseNumber(item.transactionAmt),
    TransCurr: ApiParsingUtilities.addDefault(item.transactionCurrency),
    StatAmnt: ApiParsingUtilities.parseNumber(item.statAmount),
    StatCurr: ApiParsingUtilities.addDefault(item.statCurrency),
    GCAmnt: ApiParsingUtilities.parseNumber(item.gcAmount),
    LineItemDescription: ApiParsingUtilities.addDefault(item.lineItemDescription),
    AssignmentId: ApiParsingUtilities.addDefault(item.assignmentId)
  };
}

function parseSingleHeaderEntry(item: HeaderDetailsEntity): HeaderDetails {
  return {
    System: ApiParsingUtilities.addDefault(item.system),
    DocNumber: ApiParsingUtilities.addDefault(item.docNumber),
    RefNumber: ApiParsingUtilities.addDefault(item.refNumber),
    CompanyCode: ApiParsingUtilities.addDefault(item.companycode),
    TotalAmount: ApiParsingUtilities.parseTotal(item.debitAmount, item.creditAmount),
    JEType: ApiParsingUtilities.addDefault(item.jEtype),
    Transaction: ApiParsingUtilities.addDefault(item.transaction),
    DebitAmount: ApiParsingUtilities.parseNumber(item.debitAmount),
    FiscalYearAndPeriod: ApiParsingUtilities.addDefault(item.fiscalYearPeriod),
    Currency: ApiParsingUtilities.addDefault(item.currency),
    CreditAmount: ApiParsingUtilities.parseNumber(item.creditAmount),
    DocDate: ApiParsingUtilities.parseDate(item.docDate),
    PostingDate: ApiParsingUtilities.parseDate(item.postingdate),
    TimeOfEntry: ApiParsingUtilities.addDefault(item.timeOfEntry),
    Description: ApiParsingUtilities.addDefault(item.description),
    RequestId: ApiParsingUtilities.addDefault(item.requestId)
  };
}

export function convertJeDetailsFromPayloadToUI(
  item: AutomatedJeDashboardElement,
  details: IJeDetailsEndpointPayload
): FullJeDetails {
  let possibleId: string | JSX.Element = ApiParsingUtilities.addDefault(details.docNumber);
  if (possibleId === DEFAULT_NULL_CHARACTER) {
    possibleId = ApiParsingUtilities.addDefault(details.jeId);
    if (possibleId !== DEFAULT_NULL_CHARACTER) {
      possibleId = (
        <React.Fragment>
          {possibleId}
          <span>(ID)</span>
        </React.Fragment>
      );
    }
  } else {
    possibleId = (
      <React.Fragment>
        {possibleId}
        <span>(Document Number)</span>
      </React.Fragment>
    );
  }
  return {
    id: possibleId as JSX.Element,
    indicator: ApiParsingUtilities.addDefault(details.indicator),
    status: ApiParsingUtilities.addDefault(item.jeStatus),
    author: ApiParsingUtilities.addDefault(details.author),
    poster: ApiParsingUtilities.addDefault(details.poster),
    reviewer: ApiParsingUtilities.addDefault(details.reviewer),
    dateModified: ApiParsingUtilities.parseDate(details.dateModified),
    docNumber: ApiParsingUtilities.addDefault(item.jeDocNum),
    postersOrg: ApiParsingUtilities.addDefault(details.posterOrg),
    reviewersOrg: ApiParsingUtilities.addDefault(details.reviewerOrg),
    classification: ApiParsingUtilities.addDefault(details.classification),
    FiscalYearAndPeriod: ApiParsingUtilities.addDefault(details.fiscalYearPeriod),
    translationDate: ApiParsingUtilities.parseDate(details.translationDate),
    description: ApiParsingUtilities.addDefault(details.description),
    companyCode: ApiParsingUtilities.addDefault(details.companyCode),
    recurringJE: ApiParsingUtilities.addDefault(details.recurringJE),
    reversalJE: ApiParsingUtilities.addDefault(details.reversalJE),
    dueDate: ApiParsingUtilities.parseDate(details.dueDate),
    purpose: ApiParsingUtilities.addDefault(details.purpose),
    refNumbber: ApiParsingUtilities.addDefault(details.refNumber),
    significance: ApiParsingUtilities.addDefault(details.significance),
    recurringYear: ApiParsingUtilities.addDefault(details.recurringYear),
    reasonCode: ApiParsingUtilities.addDefault(details.reasoncode),
    planReversalDate: ApiParsingUtilities.parseDate(details.planReversalDate),
    headerDetails: !details.headerDetails ? [] : details.headerDetails.map(parseSingleHeaderEntry),
    transactionDetails: !details.transactionDetails ? [] : details.transactionDetails.map(parseSingleTransactionEntry)
  };
}
