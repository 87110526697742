import { MessageBarType } from "@fluentui/react";
import { DomainDataEnum, DomainDataObjects, IUserProviderState, LocalMessage } from "../../../../Shared";
import { AdditionalData, GLAttachmentsRef } from "../../../shared";
import { CheckForErrorsInBatchTabs } from "../../../validation";
import { ErrorListModel } from "../../GLCreate";
import { GLBatchActionsEnum } from "../GLBatch.Actions";
import { GLBatchPageState } from "../GLBatch.State";
import { GLBatchFormikState, GLBatchProps } from "../GLBatch.types";
import { BatchActionOnSubmitHandlerMap } from "./GLBatchActions";

export type UIBatchActionDelegationOptions = {
  userAction:
    | GLBatchActionsEnum.Clear
    | GLBatchActionsEnum.Post
    | GLBatchActionsEnum.Save
    | GLBatchActionsEnum.Validate
    | GLBatchActionsEnum.NoActivity
    | GLBatchActionsEnum.PreReview;
  actionOptions?: AdditionalData;
  actions: BatchActionOnSubmitHandlerMap;
  userChanges: GLBatchFormikState;
  attachmentsRef: GLAttachmentsRef;
  initialState: GLBatchPageState;
  configuration: GLBatchProps["configuration"];
  userContext: Pick<IUserProviderState, "accessToken" | "user" | "jemUser">;
  domainData: Pick<DomainDataObjects, DomainDataEnum.JeReasonCodes | DomainDataEnum.JeBatchFields>;
};

export interface OnActionOptions {
  preventReloadOfData: boolean;
}

export type GLBatchActionResult = {
  action:
    | GLBatchActionsEnum.Clear
    | GLBatchActionsEnum.Post
    | GLBatchActionsEnum.Save
    | GLBatchActionsEnum.Validate
    | GLBatchActionsEnum.NoActivity
    | GLBatchActionsEnum.PreReview;
  RefGuid: string;
  message: LocalMessage;
  batchErrors?: ErrorListModel[];
  options?: OnActionOptions;
  blockPage: boolean;
};

export async function UIBatchActionDelegation(opts: UIBatchActionDelegationOptions): Promise<GLBatchActionResult> {
  const {
    userAction,
    actionOptions,
    userChanges,
    attachmentsRef,
    initialState,
    configuration,
    userContext,
    domainData,
    actions
  } = opts;

  // If user wants to clear the page go ahead before checking for errors
  if (userAction === GLBatchActionsEnum.Clear) {
    const result = await actions[GLBatchActionsEnum.Clear]({
      formikState: userChanges,
      state: {
        batchState: initialState.batchState,
        pageConfiguration: initialState.pageConfiguration
      }
    });
    return {
      action: userAction,
      message: result.message,
      RefGuid: result.refGuid,
      blockPage: result.blockPage
    };
  }

  if (opts.userChanges.detailsTabBatchName === "") {
    return {
      action: userAction,
      message: {
        message: "Please add a name to the Batch File.",
        type: MessageBarType.error
      },
      RefGuid: initialState.batchState.refGuid,
      options: {
        preventReloadOfData: true
      },
      blockPage: false
    };
  }

  // attach files and get errors
  const [changes, attachmentErrors] = await attachmentsRef.saveAndGetAttachments();

  if (attachmentErrors) {
    return {
      action: userAction,
      message: {
        message: "There were errors in the Attachments Tab. Please correct them and try again.",
        type: MessageBarType.error
      },
      RefGuid: initialState.batchState.refGuid,
      options: {
        preventReloadOfData: true
      },
      blockPage: false
    };
  }

  // check for errors in tabs
  // note that not all actions require validation
  const errors = await CheckForErrorsInBatchTabs({
    configuration: configuration,
    accessToken: userContext.accessToken,
    formikState: userChanges,
    userAction,
    initialState: initialState.batchState,
    domainData
  });
  const saveMessage = await actions[GLBatchActionsEnum.Save]({
    formikState: userChanges,
    attachmentObjects: changes,
    state: {
      batchState: initialState.batchState,
      pageConfiguration: initialState.pageConfiguration
    }
  });
  const pathToBatch = saveMessage.pathToBatchInDocumentsApi;

  if (userAction === GLBatchActionsEnum.Save) {
    return {
      action: userAction,
      message: saveMessage.message,
      RefGuid: saveMessage.refGuid,
      blockPage: saveMessage.blockPage
    };
  } else if (userAction === GLBatchActionsEnum.NoActivity) {
    const noActivityMessage = await actions[GLBatchActionsEnum.NoActivity](
      {
        state: {
          batchState: initialState.batchState,
          pageConfiguration: initialState.pageConfiguration
        }
      },
      actionOptions as AdditionalData
    );
    return {
      action: userAction,
      ...noActivityMessage,
      RefGuid: initialState.batchState.refGuid,
      options: {
        preventReloadOfData: noActivityMessage.message.type === MessageBarType.success
      },
      blockPage: noActivityMessage.blockPage
    };
  }
  if (errors.length !== 0) {
    let message = "There are errors in the JE. Please correct them and try again.";
    if (errors.length === 1) {
      message = errors[0];
    }
    // } else if (errors.length <= 3) {
    //   message = errors.join(",");
    // }
    return {
      action: userAction,
      message: {
        message,
        type: MessageBarType.error
      },
      RefGuid: initialState.batchState.refGuid,
      blockPage: false
    };
  }

  if (!pathToBatch) {
    return {
      action: userAction,
      message: {
        message: "Please add a Batch File.",
        type: MessageBarType.error
      },
      RefGuid: initialState.batchState.refGuid,
      blockPage: false
    };
  }

  const validateResult = await actions[GLBatchActionsEnum.Validate]({
    formikState: userChanges,
    attachmentObjects: changes,
    state: {
      batchState: initialState.batchState,
      pageConfiguration: initialState.pageConfiguration
    },
    pathToBatchInDocumentApi: pathToBatch
  });

  if (validateResult.message.type === MessageBarType.error || userAction === GLBatchActionsEnum.Validate) {
    return {
      action: userAction,
      message: validateResult.message,
      RefGuid: validateResult.refGuid,
      batchErrors: validateResult.batchErrors,
      blockPage: validateResult.blockPage
    };
  }
  if (userAction === GLBatchActionsEnum.PreReview) {
    const preReviewResult = await actions[GLBatchActionsEnum.PreReview]({
      formikState: userChanges,
      attachmentObjects: changes,
      state: {
        batchState: initialState.batchState,
        pageConfiguration: initialState.pageConfiguration
      },
      pathToBatchInDocumentApi: pathToBatch
    });
    if (preReviewResult.message.type === MessageBarType.error || userAction === GLBatchActionsEnum.PreReview) {
      return {
        action: userAction,
        message: preReviewResult.message,
        RefGuid: preReviewResult.refGuid,
        batchErrors: preReviewResult.batchErrors,
        blockPage: true
      };
    }
  }
  const postResult = await actions[GLBatchActionsEnum.Post]({
    formikState: userChanges,
    attachmentObjects: changes,
    state: {
      batchState: initialState.batchState,
      pageConfiguration: initialState.pageConfiguration
    },
    pathToBatchInDocumentApi: pathToBatch
  });

  if (validateResult.message.type === MessageBarType.warning) {
    return {
      action: userAction,
      message: {
        message: `Batch Posted: ${validateResult.message.message.replace("Batch Validated.", "")}`,
        type: MessageBarType.warning
      },
      RefGuid: postResult.refGuid,
      batchErrors: postResult.batchErrors,
      blockPage: postResult.blockPage
    };
  }

  return {
    action: userAction,
    message: postResult.message,
    RefGuid: postResult.refGuid,
    batchErrors: postResult.batchErrors,
    blockPage: postResult.blockPage
  };
}
