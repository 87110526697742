import { Dropdown, IDropdownOption } from "@fluentui/react";
import { convertToReasonCodeDropdownOptions, DomainDataObjects, JEMContext } from "@jem/components";
import React, { forwardRef, useContext, useEffect, useImperativeHandle, useState } from "react";
//import { MaxWidthHeightOptions } from "../../utilities/FluentUIHacks";

export interface IReasonCodeRef {
  getReasonCode: () => IDropdownOption<string> | null;
  setInitialReasonCode: (initialReasonCode: string) => void;
  reset: () => void;
}

interface IReasonCodeProps {
  loading: boolean;
  onChange?: (ReasonCode: IDropdownOption<string>) => void;
  //styles?: MaxWidthHeightOptions;
  ReasonCode: string;
}

const ReasonCodeDropdown = forwardRef((props: IReasonCodeProps, ref) => {
  const domainContext = useContext(JEMContext);
  const ReasonCodeDropdownOptions = convertToReasonCodeDropdownOptions(
    domainContext.initInfo.values?.JeReasonCodes as DomainDataObjects["JeReasonCodes"]
  );
  const [ReasonCode, setReasonCode] = useState<IDropdownOption<string>>();

  useEffect(() => {
    const irc = ReasonCodeDropdownOptions.find((rc) => rc.text === props.ReasonCode);
    if (!irc) return;

    setReasonCode(irc);
  }, [props.ReasonCode]);

  const onChangeReasonCode = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void => {
    if (!option) {
      return;
    }

    setReasonCode(option);
    if (props.onChange) {
      props.onChange(option);
    }
  };

  useImperativeHandle(
    ref,
    (): IReasonCodeRef => ({
      getReasonCode() {
        if (!ReasonCode) {
          return null;
        }
        return ReasonCode;
      },
      setInitialReasonCode(initialReasonCode: string) {
        // get from options values
        const rc = ReasonCodeDropdownOptions.find((rc) => rc.text === initialReasonCode);
        if (!ReasonCode) return;

        setReasonCode(rc);
      },
      reset() {
        setReasonCode(ReasonCodeDropdownOptions[0]);
      }
    })
  );

  return (
    <React.Fragment>
      <Dropdown
        selectedKey={ReasonCode?.key}
        onChange={onChangeReasonCode}
        options={ReasonCodeDropdownOptions}
        disabled={props.loading}
        styles={{
          root: {
            width: "100%",
            marginTop: "4px"
          }
        }}
        required
        errorMessage={ReasonCode?.key === undefined ? "ReasonCode is required" : undefined}
      />
    </React.Fragment>
  );
});

ReasonCodeDropdown.displayName = "ReasonCodeDropdown";

export { ReasonCodeDropdown, IReasonCodeProps };
