import { Checkbox, TextField } from "@fluentui/react";
import { LoadingStatus } from "@jem/components";
import React, { useContext, useImperativeHandle, useState } from "react";
import { IHCCDraftContext } from "../../contexts/IHCCDraftContext/IHCCDraftContext";

function commentsError(newUserComment: string) {
  const newUserCommentFieldLength = 50;
  const remaining = newUserCommentFieldLength - newUserComment.length;
  if (remaining < 0) {
    return `Comment exceeds max length. ${newUserCommentFieldLength} max characters`;
  }
  return `${remaining} characters remaining`;
}

export type CommentErrorMessage = string | null;
export interface IHCCCreateCommentsRef {
  getNewComment: () => [string, CommentErrorMessage];
  getIsBulk: () => boolean;
  reset: () => void;
}

export interface IHCCCreateCommentsProps {
  customRef: React.Ref<IHCCCreateCommentsRef>;
  disabled: boolean;
  loadingStatus: LoadingStatus;
}

const IHCCCreateComments: React.FC<IHCCCreateCommentsProps> = (props) => {
  const draftContext = useContext(IHCCDraftContext);
  const [value, setValue] = useState<string>(draftContext.draftModel.values.comments);
  const [isBPO, setIsBPO] = useState(draftContext.draftModel.values.isBulk);

  const onChange = React.useCallback(
    (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
      setIsBPO(!!checked);
    },
    []
  );

  useImperativeHandle(props.customRef, () => ({
    getNewComment() {
      return [value, value === "" ? "Comments cannot be empty" : null];
    },
    getIsBulk() {
      return isBPO;
    },
    reset() {
      setValue("");
      setIsBPO(false);
    }
  }));

  return (
    <div className="ms-Grid" dir="ltr">
      <div className="ms-Grid-row">
        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
          <TextField
            name="newUserComment"
            label="Comments"
            value={value}
            onChange={(_, newValue) => setValue(newValue || "")}
            description={commentsError(value)}
            multiline
            autoAdjustHeight
            rows={5}
            maxLength={50}
            styles={{
              root: {
                marginTop: "10px",
                marginBottom: "10px"
              }
            }}
            disabled={props.loadingStatus === LoadingStatus.Pending}
            readOnly={props.disabled}
            required
          />
        </div>
        <div className={`ms-Grid-col ms-sm12 ms-md12 ms-lg12`}>
          <Checkbox label="Bulk PO" checked={isBPO} onChange={onChange} disabled={props.disabled} />
        </div>
      </div>
    </div>
  );
};

IHCCCreateComments.displayName = "IHCCCreateComments";

export default IHCCCreateComments;
