import React from "react";
import { Pivot, PivotItem } from "@fluentui/react";

export type PivotOption = "manualJe" | "automatedJe";

export interface ManualAutomatedJePivotOpts {
  props: {
    onChange: (opts: string) => void;
    selectedPivot: PivotOption;
  };
}

const ManualAutomatedJePivot: React.FC<ManualAutomatedJePivotOpts["props"]> = (props) => {
  return (
    <React.Fragment>
      <Pivot
        onLinkClick={(item?: PivotItem | undefined) => {
          if (item && item.props.itemKey) {
            props.onChange(item.props.itemKey);
          }
        }}
        selectedKey={props.selectedPivot}
      >
        <PivotItem itemKey={"manualJe"} headerText="Manual JE" ariaLabel="Manual JE">
          <div style={{ display: "none" }}></div>
        </PivotItem>
        <PivotItem itemKey={"automatedJe"} headerText="Automated JE" ariaLabel="Automated JE">
          <div style={{ display: "none" }}></div>
        </PivotItem>
      </Pivot>
    </React.Fragment>
  );
};

export { ManualAutomatedJePivot };
