import React from "react";
import { useLocation } from "react-router-dom";

import { EUARoles, JemUserRoles } from "../../contexts/UserContext/UserContext.roles.main";

export const PaymentOrdersLinks = (
  location: ReturnType<typeof useLocation>,
  onClickHandler: (route: string) => (event: React.MouseEvent | any) => void,
  jemUserRoles: JemUserRoles
) => {
  const paymentOrderLinks = [
    {
      name: "Action Dashboard",
      key: "paymentOrdersDashboard",
      ariaLabel: "Payment Orders Dashboard",
      // "data-id": "automation_id_22241",
      target: "_blank",
      onClick: onClickHandler("/ihcc/dashboard"),
      isSelected: location.pathname === "/ihcc/dashboard"
    },
    {
      name: "Create",
      key: "paymentOrdersCreate",
      ariaLabel: "Payment Orders Create",
      // "data-id": "automation_id_22242",
      target: "_blank",
      onClick: onClickHandler("/ihcc/create"),
      isSelected: location.pathname === "/ihcc/create"
    },
    {
      name: "Search",
      key: "paymentOrdersSearch",
      ariaLabel: "Payment Orders Search",
      // "data-id": "automation_id_22243",
      target: "_blank",
      onClick: onClickHandler("/ihcc/search"),
      isSelected: location.pathname === "/ihcc/search"
    }
  ];
  if (jemUserRoles.roles.includes(EUARoles.TreasuryAdministrator)) {
    paymentOrderLinks.push({
      name: "Release",
      key: "paymentOrdersRelease",
      ariaLabel: "Payment Orders Release",
      // // "data-id": "automation_id_22250",
      target: "_blank",
      onClick: onClickHandler("/ihcc/treasuryDashboard"),
      isSelected: location.pathname === "/ihcc/treasuryDashboard"
    });
  }
  const navOptions = {
    name: "Payment Orders",
    key: "paymentOrders",
    ariaLabel: "Payment Orders",
    icon: "Money",
    // "data-id": "automation_id_22240",
    target: "_blank",
    links: paymentOrderLinks
  };
  return navOptions;
};
