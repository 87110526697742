import {
  LoadingStatus,
  MockDataFn,
  JemConfiguration,
  LoggingContext,
  FatalError,
  UserContext,
  ihccLoader
} from "@jem/components";
import React, { useContext, useEffect, useState } from "react";
import { useIHCCCreateActions } from "./IHCCDraftContext.actions";
import { EMPTY_MODEL, fetchIhccDraft } from "./IHCCDraftContext.fetchIhccDraft";
import { EMPTY_INIT_INFO, getInitInfo, IHCCDraftInitInfoResponse } from "./IHCCDraftContext.fetchInitInfo";
import { IHCCCreatePageModel, IHCCDraftContextData } from "./IHCCDraftContext.types";

const baseContext: IHCCDraftContextData = {
  draftModel: {
    values: EMPTY_MODEL,
    status: LoadingStatus.Idle
  },
  initInfo: {
    values: EMPTY_INIT_INFO,
    status: LoadingStatus.Idle
  },
  actions: null,
  reloadDraft: () => Promise.resolve()
};

const IHCCDraftContext = React.createContext<IHCCDraftContextData>(baseContext);

export interface IHCCDraftContextProps {
  mockDraftDataFn?: MockDataFn<IHCCDraftContextData>;
  configuration: JemConfiguration;
  children: React.ReactNode;
}

async function loadMocks(
  setInitInfoStatus: React.Dispatch<React.SetStateAction<LoadingStatus>>,
  setDraftModelStatus: React.Dispatch<React.SetStateAction<LoadingStatus>>,
  setInitInfo: React.Dispatch<React.SetStateAction<IHCCDraftInitInfoResponse>>,
  setDraftModel: React.Dispatch<React.SetStateAction<IHCCCreatePageModel>>,
  mockFn: MockDataFn<IHCCDraftContextData>
) {
  setInitInfoStatus(LoadingStatus.Pending);
  setDraftModelStatus(LoadingStatus.Pending);
  const mockData = await mockFn({});
  setInitInfo(mockData.initInfo.values);
  setDraftModel(mockData.draftModel.values);
  setInitInfoStatus(LoadingStatus.Resolved);
  setDraftModelStatus(LoadingStatus.Resolved);
}

// load PO draft
// if empty return empty state
// else transform response into state
const IHCCDraftProvider = (props: IHCCDraftContextProps) => {
  const { appInsights } = useContext(LoggingContext);
  if (!appInsights) {
    throw new FatalError("Please use a LoggingContext Provider.");
  }
  const userContext = useContext(UserContext);
  if (!userContext) {
    throw new FatalError("Please use a UserContext Provider.");
  }

  const [draftModelStatus, setDraftModelStatus] = useState<LoadingStatus>(LoadingStatus.Idle);
  const [draftModel, setDraftModel] = useState<IHCCCreatePageModel>(EMPTY_MODEL);

  const [initInfoStatus, setInitInfoStatus] = useState<LoadingStatus>(LoadingStatus.Idle);
  const [initInfo, setInitInfo] = useState<IHCCDraftInitInfoResponse>(EMPTY_INIT_INFO);

  const actions = useIHCCCreateActions(props.configuration.IhccApi);

  useEffect(() => {
    if (props.mockDraftDataFn) {
      const mockFn = props.mockDraftDataFn as MockDataFn<IHCCDraftContextData>;
      loadMocks(setInitInfoStatus, setDraftModelStatus, setInitInfo, setDraftModel, mockFn);
    } else {
      ihccLoader<IHCCCreatePageModel>(
        userContext.accessToken,
        appInsights,
        props.configuration,
        fetchIhccDraft,
        setDraftModelStatus,
        setDraftModel
      );
      ihccLoader<IHCCDraftInitInfoResponse>(
        userContext.accessToken,
        appInsights,
        props.configuration,
        getInitInfo,
        setInitInfoStatus,
        setInitInfo
      );
    }
  }, []);

  const reloadDraft = async () => {
    if (props.mockDraftDataFn) {
      const mockFn = props.mockDraftDataFn as MockDataFn<IHCCDraftContextData>;
      loadMocks(setInitInfoStatus, setDraftModelStatus, setInitInfo, setDraftModel, mockFn);
    } else {
      ihccLoader<IHCCCreatePageModel>(
        userContext.accessToken,
        appInsights,
        props.configuration,
        fetchIhccDraft,
        setDraftModelStatus,
        setDraftModel
      );
    }
  };

  return (
    <IHCCDraftContext.Provider
      value={{
        draftModel: {
          status: draftModelStatus,
          values: draftModel
        },
        initInfo: {
          status: initInfoStatus,
          values: initInfo
        },
        actions: actions,
        reloadDraft
      }}
    >
      {props.children}
    </IHCCDraftContext.Provider>
  );
};

export { IHCCDraftContext, IHCCDraftProvider };
