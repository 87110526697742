import { css } from "@emotion/css";
import { MessageBar, MessageBarType } from "@fluentui/react";
import {
  OpsDashboardApi,
  UserContext,
  LoggingContext,
  LoadingStatus,
  ApiError,
  PageHeading,
  redirectMeToTheOrigin,
  LoadingSpinner
} from "@jem/components";

import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import OpsDashboardCreateActions from "../../components/OperationsCreate/OpsDashboardCreate.Actions";
import {
  processCreateAction,
  processAction
} from "../../components/OperationsCreate/OpsDashboardCreate.ActionsRequests";
import CreateRequestForm, { ICreateRequestForm } from "../../components/OperationsCreate/OpsDashboardCreate.Form";
import { ActionTypes } from "../../components/OperationsCreate/OpsDashboardCreate.types";
import { useOpsDashboardApi } from "../../components/OperationsCreate/OpsDashboardCreate.useOpsDashboardApi";
import { OpsDashboardContext } from "../../contexts/OpsDashboardContext/OpsDashboardContext";

export interface CreateRequestProps {
  configuration: OpsDashboardApi;
}

let OpsDashboardCreate: React.FC<CreateRequestProps> = (props) => {
  const [state, resetEntry, setLoading] = useOpsDashboardApi(props.configuration);
  const opsDashboardContext = useContext(OpsDashboardContext);
  const context = useContext(UserContext);
  const loggingContext = useContext(LoggingContext);
  const navigate = useNavigate();
  const location = useLocation();
  const requestId = useRef("0");
  const formRef = useRef<ICreateRequestForm["ref"]>(null);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    if (state.loadingStatus === LoadingStatus.Rejected) {
      if (state.notification) {
        loggingContext.addNotification(state.notification);
      }
    }
    //
  }, [state.loadingStatus]);

  useEffect(() => {
    document.body.scrollTop = 0;
  }, []);

  useEffect(() => {
    if (state.requestId !== null) {
      requestId.current = state.requestId;
      if (formRef.current && state.request && state.action) {
        formRef.current.setForm(state.request, state.action, {
          isEditable: state.isEditable
        });
      }
    } else {
      requestId.current = "0";
      if (formRef.current && state.action) {
        formRef.current.setAction(state.action, {
          isEditable: state.isEditable
        });
      } else if (formRef.current) {
        formRef.current.reset();
      }
    }
  }, [state.request, state.action, state.request?.status]);

  const onAction = async (type: ActionTypes, comment?: string) => {
    if (formRef.current) {
      if (opsDashboardContext.hasErrors()) {
        setError(true);
        return;
      } else {
        setError(false);
      }
      const form = formRef.current.getForm();
      if (comment) {
        form.apiPayload.comments = comment;
      }
      try {
        if (type === ActionTypes.Create) {
          const result = await processCreateAction(type, form, props.configuration, context.accessToken);
          loggingContext.addNotification(result.notification);
          navigate(`/ops/dashboard/requests/${result.rowKey}`, {
            state: {
              origin: `${location.pathname}${location.search}`,
              state: null
            }
          });
        } else {
          setLoading(LoadingStatus.Pending);
          const rowKey = form.apiPayload.rowKey;
          if (!rowKey) {
            throw new Error(`No rowkey found to perform ${type}.`);
          }
          if (!state.request) {
            throw new Error(`No Action Data found.`);
          }
          if (state.request.status.indexOf("Reject") !== -1 && type === ActionTypes.SendToApprovers) {
            await processAction(ActionTypes.Save, form, props.configuration, context.accessToken);
          }
          const result = await processAction(type, form, props.configuration, context.accessToken);
          loggingContext.addNotification(result.notification);
          resetEntry(rowKey);
        }
      } catch (e) {
        if (e instanceof ApiError) {
          const message = (e as ApiError).message;
          loggingContext.addNotification({
            actionName: ActionTypes.RejectWithComment,
            exception: e,
            summaryBodyText: message,
            subjectHeader: `Error - ${form.apiPayload.rowKey || "No Row Id"}`,
            type: "Error"
          });
        } else {
          loggingContext.addNotification({
            actionName: ActionTypes.RejectWithComment,
            exception: e,
            summaryBodyText: "Could not complete Action for Request.",
            subjectHeader: `Error - ${form.apiPayload.rowKey || "No Row Id"}`,
            type: "Error"
          });
        }
        if (form.apiPayload.rowKey) {
          resetEntry(form.apiPayload.rowKey);
        } else {
          setLoading(LoadingStatus.Rejected);
        }
      }
    }
  };

  return (
    <>
      {state.loadingStatus === LoadingStatus.Rejected || state.loadingStatus === LoadingStatus.Resolved ? (
        <>
          <div>
            <PageHeading
              onClose={() => redirectMeToTheOrigin(navigate, location, 0, "/ops/dashboard", true)}
              closeLabel={"Close"}
              overrideRootStyles={css`
                margin: 0;
              `}
            >
              <OpsDashboardCreateActions apiState={state} onAction={onAction} />
            </PageHeading>
          </div>
          <div>
            {error ? (
              <MessageBar
                delayedRender={false}
                messageBarType={MessageBarType.error}
                dismissButtonAriaLabel="Close"
                onDismiss={() => {
                  setError(false);
                }}
              >
                Please clear errors in the page before proceeding.
              </MessageBar>
            ) : null}
          </div>
          <div>
            <CreateRequestForm ref={formRef} configuration={props.configuration} />
          </div>
        </>
      ) : (
        <LoadingSpinner label="Loading details" />
      )}
    </>
  );
};

OpsDashboardCreate = React.memo(OpsDashboardCreate);
OpsDashboardCreate.displayName = "OpsDashboardCreate";
export default OpsDashboardCreate;
