import * as Yup from "yup";

import { GLCreateFormikState } from "../components/GLCreate/GLCreateForm.types";

import { DomainDataEnum, DomainDataObjects } from "../../Shared/contexts/JEMContext/JEMContext.domainData.types";
import { ValidationManager } from "./ValidationManager";
import { JemConfiguration } from "../../JemConfiguration";
import { IUserProviderState } from "../../Shared/utilities/RequestUtilities";
import { GLPageState } from "../components/GLCreate/GLCreate.State";
import { MessageBarType } from "@fluentui/react";
import { GLBatchPageState } from "../components/GLBatch/GLBatch.State";
import { GLBatchActionsEnum } from "../components/GLBatch/GLBatch.Actions";
import { GLBatchFormikState } from "../components/GLBatch/GLBatch.types";
import { BatchValidationManager } from "./BatchValidationManager";
import { GLJeDetailsFormikState } from "../components/GLDetails/GLDetailsForm.types";
import { GLCreateActionsEnum } from "../components/GLCreate/useWeightage";

const usersAliasesValidation = (opts: {
  configuration: JemConfiguration["GeneralLedgerApi"];
  accessToken: IUserProviderState["accessToken"];
}) => {
  return ValidationManager.PosterAndReviewerPermissionValidation(opts).required();
};

export const glDetailsOfflineValidation = (initialState: { author: string }) => {
  return Yup.object({
    //attachments_count: Yup.number().min(1, "You must attach at least one document."),
    // attachments_region: Yup.string().required("Attachments region is required."),
    attachments_error: Yup.string(),
    detailsTabJePurpose: ValidationManager.jePurposeValidation(),
    reviewersTabComments: ValidationManager.CommentsValidation(false)
  }).concat(
    ValidationManager.PosterAndReviewerOfflineValidation(initialState, {
      posterRequired: false,
      reviewerRequired: true,
      reviewerIsAuthorCheck: true,
      additionalReviewersIsAuthorCheck: true,
      posterCommentsRequired: false,
      backupPostersRequired: false,
      backupReviewersRequired: false,
      preReviewerRequired: false,
      preReviewerIsAuthorCheck: false
    }).required()
  );
};

export const glCreateOfflineValidation = (
  userAction: GLCreateActionsEnum,
  domainData: Pick<
    DomainDataObjects,
    | DomainDataEnum.JeCompanyCodes
    | DomainDataEnum.JeTypes
    | DomainDataEnum.JeReasonCodes
    | DomainDataEnum.CurrencyCodes
    | DomainDataEnum.FiscalPeriods
  >,
  initialState: GLPageState["createState"],
  loggedInUser: string
) => {
  return Yup.object({
    attachments_count:
      userAction === GLCreateActionsEnum.PreReview
        ? Yup.number().min(1, "You must attach at least one document.")
        : Yup.number(),
    // attachments_region: Yup.string().required("Attachments region is required."),
    attachments_error: Yup.string(),
    errorTabErrorMessage: Yup.string(),
    errorTabErrorTable: Yup.array()
  })
    .concat(ValidationManager.JEDetailsValidation(domainData, initialState))
    .concat(
      ValidationManager.PosterAndReviewerOfflineValidation(
        initialState,
        {
          posterRequired: userAction === GLCreateActionsEnum.SendToPoster,
          reviewerRequired: true,
          reviewerIsAuthorCheck: true,
          additionalReviewersIsAuthorCheck: true,
          posterCommentsRequired: userAction === GLCreateActionsEnum.SendToPoster, // depends on userAction
          backupPostersRequired: userAction === GLCreateActionsEnum.PreReview,
          backupReviewersRequired: false,
          preReviewerRequired: userAction === GLCreateActionsEnum.PreReview,
          preReviewerIsAuthorCheck: userAction === GLCreateActionsEnum.PreReview,
          posterIsAuthorCheck: userAction === GLCreateActionsEnum.SendToPoster
        },
        userAction,
        loggedInUser
      ).required()
    );
};

/**
 * Validates the GL Create Details, Posters, Reviewers
 * Does not Validate the Attachments or Line Items
 * @param configuration The configuration for the API
 * @param accessToken Function that Retrieves Bearer Token
 * @param domainData Fiscal Years, Currencies, JE Parameters
 * @param formikState User Changes State (Overrides Initial State)
 * @param userAction GLCreateActionsEnum
 * @returns Error Message
 */
export async function CheckForErrorsInTabs(opts: {
  configuration: JemConfiguration["GeneralLedgerApi"];
  userContext: Pick<IUserProviderState, "accessToken" | "user">;
  domainData: Pick<
    DomainDataObjects,
    | DomainDataEnum.JeCompanyCodes
    | DomainDataEnum.JeTypes
    | DomainDataEnum.JeReasonCodes
    | DomainDataEnum.CurrencyCodes
    | DomainDataEnum.FiscalPeriods
  >;
  initialState: GLPageState["createState"];
  pageConfiguration: GLPageState["pageConfiguration"];
  formikState: GLCreateFormikState;
  userAction: GLCreateActionsEnum;
}): Promise<string[]> {
  try {
    await glCreateOfflineValidation(
      opts.userAction,
      opts.domainData,
      opts.initialState,
      opts.userContext.user.alias
    ).validate(opts.formikState, {
      abortEarly: false
    });

    await usersAliasesValidation({
      configuration: opts.configuration,
      accessToken: opts.userContext.accessToken
    }).validate(opts.formikState, {
      abortEarly: false
    });

    return [];
  } catch (error) {
    if (error instanceof Yup.ValidationError) {
      const errorMessages = new Set(error.inner.map((err) => err.message));
      return Array.from(errorMessages);
    }
    throw error;
  }
}

/**
 * Validates the GL Create Details, Posters, Reviewers
 * Does not Validate the Attachments or Line Items
 * @param configuration The configuration for the API
 * @param accessToken Function that Retrieves Bearer Token
 * @param formikState User Changes State (Overrides Initial State)
 * @returns Error Message
 */
export async function CheckForErrorsInJeDetails(opts: {
  configuration: JemConfiguration["GeneralLedgerApi"];
  accessToken: IUserProviderState["accessToken"];
  formikState: GLJeDetailsFormikState;
  author: string;
}): Promise<{
  message: string;
  type: MessageBarType;
} | null> {
  try {
    const validator = glDetailsOfflineValidation({
      author: opts.author
    });
    validator.concat(
      usersAliasesValidation({
        configuration: opts.configuration,
        accessToken: opts.accessToken
      })
    );
    await validator.validate(opts.formikState, {
      abortEarly: false
    });

    return null;
  } catch (error) {
    if (error instanceof Yup.ValidationError) {
      const errorMessage = error.inner.map((err) => err.message).join(", ");
      return {
        message: errorMessage,
        type: MessageBarType.error
      };
    }
    throw error;
  }
}

export const batchDetailsOfflineValidation = (
  userAction: GLBatchActionsEnum,
  initialState: GLBatchPageState["batchState"],
  domainData: Pick<DomainDataObjects, DomainDataEnum.JeReasonCodes>
) => {
  return Yup.object({
    attachments_count:
      userAction === GLBatchActionsEnum.PreReview
        ? Yup.number().min(1, "You must attach at least one document.")
        : Yup.number(),
    attachments_region: Yup.string().required("Attachments region is required."),
    attachments_error: Yup.string(),
    errorTabErrorMessage: Yup.string(),
    errorTabErrorTable: Yup.array()
  })
    .concat(BatchValidationManager.BatchDetailsValidations(domainData, initialState))
    .concat(
      ValidationManager.PosterAndReviewerOfflineValidation(initialState, {
        posterRequired: false,
        reviewerRequired: true,
        reviewerIsAuthorCheck: true,
        additionalReviewersIsAuthorCheck: true,
        posterCommentsRequired: false,
        backupPostersRequired: initialState.isPreReview ? true : false,
        backupReviewersRequired: false,
        preReviewerRequired: initialState.isPreReview ? true : false,
        preReviewerIsAuthorCheck: initialState.isPreReview ? true : false
      }).required()
    );
};

/**
 * Validates the JE Details, Posters, Reviewers
 * Does not Validate the Attachments or Line Items
 * @param configuration The configuration for the API
 * @param accessToken Function that Retrieves Bearer Token
 * @param formikState User Changes State (Overrides Initial State)
 * @param userAction GLBatchActionsEnum
 * @returns Error Message
 */
export async function CheckForErrorsInBatchTabs(opts: {
  configuration: JemConfiguration["GeneralLedgerApi"];
  accessToken: IUserProviderState["accessToken"];
  initialState: GLBatchPageState["batchState"];
  domainData: Pick<DomainDataObjects, DomainDataEnum.JeReasonCodes>;
  formikState: GLBatchFormikState;
  userAction: GLBatchActionsEnum;
}): Promise<string[]> {
  try {
    await batchDetailsOfflineValidation(opts.userAction, opts.initialState, opts.domainData).validate(
      opts.formikState,
      {
        abortEarly: false
      }
    );

    await usersAliasesValidation({
      configuration: opts.configuration,
      accessToken: opts.accessToken
    }).validate(opts.formikState, {
      abortEarly: false
    });

    return [];
  } catch (error) {
    if (error instanceof Yup.ValidationError) {
      const errorMessages = new Set(error.inner.map((err) => err.message));
      return Array.from(errorMessages);
    }
    throw error;
  }
}
