import React, { useEffect, useRef, useState } from "react";
import {
  PrimaryButton,
  DefaultButton,
  Checkbox,
  TextField,
  ITextField,
  BaseButton,
  Button,
  Text
} from "@fluentui/react";
import { css } from "@emotion/css";

export const submissionStyles = css`
  position: relative;
  & .action-manager--footer-action {
    max-width: 360px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  & .action-manager--footer-buttons {
    display: flex;
    margin: 16px 0 0 0;
    justify-content: flex-end;

    & button:first-of-type {
      margin: 0 16px 0 0;
    }
  }
`;

export interface ITextFieldCheckBoxProps {
  onSubmit: (type: "submit" | "cancel", text: string) => void;
  description?: string;
  textFieldLabel: string;
  checkboxText: string;
  submitButtonText: string;
}

export interface ITextFieldProps {
  onSubmit: (type: "submit" | "cancel", text: string) => void;
  description?: string;
  textFieldLabel: string;
  submitButtonText: string;
  initialValue?: string;
  autoComplete?: string;
  validationFunction?: (text: string) => string | null;
}

export interface ICheckboxProps {
  onSubmit: (type: "submit" | "cancel") => void;
  checkboxText: string;
  submitButtonText: string;
}

export interface ISubmissionProps {
  onSubmit: (type: "submit" | "cancel") => void;
  SubmissionText: string;
  submitButtonText: string;
}

export const Submission = (props: ISubmissionProps): JSX.Element => {
  const onSubmit = (
    event: React.MouseEvent<
      HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | BaseButton | Button | HTMLSpanElement,
      MouseEvent
    >
  ) => {
    event.stopPropagation();
    props.onSubmit("submit");
  };

  const onCancel = (
    event: React.MouseEvent<
      HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | BaseButton | Button | HTMLSpanElement,
      MouseEvent
    >
  ) => {
    event.stopPropagation();
    props.onSubmit("cancel");
  };

  return (
    <div className={submissionStyles}>
      <div className="action-manager--footer-action">
        <Text variant="small">{props.SubmissionText}</Text>
      </div>
      <div className="action-manager--footer-buttons">
        <PrimaryButton onClick={onSubmit} text={props.submitButtonText} disabled={false} />
        <DefaultButton onClick={onCancel} text="Cancel" />
      </div>
    </div>
  );
};

export const TextFieldSubmission = (props: ITextFieldProps): JSX.Element => {
  const textRef = useRef<ITextField>(null);
  const [disabled, setDisabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [value, setValue] = useState<string>(props.initialValue || "");

  const onSubmit = (
    event: React.MouseEvent<
      HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | BaseButton | Button | HTMLSpanElement,
      MouseEvent
    >
  ) => {
    event.stopPropagation();
    if (textRef.current) {
      if (!textRef.current.value) {
        setErrorMessage("Please add a value");
      } else {
        if (errorMessage) {
          setErrorMessage("");
        }
        props.onSubmit("submit", textRef.current.value);
      }
    }
  };

  const onCancel = (
    event: React.MouseEvent<
      HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | BaseButton | Button | HTMLSpanElement,
      MouseEvent
    >
  ) => {
    event.stopPropagation();
    setErrorMessage("");
    if (textRef.current) {
      props.onSubmit("cancel", "");
    }
  };

  return (
    <div className={submissionStyles}>
      <div className="action-manager--footer-action">
        <TextField
          name="dialogText"
          label={props.textFieldLabel}
          description={props.description}
          componentRef={textRef}
          value={value}
          errorMessage={errorMessage}
          autoComplete={props.autoComplete || "off"}
          onChange={(event, newValue) => {
            event.stopPropagation();
            if (textRef.current && newValue !== undefined) {
              let disabled = false;
              if (newValue !== "") {
                const error = props.validationFunction && props.validationFunction(newValue);
                if (error !== null && error !== undefined) {
                  disabled = true;
                  setErrorMessage(error);
                }
              } else {
                disabled = true;
                setErrorMessage("Please add a value");
              }
              if (!disabled) {
                setErrorMessage("");
              }
              setDisabled(disabled);
              setValue(newValue);
            }
          }}
        />
      </div>
      <div className="action-manager--footer-buttons">
        <PrimaryButton onClick={onSubmit} text={props.submitButtonText} disabled={disabled} />
        <DefaultButton onClick={onCancel} text="Cancel" />
      </div>
    </div>
  );
};

export const CheckBoxSubmission = (props: ICheckboxProps): JSX.Element => {
  const [disabled, setDisabled] = useState(true);
  const onChangeCheckBox = () => {
    setDisabled(false);
  };

  const onSubmit = (
    event: React.MouseEvent<
      HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | BaseButton | Button | HTMLSpanElement,
      MouseEvent
    >
  ) => {
    event.stopPropagation();
    props.onSubmit("submit");
  };

  const onCancel = (
    event: React.MouseEvent<
      HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | BaseButton | Button | HTMLSpanElement,
      MouseEvent
    >
  ) => {
    event.stopPropagation();
    props.onSubmit("cancel");
  };

  return (
    <div className={submissionStyles}>
      <div className="action-manager--footer-action">
        <Checkbox label={props.checkboxText} onChange={onChangeCheckBox} />
      </div>
      <div className="action-manager--footer-buttons">
        <PrimaryButton onClick={onSubmit} disabled={disabled} text={props.submitButtonText} />
        <DefaultButton onClick={onCancel} text="Cancel" />
      </div>
    </div>
  );
};

export const TextFieldCheckboxSubmission = (props: ITextFieldCheckBoxProps): JSX.Element => {
  const textRef = useRef<ITextField>(null);
  const [disabled, setDisabled] = useState(true);
  const [checked, setChecked] = useState(false);
  const [isEmpty, setIsEmpty] = useState(true);

  const onChangeCheckBox = (
    ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
    checked?: boolean | undefined
  ) => {
    setChecked(!!checked);
  };

  const onSubmit = (
    event: React.MouseEvent<
      HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | BaseButton | Button | HTMLSpanElement,
      MouseEvent
    >
  ) => {
    event.stopPropagation();
    if (textRef.current && textRef.current.value) {
      props.onSubmit("submit", textRef.current.value);
    }
  };

  useEffect(() => {
    setDisabled(!checked || isEmpty);
  }, [checked, isEmpty]);

  const onCancel = (
    event: React.MouseEvent<
      HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | BaseButton | Button | HTMLSpanElement,
      MouseEvent
    >
  ) => {
    event.stopPropagation();
    if (textRef.current) {
      props.onSubmit("cancel", "");
    }
  };

  return (
    <div className={submissionStyles}>
      <div className="action-manager--footer-action">
        <TextField
          name="dialogText"
          label={props.textFieldLabel}
          componentRef={textRef}
          onChange={(event, newValue) => {
            event.stopPropagation();
            setIsEmpty(newValue === "");
          }}
        />
        <Checkbox label={props.checkboxText} onChange={onChangeCheckBox} />
      </div>
      <div className="action-manager--footer-buttons">
        <PrimaryButton onClick={onSubmit} disabled={disabled} text={props.submitButtonText} />
        <DefaultButton onClick={onCancel} text="Cancel" />
      </div>
    </div>
  );
};
