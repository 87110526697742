import { BaseButton, Button, DefaultButton, PrimaryButton } from "@fluentui/react";
import React, { PropsWithChildren, useContext, useRef, useState } from "react";
import { ActionResult, ActionTypes } from "../ActionManager/ActionsManager.types";
import { Attachments } from "../Attachments/Attachments";
import {
  IAttachmentEntryFormSingleUploadProps,
  IAttachmentEntryFormProps,
  IAttachmentEntryFormRef,
  IAttachment
} from "../Attachments/Attachments.types";
import { ActionsContext } from "./Actions.ActionsContext";
import { ActionTitle } from "./Actions.ActionTitle";
import { submissionStyles } from "./Actions.BottomButtons";
import { ActionOnSubmitHandlerMap, BaseActionProps, CommonPropsOnRows } from "./Actions.types";

interface AttachmentsMainProps<ListType, ResultType> extends BaseActionProps<ListType, ResultType> {
  onSubmitHandler: ActionOnSubmitHandlerMap<ListType, ResultType>[ActionTypes.addAttachment];
  onUploadFile: IAttachmentEntryFormSingleUploadProps["onUploadFile"];
}

export type AttachmentsProps<ListType, ResultType> = AttachmentsMainProps<ListType, ResultType> &
  Omit<IAttachmentEntryFormProps, "customRef">;

const AttachmentsAction = <ListType extends CommonPropsOnRows, ResultType extends ActionResult>(
  props: PropsWithChildren<AttachmentsProps<ListType, ResultType>>
) => {
  const [disabled, setDisabled] = useState(true);

  const { styles } = useContext(ActionsContext);
  const attachmentsRef = useRef<IAttachmentEntryFormRef<IAttachment>>(null);

  const onSubmit = async (
    event: React.MouseEvent<
      HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | BaseButton | Button | HTMLSpanElement,
      MouseEvent
    >
  ) => {
    event.stopPropagation();
    if (attachmentsRef.current) {
      const [attachments, error] = await attachmentsRef.current.saveAndGetAttachments();
      if (!error) {
        const result = await props.onSubmitHandler(props.items, attachments.attachments);
        props.onSubmit(result);
      } else {
        setDisabled(true);
      }
    }
  };

  const onCancel = (
    event: React.MouseEvent<
      HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | BaseButton | Button | HTMLSpanElement,
      MouseEvent
    >
  ) => {
    event.stopPropagation();
    props.onCancel();
  };
  return (
    <>
      <ActionTitle onCloseButton={() => props.onCancel()} name={"Attachments"}></ActionTitle>
      <div className={styles.contentStyles.body}>
        <Attachments
          customRef={attachmentsRef}
          onUploadFile={props.onUploadFile}
          onDeleteFile={props.onDeleteFile}
          onDownloadFile={props.onDownloadFile}
          onChange={(lAttachments, rAttachments, errorMessage) => {
            if (lAttachments.length === 0) {
              setDisabled(true);
            } else if (errorMessage) {
              setDisabled(true);
            } else {
              setDisabled(false);
            }
          }}
        ></Attachments>
        <div className={submissionStyles}>
          <div className="action-manager--footer-buttons">
            <PrimaryButton onClick={onSubmit} disabled={disabled} text={"Upload"} />
            <DefaultButton onClick={onCancel} text="Cancel" />
          </div>
        </div>
      </div>
    </>
  );
};

AttachmentsAction.displayName = "Attachments";

export { AttachmentsAction };
