import { ApiParsingUtilities } from "@jem/components";

export interface JEMChecklistDashboardRowFromApi extends ObjectContainingLegacyAttachments {
  checklistName: string;
  checklistId: number;
  closeChecklistTaskId: number;
  opsDetailsId: number;
  opsDetailsName: string;
  checklistRefGuid: string;
  companyCodes: string;
  fiscalYear?: number;
  fiscalPeriod?: number;
  isActive: boolean;
  defaultReviewers: string;
  posting: string;
  significantReview: string;
  regularReview: string;
  lowValueReview: string;
  signOffStatus: string;
  signoffDate?: string;
  signoffUser?: string;
  PublishedDate?: string;
}

export interface ObjectContainingLegacyAttachments {
  attachments: LegacyAttachments | null;
}

export interface LegacyAttachments {
  corpStoreAttachments: CorpStoreAttachmentInfo[];
  regStoreAttachments: RegStoreAttachment[];
}

export interface CorpStoreAttachmentInfo {
  fileId: number;
  originalName: string;
  corpStoreId: number | null;
}

export interface RegStoreAttachment {
  blobName: string | null;
  fileId: number;
  fileSize: number | null;
  fileStatus: number | null;
  originalName: string | null;
  regStoreId: number | null;
  url: string | null;
  uploadedDate: string | null;
  isRescindEvidence: boolean | null;
  region: string | null;
}

export interface JEMChecklistDashboardRow {
  defaultReviewers: any;
  ChecklistName: string;
  ChecklistId: number;
  CloseChecklistTaskId: number;
  opsDetailsId: number;
  OpsDetail: string;
  ChecklistRefGuid: string;
  CC: string[];
  FY?: number;
  FP?: number;
  Active: string;
  ProcessingState?: string;
  Posting: string;
  SignificantReview: string;
  RegularReview: string;
  LowValueReview: string;
  signOffStatus?: string;
  signOffDate?: string;
  signOffUser?: string;
  PublishedDate?: string;
}

export function sanitizeDashboardRow(row: JEMChecklistDashboardRowFromApi): JEMChecklistDashboardRow {
  const newSanitizedRow: JEMChecklistDashboardRow = {
    ChecklistName: ApiParsingUtilities.addDefault(row.checklistName, ""),
    CC: ApiParsingUtilities.addDefault(row.companyCodes, "")
      .split(",")
      .map((cc) => cc.trim()),
    FY: row.fiscalYear ? ApiParsingUtilities.parseNumberToNumber(row.fiscalYear, 0) : undefined,
    FP: row.fiscalPeriod ? ApiParsingUtilities.parseNumberToNumber(row.fiscalPeriod, 0) : undefined,
    ChecklistRefGuid: ApiParsingUtilities.addDefault(row.checklistRefGuid, ""),
    ChecklistId: ApiParsingUtilities.parseNumberToNumber(row.checklistId, 0),
    opsDetailsId: ApiParsingUtilities.parseNumberToNumber(row.opsDetailsId, 0),
    OpsDetail: ApiParsingUtilities.addDefault(row.opsDetailsName, ""),
    Active: row.isActive ? "True" : "False",
    Posting: ApiParsingUtilities.addDefault(row.posting, ""),
    SignificantReview: ApiParsingUtilities.addDefault(row.significantReview, ""),
    RegularReview: ApiParsingUtilities.addDefault(row.regularReview, ""),
    LowValueReview: ApiParsingUtilities.addDefault(row.lowValueReview, ""),
    CloseChecklistTaskId: ApiParsingUtilities.parseNumberToNumber(row.closeChecklistTaskId),
    defaultReviewers: ApiParsingUtilities.addDefault(row.defaultReviewers, ""),
    PublishedDate: ApiParsingUtilities.parseDateWithTimeStamp(row.PublishedDate ?? null, ""),
    signOffDate: ApiParsingUtilities.parseDateWithTimeStamp(row.signoffDate ?? null, ""),
    signOffUser: ApiParsingUtilities.addDefault(row.signoffUser ?? null, ""),
    signOffStatus: ApiParsingUtilities.addDefault(row.signOffStatus, "")
  };
  return newSanitizedRow;
}
