import { ICommandBarItemProps } from "@fluentui/react";
import { JEMChecklistDetailsRow } from "@jem/components";
export interface IJemChecklistDetailsCommandBarItemProps {
  onEditClick: (editItem: JEMChecklistDetailsRow) => void;
  onChecklistCloneClicked: () => void;
  onPublishChecklistClicked: () => void;
  onImportTasksClicked: () => void;
  onNoActivityChecklistClicked: () => void;
  onRefreshChecklistDetailsClicked: () => void;
  onBulkDeleteChecklistClicked: () => void;
  onBulkEditClicked: () => void;
  onSignOffClicked: () => void;
  disableSignOff: () => boolean;
  onReopenClicked: () => void;
  disableReopen: () => boolean;
  disableAllButSignOff: () => boolean;
  checkListGUID: string;
  backupReviewers: string[];
  backupPosters: string[];
  companyCodes: number[];
}

const JemChecklistDetailsCommandBarItems = (props: IJemChecklistDetailsCommandBarItemProps): ICommandBarItemProps[] => {
  return [
    {
      key: "newJETask",
      text: "New JE Task",
      cacheKey: "myCacheKey", // changing this key will invalidate this item's cache
      iconProps: { iconName: "Add" },
      disabled: props.disableAllButSignOff(),
      onClick: () =>
        props.onEditClick(
          emptyJemChecklistDetailsRowObject(props.checkListGUID, props.backupReviewers, props.backupPosters)
        )
    },
    {
      key: "editBulk",
      text: "Bulk edit",
      cacheKey: "editBulk",
      iconProps: { iconName: "Edit" },
      disabled: props.disableAllButSignOff(),
      onClick: () => props.onBulkEditClicked()
    },
    {
      key: "refresh",
      text: "Refresh Data",
      iconProps: { iconName: "Refresh" },
      disabled: props.disableAllButSignOff(),
      onClick: () => props.onRefreshChecklistDetailsClicked()
    },
    {
      key: "cloneJEMChecklist",
      text: "Clone JEM Checklist",
      iconProps: { iconName: "Copy" },
      //disabled: props.disableAllButSignOff(),
      onClick: () => props.onChecklistCloneClicked()
    },
    {
      key: "publishJEMChecklist",
      text: "Publish JEM Checklist",
      iconProps: { iconName: "PublishContent" },
      disabled: props.disableAllButSignOff(),
      onClick: () => props.onPublishChecklistClicked()
    },
    {
      key: "importChecklistTasks",
      text: "Import Tasks",
      iconProps: { iconName: "ExcelLogo" },
      disabled: props.disableAllButSignOff(),
      onClick: () => props.onImportTasksClicked()
    },
    {
      key: "NoActivityJEMChecklist",
      text: "Mark No Activity",
      iconProps: { iconName: "DeactivateOrders" },
      disabled: props.disableAllButSignOff(),
      onClick: () => props.onNoActivityChecklistClicked()
    },
    {
      key: "bulkDeleteJEMChecklist",
      text: "Bulk Delete Tasks",
      iconProps: { iconName: "Delete" },
      disabled: props.disableAllButSignOff(),
      onClick: () => props.onBulkDeleteChecklistClicked()
    },
    {
      key: "signOffJEMChecklist",
      text: "Sign Off Checklist",
      iconProps: { iconName: "CheckMark" },
      onClick: () => props.onSignOffClicked(),
      disabled: props.disableSignOff()
    },
    {
      key: "reopenJEMChecklist",
      text: "Re-open Checklist",
      iconProps: { iconName: "SkypeCircleArrow" },
      onClick: () => props.onReopenClicked(),
      disabled: props.disableReopen()
    }
  ];
};

const emptyJemChecklistDetailsRowObject = (
  GUID: string,
  backupReviewers: string[],
  backupPosters: string[]
): JEMChecklistDetailsRow => {
  const c: JEMChecklistDetailsRow = {
    taskId: "",
    jeTaskName: "",
    jeType: "",
    backupPoster: backupPosters,
    checklist: "",
    postingDueDate: "",
    significance: "",
    publishedState: "",
    status: "",
    assignedPoster: "",
    docNo: "",
    assignedReviewer: backupReviewers,
    attachment: "",
    fcwChecklistName: "",
    author: "",
    reasonCode: "",
    datemodified: "",
    purpose: "",
    postingPeriod: "",
    postingdate: "",
    translationDate: "",
    documentType: "",
    companycode: [],
    referenceNumber: 0,
    currencycode: "",
    description: "",
    refGuid: "",
    checklistRefGuid: GUID,
    href: "",
    reasonCodeText: "",
    isCloneable: true,
    isAdHocDraft: false,
    postingNotRequired: "",
    taskType: "",
    opsDetailId: -1,
    postedBy: "",
    frequency: "",
    draftDateCode: "",
    estimatedTimeMins: 0,
    actualTimeTakenMins: 0,
    draftDateCodeObject: undefined,
    signoffStatus: "",
    preReviewers: "",
    noActivityComments: "",
    isF05Posting: false,
    isF05PostingString: "false"
  };
  return c;
};
export default JemChecklistDetailsCommandBarItems;
