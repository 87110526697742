export class GLNumberSearchState {
  DocNumber: string;
  CompanyCode: number;
  FiscalYear: number;
  SAPModule: string;
  RefGuid: string;
  constructor(opts: {
    DocNumber: string;
    CompanyCode: number;
    FiscalYear: number;
    SAPModule: string;
    RefGuid: string;
  }) {
    this.DocNumber = opts.DocNumber;
    this.CompanyCode = opts.CompanyCode;
    this.FiscalYear = opts.FiscalYear;
    this.SAPModule = opts.SAPModule;
    this.RefGuid = opts.RefGuid;

    if (
      [this.DocNumber, this.CompanyCode, this.FiscalYear, this.SAPModule, this.RefGuid].some((val) => val === undefined)
    ) {
      throw new Error("GLNumberSearchState does not accept undefined values");
    }
  }
  static compare(state1: GLNumberSearchState, state2: GLNumberSearchState): boolean {
    return (
      state1.DocNumber === state2.DocNumber &&
      state1.CompanyCode === state2.CompanyCode &&
      state1.FiscalYear === state2.FiscalYear &&
      state1.SAPModule === state2.SAPModule &&
      state1.RefGuid === state2.RefGuid
    );
  }
}

export const emptySearchState: GLNumberSearchState = {
  DocNumber: "",
  CompanyCode: 0,
  FiscalYear: 0,
  SAPModule: "",
  RefGuid: ""
};
