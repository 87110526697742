import { css } from "@emotion/css";
import {
  Checkbox,
  ComboBox,
  IComboBoxProps,
  IDatePickerStrings,
  Label,
  SpinButton,
  Stack,
  Text,
  TextField,
  defaultDatePickerStrings,
  useTheme
} from "@fluentui/react";
import { Field, useFormikContext } from "formik";
import React, { useEffect } from "react";
import { AsyncSubject } from "rxjs";
import { JemConfiguration } from "../../../../JemConfiguration";
import { LoadingStatus } from "../../../../Shared";
import { DatePickerField } from "../../../../Shared/components/DatePickerField/DatePickerField";
import { JEMChecklistDropdown } from "../../../../Shared/components/JEMChecklistDropdown/JEMChecklistDropdown";
import { SimpleShimmerForForms } from "../../../../Shared/components/SimpleShimmerForForms/SimpleShimmerForForms";
import { DomainDataEnum, DomainDataObjects } from "../../../../Shared/contexts/JEMContext/JEMContext.domainData.types";
import { checkForDisabledChecklist } from "../../../../Shared/contexts/JEMContext/utilities/DomainData.utils";
import { ForceMaxWidthAndHeightOnDropdownStyle } from "../../../../Shared/utilities/FluentUIHacks";
import { gridStyle, textFieldStyles } from "../../../shared/GL.styles";
import {
  FCWChecklistModel,
  getCurrentFyFp,
  getFiscalPeriodEndDate,
  getFiscalPeriodStartDate
} from "../../../utilities";
import { CreatePageType, GLPageState } from "../GLCreate.State";
import { FiscalPeriodModel } from "../GLCreate.types";
import { GLCreateFormikState } from "../GLCreateForm.types";
import { GLCreateCompanyCode } from "./GLCreate.CompanyCode";
import { useConst } from "@fluentui/react-hooks";
export interface JeDetailsRef {
  getJEDetails: () => JeDetailsStateForAPI;
  reset: () => void;
}

export interface JeDetailsStateForAPI {
  JERefNo: number;
  CompanyCode: number;
  JEType: string;
  Description: string;
  ReasonCode: string;
  Currency: string;
  JEPurpose: string;
  IsBPOEntry: boolean;
  IsReusable: boolean;
  FiscalYear: number;
  FiscalMonth: number;
  FiscalPeriod: string;
  FiscalPeriodDate: string;
  PostByDate: string;
  TranslationDate: string;
}

const numberStyles = {
  root: {
    width: "100px"
  }
};

function monthDiff(d1: Date, d2: Date) {
  let months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  return months <= 0 ? 0 : months;
}

const columnHeaderProps = {
  className: css`
    display: block;
    line-height: 2em;
    font-weight: 700;
  `,
  variant: "large"
};

export function getReversalDate(
  fiscalPeriod: FiscalPeriodModel,
  domainData: Pick<DomainDataObjects, DomainDataEnum.FiscalPeriods>,
  postinDate: Date
): Date {
  const fp = fiscalPeriod;
  const postDate = postinDate;

  if (fp.index) {
    if (domainData.FiscalPeriods[fp.index + 1] !== null && domainData.FiscalPeriods[fp.index + 1] !== undefined) {
      return new Date(domainData.FiscalPeriods[fp.index + 1].startDate);
    }
    if (postDate > fp.startDate) {
      const mDiff = monthDiff(new Date(fp.startDate), new Date(postDate));
      if (
        mDiff !== 0 &&
        domainData.FiscalPeriods[fp.index + mDiff + 1] !== null &&
        domainData.FiscalPeriods[fp.index + mDiff + 1] !== undefined
      ) {
        return new Date(domainData.FiscalPeriods[fp.index + mDiff + 1].startDate);
      }
    } else if (postDate < fp.startDate) {
      const mDiff = monthDiff(new Date(postDate), new Date(fp.startDate));
      if (
        mDiff !== 0 &&
        domainData.FiscalPeriods[fp.index - mDiff + 1] !== null &&
        domainData.FiscalPeriods[fp.index - mDiff + 1] !== undefined
      ) {
        return new Date(domainData.FiscalPeriods[fp.index - mDiff + 1].startDate);
      }
    }
  } else {
    // find the next fiscal period using the calendar month and calendar year + 1 month
    let nextCalendarMonth = (fiscalPeriod.calendarMonth += 1);
    let nextCalendarYear = fiscalPeriod.calendarYear;
    if (nextCalendarMonth === 13) {
      nextCalendarMonth = 1;
      nextCalendarYear += 1;
    }
    const fp = domainData.FiscalPeriods.find(
      (fp) => fp.calendarYear === nextCalendarYear && fp.calendarMonth === nextCalendarMonth
    );
    if (fp) {
      return new Date(fp.startDate);
    }
    return new Date(`${nextCalendarMonth}/01/${nextCalendarYear}`);
  }
  return new Date(fp.startDate);
}

export interface GLCreateJeDetailsProps {
  configuration: JemConfiguration["GeneralLedgerApi"];
  pageState: GLPageState;
  disabled: boolean;
  loadingStatus: LoadingStatus;
  domainData: Pick<DomainDataObjects, DomainDataEnum.FiscalPeriods>;
  onFiscalPeriodChange: (confirmation: AsyncSubject<boolean>, fiscalPeriod: string) => void;
  onChecklistChange: (confirmation: AsyncSubject<boolean>, checklist: FCWChecklistModel) => void;
  onPostingDateChange: (confirmation: AsyncSubject<boolean>, postingDate: Date) => void;
  hasMsSalesPermission: boolean;
}

const GLCreateJeDetails: React.FC<GLCreateJeDetailsProps> = (props) => {
  const formik = useFormikContext<GLCreateFormikState>();
  const theme = useTheme();
  const createState = props.pageState.createState;
  //removing je type ZS if it's F05 currency
  if (
    createState.jeTypes !== null &&
    props.pageState.pageConfiguration.pageType === CreatePageType.FourCurrency &&
    !props.hasMsSalesPermission
  ) {
    const index = createState.jeTypes.findIndex((obj) => obj.key === "ZS");
    if (index > -1) {
      createState.jeTypes.splice(index, 1);
    }
  }
  const onChange =
    (fieldName: string): IComboBoxProps["onChange"] =>
    (_event, item, _index, _value) => {
      if (item) {
        formik.setFieldValue(fieldName, item.key);
      } else if (createState.fysfps.length === 0) {
        // There are no fiscal periods, so set the fiscal period to whatever the user entered
        formik.setFieldValue(fieldName, _value);
      } else {
        formik.setFieldValue(fieldName, "");
      }
    };

  const onChangeFyFp = async (_event, item, _index, _value) => {
    const fieldName = "detailsTabFiscalYearPeriod";
    if (item) {
      const subject = new AsyncSubject<boolean>();
      props.onFiscalPeriodChange(subject, item.text);
      subject.subscribe((confirmed) => {
        if (confirmed) {
          formik.setFieldValue(fieldName, item.key);
        }
      });
    } else if (createState.fysfps.length === 0) {
      // There are no fiscal periods, so set the fiscal period to whatever the user entered
      formik.setFieldValue(fieldName, _value);
    } else {
      formik.setFieldValue(fieldName, "");
    }
  };

  useEffect(() => {
    if (formik.values.detailsTabJeType === "SV") {
      // MUST CHANGE TRANSLATE DATE TO REVERSAL DATE FOR SV
      // ONLY IF THE REVERSAL DATE IS NOT ALREADY SET
      if (isNaN(Date.parse(formik.values.detailsTabReversalDate.toString()))) {
        const reversalDate = getReversalDate(
          props.pageState.createState.jeDetails.fiscalPeriod,
          props.domainData,
          formik.values.detailsTabPostingDate
        );
        formik.setFieldValue("detailsTabReversalDate", reversalDate);
      }
    }
  }, [formik.values.detailsTabJeType]);

  const checklistShouldBeDisabled = props.pageState.pageConfiguration.isChecklist
    ? checkForDisabledChecklist(
        createState.jeDetails.isAdhocDraft,
        formik.values.detailsTabSelectedChecklist.checklistRefGuid
      )
    : createState.jeDetails.isBpo; // if it is a BPO item, then disable checklist

  const outOfBoundsErrorMessage: IDatePickerStrings = useConst(() => ({
    ...defaultDatePickerStrings,
    isOutOfBoundsErrorMessage: "Posting Date should be in the current Posting Period."
  }));

  return (
    <>
      <div className="ms-Grid" dir="ltr">
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-xl4">
            <div className="ms-Grid" dir="ltr">
              <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12">
                  <Text {...columnHeaderProps}>JEM</Text>
                </div>
              </div>
              <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12 ms-lg6">
                  <TextField
                    styles={textFieldStyles(theme)}
                    label="Author"
                    borderless
                    placeholder={createState.author}
                  />
                </div>
                <div className="ms-Grid-col ms-sm12 ms-lg6">
                  <TextField
                    styles={textFieldStyles(theme)}
                    label="Modified Date"
                    borderless
                    placeholder={createState.modifiedDate}
                  />
                </div>
              </div>
              <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12 ms-lg6">
                  <JEMChecklistDropdown
                    configuration={props.configuration}
                    fiscalYearFiscalPeriod={formik.values.detailsTabFiscalYearPeriod}
                    selectedChecklist={formik.values.detailsTabSelectedChecklist}
                    onChecklistChange={function (checklist: FCWChecklistModel): void {
                      const subject = new AsyncSubject<boolean>();
                      props.onChecklistChange(subject, checklist);
                      subject.subscribe((confirmed) => {
                        if (confirmed) {
                          formik.setFieldValue("detailsTabSelectedChecklist", checklist);
                        }
                      });
                    }}
                    disabled={
                      checklistShouldBeDisabled || props.disabled || props.loadingStatus === LoadingStatus.Pending
                    }
                    errorMessage={
                      checklistShouldBeDisabled || props.disabled || props.loadingStatus !== LoadingStatus.Resolved
                        ? ""
                        : typeof formik.errors.detailsTabSelectedChecklist === "string"
                        ? formik.errors.detailsTabSelectedChecklist
                        : ""
                    }
                    onNewChecklists={function (checklists: FCWChecklistModel[]): void {
                      formik.setFieldValue("detailsTabCurrentChecklists", checklists);
                    }}
                  />
                </div>
                <div className="ms-Grid-col ms-sm12 ms-lg6">
                  <Label>Classification</Label>
                  <SimpleShimmerForForms loadingStatus={props.loadingStatus} height={32}>
                    <Field
                      label="Long Term"
                      name={"detailsTabIsLongTerm"}
                      type="checkbox"
                      as={Checkbox}
                      styles={{
                        root: {
                          paddingBottom: "5px"
                        }
                      }}
                      disabled={props.disabled}
                      onChange={(_event, checked) => {
                        formik.setFieldValue("detailsTabIsLongTerm", checked);
                      }}
                    />
                  </SimpleShimmerForForms>
                </div>
              </div>
              <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12 ms-lg6">
                  <Label id="actualTimeTakenLabel" required htmlFor="actualTimeTaken">
                    Actual Time Taken (mins):
                  </Label>
                  <SimpleShimmerForForms loadingStatus={props.loadingStatus} height={32}>
                    <SpinButton
                      id="detailsTabActualTimeTaken"
                      ariaDescribedBy={`actualTimeTakenLabel`}
                      aria-labelledby={`actualTimeTakenLabel`}
                      inputProps={{ "aria-labelledby": "actualTimeTakenLabel" }}
                      min={0}
                      max={2147483647}
                      step={1}
                      value={
                        formik.values.detailsTabActualTimeTaken !== -1 && formik.values.detailsTabActualTimeTaken
                          ? formik.values.detailsTabActualTimeTaken.toString()
                          : "0"
                      }
                      onChange={(e, newValue) => {
                        if (!newValue) {
                          formik.setFieldValue("detailsTabActualTimeTaken", 0);
                          return;
                        }
                        formik.setFieldValue("detailsTabActualTimeTaken", parseInt(newValue));
                      }}
                      incrementButtonAriaLabel={"Increase Actual Time Taken by 1 minute"}
                      decrementButtonAriaLabel={"Decrease Actual Time Taken by 1 minute"}
                      styles={
                        !formik.values.detailsTabActualTimeTaken
                          ? { root: { borderColor: theme.semanticColors.errorText } }
                          : undefined
                      }
                      disabled={
                        formik.values.detailsTabSelectedChecklist.checklistName === "NOT APPLICABLE" || props.disabled
                      }
                    />
                    {!formik.values.detailsTabActualTimeTaken &&
                      formik.values.detailsTabSelectedChecklist.checklistName != "NOT APPLICABLE" && (
                        <Text style={{ color: theme.semanticColors.errorText }} variant="small" role="alert">
                          Actual Time Taken (mins) is required.
                        </Text>
                      )}
                  </SimpleShimmerForForms>
                </div>
                <div className="ms-Grid-col ms-sm12 ms-lg6">
                  <SimpleShimmerForForms loadingStatus={props.loadingStatus} height={32} label="Reason Code">
                    <Field
                      label="Reason Code"
                      name={"detailsTabReasonCode"}
                      onChange={onChange("detailsTabReasonCode")}
                      as={ComboBox}
                      allowFreeform={true}
                      autoComplete="on"
                      options={createState.reasonCodes}
                      styles={ForceMaxWidthAndHeightOnDropdownStyle({ width: "100%", height: "200px" })}
                      selectedKey={formik.values.detailsTabReasonCode ? formik.values.detailsTabReasonCode : ""}
                      disabled={props.disabled}
                      errorMessage={
                        props.disabled
                          ? ""
                          : createState.reasonCodes.length === 0
                          ? "Error: No Reason Codes found"
                          : formik.errors.detailsTabReasonCode
                      }
                    />
                  </SimpleShimmerForForms>
                </div>
                <div className="ms-Grid-col ms-sm12 ms-lg6">
                  <SimpleShimmerForForms loadingStatus={props.loadingStatus} height={64} label="Purpose">
                    <Field
                      as={TextField}
                      label="Purpose"
                      name="detailsTabJePurpose"
                      type="input"
                      onChange={(
                        _event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
                        newValue?: string
                      ) => {
                        if (!newValue || newValue.length <= 250) {
                          formik.setFieldValue("detailsTabJePurpose", newValue);
                        }
                      }}
                      description="Max 250 characters"
                      multiline
                      disabled={props.disabled}
                      errorMessage={props.disabled ? "" : formik.errors.detailsTabJePurpose}
                    />
                  </SimpleShimmerForForms>
                </div>
              </div>
            </div>
          </div>
          <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-xl4">
            <div className="ms-Grid" dir="ltr">
              <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12">
                  <Text {...columnHeaderProps}>SAP document</Text>
                </div>
              </div>
              <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12 ms-lg6">
                  <SimpleShimmerForForms loadingStatus={props.loadingStatus} height={32} label="Period">
                    <Field
                      label="Period"
                      name={"detailsTabFiscalYearPeriod"}
                      onChange={onChangeFyFp}
                      as={ComboBox}
                      options={createState.fysfps}
                      styles={ForceMaxWidthAndHeightOnDropdownStyle({ width: "100%", height: "200px" })}
                      allowFreeform={true}
                      autoComplete="on"
                      selectedKey={
                        formik.values.detailsTabFiscalYearPeriod ? formik.values.detailsTabFiscalYearPeriod : ""
                      }
                      disabled={checklistShouldBeDisabled || props.disabled || createState.isExternalDraft}
                      errorMessage={
                        checklistShouldBeDisabled || props.disabled
                          ? ""
                          : props.domainData.FiscalPeriods.length === 0
                          ? "Error: No Fiscal Years and Periods received from API"
                          : formik.errors.detailsTabFiscalYearPeriod
                      }
                    />
                  </SimpleShimmerForForms>
                </div>
                <div className="ms-Grid-col ms-sm12 ms-lg6">
                  <SimpleShimmerForForms loadingStatus={props.loadingStatus} height={32} label="Company Code">
                    <GLCreateCompanyCode
                      disabled={props.disabled || createState.isExternalDraft}
                      loadingStatus={props.loadingStatus}
                      isFourCurrency={props.pageState.pageConfiguration.pageType === CreatePageType.FourCurrency}
                    />
                  </SimpleShimmerForForms>
                </div>
              </div>
              <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12 ms-lg6">
                  <SimpleShimmerForForms loadingStatus={props.loadingStatus} height={32} label="Posting Date">
                    <Field
                      name="detailsTabPostingDate"
                      label="Posting Date"
                      component={DatePickerField}
                      maxDate={
                        formik.values.detailsTabSelectedChecklist.checklistId !== 0 &&
                        formik.values.detailsTabSelectedChecklist.checklistId !== -1
                          ? getFiscalPeriodEndDate(props.domainData, formik.values.detailsTabFiscalYearPeriod)
                          : undefined
                      }
                      minDate={
                        formik.values.detailsTabSelectedChecklist.checklistId !== 0 &&
                        formik.values.detailsTabSelectedChecklist.checklistId !== -1
                          ? getFiscalPeriodStartDate(props.domainData, formik.values.detailsTabFiscalYearPeriod)
                          : undefined
                      }
                      strings={outOfBoundsErrorMessage}
                      onSelectDate={(date: Date | null | undefined) => {
                        if (!date) return;
                        const fyfp = getCurrentFyFp(date);
                        if (!checklistShouldBeDisabled)
                          formik.setFieldValue("detailsTabFiscalYearPeriod", fyfp.fiscalPeriod);
                        const newReversalDate = getReversalDate(fyfp, props.domainData, date);
                        formik.setFieldValue("detailsTabReversalDate", newReversalDate);
                      }}
                      disabled={props.disabled}
                      errorMessage={
                        checklistShouldBeDisabled || props.disabled ? "" : formik.errors.detailsTabPostingDate
                      }
                    />
                  </SimpleShimmerForForms>
                </div>
                <div className="ms-Grid-col ms-sm12 ms-lg6">
                  <SimpleShimmerForForms loadingStatus={props.loadingStatus} height={32} label="Reference Number">
                    <Field
                      as={TextField}
                      label="Reference Number"
                      name="detailsTabRefNumber"
                      type="input"
                      onChange={(
                        _event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
                        newValue?: string
                      ) => {
                        if (!newValue || newValue.length <= 6) {
                          formik.setFieldValue("detailsTabRefNumber", newValue);
                        }
                      }}
                      disabled={props.disabled}
                      errorMessage={props.disabled ? "" : formik.errors.detailsTabRefNumber}
                      required
                    />
                  </SimpleShimmerForForms>
                </div>
              </div>
              <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12">
                  <SimpleShimmerForForms loadingStatus={props.loadingStatus} height={32} label="Currency">
                    <Field
                      label="Currency"
                      name={"detailsTabCurrency"}
                      onChange={onChange("detailsTabCurrency")}
                      as={ComboBox}
                      allowFreeform={true}
                      autoComplete="on"
                      options={createState.currencies}
                      styles={ForceMaxWidthAndHeightOnDropdownStyle({ width: "100%", height: "200px" })}
                      selectedKey={formik.values.detailsTabCurrency ? formik.values.detailsTabCurrency : ""}
                      disabled={props.disabled || createState.isExternalDraft}
                      errorMessage={
                        props.disabled
                          ? ""
                          : createState.currencies.length === 0
                          ? "Error: No Currency Codes found"
                          : formik.errors.detailsTabCurrency
                      }
                    />
                  </SimpleShimmerForForms>
                </div>
              </div>
              <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12 ms-lg6">
                  <Stack>
                    <Stack.Item grow={1}>
                      <SimpleShimmerForForms loadingStatus={props.loadingStatus} height={32} label="Document Type">
                        <Field
                          label="Document Type"
                          name={"detailsTabJeType"}
                          onChange={onChange("detailsTabJeType")}
                          as={ComboBox}
                          allowFreeform={true}
                          autoComplete="on"
                          options={createState.jeTypes}
                          styles={ForceMaxWidthAndHeightOnDropdownStyle({ width: "100%", height: "200px" })}
                          selectedKey={formik.values.detailsTabJeType ? formik.values.detailsTabJeType : ""}
                          disabled={props.disabled}
                          errorMessage={
                            props.disabled
                              ? ""
                              : createState.jeTypes.length === 0
                              ? "Error: No JE Types found"
                              : formik.errors.detailsTabJeType
                          }
                        />
                      </SimpleShimmerForForms>
                    </Stack.Item>
                    <Stack.Item grow={1}>
                      <SimpleShimmerForForms
                        loadingStatus={props.loadingStatus}
                        height={32}
                        label={formik.values.detailsTabJeType !== "SV" ? "Translation Date" : "Reversal Date (SV)"}
                      >
                        <>
                          {formik.values.detailsTabJeType !== "SV" ? (
                            <Field
                              name="detailsTabTranslationDate"
                              label="Translation Date"
                              component={DatePickerField}
                              disabled={props.disabled}
                              maxDate={formik.values.detailsTabPostingDate}
                              errorMessage={props.disabled ? "" : formik.errors.detailsTabTranslationDate}
                            />
                          ) : (
                            <Field
                              name="detailsTabReversalDate"
                              label="Reversal Date (SV)"
                              component={DatePickerField}
                              disabled={props.disabled}
                              errorMessage={props.disabled ? "" : formik.errors.detailsTabReversalDate}
                            />
                          )}
                        </>
                      </SimpleShimmerForForms>
                    </Stack.Item>
                  </Stack>
                </div>
                <div className="ms-Grid-col ms-sm12 ms-lg6">
                  <SimpleShimmerForForms loadingStatus={props.loadingStatus} height={64} label="Description">
                    <Field
                      as={TextField}
                      label="Description"
                      name="detailsTabDescription"
                      type="input"
                      multiline
                      description="Max 25 characters"
                      onChange={(
                        _event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
                        newValue?: string
                      ) => {
                        if (!newValue || newValue.length <= 25) {
                          formik.setFieldValue("detailsTabDescription", newValue);
                        }
                      }}
                      disabled={props.disabled}
                      errorMessage={props.disabled ? "" : formik.errors.detailsTabDescription}
                    />
                  </SimpleShimmerForForms>
                </div>
              </div>
            </div>
          </div>
          <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-xl4">
            <Stack>
              <Stack.Item>
                <Text {...columnHeaderProps}>Line Amount Summary</Text>
              </Stack.Item>
              <Stack.Item align="start">
                <SimpleShimmerForForms loadingStatus={props.loadingStatus} height={32} label="Currency">
                  <Field as={TextField} label="Currency" name="detailsTabCurrency" type="input" readOnly />
                </SimpleShimmerForForms>
              </Stack.Item>
              <Stack horizontal grow {...{ tokens: gridStyle }}>
                <Stack.Item {...{ styles: numberStyles }}>
                  <SimpleShimmerForForms loadingStatus={props.loadingStatus} height={32} label="Debit">
                    <Field as={TextField} label="Debit" name="detailsTabDebit" type="input" readOnly />
                  </SimpleShimmerForForms>
                </Stack.Item>
                <Stack.Item {...{ styles: numberStyles }}>
                  <SimpleShimmerForForms loadingStatus={props.loadingStatus} height={32} label="Credit">
                    <Field as={TextField} label="Credit" name="detailsTabCredit" type="input" readOnly />
                  </SimpleShimmerForForms>
                </Stack.Item>
                <Stack.Item {...{ styles: numberStyles }}>
                  <SimpleShimmerForForms loadingStatus={props.loadingStatus} height={32} label="Total">
                    <Field
                      as={TextField}
                      label="Total"
                      name="detailsTabTotal"
                      type="input"
                      readOnly
                      errorMessage={props.disabled ? "" : formik.errors.detailsTabTotal}
                    />
                  </SimpleShimmerForForms>
                </Stack.Item>
              </Stack>
              {(props.pageState.pageConfiguration.pageType === CreatePageType.DualCurrency ||
                props.pageState.pageConfiguration.pageType === CreatePageType.FourCurrency) && (
                <>
                  <Stack.Item>
                    <Text {...columnHeaderProps}>Line Amount LC Summary</Text>
                  </Stack.Item>
                  <Stack.Item align="start">
                    <SimpleShimmerForForms loadingStatus={props.loadingStatus} height={32} label="Currency">
                      <Field
                        as={TextField}
                        label="Currency"
                        name={
                          props.pageState.pageConfiguration.pageType === CreatePageType.FourCurrency
                            ? "detailsTabCurrency1"
                            : "detailsTabCurrency"
                        }
                        type="input"
                        readOnly
                      />
                    </SimpleShimmerForForms>
                  </Stack.Item>
                  <Stack horizontal grow {...{ tokens: gridStyle }}>
                    <Stack.Item {...{ styles: numberStyles }}>
                      <SimpleShimmerForForms loadingStatus={props.loadingStatus} height={32} label="Debit">
                        <Field as={TextField} label="Debit" name="detailsTabDebitLC" type="input" readOnly />
                      </SimpleShimmerForForms>
                    </Stack.Item>
                    <Stack.Item {...{ styles: numberStyles }}>
                      <SimpleShimmerForForms loadingStatus={props.loadingStatus} height={32} label="Credit">
                        <Field as={TextField} label="Credit" name="detailsTabCreditLC" type="input" readOnly />
                      </SimpleShimmerForForms>
                    </Stack.Item>
                    <Stack.Item {...{ styles: numberStyles }}>
                      <SimpleShimmerForForms loadingStatus={props.loadingStatus} height={32} label="Total">
                        <Field
                          as={TextField}
                          label="Total"
                          name="detailsTabTotalLC"
                          type="input"
                          readOnly
                          errorMessage={props.disabled ? "" : formik.errors.detailsTabTotalLC}
                        />
                      </SimpleShimmerForForms>
                    </Stack.Item>
                  </Stack>
                </>
              )}
              {props.pageState.pageConfiguration.pageType === CreatePageType.FourCurrency && (
                <>
                  <Stack.Item>
                    <Text {...columnHeaderProps}>Line Amount LC2 Summary</Text>
                  </Stack.Item>
                  <Stack.Item align="start">
                    <SimpleShimmerForForms loadingStatus={props.loadingStatus} height={32} label="Currency">
                      <Field
                        as={TextField}
                        label="Currency"
                        name="detailsTabCurrency2"
                        type="input"
                        readOnly
                        value={"USD"}
                      />
                    </SimpleShimmerForForms>
                  </Stack.Item>
                  <Stack horizontal grow {...{ tokens: gridStyle }}>
                    <Stack.Item {...{ styles: numberStyles }}>
                      <SimpleShimmerForForms loadingStatus={props.loadingStatus} height={32} label="Debit">
                        <Field as={TextField} label="Debit" name="detailsTabDebitLC2" type="input" readOnly />
                      </SimpleShimmerForForms>
                    </Stack.Item>
                    <Stack.Item {...{ styles: numberStyles }}>
                      <SimpleShimmerForForms loadingStatus={props.loadingStatus} height={32} label="Credit">
                        <Field as={TextField} label="Credit" name="detailsTabCreditLC2" type="input" readOnly />
                      </SimpleShimmerForForms>
                    </Stack.Item>
                    <Stack.Item {...{ styles: numberStyles }}>
                      <SimpleShimmerForForms loadingStatus={props.loadingStatus} height={32} label="Total">
                        <Field
                          as={TextField}
                          label="Total"
                          name="detailsTabTotalLC2"
                          type="input"
                          readOnly
                          errorMessage={props.disabled ? "" : formik.errors.detailsTabTotalLC2}
                        />
                      </SimpleShimmerForForms>
                    </Stack.Item>
                  </Stack>
                </>
              )}
              {props.pageState.pageConfiguration.pageType === CreatePageType.FourCurrency && (
                <>
                  <Stack.Item>
                    <Text {...columnHeaderProps}>Line Amount LC3 Summary</Text>
                  </Stack.Item>
                  <Stack.Item align="start">
                    <SimpleShimmerForForms loadingStatus={props.loadingStatus} height={32} label="Currency">
                      <Field
                        as={TextField}
                        label="Currency"
                        name="detailsTabCurrency3"
                        type="input"
                        readOnly
                        //value={"USD"}
                      />
                    </SimpleShimmerForForms>
                  </Stack.Item>
                  <Stack horizontal grow {...{ tokens: gridStyle }}>
                    <Stack.Item {...{ styles: numberStyles }}>
                      <SimpleShimmerForForms loadingStatus={props.loadingStatus} height={32} label="Debit">
                        <Field as={TextField} label="Debit" name="detailsTabDebitLC3" type="input" readOnly />
                      </SimpleShimmerForForms>
                    </Stack.Item>
                    <Stack.Item {...{ styles: numberStyles }}>
                      <SimpleShimmerForForms loadingStatus={props.loadingStatus} height={32} label="Credit">
                        <Field as={TextField} label="Credit" name="detailsTabCreditLC3" type="input" readOnly />
                      </SimpleShimmerForForms>
                    </Stack.Item>
                    <Stack.Item {...{ styles: numberStyles }}>
                      <SimpleShimmerForForms loadingStatus={props.loadingStatus} height={32} label="Total">
                        <Field
                          as={TextField}
                          label="Total"
                          name="detailsTabTotalLC3"
                          type="input"
                          readOnly
                          errorMessage={props.disabled ? "" : formik.errors.detailsTabTotalLC3}
                        />
                      </SimpleShimmerForForms>
                    </Stack.Item>
                  </Stack>
                </>
              )}
            </Stack>
          </div>
        </div>
      </div>
    </>
  );
};

GLCreateJeDetails.displayName = "GLCreateJeDetails";

export { GLCreateJeDetails };
