function containsInvalidCharacters(str: string): boolean {
  const regex = /[ !@#$%^&*()_+=[\]{}':"/|,.<>?]/g;
  return regex.test(str);
}

export function removeDuplicateAliases(aliasArray: (string | undefined)[]): string[] {
  const cleanedAliases = aliasArray.filter((alias) => alias !== undefined) as string[];

  const aliasesWithDomain = cleanedAliases.filter((alias) => alias.indexOf("\\") !== -1);
  const aliasesWithoutDomain = cleanedAliases.filter((alias) => alias.indexOf("\\") === -1);

  // remove domain from aliases with domain
  const removedDomainFromAliases = aliasesWithDomain.map((alias) => alias.split("\\")[1]).concat(aliasesWithoutDomain);
  const allAliases = [...aliasesWithoutDomain, ...removedDomainFromAliases];
  const uniqueAliases = Array.from(new Set(allAliases));
  return uniqueAliases;
}

export function ValidateReviewerAliasesLocally(AdditionalApprovers: string): null | string {
  if (containsInvalidCharacters(AdditionalApprovers)) {
    return "The Additional Reviewer field does not accept special characters except for backslash (\\) and semicolon (;).";
  }
  const originalAliases = AdditionalApprovers.split(";");
  const aliasArray = Array.from(new Set(originalAliases));

  const removedBlankAliases = aliasArray.filter((alias) => alias.trim().length > 0);
  if (aliasArray.length !== removedBlankAliases.length) {
    return "The Additional Reviewer field does not accept blank aliases.";
  }

  if (aliasArray.length !== originalAliases.length) {
    return "The Additional Reviewer field does not accept duplicate aliases.";
  }

  const aliasesWithDomain = aliasArray.filter((alias) => alias.indexOf("\\") !== -1);
  const aliasesWithoutDomain = aliasArray.filter((alias) => alias.indexOf("\\") === -1);
  const match = aliasesWithDomain.find((aliasWithDomain) => {
    for (const aliasWithoutDomain of aliasesWithoutDomain) {
      if (aliasWithDomain.includes(aliasWithoutDomain)) {
        return true;
      }
    }
    return false;
  });
  if (match) {
    return "The Additional Reviewer field does not accept duplicate aliases.";
  }

  return null;
}
