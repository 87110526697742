import { CoherencePanel, CoherencePanelSize } from "@coherence-design-system/controls";
import React from "react";
import ActionButtons from "../../copiedItems/ActionButtons";
import { JEMChecklistDetailsRow } from "@jem/components";
import { useForm } from "react-hook-form";
import { Stack, Text, Label } from "@fluentui/react";
import InlineFormInputErrorMessage from "./InlineFormInputErrorMessage";

interface IJEMChecklistDetailsDeletePanelProps {
  checklistItem: JEMChecklistDetailsRow;
  onClosePanel: () => void;
  onSaveItem: (editedItem: JEMChecklistDetailsRow) => void;
  loading?: boolean;
  error: string[];
}

const JEMChecklistDetailsDeletePanel = (props: IJEMChecklistDetailsDeletePanelProps): JSX.Element => {
  const { checklistItem, onClosePanel, onSaveItem, error, loading } = props;
  const form = useForm<JEMChecklistDetailsRow>();
  const { handleSubmit } = form;

  const onHandleSubmit = () => {
    onSaveItem(checklistItem);
  };

  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.small}
      titleText={"Delete JE Task"}
      isOpen
      onDismiss={onClosePanel}
      hasCloseButton
      closeButtonAriaLabel="Close Delete JE Task"
      onRenderFooter={(): JSX.Element => (
        <ActionButtons
          mutationLoading={false}
          closePanel={onClosePanel}
          handleSubmit={handleSubmit(onHandleSubmit)}
          saveLabel="Delete"
          saveTitle="Delete"
          cancelLabel="Cancel"
          cancelTitle="Cancel"
          disabled={loading}
        />
      )}
    >
      <InlineFormInputErrorMessage errorMessages={error} />
      <form onSubmit={onHandleSubmit}>
        <Stack tokens={{ childrenGap: 20 }}>
          <span></span>
          {/*<Label>{checklistItem.checklist}</Label>*/}
          <Label>Posting Period {checklistItem.postingPeriod}</Label>
          <Label> Deleting the JE Task with details </Label>
          <Text> JE Task Name : {checklistItem.jeTaskName} </Text>
          <Text> Purpose : {checklistItem.purpose}</Text>
          <Text> JE Type : {checklistItem.jeType} </Text>
          <Text> Company Code : {checklistItem.companycode}</Text>
        </Stack>
      </form>
    </CoherencePanel>
  );
};
export default JEMChecklistDetailsDeletePanel;
