import { useState } from "react";
import useSWR, { SWRConfiguration } from "swr";

type UseQueryOptions<T> = SWRConfiguration<T> & {
  queryKey: string | [any, ...unknown[]] | readonly [any, ...unknown[]] | Record<any, any> | null | undefined | false;
  queryFn: (options: {
    queryKey: string | [any, ...unknown[]] | readonly [any, ...unknown[]] | Record<any, any> | null | undefined | false;
  }) => Promise<T>;
  enabled?: boolean;
  staleTime?: number;
  select?: (data: T) => T;
};

interface UseQueryResult<T, E = Error> {
  data: T | undefined;
  isLoading: boolean;
  isFetching: boolean;
  error: E | undefined;
  dataUpdatedAt: number;
  refetch: () => void;
  removeCache: () => void;
}

export function useQuery<T, E = Error>({
  queryKey,
  queryFn,
  enabled = true,
  staleTime,
  select
}: UseQueryOptions<T>): UseQueryResult<T, E> {
  const [dataUpdatedAtMap, setDataUpdatedAtMap] = useState<Record<string, number>>({});

  const fetcher = async (args: UseQueryOptions<T>["queryKey"]) => {
    const cacheKey = JSON.stringify(args);
    const newData = await queryFn({ queryKey: args });
    const newDataUpdatedAt = new Date().getTime();
    setDataUpdatedAtMap((prevDataUpdatedAtMap) => ({
      ...prevDataUpdatedAtMap,
      [cacheKey]: newDataUpdatedAt
    }));

    return newData;
  };

  const swrOptions: SWRConfiguration = {
    fetcher,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    shouldRetryOnError: false,
    revalidateIfStale: true,
    revalidateOnMount: true
  };

  if (staleTime !== undefined) {
    swrOptions.refreshInterval = staleTime;
  }

  const { data, error, isValidating, isLoading, mutate } = useSWR(enabled ? queryKey : null, swrOptions);
  const cacheKey = JSON.stringify(queryKey);
  const dataUpdatedAt = cacheKey in dataUpdatedAtMap ? dataUpdatedAtMap[cacheKey] : 0;

  const removeCache = () => {
    // Generate a new unique ID to reset the cache
    // TODO: Preferably wait until SWR has a way to clear cache for a specific key
  };
  return {
    data: select !== undefined && data !== undefined ? select(data) : data,
    isLoading,
    isFetching: isValidating,
    error,
    dataUpdatedAt: dataUpdatedAt,
    refetch: () => mutate(),
    removeCache
  };
}
