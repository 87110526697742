import { css } from "@emotion/css";
import { Label, SelectionMode, TextField } from "@fluentui/react";
import { CommentsModel, DashboardGrid } from "@jem/components";
import { IExtendedColumn, IDashboardGridRef } from "@jem/components";
import { exportToExcel } from "@jem/components";
import React, { useContext, useRef } from "react";

import { IHCCPODetailsContext } from "../../../contexts/IHCCPODetailsContext/IHCCPODetailsContext";

const ihccDashboardUserConfigName = "poDetailsReviewConfiguration";
export interface IHCCPODetailsTabProps {
  saveBtnDisabled: boolean;
}
export const getPOReviewDetailsColumns = (): IExtendedColumn[] => {
  const cols: IExtendedColumn[] = [
    {
      key: "persona",
      name: "Persona",
      fieldName: "persona",
      type: "string",
      minWidth: 80
    },
    {
      key: "alias",
      name: "Alias",
      fieldName: "alias",
      type: "string",
      minWidth: 80
    },
    {
      key: "comments",
      name: "Comments",
      fieldName: "comments",
      type: "string",
      minWidth: 80
    },
    {
      key: "createdDate",
      name: "Date Added",
      fieldName: "dateAdded",
      type: "string",
      minWidth: 80
    }
  ];

  return cols;
};

export interface IHCCReviewFormTabProps {
  saveBtnDisabled: boolean;
}

const ReviewerColumn: React.FC<IHCCReviewFormTabProps> = (props) => {
  const poData = useContext(IHCCPODetailsContext);
  const reviewerModel = poData.model.values.reviewTab;
  return (
    <>
      <div className="ms-Grid" dir="ltr">
        <div className="ms-Grid-row">
          <Label>Enter a comment and the email alias of the Reviewer</Label>
        </div>
        <div className="ms-Grid-row">
          <TextField label="Reviewer:" value={reviewerModel.primaryReviewer} />
        </div>
        <div className="ms-Grid-row">
          <TextField
            disabled={props.saveBtnDisabled}
            label="Additional Reviewers:"
            value={poData.userChanges.additionalReviewers}
            onChange={(e, newValue) => {
              poData.setUserChanges({
                additionalReviewers: newValue || ""
              });
            }}
            description="Maximum of 16 additional reviewers. Separate by semicolon (;)."
          />
        </div>
        <div className="ms-Grid-row">
          <TextField
            label="Comments:"
            value={poData.userChanges.comments}
            onChange={(e, newValue) => {
              poData.setUserChanges({
                comments: newValue || ""
              });
            }}
            errorMessage={poData.userChanges.commentsError}
            multiline
            rows={1}
          />
        </div>
      </div>
    </>
  );
};

let ReviewerComments: React.FC<{ comments: CommentsModel[] }> = (props) => {
  const dashboardGridRef = useRef<IDashboardGridRef>(null);

  return (
    <div
      className={css`
        border-left: 2px solid;
        padding-left: 10px;
      `}
      data-is-scrollable="true"
    >
      <DashboardGrid
        idForLocalStorage={ihccDashboardUserConfigName}
        customRef={dashboardGridRef}
        columnGenerator={getPOReviewDetailsColumns}
        items={props.comments}
        isSortedIndex={1}
        height={`300px`}
        isSortedDescending={true}
        selectionMode={SelectionMode.multiple}
        onExport={(rows) => {
          exportToExcel({
            sheetName: "paymentOrderComments",
            rowsWithHeader: rows,
            fileName: "paymentOrderComments.xlsx"
          });
        }}
        disablePagination={true}
      ></DashboardGrid>
    </div>
  );
};

ReviewerComments = React.memo(ReviewerComments);
ReviewerComments.displayName = "ReviewerComments";

const ReviewFormTab: React.FC<IHCCReviewFormTabProps> = (props) => {
  const poData = useContext(IHCCPODetailsContext);
  const reviewerModel = poData.model.values.reviewTab;

  return (
    <React.Fragment>
      <div className="ms-Grid" dir="ltr">
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm4">
            <ReviewerColumn saveBtnDisabled={props.saveBtnDisabled} />
          </div>
          <div className="ms-Grid-col ms-sm8">
            <ReviewerComments comments={reviewerModel.commentsGrid} />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

ReviewFormTab.displayName = "ReviewFormTab";
export default ReviewFormTab;
