import { DomainDataEnum, DomainDataObjects } from "../../Shared/contexts/JEMContext/JEMContext.domainData.types";
import { currentFyFpByMonth } from "../../Shared/utilities/FiscalYearFiscalPeriod";
import { FiscalPeriodModel } from "../components/GLCreate/GLCreate.types";
import { FiscalPeriodStringTransformations } from "./FiscalPeriodStringTransformations";

export function getFiscalPeriodStartDate(
  domainData: Pick<DomainDataObjects, DomainDataEnum.FiscalPeriods>,
  fiscalYearPeriodString: string
): Date {
  const { fiscalMonth, fiscalYear } =
    FiscalPeriodStringTransformations.FiscalPeriodStringToFiscalYearAndFiscalMonth(fiscalYearPeriodString);
  const { calendarMonth, calendarYear } = getCalendayMonthAndYearFromFiscalMonthAndFiscalYear(
    domainData,
    fiscalMonth,
    fiscalYear
  );
  return new Date(calendarYear, calendarMonth - 1, 1);
}
export function getFiscalPeriodEndDate(
  domainData: Pick<DomainDataObjects, DomainDataEnum.FiscalPeriods>,
  fiscalYearPeriodString: string
): Date {
  const { fiscalMonth, fiscalYear } =
    FiscalPeriodStringTransformations.FiscalPeriodStringToFiscalYearAndFiscalMonth(fiscalYearPeriodString);
  const { calendarMonth, calendarYear } = getCalendayMonthAndYearFromFiscalMonthAndFiscalYear(
    domainData,
    fiscalMonth,
    fiscalYear
  );
  return new Date(calendarYear, calendarMonth, 0);
}
export function getCalendayMonthAndYearFromFiscalMonthAndFiscalYear(
  domainData: Pick<DomainDataObjects, DomainDataEnum.FiscalPeriods>,
  fiscalMonth: number,
  fiscalYear: number
): { calendarMonth: number; calendarYear: number } {
  for (let i = 0; i < domainData.FiscalPeriods.length; i++) {
    const fiscalPeriod = domainData.FiscalPeriods[i];
    if (fiscalPeriod.fiscalYear === fiscalYear && fiscalPeriod.fiscalMonth === fiscalMonth) {
      return {
        calendarMonth: fiscalPeriod.calendarMonth,
        calendarYear: fiscalPeriod.calendarYear
      };
    }
  }
  if (domainData.FiscalPeriods.length > 0) {
    //copy fiscalperiods in local
    const localFiscalPeriods = [...domainData.FiscalPeriods];
    //sort by fiscalYear and fiscalMonth
    localFiscalPeriods.sort((a, b) => {
      if (a.fiscalYear === b.fiscalYear) {
        return a.fiscalMonth - b.fiscalMonth;
      }
      return a.fiscalYear - b.fiscalYear;
    });

    if (fiscalYear <= localFiscalPeriods[0].fiscalYear) {
      // walk backwards through fiscalperiods until we find the first fiscalperiod that matches the fiscalyear
      let itrFiscalMonth = localFiscalPeriods[0].fiscalMonth,
        itrFiscalYear = localFiscalPeriods[0].fiscalYear,
        itrCalendarMonth = localFiscalPeriods[0].calendarMonth,
        itrCalendarYear = localFiscalPeriods[0].calendarYear;
      let tries = 50;
      while (tries > 0) {
        if (itrFiscalYear === fiscalYear && itrFiscalMonth === fiscalMonth) {
          return { calendarMonth: itrCalendarMonth, calendarYear: itrCalendarYear };
        }
        itrFiscalMonth - 1 < 1 ? (itrFiscalMonth = 12) : itrFiscalMonth--;
        itrFiscalMonth === 12 ? itrFiscalYear-- : itrFiscalYear;
        itrCalendarMonth - 1 < 1 ? (itrCalendarMonth = 12) : itrCalendarMonth--;
        itrCalendarMonth === 12 ? itrCalendarYear-- : itrCalendarYear;
        tries--;
      }
    }

    return {
      calendarMonth: localFiscalPeriods[0].calendarMonth,
      calendarYear: localFiscalPeriods[0].calendarYear
    };
  }
  return { calendarMonth: 0, calendarYear: 0 };
}

export function getCurrentFyFp(currentDate: Date): FiscalPeriodModel {
  // currentDate.getMonth() + 1 is zero based, so we need to add 1 to get the correct month
  const { fiscalMonth, fiscalYear } = currentFyFpByMonth(currentDate.getMonth() + 1, currentDate.getFullYear());
  return {
    calendarMonth: currentDate.getMonth() + 1,
    calendarYear: currentDate.getFullYear(),
    fiscalYear: fiscalYear,
    fiscalMonth: fiscalMonth,
    fiscalPeriod: FiscalPeriodStringTransformations.FiscalYearAndFiscalMonthToString(fiscalYear, fiscalMonth),
    endDate: new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0),
    startDate: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
    index: null
  };
}

export function GetCurrentFiscalPeriod(
  domainData: Pick<DomainDataObjects, DomainDataEnum.FiscalPeriods>,
  overrideFiscalPeriod: Pick<FiscalPeriodModel, "fiscalYear" | "fiscalMonth"> | null = null
): FiscalPeriodModel {
  const currentDate = new Date();
  let fiscalPeriodDate: FiscalPeriodModel | null = null;
  for (const fiscalPeriod of domainData.FiscalPeriods) {
    if (overrideFiscalPeriod !== null) {
      if (
        overrideFiscalPeriod.fiscalYear === fiscalPeriod.fiscalYear &&
        overrideFiscalPeriod.fiscalMonth === fiscalPeriod.fiscalMonth
      ) {
        fiscalPeriodDate = {
          calendarMonth: fiscalPeriod.calendarMonth,
          calendarYear: fiscalPeriod.calendarYear,
          fiscalYear: fiscalPeriod.fiscalYear,
          fiscalMonth: fiscalPeriod.fiscalMonth,
          fiscalPeriod: FiscalPeriodStringTransformations.FiscalYearAndFiscalMonthToString(
            fiscalPeriod.fiscalYear,
            fiscalPeriod.fiscalMonth
          ),
          endDate: new Date(fiscalPeriod.calendarYear, fiscalPeriod.calendarMonth + 1, 0),
          startDate: new Date(fiscalPeriod.calendarYear, fiscalPeriod.calendarMonth, 1),
          index: null
        };
        break;
      }
    } else if (currentDate >= new Date(fiscalPeriod.startDate) && currentDate <= new Date(fiscalPeriod.endDate)) {
      fiscalPeriodDate = {
        calendarMonth: fiscalPeriod.calendarMonth,
        calendarYear: fiscalPeriod.calendarYear,
        fiscalYear: fiscalPeriod.fiscalYear,
        fiscalMonth: fiscalPeriod.fiscalMonth,
        fiscalPeriod: FiscalPeriodStringTransformations.FiscalYearAndFiscalMonthToString(
          fiscalPeriod.fiscalYear,
          fiscalPeriod.fiscalMonth
        ),
        endDate: new Date(fiscalPeriod.calendarYear, fiscalPeriod.calendarMonth + 1, 0),
        startDate: new Date(fiscalPeriod.calendarYear, fiscalPeriod.calendarMonth, 1),
        index: null
      };
      break;
    }
  }

  if (!fiscalPeriodDate) {
    return getCurrentFyFp(currentDate);
  }
  return fiscalPeriodDate;
}
