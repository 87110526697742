import React, { useState, forwardRef, useImperativeHandle } from "react";
import { IconButton, Panel, PrimaryButton } from "@fluentui/react";
import { For } from "react-loops";
import { AsyncSubject } from "rxjs";
import { css } from "@emotion/css";

export enum NotificationTypes {
  errors,
  info,
  success,
  warning
}

export interface Notification {
  type: NotificationTypes;
  messages: string;
  refguid: string;
  documentId: string;
  timestamp: Date;
}

export interface NotificationMessagesRef {
  open: (notifications: Notification[]) => void;
  reset: () => void;
  getOnSubmitAsyncSubject: () => AsyncSubject<null>;
}

const SuccessIcon = () => {
  return (
    <svg
      className={css`
        fill: #107c10;
      `}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 2048 2048"
    >
      <path d="M1491 595l90 90-749 749-365-365 90-90 275 275 659-659zM1024 0q141 0 272 36t245 103 207 160 160 208 103 245 37 272q0 141-36 272t-103 245-160 207-208 160-245 103-272 37q-141 0-272-36t-245-103-207-160-160-208-103-244-37-273q0-141 36-272t103-245 160-207 208-160T751 37t273-37zm0 1920q123 0 237-32t214-90 182-141 140-181 91-214 32-238q0-123-32-237t-90-214-141-182-181-140-214-91-238-32q-123 0-237 32t-214 90-182 141-140 181-91 214-32 238q0 123 32 237t90 214 141 182 181 140 214 91 238 32z" />
    </svg>
  );
};

const WarningIcon = () => {
  return (
    <svg
      className={css`
        fill: #a80000;
      `}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 2048 2048"
    >
      <path d="M1920 1920H0L960 0l960 1920zM207 1792h1506L960 286 207 1792zm817-1024v640H896V768h128zm-128 768h128v128H896v-128z" />
    </svg>
  );
};

const InfoIcon = () => {
  return (
    <svg
      className={css`
        fill: #a80000;
      `}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 2048 2048"
    >
      <path d="M960 1920q-133 0-255-34t-230-96-194-150-150-195-97-229T0 960q0-133 34-255t96-230 150-194 195-150 229-97T960 0q133 0 255 34t230 96 194 150 150 195 97 229 34 256q0 133-34 255t-96 230-150 194-195 150-229 97-256 34zm0-1792q-115 0-221 30t-198 84-169 130-130 168-84 199-30 221q0 114 30 220t84 199 130 169 168 130 199 84 221 30q114 0 220-30t199-84 169-130 130-168 84-199 30-221q0-114-30-220t-84-199-130-169-168-130-199-84-221-30zm-64 640h128v640H896V768zm0-256h128v128H896V512z" />
    </svg>
  );
};

const ErrorIcon = () => {
  return (
    <svg
      className={css`
        fill: #a80000;
      `}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 2048 2048"
    >
      <path d="M1024 0q141 0 272 36t244 104 207 160 161 207 103 245 37 272q0 141-36 272t-104 244-160 207-207 161-245 103-272 37q-141 0-272-36t-244-104-207-160-161-207-103-245-37-272q0-141 36-272t104-244 160-207 207-161T752 37t272-37zm0 1920q124 0 238-32t214-90 181-140 140-181 91-214 32-239q0-124-32-238t-90-214-140-181-181-140-214-91-239-32q-124 0-238 32t-214 90-181 140-140 181-91 214-32 239q0 124 32 238t90 214 140 181 181 140 214 91 239 32zm443-1249l-352 353 352 353-90 90-353-352-353 352-90-90 352-353-352-353 90-90 353 352 353-352 90 90z" />
    </svg>
  );
};

interface OneNotificationProps extends Notification {
  onDismiss: () => void;
}

const OneNotification: React.FC<OneNotificationProps> = (props) => {
  return (
    <div
      className={css`
        & .top-elements {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          gap: 8px;
          margin-bottom: 8px;
        }
        & .icon {
          width: 16px;
          height: 16px;
          margin-top: 2px;
        }
        & .date {
          margin-left: auto;
        }
      `}
    >
      <div className="top-elements">
        <span className="icon">
          {props.type === NotificationTypes.errors ? <ErrorIcon /> : null}
          {props.type === NotificationTypes.warning ? <WarningIcon /> : null}
          {props.type === NotificationTypes.success ? <SuccessIcon /> : null}
          {props.type === NotificationTypes.info ? <InfoIcon /> : null}
        </span>
        <span>{props.messages}</span>
        <span className="date">{props.timestamp.toLocaleDateString()}</span>
        <IconButton
          iconProps={{ iconName: "Delete" }}
          title="Delete"
          ariaLabel="Delete"
          onClick={() => {
            props.onDismiss();
          }}
        />
      </div>
      <div>JE {props.documentId}</div>
    </div>
  );
};

let onSubmitAsyncSubject: null | AsyncSubject<null> = null;

const NotificationsManager = forwardRef<NotificationMessagesRef>((props, ref) => {
  const [notificationsForUi, setNotifications] = useState<Notification[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useImperativeHandle(ref, () => ({
    open(notifications) {
      setNotifications([...notificationsForUi, ...notifications]);
      setIsOpen(true);
    },
    reset() {
      setNotifications([]);
    },
    getOnSubmitAsyncSubject() {
      onSubmitAsyncSubject = new AsyncSubject<null>();
      return onSubmitAsyncSubject;
    }
  }));

  const dismissPanel = () => {
    if (onSubmitAsyncSubject) {
      onSubmitAsyncSubject.next(null);
      onSubmitAsyncSubject.complete();
      onSubmitAsyncSubject = null;
    }
    setIsOpen(false);
  };

  const onRenderFooterContent = () => (
    <>
      <PrimaryButton
        onClick={() => {
          setNotifications([]);
        }}
      >
        Dismiss All
      </PrimaryButton>
    </>
  );

  return (
    <React.Fragment>
      <Panel
        isLightDismiss
        isHiddenOnDismiss={true}
        headerText="Notifications"
        isOpen={isOpen}
        onDismiss={dismissPanel}
        closeButtonAriaLabel="Close"
        onRenderFooterContent={onRenderFooterContent}
        isFooterAtBottom={true}
      >
        <div
          className={css`
            display: flex;
            flex-direction: column;
            gap: 8px;
            margin-top: 16px;
          `}
        >
          <For
            of={notificationsForUi}
            as={(n, i) => (
              <OneNotification
                {...n}
                onDismiss={() => {
                  notificationsForUi.splice(i.index, 1);
                  setNotifications([...notificationsForUi]);
                }}
              />
            )}
          />
        </div>
      </Panel>
    </React.Fragment>
  );
});

NotificationsManager.displayName = "NotificationsManager";

export { NotificationsManager };
