import {
  ApiError,
  ApiParsingUtilities,
  DomainDataObjects,
  IUserProviderState,
  JemConfiguration,
  postRequest
} from "@jem/components";
import { faker } from "@faker-js/faker";
import { v4 } from "uuid";
import { GLBatchSearchCriteria } from "../GLBatchSearch.types";

export interface BatchLineInfo {
  bpoBatch: boolean;
  batchName: string;
  description: string;
  errorPostings: number;
  isHavingAttachments: number;
  postingDate: string;
  refGuid: string;
  successPostings: number;
  totalPostings: number;
  checkListName: string;
  opsDetailsName: string;
  isAdhocOrStd: string;
}

export interface BatchLineInfoModel extends BatchLineInfo {
  postingComplete: boolean;
  hasAttachments: boolean;
}

export interface BatchPayloadSchema {
  Alias: string[];
  AliasType: string;
  FiscalYear: number;
  FiscalValue: FiscalValue[];
  BatchName: string | null;
  OpsDetailId: OpsDetailId[];
}

export interface OpsDetailId {
  OpsId: number;
}

export interface FiscalValue {
  FiscalYear: number;
  FiscalMonth: number;
}

export function createRandomBatchLineInfo(): BatchLineInfo {
  const total = faker.datatype.number(200);
  const errors = faker.datatype.number(100);
  const success = total - errors;
  return {
    bpoBatch: faker.datatype.boolean(),
    batchName: faker.lorem.words(2),
    description: faker.lorem.words(3),
    errorPostings: errors,
    isHavingAttachments: faker.datatype.number(50),
    postingDate: faker.date.recent().toISOString(),
    refGuid: faker.datatype.uuid(),
    successPostings: success,
    totalPostings: total
  } as BatchLineInfo;
}

export async function fetchSearchResults(
  configuration: JemConfiguration["GeneralLedgerApi"],
  accessToken: IUserProviderState["accessToken"],
  domainData: DomainDataObjects | null,
  searchCriteria: GLBatchSearchCriteria
): Promise<BatchLineInfoModel[]> {
  console.log("fetchSearchResults");
  const payload = userSelectionToPayload(searchCriteria);
  const endpointUrl = `${configuration.baseApiUrl}${configuration.endpoints.getBatchSearchResults}`.replace(
    "{refguid}",
    v4()
  );
  const response = await postRequest<BatchLineInfo[]>(endpointUrl, payload, accessToken);
  if (!response) {
    throw new ApiError("Error from Server.");
  }

  return response.map(transformBatchLineInfoIntoModel);
}

function userSelectionToPayload(searchCriteria: GLBatchSearchCriteria): BatchPayloadSchema {
  const payload: BatchPayloadSchema = {
    Alias: [searchCriteria.Alias],
    AliasType: searchCriteria.AliasType === "P" ? "1" : "2",
    FiscalYear: searchCriteria.FiscalYear,
    FiscalValue: (searchCriteria.FiscalMonth.includes(-1)
      ? []
      : searchCriteria.FiscalMonth.includes(0)
      ? Array.from({ length: 12 }, (_, i) => i + 1)
      : searchCriteria.FiscalMonth
    ).map((fm) => ({ FiscalYear: searchCriteria.FiscalYear, FiscalMonth: fm })),
    BatchName: searchCriteria.BatchName,
    OpsDetailId: searchCriteria.OpsDetailId
      ? (searchCriteria.OpsDetailId.includes(-1) ? [] : searchCriteria.OpsDetailId).map((fm) => ({ OpsId: fm }))
      : []
  };
  return payload;
}

function transformBatchLineInfoIntoModel(apiData: BatchLineInfo): BatchLineInfoModel {
  return {
    ...apiData,
    postingDate: ApiParsingUtilities.parseDateWithTimeStamp(apiData.postingDate),
    postingComplete: apiData.successPostings + apiData.errorPostings === apiData.totalPostings,
    hasAttachments: apiData.isHavingAttachments > 0
  };
}
