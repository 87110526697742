import {
  PrimaryButton,
  DefaultButton,
  Modal,
  Stack,
  Text,
  mergeStyles,
  TooltipHost,
  Label,
  IconButton,
  FontWeights
} from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import React, { useImperativeHandle } from "react";
import { AsyncSubject } from "rxjs";

export interface AliasChangeModalContentProps {
  newPosters: string[];
  previousPosters: string[];
  newReviewers: string[];
  previousReviewers: string[];
}

export interface AliasChangeModalRef {
  getUserSubmission: (props: AliasChangeModalContentProps) => AsyncSubject<boolean>;
}

export interface AliasChangeModalProps {
  customRef: React.RefObject<AliasChangeModalRef>;
}

const tagStyles = mergeStyles({
  display: "inline-block",
  backgroundColor: "#f3f3f3",
  borderRadius: "3px",
  padding: "2px 4px",
  margin: "2px"
});

let ModalController: AsyncSubject<boolean> | null = null;

const AliasDiffDisplay: React.FC<{ label: string; oldAliases: string[]; newAliases: string[] }> = (props) => {
  const N = 3;
  const firstNNewAliases = props.newAliases.slice(0, N);
  const firstNOldAliases = props.oldAliases.slice(0, N);
  const restOfNewAliases = props.newAliases.slice(N);
  const restOfOldAliases = props.oldAliases.slice(N);

  const newAliasesAreEmpty = props.newAliases.length === 0;
  const oldAliasesAreEmpty = props.oldAliases.length === 0;

  return (
    <>
      <Text variant="large">Changes for {props.label}</Text>
      <Stack {...{ tokens: { childrenGap: 10 } }}>
        <Label style={{ fontWeight: "bold" }}>Previous {props.label}:</Label>
        {oldAliasesAreEmpty ? (
          <Text variant="small" style={{ fontStyle: "italic", marginTop: "0" }}>
            None
          </Text>
        ) : (
          <div style={{ marginTop: "0" }}>
            {firstNOldAliases.map((alias, index) => (
              <span className={tagStyles} key={index}>
                {alias}
              </span>
            ))}
            {restOfOldAliases.length !== 0 && (
              <TooltipHost content={restOfOldAliases.map((alias) => alias.trim().toUpperCase()).join(";")}>
                <span className={tagStyles}>+{restOfOldAliases.length}</span>
              </TooltipHost>
            )}
          </div>
        )}
      </Stack>
      <Stack {...{ tokens: { childrenGap: 10 } }}>
        <Label style={{ fontWeight: "bold" }}>New {props.label}:</Label>
        {newAliasesAreEmpty ? (
          <Text variant="small" style={{ fontStyle: "italic", marginTop: "0" }}>
            None
          </Text>
        ) : (
          <div style={{ marginTop: "0" }}>
            {firstNNewAliases.map((alias, index) => (
              <span className={tagStyles} key={index}>
                {alias}
              </span>
            ))}
            {restOfNewAliases.length !== 0 && (
              <TooltipHost content={restOfNewAliases.map((alias) => alias.trim().toUpperCase()).join(";")}>
                <span className={tagStyles}>+{restOfNewAliases.length}</span>
              </TooltipHost>
            )}
          </div>
        )}
      </Stack>
    </>
  );
};

const modalHeaderStyles = mergeStyles({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  fontWeight: FontWeights.semibold,
  padding: "16px",
  borderBottom: "1px solid #c8c8c8"
});

const modalCloseButtonStyles = mergeStyles({
  padding: "8px"
});

const modalContentStyles = mergeStyles("ms-Grid", {
  padding: "16px !important"
});

const buttonStyles = mergeStyles({
  marginLeft: "8px"
});

const buttonStackStyles = mergeStyles({
  marginTop: "16px",
  display: "flex",
  justifyContent: "flex-end"
});

const AliasChangeModal: React.FC<AliasChangeModalProps> = (props) => {
  const [hideModal, { toggle: toggleHideModal }] = useBoolean(true);
  const currentModalProps = React.useRef<AliasChangeModalContentProps>({
    newPosters: [],
    previousPosters: [],
    newReviewers: [],
    previousReviewers: []
  });

  useImperativeHandle(props.customRef, () => ({
    getUserSubmission: (props: AliasChangeModalContentProps) => {
      ModalController = new AsyncSubject<boolean>();
      currentModalProps.current = props;
      toggleHideModal();
      return ModalController;
    }
  }));

  const theProps = currentModalProps.current;

  return (
    <Modal
      isOpen={!hideModal}
      onDismiss={() => {
        if (ModalController) {
          ModalController.next(false);
          ModalController.complete();
          ModalController = null;
        }
        toggleHideModal();
      }}
      isBlocking={true}
      styles={{ main: { minWidth: "80%", display: "inline-block" } }}
    >
      <div className={modalHeaderStyles}>
        <Stack horizontal verticalAlign="center">
          <Text variant="large">Resetting checklist will modify Backup Posters and Backup Reviewers</Text>
        </Stack>
        <IconButton
          iconProps={{ iconName: "Cancel" }}
          ariaLabel="Close"
          className={modalCloseButtonStyles}
          onClick={() => {
            if (ModalController) {
              ModalController.next(false);
              ModalController.complete();
              ModalController = null;
            }
            toggleHideModal();
          }}
        />
      </div>
      <div className={modalContentStyles} dir="ltr">
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
            <AliasDiffDisplay label="Posters" oldAliases={theProps.previousPosters} newAliases={theProps.newPosters} />
          </div>
          <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
            <AliasDiffDisplay
              label="Reviewers"
              oldAliases={theProps.previousReviewers}
              newAliases={theProps.newReviewers}
            />
          </div>
        </div>
        <div className="ms-Grid-row">
          <div className={buttonStackStyles}>
            <PrimaryButton
              onClick={() => {
                if (ModalController) {
                  ModalController.next(true);
                  ModalController.complete();
                  ModalController = null;
                }
                toggleHideModal();
              }}
              text={"Yes"}
              className={buttonStyles}
            />
            <DefaultButton
              onClick={() => {
                if (ModalController) {
                  ModalController.next(false);
                  ModalController.complete();
                  ModalController = null;
                }
                toggleHideModal();
              }}
              text={"No"}
              className={buttonStyles}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

AliasChangeModal.displayName = "AliasChangeModal";

export { AliasChangeModal };
