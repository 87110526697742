import { css } from "@emotion/css";
import { Label, Text } from "@fluentui/react";
import React from "react";
import { labelStyles } from "../../../Shared/utilities/CommonStyles";
import { FullJeDetails } from "../JeDetails/JEDetails.schema";

export interface ICreateDetailsGetter {
  result: FullJeDetails;
}

export interface UIAuditDetails {
  props: {
    details: FullJeDetails;
  };
}

const JeAuditDetails: React.FC<UIAuditDetails["props"]> = (props) => {
  return (
    <React.Fragment>
      <div
        className={css`
          display: grid;
          grid-template-columns: 150px 150px 150px 150px 150px auto;
          grid-template-rows: auto auto auto;
          margin-bottom: 16px;
        `}
      >
        <div>
          <Label styles={labelStyles}>Doc Number</Label>
          <Text>{props.details.docNumber} </Text>
        </div>
        <div>
          <Label styles={labelStyles}>{"Poster's Org"}</Label>
          <Text>{props.details.postersOrg} </Text>
        </div>
        <div>
          <Label styles={labelStyles}>Classification</Label>
          <Text>{props.details.classification}</Text>
        </div>
        <div>
          <Label styles={labelStyles}>Fiscal Year/Period</Label>
          <Text>{props.details.FiscalYearAndPeriod}</Text>
        </div>
        <div>
          <Label styles={labelStyles}>Translation Date</Label>
          <Text>{props.details.translationDate}</Text>
        </div>
        <div>
          <Label styles={labelStyles}>Description</Label>
          <Text>{props.details.description}</Text>
        </div>

        <div>
          <Label styles={labelStyles}>Company Code</Label>
          <Text>{props.details.companyCode}</Text>
        </div>
        <div>
          <Label styles={labelStyles}>{"Reviewer's Org"}</Label>
          <Text>{props.details.reviewersOrg}</Text>
        </div>
        <div>
          <Label styles={labelStyles}>Recurring JE</Label>
          <Text>{props.details.recurringJE}</Text>
        </div>
        <div>
          <Label styles={labelStyles}>Reversal JE</Label>
          <Text>{props.details.reversalJE}</Text>
        </div>
        <div>
          <Label styles={labelStyles}>Review Due Date</Label>
          <Text>{props.details.dueDate}</Text>
        </div>
        <div>
          <Label styles={labelStyles}>Purpose</Label>
          <Text>{props.details.purpose}</Text>
        </div>

        <div>
          <Label styles={labelStyles}>Ref Number</Label>
          <Text>{props.details.refNumbber}</Text>
        </div>
        <div>
          <Label styles={labelStyles}>Significance</Label>
          <Text>{props.details.significance}</Text>
        </div>
        <div>
          <Label styles={labelStyles}>Recurring Year</Label>
          <Text>{props.details.recurringYear}</Text>
        </div>
        <div>
          <Label styles={labelStyles}>Reason Code </Label>
          <Text>{props.details.reasonCode}</Text>
        </div>
        <div>
          <Label styles={labelStyles}>Plan Reverse Date</Label>
          <Text>{props.details.planReversalDate}</Text>
        </div>
        <div>
          <Label styles={labelStyles}>Indicator</Label>
          <Text>{props.details.indicator}</Text>
        </div>
      </div>
    </React.Fragment>
  );
};
export { JeAuditDetails };
