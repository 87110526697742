import React from "react";
import { css } from "@emotion/css";
import { Breadcrumb, Shimmer, ShimmerElementType, Stack, Text, useTheme } from "@fluentui/react";
import { LoadingStatus, gridStyle } from "@jem/components";

export interface IJEMChecklistDetailsHeaderProps {
  onNavigateBack: () => void;
  loadingStatus: LoadingStatus;
  checklistName: string;
  checklistPeriod: string;
}

let JEMChecklistDetailsHeader: React.FC<IJEMChecklistDetailsHeaderProps> = (props) => {
  const theme = useTheme();

  return (
    <>
      <div
        className={css`
          padding: 16px;
          padding-top: 0px;
        `}
      >
        <Stack>
          <Stack.Item>
            <Breadcrumb
              styles={{
                root: {
                  margin: 0
                },
                item: {
                  fontSize: theme.fonts.medium.fontSize
                },
                itemLink: {
                  fontSize: theme.fonts.medium.fontSize,
                  color: theme.palette.themePrimary
                }
              }}
              items={[
                {
                  text: "JEM Checklists",
                  key: "a",
                  onClick: () => {
                    if (props.onNavigateBack) props.onNavigateBack();
                  }
                },
                {
                  text:
                    props.loadingStatus !== LoadingStatus.Resolved && props.loadingStatus !== LoadingStatus.Rejected
                      ? "..."
                      : `Checklist entries ${props.checklistName}`,
                  key: "b",
                  isCurrentItem: true
                }
              ]}
              maxDisplayedItems={3}
              ariaLabel="Breadcrumb with items rendered as links"
              overflowAriaLabel="More links"
            />
          </Stack.Item>
          <Stack.Item>
            <Stack horizontal {...{ tokens: gridStyle }}>
              <Stack.Item align="baseline">
                {props.loadingStatus !== LoadingStatus.Resolved && props.loadingStatus !== LoadingStatus.Rejected ? (
                  <>
                    <Shimmer shimmerElements={[{ type: ShimmerElementType.line, height: 60, width: "500px" }]} />
                  </>
                ) : (
                  <>
                    <Text
                      className={css`
                        display: block;
                        padding-top: 0 !important;
                        margin-top: 0 !important;
                        line-height: 2.25em;
                        & span {
                          font-size: 12px;
                          color: ${theme.palette.neutralSecondary};
                        }
                      `}
                      variant="xxLarge"
                    >
                      Checklist entries {props.checklistName}
                    </Text>
                  </>
                )}
              </Stack.Item>
              <Stack.Item align="baseline" grow>
                {props.loadingStatus !== LoadingStatus.Resolved && props.loadingStatus !== LoadingStatus.Rejected ? (
                  <>
                    <Shimmer width="200px" />
                  </>
                ) : (
                  <>
                    <Text
                      className={css`
                        display: block;
                        padding-top: 0 !important;
                        margin-top: 0 !important;
                      `}
                      variant="medium"
                    >
                      Period: {props.checklistPeriod}
                    </Text>
                  </>
                )}
              </Stack.Item>
            </Stack>
          </Stack.Item>
        </Stack>
      </div>
    </>
  );
};

JEMChecklistDetailsHeader = React.memo(JEMChecklistDetailsHeader, (prev, next) => {
  if (prev.loadingStatus !== next.loadingStatus) return false;
  if (prev.checklistName !== next.checklistName) return false;
  if (prev.checklistPeriod !== next.checklistPeriod) return false;
  return true;
});

JEMChecklistDetailsHeader.displayName = "JEMChecklistDetailsHeader";

export { JEMChecklistDetailsHeader };
