import React, { PropsWithChildren, useContext } from "react";
import { ActionResult, ActionTypes } from "../ActionManager/ActionsManager.types";
import { ActionsContext } from "./Actions.ActionsContext";
import { ActionTitle } from "./Actions.ActionTitle";
import { Submission } from "./Actions.BottomButtons";
import { ActionOnSubmitHandlerMap, BaseActionProps, CommonPropsOnRows } from "./Actions.types";

interface SendBackFromTreasury<ListType, ResultType> extends BaseActionProps<ListType, ResultType> {
  onSubmitHandler: ActionOnSubmitHandlerMap<ListType, ResultType>[ActionTypes.sendBackFromTreasury];
}

const GLDelete = <ListType extends CommonPropsOnRows, ResultType extends ActionResult>(
  props: PropsWithChildren<SendBackFromTreasury<ListType, ResultType>>
) => {
  const { styles } = useContext(ActionsContext);
  return (
    <>
      <ActionTitle onCloseButton={() => props.onCancel()} name={"Delete Action"}></ActionTitle>
      <div className={styles.contentStyles.body}>
        <Submission
          onSubmit={async function (type: "submit" | "cancel"): Promise<void> {
            if (type === "cancel") {
              props.onCancel();
            } else {
              const result = await props.onSubmitHandler(props.items);
              props.onSubmit(result);
            }
          }}
          SubmissionText={`Are you sure you want to delete ${props.items.length} JE${
            props.items.length > 1 ? "s" : ""
          }?`}
          submitButtonText={"Ok"}
        ></Submission>
      </div>
    </>
  );
};

GLDelete.displayName = "GLDelete";

export { GLDelete };
