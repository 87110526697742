import { BaseButton, Button, DefaultButton, Dropdown, IDropdownOption, PrimaryButton, Text } from "@fluentui/react";
import {
  JemConfiguration,
  JemNotification,
  IUserProviderState,
  postRequest,
  IHCCContext,
  CompanyCodeSingleSelectorRef,
  IFiscalPeriodsRef,
  ISignificanceRef,
  LoadingStatus,
  CompanyCodeSingleSelector,
  FiscalPeriodsDropdown,
  SignificanceDropdown
} from "@jem/components";

import React, { useContext, useEffect, useRef, useState } from "react";

import { sanitizeTreasuryRow } from "./TreasuryDashboard.requests";
import {
  convertToFiscalYearOptionTreasury,
  ITreasuryState,
  ITreasuryStateForApi,
  POStatusOptions,
  SanitizedTreasuryRow,
  TreaasuryDashboardApiPayload
} from "./TreasuryDashboard.types";
import { convertToTenantOptionSearch } from "../IHCCSearch/IHCCSearch.types";

interface ITreasuryDashboardFormProps {
  searchState: ITreasuryState | undefined;
  setGridItems: (searchResult: SanitizedTreasuryRow[], searchState: ITreasuryState) => void;
  setGridLoading: (value: boolean) => void;
  configuration: JemConfiguration["IhccApi"];
  triggerRefreshFn: (searchState: ITreasuryState, procStatusMessages: JemNotification) => void;
}

function payloadToTreasuryDashboardData(payload: TreaasuryDashboardApiPayload): SanitizedTreasuryRow[] {
  const sanitizeRows = payload.map(sanitizeTreasuryRow);
  return sanitizeRows;
}

export async function getGridItemsFromPayload(
  getTokenFn: IUserProviderState["accessToken"],
  { Status, Significance, CompanyCode, FiscalYears, FiscalPeriods, TenantId }: ITreasuryState,
  config: JemConfiguration["IhccApi"]
): Promise<SanitizedTreasuryRow[]> {
  const data: ITreasuryStateForApi = {
    POStatus: Status.includes(0) ? [15, 6, 5, 16, 10, 12, 7] : Status,
    POCategory: Significance,
    CompanyCode: CompanyCode,
    FiscalYear:
      FiscalYears.length === 0
        ? [new Date().getFullYear(), new Date().getFullYear() + 1]
        : FiscalYears.map((x) => Number(x)),
    FiscalPeriod: FiscalPeriods.length === 0 ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12] : FiscalPeriods,
    TenantId: TenantId === -1 ? null : TenantId
  };
  try {
    const url = `${config.baseApiUrl}${config.endpoints.getTreasuryDashboard}`;
    const gridData = await postRequest<TreaasuryDashboardApiPayload>(url, data, getTokenFn);
    if (gridData === null) {
      return [];
    }
    return payloadToTreasuryDashboardData(gridData);
  } catch (e) {
    // return empty for now
    console.error(e);
    return [];
  }
}

const TreasuryDashboardForm: React.FC<ITreasuryDashboardFormProps> = (props: ITreasuryDashboardFormProps) => {
  const { initInfo } = useContext(IHCCContext);

  const [fiscalYearsLoading, setFiscalYearsLoading] = useState(true);
  const [fiscalPeriodsLoading, setfiscalPeriodsLoading] = useState(true);
  const [tenantLoading, setTenantLoading] = useState(true);
  const companyCodesRef = useRef<CompanyCodeSingleSelectorRef>(null);
  const fiscalPeriodRef = useRef<IFiscalPeriodsRef>(null);
  const significanceRef = useRef<ISignificanceRef>(null);
  const [fiscalYears, setfiscalYears] = useState<ITreasuryState["FiscalYears"]>([0]);
  const [status, setStatus] = useState<ITreasuryState["Status"]>([12, 7]);
  const [tenant, setTenant] = useState<ITreasuryState["TenantId"]>(-1);

  const onChangeFiscalYear = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void => {
    if (option) {
      const keyIsAll = option.key === 0;
      if (keyIsAll) {
        if (option.selected) {
          // set the selected option to All only
          setfiscalYears([0]);
        } else {
          // if All was selected, then set it to everything else
          setfiscalYears([0]);
        }
      } else {
        // remove All from the selected options
        const interSelectedKeys = fiscalYears.filter((i) => i !== 0);
        setfiscalYears(
          option.selected
            ? [...interSelectedKeys, option.key as number]
            : interSelectedKeys.filter((key) => key !== option.key)
        );
      }
    }
  };

  const onChangeStatus = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void => {
    if (option) {
      const keyIsAll = option.key === 0;
      if (keyIsAll) {
        if (option.selected) {
          // set the selected option to All only
          setStatus([0]);
        } else {
          // if All was selected, then set it to everything else
          setStatus([0]);
        }
      } else {
        // remove All from the selected options
        const interSelectedKeys = status.filter((i) => i !== 0);
        setStatus(
          option.selected
            ? [...interSelectedKeys, option.key as number]
            : interSelectedKeys.filter((key) => key !== option.key)
        );
      }
    }
  };
  const onChangeTenant = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void => {
    if (option) {
      setTenant(option.key as number);
    }
  };

  const reset = () => {
    setStatus([12, 7]);
    setfiscalYears([0]);
    setTenant(-1);
    if (companyCodesRef.current) {
      companyCodesRef.current.reset();
    }
    if (fiscalPeriodRef.current) {
      fiscalPeriodRef.current.reset();
    }
    if (significanceRef.current) {
      significanceRef.current.reset();
    }
  };

  useEffect(() => {
    submit();
  }, [initInfo.values]);

  const submit = async (
    ev?: React.MouseEvent<
      HTMLDivElement | HTMLAnchorElement | HTMLButtonElement | BaseButton | Button | HTMLSpanElement
    >
  ) => {
    if (ev) ev.preventDefault();
    props.setGridLoading(true);
    if (initInfo.values && companyCodesRef.current && fiscalPeriodRef.current && significanceRef.current) {
      const CompanyCode = companyCodesRef.current.getCompanyCode();
      const FiscalPeriods = fiscalPeriodRef.current.getFiscalPeriods();
      const Significance = significanceRef.current.getSignificance();
      const currentFiscalYear = initInfo.values.currentFiscalYear;
      const currentState: ITreasuryState = {
        Status: status,
        Significance: Significance.includes("all") ? ["I", "L", "N", "O", "X"] : Significance,
        CompanyCode: CompanyCode || 0,
        FiscalYears:
          fiscalYears.includes(0) && currentFiscalYear ? [currentFiscalYear - 1, currentFiscalYear] : fiscalYears,
        FiscalPeriods: FiscalPeriods.includes(0) ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12] : FiscalPeriods,
        TenantId: tenant
      };
      props.triggerRefreshFn(currentState, {
        subjectHeader: " ",
        summaryBodyText: " ",
        type: "Information"
      });
    }
  };

  useEffect(() => {
    if (initInfo.status === LoadingStatus.Resolved) {
      setFiscalYearsLoading(false);
      setfiscalPeriodsLoading(false);
      setTenantLoading(false);
    }
  }, [initInfo.status]);

  return (
    <React.Fragment>
      <div className="ms-Grid" dir="ltr">
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm3">
            <CompanyCodeSingleSelector ref={companyCodesRef} context={"IHCC"} />
          </div>
          <div className="ms-Grid-col ms-sm3">
            <Dropdown
              label="Fiscal Year"
              selectedKeys={fiscalYears}
              onChange={onChangeFiscalYear}
              options={convertToFiscalYearOptionTreasury(initInfo.values?.fiscalYears.map((x) => x) || [])}
              disabled={fiscalYearsLoading}
              multiSelect={true}
            />
            <Text
              nowrap
              variant="xSmall"
              block
              style={{
                marginTop: "4px",
                color: "var(--accent-font-color, black)"
              }}
            >
              Multiple fiscal years enabled. Separate by semicolons.
            </Text>
          </div>
          <div className="ms-Grid-col ms-sm3">
            <FiscalPeriodsDropdown ref={fiscalPeriodRef} loading={fiscalPeriodsLoading} />
          </div>
        </div>
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm3">
            <Dropdown
              label="PO Status"
              selectedKeys={status}
              onChange={onChangeStatus}
              options={POStatusOptions}
              multiSelect
            />
            <Text
              nowrap
              variant="xSmall"
              block
              style={{
                marginTop: "4px",
                color: "var(--accent-font-color, black)"
              }}
            >
              Multiple statuses enabled. Separate by semicolons.
            </Text>
          </div>
          <div className="ms-Grid-col ms-sm3">
            <SignificanceDropdown ref={significanceRef} loading={false} />
          </div>
          <div className="ms-Grid-col ms-sm3">
            <Dropdown
              label="Tenant"
              selectedKey={tenant}
              onChange={onChangeTenant}
              options={convertToTenantOptionSearch(initInfo.values?.tenants.map((x) => x) || [])}
              disabled={tenantLoading}
            />
          </div>
        </div>
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm6">
            <PrimaryButton
              text="Search"
              allowDisabledFocus
              style={{ margin: "55px 0 0 8px" }}
              onClick={submit}
              type="submit"
              disabled={false}
            />
            <DefaultButton text="Reset" allowDisabledFocus style={{ margin: "55px 0 0 8px" }} onClick={reset} />
          </div>
          <div className="ms-Grid-col ms-sm6"></div>
        </div>
      </div>
    </React.Fragment>
  );
};

TreasuryDashboardForm.displayName = "TreasuryDashboardForm";

export default TreasuryDashboardForm;
