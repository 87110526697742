import React, { useEffect, useState } from "react";
import { Stack, Text, BaseButton, Button, DefaultButton } from "@fluentui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { ManualAutomatedJePivot, PivotOption } from "@jem/components";

interface IGLDashboardHeaderProps {
  currentDashboardType: (currentTile: PivotOption) => void;
  onRefresh: () => void;
  lastRefreshed: string;
  hidden: boolean;
  disabled: boolean;
}

const GLDashboardHeader: React.FC<IGLDashboardHeaderProps> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [automatedJE, setAutomatedJE] = useState<PivotOption>("manualJe");

  const navigateCallback = (
    event: React.MouseEvent<
      HTMLDivElement | BaseButton | Button | HTMLAnchorElement | HTMLButtonElement | HTMLSpanElement,
      MouseEvent
    >
  ) => {
    event.preventDefault();
    navigate("/gl/create", {
      state: {
        origin: location.pathname,
        state: location.state
      }
    });
  };

  useEffect(() => {
    props.currentDashboardType(automatedJE);
  }, [automatedJE]);

  return (
    <Stack
      horizontalAlign="start"
      verticalAlign="start"
      styles={{
        root: {
          width: "100%",
          padding: "0"
        }
      }}
    >
      <Stack
        horizontal
        verticalAlign="center"
        horizontalAlign="space-between"
        wrap
        tokens={{ childrenGap: 10 }}
        styles={{
          root: {
            width: "100%"
          }
        }}
      >
        <Stack.Item grow align="stretch">
          <h2>JE Dashboard</h2>
        </Stack.Item>
        {!props.hidden && (
          <>
            {props.lastRefreshed && (
              <Stack.Item align="end">
                <Text variant="small">Last Refreshed: {props.lastRefreshed}</Text>
              </Stack.Item>
            )}
            <Stack.Item align="end">
              <DefaultButton
                iconProps={{ iconName: "Refresh" }}
                ariaLabel="Refresh"
                onClick={props.onRefresh}
                disabled={props.disabled}
              ></DefaultButton>
            </Stack.Item>
          </>
        )}
        <Stack.Item align="end">
          <Stack horizontalAlign="center" tokens={{ childrenGap: 5 }}>
            <Stack.Item>
              <Text variant="small">Quickstart</Text>
            </Stack.Item>
            <Stack.Item>
              <DefaultButton
                iconProps={{ iconName: "Add" }}
                ariaLabel="Create New Journal Entry"
                onClick={navigateCallback}
              >
                Create New Journal Entry
              </DefaultButton>
            </Stack.Item>
          </Stack>
        </Stack.Item>
      </Stack>
      <ManualAutomatedJePivot
        onChange={function (opts: string) {
          setAutomatedJE(opts as PivotOption);
        }}
        selectedPivot={automatedJE}
      ></ManualAutomatedJePivot>
    </Stack>
  );
};

GLDashboardHeader.displayName = "GLDashboardHeader";
export default GLDashboardHeader;
