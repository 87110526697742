import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { JemConfiguration } from "../../../JemConfiguration";
import { IUserProviderState } from "../../../Shared/utilities/RequestUtilities";
import { RequireAtLeastOne } from "../../../Shared/utilities/TypeUtils";
import { LoadingStatus } from "../../../Shared/utilities/Utilities";

export async function ihccLoader<T>(
  getTokenFn: IUserProviderState["accessToken"],
  appInsights: ApplicationInsights,
  configuration: JemConfiguration,
  fetcherFunction: (configuration: JemConfiguration, getTokenFn: IUserProviderState["accessToken"]) => Promise<T>,
  loadingDispatch: React.Dispatch<React.SetStateAction<LoadingStatus>>,
  valuesDispatch: React.Dispatch<React.SetStateAction<T>>
) {
  loadingDispatch(LoadingStatus.Pending);
  try {
    const apiData = await fetcherFunction(configuration, getTokenFn);
    valuesDispatch(apiData);
    loadingDispatch(LoadingStatus.Resolved);
  } catch (e) {
    if (e instanceof Error) {
      appInsights.trackException({
        exception: e
      });
    } else if (typeof e === "string") {
      appInsights.trackException({
        exception: new Error(e)
      });
    } else {
      appInsights.trackException({
        exception: new Error("Unknown exception in IHCCContext loader")
      });
    }
    loadingDispatch(LoadingStatus.Rejected);
  }
}

export interface FromReducer<T> {
  status: LoadingStatus;
  values: T;
}

export async function ihccLoaderFromReducer<T>(
  getTokenFn: IUserProviderState["accessToken"],
  appInsights: ApplicationInsights,
  configuration: JemConfiguration,
  fetcherFunction: (configuration: JemConfiguration, getTokenFn: IUserProviderState["accessToken"]) => Promise<T>,
  stateReducer: React.Dispatch<RequireAtLeastOne<FromReducer<T>, keyof FromReducer<T>>>
): Promise<T | undefined> {
  stateReducer({
    status: LoadingStatus.Pending
  });
  try {
    const apiData = await fetcherFunction(configuration, getTokenFn);
    stateReducer({
      status: LoadingStatus.Resolved,
      values: apiData
    });
    return apiData;
  } catch (e) {
    if (e instanceof Error) {
      appInsights.trackException({
        exception: e
      });
    } else if (typeof e === "string") {
      appInsights.trackException({
        exception: new Error(e)
      });
    } else {
      appInsights.trackException({
        exception: new Error("Unknown exception in IHCCContext loader")
      });
    }
    stateReducer({
      status: LoadingStatus.Rejected
    });
  }
  return;
}
