const startMonthDayOfFiscalYear = "07/01";

export const currentFyFpByMonth = (
  month: number,
  currentYear: number
): {
  fiscalYear: number;
  fiscalMonth: number;
} => {
  const yearOffset = month <= 6 ? 0 : 1;
  const currentFiscalYear = currentYear + yearOffset;
  const startOfFiscalYear = new Date(Date.parse(`${startMonthDayOfFiscalYear}/${currentFiscalYear}`));
  const firstMonth = startOfFiscalYear.getMonth() + 1; // Zero Based
  let fiscalMonth = ((month - firstMonth) % 12) + 1;
  if (fiscalMonth <= 0) {
    fiscalMonth = fiscalMonth + 12;
  }
  return {
    fiscalYear: startOfFiscalYear.getFullYear(),
    fiscalMonth: fiscalMonth
  };
};

export const currentFyFp = (): {
  fiscalYear: number;
  fiscalMonth: number;
} => {
  const today = new Date();
  const currentMonth = today.getMonth(); // Zero Based month
  return currentFyFpByMonth(currentMonth + 1, today.getFullYear());
};

/**
 * @description Using a FiscalMonth and FiscalYear, calculate the
 * Calendar Month and Calendar Year that the FiscalMonth falls in.
 * @param fiscalMonth
 * @param fiscalYear
 * @returns {
 *   calendarMonth: number;
 *   calendarYear: number;
 * }
 */
export function calculateCalendarMonthAndYear(
  fiscalMonth: number,
  fiscalYear: number
): { calendarMonth: number; calendarYear: number } {
  const firstMonth = new Date(Date.parse(`${startMonthDayOfFiscalYear}/${fiscalYear}`));
  const firstMonthOfFiscalYear = firstMonth.getMonth() + 1; // Zero Based
  const calendarMonth = ((fiscalMonth - firstMonthOfFiscalYear) % 12) + 1;
  if (calendarMonth <= 0) {
    return {
      calendarMonth: calendarMonth + 12,
      calendarYear: fiscalYear - 1
    };
  }
  return {
    calendarMonth: calendarMonth,
    calendarYear: fiscalYear
  };
}

export const fyFp = currentFyFp();
