import { mergeStyles, TooltipHost } from "@fluentui/react";
import React from "react";
import { utils, writeFile } from "xlsx";

export interface ExportToExcelOptions<ListType> {
  sheetName: string;
  rowsWithHeader: ListType[][];
  fileName: string;
}

export function exportToExcel<ListType>({ sheetName, rowsWithHeader, fileName }: ExportToExcelOptions<ListType>): void {
  const workSheetName = sheetName;
  const workBook = utils.book_new();
  const workSheet = utils.aoa_to_sheet(rowsWithHeader);
  utils.book_append_sheet(workBook, workSheet, workSheetName);
  writeFile(workBook, fileName);
}

const tagStyles = mergeStyles({
  display: "inline-block",
  backgroundColor: "#f3f3f3",
  borderRadius: "3px",
  padding: "2px 4px",
  margin: "2px"
});

export function onRenderArray(arr: string[]): JSX.Element {
  const firstCompnayCode = arr.slice(0, 1);
  const restOfCompanyCodes = arr.slice(1);
  return (
    <>
      <span className={tagStyles}>{firstCompnayCode}</span>
      {restOfCompanyCodes.length > 0 && (
        <TooltipHost content={restOfCompanyCodes.join(";")}>
          <span className={tagStyles}>+{restOfCompanyCodes.length}</span>
        </TooltipHost>
      )}
    </>
  );
}
