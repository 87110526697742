import { Pivot, PivotItem, Stack } from "@fluentui/react";
import { JemConfiguration } from "@jem/components";
import React from "react";

export interface ChecklistCreateResponse {
  checklistName: string;
  checklistPath: string;
  errorCode: string;
  refGuid: string;
  templateID: number;
}

export enum JEMChecklistDashboardOptions {
  SearchByOpsDetail = "opsDetail",
  SearchByCompanyCode = "companyCode"
}

const JEMChecklistDashboardHeader: React.FC<{
  configuration: JemConfiguration["GeneralLedgerApi"];
  onTabChange: (tab: JEMChecklistDashboardOptions) => void;
  currentTab: JEMChecklistDashboardOptions;
}> = (props) => {
  return (
    <>
      <Stack
        horizontal
        verticalAlign="center"
        horizontalAlign="space-between"
        wrap
        tokens={{ childrenGap: 20 }}
        styles={{
          root: {
            width: "100%"
          }
        }}
      >
        <Stack.Item grow align="stretch">
          <h2>JEM Checklist Dashboard</h2>
        </Stack.Item>
        <Stack.Item grow align="end">
          <Pivot
            aria-label="JEM Checklist Dashboard Selector"
            selectedKey={props.currentTab}
            onLinkClick={(item?: PivotItem) => {
              if (item !== undefined) {
                const tileName = item.props.itemKey as JEMChecklistDashboardOptions;
                props.onTabChange(tileName);
              }
            }}
            styles={{
              root: {
                float: "right"
              }
            }}
          >
            <PivotItem
              headerText="Search by Ops Detail"
              itemKey={JEMChecklistDashboardOptions.SearchByOpsDetail}
              alwaysRender={false}
            ></PivotItem>
            <PivotItem
              headerText="Search by Company Code"
              itemKey={JEMChecklistDashboardOptions.SearchByCompanyCode}
              alwaysRender={false}
            ></PivotItem>
          </Pivot>
        </Stack.Item>
      </Stack>
    </>
  );
};

JEMChecklistDashboardHeader.displayName = "JEMChecklistDashboardHeader";
export default JEMChecklistDashboardHeader;
