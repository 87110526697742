import { CommandBar, ICommandBarItemProps } from "@fluentui/react";
import { GridCommandToggle, isButtonEnabled, LoadingStatus } from "@jem/components";
import React, { useEffect, useState } from "react";
import { ActionsModel, DetailsPageModel } from "../../contexts/IHCCPODetailsContext/IHCCPODetailsContext.types";

// eslint-disable-next-line
const isEqual = require("lodash/isEqual");

export enum PODetailsActionsEnum {
  Save = "Save",
  SaveSAPInfo = "SaveSAPInfo",
  ReleaseForSignOff = "ReleaseForSignOff",
  SignOff = "SignOff",
  NeedClarification = "NeedClarification",
  Reverse = "Reverse",
  Attach = "Attach"
}

export type PODetailsActions = {
  [key in PODetailsActionsEnum]: GridCommandToggle;
};

export type PODetailsActionsMapping = {
  [key: string]: PODetailsActionsEnum;
};

export interface IHCCPODetailsActionsBarProps {
  onAction: (actionName: PODetailsActionsEnum) => void;
  setSaveButtonDisabledStatus: (value: boolean) => void;
  detailsPageModel: DetailsPageModel;
  actionsModel: ActionsModel;
  loadingStatus: LoadingStatus;
  addAttachmentEnabled: boolean;
}

const actionFlags: PODetailsActions = {
  [PODetailsActionsEnum.Save]: GridCommandToggle.Disabled,
  [PODetailsActionsEnum.SaveSAPInfo]: GridCommandToggle.Disabled,
  [PODetailsActionsEnum.ReleaseForSignOff]: GridCommandToggle.Disabled,
  [PODetailsActionsEnum.SignOff]: GridCommandToggle.Disabled,
  [PODetailsActionsEnum.NeedClarification]: GridCommandToggle.Disabled,
  [PODetailsActionsEnum.Reverse]: GridCommandToggle.Disabled,
  [PODetailsActionsEnum.Attach]: GridCommandToggle.Enabled
};

const PODetailsActionsMapping: {
  [key: string]: PODetailsActionsEnum;
} = {
  Save: PODetailsActionsEnum.Save,
  SaveSapInfo: PODetailsActionsEnum.SaveSAPInfo,
  InitReview: PODetailsActionsEnum.ReleaseForSignOff,
  Approve: PODetailsActionsEnum.SignOff,
  Clarify: PODetailsActionsEnum.NeedClarification,
  ReversalPO: PODetailsActionsEnum.Reverse
};

function calculateButtonStates(detailsModel: DetailsPageModel, actions: ActionsModel): PODetailsActions {
  const poWeightage = detailsModel.weightage;
  const newActionFlags = { ...actionFlags };

  for (const actionObject of actions) {
    const actionName = actionObject.action;
    if (!(actionName in PODetailsActionsMapping)) {
      continue;
    }

    const buttonWeightage = actionObject.weightage;
    const flagState = isButtonEnabled(buttonWeightage, poWeightage);
    const actionNameOnModel = PODetailsActionsMapping[actionName as keyof typeof PODetailsActionsMapping];
    if (flagState) {
      newActionFlags[actionNameOnModel] = GridCommandToggle.Enabled;
    }
  }
  return newActionFlags;
}

const actionsForUI = (
  actionsState: PODetailsActions,
  onAction: (actionName: PODetailsActionsEnum) => any,
  addAttachmentEnabled: boolean
) => {
  const attachFileButtonName = !addAttachmentEnabled ? "Attach File" : "View File";
  return [
    {
      key: "save",
      name: "Save",
      iconProps: { iconName: "Save" },
      onClick: () => onAction(PODetailsActionsEnum.Save),
      disabled: actionsState.Save === GridCommandToggle.Disabled
    },
    {
      key: "saveSAPInfo",
      name: "Save SAP info",
      iconProps: { iconName: "Save" },
      onClick: () => onAction(PODetailsActionsEnum.SaveSAPInfo),
      disabled: actionsState.SaveSAPInfo === GridCommandToggle.Disabled
    },
    {
      key: "releaseForSignOff",
      name: "Release for Sign-off",
      iconProps: { iconName: "PublishContent" },
      onClick: () => onAction(PODetailsActionsEnum.ReleaseForSignOff),
      disabled: actionsState.ReleaseForSignOff === GridCommandToggle.Disabled
    },
    {
      key: "signOff",
      name: "Sign-off",
      iconProps: { iconName: "PublishContent" },
      onClick: () => onAction(PODetailsActionsEnum.SignOff),
      disabled: actionsState.SignOff === GridCommandToggle.Disabled
    },
    {
      key: "needClarification",
      name: "Reject",
      iconProps: { iconName: "StatusCircleQuestionMark" },
      onClick: () => onAction(PODetailsActionsEnum.NeedClarification),
      disabled: actionsState.NeedClarification === GridCommandToggle.Disabled
    },
    {
      key: "reversePo",
      name: "Reverse",
      iconProps: { iconName: "Undo" },
      onClick: () => onAction(PODetailsActionsEnum.Reverse),
      disabled: actionsState.Reverse === GridCommandToggle.Disabled
    },
    {
      key: "attachFile",
      name: attachFileButtonName,
      iconProps: { iconName: "Attach" },
      onClick: () => onAction(PODetailsActionsEnum.Attach)
      // disabled:  actionNameToFlag.Attach
    }
  ] as ICommandBarItemProps[];
};

let IHCCPODetailsActionsBar: React.FC<IHCCPODetailsActionsBarProps> = (props) => {
  const [actionsState, setActionsState] = useState<PODetailsActions>(actionFlags);

  useEffect(() => {
    if (props.loadingStatus === LoadingStatus.Resolved) {
      const newActionFlags = calculateButtonStates(props.detailsPageModel, props.actionsModel);
      setActionsState(newActionFlags);
      props.setSaveButtonDisabledStatus(newActionFlags.Save === GridCommandToggle.Disabled);
    }
  }, [props.loadingStatus, props.detailsPageModel, props.actionsModel]);

  const onAction = (actionName: PODetailsActionsEnum) => {
    props.onAction(actionName);
  };

  const [actions, setActions] = useState<ICommandBarItemProps[]>(
    actionsForUI(actionsState, onAction, props.addAttachmentEnabled)
  );

  useEffect(() => {
    setActions(actionsForUI(actionsState, onAction, props.addAttachmentEnabled));
  }, [actionsState]);

  return (
    <>
      <CommandBar
        items={actions}
        styles={{
          root: {
            paddingLeft: 0
          }
        }}
      />
    </>
  );
};

IHCCPODetailsActionsBar = React.memo(IHCCPODetailsActionsBar, (prevProps, nextProps) => {
  if (prevProps.loadingStatus !== nextProps.loadingStatus) {
    return false;
  }
  if (!isEqual(prevProps.detailsPageModel, nextProps.detailsPageModel)) {
    return false;
  }
  if (!isEqual(prevProps.actionsModel, nextProps.actionsModel)) {
    return false;
  }
  return true;
});

IHCCPODetailsActionsBar.displayName = "IHCCPODetailsActionsBar";
export default IHCCPODetailsActionsBar;
