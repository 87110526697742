import React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { BaseRouteProps } from "@jem/components";
import JEMChecklistDashboardPage from "./JEMChecklist/pages/JEMChecklistDashboard/JEMChecklistDashboardPage";
import JEMChecklistDetailsPage from "./JEMChecklist/pages/JEMChecklistDetails/JEMChecklistDetailsPage";

const JEMChecklist: React.FC<BaseRouteProps> = (props) => {
  const navigate = useNavigate();
  return (
    <>
      <Routes>
        <Route
          path="/dashboard"
          element={
            <JEMChecklistDashboardPage
              configuration={props.config.GeneralLedgerApi}
              attachmentsConfiguration={props.config.DocumentsApi}
              fcwCreateTaskUrl={props.config.FCWNewJemChecklistLink}
            ></JEMChecklistDashboardPage>
          }
        />
        <Route
          path="/details"
          element={
            <JEMChecklistDetailsPage
              configuration={props.config}
              onNavigateBack={() => {
                navigate(-1);
              }}
            />
          }
        />
      </Routes>
    </>
  );
};

JEMChecklist.displayName = "JEMChecklist";
export default JEMChecklist;
