import React, { useContext, useState } from "react";
import { useTheme } from "@fluentui/react";
import { OrderedMap } from "immutable";
import {
  DomainDataObjects,
  FatalError,
  GenericActionButtonCalculator,
  GLDashboardRow,
  IIndexedTile,
  LoadingStatus,
  SanitizedDashboardRow,
  UserContext,
  JemConfiguration,
  GeneralLedgerAction,
  ErrorHelper,
  ErrorMessages,
  LoggingContext,
  RefetchButton,
  useQuery
} from "@jem/components";
import { useLocation } from "react-router-dom";
import { getGLColumns } from "../../GLDashboard/GLDashboard.Columns";
import { fetchBatchSearchDetailsResults } from "./GLBatchSearchJEResults.requests";

import GLDashboard from "../../GLDashboard/GLDashboard";

export interface GLBatchSearchJEResultsProps {
  configuration: JemConfiguration["GeneralLedgerApi"];
  attachmentsConfiguration: JemConfiguration["DocumentsApi"];
  domainData: DomainDataObjects;
  batchName: string;
  refGuid: string;
}

const GLBatchSearchJEResults: React.FC<GLBatchSearchJEResultsProps> = (props) => {
  const userContext = useContext(UserContext);
  if (!userContext) {
    throw new FatalError("Please use a UserContext Provider.");
  }
  const logger = useContext(LoggingContext);
  const theme = useTheme();
  const location = useLocation();
  const [processingStatus, setProcessingStatus] = useState<GeneralLedgerAction | null>(null);

  const {
    data: pageState,
    error,
    isFetching,
    isLoading,
    dataUpdatedAt,
    refetch
  } = useQuery({
    queryKey: ["GLBatchSingleBatchSearch", props.refGuid],
    queryFn: () =>
      /*fetchJESearchResults*/
      fetchBatchSearchDetailsResults(props.configuration, userContext.accessToken, props.refGuid),
    onSuccess: (data) => {
      if (!processingStatus) {
        return data;
      }

      // iterate processingStatus.changes and update gridData, jeId in gridData is a string, but in changes it is a number
      const gridDataCopy = [...data];
      for (const change of processingStatus.changes) {
        const index = gridDataCopy.findIndex((row) => row.jeId === change.jeId.toString());
        if (index !== -1) {
          const errorMessage = ErrorHelper.getMessage(change.errorCode as keyof typeof ErrorMessages);
          gridDataCopy[index].processingStatus = errorMessage;
          gridDataCopy[index].procStatType =
            change.errorCode && change.errorCode.indexOf("ERR") !== -1 ? "Error" : "Success";
        }
      }
      return gridDataCopy;
    },
    enabled: props.refGuid !== "",
    // set stale to 1 hour
    staleTime: 1000 * 60 * 60
  });

  const loadingStatus =
    isLoading || isFetching
      ? LoadingStatus.Pending
      : error !== undefined
      ? LoadingStatus.Rejected
      : LoadingStatus.Resolved;
  const gridItems = pageState ? (pageState as SanitizedDashboardRow[]) : [];

  return (
    <>
      <RefetchButton
        lastRefreshed={dataUpdatedAt}
        onRefetch={() => {
          refetch();
        }}
        disabled={loadingStatus === LoadingStatus.Pending}
      />
      <GLDashboard
        configuration={props.configuration}
        attachmentsConfiguration={props.attachmentsConfiguration}
        items={gridItems as GLDashboardRow[]}
        tilesAreLoading={LoadingStatus.Resolved}
        dataIsLoading={loadingStatus}
        uniqueIdForDashboardsColumnGenerator={"glBatchSearchDashboardConfig"}
        columnGenerator={getGLColumns(location, theme)}
        theIndexFromTheColumnsThatIsInitallySortedWith={14}
        tiles={OrderedMap<string, IIndexedTile>({})}
        buttonCalculator={GenericActionButtonCalculator<GLDashboardRow>(props.domainData, userContext.jemUser.roles)}
        domainData={props.domainData}
        onAction={(action) => {
          logger.addNotification(action.notification);
          setProcessingStatus(action.response);
          refetch();
        }}
      />
    </>
  );
};

GLBatchSearchJEResults.displayName = "GLBatchSearchJEResults";
export default GLBatchSearchJEResults;
