import React from "react";
import { Link } from "react-router-dom";
import { TooltipHost } from "@fluentui/react";

import { OpsDashboardRequestsElement } from "./RequestsGrid.types";
import { DEFAULT_NULL_CHARACTER, IExtendedColumn } from "@jem/components";

interface LinkToDetailsProps {
  rowKey: string;
}

let LinkToDetails: React.FC<LinkToDetailsProps> = (props) => (
  <Link
    to={`/ops/dashboard/requests/${props.rowKey}`}
    state={{
      origin: "/ops/dashboard",
      state: null
    }}
  >
    {props.rowKey}
  </Link>
);

LinkToDetails = React.memo(LinkToDetails, (prevProps, nextProps) => {
  if (prevProps.rowKey !== nextProps.rowKey) return false;
  return true;
});
LinkToDetails.displayName = "LinkToDetails";

export const getOpsDashboardRequestsColumns = () => (): IExtendedColumn[] => {
  const cols: IExtendedColumn[] = [
    {
      key: "ID",
      name: "ID",
      fieldName: "rowKey",
      type: "string",
      minWidth: 240,
      onRender: (gridItem: OpsDashboardRequestsElement) => <LinkToDetails rowKey={gridItem.rowKey} />
    },
    {
      key: "Type",
      name: "Action Type",
      fieldName: "title",
      type: "string",
      minWidth: 120
    },
    {
      key: "CreatedDate",
      name: "Created Date",
      fieldName: "createdDate",
      type: "string",
      minWidth: 95,
      sortDescendingAriaLabel: "Sort by most recently created",
      onRender: (item: OpsDashboardRequestsElement) => {
        if (item.createdDate === DEFAULT_NULL_CHARACTER || !item.createdDate) {
          return DEFAULT_NULL_CHARACTER;
        }
        return <TooltipHost content={item.createdDate}>{item.createdDate}</TooltipHost>;
      }
    },
    {
      key: "ReviewDate",
      name: "Review Date",
      fieldName: "reviewDate",
      type: "string",
      minWidth: 95,
      sortDescendingAriaLabel: "Sorted by most recently reviewed",
      onRender: (item: OpsDashboardRequestsElement) => {
        if (item.reviewDate === DEFAULT_NULL_CHARACTER || !item.reviewDate) {
          return DEFAULT_NULL_CHARACTER;
        }
        return <TooltipHost content={item.reviewDate}>{item.reviewDate}</TooltipHost>;
      }
    },
    {
      key: "CreatedBy",
      name: "Created By",
      fieldName: "createdBy",
      type: "string",
      minWidth: 80
    },
    {
      key: "Status",
      name: "Status",
      fieldName: "status",
      type: "string",
      minWidth: 50
    },
    {
      key: "ReviewedBy",
      name: "Reviewed By",
      fieldName: "approvedBy",
      type: "string",
      minWidth: 90
    }
  ];

  return cols;
};
