import { css } from "@emotion/css";
import { Label, SelectionMode, Text } from "@fluentui/react";
import { FormikErrors } from "formik";
import React, { useRef } from "react";
import { For } from "react-loops";
import { DashboardGrid } from "../../../../Shared/components/DashboardGrid/DashboardGrid";
import { IDashboardGridRef, IExtendedColumn } from "../../../../Shared/components/DashboardGrid/DashboardGrid.types";
import { exportToExcel } from "../../../../Shared/components/DashboardGrid/DashboardGrid.utilities";
import { LoadingStatus } from "../../../../Shared/utilities/Utilities";

import { ErrorListModel, GLCreateFormikState, GLCreateState } from "../GLCreateForm.types";

interface GLCreateErrorColumn extends IExtendedColumn {
  fieldName: keyof GLCreateState["errorTable"][number];
}

export const getErrorColumns = (): IExtendedColumn[] => {
  const cols: GLCreateErrorColumn[] = [
    {
      key: "rowNumber",
      name: "Row",
      fieldName: "rowNumber",
      type: "number",
      minWidth: 50
    },
    {
      key: "description",
      name: "Description",
      fieldName: "description",
      type: "string",
      minWidth: 350,
      isMultiline: true
    }
  ];

  return cols;
};

let GLErrorDetailsTab: React.FC<{
  loadingStatus: LoadingStatus;
  errors: FormikErrors<GLCreateFormikState>;
  genericHeaderErrors: string[];
  lineItemErrors: ErrorListModel[];
}> = (props) => {
  const dashboardGridRef = useRef<IDashboardGridRef>(null);

  const errorValues = Object.values(props.errors);
  const genericHeaderErrors = props.genericHeaderErrors.filter((error) => {
    return errorValues.indexOf(error) === -1;
  });
  return (
    <>
      <div className="ms-Grid" dir="ltr">
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm5">
            <div className="ms-Grid" dir="ltr">
              <div className="ms-Grid-row">
                <Label>Header Errors</Label>
              </div>
              <div className="ms-Grid-row">
                <ul
                  className={css`
                    padding-left: 10px;
                    list-style-type: none;
                  `}
                  data-is-scrollable="true"
                >
                  <For
                    of={genericHeaderErrors}
                    as={(item) => (
                      <li>
                        <Text variant="smallPlus">{item}</Text>
                      </li>
                    )}
                  />

                  <For
                    in={props.errors}
                    as={(item) => (
                      <li>
                        <Text variant="smallPlus">{item}</Text>
                      </li>
                    )}
                  />
                </ul>
              </div>
            </div>
          </div>
          <div className="ms-Grid-col ms-sm7">
            <div className="ms-Grid" dir="ltr">
              <div className="ms-Grid-row">
                <Label>Speadsheet Errors</Label>
              </div>
              <div className="ms-Grid-row">
                <div
                  className={css`
                    border-left: 2px solid;
                    padding-left: 10px;
                  `}
                  data-is-scrollable="true"
                >
                  <DashboardGrid
                    idForLocalStorage={"glCreateSpreadsheetErrors"}
                    customRef={dashboardGridRef}
                    columnGenerator={getErrorColumns}
                    items={props.lineItemErrors}
                    isSortedIndex={0}
                    height={`200px`}
                    isSortedDescending={false}
                    selectionMode={SelectionMode.multiple}
                    onExport={(rows) => {
                      exportToExcel({
                        sheetName: "spreadsheetErrors",
                        rowsWithHeader: rows,
                        fileName: "spreadsheetErrors.xlsx"
                      });
                    }}
                    disablePagination={true}
                  ></DashboardGrid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

GLErrorDetailsTab.displayName = "GLErrorDetailsTab";

GLErrorDetailsTab = React.memo(GLErrorDetailsTab, (prevProps, nextProps) => {
  if (prevProps.loadingStatus !== nextProps.loadingStatus) {
    return false;
  }
  if (prevProps.genericHeaderErrors !== nextProps.genericHeaderErrors) {
    return false;
  }
  if (prevProps.lineItemErrors !== nextProps.lineItemErrors) {
    return false;
  }
  return true;
});
export { GLErrorDetailsTab };
