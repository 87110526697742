import { JemConfiguration, IUserProviderState, getValidUrl, getRequest, ApiError } from "@jem/components";
import { JEMChecklistDashboardRow, sanitizeDashboardRow } from "../../shared/JEMChecklistDashboardRow";

export async function getOpsDetailsList(
  configuration: JemConfiguration["GeneralLedgerApi"],
  getTokenFn: IUserProviderState["accessToken"],
  fiscalYear: number,
  fiscalPeriod: number,
  opsDetailId: number
): Promise<JEMChecklistDashboardRow[]> {
  const endpoint = `${configuration.baseApiUrl}${configuration.endpoints.fcwGetChecklistsFiltered}`
    .replace("{fiscalYear}", fiscalYear.toString())
    .replace("{fiscalPeriod}", fiscalPeriod.toString())
    .replace("{opsDetailId}", opsDetailId.toString());

  const endpointUrl = getValidUrl(endpoint);
  const response = await getRequest<any[]>(endpointUrl, getTokenFn);
  if (!response) {
    throw new ApiError("No response from server");
  }

  const sanitizedDraftJEMChecklistResult = await sanitizeDashboardResultSet(response);
  return sanitizedDraftJEMChecklistResult as JEMChecklistDashboardRow[];
}

export interface IDeleteChecklistResponse {
  refGuid: string;
  errorCode: string;
  newRefGuid: string;
}

export function sanitizeDashboardResultSet(results: any[]): Promise<JEMChecklistDashboardRow[]> {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      try {
        const sanitizedResults = results.map((row) => sanitizeDashboardRow(row));
        resolve(sanitizedResults);
      } catch (error) {
        reject(error);
      }
    }, 1);
  });
}
