import { Dropdown, IDropdownOption } from "@fluentui/react";
import React, { useState, useImperativeHandle, useMemo } from "react";
import { GLSearchState } from "../GLSearch.types";
import { CommonDropdownOnChangeHandler } from "@jem/components";

export interface BPOClassificationDropdownRef {
  reset: () => void;
  getBpoClassification: () => GLSearchState["BPOClassification"];
  setInitialBpoClassification: (statuses: GLSearchState["BPOClassification"]) => void;
}

export interface BPOClassificationDropdownProps {
  customRef: React.Ref<BPOClassificationDropdownRef>;
}

export const BPOClassificationOptions = () => {
  return [
    { key: -1, text: "None Selected", disabled: true },
    { key: 0, text: "All" },
    { key: 1, text: "Non BPO Entry" },
    { key: 2, text: "BPO Standard Entry" },
    { key: 3, text: "BPO Non Standard Entry" }
  ];
};

const BPOClassificationDropdown: React.FC<BPOClassificationDropdownProps> = (props) => {
  const [bpoSelections, setBpoSelections] = useState<number[]>([0]);
  const bpos = useMemo(() => {
    return BPOClassificationOptions();
  }, []);

  useImperativeHandle(props.customRef, () => ({
    reset: () => {
      setBpoSelections([0]);
    },
    getBpoClassification: () => {
      return bpoSelections;
    },
    setInitialBpoClassification: (bpos: GLSearchState["BPOClassification"]) => {
      setBpoSelections(bpos);
    }
  }));
  const onChangeStatus = (_event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void => {
    if (!option) {
      return;
    }

    const newOptions = CommonDropdownOnChangeHandler({
      currentOptions: bpoSelections,
      option: option,
      keyForAll: 0,
      keyForNone: -1,
      totalOptions: bpos.length - 2
    });
    setBpoSelections(newOptions);
  };

  return (
    <>
      <Dropdown
        label="BPO Classification:"
        selectedKeys={bpoSelections}
        onChange={onChangeStatus}
        options={bpos}
        multiSelect
        styles={{
          root: {
            width: "100%",
            maxWidth: "100%"
          }
        }}
      />
    </>
  );
};

BPOClassificationDropdown.displayName = "BPOClassificationDropdown";
export default BPOClassificationDropdown;
