import {
  AutomatedDashboardConfiguration,
  AutomatedJeDashboardElement
} from "../AutomatedJeDashboard/AutomatedJeDashboard.requests";
import { FullJeDetails, IJeDetailsEndpointPayload } from "./JEDetails.schema";
import { AuthorizationToken, IRequestOptions } from "../../../Shared/utilities/RequestUtilities";
import { v4 } from "uuid";
import { convertJeDetailsFromPayloadToUI } from "./JeDetails.utilities";

// todo: once we have at least 3 types of request (get post patch delete) for jem convert this into a set of utilities
const getJeDetails = async (
  item: AutomatedJeDashboardElement,
  configuration: AutomatedDashboardConfiguration,
  getTokenFn?: IRequestOptions<AutomatedJeDashboardElement>["getTokenFn"]
) => {
  const headers = {
    accept: "application/json",
    requestid: v4()
  };
  if (getTokenFn && getTokenFn instanceof Function) {
    (headers as any).Authorization = await AuthorizationToken(getTokenFn);
  }
  const fullEndpoint = configuration.baseApiUrl + configuration.endpoints.s2SGetJE.replace("{jeid}", `${item.jeId}`);
  const resp = await fetch(fullEndpoint, {
    headers,
    referrerPolicy: "strict-origin-when-cross-origin",
    method: "GET",
    mode: "cors"
  });
  const json = await resp.json();
  return json as IJeDetailsEndpointPayload;
};

export const getEntry = async (
  item: AutomatedJeDashboardElement,
  configuration: AutomatedDashboardConfiguration,
  opts: IRequestOptions<FullJeDetails>
): Promise<FullJeDetails> => {
  if (opts.mockFn) {
    return opts.mockFn(item);
  }
  const details = await getJeDetails(item, configuration, opts.getTokenFn);
  return convertJeDetailsFromPayloadToUI(item, details);
};
