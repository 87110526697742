import { IDropdownOption, IDropdownProps } from "@fluentui/react";
import React, { useContext, useImperativeHandle, useState } from "react";
import { QueryBasedDropdown } from "../QueryBasedDropdown/QueryBasedDropdown";
import { ForceMaxWidthAndHeightOnDropdownStyle } from "../../utilities/FluentUIHacks";
import { JemConfiguration } from "../../../JemConfiguration";
import { IUserProviderState, getRequest, getValidUrl } from "../../utilities/RequestUtilities";
import { ApiError } from "../../utilities/ErrorHelpers";
import { UserContext } from "../../contexts/UserContext/UserContext";
import { useQuery } from "../../hooks/useQuery";

interface OpsDetail {
  id: number;
  name: string;
  autoPublish: boolean;
  isActive: boolean;
  deadline: string;
  opsOrgName: string;
  opsSummaryName: string;
  companyCodes: string;
  checklistOwners: string;
  backupTaskPerformers: string;
  defaultReviewers: string;
  modifiedOn: string;
  modifiedBy: string;
}

interface OpsDetailDropdownRef {
  getOpsDetail: () => { id: number; name: string } | null;
  reset: () => void;
  setInitialOpsDetail: (value: number) => void;
}

interface OpsDetailDropdownProps {
  configuration: JemConfiguration["GeneralLedgerApi"];
  onChange?: (opsDetail: string[]) => void;
  customRef: React.Ref<OpsDetailDropdownRef>;
  value?: number;
  removeAllOption?: boolean;
  styles?: IDropdownProps["styles"];
  allowClear?: boolean;
}

async function getOpsDetails(
  configuration: JemConfiguration["GeneralLedgerApi"],
  userContext: IUserProviderState,
  removeAllOption: boolean
): Promise<OpsDetail[]> {
  const endpoint = `${configuration.baseApiUrl}${configuration.endpoints.fcwGetOpsDetails}`;

  const endpointUrl = getValidUrl(endpoint);
  const response = await getRequest<OpsDetail[]>(endpointUrl, userContext.accessToken);
  if (!response) {
    throw new ApiError("No response from server");
  }
  // name = All must always come on top
  response.sort((a, b) => {
    if (a.id === -1) {
      return -1;
    }
    if (b.id === -1) {
      return 1;
    }
    return a.name.localeCompare(b.name);
  });
  if (removeAllOption) {
    response.shift();
  }
  return response;
}

const OpsDetailDropdown: React.FC<OpsDetailDropdownProps> = (props) => {
  const [opsDetail, setOpsDetail] = useState<{ opsDetailName: string; opsDetailId: number } | null>(null);
  const userContext = useContext(UserContext);

  const { data } = useQuery({
    queryKey: ["opsDetails"],
    queryFn: () => getOpsDetails(props.configuration, userContext, props.removeAllOption || false)
  });
  const opsDetails: OpsDetail[] = data || [];

  useImperativeHandle(props.customRef, () => ({
    getOpsDetail: () => {
      if (opsDetail) {
        return {
          id: opsDetail.opsDetailId,
          name: opsDetail.opsDetailName
        };
      }
      if (props.value) {
        const opsDetail = opsDetails.find((item) => item.id === props.value);
        if (opsDetail) {
          return {
            id: opsDetail.id,
            name: opsDetail.name
          };
        }
      }
      return null;
    },
    reset: () => {
      setOpsDetail({ opsDetailName: "", opsDetailId: 0 });
    },
    setInitialOpsDetail(value: number) {
      setOpsDetail({ opsDetailName: "", opsDetailId: value });
    }
  }));

  const onChangeOpsDetail = (option?: IDropdownOption): void => {
    if (option) {
      setOpsDetail({ opsDetailName: option.text, opsDetailId: option.key as number });
    } else if (props.allowClear) {
      setOpsDetail(null);
    }
  };

  const opsDetailOptions: IDropdownOption[] = opsDetails
    ? opsDetails.map((item) => {
        return {
          key: item.id,
          text: item.name
        };
      })
    : [];

  return (
    <>
      <QueryBasedDropdown
        label="OpsDetail"
        disabled={opsDetails === null}
        options={opsDetailOptions}
        selectedKey={props.value ? props.value : opsDetail ? opsDetail.opsDetailId : undefined}
        onChange={onChangeOpsDetail}
        styles={
          props.styles
            ? props.styles
            : ForceMaxWidthAndHeightOnDropdownStyle({
                width: "290px",
                height: "300px"
              })
        }
        allowClear={props.allowClear}
      />
    </>
  );
};

OpsDetailDropdown.displayName = "OpsDetailDropdown";
export { OpsDetailDropdown, OpsDetailDropdownProps, OpsDetailDropdownRef, OpsDetail, getOpsDetails };
