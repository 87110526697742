import { css } from "@emotion/css";
import { Dropdown, IDropdownProps, Label, SelectionMode, TextField } from "@fluentui/react";
import { FastField, useFormikContext } from "formik";
import React, { useRef } from "react";
import { JEMAliasPicker, LoadingStatus, removeDuplicateAliases } from "../../../../Shared";
import { DashboardGrid } from "../../../../Shared/components/DashboardGrid/DashboardGrid";
import { IDashboardGridRef, IExtendedColumn } from "../../../../Shared/components/DashboardGrid/DashboardGrid.types";
import { exportToExcel } from "../../../../Shared/components/DashboardGrid/DashboardGrid.utilities";
import { SimpleShimmerForForms } from "../../../../Shared/components/SimpleShimmerForForms/SimpleShimmerForForms";
import { GLCommentsModel } from "../../../../Shared/utilities/SharedModels";
import { GLJeDetailsFormikState, GLJeDetailsState } from "../GLDetailsForm.types";

interface GLDetailsReviewColumn extends IExtendedColumn {
  fieldName: keyof GLJeDetailsState["reviewersTab"]["commentItems"][0];
}

export const getReviewDetailsColumns = (): IExtendedColumn[] => {
  const cols: GLDetailsReviewColumn[] = [
    {
      key: "persona",
      name: "Persona",
      fieldName: "persona",
      type: "string",
      minWidth: 80
    },
    {
      key: "alias",
      name: "Alias",
      fieldName: "alias",
      type: "string",
      minWidth: 80
    },
    {
      key: "clarification",
      name: "Clarification",
      fieldName: "clarification",
      type: "string",
      minWidth: 80
    },
    {
      key: "comments",
      name: "Comments",
      fieldName: "comments",
      type: "string",
      minWidth: 80
    },
    {
      key: "dateAdded",
      name: "Date Added",
      fieldName: "dateAdded",
      type: "date",
      minWidth: 80
    },
    {
      key: "version",
      name: "Version",
      fieldName: "version",
      type: "number",
      minWidth: 40
    }
  ];

  return cols;
};

const ReviewerColumn: React.FC<{ clarificationCodes: string[]; loadingStatus: LoadingStatus }> = (props) => {
  const formik = useFormikContext<GLJeDetailsFormikState>();

  const clarificationCodeChange: IDropdownProps["onChange"] = (_event, item) => {
    const clarCode = formik.values.reviewersTabClarificationCode;
    if (item) {
      if (item) {
        formik.setFieldValue(
          "reviewersTabClarificationCode",
          item.selected ? [...clarCode, item.key] : clarCode.filter((key) => key !== item.key)
        );
      }
    }
  };
  return (
    <>
      <div className="ms-Grid" dir="ltr">
        <div className="ms-Grid-row">
          <Label>Enter a comment and the email alias of the Reviewer</Label>
        </div>
        <div className="ms-Grid-row">
          <SimpleShimmerForForms loadingStatus={props.loadingStatus} label="Reviewer:" height={32}>
            <FastField
              name={"reviewersTabReviewer"}
              label="Reviewer:"
              as={TextField}
              type="input"
              readOnly
              errorMessage={
                formik.touched.reviewersTabReviewer && formik.errors.reviewersTabReviewer
                  ? formik.errors.reviewersTabReviewer
                  : ""
              }
            />
          </SimpleShimmerForForms>
        </div>
        <div className="ms-Grid-row">
          <SimpleShimmerForForms loadingStatus={props.loadingStatus} label="Additional Reviewers:" height={32}>
            <JEMAliasPicker
              label="Additional Reviewers"
              required={true}
              disabled={false}
              initialAliases={formik.values.reviewersTabAdditionalReviewers}
              onChange={(value) => {
                // remember to remove duplicates from the list
                const unique = removeDuplicateAliases(value);
                formik.setFieldValue("reviewersTabAdditionalReviewers", unique);
              }}
              description="Maximum of 75 user aliases separated by semicolon(;)."
              errorMessage={
                formik.errors.reviewersTabAdditionalReviewers
                  ? formik.errors.reviewersTabAdditionalReviewers.toString()
                  : ""
              }
            />
          </SimpleShimmerForForms>
        </div>
        <div className="ms-Grid-row">
          <SimpleShimmerForForms loadingStatus={props.loadingStatus} label="Comments:" height={64}>
            <FastField
              name={"reviewersTabComments"}
              label="Comments:"
              as={TextField}
              multiline
              rows={2}
              type="input"
              errorMessage={
                formik.touched.reviewersTabComments && formik.errors.reviewersTabComments
                  ? formik.errors.reviewersTabComments
                  : ""
              }
            />
          </SimpleShimmerForForms>
        </div>
        <div className="ms-Grid-row">
          <SimpleShimmerForForms loadingStatus={props.loadingStatus} label="Clarification Codes:" height={32}>
            <FastField
              label="Clarification Codes:"
              name={"reviewersTabClarificationCode"}
              as={Dropdown}
              options={props.clarificationCodes.map((code, index) => ({
                key: `${index}`,
                text: code
              }))}
              multiSelect
              onChange={clarificationCodeChange}
            />
          </SimpleShimmerForForms>
        </div>
      </div>
    </>
  );
};

let ReviewerComments: React.FC<{ comments: GLCommentsModel[]; loadingStatus: LoadingStatus }> = (props) => {
  const dashboardGridRef = useRef<IDashboardGridRef>(null);

  return (
    <div
      className={css`
        border-left: 2px solid;
        padding-left: 10px;
      `}
      data-is-scrollable="true"
    >
      <DashboardGrid
        idForLocalStorage={"glJeDetailsReviewerComments"}
        customRef={dashboardGridRef}
        columnGenerator={getReviewDetailsColumns}
        items={props.comments}
        isSortedIndex={4}
        height={`250px`}
        isSortedDescending={true}
        loadingStatus={props.loadingStatus}
        selectionMode={SelectionMode.multiple}
        onExport={(rows) => {
          exportToExcel({
            sheetName: "jeDetailsReviewComments",
            rowsWithHeader: rows,
            fileName: "jeDetailsReviewComments.xlsx"
          });
        }}
        disablePagination={true}
      ></DashboardGrid>
    </div>
  );
};

ReviewerComments = React.memo(ReviewerComments);
ReviewerComments.displayName = "ReviewerComments";

export interface ReviewFormProps {
  comments: GLCommentsModel[];
  clarificationCodes: string[];
  loadingStatus: LoadingStatus;
}

const ReviewFormTab: React.FC<ReviewFormProps> = (props) => {
  return (
    <React.Fragment>
      <div className="ms-Grid" dir="ltr">
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm6">
            <ReviewerColumn clarificationCodes={props.clarificationCodes} loadingStatus={props.loadingStatus} />
          </div>
          <div className="ms-Grid-col ms-sm6">
            <ReviewerComments comments={props.comments} loadingStatus={props.loadingStatus} />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

ReviewFormTab.displayName = "ReviewFormTab";
export { ReviewFormTab };
