import { Label, Stack, Text } from "@fluentui/react";
import React from "react";
import { labelStyles } from "../../../../Shared/utilities/CommonStyles";
import { gridStyle, columnStyles, itemStyle } from "../../../shared/GL.styles";

import { GLJeSapDetailsState } from "../GLDetailsForm.types";

export interface SapDetailsHeaderProps {
  state: GLJeSapDetailsState;
}

const SapDetailsHeader: React.FC<SapDetailsHeaderProps> = (props) => {
  return (
    <>
      <Stack horizontal wrap {...{ tokens: gridStyle }}>
        <Stack {...{ tokens: columnStyles }}>
          <div style={itemStyle}>
            <Label styles={labelStyles}>Doc Number</Label>
            <Text>{props.state.header.docNumber}</Text>
          </div>
          <div style={itemStyle}>
            <Label styles={labelStyles}>Ref Number</Label>
            <Text>{props.state.header.refNumber}</Text>
          </div>
          <div style={itemStyle}>
            <Label styles={labelStyles}>Company Code</Label>
            <Text>{props.state.header.companyCode}</Text>
          </div>
        </Stack>
        <Stack {...{ tokens: columnStyles }}>
          <div style={itemStyle}>
            <Label styles={labelStyles}>JE Type</Label>
            <Text>{props.state.header.jeType}</Text>
          </div>
          <div style={itemStyle}>
            <Label styles={labelStyles}>Description</Label>
            <Text>{props.state.header.description}</Text>
          </div>
          <div style={itemStyle}>
            <Label styles={labelStyles}>Transaction</Label>
            <Text>{props.state.header.transaction}</Text>
          </div>
        </Stack>
        <Stack {...{ tokens: columnStyles }}>
          <div style={itemStyle}>
            <Label styles={labelStyles}>Fiscal Year</Label>
            <Text>{props.state.header.fiscalYear}</Text>
          </div>
          <div style={itemStyle}>
            <Label styles={labelStyles}>Fiscal Period</Label>
            <Text>{props.state.header.fiscalPeriod}</Text>
          </div>
        </Stack>
        <Stack {...{ tokens: columnStyles }}>
          <div style={itemStyle}>
            <Label styles={labelStyles}>Currency</Label>
            <Text>{props.state.header.currency}</Text>
          </div>
          <div style={itemStyle}>
            <Label styles={labelStyles}>Doc Date</Label>
            <Text>{props.state.header.docDate}</Text>
          </div>
        </Stack>
        <Stack {...{ tokens: columnStyles }}>
          <div style={itemStyle}>
            <Label styles={labelStyles}>Posting Date</Label>
            <Text>{props.state.header.postingDate}</Text>
          </div>
          <div style={itemStyle}>
            <Label styles={labelStyles}>Time of Entry</Label>
            <Text>{props.state.header.timeOfEntry}</Text>
          </div>
        </Stack>
        <Stack {...{ tokens: columnStyles }}>
          <div style={itemStyle}>
            <Label styles={labelStyles}>Total Amount</Label>
            <Text>{props.state.header.totalAmount}</Text>
          </div>
          <div style={itemStyle}>
            <Label styles={labelStyles}>Debit Amount</Label>
            <Text>{props.state.header.debitAmount}</Text>
          </div>
          <div style={itemStyle}>
            <Label styles={labelStyles}>Credit Amount</Label>
            <Text>{props.state.header.creditAmount}</Text>
          </div>
        </Stack>
      </Stack>
    </>
  );
};

SapDetailsHeader.displayName = "SapDetailsHeader";
export { SapDetailsHeader };
