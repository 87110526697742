import {
  ALL_STATUS,
  AutomatedJeDashboardElement,
  DashboardApiPayload,
  singleS2SJeToElement,
  statusType
} from "./AutomatedJeDashboard.requests";
import { ISourceSelectorState } from "../../../Shared/components/DashboardSourceSelector/DashboardSourceSelector";

import { default as moment } from "moment";
import { v4 as uuidv4 } from "uuid";
import { DomainDataEnum, DomainDataObjects } from "../../../Shared";
import { ITenantConfigItem } from "./AutomatedJeDashboard";
import { ITenantConfigItemDisplay } from "./CollapsibleList";

interface IGeneratorArgs {
  type: statusType;
  i: number;
  fiscalMonth: number;
  fiscalYear: number;
}

function random(minm: number, maxm: number) {
  return Math.floor(Math.random() * (maxm - minm + 1)) + minm;
}

function randomFloat(minm: number, maxm: number) {
  return Math.random() * (maxm - minm) + minm;
}

export const fiscalMonthToDate = (day: number, fiscalMonth: number, fiscalYear: number): Date => {
  const calendarYear = fiscalYear - 1;
  const fiscalMonthZeroBased = fiscalMonth - 1;
  const d = new Date(calendarYear, 6, day);
  d.setMonth(d.getMonth() + fiscalMonthZeroBased);
  return d;
};

export const fiscalMonthToDateString = (day: number, fiscalMonth: number, fiscalYear: number): string => {
  const d = fiscalMonthToDate(day, fiscalMonth, fiscalYear);
  return [("0" + (d.getMonth() + 1)).slice(-2), ("0" + d.getDate()).slice(-2), d.getFullYear()].join("/");
};

function randomDate(start: Date, end: Date) {
  return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
}

const companyCodes = ["1010", "1075", "2211", "1085", "1038", "2210", "1055", "1064"];
const headers = [
  "Dec rel sig",
  "Metaswitch periodic",
  "JD Dec 20 release B1",
  "Cash and Bank",
  "low",
  "MS Sales MTD reven",
  "MG Dec 20 release B",
  "MS Sales MTD revenue - 4"
];
const jeTypes = ["SA", "SZ", "ZS", "SP", "SI", "SB", "SL", "SS", "02"];
const elementGenerator = ({ type, fiscalMonth, fiscalYear }: IGeneratorArgs): DashboardApiPayload => {
  const minId = 100000;
  const maxId = 999999;
  const obj: any = { jeId: random(minId, maxId), jeStatus: type };
  let SapAmount: number | null = null;
  if (type === "Failed in SAP" || type === "Unknown") {
    if (type === "Failed in SAP") {
      obj.actionRowWeightage = 2;
      obj.refguid = uuidv4();
    }
    obj.jeDocNum = null;
    obj.dueDate = null;
    obj.companyCode = null;
    obj.jeType = null;
    obj.jeHeaderText = null;
    obj.sourceAmount = null;
    obj.differenceAmount = null;
    obj.postingType = null;
    obj.jeSAPErrorInfo = {
      jeHeaderError:
        "Enter rate UAK / UAH rate type ZUA for 20.06.2021 in the system settings;Check - document date is in the future",
      jeLineItemErrors: [
        {
          lineNumber: 3,
          description:
            "SAP automatic account assignment defaults internal order 2013155 for company code 1079 account 545090 . To override the default place an exclamation mark (!) in the cost center field. To use the SAP default internal order change the internal order number to 2013155 in the internal order field.;BRV3026 - NET REVENUE MUST BE POSTED TO S&amp;amp;M PC'S"
        },
        {
          lineNumber: 3,
          description:
            "SAP automatic account assignment defaults internal order 2013155 for company code 1079 account 545090 . To override the default place an exclamation mark (!) in the cost center field. To use the SAP default internal order change the internal order number to 2013155 in the internal order field.;BRV3026 - NET REVENUE MUST BE POSTED TO S&amp;amp;M PC'S"
        },
        {
          lineNumber: 3,
          description:
            "SAP automatic account assignment defaults internal order 2013155 for company code 1079 account 545090 . To override the default place an exclamation mark (!) in the cost center field. To use the SAP default internal order change the internal order number to 2013155 in the internal order field.;BRV3026 - NET REVENUE MUST BE POSTED TO S&amp;amp;M PC'S"
        },
        {
          lineNumber: 3,
          description:
            "SAP automatic account assignment defaults internal order 2013155 for company code 1079 account 545090 . To override the default place an exclamation mark (!) in the cost center field. To use the SAP default internal order change the internal order number to 2013155 in the internal order field.;BRV3026 - NET REVENUE MUST BE POSTED TO S&amp;amp;M PC'S"
        },
        {
          lineNumber: 3,
          description:
            "SAP automatic account assignment defaults internal order 2013155 for company code 1079 account 545090 . To override the default place an exclamation mark (!) in the cost center field. To use the SAP default internal order change the internal order number to 2013155 in the internal order field.;BRV3026 - NET REVENUE MUST BE POSTED TO S&amp;amp;M PC'S"
        },
        {
          lineNumber: 3,
          description:
            "SAP automatic account assignment defaults internal order 2013155 for company code 1079 account 545090 . To override the default place an exclamation mark (!) in the cost center field. To use the SAP default internal order change the internal order number to 2013155 in the internal order field.;BRV3026 - NET REVENUE MUST BE POSTED TO S&amp;amp;M PC'S"
        }
      ]
    };
    return obj;
  } else if (type === "Submitted") {
    obj.jeDocNum = null;
  } else {
    const minDocNum = 100000000;
    const maxDocNum = 999999999;
    obj.jeDocNum = `${random(minDocNum, maxDocNum)}`;
    SapAmount = randomFloat(1.3, 250000.67);
    obj.refguid = uuidv4();
  }
  const minDay = 1;
  const maxDay = 28;
  const day = random(minDay, maxDay);
  const debit = randomFloat(1.1, 2300.56);
  return {
    ...obj,
    dueDate: fiscalMonthToDateString(day, fiscalMonth, fiscalYear),
    companyCode: companyCodes[random(0, companyCodes.length - 1)],
    jeType: jeTypes[random(0, jeTypes.length - 1)],
    jeHeaderText: headers[random(0, headers.length - 1)],
    sapAmount: Number(SapAmount?.toFixed(2)),
    sourceAmount: Number(debit.toFixed(2)),
    differenceAmount: Number(SapAmount ? (debit - SapAmount).toFixed(2) : debit.toFixed(2)),
    submittedTime: moment(randomDate(fiscalMonthToDate(1, fiscalMonth, fiscalYear), new Date())).format(
      "MM/DD/YYYY h:mm:ss A"
    ),
    retryPerformedBy: "abcdef",
    retryPerformedDate: moment(randomDate(fiscalMonthToDate(1, fiscalMonth, fiscalYear), new Date())).format(
      "MM/DD/YYYY h:mm:ss A"
    )
  } as DashboardApiPayload;
};

const shuffleArray = (array: any[]) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
};

export const dataGenerator = (fiscalMonth: number, fiscalYear: number, limit?: number): DashboardApiPayload[] => {
  let j = 0;
  let results: DashboardApiPayload[] = [];
  ALL_STATUS.forEach((t) => {
    results = results.concat(
      Array.from({ length: random(50, limit ? Math.max(51, limit) : 577) }, (_, z) => {
        j = j + z;
        return elementGenerator({
          type: t as statusType,
          i: j,
          fiscalMonth,
          fiscalYear
        });
      })
    );
  });
  shuffleArray(results);
  return results;
};

export const itemsGenerator = (args: ISourceSelectorState, limit?: number): AutomatedJeDashboardElement[] => {
  const data = dataGenerator(Number(args.fiscalPeriod), Number(args.fiscalYear), limit);
  return data.map(singleS2SJeToElement);
};

export const mockResponseGenerator = (args: ISourceSelectorState): Promise<AutomatedJeDashboardElement[]> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(itemsGenerator(args, 5));
    }, 300);
  });
};

const mockJemTenantConfigs: DomainDataObjects[DomainDataEnum.JemTenantConfigs] = [
  {
    id: 1,
    name: "Mock Name",
    appId: "exampleAppId",
    primaryPoster: "Mock Primary Poster",
    additionalPosters: "Mock Additional Posters",
    defaultReviewers: "Mock Default Reviewers",
    emailContacts: "Mock Email Contacts",
    refGuidPrefix: "Mock Ref GUID Prefix",
    tenantOwner: "Mock Tenant Owner",
    exclusionConfigurations: "Mock Exclusion Configurations",
    description: "Mock Description",
    modifiedDate: "2022-01-01T00:00:00Z",
    modifiedby: "Mock Modifier",
    isActive: true,
    allowedPostingInJEM: true,
    additionalConfigurations: "Mock Additional Configurations"
  }
  // Add more objects as needed
];

export const mockTenantConfigs = (): Promise<DomainDataObjects[DomainDataEnum.JemTenantConfigs]> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(mockJemTenantConfigs);
    }, 300);
  });
};

export const handleDashboardSelectorRef = (selectorState: ISourceSelectorState) => {
  const tenantDetails = mockJemTenantConfigs.find((tenant) => tenant.appId === selectorState.appId);
  if (tenantDetails) {
    const tenantConfigItem: ITenantConfigItem = {
      key: tenantDetails.appId,
      name: tenantDetails.name,
      primaryPoster: tenantDetails.primaryPoster,
      additionalPosters: tenantDetails.additionalPosters || "",
      defaultReviewer: tenantDetails.defaultReviewers,
      tenantOwner: tenantDetails.tenantOwner
    };
    const tenantConfigItemDisplay: ITenantConfigItemDisplay[] = [];
    for (const [propertyName, propertyValue] of Object.entries(tenantConfigItem)) {
      tenantConfigItemDisplay.push({ column: propertyName, value: propertyValue });
    }
    return tenantConfigItemDisplay;
  } else return null;
};

/*describe("handleDashboardSelectorRef", () => {
  it("should return tenantConfigItemDisplay when tenantDetails is found", () => {
    const selectorState: ISourceSelectorState = {
      appId: "exampleAppId",
      fiscalYear: 2024,
      fiscalPeriod: 1
    };
    const tenantDetails = {
      id: 1,
      name: "Mock Name",
      appId: "exampleAppId",
      primaryPoster: "Mock Primary Poster",
      additionalPosters: "Mock Additional Posters",
      defaultReviewers: "Mock Default Reviewers",
      emailContacts: "",
      refGuidPrefix: "",
      tenantOwner: "Mock Tenant Owner",
      exclusionConfigurations: "",
      description: "",
      modifiedDate: "",
      modifiedby: "",
      isActive: true,
      allowedPostingInJEM: true,
      additionalConfigurations: ""
    };

    const expectedTenantConfigItemDisplay = [
      { column: "key", value: tenantDetails.appId },
      { column: "name", value: tenantDetails.name },
      { column: "primaryPoster", value: tenantDetails.primaryPoster },
      { column: "additionalPosters", value: tenantDetails.additionalPosters || "" },
      { column: "defaultReviewer", value: tenantDetails.defaultReviewers },
      { column: "tenantOwner", value: tenantDetails.tenantOwner }
    ];

    jest.spyOn(mockJemTenantConfigs, "find").mockReturnValue(tenantDetails);

    const result = handleDashboardSelectorRef(selectorState);

    expect(result).toEqual(expectedTenantConfigItemDisplay);
  });

  it("should return null when tenantDetails is not found", () => {
    const selectorState: ISourceSelectorState = {
      appId: "exampleAppId",
      fiscalYear: 2024,
      fiscalPeriod: 1
    };

    jest.spyOn(mockJemTenantConfigs, "find").mockReturnValue(undefined);

    const result = handleDashboardSelectorRef(selectorState);

    expect(result).toBeNull();
  });
});*/
