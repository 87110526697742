import {
  FiscalPeriodStringTransformations,
  postRequest,
  putRequest,
  JEMChecklistResponseFromApi,
  JEMChecklistDetailsRow,
  sanitizeChecklistDetailsRow,
  SignoffStatus
} from "@jem/components";
import { deleteRequest } from "@jem/components";
import { JemConfiguration, IUserProviderState, getValidUrl, getRequest, ApiError } from "@jem/components";
import { DomainDataEnum, DomainDataObjects } from "../../../../../jem-shared-lib/lib";
import { TaskBulkEditInputDto } from "../JEMChecklistBulkEdit/TaskBulkEditInput";
import { FcwLineItem, GLApiCommonResponse } from "./JEMChecklistDetails.types";

const JETaskTypes = new Map<string, string>([
  ["Draft JE", "je"],
  ["Batch JE", "batch"],
  ["Bulk JE", "batch"]
]);

export interface CustomizedState {
  checklistName: string;
  fp: number;
  fy: number;
  defaultReviewers: string;
}

export interface ChecklistDetails {
  fcwChecklistName: string;
  checklistName: string;
  checklistOwners: string;
  defaultReviewers: string;
  backupPoster: string[];
  backupReviewer: string[];
  companyCodes: number[];
  fcwTaskId: string;
  fp: number;
  fy: number;
  fiscalPeriod: string;
  taskList: JEMChecklistDetailsRow[];
  signOffStatus: string;
}

export async function getChecklistDetails(
  configuration: JemConfiguration["GeneralLedgerApi"],
  getTokenFn: IUserProviderState["accessToken"],
  GUID: string,
  locationObjects: CustomizedState | null,
  domainData: Pick<DomainDataObjects, DomainDataEnum.PostingDueDates>
): Promise<ChecklistDetails> {
  const endpoint = `${configuration.baseApiUrl}${configuration.endpoints.fcwGetChecklist}`.replace("{RefGuid}", GUID);

  const endpointUrl = getValidUrl(endpoint);
  const response = await getRequest<JEMChecklistResponseFromApi>(endpointUrl, getTokenFn);

  if (!response) {
    throw new ApiError("No response from server");
  }

  let checklistName = locationObjects && locationObjects.checklistName ? locationObjects.checklistName : "";
  let checklistPeriod =
    locationObjects && locationObjects.fy && locationObjects.fp
      ? FiscalPeriodStringTransformations.FiscalYearAndFiscalMonthToString(locationObjects.fy, locationObjects.fp)
      : "";
  const defaultReviewers =
    locationObjects && locationObjects.defaultReviewers ? locationObjects.defaultReviewers : response.defaultReviewers;

  const sanitizedDraftJEMChecklistResult = await sanitizeChecklistDetailsResultSet(
    response.checklistTasks,
    GUID,
    response.opsDetailId,
    domainData
  );
  checklistName = checklistName || response.fcwChecklistName;
  checklistPeriod =
    checklistPeriod ||
    FiscalPeriodStringTransformations.FiscalYearAndFiscalMonthToString(response.fiscalYear, response.fiscalPeriod);

  const cc =
    response.companyCodes === null || response.companyCodes === undefined || response.companyCodes === ""
      ? []
      : Array.isArray(response.companyCodes)
      ? (response.companyCodes as number[])
      : typeof response.companyCodes === "string"
      ? response.companyCodes.split(",").map(Number)
      : [];
  const backupPoster =
    response.backUpPoster === null || response.backUpPoster === undefined || response.backUpPoster === ""
      ? []
      : response.backUpPoster.split(";").map((x) => x.trim().toUpperCase());
  const backupReviewer =
    response.backUpReviewer === null || response.backUpReviewer === undefined || response.backUpReviewer === ""
      ? []
      : response.backUpReviewer.split(";").map((x) => x.trim().toUpperCase());
  const status = response.signOffStatus;

  return {
    fcwChecklistName: checklistName,
    checklistName: response.checklistName,
    checklistOwners: response.checklistOwners,
    defaultReviewers: defaultReviewers,
    fcwTaskId: response.fcwTaskId,
    fp: response.fiscalPeriod,
    fy: response.fiscalYear,
    fiscalPeriod: checklistPeriod,
    taskList: sanitizedDraftJEMChecklistResult,
    backupPoster: backupPoster,
    backupReviewer: backupReviewer,
    companyCodes: cc,
    signOffStatus: status
  };
}

export interface CloneResponse {
  data: CloneSingleMessage[];
  status: boolean;
  message: string;
  errorCode: string;
}

export interface CloneSingleMessage {
  refguid: string;
  draftName: string;
  errorcode: string;
  errorDescription: string;
}

export async function cloneJEMChecklist(
  configuration: JemConfiguration["GeneralLedgerApi"],
  getTokenFn: IUserProviderState["accessToken"],
  JEMChecklistGUIDSource: string,
  JEMChecklistGUIDTarget: string,
  taskRefGUIDs: string[]
): Promise<CloneResponse> {
  const endpoint = `${configuration.baseApiUrl}${configuration.endpoints.fcwPutClone}`
    .replace("{sourcechecklist}", JEMChecklistGUIDSource)
    .replace("{targetchecklist}", JEMChecklistGUIDTarget);
  const endpointUrl = getValidUrl(endpoint);
  const response = await putRequest<CloneResponse>(endpointUrl, taskRefGUIDs, getTokenFn);

  if (!response) {
    throw new ApiError("No response from server");
  }

  const fcwChecklistResponse = response;
  return fcwChecklistResponse;
}

export async function publishJEMChecklistTasks(
  configuration: JemConfiguration["GeneralLedgerApi"],
  getTokenFn: IUserProviderState["accessToken"],
  jemChecklistGuid: string,
  taskRefGUIDs: string[]
): Promise<any> {
  const endpoint = `${configuration.baseApiUrl}${configuration.endpoints.fcwPutPublish}`.replace(
    "{checklistRefguid}",
    jemChecklistGuid
  );
  const endpointUrl = getValidUrl(endpoint);
  const response = await putRequest<any[]>(endpointUrl, taskRefGUIDs, getTokenFn);
  if (!response) {
    throw new ApiError("No response from server");
  }
  const fcwChecklistResponse = response;
  return fcwChecklistResponse;
}

export async function bulkDeleteJEMChecklistTasks(
  configuration: JemConfiguration["GeneralLedgerApi"],
  getTokenFn: IUserProviderState["accessToken"],
  jemChecklistGuid: string,
  taskRefGUIDs: string[]
): Promise<any> {
  const endpoint = `${configuration.baseApiUrl}${configuration.endpoints.fcwPutBulkDeleteChecklistTasks}`.replace(
    "{checklistRefguid}",
    jemChecklistGuid
  );
  console.log("bulkdeleteendpoint", endpoint);
  const endpointUrl = getValidUrl(endpoint);
  const response = await putRequest<any[]>(endpointUrl, taskRefGUIDs, getTokenFn);
  if (!response) {
    throw new ApiError("No response from server");
  }
  const fcwChecklistResponse = response;
  return fcwChecklistResponse;
}

export async function bulkEditJEMChecklistTasks(
  configuration: JemConfiguration["GeneralLedgerApi"],
  getTokenFn: IUserProviderState["accessToken"],
  jemChecklistGuid: string,
  bulkEditInput: TaskBulkEditInputDto
): Promise<any> {
  const endpoint = `${configuration.baseApiUrl}${configuration.endpoints.fcwPutBulkEditChecklistTasks}`.replace(
    "{checklistRefguid}",
    jemChecklistGuid
  );
  console.log("bulkeditendpoint", endpoint);
  const endpointUrl = getValidUrl(endpoint);
  const response = await postRequest<any[]>(endpointUrl, bulkEditInput, getTokenFn);
  if (!response) {
    throw new ApiError("No response from server");
  }
  return response;
}

export interface INoaCtivityResponseObject {
  refguid: string;
  jeTaskName: string;
  data: GLApiCommonResponse;
}

export async function markNoActivityJEMChecklistTasks(
  configuration: JemConfiguration["GeneralLedgerApi"],
  getTokenFn: IUserProviderState["accessToken"],
  jemChecklistGuid: string,
  checklistDetailsItems: JEMChecklistDetailsRow[]
): Promise<INoaCtivityResponseObject[]> {
  //

  const responseList = Promise.all(
    checklistDetailsItems.map(async (item) => {
      console.log("item.jeType", item.jeType);
      const taskType = JETaskTypes.get(item.jeType);
      const endpoint = `${configuration.baseApiUrl}${configuration.endpoints.fcwPutNoActivity}`
        .replace("{jetype}", taskType as string)
        .replace("{refguid}", item.refGuid);
      const endpointUrl = getValidUrl(endpoint);
      // refguid- request, jetaskname - requestitem, {status: false, errorCOde: ERR, message" "response message}
      const response = await putRequest<any>(endpointUrl, null, getTokenFn);
      if (!response) {
        throw new ApiError("No response from server");
      }
      ///cte to 500400 response
      const { errorCode, message, status } = response;
      const data: GLApiCommonResponse = {
        errorCode,
        message,
        status
      };
      return { refguid: item.refGuid, jeTaskName: item?.jeTaskName, data: data } as INoaCtivityResponseObject;
    })
  );

  const responses = await responseList;
  return responses;
}

export interface INoActivityPayload {
  jeDrafts: string[];
  batchDrafts: string[];
  reason: string;
  comments: string;
}
export async function markNoActivityWithPayload(
  configuration: JemConfiguration["GeneralLedgerApi"],
  getTokenFn: IUserProviderState["accessToken"],
  jemChecklistGuid: string,
  checklistDetailsItems: JEMChecklistDetailsRow[],
  noActivityReason: string,
  comments: string
): Promise<any> {
  const jeTypeRefGUIDs = checklistDetailsItems
    .filter((item) => item.jeType.includes("Draft JE"))
    .map((filteredItem) => filteredItem.refGuid);
  const batchTypeRefGUIDs = checklistDetailsItems
    .filter((item) => item.jeType.includes("Batch JE") || item.jeType.includes("Bulk JE"))
    .map((filteredItem) => filteredItem.refGuid);

  const noActivityRequestPayload: INoActivityPayload = {
    jeDrafts: jeTypeRefGUIDs,
    batchDrafts: batchTypeRefGUIDs,
    reason: noActivityReason != null && noActivityReason != "" ? noActivityReason : "Mark No Activity multiple",
    comments: comments != null && comments != "" ? comments : "marked as no activity for this period"
  };
  const endpoint = `${configuration.baseApiUrl}${configuration.endpoints.fcwPutNoActivityWithPayload}`;
  const endpointUrl = getValidUrl(endpoint);

  const response = await putRequest<any[]>(endpointUrl, noActivityRequestPayload, getTokenFn);
  if (!response) {
    throw new ApiError("No response from server");
  }
  const fcwChecklistResponse = response;
  return fcwChecklistResponse;
}

export interface FCWSaveResponse {
  checklistName: string;
  checklistStatus: number;
  jedrafts: Jedraft[];
  createdDateTime: string;
  companyCode: string;
  fiscalYear: number;
  fiscalMonth: number;
  publishedDate: string;
  refGuid: string;
  signOffDate: string;
  errorCode: string;
  fcwTaskId: number;
  fcwOpsDetailsId: number;
}

export interface Jedraft {
  jeDescription: string;
  reasonCode: string;
  purpose: string;
  postingNotRequired: string;
  poster: string;
  jeType: string;
  frequency: string;
  processingStatus: string;
  comments: string;
  draftName: string;
  coCode: string;
  postedDate: string;
  postingPeriod: string;
  postedBy: string;
  jeNumber: string;
  reviewedBy: string;
  reviewedDate: string;
  id: string;
  refguid: string;
  checklistGuid: string;
  avgCompletionTime: number;
  dueDate: string;
  isPosterValid: string;
  isCloneable: boolean;
  isAdhocDraft: boolean;
}

export async function saveJEChecklistTask(
  configuration: JemConfiguration["GeneralLedgerApi"],
  getTokenFn: IUserProviderState["accessToken"],
  data: JEMChecklistDetailsRow,
  jereasoncodes: DomainDataObjects[DomainDataEnum.JeReasonCodes]
): Promise<FCWSaveResponse> {
  const endpoint = `${configuration.baseApiUrl}${configuration.endpoints.fcwPostChecklist}`;
  const endpointUrl = getValidUrl(endpoint);

  const reason = jereasoncodes.find((reason: { code: string }) => reason.code.trim() == data.reasonCode.trim());

  const payload: FcwLineItem = {
    jEDescription: data.description,
    reasonCode: reason?.jeReason as string,
    purpose: data.purpose,
    postingNotReqd: null,
    poster: data.assignedPoster,
    backupPosters: data.backupPoster.join(";"),
    comments: null,
    jEType: data.jeType,
    draftName: data.jeTaskName,
    checklistGuid: data.checklistRefGuid || "",
    postedBy: null,
    postedDate: null,
    postingPeriod: data.postingPeriod,
    refguid: data.refGuid || null,
    reviewedBy: data.assignedReviewer.join(";"),
    id: null,
    companyCode: data.companycode.join(","),
    dueDate: data.postingDueDate,
    isCloneable: data.isCloneable,
    draftDateCode: data.draftDateCode,
    frequency: data.frequency,
    estimatedTimeMins: data.estimatedTimeMins,
    currencycode: data.currencycode,
    documentType: data.documentType,
    referenceNumber: data.referenceNumber,
    allowDualCurrency: data.isF05Posting
  };

  const response = await postRequest<FCWSaveResponse>(endpointUrl, [payload], getTokenFn);

  if (!response) {
    throw new ApiError("No response from server");
  }

  return response;
}

export async function deleteJEChecklistTask(
  configuration: JemConfiguration["GeneralLedgerApi"],
  getTokenFn: IUserProviderState["accessToken"],
  data: JEMChecklistDetailsRow
): Promise<any> {
  const endpoint = `${configuration.baseApiUrl}${configuration.endpoints.fcwDeleteChecklistTask}`.replace(
    "{RefGuid}",
    data.refGuid as string
  );
  const endpointUrl = getValidUrl(endpoint);

  const response = await deleteRequest<any>(endpointUrl, getTokenFn);

  if (!response) {
    throw new ApiError("No response from server");
  }

  return response;
}

export interface IDeleteChecklistResponse {
  refGuid: string;
  errorCode: string;
  newRefGuid: string;
}

export function sanitizeChecklistDetailsResultSet(
  results: any[],
  GUID: string,
  opsDetailId: number,
  domainData: Pick<DomainDataObjects, DomainDataEnum.PostingDueDates>
): Promise<JEMChecklistDetailsRow[]> {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      try {
        const sanitizedResults = results.map((row) =>
          sanitizeChecklistDetailsRow(row, GUID, opsDetailId, domainData[DomainDataEnum.PostingDueDates])
        );
        resolve(sanitizedResults);
      } catch (error) {
        reject(error);
      }
    }, 1);
  });
}

export interface ISignOffChecklistPayload {
  refGuid: string;
  signOffState: number;
  taskIds: string[];
  reasonMissedSLA: number;
  comments: string;
  actualTimeTaken: number;
}
export async function signOffJEChecklist(
  configuration: JemConfiguration["GeneralLedgerApi"],
  getTokenFn: IUserProviderState["accessToken"],
  jeChecklistRefGuid: string,
  taskIds: string[],
  signoffStatus: SignoffStatus,
  reasonMissedSLA: number,
  comments: string,
  actualTimeTaken: number
): Promise<any> {
  const requestPayload: ISignOffChecklistPayload = {
    refGuid: jeChecklistRefGuid,
    signOffState: signoffStatus,
    taskIds,
    reasonMissedSLA,
    comments,
    actualTimeTaken
  };
  const endpoint = `${configuration.baseApiUrl}${configuration.endpoints.fcwSignOffChecklist}`;
  const endpointUrl = getValidUrl(endpoint, true);

  const response = await putRequest<any[]>(endpointUrl, requestPayload, getTokenFn);
  if (!response) {
    throw new ApiError("signOffJEChecklist::No response from server");
  }

  return response;
}

export async function getTaskPropertyFromFCCW(
  configuration: JemConfiguration["GeneralLedgerApi"],
  getTokenFn: IUserProviderState["accessToken"],
  propertyName: string
): Promise<any> {
  const endpoint = `${configuration.baseApiUrl}${configuration.endpoints.fcwGetTaskProperty.replace(
    "{propertyName}",
    propertyName
  )}`;
  const endpointUrl = getValidUrl(endpoint, true);

  const response = await getRequest<unknown[]>(endpointUrl, getTokenFn);
  if (!response) {
    throw new ApiError("getTaskPropertyFromFCCW::No response from server");
  }

  return response;
}
