﻿import * as msal from "@azure/msal-browser";
import { JemConfiguration } from "../../../JemConfiguration";

function getQualifiedUrl(uri: string): string {
  return new URL(uri, window.location.origin).href;
}

export interface MsalSettings {
  configuration: msal.Configuration;
  redirectSettings: msal.RedirectRequest;
  graphApiEndpoint: string;
}

export function _msalSettings(appConfig: JemConfiguration): MsalSettings {
  const configuration: msal.Configuration = {
    auth: {
      clientId: appConfig.auth.clientId,
      authority: appConfig.auth.authority,
      redirectUri: getQualifiedUrl("/login"),
      postLogoutRedirectUri: getQualifiedUrl("/logout")
    },
    cache: {
      cacheLocation: "sessionStorage",
      storeAuthStateInCookie: false
    }
  };

  return {
    configuration,
    redirectSettings: {
      scopes: appConfig.auth.scopes
    },
    graphApiEndpoint: appConfig.auth.graphApiEndpoint
  };
}

export function msalSettings(envType: string, appConfig: JemConfiguration): [boolean, MsalSettings] {
  return envType === "accessibility" || envType === "test" ? [true, {} as any] : [false, _msalSettings(appConfig)];
}
