import { JemConfiguration } from "../../../JemConfiguration";
import { APIAnomalyError } from "../../../Shared/utilities/ErrorHelpers";
import {
  getArrayFromCacheOrFromEndpoint,
  getObjectFromCacheOrFromEndpoint
} from "../../../Shared/utilities/LocalStorageCache";
import { getRequest, getValidUrl, IUserProviderState } from "../../../Shared/utilities/RequestUtilities";
import { IHCCDashboardConfiguration } from "./IHCCContext.types";

export async function fetchInitInfo(
  configuration: JemConfiguration,
  getTokenFn: IUserProviderState["accessToken"]
): Promise<IHCCDashboardConfiguration["Init"] | null> {
  const endpoint = `${configuration.IhccApi.baseApiUrl}${configuration.IhccApi.endpoints.initInfo}`;
  const endpointUrl = getValidUrl(endpoint);
  const payload = await getRequest<IHCCDashboardConfiguration["Init"]>(endpointUrl, getTokenFn);
  if (!payload || Object.keys(payload).length === 0) {
    return null;
  }

  return payload;
}

export async function getInitInfo(
  configuration: JemConfiguration,
  getTokenFn: IUserProviderState["accessToken"]
): Promise<IHCCDashboardConfiguration["Init"]> {
  const initInfo = await getObjectFromCacheOrFromEndpoint<IHCCDashboardConfiguration["Init"] | null, JemConfiguration>(
    configuration,
    "POInitInfo",
    fetchInitInfo,
    getTokenFn,
    configuration.IhccApi.cacheTimeInHours
  );
  if (!initInfo || Object.keys(initInfo).length === 0) {
    throw new APIAnomalyError("Received empty Object for IHCC Init Info");
  }
  return initInfo;
}

export async function fetchUiParams(
  configuration: JemConfiguration,
  getTokenFn: IUserProviderState["accessToken"]
): Promise<IHCCDashboardConfiguration["UIParameters"] | null> {
  const endpoint = `${configuration.IhccApi.baseApiUrl}${configuration.IhccApi.endpoints.uiParameters}`;
  const endpointUrl = getValidUrl(endpoint);
  const payload = await getRequest<IHCCDashboardConfiguration["UIParameters"]>(endpointUrl, getTokenFn);

  return payload;
}

export async function getUiParams(
  configuration: JemConfiguration,
  getTokenFn: IUserProviderState["accessToken"]
): Promise<IHCCDashboardConfiguration["UIParameters"]> {
  const uiParams = await getArrayFromCacheOrFromEndpoint<
    IHCCDashboardConfiguration["UIParameters"] | null,
    JemConfiguration
  >(configuration, "POUIParams", fetchUiParams, getTokenFn, configuration.IhccApi.cacheTimeInHours);
  if (!uiParams || uiParams.length === 0) {
    throw new APIAnomalyError("Received empty Array for IHCC UI Parameter Lists");
  }
  return uiParams;
}
