import React from "react";
import { useLocation } from "react-router-dom";
import { JemUserRoles } from "../../contexts/UserContext/UserContext.roles.main";
import { AppNavProps } from "./AppNav.types";

export const JEMChecklistLinks = (
  location: ReturnType<typeof useLocation>,
  onClickHandler: (route: string) => (event: React.MouseEvent | any) => void,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  props: AppNavProps,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  jemUserRoles: JemUserRoles
) => {
  const links = [
    {
      name: "Dashboard",
      key: "bpoDashboard",
      ariaLabel: "BPO Dashboard",
      // "data-id": "automation_id_22235",
      target: "_blank",
      onClick: onClickHandler("/jemchecklist/dashboard"),
      isSelected: location.pathname === "/jemchecklist/dashboard"
    }
  ];

  return {
    name: "JEMChecklist",
    key: "jemchecklist",
    ariaLabel: "JEMChecklist",
    icon: "EntitlementPolicy",
    // "data-id": "automation_id_22235",
    target: "_blank",
    links
  };
};
