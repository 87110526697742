import React from "react";
import { TextField, ITextField, Callout, DirectionalHint, ActionButton, Label, Stack, getId } from "@fluentui/react";

export interface IAutocompleteProps {
  id?: string;
  className?: string;
  name?: string;
  value?: string;
  required?: boolean;
  errorMessage?: string;
  label?: string;
  onChange?: (newValue?: string) => void;
  onBlur?: () => void;
  onSuggest?: (value?: string) => string[];
  disabled?: boolean;
  transformValue?: (newValue?: string) => string;
}

interface IAutocompleteState {
  errorMessage?: string;
  value?: string;
  isFocused: boolean;
  isCalloutHover: boolean;
  hideSuggestions: boolean;
  suggestions: string[];
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

export class Autocomplete extends React.Component<IAutocompleteProps, IAutocompleteState> {
  private readonly textFieldWrapperRef = React.createRef<HTMLDivElement>();
  public ref = React.createRef<ITextField>();
  private readonly textFieldId = getId("autocomplete-");
  private timeout: number | undefined;

  public state: IAutocompleteState = {
    errorMessage: this.props.errorMessage,
    value: this.props.value,
    isFocused: false,
    isCalloutHover: false,
    hideSuggestions: false,
    suggestions: []
  };

  public render(): React.ReactNode {
    // const { id, className = "", name, required, errorMessage, label, disabled = false } = this.props;
    const { id, className = "", name, required, label, disabled = false } = this.props;
    const suggestionButtons = this.renderSuggestionButtons(this.state.suggestions);
    const calloutWidth = this.textFieldWrapperRef.current ? this.textFieldWrapperRef.current.offsetWidth : undefined;
    const calloutHidden =
      this.state.hideSuggestions ||
      this.state.suggestions.length === 0 ||
      (!this.state.isFocused && !this.state.isCalloutHover);

    return (
      <React.Fragment>
        <Stack disableShrink>
          <Label htmlFor={`#${this.textFieldId}`} required={required}>
            {label}
          </Label>
          <div id={id} className={`autocomplete ${className}`} ref={this.textFieldWrapperRef}>
            <TextField
              componentRef={this.ref}
              id={this.textFieldId}
              ariaLabel={label}
              name={name}
              value={this.state.value}
              autoComplete="off"
              errorMessage={this.state.errorMessage}
              disabled={disabled}
              onChange={disabled ? noop : this.onChange}
              onFocus={disabled ? noop : this.onFocus}
              onBlur={disabled ? noop : this.onBlur}
              onKeyDown={disabled ? noop : this.onKeyDown}
            />
          </div>
          <Callout
            onMouseEnter={this.onCalloutMouseEnter}
            onMouseLeave={this.onCalloutMouseLeave}
            isBeakVisible={false}
            hidden={calloutHidden}
            target={this.textFieldWrapperRef.current}
            directionalHint={DirectionalHint.bottomLeftEdge}
            style={{ minWidth: calloutWidth }}
          >
            {suggestionButtons}
          </Callout>
        </Stack>
      </React.Fragment>
    );
  }

  private transformValue(newValue?: string): string {
    if (this.props.transformValue) return this.props.transformValue(newValue);
    return newValue || "";
  }

  public componentDidMount(): void {
    this.updateSuggestions();
  }

  public componentDidUpdate(oldProps: IAutocompleteProps, oldState: IAutocompleteState): void {
    if (oldProps.value !== this.props.value || oldProps.errorMessage !== this.props.errorMessage) {
      this.setState({ value: this.transformValue(this.props.value), errorMessage: this.props.errorMessage });
    }

    if (oldState.value !== this.state.value) {
      this.updateSuggestions();
    }
  }

  public componentWillUnmount(): void {
    window.clearTimeout(this.timeout);
  }

  private updateSuggestions() {
    const { onSuggest = () => [] } = this.props;

    window.clearTimeout(this.timeout);

    this.timeout = window.setTimeout(() => {
      this.timeout = undefined;
      this.setState(({ value }) => ({ suggestions: onSuggest(value) }));
    }, 100);
  }

  private readonly onChange = (_event: any, value = ""): void => {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const { onChange = () => {} } = this.props;

    this.setState({
      value: this.transformValue(this.props.value),
      errorMessage: this.props.errorMessage,
      hideSuggestions: false
    });

    onChange(value);
  };

  private readonly onFocus = () => this.setState({ isFocused: true, hideSuggestions: false });

  private readonly onBlur = () => this.setState({ isFocused: false });

  private readonly onCalloutMouseEnter = () => this.setState({ isCalloutHover: true });

  private readonly onCalloutMouseLeave = () => this.setState({ isCalloutHover: false });

  private readonly onKeyDown = (event: React.KeyboardEvent): void => {
    if (event.keyCode === 13 && !event.ctrlKey) {
      if (this.state.suggestions.length > 0) {
        this.setState({
          value: this.transformValue(this.state.suggestions[0]),
          hideSuggestions: true
        });
      }
    }
  };

  private renderSuggestionButtons(suggestions: string[]): React.ReactNode {
    return suggestions.map((text, i) => {
      const onClick = () => {
        this.onChange(null, text);
        this.setState({ hideSuggestions: true });
      };

      return (
        <ActionButton key={i} style={{ width: "100%", display: "block" }} onClick={onClick}>
          {text}
        </ActionButton>
      );
    });
  }
}
