import { CoherencePanel, CoherencePanelSize } from "@coherence-design-system/controls";
import { FontIcon, Label, Pivot, PivotItem, SelectionMode, Text, Selection } from "@fluentui/react";
import {
  DashboardGrid,
  DomainDataEnum,
  DomainDataObjects,
  IDashboardGridRef,
  IExtendedColumn,
  JEMChecklistDetailsRow,
  JemConfiguration
} from "@jem/components";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import ActionButtons from "../../copiedItems/ActionButtons";
import { ChecklistCloneSelector, IChecklistCloneSelectorRef } from "../ChecklistCloneSelector/ChecklistCloneSelector";
import InlineFormInputErrorMessage from "./InlineFormInputErrorMessage";
import { CloneResponse } from "./JEMChecklistDetails.Requests";

interface ExtendedSanitizedDashboardChecklist extends JEMChecklistDetailsRow {
  processingStatus: string;
  errorCode: string;
}
interface IJEMChecklistDetailsClonePanelProps {
  configuration: JemConfiguration["GeneralLedgerApi"];
  checklistRefGUIDSource: string;
  checklistNameSource: string;
  checklistPeriodSource: string;
  domainData: Pick<DomainDataObjects, DomainDataEnum.FiscalPeriods>;
  allItems: JEMChecklistDetailsRow[];
  checklistItems: JEMChecklistDetailsRow[];
  loading?: boolean;
  error: string[];
  previousCloneResponse: CloneResponse | null;
  onClosePanel: () => void;
  onSaveItem: (checklistRefGUIDSource: string, checklistRefGUIDTarget: string, taskRefGuids: string[]) => void;
}

const _columns = () => (): IExtendedColumn[] => {
  const defaultColumns: IExtendedColumn[] = [
    {
      key: "column1",
      name: "JETaskName",
      fieldName: "jeTaskName",
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      type: "string"
    },
    {
      key: "column2",
      name: "PublishedState",
      fieldName: "publishedState",
      minWidth: 120,
      maxWidth: 140,
      isResizable: true,
      type: "string"
    }
  ];
  defaultColumns.push({
    key: "column3",
    name: "Clone Result",
    fieldName: "processingStatus",
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
    onRender: (item: ExtendedSanitizedDashboardChecklist) => {
      return (
        <span style={{ color: item.errorCode && item.errorCode.indexOf("ERR") !== -1 ? "red" : "black" }}>
          {item.processingStatus}
        </span>
      );
    },
    type: "string"
  });
  return defaultColumns;
};

const extendRow = (
  previousCloneResponse: CloneResponse,
  x: ExtendedSanitizedDashboardChecklist,
  isStandardJe: boolean,
  isSelected: boolean
) => {
  const existingClone = previousCloneResponse.data.find((y) => y.refguid === x.refGuid);
  if (existingClone) {
    x.processingStatus = existingClone.errorDescription;
    x.errorCode = existingClone.errorcode;
  } else {
    if (isStandardJe) {
      x.processingStatus = "No Result Found";
      x.errorCode = "ERR";
    } else if (isSelected) {
      x.processingStatus = "No Result Found";
      x.errorCode = "ERR";
    } else {
      x.processingStatus = "";
      x.errorCode = "INF";
    }
  }
};

export const JEMChecklistDetailsClonePanel: React.FC<IJEMChecklistDetailsClonePanelProps> = (props) => {
  const {
    checklistRefGUIDSource,
    checklistNameSource,
    onClosePanel,
    onSaveItem,
    error,
    loading,
    previousCloneResponse
  } = props;
  const checklistSelectionRef = useRef<IChecklistCloneSelectorRef>(null);

  const [standardJes, setStandardJes] = useState([...props.allItems.filter((x) => !x.isAdHocDraft && x.isCloneable)]);
  const [adhocJes, setAdhocJes] = useState([...props.checklistItems]);
  const [selectedCount, setSelectedCount] = useState(0);
  const adhocDashboardGridRef = useRef<IDashboardGridRef>(null);

  useEffect(() => {
    if (!props.previousCloneResponse) {
      return;
    }

    const standardJes = props.allItems.filter((x) => !x.isAdHocDraft && x.isCloneable);
    const adhocJes = props.checklistItems;
    setStandardJes(standardJes);
    setAdhocJes(adhocJes);
  }, [props.allItems, props.checklistItems]);

  const onHandleSubmit = async () => {
    let selectedItems: JEMChecklistDetailsRow[] = [];
    if (adhocDashboardGridRef.current) {
      const selection = adhocDashboardGridRef.current.getSelection();
      selectedItems = selection.isAllSelected ? adhocJes : (selection.items as JEMChecklistDetailsRow[]);
    }

    if (checklistSelectionRef.current) {
      const sourceChecklist = checklistRefGUIDSource;
      const targetChecklist = checklistSelectionRef.current.getUserSelectedChecklist(true);
      // TODO: Handle errors
      if (targetChecklist) {
        onSaveItem(
          sourceChecklist,
          targetChecklist.checklistRefGuid,
          selectedItems.map((x) => x.refGuid)
        );
      }
    }
  };

  const standardJesCount = standardJes.length;

  const totalCount = adhocJes.length + standardJesCount;
  let standardJesNotCloned = 0;
  let adhocItemsNotCloned = 0;
  if (previousCloneResponse !== null) {
    standardJesNotCloned = (standardJes as ExtendedSanitizedDashboardChecklist[]).reduce((count, x) => {
      extendRow(previousCloneResponse, x, true, false);
      if (x.errorCode.includes("ERR")) {
        return count + 1;
      }
      return count;
    }, 0);
    if (adhocDashboardGridRef.current) {
      const selection = adhocDashboardGridRef.current.getSelection();
      const selectedAdhocItems = selection.isAllSelected ? adhocJes : (selection.items as JEMChecklistDetailsRow[]);
      adhocItemsNotCloned = (adhocJes as ExtendedSanitizedDashboardChecklist[]).reduce((count, x) => {
        const isSelected = selectedAdhocItems.some((y) => y.refGuid === x.refGuid);
        extendRow(previousCloneResponse, x, false, isSelected);
        if (x.errorCode.includes("ERR") && isSelected) {
          return count + 1;
        }
        return count;
      }, 0);
    }
  }

  const totalStandardClonedCount = standardJesCount - standardJesNotCloned;
  const totalAdhocClonedCount = selectedCount - adhocItemsNotCloned;

  const targetChecklist = checklistSelectionRef.current?.getUserSelectedChecklist(false);
  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.large}
      titleText="Clone JEM Checklist"
      isOpen
      onDismiss={() => {
        setStandardJes([]);
        setAdhocJes([]);
        onClosePanel();
      }}
      hasCloseButton
      closeButtonAriaLabel="Close Clone JEM Checklist"
      onRenderFooter={(): JSX.Element => (
        <ActionButtons
          disabled={totalCount === 0 || loading || previousCloneResponse !== null}
          mutationLoading={false}
          closePanel={() => {
            setStandardJes([]);
            setAdhocJes([]);
            onClosePanel();
          }}
          handleSubmit={onHandleSubmit}
          saveLabel="Clone"
          saveTitle="Clone"
          cancelLabel="Cancel"
          cancelTitle="Cancel"
        />
      )}
    >
      <InlineFormInputErrorMessage errorMessages={error} />
      <form onSubmit={onHandleSubmit}>
        <div className="ms-Grid" dir="ltr">
          <div className="ms-Grid-row">
            <Label>Source Checklist Name: {checklistNameSource}</Label>
            <Label>From Posting Period: {props.checklistPeriodSource}</Label>
          </div>
          <div className="ms-Grid-row">
            <ChecklistCloneSelector
              configuration={props.configuration}
              customRef={checklistSelectionRef}
              domainData={props.domainData}
              currentFiscalPeriod={props.checklistPeriodSource}
            />
          </div>
          {targetChecklist && (
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm12">
                <div
                  style={{
                    marginTop: "8px",
                    marginBottom: "8px"
                  }}
                >
                  <Label
                    style={{
                      marginRight: "8px"
                    }}
                  >
                    Navigate to Target Checklist:
                  </Label>
                  <Link
                    to={`/jemchecklist/details?checklistRefGuid=${targetChecklist.checklistRefGuid}`}
                    state={{
                      checklistName: targetChecklist.checklistName
                    }}
                    target="_blank"
                    style={{
                      display: "flex",
                      alignItems: "center"
                    }}
                  >
                    {targetChecklist.checklistName}
                    <FontIcon
                      iconName="OpenInNewTab"
                      style={{
                        marginLeft: "4px"
                      }}
                    />
                  </Link>
                </div>
              </div>
            </div>
          )}
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12">
              <div
                style={{
                  marginTop: "8px",
                  marginBottom: "8px"
                }}
              >
                {previousCloneResponse === null ? (
                  <>
                    <Label style={{ marginRight: "8px" }}>Total Adhoc items to Clone: {totalAdhocClonedCount}</Label>
                    <Label style={{ marginRight: "8px" }}>Total Standard items to Clone: {standardJes.length}</Label>
                  </>
                ) : (
                  <>
                    <Label style={{ marginRight: "8px" }}>
                      Adhoc Jes Cloned: {totalAdhocClonedCount}, Not Cloned: {adhocItemsNotCloned}
                    </Label>
                    <Label style={{ marginRight: "8px" }}>
                      Standard Jes Cloned: {totalStandardClonedCount}, Not Cloned: {standardJesNotCloned}
                    </Label>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12">
              {standardJes.length === 0 && adhocJes.length === 0 ? (
                <Label>Nothing to Copy</Label>
              ) : (
                <Pivot
                  styles={{
                    root: {
                      marginTop: "10px",
                      marginBottom: "10px"
                    }
                  }}
                >
                  {adhocJes.length > 0 && (
                    <PivotItem headerText={`Adhoc Je${standardJesCount > 1 ? "s" : ""}`} alwaysRender={true}>
                      <>
                        <Text variant="large">Only Selected tasks below will be cloned.</Text>
                        <DashboardGrid
                          idForLocalStorage="jemChecklistCloneAdhocConfiguration"
                          columnGenerator={_columns()}
                          items={adhocJes}
                          height={"390px"}
                          selectionMode={SelectionMode.multiple}
                          disableSorting={false}
                          disablePagination={false}
                          customRef={adhocDashboardGridRef}
                          onSelectionChanged={(selection: Selection) => {
                            const userSelectedItems = selection.getSelection();
                            if (selection.isAllSelected()) {
                              setSelectedCount(adhocJes.length);
                            } else {
                              setSelectedCount(userSelectedItems.length);
                            }
                          }}
                        />
                      </>
                    </PivotItem>
                  )}
                  {standardJes.length > 0 && (
                    <PivotItem headerText={`Standard Je${standardJesCount > 1 ? "s" : ""}`}>
                      <>
                        <Text variant="large">The following Standard JEs will be cloned.</Text>
                        <DashboardGrid
                          idForLocalStorage="jemChecklistCloneStandardConfiguration"
                          columnGenerator={_columns()}
                          items={standardJes}
                          height={"390px"}
                          selectionMode={SelectionMode.none}
                          disableSorting={false}
                          disablePagination={false}
                          customRef={null}
                        />
                      </>
                    </PivotItem>
                  )}
                </Pivot>
              )}
            </div>
          </div>
        </div>
      </form>
    </CoherencePanel>
  );
};
