import { ICommandBarItemProps } from "@fluentui/react";
import { IDashboardListActionsState } from "./DashboardListActions";
import { DashboardListActionsProps, GridCommandToggle } from "./DashboardListActions.types";

export interface OptionsToResize {
  mainContainerWidth: number;
  buttonsWidth: Record<string, number>;
}

export interface CommandBarItems {
  items: ICommandBarItemProps[];
  overFlowItems: ICommandBarItemProps[];
}

export type GetCommandBarButtonsFunction = (
  state: IDashboardListActionsState,
  props: DashboardListActionsProps,
  options?: OptionsToResize
) => CommandBarItems;

export const getCommandBarButtons: GetCommandBarButtonsFunction = (state, props, opts) => {
  const items: ICommandBarItemProps[] = [];
  const overFlowItems: ICommandBarItemProps[] = [];
  let totalWidth = 48;

  function pushToItemsOrToOverflow(buttonName: string, newCommand: ICommandBarItemProps) {
    if (opts && buttonName in opts.buttonsWidth) {
      const buttonWidth = opts.buttonsWidth[buttonName];
      const newTotalWidth = totalWidth + buttonWidth;
      if (opts.mainContainerWidth - newTotalWidth > 0) {
        items.push(newCommand);
        totalWidth = newTotalWidth;
      } else {
        overFlowItems.push(newCommand);
      }
    } else {
      items.push(newCommand);
    }
  }

  if (state.retryDisabled !== GridCommandToggle.Removed && props.retry) {
    const newCommand = {
      key: "retry",
      name: "Retry",
      ariaLabel: "Retry Entry",
      iconProps: { iconName: "Refresh" },
      onClick: props.retry,
      disabled: state.retryDisabled === GridCommandToggle.Disabled
    };
    pushToItemsOrToOverflow("Retry", newCommand);
  }
  if (state.saveDisabled !== GridCommandToggle.Removed && props.save) {
    const newCommand = {
      key: "save",
      name: "Save",
      ariaLabel: "Save",
      iconProps: { iconName: "Save" },
      onClick: props.save,
      disabled: state.saveDisabled === GridCommandToggle.Disabled
    };
    pushToItemsOrToOverflow("Save", newCommand);
  }
  if (state.sendToPosterDisabled !== GridCommandToggle.Removed && props.sendToPoster) {
    const newCommand = {
      key: "sendToPoster",
      name: "Send To Poster",
      ariaLabel: "Send to Poster",
      iconProps: { iconName: "MailForward" },
      onClick: props.sendToPoster,
      disabled: state.sendToPosterDisabled === GridCommandToggle.Disabled
    };
    pushToItemsOrToOverflow("Send To Poster", newCommand);
  }
  if (state.approveDisabled !== GridCommandToggle.Removed && props.approve) {
    const newCommand = {
      key: "approve",
      name: "Approve",
      ariaLabel: "Approve",
      iconProps: { iconName: "CheckMark" },
      onClick: props.approve,
      disabled: state.approveDisabled === GridCommandToggle.Disabled
    };
    pushToItemsOrToOverflow("Approve", newCommand);
  }
  if (state.releaseForSignoffDisabled !== GridCommandToggle.Removed && props.releaseForSignoff) {
    const newCommand = {
      key: "releaseForSignoff",
      name: "Release For Signoff",
      ariaLabel: "Release For Signoff",
      iconProps: { iconName: "PublishContent" },
      onClick: props.releaseForSignoff,
      disabled: state.releaseForSignoffDisabled === GridCommandToggle.Disabled
    };
    pushToItemsOrToOverflow("Release For Signoff", newCommand);
  }
  if (state.needsClarificationDisabled !== GridCommandToggle.Removed && props.needsClarification) {
    const newCommand = {
      key: "needsClarification",
      name: "Reject",
      ariaLabel: "Reject",
      iconProps: { iconName: "StatusCircleQuestionMark" },
      onClick: props.needsClarification,
      disabled: state.needsClarificationDisabled === GridCommandToggle.Disabled
    };
    pushToItemsOrToOverflow("Reject", newCommand);
  }
  if (state.addPosterDisabled !== GridCommandToggle.Removed && props.addPoster) {
    const newCommand = {
      key: "addPoster",
      name: "Add Poster",
      ariaLabel: "Add Poster",
      iconProps: { iconName: "PeopleAdd" },
      onClick: props.addPoster,
      disabled: state.addPosterDisabled === GridCommandToggle.Disabled
    };
    pushToItemsOrToOverflow("Add Poster", newCommand);
  }
  if (state.addReviewerDisabled !== GridCommandToggle.Removed && props.addReviewer) {
    const newCommand = {
      key: "addReviewer",
      name: "Add Reviewer",
      ariaLabel: "Add Reviewer",
      iconProps: { iconName: "Add" },
      onClick: props.addReviewer,
      disabled: state.addReviewerDisabled === GridCommandToggle.Disabled
    };
    pushToItemsOrToOverflow("Add Reviewer", newCommand);
  }
  if (state.recallDisabled !== GridCommandToggle.Removed && props.recall) {
    const newCommand = {
      key: "recall",
      name: "Recall",
      ariaLabel: "Recall",
      iconProps: { iconName: "Undo" },
      onClick: props.recall,
      disabled: state.recallDisabled === GridCommandToggle.Disabled
    };
    pushToItemsOrToOverflow("Recall", newCommand);
  }
  if (state.rescindDisabled !== GridCommandToggle.Removed && props.rescind) {
    const newCommand = {
      key: "rescind",
      name: "Rescind",
      ariaLabel: "Rescind",
      iconProps: { iconName: "Refresh" },
      onClick: props.rescind,
      disabled: state.rescindDisabled === GridCommandToggle.Disabled
    };
    pushToItemsOrToOverflow("Rescind", newCommand);
  }
  if (state.addAttachmentDisabled !== GridCommandToggle.Removed && props.addAttachment) {
    const newCommand = {
      key: "addAttachment",
      name: "Add Attachment",
      ariaLabel: "Add Attachment",
      iconProps: { iconName: "Attach" },
      onClick: props.addAttachment,
      disabled: state.addAttachmentDisabled === GridCommandToggle.Disabled
    };
    pushToItemsOrToOverflow("Add Attachment", newCommand);
  }
  if (state.deleteActionDisabled !== GridCommandToggle.Removed && props.deleteAction) {
    const newCommand = {
      key: "deleteAction",
      name: "Delete",
      ariaLabel: "Delete",
      iconProps: { iconName: "Delete" },
      onClick: props.deleteAction,
      disabled: state.deleteActionDisabled === GridCommandToggle.Disabled
    };
    pushToItemsOrToOverflow("Delete Action", newCommand);
  }
  if (state.releaseToSAPDisabled !== GridCommandToggle.Removed && props.releaseToSAP) {
    const newCommand = {
      key: "releaseToSAP",
      name: "Release To SAP",
      ariaLabel: "Release To SAP",
      iconProps: { iconName: "ReleaseGate" },
      onClick: props.releaseToSAP,
      disabled: state.releaseToSAPDisabled === GridCommandToggle.Disabled
    };
    pushToItemsOrToOverflow("Release To SAP", newCommand);
  }
  if (state.sendBackFromTreasuryDisabled !== GridCommandToggle.Removed && props.sendBackFromTreasury) {
    const newCommand = {
      key: "sendBackFromTreasury",
      name: "Needs Clarification",
      ariaLabel: "Needs Clarification",
      iconProps: { iconName: "StatusCircleQuestionMark" },
      onClick: props.sendBackFromTreasury,
      disabled: state.sendBackFromTreasuryDisabled === GridCommandToggle.Disabled
    };
    pushToItemsOrToOverflow("Needs Clarification", newCommand);
  }
  if (state.recallPreReviewDisabled !== GridCommandToggle.Removed && props.recallPreReview) {
    const newCommand = {
      key: "recallPreReview",
      name: "Recall",
      ariaLabel: "Recall",
      iconProps: { iconName: "Undo" },
      onClick: props.recallPreReview,
      disabled: state.recallPreReviewDisabled === GridCommandToggle.Disabled
    };
    pushToItemsOrToOverflow("Recall", newCommand);
  }
  if (state.approvePreReviewDisabled !== GridCommandToggle.Removed && props.approvePreReview) {
    const newCommand = {
      key: "approvePreReview",
      name: "Approve",
      ariaLabel: "Approve",
      iconProps: { iconName: "CheckMark" },
      onClick: props.approvePreReview,
      disabled: state.approvePreReviewDisabled === GridCommandToggle.Disabled
    };
    pushToItemsOrToOverflow("Approve", newCommand);
  }
  if (state.needsClarificationPreReviewDisabled !== GridCommandToggle.Removed && props.needsClarificationPreReview) {
    const newCommand = {
      key: "needsClarificationPreReview",
      name: "Reject",
      ariaLabel: "Reject",
      iconProps: { iconName: "StatusCircleQuestionMark" },
      onClick: props.needsClarificationPreReview,
      disabled: state.needsClarificationPreReviewDisabled === GridCommandToggle.Disabled
    };
    pushToItemsOrToOverflow("Reject", newCommand);
  }
  if (state.reverseDisabled !== GridCommandToggle.Removed && props.reverse) {
    const newCommand = {
      key: "reverse",
      name: "Reverse",
      ariaLabel: "Reverse",
      iconProps: { iconName: "Undo" },
      onClick: props.reverse,
      disabled: state.reverseDisabled === GridCommandToggle.Disabled
    };
    pushToItemsOrToOverflow("Reverse", newCommand);
  }
  return {
    items,
    overFlowItems
  };
};
