import { Tenants } from "@jem/components";
import { DashboardRow, GridAction, SanitizedRow } from "../IHCCDashboard/IHCCDashboard.types";

export class SearchState {
  Status: number[];
  StartPONumber: string;
  POType: string;
  AliasType: "P" | "R";
  Aliases: string[];
  EndPONumber: string;
  Significance: string[];
  CompanyCode: number;
  FiscalYear: number;
  FiscalPeriods: number[];
  TenantId: number;
  constructor(opts: {
    Status: number[];
    StartPONumber: string;
    POType: string;
    AliasType: "P" | "R";
    Aliases: string[];
    EndPONumber: string;
    Significance: string[];
    CompanyCode: number;
    FiscalYear: number;
    FiscalPeriods: number[];
    TenantId: number;
  }) {
    this.Status = opts.Status;
    this.StartPONumber = opts.StartPONumber;
    this.POType = opts.POType;
    this.AliasType = opts.AliasType;
    this.Aliases = opts.Aliases;
    this.EndPONumber = opts.EndPONumber;
    this.Significance = opts.Significance;
    this.CompanyCode = opts.CompanyCode;
    this.FiscalYear = opts.FiscalYear;
    this.FiscalPeriods = opts.FiscalPeriods;
    this.TenantId = opts.TenantId;
    if (
      [
        this.Status,
        this.StartPONumber,
        this.POType,
        this.AliasType,
        this.Aliases,
        this.EndPONumber,
        this.Significance,
        this.CompanyCode,
        this.FiscalYear,
        this.FiscalPeriods,
        this.TenantId
      ].some((val) => val === undefined)
    ) {
      throw new Error("SearchState does not accept undefined values");
    }
  }
}
export const InitialSearchState = new SearchState({
  Status: [],
  StartPONumber: "",
  POType: "BO",
  AliasType: "P",
  Aliases: [],
  EndPONumber: "",
  Significance: [],
  CompanyCode: 0,
  FiscalYear: new Date().getFullYear(),
  FiscalPeriods: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  TenantId: -1
});

export interface ISearchStateForApi {
  POStatus: number[];
  StartPONumber: string;
  POType: string;
  AliasType: 1 | "2";
  Alias: string;
  EndPONumber: string;
  POCategory: string[];
  CompanyCode: number;
  FiscalYear: number;
  FiscalValue: number[];
  BankArea: "IHCC";
  TenantId: number | null;
}

export interface IHCCSearchApiPayload {
  gridActions: GridAction[];
  result: DashboardRow[];
}

export interface IHCCSanitizedSearchGrid {
  gridActions: GridAction[];
  result: SanitizedRow[];
}

export const POTypeOptions = [
  { key: "BO", text: "Both" },
  { key: "O", text: "Original" },
  { key: "X", text: "Reversal" }
];

export const convertToFiscalYearOptionSearch = (fiscalYears: number[]) => {
  const fiscalYearDropdownObject = [];

  for (let i = 0; i < fiscalYears.length; i++) {
    fiscalYearDropdownObject.push({ key: fiscalYears[i], text: `${fiscalYears[i]}` });
  }

  return fiscalYearDropdownObject;
};

export const convertToTenantOptionSearch = (tenants: Tenants[]) => {
  const tenantDropdownObject = [];

  tenantDropdownObject.unshift({ key: -1, text: "None Selected" });

  for (let i = 0; i < tenants.length; i++) {
    tenantDropdownObject.push({ key: tenants[i].id, text: `${tenants[i].tenantName}` });
  }

  return tenantDropdownObject;
};
