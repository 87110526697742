import { IDropdownOption, mergeStyleSets } from "@fluentui/react";
import { JemConfiguration } from "../../JemConfiguration";
import { getValidUrl, IUserProviderState, putRequest } from "../../Shared";

export const popupStyles = mergeStyleSets({
  root: {
    background: "rgba(0, 0, 0, 0.2)",
    bottom: "0",
    left: "0",
    position: "fixed",
    right: "0",
    top: "0"
  },
  content: {
    background: "white",
    left: "50%",
    maxWidth: "850px",
    padding: "0 2em 2em",
    position: "absolute",
    top: "50%",
    transform: "translate(-50%, -50%)"
  }
});

const options: string[] = [
  "JE not needed this period as per agreed frequency",
  "No activity this period",
  "Late entry request not approved by FC",
  "Late entry request not approved by Corp",
  "Other",
  "Posted as batch JE"
];

export const noActivityReasonOptions: IDropdownOption[] = options.map((item) => {
  return {
    key: item,
    text: item
  };
});

export interface NoActivityOptions {
  refguid: string;
  type: "je" | "batch";
  reason: string;
  //comments;
  configuration: JemConfiguration["GeneralLedgerApi"];
  accessToken: IUserProviderState["accessToken"];
}

export interface INoActivityPayload {
  jeDrafts: string[];
  batchDrafts: string[];
  reason: string;
  comments: string;
}

export interface NoActivityResponse {
  status: boolean;
  message: string;
  errorCode: string;
}

export async function noActivityAction(options: NoActivityOptions) {
  /*
  const endpoint = `${options.configuration.baseApiUrl}${options.configuration.endpoints.fcwPutNoActivity}`
    .replace("{refguid}", options.refguid)
    .replace("{jetype}", options.type);
  const endpointUrl = getValidUrl(endpoint);
  const response = await putRequest<NoActivityResponse>(endpointUrl, undefined, options.accessToken);
  if (!response) {
    throw new Error("NoActivity not found.");
  }

  return response;*/

  const noActivityRequestPayload: INoActivityPayload = {
    jeDrafts: options.type == "je" ? [options.refguid] : [],
    batchDrafts: options.type != "je" ? [options.refguid] : [],
    reason: options.reason != null && options.reason != "" ? options.reason : "Mark No Activity multiple",
    comments: "marked as no activity for this period"
  };
  const endpoint = `${options.configuration.baseApiUrl}${options.configuration.endpoints.fcwPutNoActivityWithPayload}`;
  const endpointUrl = getValidUrl(endpoint);

  const response = await putRequest<NoActivityResponse>(endpointUrl, noActivityRequestPayload, options.accessToken);
  if (!response) {
    throw new Error("NoActivity not found.");
  }
  return response;
}
