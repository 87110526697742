import { IDetailsList } from "@fluentui/react";

/** DetailsList does not support setting the column width on load, but it supports "a recommendation" of widths on load.
 * This means that minWidth in the Column Configuration will be taken as "Current Width" on load, with the major
 * caveat that it will will render 100% of the width and no more. If your minWidths add more than 100%, the DetailsList
 * will drop or adjust as follows:
 *  - If you set the column as justified, it will resize columns for you to fit them in 100%, but it will not
 *    allow resizing above 100%.
 *  - If you set the columns as fixed, it will drop columns if they take more than 100%, but it will allow
 *    resizing above 100%.
 * The solution is to define both the minWidth in the Columns (and keep track of it), and to keep track of the
 * FULL WIDTH of the list. We set both on load, this allows the DetailList to take an initial column width on
 * load.
 * For more info on why is this not supported: https://github.com/microsoft/fluentui/issues/15971
 * Regardless... this method works, even if a little brittle.
 * @param detailsListComponentRef the componentRef arg of DetailsList
 * @returns The "calculatedWidth" of the List
 */
export function dangerZoneWidthCalculationNoTypeSafety(detailsListComponentRef: React.RefObject<IDetailsList>): string {
  const THEPADDINGOFYOURCOLUMNSADJUSTACCORDINGLY = 20;
  if (detailsListComponentRef.current) {
    const detailsComponent = detailsListComponentRef.current as any;
    if (detailsComponent.state && Array.isArray(detailsComponent.state.adjustedColumns)) {
      const columnsWidth = detailsComponent.state.adjustedColumns.reduce((width: number, col: any) => {
        width += col.calculatedWidth + THEPADDINGOFYOURCOLUMNSADJUSTACCORDINGLY;
        return width;
      }, 50);
      if (!isNaN(columnsWidth)) {
        return `${columnsWidth}px`;
      }
    }
  }
  return "100%";
}
