import { Link, Theme, Text, TooltipHost } from "@fluentui/react";
import React from "react";
import { IExtendedColumn } from "../DashboardGrid";
import { AttachmentUtil } from "./Attachments.AttachmentUtil";
import {
  AttachmentForUI,
  DocumentStatus,
  DownloadFunction,
  GLAttachment,
  IAttachment,
  IAttachmentEntryFormProps,
  InternalAttachment
} from "./Attachments.types";

function DocumentStatusToString(status: DocumentStatus) {
  return {
    1: "Not Uploaded",
    2: "Upload Link",
    3: "Upload In Progress",
    4: "Uploaded"
  }[status];
}

export function getAttachmentDefaultColumns<AttachmentType extends GLAttachment | IAttachment>(
  remoteAttachments: InternalAttachment<AttachmentType>[],
  onDownloadFile: IAttachmentEntryFormProps["onDownloadFile"],
  theme: Theme,
  isGeneralLedger: boolean,
  _setRemoteAttachments: React.Dispatch<React.SetStateAction<InternalAttachment<AttachmentType>[]>>
): IExtendedColumn[] {
  const defaultColumns: IExtendedColumn[] = [
    {
      key: "fileName",
      name: "File Name",
      fieldName: "fileName",
      minWidth: 100,
      type: "string",
      isResizable: true,
      onRender: (item: AttachmentForUI<AttachmentType>) => {
        if (item.status === DocumentStatus.Uploaded) {
          return (
            <TooltipHost
              content={
                <Link
                  style={{ color: theme.palette.blueDark }}
                  onClick={() => {
                    const remoteAtt = remoteAttachments.filter((att) => att.guid === item.guid);
                    if (remoteAtt.length === 1) {
                      AttachmentUtil.attachmentDownload<AttachmentType>(
                        item.url,
                        item.fileName,
                        onDownloadFile as DownloadFunction<AttachmentType>,
                        item.region
                      );
                    }
                  }}
                >
                  {item.fileName}
                </Link>
              }
            >
              <Link
                style={{ color: theme.palette.blueDark }}
                onClick={async () => {
                  const remoteAtt = remoteAttachments.filter((att) => att.guid === item.guid);
                  if (remoteAtt.length === 1) {
                    try {
                      await AttachmentUtil.attachmentDownload(
                        item.url,
                        item.fileName,
                        onDownloadFile as DownloadFunction<AttachmentType>,
                        item.region
                      );
                    } catch (e) {
                      // change the remoteAttachments errorMessage to show the error
                      _setRemoteAttachments((prev) => {
                        const newRemoteAttachments = [...prev];
                        const index = newRemoteAttachments.findIndex((att) => att.guid === item.guid);
                        if (index !== -1) {
                          newRemoteAttachments[index].processingStatus = "Could not download file";
                          newRemoteAttachments[index].procStatType = "Error";
                        }
                        return newRemoteAttachments;
                      });
                    }
                  }
                }}
              >
                {item.fileName}
              </Link>
            </TooltipHost>
          );
        } else {
          return <TooltipHost content={item.fileName}>{item.fileName}</TooltipHost>;
        }
      }
    },
    {
      key: "fileSize",
      name: "Size (KB)",
      fieldName: "size",
      minWidth: 50,
      type: "number",
      isResizable: true
    },
    {
      key: "status",
      name: "Status",
      fieldName: "status",
      minWidth: 65,
      type: "string",
      isResizable: true,
      onRender: (item) => {
        const status = DocumentStatusToString(item.status);
        return (
          <TooltipHost content={status}>
            <span>{status}</span>
          </TooltipHost>
        );
      }
    },
    {
      key: "uploadedOn",
      name: "Date Attached ",
      fieldName: "uploadDatetime",
      minWidth: 65,
      type: "date",
      isResizable: true
    },
    {
      key: "typeId",
      name: "Document Type",
      ariaLabel: "Document Type",
      fieldName: "documentType",
      type: "string",
      minWidth: 70
    },
    {
      key: "error",
      name: "Error",
      fieldName: "processingStatus",
      type: "string",
      minWidth: 80,
      isResizable: true,
      onRender: (item) => {
        const procColor = item.procStatType == "Success" ? theme.palette.green : theme.palette.red;
        return (
          <TooltipHost content={item.processingStatus}>
            <Text
              variant="small"
              style={{
                color: procColor
              }}
            >
              {item.processingStatus}
            </Text>
          </TooltipHost>
        );
      }
    }
  ];

  if (isGeneralLedger) {
    defaultColumns.unshift({
      key: "fiscalPeriod",
      name: "Period Attached",
      fieldName: "fiscalPeriod",
      minWidth: 65,
      type: "string",
      isResizable: true,
      onRender: (item: InternalAttachment<AttachmentType>) => {
        if (!item.fiscalPeriod) {
          const pattern = /(\d{4})\/(\d{1,2})\//;
          const matches = pattern.exec(item.url);
          if (!matches || matches.length !== 3) {
            return;
          }
          const year = matches[1];
          const month = matches[2];
          const fp = `${year}/${month}`;
          return (
            <TooltipHost content={fp}>
              <span>{fp}</span>
            </TooltipHost>
          );
        }
        return (
          <TooltipHost content={item.fiscalPeriod}>
            <span>{item.fiscalPeriod}</span>
          </TooltipHost>
        );
      }
    });
  }

  return defaultColumns;
}
