import { css } from "@emotion/css";
import { MessageBar, MessageBarType, Pivot, PivotItem, Separator, useTheme } from "@fluentui/react";
import { SeverityLevel } from "@microsoft/applicationinsights-web";

import { Form, Formik, FormikProps } from "formik";
import React, { useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";

import { JemConfiguration } from "../../../JemConfiguration";
import {
  ApiError,
  GetJeStatusFromRefGuid,
  IconMappingForSignificance,
  LoadingStatus,
  Section,
  SectionRef
} from "../../../Shared";
import { PageHeading } from "../../../Shared/components/PageHeading/PageHeading";
import { DomainDataEnum, DomainDataObjects } from "../../../Shared/contexts/JEMContext/JEMContext.domainData.types";
import { LoggingContext } from "../../../Shared/contexts/LoggingContext/LoggingContext";
import { UserContext } from "../../../Shared/contexts/UserContext/UserContext";
import { stringToStatusCode } from "../../../Shared/utilities/IStatusCodes";
import { PageStyles } from "../../../Shared/utilities/PageUtilities";
import { LocalMessage } from "../../../Shared/utilities/SharedModels";
import { useQuery } from "../../../Shared/hooks/useQuery";
import { useActionWeightages } from "../../hooks/useActionWeightages";
import { useGLAttachmentActionsRequests } from "../../shared/GL.AttachmentActionsRequests";
import { GLAttachments, GLAttachmentsRef, GLAttachmentRequiredItems } from "../../shared/GL.Attachments";
import { AdditionalReverseJEData, GLAttachmentEndpointMap } from "../../shared";
import { glDetailsOfflineValidation } from "../../validation/CheckForErrorsInTabs";
import { UIActionDelegation } from "./Actions";
import { useGLDetailsActions } from "./Actions/GLDetailsActions";
import { GLDetailsActions, GLDetailsActionsEnum } from "./GLDetails.Actions";
import { getJEDetailsFromSearch } from "./GLDetails.docnumber.requests";
import { JEDetailsTitle } from "./GLDetails.JEDetailsTitle";
import { fetchJeDetails } from "./GLDetails.jeid.requests";
import {
  GLJeDetailsSettingsFromDocNumber,
  GLJeDetailsSettingsFromJeId,
  glJeDetailsToFormikValues,
  placeholderStateForJeDetails
} from "./GLDetails.jeid.State";
import { GLJeDetailsFormikState, GLJeDetailsState, JeDetailsTabs, JeModuleTypeToNumber } from "./GLDetailsForm.types";
import { GLSapDetails } from "./GLDetailsSapDetails/GLDetails.SapDetails";
import { GLDetailsTab } from "./GLDetailsTabs/GLDetails.Tab.Details";
import { OtherDetailsTab } from "./GLDetailsTabs/GLDetails.Tab.OtherDetails";
import { ReviewFormTab } from "./GLDetailsTabs/GLDetails.Tab.Review";

export interface GLDetailsRef {
  removeCache: () => void;
}

export interface GLJeDetailsProps {
  configuration: JemConfiguration["GeneralLedgerApi"];
  attachmentsConfiguration: JemConfiguration["DocumentsApi"];
  domainData: Pick<
    DomainDataObjects,
    | DomainDataEnum.FiscalPeriods
    | DomainDataEnum.JeStatus
    | DomainDataEnum.StatusActions
    | DomainDataEnum.JeClarificationCodes
  > | null;
  onNavigate: () => void;
  onNavigateBack: () => void;
  settings: GLJeDetailsSettingsFromJeId | GLJeDetailsSettingsFromDocNumber | GLJeDetailsSettingsFromRefGuid | null;
  significanceIcons: IconMappingForSignificance;
  customRef?: React.MutableRefObject<GLDetailsRef | null>;
}

export interface GLJeDetailsSettingsFromRefGuid {
  refGuid: string;
  tabName: JeDetailsTabs;
}

const GLDetailsForm: React.FC<GLJeDetailsProps> = (props) => {
  const theme = useTheme();
  const logger = useContext(LoggingContext);
  const userContext = useContext(UserContext);
  const formikRef = useRef<FormikProps<GLJeDetailsFormikState>>(null);
  const sectionRef = useRef<SectionRef>(null);
  const domainData = props.domainData || {
    FiscalPeriods: [],
    StatusActions: [],
    JeStatus: [],
    JeClarificationCodes: []
  };

  const initialData = useMemo<GLJeDetailsState>(() => {
    return placeholderStateForJeDetails(domainData);
  }, [props.domainData]);

  let cacheKey:
    | [string]
    | [string, string]
    | [string, GLJeDetailsSettingsFromDocNumber]
    | [string, GLJeDetailsSettingsFromRefGuid] = ["jedetails"];
  if (props.settings !== null && "jeid" in props.settings) {
    const settingsFromJeId = props.settings as GLJeDetailsSettingsFromJeId;
    cacheKey = ["jedetails", settingsFromJeId.jeid];
  } else if (props.settings !== null && "DocNumber" in props.settings) {
    const settingsFromDocNumber = props.settings as GLJeDetailsSettingsFromDocNumber;
    cacheKey = ["jedetails", settingsFromDocNumber];
  } else if (props.settings !== null && "refGuid" in props.settings) {
    const settingsFromRefGuid = props.settings as GLJeDetailsSettingsFromRefGuid;
    cacheKey = ["jedetails", settingsFromRefGuid];
  }

  const sapModuleFromSearchPayload =
    props.settings != null ? (props.settings as GLJeDetailsSettingsFromDocNumber).SAPModule : "FI"; //if SAP module is not coming from the object then default it to FI

  const { isLoading, data, error, isFetching, refetch, dataUpdatedAt, removeCache } = useQuery<GLJeDetailsState>({
    queryKey: cacheKey,
    queryFn: async (queryOptions) => {
      const [, settings] = queryOptions.queryKey as [
        string,
        GLJeDetailsSettingsFromJeId | GLJeDetailsSettingsFromDocNumber | GLJeDetailsSettingsFromRefGuid
      ];
      if (typeof settings === "string") {
        const jeid = settings;
        return fetchJeDetails(props.configuration, userContext.accessToken, domainData, jeid, props.significanceIcons);
      }
      if ("refGuid" in settings) {
        const refGuid = settings.refGuid;
        const checkStatus = await GetJeStatusFromRefGuid(props.configuration, refGuid, userContext.accessToken);
        if (!checkStatus.jeId) {
          throw new ApiError("Details not found.", {
            refGuid: refGuid,
            jeid: checkStatus.jeId
          });
        }
        return fetchJeDetails(
          props.configuration,
          userContext.accessToken,
          domainData,
          checkStatus.jeId.toString(),
          props.significanceIcons
        );
      }
      const fromDocNumber = settings as GLJeDetailsSettingsFromDocNumber;
      const detailsFromSearch = await getJEDetailsFromSearch(
        props.configuration,
        domainData,
        props.significanceIcons,
        fromDocNumber,
        userContext.accessToken
      );
      if (!detailsFromSearch || (Array.isArray(detailsFromSearch) && detailsFromSearch.length === 0)) {
        throw new ApiError("Details not found.", {
          DocNumber: fromDocNumber.DocNumber
        });
      }

      const jeid = detailsFromSearch.jeId;
      if (!jeid) {
        return detailsFromSearch;
      }
      return fetchJeDetails(
        props.configuration,
        userContext.accessToken,
        domainData,
        `${jeid}`,
        props.significanceIcons
      );
    },
    enabled: props.settings !== null && props.domainData !== null ? true : false,
    staleTime: 1000 * 60 * 20
  });

  const details = (data as GLJeDetailsState | null) || initialData;

  useImperativeHandle(props.customRef, () => ({
    removeCache: () => {
      console.log("removing cache");
      removeCache();
    },
    expand: () => {
      window.requestAnimationFrame(() => {
        if (sectionRef.current) {
          sectionRef.current.expand();
        }
      });
    }
  }));

  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.Pending);
  const [disabled, setDisabledPage] = useState<boolean>(true);

  const [formikState, setFormikState] = useState<GLJeDetailsFormikState | null>(null);
  const [currentTab, setCurrentTab] = useState<JeDetailsTabs>(
    props.settings && "tabName" in props.settings ? props.settings.tabName : JeDetailsTabs.jeDetails
  );

  const actions = useGLDetailsActions(props.configuration);

  const actionWeightages = useActionWeightages(domainData);

  const [userAction, setUserAction] = useState<null | GLDetailsActionsEnum>(null);
  const [actionSettings, setActionSettings] = useState<null | {
    userAction: GLDetailsActionsEnum;
    options: AdditionalReverseJEData;
  }>(null);
  const [message, setMessage] = useState<LocalMessage | null>(null);

  const initialValues = glJeDetailsToFormikValues(details);

  const attachmentsRef = useRef<GLAttachmentsRef>(null);

  const attachmentActions = useGLAttachmentActionsRequests(
    props.attachmentsConfiguration,
    `${props.configuration.baseApiUrl}${props.configuration.endpoints.attachmentDelete}`
  );

  const handleClick = (item?: PivotItem) => {
    if (item !== undefined) {
      const tileName = item.props.itemKey as JeDetailsTabs;
      setCurrentTab(tileName);
    }
  };

  useEffect(() => {
    if (data !== undefined && props.settings !== null) {
      refetch();
    }
    if (message !== null) setMessage(null);
  }, [props.settings]);

  useEffect(
    function handleJeidChangesAndNavigation() {
      if (isLoading || isFetching || props.settings === null) {
        if (loadingStatus !== LoadingStatus.Pending) setLoadingStatus(LoadingStatus.Pending);
        return;
      }

      if (!isLoading && !isFetching && props.settings === null && data === null) {
        // setPreviousActionResult(null);
        setMessage(null);
        // props.onNavigate(data.pageConfiguration.refGuid, false);
        return;
      }

      if (error) {
        setMessage({
          type: MessageBarType.error,
          message: (error as unknown as Error).message || "An error occurred while loading the data."
        });
      } else {
        if (!data || props.settings === null) return;

        if (data.jeId === 0) {
          setMessage({
            type: MessageBarType.info,
            message: "JE not found in JEM, retrieving from SAP."
          });
        }

        const newFormikState = glJeDetailsToFormikValues(data);
        setFormikState({ ...newFormikState });
      }

      const timer = setTimeout(() => {
        setLoadingStatus(LoadingStatus.Resolved);

        setDisabledPage(false);
      }, 150);

      return () => {
        if (timer) clearTimeout(timer);
      };
    },
    [data, isFetching, isLoading, error]
  );

  useEffect(
    function processAction() {
      // The effect has to depend on myPageValues.  If you're here for a change of value rather than a real action, just leave.
      if (userAction === null) return;

      // Each action must reset the action to NONE to avoid repeating the action if the values change again.
      setUserAction(null);

      // Send the values to the right place.
      if (userAction === GLDetailsActionsEnum.ViewFile) {
        setCurrentTab(JeDetailsTabs.attachments);
        return;
      }

      if (!formikRef.current) {
        // This should never happen.
        logger.appInsights?.trackException({ exception: new Error("formikRef.current is null") });
        return;
      }
      if (!attachmentsRef.current) {
        // This should never happen
        logger.appInsights?.trackException({ exception: new Error("attachmentsRef.current is null") });
        return;
      }

      setLoadingStatus(LoadingStatus.Pending);
      setMessage(null);

      UIActionDelegation({
        userAction: userAction,
        actionOptions: actionSettings?.options,
        userChanges: formikRef.current.values,
        attachmentsRef: attachmentsRef.current,
        initialState: details,
        configuration: props.configuration,
        userContext,
        domainData,
        actions,
        actionWeightages
      })
        .then((result) => {
          setMessage(result.message);

          if (props.settings) {
            refetch();
            if (result.blockPage) {
              setDisabledPage(true);
            }
          }
        })
        .catch((e) => {
          logger.appInsights?.trackException({
            exception: e,
            severityLevel: SeverityLevel.Error,
            properties: {
              userAction: userAction,
              refGuid: details.refGuid
            }
          });
          const message =
            e instanceof ApiError
              ? e.message
              : `An error occurred while processing your request. Please try again later.`;
          setMessage({
            message: message,
            type: MessageBarType.error
          });
          if (userAction !== GLDetailsActionsEnum.Save) {
            refetch();
          }
        })
        .finally(() => {
          setLoadingStatus(LoadingStatus.Resolved);
        });
    },
    [actionSettings, setFormikState]
  );

  return (
    <React.Fragment>
      <div className={PageStyles.glPage}>
        <Formik<GLJeDetailsFormikState>
          enableReinitialize={true}
          initialValues={formikState === null ? initialValues : formikState}
          onSubmit={setFormikState}
          innerRef={formikRef}
          validateOnChange={false}
          validateOnBlur={true}
          validateOnMount={props.settings ? true : false}
          validationSchema={glDetailsOfflineValidation({
            author: details.header.author
          })}
        >
          {(formik) => (
            <Form>
              <PageHeading
                onClose={
                  props.settings !== null && "DocNumber" in props.settings
                    ? undefined
                    : () => {
                        props.onNavigate();
                      }
                }
                closeLabel=""
                overrideRootStyles={css`
                  margin: 0;
                  background-color: ${theme.palette.neutralLighterAlt};
                `}
              >
                <GLDetailsActions
                  onAction={async function handleAction(actionName: GLDetailsActionsEnum, options: any): Promise<void> {
                    await formik.submitForm();
                    setUserAction(actionName);
                    setActionSettings({ userAction: actionName, options: options });
                  }}
                  actionWeightages={actionWeightages}
                  rowWeightage={details.rowWeightage}
                  jeStatusCode={stringToStatusCode(domainData, details.header.status)}
                  opsDetailsName={
                    details.detailsTab.opsDetailName === "" || details.detailsTab.opsDetailName === "—"
                      ? "Not Applicable"
                      : details.detailsTab.opsDetailName
                  }
                  reverseJENumber={details.detailsTab.reversalJe}
                  initialAttachments={details.attachments}
                  initialRegion={details.attachments_region.toString()}
                  items={[
                    {
                      fiscalPeriod: details.detailsTab.fiscalPeriod,
                      fiscalYear: details.detailsTab.fiscalYear,
                      refGuid: details.refGuid,
                      jeId: details.jeId.toString(),
                      format: `${details.postingType}`
                    }
                  ]}
                  lockRegion={details.lock_region}
                  onDeleteFile={attachmentActions.deleteAttachment}
                  onUploadFile={attachmentActions.uploadAttachment}
                  onDownloadFile={attachmentActions.downloadAttachment}
                />
              </PageHeading>
              <div
                ref={null}
                className={css`
                  width: 100%;
                  min-height: 1px;
                  box-sizing: border-box;
                  overflow: hidden;
                `}
              >
                <Separator
                  styles={{
                    root: {
                      margin: "0px",
                      padding: "0px",
                      height: "1px",
                      backgroundColor: theme.palette.neutralLighterAlt,
                      ":before": {
                        backgroundColor: theme.palette.neutralTertiaryAlt
                      }
                    }
                  }}
                />
              </div>
              <div
                className={css`
                  padding: 0 16px 0 16px;
                  padding-top: 0px;
                  background-color: ${theme.palette.neutralLighterAlt};
                `}
              >
                <JEDetailsTitle
                  theme={theme}
                  state={details}
                  disabled={disabled}
                  loadingStatus={loadingStatus}
                  hiddenBackButton={props.settings !== null && "DocNumber" in props.settings}
                  lastRefreshed={dataUpdatedAt}
                  onRefresh={refetch}
                  onNavigateBack={props.onNavigateBack}
                />
              </div>
              {!message ? null : (
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm12">
                    <MessageBar
                      messageBarType={message.type}
                      isMultiline={false}
                      onDismiss={() => setMessage(null)}
                      dismissButtonAriaLabel="Close"
                    >
                      {message.message}
                    </MessageBar>
                  </div>
                </div>
              )}

              <Section label="JE Details Section" customRef={sectionRef}>
                <Pivot
                  selectedKey={currentTab}
                  onLinkClick={handleClick}
                  styles={{
                    root: {
                      backgroundColor: theme.palette.neutralLighterAlt,
                      marginTop: "-10px",
                      ":after": {
                        content: "''",
                        height: "1px",
                        display: "block",
                        width: "100%",
                        backgroundColor: theme.palette.neutralTertiaryAlt
                      }
                    }
                  }}
                >
                  <PivotItem headerText={"JE Details"} itemKey={JeDetailsTabs.jeDetails} alwaysRender={true}>
                    <div
                      className={css`
                        width: 100%;
                        position: relative;
                        padding: 16px;
                      `}
                    >
                      <GLDetailsTab
                        state={details}
                        loadingStatus={loadingStatus}
                        actionWeightages={actionWeightages}
                        disabled={disabled}
                      />
                    </div>
                  </PivotItem>
                  <PivotItem
                    headerText={`Attachments (${formik.values.attachments_count})`}
                    itemKey={JeDetailsTabs.attachments}
                    alwaysRender={true}
                  >
                    <div
                      className={css`
                        width: 100%;
                        position: relative;
                        padding: 16px;
                      `}
                    >
                      <GLAttachments
                        customRef={attachmentsRef}
                        initialAttachments={details.attachments}
                        onDeleteFile={attachmentActions.deleteAttachment}
                        onUploadFile={attachmentActions.uploadAttachment}
                        onDownloadFile={attachmentActions.downloadAttachment}
                        disabled={false}
                        loadingStatus={LoadingStatus.Resolved}
                        initialRegion={details.attachments_region.toString()}
                        items={[
                          {
                            fiscalPeriod: details.detailsTab.fiscalPeriod,
                            fiscalYear: details.detailsTab.fiscalYear,
                            refGuid: details.refGuid,
                            jeId: details.jeId.toString(),
                            format: `${details.postingType}`
                          }
                        ]}
                        lockRegion={details.lock_region}
                        status={details.status}
                        reviewer={
                          details.reviewersTab.reviewer + ";" + details.reviewersTab.additionalReviewers.join(";")
                        }
                      />
                    </div>
                  </PivotItem>
                  <PivotItem headerText={"Review"} itemKey={JeDetailsTabs.review} alwaysRender={true}>
                    <div
                      className={css`
                        width: 100%;
                        position: relative;
                        padding: 16px;
                      `}
                    >
                      <ReviewFormTab
                        comments={details.reviewersTab.commentItems}
                        clarificationCodes={details.reviewersTab.clarificationCodes}
                        loadingStatus={loadingStatus}
                      />
                    </div>
                  </PivotItem>
                  {details.postingType === 7 ? (
                    <PivotItem headerText={"Other Details"} itemKey={JeDetailsTabs.otherDetails} alwaysRender={true}>
                      <div
                        className={css`
                          width: 100%;
                          position: relative;
                          padding: 16px;
                        `}
                      >
                        <OtherDetailsTab
                          jeId={details.jeId}
                          loadingStatus={loadingStatus}
                          configuration={props.configuration}
                        />
                      </div>
                    </PivotItem>
                  ) : null}
                </Pivot>
              </Section>
            </Form>
          )}
        </Formik>
        <hr
          className={css`
            margin: 0;
            padding: 0;
            border: none;
            background: ${theme.palette.neutralTertiaryAlt};
            height: 1px;
          `}
        />

        {details !== null && details.detailsTab.documentNumber && details.detailsTab.companyCode ? (
          <Section label="SAP Details" customRef={sectionRef}>
            <GLSapDetails
              configuration={props.configuration}
              payloadForService={{
                compcode: Number(details.detailsTab.companyCode),
                docNum: Number(details.detailsTab.documentNumber),
                fYear: Number(details.detailsTab.fiscalYear),
                Module: `${JeModuleTypeToNumber[sapModuleFromSearchPayload]}`,
                jeType: details.detailsTab.jeType
              }}
              templateId={details.templateId}
            />
          </Section>
        ) : null}
      </div>
    </React.Fragment>
  );
};

GLDetailsForm.displayName = "GLDetailsForm";
export { GLDetailsForm };
