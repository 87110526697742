import { MessageBarType } from "@fluentui/react";
import { useContext } from "react";
import { JemConfiguration } from "../../../../JemConfiguration";
import { UserContext } from "../../../../Shared/contexts/UserContext/UserContext";

import { v4 } from "uuid";
import { DomainDataEnum, DomainDataObjects } from "../../../../Shared/contexts/JEMContext/JEMContext.domainData.types";
import { ApiError } from "../../../../Shared/utilities/ErrorHelpers";
import { stringToStatusCode } from "../../../../Shared/utilities/IStatusCodes";
import { getValidUrl, IUserProviderState, postRequest } from "../../../../Shared/utilities/RequestUtilities";
import { LocalMessage } from "../../../../Shared/utilities/SharedModels";
import { GLAttachmentChanges } from "../../../shared/GL.Attachments";
import { GLDetailsActionsEnum } from "../GLDetails.Actions";
import { GLJeDetailsFormikState, GLJeDetailsState } from "../GLDetailsForm.types";
import {
  currentFyFp,
  IAttachment,
  ILoggingProviderState,
  LoggingContext,
  SAPReversalActionPayload
} from "../../../../Shared";
import { RegStoreAttachmentForUpload } from "../../../shared/GLDashboardRow";
import { AdditionalReverseJEData, iAttachmentToRegStoreAttachment } from "../../../shared";
import { SeverityLevel } from "@microsoft/applicationinsights-web";

export interface DetailsActionPayload {
  state: GLJeDetailsState;
  formikState: GLJeDetailsFormikState;
  attachmentObjects: GLAttachmentChanges;
  domainData: Pick<DomainDataObjects, DomainDataEnum.JeStatus>;
}

export interface GLDetailsResult {
  message: LocalMessage;
  blockPage: boolean;
}

export interface CreateBatchActionOnSubmitHandlerMap {
  [GLDetailsActionsEnum.Save]: (payload: DetailsActionPayload) => Promise<GLDetailsResult>;
  [GLDetailsActionsEnum.NeedsClarification]: (payload: DetailsActionPayload) => Promise<GLDetailsResult>;
  [GLDetailsActionsEnum.ReleaseForSignOff]: (payload: DetailsActionPayload) => Promise<GLDetailsResult>;
  [GLDetailsActionsEnum.SaveSAPInfo]: (payload: DetailsActionPayload) => Promise<GLDetailsResult>;
  [GLDetailsActionsEnum.SignOff]: (payload: DetailsActionPayload) => Promise<GLDetailsResult>;
  [GLDetailsActionsEnum.ReverseJE]: (
    payload: DetailsActionPayload,
    options?: AdditionalReverseJEData
  ) => Promise<GLDetailsResult>;
  [GLDetailsActionsEnum.ViewFile]: (payload: DetailsActionPayload) => Promise<GLDetailsResult>;
}

export function useGLDetailsActions(
  configuration: JemConfiguration["GeneralLedgerApi"]
): CreateBatchActionOnSubmitHandlerMap {
  const userContext = useContext(UserContext);
  const logger = useContext(LoggingContext);

  const makeUrl = (endpoint: string) => {
    const base = configuration.baseApiUrl;
    return getValidUrl(`${base}${endpoint}`);
  };

  const actions: CreateBatchActionOnSubmitHandlerMap = {
    Save: async function (payload: DetailsActionPayload): Promise<GLDetailsResult> {
      logger.appInsights?.trackEvent({
        name: "saveDetails",
        properties: {
          refGuid: payload.state.refGuid
        }
      });
      const attachmentsChanged = payload.attachmentObjects.recentlyUploadedAttachments.length !== 0;
      if (attachmentsChanged) {
        const packedAttachments = assembleAttachmentsPayload(
          payload.state.jeId,
          payload.attachmentObjects.region_key,
          payload.attachmentObjects.recentlyUploadedAttachments,
          logger
        );
        const endpoint = makeUrl(configuration.endpoints.attachmentUpdate);
        const response = await postRequest(endpoint, packedAttachments, userContext.accessToken);
        if (!response) {
          throw new ApiError("Could not Add Attachments.");
        }
      }
      const detailsPayload = assembleJeDetails(payload, userContext);
      const endpoint = makeUrl(configuration.endpoints.detailsSave);
      const response = await postRequest<number>(endpoint, detailsPayload, userContext.accessToken);
      if (!response) {
        throw new ApiError("Could not Save JE Details.");
      }

      return {
        message: {
          message: "JE Details Saved.",
          type: MessageBarType.success
        },
        blockPage: false
      };
    },
    NeedsClarification: async function (payload: DetailsActionPayload): Promise<GLDetailsResult> {
      logger.appInsights?.trackEvent({
        name: "needsClarificationDetails",
        properties: {
          refGuid: payload.state.refGuid
        }
      });
      const clarifyPayload = assemblePostPosingPayload(payload, userContext, "Clarify");
      const endpoint = makeUrl(configuration.endpoints.dashboardWorkflowGuid).replace("{wfGUID}", v4());
      const response = await postRequest<JeDetailsActionResponse>(endpoint, clarifyPayload, userContext.accessToken);
      if (!response) {
        throw new ApiError("Could not send entry back for clarifications.");
      }

      return {
        message: {
          message: response.message,
          type: MessageBarType.success
        },
        blockPage: false
      };
    },
    "ReleaseForSign-Off": async function (payload: DetailsActionPayload): Promise<GLDetailsResult> {
      logger.appInsights?.trackEvent({
        name: "releaseForSignOffDetails",
        properties: {
          refGuid: payload.state.refGuid
        }
      });
      const initReviewPayload = assemblePostPosingPayload(payload, userContext, "ReleaseForSignOff");
      const endpoint = makeUrl(configuration.endpoints.dashboardWorkflowGuid).replace("{wfGUID}", v4());
      const response = await postRequest<JeDetailsActionResponse>(endpoint, initReviewPayload, userContext.accessToken);
      if (!response) {
        throw new ApiError("Could not Release JE Sign-Off.");
      }

      return {
        message: {
          message: response.message,
          type: MessageBarType.success
        },
        blockPage: false
      };
    },
    SaveSAPInfo: async function (payload: DetailsActionPayload): Promise<GLDetailsResult> {
      logger.appInsights?.trackEvent({
        name: "saveSAPInfoDetails",
        properties: {
          refGuid: payload.state.refGuid
        }
      });
      const saveSAPInfoPayload = assembleJeDetailsForSaveSAP(payload.state);

      const endpoint = makeUrl(configuration.endpoints.detailsSaveSAPInfo);
      const response = await postRequest<JeDetailsActionResponse>(
        endpoint.replace("v1.0", "v2.0"),
        saveSAPInfoPayload,
        userContext.accessToken
      );

      if (!response) {
        throw new ApiError("Could not peform Save SAP Info on entry;");
      }

      return {
        message: {
          message: "Successfully Saved SAP Info for JE.",
          type: MessageBarType.success
        },
        blockPage: false
      };
    },
    "Sign-off": async function (payload: DetailsActionPayload): Promise<GLDetailsResult> {
      logger.appInsights?.trackEvent({
        name: "signOffDetails",
        properties: {
          refGuid: payload.state.refGuid
        }
      });
      const approvePayload = assemblePostPosingPayload(payload, userContext, "Approve");
      const endpoint = makeUrl(configuration.endpoints.dashboardWorkflowGuid).replace("{wfGUID}", v4());
      const response = await postRequest<JeDetailsActionResponse>(endpoint, approvePayload, userContext.accessToken);
      if (!response) {
        throw new ApiError("Could not Sign Off on entry.");
      }

      return {
        message: {
          message: response.message,
          type: MessageBarType.success
        },
        blockPage: false
      };
    },
    "Reverse JE": async function (
      payload: DetailsActionPayload,
      options?: AdditionalReverseJEData
    ): Promise<GLDetailsResult> {
      logger.appInsights?.trackEvent({
        name: "reverseJE",
        properties: {
          refGuid: payload.state.jeId
        }
      });
      const attachmentsChanged = options?.attachments?.length !== 0;
      const attachments = options ? options.attachments : [];
      const region_key = options ? options.regionKey : 0;
      let packedAttachments;
      if (attachmentsChanged) {
        packedAttachments = assembleAttachmentsPayload(payload.state.jeId, region_key, attachments, logger);
      }
      const reversalPayload: SAPReversalActionPayload = {
        jeIds: [payload.state.jeId.toString()],
        reversalReason: options?.reversalReason || "",
        postingDate: options?.postingDate || "",
        postingPeriod: options?.postingPeriod || "",
        attachments: attachmentsChanged ? (packedAttachments ? packedAttachments[0].Attachments : null) : null,
        reversalComment: options?.comments || ""
      };
      const url = makeUrl(configuration.endpoints.postReverseJEs);
      const response = await postRequest(url, reversalPayload, userContext.accessToken);
      if (!response) {
        throw new ApiError("Error posting reversals.");
      }
      if (
        response[0].message === "Document Posted Successfully" ||
        response[0].message === "Send it for background processing"
      ) {
        return {
          message: {
            message: response[0].message,
            type: MessageBarType.success
          },
          blockPage: false
        };
      }
      return {
        message: {
          message: response[0].message,
          type: MessageBarType.error
        },
        blockPage: false
      };
    },
    "View File": async function (_payload: DetailsActionPayload): Promise<GLDetailsResult> {
      throw new Error("Function not implemented.");
    }
  };
  return actions;
}

export interface JeDetailsActionResponse {
  status: boolean;
  message: string;
  changes: JeDetailsChangeResponse[];
}

export interface JeDetailsChangeResponse {
  jeId: number;
  refguid: string;
  message: string;
  currentJeStatus: number;
}

export interface PayloadForSavingJeDetails {
  comment: string;
  reviewer: string;
  commentedAlias: string;
  isAttachementUploaded: boolean;
  isReviewerChanged: boolean;
  jeId: number;
  purpose: string;
}

export interface PostPostingJeDetailsActionsPayload {
  actionType: string;
  jeWFInfo: JeWFInfo;
}

export interface SaveSAPInfoPayload {
  compcode: number;
  docNum: number;
  fYear: number;
  module: string;
  jeType: string;
}

export interface JeWFInfo {
  jes: JeInfo[];
  actionBy: string;
  comment: string;
  clarifications: string;
}

export interface JeInfo {
  jeId: number;
  status: number;
  version: number;
}

function getJeInfo(jeDetails: DetailsActionPayload): JeInfo[] {
  const jeInfo: JeInfo = {
    jeId: jeDetails.state.jeId,
    status: stringToStatusCode(jeDetails.domainData, jeDetails.state.header.status),
    version: 0.0
  };
  const jeInfos: JeInfo[] = [jeInfo];

  return jeInfos;
}

function assemblePostPosingPayload(
  jeDetails: DetailsActionPayload,
  userContext: IUserProviderState,
  action: string
): PostPostingJeDetailsActionsPayload {
  const payload: PostPostingJeDetailsActionsPayload = {
    actionType: action,
    jeWFInfo: {
      jes: getJeInfo(jeDetails),
      actionBy: userContext.user.alias,
      comment: jeDetails.formikState.reviewersTabComments,
      clarifications: jeDetails.formikState.reviewersTabClarificationCode.toString()
    }
  };

  return payload;
}

function assembleJeDetailsForSaveSAP(searchState: DetailsActionPayload["state"]): SaveSAPInfoPayload {
  const payload: SaveSAPInfoPayload = {
    compcode: Number(searchState.detailsTab.companyCode),
    docNum: Number(searchState.detailsTab.documentNumber),
    fYear: Number(searchState.detailsTab.fiscalYear),
    module: searchState.sapModule,
    jeType: ""
  };
  return payload;
}

function assembleJeDetails(payload: DetailsActionPayload, userContext: IUserProviderState): PayloadForSavingJeDetails {
  const allReviewers = `${payload.formikState.reviewersTabReviewer}${
    payload.formikState.reviewersTabAdditionalReviewers.length > 0
      ? `;${payload.formikState.reviewersTabAdditionalReviewers.join(";")}`
      : ""
  }`;
  const isAttachementUploaded = payload.state.attachments.length !== payload.formikState.attachments_count;
  const isReviewerChanged =
    payload.formikState.reviewersTabReviewer !== payload.state.reviewersTab.reviewer ||
    payload.formikState.reviewersTabAdditionalReviewers !== payload.state.reviewersTab.additionalReviewers;
  const finalPayload: PayloadForSavingJeDetails = {
    comment: payload.formikState.reviewersTabComments,
    reviewer: allReviewers,
    commentedAlias: userContext.user.alias,
    isAttachementUploaded,
    isReviewerChanged,
    jeId: payload.state.jeId,
    purpose: payload.formikState.detailsTabJePurpose
  };
  return finalPayload;
}

// don't ask about the double array
// don't ask about the double array
// don't ask about the double array
// possibly wasted a life time on this
export type LegacyAttachmentUploadPayload = AttachmentUploadPayload[];

export interface AttachmentUploadPayload {
  JeIds: number[];
  urlPrefix?: string;
  Attachments: AttachmentsChanges;
}

export interface AttachmentsChanges {
  RegStoreAttachments: RegStoreAttachmentForUpload[];
}

function assembleAttachmentsPayload(
  jeId: number,
  regionId: number,
  attachmentChanges: IAttachment[],
  logger: ILoggingProviderState
): LegacyAttachmentUploadPayload {
  const regStoreAttachments = attachmentChanges.map((attachment) =>
    iAttachmentToRegStoreAttachment(regionId, attachment)
  );
  const urls = attachmentChanges.map((a) => a.url);
  // get fy/fp as a string "2023/11", remove /path.ext from each attachment like 2023/11/path.ext,
  const fiscalYears = new Set(
    urls.map((u) => {
      let path = "";
      try {
        const url = new URL(u);
        path = url.pathname.split("/corpacctjestore/")[1];
      } catch (_) {
        path = u;
      }

      const pieces = path.split("/");
      const fyfp = pieces.slice(0, 2).join("/");
      return fyfp;
    })
  );

  let urlPrefix;
  if (fiscalYears.size === 0) {
    logger.appInsights?.trackException({
      exception: new Error(`${jeId} had no common fiscal year and fiscal period when uploading attachment.`),
      severityLevel: SeverityLevel.Error
    });
    const { fiscalYear, fiscalMonth } = currentFyFp();
    urlPrefix = `${fiscalYear}/${fiscalMonth}`;
  } else {
    const firstFiscalYear = fiscalYears.values().next().value;
    urlPrefix = firstFiscalYear;
  }
  if (fiscalYears.size > 1) {
    logger.appInsights?.trackException({
      exception: new Error(`${jeId} more than 1 fiscalyear/fiscalperiod when uploading attachments.`),
      severityLevel: SeverityLevel.Error
    });
  }
  // get the first one
  const payloadForAttachmentUpload: AttachmentUploadPayload = {
    JeIds: [jeId],
    urlPrefix: urlPrefix,
    Attachments: {
      RegStoreAttachments: regStoreAttachments
    }
  };
  return [payloadForAttachmentUpload];
}
