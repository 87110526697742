import { JeDetailsTabs } from "./GLDetailsForm.types";

export function parseTabNameToEnumTabName(tabName: string) {
  switch (tabName.toLowerCase()) {
    case "details":
      return JeDetailsTabs.jeDetails;
    case "review":
      return JeDetailsTabs.review;
    case "attachments":
      return JeDetailsTabs.attachments;
    default:
      return JeDetailsTabs.jeDetails;
  }
}
