import { IStackTokens, ITextFieldProps, useTheme } from "@fluentui/react";

export const gridStyle: IStackTokens = { childrenGap: 16 };
export const itemStyle = { width: "130px" };
export const columnStyles: IStackTokens = {
  childrenGap: 10,
  padding: 10,
  maxWidth: 220
};

export const textFieldStyles = (theme: ReturnType<typeof useTheme>): Partial<ITextFieldProps["styles"]> => {
  return {
    field: {
      color: theme.palette.neutralPrimary,
      fontWeight: 600,
      backgroundColor: theme.palette.neutralLighterAlt
    }
  };
};
