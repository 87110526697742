import { css } from "@emotion/css";
import { Label, Pivot, PivotItem, SelectionMode, Text } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { DashboardGrid } from "../../../Shared/components/DashboardGrid/DashboardGrid";
import { globalColors } from "../../../globalCss/styles";
import { UserConfiguration } from "../../../Shared/components/DashboardGrid/DashboardGrid.types";
import { JeAuditDetails } from "../JeAuditDetails/JeAuditDetails";
import { headerDetailsColumns, transDetailsColumns } from "./JeDetails.columns";
import { FullJeDetails } from "./JEDetails.schema";
import { labelStyles } from "../../../Shared/utilities/CommonStyles";

export interface UIJeDetails {
  props: {
    details: FullJeDetails | null;
  };
}

interface AuditDetailsState {
  currentTab: "jeDetails" | "attachments" | "reviewers";
}

const AuditDetailsStateInitail: AuditDetailsState = {
  currentTab: "jeDetails"
};

const transactionUserConfigName = "JeDetailsTransactionDetails";
const headerUserConfigName = "JeDetailsHeaderDetails";

const JeDetails: React.FC<UIJeDetails["props"]> = (props) => {
  const [auditDetailsState, setauditDetailsState] = useState<AuditDetailsState>(AuditDetailsStateInitail);
  const [headerUserConfiguration, setHeaderUserConfiguration] = useState<null | undefined | UserConfiguration>(
    undefined
  );
  const [transactionUserConfiguration, setTransactionUserConfiguration] = useState<
    null | undefined | UserConfiguration
  >(undefined);
  useEffect(() => {
    const lHeaderConfig = localStorage.getItem(headerUserConfigName);
    if (lHeaderConfig) {
      setHeaderUserConfiguration(JSON.parse(lHeaderConfig));
    } else {
      setHeaderUserConfiguration(null);
    }
    const lTransactionConfig = localStorage.getItem(transactionUserConfigName);
    if (lTransactionConfig) {
      setTransactionUserConfiguration(JSON.parse(lTransactionConfig));
    } else {
      setTransactionUserConfiguration(null);
    }
  }, []);

  const handleClick = (item?: PivotItem) => {
    if (item !== undefined) {
      const tileName = item.props.itemKey as any;
      setauditDetailsState((p) => ({
        ...p,
        currentTab: tileName
      }));
    }
  };

  if (props.details === null) {
    return null;
  }

  return (
    <React.Fragment>
      <div
        className={css`
          height: 120px;
          background-color: ${globalColors.jeDetailsHeadingBackground};
        `}
      >
        <Text
          className={css`
            padding-top: 0.67em !important;
            margin-top: 0 !important;
            line-height: 2.25em;
            & span {
              font-size: 12px;
              color: ${globalColors.jeDetailsHeadingLabelFont};
            }
          `}
          variant="xxLarge"
        >
          JE {props.details.id}
        </Text>
        <div
          className={css`
            display: flex;
            flex-direction: row;
            align-items: center;
            & label {
              margin-right: 4px;
              color: ${globalColors.jeDetailsHeadingLabelFont};
              font-weight: normal;
            }
            & span {
              margin-right: 16px;
              color: ${globalColors.jeDetailsHeadingTextFont};
              font-weight: 600;
            }
          `}
        >
          <Label styles={labelStyles}>Status</Label>
          <Text>{props.details.status} </Text>
          <Label styles={labelStyles}>Author</Label>
          <Text>{props.details.author} </Text>
          <Label styles={labelStyles}>Poster</Label>
          <Text>{props.details.poster} </Text>
          <Label styles={labelStyles}>Reviewer</Label>
          <Text>{props.details.reviewer} </Text>
          <Label styles={labelStyles}>Date modified</Label>
          <Text>{props.details.dateModified} </Text>
        </div>
      </div>
      <Pivot
        selectedKey={auditDetailsState.currentTab}
        onLinkClick={handleClick}
        styles={{
          root: {
            backgroundColor: globalColors.jeDetailsHeadingBackground
          }
        }}
      >
        <PivotItem headerText={"JE Details"} itemKey={"jeDetails"}>
          <div
            className={css`
              width: 100%;
              position: relative;
            `}
          >
            <JeAuditDetails details={props.details} />
            <hr
              className={css`
                margin: 0;
                padding: 0;
                border: none;
                background: ${globalColors.hrLineDark};
                height: 1px;
              `}
            />
            <div>
              <Text
                variant="large"
                className={css`
                  display: block;
                  line-height: 2.5em;
                  font-weight: 700;
                `}
              >
                COMPARISON
              </Text>
            </div>
            <div>
              <Text
                variant="large"
                className={css`
                  display: block;
                  line-height: 1.75em;
                  margin-bottom: 0em;
                `}
              >
                Header Details
              </Text>
            </div>
            {props.details.headerDetails.length === 0 ? (
              <Text
                variant="xLarge"
                style={{
                  color: "var(--accent-font-color, gray)",
                  textAlign: "center"
                }}
              >
                No Items to show.
              </Text>
            ) : headerUserConfiguration === undefined ? null : (
              <DashboardGrid
                idForLocalStorage="s2sJeDetailsGridConfiguration"
                columnGenerator={headerDetailsColumns}
                items={props.details.headerDetails}
                height={"172px"}
                selectionMode={SelectionMode.none}
                disableSorting={true}
                disablePagination={true}
                customRef={null}
              />
            )}
            <div>
              <Text
                className={css`
                  display: block;
                  padding: 1em 0;
                  line-height: 1.75em;
                `}
                variant="large"
              >
                Transaction Details
              </Text>
            </div>
            {props.details.transactionDetails.length === 0 ? (
              <Text
                variant="xLarge"
                style={{
                  color: "var(--accent-font-color, gray)",
                  textAlign: "center"
                }}
              >
                No Items to show.
              </Text>
            ) : transactionUserConfiguration === undefined ? null : (
              <>
                <div>
                  <Text
                    className={css`
                      line-height: 1.5em;
                    `}
                    variant="large"
                  >{`${Math.floor(props.details.transactionDetails.length / 2)} transactions`}</Text>
                </div>
                <DashboardGrid
                  idForLocalStorage="s2sSapDetailsGridConfiguration"
                  columnGenerator={transDetailsColumns}
                  items={props.details.transactionDetails}
                  height={`${Math.min((props.details.transactionDetails.length + 1) * 43 + 42, 300)}px`}
                  selectionMode={SelectionMode.none}
                  disableSorting={true}
                  customRef={null}
                />
              </>
            )}
          </div>
        </PivotItem>
      </Pivot>
    </React.Fragment>
  );
};

export { JeDetails };
