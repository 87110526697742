import { Theme, TooltipHost, IconButton, Label, Stack, Text, TextField, useTheme } from "@fluentui/react";
import { css } from "@emotion/css";
import { Field, useFormikContext } from "formik";
import React from "react";
import { JemUserRoles, LoadingStatus } from "../../../Shared";
import { gridStyle } from "../../shared";
import { GLBatchPageState } from "./GLBatch.State";
import { GLBatchFormikState } from "./GLBatch.types";
import { textFieldStyles } from "../../shared";

export interface BatchTitleProps {
  theme: Theme;
  state: GLBatchPageState;
  disabled: boolean;
  loadingStatus: LoadingStatus;
  userPermissions: JemUserRoles;
  onNavigate: () => void;
}

const BatchTitle: React.FC<BatchTitleProps> = (props) => {
  const formik = useFormikContext<GLBatchFormikState>();
  const theme = useTheme();

  return (
    <>
      <Stack horizontal {...{ tokens: gridStyle }}>
        <Stack.Item align="center">
          <TooltipHost
            content="Back to Dashboard"
            // This id is used on the tooltip itself, not the host
            // (so an element with this id only exists when the tooltip is shown)
            calloutProps={{ gapSpace: 0 }}
            styles={{ root: { display: "inline-block" } }}
            setAriaDescribedBy={false}
          >
            <IconButton
              aria-label="Back to Dashboard"
              iconProps={{
                iconName: "ArrowUpRightMirrored8",
                styles: {
                  root: {
                    transform: "rotate(-45deg)"
                  }
                }
              }}
              onClick={props.onNavigate}
            />
          </TooltipHost>
        </Stack.Item>
        <Stack.Item
          align="center"
          {...{
            tokens: gridStyle
          }}
        >
          <div
            className={css(`
                    display: flex;
                    flex-direction: row;
                    gap: 8px;
                    align-items: center;
                  `)}
          >
            <Label htmlFor={"theHeaderName"}>
              <Text
                className={css`
                  display: block;
                  margin-top: 0 !important;
                  line-height: 2.25em;
                  margin-top: -4px;
                `}
                variant="xxLarge"
                aria-label={`Batch ${props.state.batchState.batchDetails.batchName}`}
              >
                Batch:
              </Text>
            </Label>
            <Field
              id={"theHeaderName"}
              as={TextField}
              name="detailsTabBatchName"
              type="input"
              styles={{
                root: {
                  height: "73px",
                  display: "flex",
                  alignItems: "center",
                  marginTop: "4px",
                  gap: "8px"
                }
              }}
              onChange={(_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                if (!newValue || newValue.length === 0) {
                  formik.setFieldValue("detailsTabBatchName", props.state.batchState.batchDetails.batchName);
                } else {
                  formik.setFieldValue("detailsTabBatchName", newValue);
                }
              }}
              disabled={props.disabled || props.loadingStatus !== LoadingStatus.Resolved}
              errorMessage={
                props.disabled
                  ? !props.userPermissions.hasSapAccess
                    ? "Please request SAP Permissions to use the Batch Functionality"
                    : ""
                  : props.loadingStatus !== LoadingStatus.Resolved
                  ? ""
                  : formik.errors.detailsTabBatchName
              }
            />
            <TextField styles={textFieldStyles(theme)} label="Poster:" placeholder={props.state.batchState.poster} />

            <TextField
              styles={textFieldStyles(theme)}
              label="Created:"
              placeholder={props.state.batchState.createdDate}
            />
          </div>
        </Stack.Item>
        <Stack.Item align="center" grow>
          {props.disabled || props.loadingStatus !== LoadingStatus.Resolved ? (
            <Text
              className={css`
                display: block;
                color: ${props.theme.palette.themePrimary};
                float: right;
              `}
              variant="xSmall"
              aria-label={`Page is ${
                props.disabled ? "READONLY" : props.loadingStatus !== LoadingStatus.Resolved ? "LOADING" : ""
              }`}
            >
              {props.disabled ? "READONLY" : props.loadingStatus !== LoadingStatus.Resolved ? "LOADING" : ""}
            </Text>
          ) : null}
        </Stack.Item>
      </Stack>
    </>
  );
};

BatchTitle.displayName = "BatchTitle";

export { BatchTitle };
