import { ContextualMenu, Dialog, DialogFooter, Label, PrimaryButton, Separator } from "@fluentui/react";
import { useBoolean, useSetTimeout } from "@fluentui/react-hooks";
import {
  GLAttachmentEndpointMap,
  GLAttachments,
  GLAttachmentsRef,
  GLDashboardRow,
  LoadingStatus
} from "@jem/components";
import React, { PropsWithChildren, useEffect, useImperativeHandle, useRef, useState } from "react";
import { AsyncSubject } from "rxjs";

let onSubmitAsyncSubject: null | AsyncSubject<boolean | null> = null;

export interface AttachmentDialogRef {
  open: (item: GLDashboardRow) => void;
  getOnSubmitAsyncSubject: () => AsyncSubject<boolean | null>;
}

export interface AttachmentDialogProps {
  customRef: React.RefObject<AttachmentDialogRef>;
  attachmentsActionsMap: GLAttachmentEndpointMap;
}

const AttachmentDialog = (props: PropsWithChildren<AttachmentDialogProps>) => {
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
  const [row, setRow] = useState<GLDashboardRow | null>(null);
  const attachmentsRef = useRef<GLAttachmentsRef>(null);
  const { setTimeout } = useSetTimeout();

  useImperativeHandle(props.customRef, () => ({
    getOnSubmitAsyncSubject() {
      onSubmitAsyncSubject = new AsyncSubject<boolean | null>();
      return onSubmitAsyncSubject;
    },
    open(item: GLDashboardRow) {
      setRow(item);
      toggleHideDialog();
    }
  }));

  useEffect(() => {
    if (row) {
      setTimeout(() => {
        if (attachmentsRef.current && typeof row.attachments !== "string") {
          attachmentsRef.current.setAttachments(row.attachments, []);
        }
      }, 100);
    }
  }, [row]);

  return (
    <>
      <Dialog
        hidden={hideDialog}
        onDismiss={() => {
          if (onSubmitAsyncSubject) {
            onSubmitAsyncSubject.next(false);
            onSubmitAsyncSubject.complete();
            onSubmitAsyncSubject = null;
          }
          if (attachmentsRef.current) attachmentsRef.current.reset();
          toggleHideDialog();
        }}
        modalProps={{
          isBlocking: true,
          topOffsetFixed: false,
          dragOptions: {
            moveMenuItemText: "Move",
            closeMenuItemText: "Close",
            menu: ContextualMenu
          }
        }}
        minWidth={800}
      >
        <Label>Attachments for {row?.docNumber}</Label>
        <Separator></Separator>
        <GLAttachments
          customRef={attachmentsRef}
          onUploadFile={props.attachmentsActionsMap.uploadAttachment}
          onDeleteFile={props.attachmentsActionsMap.deleteAttachment}
          onDownloadFile={props.attachmentsActionsMap.downloadAttachment}
          initialAttachments={row ? row.attachments : []}
          items={[]}
          disabled={true}
          initialRegion={"2"}
          loadingStatus={LoadingStatus.Resolved}
          lockRegion={true}
        />
        <DialogFooter>
          <PrimaryButton
            onClick={() => {
              if (onSubmitAsyncSubject) {
                onSubmitAsyncSubject.next(false);
                onSubmitAsyncSubject.complete();
                onSubmitAsyncSubject = null;
              }
              if (attachmentsRef.current) attachmentsRef.current.reset();
              toggleHideDialog();
            }}
            text={"Close"}
          />
        </DialogFooter>
      </Dialog>
    </>
  );
};

AttachmentDialog.displayName = "AttachmentDialog";
export default AttachmentDialog;
