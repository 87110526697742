import { IDropdownOption } from "@fluentui/react";

import React from "react";
import { ObjectKeys } from "./TypeUtils";

export const SignificanceTypes = {
  I: "Insignificant",
  O: "Optional",
  L: "LowValue",
  N: "Regular",
  X: "Significant"
};
export type Significances = keyof typeof SignificanceTypes;
export type IconMappingForSignificance = Record<Significances, (significanceType: string) => React.ReactElement>;

const SignificanceIcon = (significance: string, significanceType: string, iconMapping: IconMappingForSignificance) => {
  switch (significance) {
    case "I":
      return iconMapping.I(significanceType);
    case "O":
      return iconMapping.O(significanceType);
    case "L":
      return iconMapping.L(significanceType);
    case "N":
      return iconMapping.N(significanceType);
    case "X":
      return iconMapping.X(significanceType);
    default:
      return <span>{significance}</span>;
  }
};

export function SignificanceTypeAndIcon(significance: string, iconMapping: IconMappingForSignificance) {
  const significanceTypes = {
    I: "Insignificant",
    O: "Optional",
    L: "LowValue",
    N: "Regular",
    X: "Significant"
  };
  const significanceType =
    significance in significanceTypes
      ? significanceTypes[significance as keyof typeof significanceTypes]
      : significance;
  return {
    significanceType,
    icon: SignificanceIcon(significance, significanceType, iconMapping)
  };
}

/**
 * @description [
 *      { key: "none", text: "None Selected", disabled: true },
 *      { key: "all", text: "All" },
 *      { key: "I", text: "Insignificant" },
 *      { key: "O", text: "Optional" },
 *      { key: "L", text: "LowValue" },
 *      { key: "N", text: "Regular" },
 *      { key: "X", text: "Significant" }
 *    ];
 *
 */
export const SignificanceOptions = (() => {
  const significancesForDropdown: IDropdownOption[] = ObjectKeys(SignificanceTypes).map((key) => ({
    key,
    text: SignificanceTypes[key]
  }));
  significancesForDropdown.unshift({ key: "all", text: "All" });
  significancesForDropdown.unshift({ key: "none", text: "None Selected", disabled: true });
  return significancesForDropdown;
})();
