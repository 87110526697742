import { GLDashboardRow } from "../GLDashboardRow";

export interface WorkflowPayload {
  actionType: string;
  jeWFInfo: JeWFInfo;
}

export interface JeWFInfo {
  jEs: WorkflowJeObject[];
  actionBy: string;
  comment: string;
  clarifications: string;
}

export interface WorkflowJeObject {
  jeId: number;
  status: number;
  version: number;
}

export enum WorkflowActionType {
  Approve = "Approve",
  Clarify = "Clarify",
  Rescind = "Rescind",
  ReleaseForSignOff = "ReleaseForSignOff",
  Recall = "Recall",
  ApprovePreReview = "ApprovePreReview",
  RecallPreReview = "RecallPreReview",
  ClarifyPreReview = "ClarifyPreReview"
}

export interface WorkflowActionRequestOptions {
  items: GLDashboardRow[];
  actionType: WorkflowActionType;
  comments: string;
  alias: string;
  clarifications: string[];
}

export function toWorkflowPayload(opts: WorkflowActionRequestOptions): WorkflowPayload {
  const payload: WorkflowPayload = {
    actionType: opts.actionType,
    jeWFInfo: {
      jEs: opts.items.map((x) => {
        const jeObj: WorkflowJeObject = {
          jeId: Number(x.jeId),
          status: Number(x.status),
          version: Number(x.version)
        };
        return jeObj;
      }),
      actionBy: opts.alias,
      comment: opts.comments,
      clarifications: opts.clarifications.join(",")
    }
  };
  return payload;
}
