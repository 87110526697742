import React, { useContext, useEffect, useImperativeHandle, useState } from "react";
import { ITag, IconButton, Label, TagPicker, useTheme } from "@fluentui/react";
import { DomainDataObjects, DomainDataEnum, JEMContext } from "../../contexts";
import { companyCodeObjectToStringArray, searchInArray } from "../CompanyCodeSingleSelector";
import { v4 } from "uuid";

export interface CompanyCodeMultiSelectorProps {
  selectedCompanies?: number[];
  customRef?: React.RefObject<CompanyCodeMultiSelectorRef>;
  onChange?: (currentCompanyCode: number[]) => void;
  maxCompanies?: number;
  required?: boolean;
  hideLabel?: boolean;
}

export interface CompanyCodeMultiSelectorRef {
  getCompanyCodes: () => number[];
  reset: () => void;
  setCompanyCodes: (companyCodes: number[]) => void;
}

export const numberToTag = (
  companyCode: number,
  allCompanyCodes: DomainDataObjects[DomainDataEnum.JeCompanyCodes]
): ITag | undefined => {
  const company = allCompanyCodes.find((co) => co.glCompanyCode === companyCode);
  if (!company) {
    return;
  }
  return {
    key: `${company.glCompanyCode}, ${company.glCompanyName}`,
    name: `${company.glCompanyCode}, ${company.glCompanyName}`
  };
};

let CompanyCodeMultiSelector: React.FC<CompanyCodeMultiSelectorProps> = (props) => {
  const jemContext = useContext(JEMContext);
  const [selectedCompanyTags, setSelectedCompanyTags] = useState<ITag[]>([]);
  const allCompanyCodes = jemContext.initInfo.values ? jemContext.initInfo.values[DomainDataEnum.JeCompanyCodes] : [];
  const theme = useTheme();

  const isInCompanyCodes = (prefix?: string, limit = 10): ITag[] => {
    const companyCodeArray = companyCodeObjectToStringArray(allCompanyCodes);
    const allItems = searchInArray(companyCodeArray, prefix, limit).map((ccName) => ({
      key: ccName,
      name: ccName
    }));

    return allItems.filter((item) => !selectedCompanyTags.find((tag) => tag.key === item.key));
  };

  useEffect(() => {
    if (jemContext.initInfo.values && props.onChange !== undefined) {
      const allCompanyCodes = jemContext.initInfo.values.JeCompanyCodes;
      const initialCompanyCodes = props.selectedCompanies
        ? props.selectedCompanies.map((co) => numberToTag(co, allCompanyCodes) as ITag).filter((co) => co !== undefined)
        : [];
      setSelectedCompanyTags(initialCompanyCodes);
    }
  }, [jemContext.initInfo.values, props.selectedCompanies]);

  useImperativeHandle(props.customRef, () => ({
    getCompanyCodes() {
      const allTags = selectedCompanyTags.map((tag) => Number((tag.key as string).split(",")[0].trim()));
      if (props.maxCompanies) {
        return allTags.slice(0, props.maxCompanies);
      }
      return allTags;
    },
    reset() {
      setSelectedCompanyTags([]);
    },
    setCompanyCodes(companyCodes: number[]) {
      const newTags = companyCodes
        .map((co) => numberToTag(co, allCompanyCodes))
        .filter((co) => co !== undefined) as ITag[];
      setSelectedCompanyTags(newTags);
    }
  }));

  useEffect(() => {
    if (props.maxCompanies === undefined) {
      return;
    }
    if (selectedCompanyTags.length > props.maxCompanies) {
      const newTags = selectedCompanyTags.slice(0, props.maxCompanies);
      setSelectedCompanyTags(newTags);
      if (props.onChange) {
        const companyCodes = newTags.map((tag) => Number((tag.key as string).split(",")[0].trim()));
        props.onChange(companyCodes);
      }
    }
  }, [props.maxCompanies]);

  const handleCopyTags = () => {
    const tagNames = selectedCompanyTags.map((tag) => tag.name).join(";");
    navigator.clipboard.writeText(tagNames);
  };

  const id = React.useMemo(() => `companypicker-${v4().slice(0, 8)}`, []);
  const label = `Company Code${props.maxCompanies && props.maxCompanies === 1 ? ":" : "s:"}`;
  return (
    <>
      {!props.hideLabel ? (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <Label htmlFor={id} required={props.required}>
            {label}
          </Label>
          <IconButton
            iconProps={{ iconName: "ClipboardList" }}
            title={`Copy ${label} to Clipboard`}
            ariaLabel={`Copy ${label} to Clipboard`}
            onClick={handleCopyTags}
          />
        </div>
      ) : null}
      <TagPicker
        onResolveSuggestions={(p) => isInCompanyCodes(p, 50)}
        removeButtonAriaLabel="Remove Company"
        pickerSuggestionsProps={{
          noResultsFoundText: "No results found"
        }}
        inputProps={{
          id: id,
          "aria-label": "Search companies",
          placeholder: "Search companies",
          onKeyDown: async (e) => {
            if (e.ctrlKey && e.code === "KeyV") {
              // Copy the selected tags to the clipboard when Ctrl+C is pressed
              e.preventDefault();
              const clipboardText = await navigator.clipboard.readText();
              const tagNames = clipboardText.split(";");
              const newTags = tagNames.map((name) => ({
                key: name.trim().toUpperCase(),
                name: name.trim().toUpperCase()
              }));
              const uniqueTags = newTags.filter((upperCaseTag) => {
                return !selectedCompanyTags.some((t) => t.key === upperCaseTag.key);
              });
              const newTagsFinal = [...selectedCompanyTags, ...uniqueTags];
              setSelectedCompanyTags(newTagsFinal);
              if (props.onChange) {
                const companyCodes = newTagsFinal.map((tag) => Number((tag.key as string).split(",")[0].trim()));
                props.onChange(companyCodes);
              }
            }
          }
        }}
        itemLimit={props.maxCompanies}
        resolveDelay={300}
        selectedItems={selectedCompanyTags}
        onChange={(items?: ITag[]) => {
          if (items) {
            setSelectedCompanyTags(items);
            if (props.onChange) {
              const companyCodes = items.map((tag) => Number((tag.key as string).split(",")[0].trim()));
              props.onChange(companyCodes);
            }
          }
        }}
        onEmptyResolveSuggestions={() => isInCompanyCodes("", 300)}
        styles={{ root: { marginTop: "0 !important", minWidth: 200 } }}
        disabled={!jemContext.initInfo.values}
      />
      {props.required && selectedCompanyTags.length === 0 && (
        <Label
          styles={{
            root: {
              fontSize: "12px",
              fontWeight: "400",
              color: theme.palette.redDark,
              margin: "0px !important",
              paddingTop: "5px"
            }
          }}
        >
          Company Code is required
        </Label>
      )}
    </>
  );
};

CompanyCodeMultiSelector = React.memo(CompanyCodeMultiSelector, (prev, next) => {
  return (
    prev.selectedCompanies === next.selectedCompanies &&
    prev.maxCompanies === next.maxCompanies &&
    prev.required === next.required
  );
});

CompanyCodeMultiSelector.displayName = "CompanyCodeMultiSelector";

export { CompanyCodeMultiSelector };
