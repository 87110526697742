import { EUARoles, IUserProviderState } from "../../Shared";
import { IDashboardListActionsState } from "../../Shared/components/DashboardListActions/DashboardListActions";
import { GridCommandToggle } from "../../Shared/components/DashboardListActions/DashboardListActions.types";
import { DomainDataObjects } from "../../Shared/contexts/JEMContext/JEMContext.domainData.types";
/**
 * @description - This component is used to calculate the weightages for grids with no Tiles (Search, Batch Search)
 * @returns
 */
export function GenericActionButtonCalculator<
  T extends { status: string; statusCode: number; access: number; rowWeightage: number }
>(_domainData: DomainDataObjects, roles: IUserProviderState["jemUser"]["roles"]) {
  // TODO: For initial load
  // Check if all rows[] have an action enabled based on rowWeightage
  // if no rows have it enabled, disable the action
  // maintain the initial state in here
  return (_rows: T[]): IDashboardListActionsState => {
    return {
      retryDisabled: GridCommandToggle.Enabled,
      saveDisabled: GridCommandToggle.Enabled,
      sendToPosterDisabled: GridCommandToggle.Removed,
      approveDisabled: GridCommandToggle.Enabled,
      releaseForSignoffDisabled: GridCommandToggle.Enabled,
      needsClarificationDisabled: GridCommandToggle.Enabled,
      addPosterDisabled: GridCommandToggle.Enabled,
      addReviewerDisabled: GridCommandToggle.Enabled,
      recallDisabled: GridCommandToggle.Enabled,
      rescindDisabled: roles.includes(EUARoles.ToolAdministrator)
        ? GridCommandToggle.Enabled
        : GridCommandToggle.Removed,
      addAttachmentDisabled: GridCommandToggle.Enabled,
      deleteActionDisabled: GridCommandToggle.Enabled,
      releaseToSAPDisabled: GridCommandToggle.Enabled,
      sendBackFromTreasuryDisabled: GridCommandToggle.Removed,
      approvePreReviewDisabled: GridCommandToggle.Removed,
      needsClarificationPreReviewDisabled: GridCommandToggle.Removed,
      recallPreReviewDisabled: GridCommandToggle.Removed,
      reverseDisabled: GridCommandToggle.Enabled
    };
  };
}
