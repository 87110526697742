import { Separator } from "@fluentui/react";
import {
  JemConfiguration,
  IAttachmentEntryFormRef,
  Attachments,
  IAttachment,
  IAttachmentEntryFormSingleUploadProps
} from "@jem/components";
import React, { forwardRef, useContext, useEffect, useImperativeHandle, useRef } from "react";
import { IHCCPODetailsContext } from "../../../contexts/IHCCPODetailsContext/IHCCPODetailsContext";
import { useIHCCAttachmentActions } from "../../../shared/IHCC.Attachments";

interface IHCCPODetailsAttachmentsProps {
  configuration: JemConfiguration["IhccApi"];
  onChange: IAttachmentEntryFormSingleUploadProps["onChange"];
  onDelete: IAttachmentEntryFormSingleUploadProps["onDelete"];
}

export interface IHCCPODetailsAttachmentsRef {
  getAttachments: IAttachmentEntryFormRef<IAttachment>["saveAndGetAttachments"];
  reset: () => void;
}

const IHCCPODetailsAttachments = forwardRef<IHCCPODetailsAttachmentsRef, IHCCPODetailsAttachmentsProps>(
  (props, ref) => {
    const poData = useContext(IHCCPODetailsContext);
    const attachmentsRef = useRef<IAttachmentEntryFormRef<IAttachment>>(null);
    const actions = useIHCCAttachmentActions(props.configuration);

    useEffect(() => {
      if (attachmentsRef.current) {
        attachmentsRef.current.setAttachments(poData.model.values.attachmentsTab, poData.userChanges.localAttachments);
      }
    }, []);

    useImperativeHandle(ref, () => ({
      getAttachments() {
        if (attachmentsRef.current) {
          return attachmentsRef.current.saveAndGetAttachments();
        }
        return Promise.resolve([
          {
            attachments: [],
            recentlyUploadedAttachments: []
          },
          null
        ]);
      },
      reset() {
        if (attachmentsRef.current) {
          return attachmentsRef.current.reset();
        }
      }
    }));

    return (
      <>
        <div className="ms-Grid" dir="ltr">
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12">
              <Separator />
              <Attachments<IAttachment>
                onUploadFile={actions.uploadAttachment}
                onDeleteFile={actions.deleteAttachment}
                onDownloadFile={actions.downloadAttachment}
                customRef={attachmentsRef}
                onChange={props.onChange}
                onDelete={props.onDelete}
                maxAttachments={10}
                disabled={poData.model.values.detailsTab.access !== 2}
              ></Attachments>
              <Separator />
            </div>
          </div>
        </div>
      </>
    );
  }
);

IHCCPODetailsAttachments.displayName = "IHCCPODetailsAttachments";

export default IHCCPODetailsAttachments;
