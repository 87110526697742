import { BaseRouteProps, UserContext, EUARoles } from "@jem/components";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { OpsDashboardProvider } from "./Operations/contexts/OpsDashboardContext/OpsDashboardContext";
import ApiTesterRoyalties from "./Operations/pages/ApiTesterRoyalties/ApiTesterRoyalties";
import OpsDashboard from "./Operations/pages/OpsDashboard/OpsDashboard";
import OpsDashboardCreate from "./Operations/pages/OpsDashboardCreate/OpsDashboardCreate";
import ReconProcess from "./Operations/pages/ReconProcess/ReconProcess";
import TenantDashboardPage from "./Operations/pages/TenantDashboard/TenantDashboardPage";
import UserDashboardPage from "./Operations/pages/UserDashboard/UserDashboardPage";

const Operations: React.FC<BaseRouteProps> = (props) => {
  const userContext = React.useContext(UserContext);

  return (
    <>
      {userContext.jemUser.roles.includes(EUARoles.ToolAdministrator) ? (
        <Routes>
          <>
            <Route path="/apitester" element={<ApiTesterRoyalties></ApiTesterRoyalties>} />
            <Route
              path="/dashboard"
              element={<OpsDashboard configuration={props.config.OpsDashboardApi}></OpsDashboard>}
            />
            <Route
              path="/tenantdashboard"
              element={<TenantDashboardPage configuration={props.config.GeneralLedgerApi}></TenantDashboardPage>}
            />
            <Route
              path="/userdashboard"
              element={<UserDashboardPage configuration={props.config.GeneralLedgerApi}></UserDashboardPage>}
            />
            <Route
              path="/recon"
              element={
                <ReconProcess
                  configuration={props.config.OpsDashboardApi}
                  attachmentsConfiguration={props.config.DocumentsApi}
                ></ReconProcess>
              }
            />
            <Route
              path="/dashboard/requests/:RowKey"
              element={
                <OpsDashboardProvider configuration={props.config.OpsDashboardApi}>
                  <OpsDashboardCreate configuration={props.config.OpsDashboardApi}></OpsDashboardCreate>
                </OpsDashboardProvider>
              }
            />
            <Route
              path="/dashboard/requests"
              element={
                <OpsDashboardProvider configuration={props.config.OpsDashboardApi}>
                  <OpsDashboardCreate configuration={props.config.OpsDashboardApi}></OpsDashboardCreate>
                </OpsDashboardProvider>
              }
            />
          </>
        </Routes>
      ) : (
        <Navigate to="/home" />
      )}
    </>
  );
};

Operations.displayName = "Operations";
export default Operations;
