import {
  FatalError,
  GLDetailsForm,
  GLJeDetailsSettingsFromJeId,
  GLJeDetailsSettingsFromRefGuid,
  JeDetailsTabs,
  JemConfiguration,
  JEMContext,
  LoadingStatus,
  LoggingContext,
  MockDataFn,
  ObjectKeys,
  parseTabNameToEnumTabName,
  redirectMeToTheOrigin,
  UserContext
} from "@jem/components";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { significanceIconMapping } from "../../../Shared/utilities/significanceIconMapping";

export interface GLDetailsProps {
  configuration: JemConfiguration["GeneralLedgerApi"];
  attachmentsConfiguration: JemConfiguration["DocumentsApi"];
  mockDashboardDataFn?: MockDataFn<any>;
}

export interface JeDetailsQueryStringParameters {
  jeid?: string;
  refGuid?: string;
  tabName: JeDetailsTabs;
}

const initialQueryStringValues: JeDetailsQueryStringParameters = {
  jeid: "",
  refGuid: "",
  tabName: JeDetailsTabs.jeDetails
};

function navigateToTheOrigin(navigate: ReturnType<typeof useNavigate>, location: ReturnType<typeof useLocation>) {
  let origin = "/gl/dashboard";
  const llocState = location.state as unknown as { origin: string; state: any };
  if (location.state && "origin" in llocState) {
    origin = llocState.origin || origin;
  }
  redirectMeToTheOrigin(navigate, location, 0, origin);
}

const GLDetails: React.FC<GLDetailsProps> = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { appInsights } = useContext(LoggingContext);
  if (!appInsights) {
    throw new FatalError("Please use a LoggingContext Provider.");
  }
  const userContext = useContext(UserContext);
  if (!userContext) {
    throw new FatalError("Please use a UserContext Provider.");
  }
  const domainContext = useContext(JEMContext);
  if (!domainContext) {
    throw new FatalError("Please use a JEMContext Provider.");
  }
  const initialQueryString = useRef<JeDetailsQueryStringParameters>(initialQueryStringValues);
  const [settings, setSettings] = useState<GLJeDetailsSettingsFromJeId | GLJeDetailsSettingsFromRefGuid | null>(null);

  useEffect(
    function getDetailsFromEndpoint() {
      if (
        domainContext.initInfo.status === LoadingStatus.Resolved ||
        domainContext.initInfo.status === LoadingStatus.Rejected
      ) {
        console.log("getDetailsFromEndpoint");
        for (const keyName of ObjectKeys(initialQueryStringValues)) {
          let value = searchParams.get(keyName);
          value = !value || value.length === 0 ? null : value;
          if (searchParams.has(keyName) && keyName.toLowerCase() === "jeid" && value) {
            initialQueryString.current.jeid = value;
          }
          if (searchParams.has(keyName) && keyName.toLowerCase() === "tabname" && value) {
            initialQueryString.current.tabName = parseTabNameToEnumTabName(value);
          }
          if (searchParams.has(keyName) && keyName.toLowerCase() === "refguid" && value) {
            initialQueryString.current.refGuid = value;
          }
        }
        if (initialQueryString.current.jeid && initialQueryString.current.jeid.trim() !== "") {
          setSettings({ jeid: initialQueryString.current.jeid, tabName: initialQueryString.current.tabName });
        } else if (initialQueryString.current.refGuid && initialQueryString.current.refGuid.trim() !== "") {
          setSettings({ refGuid: initialQueryString.current.refGuid, tabName: initialQueryString.current.tabName });
        } else {
          navigateToTheOrigin(navigate, location);
        }
      }
    },
    [searchParams, domainContext.initInfo.values]
  );

  return (
    <>
      <GLDetailsForm
        configuration={props.configuration}
        attachmentsConfiguration={props.attachmentsConfiguration}
        domainData={domainContext.initInfo.values}
        settings={settings}
        significanceIcons={significanceIconMapping}
        onNavigate={() => {
          const from = location.state && (location.state as any).from ? (location.state as any).from : undefined;
          if (from) {
            navigate(from);
          } else {
            navigate("/gl/dashboard");
          }
        }}
        onNavigateBack={() => {
          navigate(-1);
        }}
      />
    </>
  );
};
GLDetails.displayName = "GLDetails";
export default GLDetails;
