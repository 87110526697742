import React, { createContext, useState } from "react";

export interface MainContainerDimensions {
  width: number;
  height: number;
}

export type ResizeCallback = (event: MainContainerDimensions) => void;

export interface PageProviderValue {
  pageResized: (ev: MainContainerDimensions) => void;
  dimensions: MainContainerDimensions | null;
  getNavCollapsedState: () => boolean;
  setNavCollapsedFlag: (flag: boolean) => void;
}

const initialState: PageProviderValue = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  pageResized: () => {},
  dimensions: null,
  getNavCollapsedState: () => false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setNavCollapsedFlag: () => {}
};

const PageContext = createContext(initialState);

interface ILoggingProviderProps {
  children: React.ReactNode;
}

const PageProvider = (props: ILoggingProviderProps) => {
  const [dimensions, setDimensions] = useState<MainContainerDimensions | null>(null);
  const [navCollapsed, setNavCollapsed] = useState<boolean>(false);

  return (
    <PageContext.Provider
      value={{
        dimensions,
        pageResized: (ev: MainContainerDimensions) => {
          setDimensions(ev);
        },
        getNavCollapsedState: () => navCollapsed,
        setNavCollapsedFlag: (flag: boolean) => {
          setNavCollapsed(flag);
        }
      }}
    >
      {props.children}
    </PageContext.Provider>
  );
};

export { PageContext, PageProvider };
