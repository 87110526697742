import { css } from "@emotion/css";
import {
  CommandBar,
  DefaultButton,
  Dropdown,
  FocusTrapZone,
  ICommandBarItemProps,
  IDropdownOption,
  Layer,
  Overlay,
  Popup,
  PrimaryButton,
  Theme,
  useTheme
} from "@fluentui/react";
import React, { useState } from "react";
import { buttonStyles } from "../../../Shared/utilities/CommonStyles";
import { IUserProviderState } from "../../../Shared/utilities/RequestUtilities";
import { EmptyRefGuid } from "../../utilities/EmptyRefGuid";
import { GLBatchPageState } from "./GLBatch.State";
import { noActivityReasonOptions, popupStyles } from "../../shared/noActivityAction";
import { AdditionalData } from "../../shared/GL.ActionsRequests";
import { debounce } from "../../../Shared";

export enum GLBatchActionsEnum {
  Save = "Save",
  Clear = "Clear",
  Validate = "Validate",
  Post = "Post",
  DownloadExcel = "DownloadExcel",
  DownloadXML = "DownloadXML",
  NoActivity = "NoActivity",
  PreReview = "PreReview"
}
interface GLBatchActionsProps {
  onAction: (actionName: GLBatchActionsEnum, options?: AdditionalData) => void;
  disabled: boolean;
  jemUser: IUserProviderState["jemUser"];
  batchState: GLBatchPageState["batchState"];
}

function CommandsForGLBatch(
  onActionCallback: GLBatchActionsProps["onAction"],
  setShowNoActivityModal: any,
  disabled: boolean,
  theme: Theme,
  jemUser: IUserProviderState["jemUser"],
  batchState: GLBatchPageState["batchState"]
): ICommandBarItemProps[] {
  // Basically, Batch's AC (Access Control) is super simple.
  const actionsDisabled = disabled || !jemUser.hasSapAccess || [3, 5, 8, 9].includes(batchState.status);

  const noActivitySupported =
    batchState.userSelectedChecklist &&
    batchState.userSelectedChecklist.checklistRefGuid !== EmptyRefGuid() &&
    batchState.userSelectedChecklist.checklistRefGuid !== "";
  const noActivityDisabledPerStatus =
    batchState.status === 8 || batchState.status === 9 || batchState.isAdhocDraft === true;
  const noActivityDisabled = !noActivitySupported || noActivityDisabledPerStatus;

  return [
    {
      key: "save",
      name: "Save",
      iconProps: { iconName: "Save" },
      onClick: debounce(() => onActionCallback(GLBatchActionsEnum.Save), 200) as any,
      disabled: actionsDisabled,
      buttonStyles: buttonStyles(theme)
    },
    {
      key: "validate",
      name: "Validate",
      iconProps: { iconName: "CheckList" },
      onClick: debounce(() => onActionCallback(GLBatchActionsEnum.Validate), 200) as any,
      disabled: actionsDisabled,
      buttonStyles: buttonStyles(theme)
    },
    {
      key: "post",
      name: "Post",
      iconProps: { iconName: "PublishContent" },
      onClick: debounce(() => onActionCallback(GLBatchActionsEnum.Post), 200) as any,
      disabled: actionsDisabled,
      buttonStyles: buttonStyles(theme)
    },
    {
      key: "downloadExcel",
      name: "Download Excel Template",
      iconProps: { iconName: "ExcelDocument" },
      onClick: debounce(() => onActionCallback(GLBatchActionsEnum.DownloadExcel), 200) as any,
      disabled: disabled,
      buttonStyles: buttonStyles(theme)
    },
    {
      key: "downloadXml",
      name: "Download XML Template",
      iconProps: { iconName: "DownloadDocument" },
      onClick: debounce(() => onActionCallback(GLBatchActionsEnum.DownloadXML), 200) as any,
      disabled: disabled,
      buttonStyles: buttonStyles(theme)
    },
    {
      key: "noActivity",
      name: "No Activity",
      iconProps: { iconName: "UnsyncOccurence" },
      //onClick: debounce(() => onActionCallback(GLBatchActionsEnum.NoActivity), 200) as any,
      onClick: debounce(() => setShowNoActivityModal(true), 200) as any,
      disabled: disabled || noActivityDisabled,
      buttonStyles: buttonStyles(theme)
    },
    {
      key: "preReview",
      name: "Pre-Review",
      iconProps: { iconName: "Preview" },
      onClick: debounce(() => onActionCallback(GLBatchActionsEnum.PreReview), 200) as any,
      disabled: actionsDisabled,
      buttonStyles: buttonStyles(theme)
    },
    {
      key: "clearForm",
      name: "Clear Form",
      iconProps: { iconName: "RemoveFromShoppingList" },
      onClick: debounce(() => onActionCallback(GLBatchActionsEnum.Clear), 200) as any,
      disabled: actionsDisabled,
      buttonStyles: buttonStyles(theme)
    }
  ];
}

const GLBatchActions: React.FC<GLBatchActionsProps> = (props) => {
  const theme = useTheme();

  const [showNoActivityModal, setShowNoActivityModal] = useState<boolean>(false);
  const [selectedNoActivityReason, setselectedNoActivityReason] = useState<string>("");
  const hidePopup = () => {
    setShowNoActivityModal(false);
  };

  const onAction = (actionName: GLBatchActionsEnum) => {
    if (props.onAction) {
      props.onAction(actionName, undefined);
      if (actionName === GLBatchActionsEnum.PreReview) {
        props.batchState.isPreReview = true;
      }
    }
  };

  const commandBarItems = CommandsForGLBatch(
    onAction,
    setShowNoActivityModal,
    props.disabled,
    theme,
    props.jemUser,
    props.batchState
  );
  return (
    <>
      <div
        className={css`
          width: 100%;
          min-height: 1px;
          box-sizing: border-box;
          overflow: hidden;
        `}
      >
        <CommandBar
          items={commandBarItems}
          styles={{
            root: {
              paddingLeft: 0,
              backgroundColor: theme.palette.neutralLighterAlt
            }
          }}
          ariaLabel={"Actions for Batch"}
          overflowButtonProps={{
            ariaLabel: "More Actions",
            styles: {
              root: {
                backgroundColor: theme.palette.neutralLighterAlt
              }
            }
          }}
        />
      </div>
      {showNoActivityModal && (
        <Layer>
          <Popup className={popupStyles.root} role="dialog" aria-modal="true" onDismiss={hidePopup}>
            <Overlay onClick={hidePopup} />
            <FocusTrapZone>
              <div role="document" className={popupStyles.content}>
                <p>Select No Activity reason</p>
                <Dropdown
                  required
                  id="noActivityReason"
                  label="No Activity Reason"
                  placeholder="Select an option"
                  options={noActivityReasonOptions}
                  selectedKey={selectedNoActivityReason}
                  errorMessage={""}
                  onChange={(_e: any, newValue: IDropdownOption<any> | undefined): void => {
                    console.log("noActivityReason in glcreation actions" + newValue?.text);
                    setselectedNoActivityReason(newValue?.text || "");
                  }}
                />

                <PrimaryButton
                  onClick={() => {
                    if (props.onAction) {
                      props.onAction(GLBatchActionsEnum.NoActivity, { noActivityReason: selectedNoActivityReason });
                    }
                    hidePopup();
                  }}
                  disabled={!selectedNoActivityReason}
                >
                  Submit
                </PrimaryButton>
                <DefaultButton onClick={hidePopup}>Close</DefaultButton>
              </div>
            </FocusTrapZone>
          </Popup>
        </Layer>
      )}
    </>
  );
};

GLBatchActions.displayName = "GLBatchActions";
export { GLBatchActions };
