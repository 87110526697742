import { PrimaryButton } from "@fluentui/react";
import { PageStyles } from "@jem/components";
import React, { useRef } from "react";
import DragAndDrop, { IDragAndDrop } from "../../components/DragAndDrop/DragAndDrop";
import TenantDropdown, { ITenantDropdown } from "../../components/TenantDropdown/TenantDropdown";

// const uploadSingleFile = async (getTokenFn: IUserProviderState["accessToken"], file: File) => {
//   const sasTokenProps = await getSasUri(getTokenFn);
//   const prefix = `files-${new Date().toLocaleDateString().replace(/\//g, "-")}`;
//   const responseFromBlobClient = await uploadToBlob(sasTokenProps, prefix, file);
//   const responseFromQueueClient = await sendToQueue(sasTokenProps, prefix, file);

//   console.log(responseFromBlobClient.requestId, responseFromQueueClient.requestId);
// };

let ApiTesterRoyalties: React.FC = () => {
  // const { accessToken } = useContext(UserContext);
  // const [addedFiles, setAddedFiles] = useState<File[]>([]);
  const dragAndDroRef = useRef<IDragAndDrop["ref"]>(null);
  const dropdownRef = useRef<ITenantDropdown["ref"]>(null);

  // const [, setMessage] = useState<IMessageType | null>(null);

  // todo: finish this
  // const onChange = async (files: FileList) => {
  //   if (files) {
  //     for (const file of Array.from(files)) {
  //       try {
  //         await uploadSingleFile(accessToken, file);
  //         setMessage({
  //           type: "success",
  //           message: "Successfully uploaded File."
  //         });
  //       } catch (err) {
  //         console.log(1, err);
  //         setMessage({
  //           type: "error",
  //           message: err instanceof Error ? err.message : "An unknown error occurred."
  //         });
  //       }
  //     }
  //   }
  // };

  return (
    <div className={PageStyles.rootDiv}>
      <TenantDropdown type="Royalties" ref={dropdownRef}></TenantDropdown>
      <DragAndDrop ref={dragAndDroRef}></DragAndDrop>
      <PrimaryButton
        onClick={() => {
          if (dragAndDroRef.current && dropdownRef.current) {
            console.log(dropdownRef.current.getState());
            console.log(dragAndDroRef.current.getAddedFiles());
          }
        }}
      >
        Upload
      </PrimaryButton>
    </div>
  );
};

ApiTesterRoyalties.displayName = "ApiTesterRoyalties";
ApiTesterRoyalties = React.memo(ApiTesterRoyalties);
export default ApiTesterRoyalties;
