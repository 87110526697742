import { IDashboardListActionsState } from "./DashboardListActions";

export enum GridCommandToggle {
  Enabled = "Enabled",
  Disabled = "Disabled",
  Removed = "Removed"
}

export interface DashboardListActionsProps {
  hide: boolean;
  preventCollapse?: boolean;
  initialState?: IDashboardListActionsState;
  buttonCalculator: (rows: any) => IDashboardListActionsState;
  retry?: () => void;
  save?: () => void;
  sendToPoster?: () => void;
  approve?: () => void;
  releaseForSignoff?: () => void;
  needsClarification?: () => void;
  addPoster?: () => void;
  addReviewer?: () => void;
  recall?: () => void;
  rescind?: () => void;
  addAttachment?: () => void;
  deleteAction?: () => void;
  releaseToSAP?: () => void;
  sendBackFromTreasury?: () => void;
  retryDisabled?: GridCommandToggle;
  saveDisabled?: GridCommandToggle;
  sendToPosterDisabled?: GridCommandToggle;
  approveDisabled?: GridCommandToggle;
  releaseForSignoffDisabled?: GridCommandToggle;
  needsClarificationDisabled?: GridCommandToggle;
  addPosterDisabled?: GridCommandToggle;
  addReviewerDisabled?: GridCommandToggle;
  recallDisabled?: GridCommandToggle;
  rescindDisabled?: GridCommandToggle;
  addAttachmentDisabled?: GridCommandToggle;
  deleteActionDisabled?: GridCommandToggle;
  releaseToSAPDisabled?: GridCommandToggle;
  sendBackFromTreasuryDisabled?: GridCommandToggle;
  customRef: React.RefObject<DashboardListActionsRef>;
  approvePreReview?: () => void;
  needsClarificationPreReview?: () => void;
  recallPreReview?: () => void;
  approvePreReviewDisabled?: GridCommandToggle;
  needsClarificationPreReviewDisabled?: GridCommandToggle;
  recallPreReviewDisabled?: GridCommandToggle;
  reverse?: () => void;
  reverseDisabled?: GridCommandToggle;
}

export interface DashboardListActionsRef {
  refreshSelection: (items: any[]) => void;
}
