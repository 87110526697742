import { ApiParsingUtilities } from "../../../Shared/utilities/ApiParsingUtilities";
import { GLDetailsSapObjectFromAPI } from "./GLDetails.types";
import { GLJeSapDetailsLineItem, GLJeSapDetailsState } from "./GLDetailsForm.types";

export function sapDetailsEntityToState(responseObjectFromSap: GLDetailsSapObjectFromAPI): GLJeSapDetailsState {
  const parsed: GLJeSapDetailsState = {
    header: {
      docNumber: ApiParsingUtilities.addDefault(responseObjectFromSap.documentNumber),
      refNumber: ApiParsingUtilities.addDefault(responseObjectFromSap.referenceDoc),
      jeType: ApiParsingUtilities.addDefault(responseObjectFromSap.docType),
      description: ApiParsingUtilities.addDefault(responseObjectFromSap.docHeaderTxt),
      transaction: ApiParsingUtilities.addDefault(responseObjectFromSap.transaction),
      companyCode: ApiParsingUtilities.addDefault(responseObjectFromSap.companyCode),
      totalAmount: ApiParsingUtilities.parseTotal(
        responseObjectFromSap.totalDebit.toFixed(2),
        responseObjectFromSap.totalCredit.toFixed(2)
      ),
      debitAmount: ApiParsingUtilities.parseNumber(responseObjectFromSap.totalDebit.toFixed(2)),
      creditAmount: ApiParsingUtilities.parseNumber(
        isNaN(responseObjectFromSap.totalCredit)
          ? 0
          : Math.sign(responseObjectFromSap.totalCredit) === -1
          ? responseObjectFromSap.totalCredit.toFixed(2)
          : +responseObjectFromSap.totalCredit.toFixed(2) * -1
      ),
      fiscalYear: ApiParsingUtilities.addDefault(responseObjectFromSap.fiscalYear),
      fiscalPeriod: ApiParsingUtilities.addDefault(responseObjectFromSap.docPeriod),
      currency: ApiParsingUtilities.addDefault(responseObjectFromSap.docCurrKey),
      postingDate: ApiParsingUtilities.parseDate(responseObjectFromSap.postingDate),
      docDate: ApiParsingUtilities.parseDate(responseObjectFromSap.docDate),
      timeOfEntry: ApiParsingUtilities.addDefault(responseObjectFromSap.timeOfEntry)
    },
    lineItems: parseSapLineItems(responseObjectFromSap.sapLineItems)
  };
  return parsed;
}

function parseSapLineItems(sapLineItems: GLDetailsSapObjectFromAPI["sapLineItems"]): GLJeSapDetailsLineItem[] {
  return sapLineItems.map((sapLineItem) => {
    const cc = sapLineItem.costCentre;
    const io = sapLineItem.internalOrder;
    const ccIo = cc && io ? `${cc} / ${io}` : cc || io || "";
    const transAmt = ApiParsingUtilities.toDecimal(
      ApiParsingUtilities.parseSAPTransAmountToNumber(sapLineItem.transAmount)
    );
    const statAmount = ApiParsingUtilities.toDecimal(
      ApiParsingUtilities.parseStatAmtNumberToNumber(sapLineItem.statAmount)
    );
    const gcAmt = ApiParsingUtilities.toDecimal(
      ApiParsingUtilities.parseNumberToNumber(sapLineItem.groupCurrencyAmount)
    );

    const lineItem: GLJeSapDetailsLineItem = {
      item: ApiParsingUtilities.addDefault(sapLineItem.item),
      pos: ApiParsingUtilities.addDefault(sapLineItem.postKey),
      account: ApiParsingUtilities.addDefault(sapLineItem.accountNumber),
      accountDescription: ApiParsingUtilities.addDefault(sapLineItem.accountDescription),
      assignmentId: ApiParsingUtilities.addDefault(sapLineItem.assignmentId),
      ccIo: ApiParsingUtilities.addDefault(ccIo),
      so: ApiParsingUtilities.addDefault(sapLineItem.salesOrder),
      soli: ApiParsingUtilities.addDefault(sapLineItem.soLineItem),
      tp: ApiParsingUtilities.addDefault(sapLineItem.tradingPartner),
      tc: ApiParsingUtilities.addDefault(sapLineItem.taxcode),
      pc: ApiParsingUtilities.addDefault(sapLineItem.profitCenter),
      transAmt,
      transCurr: ApiParsingUtilities.addDefault(sapLineItem.transCurrency),
      statAmount,
      statCurr: ApiParsingUtilities.addDefault(sapLineItem.statCurreny),
      gcAmt,
      gcCurr: ApiParsingUtilities.addDefault(sapLineItem.groupCurrencyKey),
      lineItemDescription: ApiParsingUtilities.addDefault(sapLineItem.lineItemDesc),
      profitCenterDescription: ApiParsingUtilities.addDefault(sapLineItem.profitcenterdesc),
      localcurramT2: ApiParsingUtilities.addDefault(sapLineItem.localcurramT2),
      localcurramT3: ApiParsingUtilities.addDefault(sapLineItem.localcurramT3),
      localcurrkeY2: ApiParsingUtilities.addDefault(sapLineItem.localcurrkeY2),
      localcurrkeY3: ApiParsingUtilities.addDefault(sapLineItem.localcurrkeY3)
    };
    return lineItem;
  });
}
