import {
  JemConfiguration,
  getValidUrl,
  getRequest,
  ApiError,
  TenantResponseFromAPI,
  IUserProviderState,
  TenantRowDetails,
  postRequest
} from "@jem/components";

export async function getTenantDetails(
  configuration: JemConfiguration["GeneralLedgerApi"],
  getTokenFn: IUserProviderState["accessToken"]
): Promise<TenantResponseFromAPI[]> {
  const endpoint = `${configuration.baseApiUrl}${configuration.endpoints.getAllTenantDetails}`;

  const endpointUrl = getValidUrl(endpoint);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const response = await getRequest<any>(endpointUrl, getTokenFn);

  if (!response) {
    throw new ApiError("No response from server");
  }

  return response.map((res: TenantResponseFromAPI) => {
    const buildResp: TenantResponseFromAPI = res;
    buildResp["batchNamePrefix"] = res.miscConfig.batchNamePrefix;
    buildResp["allowAutoReviewString"] = buildResp.allowAutoReview ? "true" : "false";
    buildResp["overrideTenantSettingString"] = buildResp.overrideTenantSetting ? "true" : "false";
    return buildResp;
  });
}

export interface ITenantPanelResponse {
  type: string;
  title: string;
  status: number;
  detail: string;
  instance: string;
  extensions: unknown;
}

export async function onBoardNewTenantDetails(
  configuration: JemConfiguration["GeneralLedgerApi"],
  getTokenFn: IUserProviderState["accessToken"],
  data: TenantRowDetails
): Promise<ITenantPanelResponse> {
  const endpoint = `${configuration.baseApiUrl}${configuration.endpoints.onboardTenantDetails}`;
  const endpointUrl = getValidUrl(endpoint);

  const payload: TenantRowDetails = data;
  const response = await postRequest<ITenantPanelResponse>(endpointUrl, payload, getTokenFn);

  if (!response) {
    return {
      type: "API Error",
      title: "No response from server",
      status: 400,
      detail: "Something went wrong!!!",
      instance: "",
      extensions: []
    };
  }

  return response;
}

export async function updateTenantDetails(
  configuration: JemConfiguration["GeneralLedgerApi"],
  getTokenFn: IUserProviderState["accessToken"],
  data: TenantRowDetails
): Promise<ITenantPanelResponse> {
  const endpoint = `${configuration.baseApiUrl}${configuration.endpoints.updateTenantDetails}`;
  const endpointUrl = getValidUrl(endpoint);

  const payload: TenantRowDetails = data;
  const response = await postRequest<ITenantPanelResponse>(endpointUrl, payload, getTokenFn);

  if (!response) {
    return {
      type: "API Error",
      title: "No response from server",
      status: 400,
      detail: "Something went wrong!!!",
      instance: "",
      extensions: []
    };
  }

  return response;
}
