import React, { useContext, useState, useEffect } from "react";
import { For } from "react-loops";
import { UserContext } from "../../contexts";
import { DefaultButton } from "@fluentui/react";

const UserProfile: React.FC = () => {
  const user = useContext(UserContext);
  const roles = user.jemUser.roles;
  const [disableRefresh, setDisableRefresh] = useState(false);

  useEffect(() => {
    setDisableRefresh(false);
  }, [user.jemUser.roles]);

  const onRefreshRoles = () => {
    setDisableRefresh(true);
    localStorage.removeItem("JemUserRoles");
    user.refreshRoles(true);
  };

  return (
    <>
      <div className="row">
        <div style={{ display: "flex" }}>
          <h4 style={{ margin: "0px" }}>Refresh Roles: </h4>
          <DefaultButton
            iconProps={{ iconName: "Refresh" }}
            ariaLabel="Refresh"
            onClick={onRefreshRoles}
            style={{ height: "25px", marginLeft: "5px", top: "-2px" }}
            disabled={disableRefresh}
          />
        </div>
        <div className="col-md-12">
          <h2>Your Roles</h2>
        </div>
        <div className="col-md-12">
          <ul>
            <For of={roles}>{(item) => <li>{item}</li>}</For>
          </ul>

          <p>
            If you need access to JEM internal roles (Auditor, bulk poster, Subledger reviewer etc.,) please click{" "}
            <a href={user.jemUser.euaPortalUrl} rel="noreferrer" target="_blank">
              here
            </a>
            .
          </p>
          <user.jemUser.accessMessageComponent />
          <p>
            If you need to change your SAP permissions, please click{" "}
            <a href="http://sapaccess" target="_blank">
              here
            </a>
            .
          </p>
          <p>
            In order to retain access to the JEM tool, please be active in the tool at least once per 80 days. Inactive
            accounts will be subject to auto deprovision per Enterprise User Access policy.
          </p>
        </div>
      </div>
    </>
  );
};

UserProfile.displayName = "UserProfile";
export { UserProfile };
