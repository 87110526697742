import { ContextualMenu, mergeStyles, Modal } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import React, { PropsWithChildren, useContext, useImperativeHandle, useReducer, useState } from "react";
import { Case, Switch } from "react-condition";
import { JemConfiguration } from "../../../JemConfiguration";
import { GLAttachmentEndpointMap } from "../../../GeneralLedger/shared/GL.Attachments";
import { ActionsContext } from "../Actions/Actions.ActionsContext";
import { BaseActionProps, CommonPropsOnRows } from "../Actions/Actions.types";
import { AddPoster } from "../Actions/AddPoster";
import { AddReviewer } from "../Actions/AddReviewer";
import { Approve } from "../Actions/Approve";
import { AttachmentsAction } from "../Actions/AttachmentsAction";
import { Delete } from "../Actions/Delete";
import { GLAttachmentsAction } from "../Actions/GLAttachmentsAction";
import { GLDelete } from "../Actions/GLDelete";
import { GLRescind } from "../Actions/GLRescind";
import { NeedsClarification } from "../Actions/NeedsClarification";
import { Recall } from "../Actions/Recall";
import { ReleaseForSignoff } from "../Actions/ReleaseForSignoff";
import { Rescind } from "../Actions/Rescind";
import { Save } from "../Actions/Save";
import { SaveToSAP } from "../Actions/SaveToSAP";
import { SendBackFromTreasury } from "../Actions/SendBackFromTreasury";
import { SendToPoster } from "../Actions/SendToPoster";
import {
  ActionEndpointMap,
  ActionResult,
  ActionTypes,
  AttachmentEndpointMap,
  GLActionEndpointMap,
  settingsReducer,
  SingleAttachmentEndpointMap
} from "./ActionsManager.types";
import { Reverse } from "../Actions/Reverse";

export interface IActionsManagerRef<ListType> {
  open: (actionName: ActionTypes, selectedItems: ListType[]) => void;
}
export interface IActionsManagerCommonProps<ListType, ResultType> {
  onCancel: () => void;
  onSubmit: (actionResult: ResultType) => void;

  customRef: React.Ref<IActionsManagerRef<ListType>>;
}

export interface IActionsManagerGLProps<ListType, ResultType> {
  attachmentsActionsMap?: GLAttachmentEndpointMap;
  configuration?: JemConfiguration["GeneralLedgerApi"];
  endpointMap: GLActionEndpointMap<ListType, ResultType>;
}

export interface IActionsManagerPaymentOrderProps<ListType, ResultType> {
  attachmentsActionsMap?: AttachmentEndpointMap;
  endpointMap: ActionEndpointMap<ListType, ResultType>;
  configuration?: never;
}

export type IActionsManagerProps<ListType, ResultType> = IActionsManagerCommonProps<ListType, ResultType> &
  (IActionsManagerGLProps<ListType, ResultType> | IActionsManagerPaymentOrderProps<ListType, ResultType>);

const ActionsManager = <ListType extends CommonPropsOnRows, ResultType extends ActionResult>(
  props: PropsWithChildren<IActionsManagerProps<ListType, ResultType>>
) => {
  const [settings, defineSettings] = useReducer<settingsReducer>((state, newState) => {
    return newState;
  }, null);
  const { styles } = useContext(ActionsContext);
  const [items, setItems] = useState<ListType[]>([]);

  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);

  useImperativeHandle(props.customRef, () => ({
    open(name, selectedItems) {
      defineSettings({
        name
      });
      setItems(selectedItems);
      showModal();
      window.scrollTo(0, 0);
    }
  }));

  const closeOverlay = () => {
    defineSettings(null);
    setItems([]);
    hideModal();
  };

  const commonActionProps: BaseActionProps<ListType, ResultType> = {
    onCancel: () => {
      closeOverlay();
      props.onCancel();
    },
    onSubmit: (result) => {
      closeOverlay();
      props.onSubmit(result);
    },
    items,
    configuration: props.configuration
  };

  const saveAction = props.endpointMap[ActionTypes.save];
  const sendToPosterAction = props.endpointMap[ActionTypes.sendToPoster];
  const approveAction = props.endpointMap[ActionTypes.approve];
  const releaseForSignoffAction = props.endpointMap[ActionTypes.releaseForSignoff];
  const needsClarificationAction = props.endpointMap[ActionTypes.needsClarification];
  const addPosterAction = props.endpointMap[ActionTypes.addPoster];
  const addReviewerAction = props.endpointMap[ActionTypes.addReviewer];
  const recallAction = props.endpointMap[ActionTypes.recall];
  const rescindAction = props.endpointMap[ActionTypes.rescind];
  const addAttachmentAction = props.endpointMap[ActionTypes.addAttachment];
  const deleteAction = props.endpointMap[ActionTypes.deleteAction];
  const saveToSAPAction = props.endpointMap[ActionTypes.saveToSAP];
  const sendBackFromTreasuryAction = props.endpointMap[ActionTypes.sendBackFromTreasury];
  const recallPreReviewAction = props.endpointMap[ActionTypes.recallPreReview];
  const approvePreReviewAction = props.endpointMap[ActionTypes.approvePreReview];
  const needsClarificationPreReviewAction = props.endpointMap[ActionTypes.needsClarificationPreReview];
  const reverseAction = props.endpointMap[ActionTypes.reverse];

  const containerStyles =
    settings?.name === ActionTypes.addAttachment || settings?.name === ActionTypes.rescind
      ? mergeStyles(styles.contentStyles.container, {
          top: "75px"
        })
      : styles.contentStyles.container;
  return (
    <>
      <Modal
        isOpen={isModalOpen}
        onDismiss={closeOverlay}
        isBlocking={true}
        topOffsetFixed={true}
        containerClassName={containerStyles}
        layerProps={{
          eventBubblingEnabled: true
        }}
        dragOptions={{
          moveMenuItemText: "Move",
          closeMenuItemText: "Close",
          menu: ContextualMenu
        }}
      >
        {settings === null ? null : (
          <>
            <Switch expression={settings.name}>
              <Case value={"save"}>
                {saveAction ? <Save {...commonActionProps} onSubmitHandler={saveAction}></Save> : undefined}
              </Case>
              <Case value={"sendToPoster"}>
                {sendToPosterAction ? (
                  <SendToPoster {...commonActionProps} onSubmitHandler={sendToPosterAction}></SendToPoster>
                ) : undefined}
              </Case>
              <Case value={"approve"}>
                {approveAction ? <Approve {...commonActionProps} onSubmitHandler={approveAction}></Approve> : undefined}
              </Case>
              <Case value={"releaseForSignoff"}>
                {releaseForSignoffAction ? (
                  <ReleaseForSignoff
                    {...commonActionProps}
                    onSubmitHandler={releaseForSignoffAction}
                  ></ReleaseForSignoff>
                ) : undefined}
              </Case>
              <Case value={"needsClarification"}>
                {needsClarificationAction ? (
                  <NeedsClarification
                    {...commonActionProps}
                    onSubmitHandler={needsClarificationAction}
                  ></NeedsClarification>
                ) : undefined}
              </Case>
              <Case value={"addPoster"}>
                {addPosterAction ? (
                  <AddPoster {...commonActionProps} onSubmitHandler={addPosterAction}></AddPoster>
                ) : undefined}
              </Case>
              <Case value={"addReviewer"}>
                {addReviewerAction ? (
                  <AddReviewer {...commonActionProps} onSubmitHandler={addReviewerAction}></AddReviewer>
                ) : undefined}
              </Case>
              <Case value={"recall"}>
                {recallAction ? <Recall {...commonActionProps} onSubmitHandler={recallAction}></Recall> : undefined}
              </Case>
              <Case value={"rescind"}>
                {rescindAction && props.attachmentsActionsMap ? (
                  props.configuration ? (
                    <GLRescind
                      {...commonActionProps}
                      onSubmitHandler={rescindAction}
                      onDeleteFile={(props.attachmentsActionsMap as GLAttachmentEndpointMap).deleteAttachment}
                      onDownloadFile={(props.attachmentsActionsMap as GLAttachmentEndpointMap).downloadAttachment}
                      onUploadFile={(props.attachmentsActionsMap as GLAttachmentEndpointMap).uploadAttachment}
                      configuration={props.configuration}
                    />
                  ) : (
                    <Rescind
                      {...commonActionProps}
                      onSubmitHandler={
                        rescindAction as NonNullable<ActionEndpointMap<ListType, ResultType>[ActionTypes.rescind]>
                      }
                      onDeleteFile={(props.attachmentsActionsMap as AttachmentEndpointMap).deleteAttachment}
                      onDownloadFile={(props.attachmentsActionsMap as AttachmentEndpointMap).downloadAttachment}
                      onUploadFile={(props.attachmentsActionsMap as AttachmentEndpointMap).uploadAttachment}
                    ></Rescind>
                  )
                ) : undefined}
              </Case>
              <Case value={"addAttachment"}>
                {addAttachmentAction && props.attachmentsActionsMap ? (
                  props.configuration ? (
                    <GLAttachmentsAction
                      {...commonActionProps}
                      configuration={props.configuration}
                      onSubmitHandler={addAttachmentAction}
                      onDeleteFile={(props.attachmentsActionsMap as GLAttachmentEndpointMap).deleteAttachment}
                      onDownloadFile={(props.attachmentsActionsMap as GLAttachmentEndpointMap).downloadAttachment}
                      onUploadFile={(props.attachmentsActionsMap as GLAttachmentEndpointMap).uploadAttachment}
                    ></GLAttachmentsAction>
                  ) : (
                    <AttachmentsAction
                      {...commonActionProps}
                      onSubmitHandler={
                        addAttachmentAction as NonNullable<
                          ActionEndpointMap<ListType, ResultType>[ActionTypes.addAttachment]
                        >
                      }
                      onDeleteFile={(props.attachmentsActionsMap as AttachmentEndpointMap).deleteAttachment}
                      onDownloadFile={(props.attachmentsActionsMap as AttachmentEndpointMap).downloadAttachment}
                      onUploadFile={(props.attachmentsActionsMap as SingleAttachmentEndpointMap).uploadAttachment}
                    ></AttachmentsAction>
                  )
                ) : undefined}
              </Case>
              <Case value={"deleteAction"}>
                {deleteAction ? (
                  props.configuration ? (
                    <GLDelete {...commonActionProps} onSubmitHandler={deleteAction}></GLDelete>
                  ) : (
                    <Delete {...commonActionProps} onSubmitHandler={deleteAction}></Delete>
                  )
                ) : undefined}
              </Case>
              {/* Specific To Treasury Dashboard */}
              <Case value={"saveToSAP"}>
                {saveToSAPAction ? (
                  <SaveToSAP {...commonActionProps} onSubmitHandler={saveToSAPAction}></SaveToSAP>
                ) : undefined}
              </Case>
              <Case value={"sendBackFromTreasury"}>
                {sendBackFromTreasuryAction ? (
                  <SendBackFromTreasury
                    {...commonActionProps}
                    onSubmitHandler={sendBackFromTreasuryAction}
                  ></SendBackFromTreasury>
                ) : undefined}
              </Case>
              <Case value={"recallPreReview"}>
                {recallPreReviewAction ? (
                  <Recall {...commonActionProps} onSubmitHandler={recallPreReviewAction}></Recall>
                ) : undefined}
              </Case>
              <Case value={"approvePreReview"}>
                {approvePreReviewAction ? (
                  <Approve {...commonActionProps} onSubmitHandler={approvePreReviewAction}></Approve>
                ) : undefined}
              </Case>
              <Case value={"needsClarificationPreReview"}>
                {needsClarificationPreReviewAction ? (
                  <NeedsClarification
                    {...commonActionProps}
                    onSubmitHandler={needsClarificationPreReviewAction}
                  ></NeedsClarification>
                ) : undefined}
              </Case>
              <Case value={"reverse"}>
                {reverseAction ? (
                  <Reverse
                    {...commonActionProps}
                    onSubmitHandler={reverseAction}
                    onDeleteFile={(props.attachmentsActionsMap as GLAttachmentEndpointMap).deleteAttachment}
                    onDownloadFile={(props.attachmentsActionsMap as GLAttachmentEndpointMap).downloadAttachment}
                    onUploadFile={(props.attachmentsActionsMap as GLAttachmentEndpointMap).uploadAttachment}
                  ></Reverse>
                ) : undefined}
              </Case>
            </Switch>
          </>
        )}
      </Modal>
    </>
  );
};

export { ActionsManager };
