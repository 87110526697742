import React, { CSSProperties, useEffect, useState } from "react";
import {
  Dropdown,
  DropdownMenuItemType,
  IDropdownOption,
  IconButton,
  IDropdownProps,
  SearchBox,
  Stack,
  Label
} from "@fluentui/react";

export interface IFieldsetProps {
  id?: string;
  label?: string;
  ariaLabel?: string;
  selectedKey: IDropdownProps["selectedKey"];
  onChange: (option?: IDropdownOption) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errorMessage?: string;
  options?: IDropdownOption[];
  disabled?: boolean;
  required?: boolean;
  width?: number | "auto" | string;
  styles: IDropdownProps["styles"];
  allowClear?: boolean;
}

const QueryBasedDropdown: React.FunctionComponent<IFieldsetProps> = (props: IFieldsetProps): JSX.Element => {
  const { id, label, ariaLabel, required, selectedKey, onChange, options, errorMessage, width, disabled } = props;
  const [defaultKey, setDefaultKey] = useState<IDropdownProps["selectedKey"]>(selectedKey || "");
  const [searchText, setSearchText] = React.useState<string>("");
  const noOptions = options && options.length === 0;
  let dropdownOptions: IDropdownOption[] = [];

  if (options) {
    dropdownOptions = options.map((dropdownOption): IDropdownOption => {
      return {
        ...(dropdownOption as IDropdownOption),
        ariaLabel: dropdownOption.text === "--" ? "None" : dropdownOption.text
      };
    });
  }
  const renderOption = (option: IDropdownOption): JSX.Element => {
    return option.itemType === DropdownMenuItemType.Header && option.key === "FilterHeader" ? (
      <SearchBox onChange={(_ev, newValue) => setSearchText(newValue || "")} placeholder="Search" underlined />
    ) : (
      <>{option.text} </>
    );
  };

  const styles = props.styles || { root: { width: width || 300 } };
  const dropdownWrapperStyle: CSSProperties = {
    maxWidth: "100%"
  };

  useEffect(() => {
    setDefaultKey(selectedKey || "");
  }, [selectedKey]);

  const removeSelection = () => {
    setDefaultKey("");
    if (onChange) {
      onChange();
    }
  };

  const onRenderLabel = (): JSX.Element => {
    /** FULL WIDTH */
    const isHidden = !props.allowClear || !defaultKey;

    return (
      <Stack horizontal verticalAlign="center" horizontalAlign="space-between">
        <Stack.Item>
          <Label>{props.label}</Label>
        </Stack.Item>
        <Stack.Item>
          {props.allowClear && (
            <IconButton
              iconProps={{ iconName: "Cancel" }}
              title="Clear Selection"
              ariaLabel="Clear Selection"
              styles={{ root: { marginBottom: -3, height: "29px !important" } }}
              onClick={removeSelection}
              hidden={isHidden}
              disabled={isHidden || disabled}
            />
          )}
        </Stack.Item>
      </Stack>
    );
  };

  return (
    <div style={dropdownWrapperStyle}>
      <Dropdown
        id={id}
        label={label}
        ariaLabel={ariaLabel}
        required={required}
        selectedKey={defaultKey}
        onChange={(event, option): void => {
          if (!option) return;
          setDefaultKey(option.key as string);
          if (onChange) {
            onChange(option);
          }
        }}
        disabled={disabled || noOptions}
        placeholder={noOptions ? "There are no options" : "Select an option"}
        options={[
          { key: "FilterHeader", text: "-", itemType: DropdownMenuItemType.Header },
          ...dropdownOptions.map((option) =>
            !option.disabled && option.text.toLowerCase().indexOf(searchText.toLowerCase()) > -1
              ? option
              : { ...option, hidden: true }
          )
        ]}
        onRenderOption={renderOption}
        onDismiss={() => setSearchText("")}
        styles={styles}
        errorMessage={errorMessage}
        calloutProps={{ calloutMaxHeight: 215 }}
        onRenderLabel={onRenderLabel}
      />
    </div>
  );
};
export { QueryBasedDropdown };
