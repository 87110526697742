import React from "react";
import { BaseRouteProps } from "@jem/components";
import { Route, Routes } from "react-router-dom";
import { Profile } from "./MySettings/pages/Profile";
import { DelegateReviewers } from "./MySettings/pages/DelegateReviewers/DelegateReviewers";

const MySettings: React.FC<BaseRouteProps> = (props) => {
  return (
    <>
      <Routes>
        <Route path="/profile" element={<Profile configuration={props.config}></Profile>}></Route>
        <Route
          path="/delegateReviewers"
          element={<DelegateReviewers configuration={props.config}></DelegateReviewers>}
        ></Route>
      </Routes>
    </>
  );
};

MySettings.displayName = "MySettings";
export default MySettings;
