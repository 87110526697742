import moment from "moment";
import { Dispatch, SetStateAction, useContext, useState } from "react";
import { LoggingContext } from "../contexts";

export enum LocalStorageKeys {
  checklistDashboardFilterState = "jem_checklistDashboardOpsFilterState",
  checklistDashboardCompanyFilterState = "jem_checklistDashboardCompanyFilterState",
  checklistDetailsFilterState = "jem_checklistDetailsFilterState"
}

/**
 * Hook to cache UI data in user browser's local storage
 * @param key A key to use for storing and retrieving cached value(s)
 * @param initialValue The value to store under the indicated key
 * @param reviveDateTime
 * @returns The stored value and setter function for it
 */
export default function useLocalStorage<S>(
  key: string,
  initialValue: S | (() => S),
  reviveDateTime?: boolean
): [S, Dispatch<SetStateAction<S>>] {
  const logger = useContext(LoggingContext);

  const reviveDateTimeFunction = (myKey: string, value: unknown): unknown => {
    if (reviveDateTime && typeof value === "string") {
      const isDate = moment(value, moment.ISO_8601, true).isValid();
      if (isDate) {
        return new Date(value);
      }
    }

    return value;
  };

  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item, reviveDateTimeFunction) : initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  const setValue = (value: S | ((prop: S) => S)): void => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      logger.appInsights?.trackException({ exception: new Error(`Failed to save to ${key} in user's local storage`) });
    }
  };

  return [storedValue, setValue];
}
