import React, { useContext, useEffect, useRef, useState } from "react";
import {
  FatalError,
  JemConfiguration,
  LoadingStatus,
  LocalMessage,
  PageHeading,
  PageStyles,
  UserContext,
  checkUsersPermissions,
  delay,
  useQuery
} from "@jem/components";
import { MessageBar, MessageBarType } from "@fluentui/react";
import {
  IDelegateReviewers,
  getDelegateReviewers,
  saveDelegateReviewers,
  IDelegateResponse
} from "../../components/DelegateReviewers/DelegateReviewers.requests";
import DelegateReviewersActions, {
  DelegateReviewerActions
} from "../../components/DelegateReviewers/DelegateReviewers.Actions";
import DelegateReviewersForm, { DelegateReviewersRef } from "../../components/DelegateReviewers/DelegateReviewersForm";

interface DelegateReviewerProps {
  configuration: JemConfiguration;
}

const emptyDelegateInfo: IDelegateReviewers = {
  action: "",
  delegateId: 0,
  delegatedToAlias: "",
  delegatorAlias: "",
  delegateFrom: "",
  delegateUpto: "",
  isActive: false,
  tempId: null
};
export function getCurrentDelegateToReviewer(
  currentUserAlias: string,
  delegateReviewerInfo: IDelegateReviewers[]
): IDelegateReviewers {
  const info = delegateReviewerInfo.filter(
    (delegateInfo) => delegateInfo.delegatorAlias.toUpperCase() === currentUserAlias.toUpperCase()
  );
  return info.length === 0 ? emptyDelegateInfo : info[0];
}

export function getSavePayload(
  user: string,
  delegateReviewerAlias: string,
  delegateReviewerInfo: IDelegateReviewers[],
  delegateFrom: string,
  delegateUpto: string
): IDelegateReviewers[] {
  const currentDelegateInfo = getCurrentDelegateToReviewer(user, delegateReviewerInfo);
  const payload: IDelegateReviewers = {
    action: "",
    delegateId: 0,
    delegatedToAlias: "",
    delegatorAlias: "",
    delegateFrom: "",
    delegateUpto: "",
    isActive: false,
    tempId: null
  };
  if (currentDelegateInfo.delegateId === 0) {
    payload.action = "SAVE";
    payload.delegateId = 0;
    payload.delegatedToAlias = delegateReviewerAlias;
    payload.delegatorAlias = user;
    payload.delegateFrom = delegateFrom;
    payload.delegateUpto = delegateUpto;
    payload.tempId = 0;
  } else if (currentDelegateInfo.delegateId > 0 && delegateReviewerAlias.length === 0) {
    payload.action = "DELETE";
    payload.delegateId = currentDelegateInfo.delegateId;
    payload.delegatedToAlias = "";
    payload.delegatorAlias = user;
    payload.delegateFrom = "";
    payload.delegateUpto = "";
    payload.tempId = null;
  } else {
    payload.action = "UPDATE";
    payload.delegateId = currentDelegateInfo.delegateId;
    payload.delegatedToAlias = delegateReviewerAlias;
    payload.delegatorAlias = user;
    payload.delegateFrom = delegateFrom;
    payload.delegateUpto = delegateUpto;
    payload.tempId = null;
  }

  const payloadArray: IDelegateReviewers[] = [];
  payloadArray.push(payload);
  return payloadArray;
}

const DelegateReviewers: React.FC<DelegateReviewerProps> = (props) => {
  const userContext = useContext(UserContext);
  if (!userContext) {
    throw new FatalError("Please use a UserContext Provider.");
  }
  const [previousResponse, setPreviousResponse] = useState<IDelegateResponse | null>(null);
  const {
    data: delegateReviewers,
    error: _error,
    refetch,
    isLoading,
    isFetching
  } = useQuery<IDelegateReviewers[]>({
    queryKey: "delegateReviewers",
    staleTime: 0,
    queryFn: async () => {
      await delay(1000);
      return getDelegateReviewers(props.configuration.GeneralLedgerApi, userContext.accessToken);
    },
    enabled: true
  });
  const loadingStatus = isLoading || isFetching ? LoadingStatus.Pending : LoadingStatus.Resolved;

  const delegateReviewersRef = useRef<DelegateReviewersRef>(null);

  const [message, setMessage] = useState<LocalMessage | null>(null);
  const [disabledPage, setDisabledPage] = useState<boolean>(false);

  const delegate = delegateReviewers
    ? getCurrentDelegateToReviewer(userContext.user.alias, delegateReviewers)
    : emptyDelegateInfo;

  const onAction = async (actionName: DelegateReviewerActions) => {
    if (!delegateReviewers) {
      // Handle the case when data is not available yet
      return;
    }
    const saveOrGetErrorMessage = async (): Promise<{
      message: string;
      type: MessageBarType;
    } | null> => {
      if (!delegateReviewersRef.current) {
        return null;
      }
      if (actionName === "Cancel") {
        refetch();
        return null;
      }

      const delegates = delegateReviewersRef.current.getDelegates();

      const currentUserDelegate = delegateReviewers.filter(
        (delegateInfo) => delegateInfo.delegatorAlias.toUpperCase() === userContext.user.alias.toUpperCase()
      );
      if (delegates.delegateTo.length !== 0) {
        if (
          currentUserDelegate &&
          currentUserDelegate.length > 0 &&
          currentUserDelegate.some((delegate) => delegate.delegatorAlias === delegates.delegateTo.toUpperCase())
        ) {
          return {
            message: `${delegates.delegateTo.toUpperCase()} already has you set as their delgated to reviewer.`,
            type: MessageBarType.error
          };
        } else {
          const validatedUsers = await checkUsersPermissions(
            {
              configuration: props.configuration.GeneralLedgerApi,
              accessToken: userContext.accessToken
            },
            undefined,
            delegates.delegateTo,
            undefined,
            undefined
          );
          if (typeof validatedUsers === "string") {
            return {
              message: validatedUsers,
              type: MessageBarType.error
            };
          }
        }
      }

      const payload = getSavePayload(
        userContext.user.alias,
        delegates.delegateTo || "",
        currentUserDelegate || [],
        delegates.startDate,
        delegates.endDate
      );
      const response = await saveDelegateReviewers(
        props.configuration.GeneralLedgerApi,
        userContext.accessToken,
        payload
      );
      refetch();
      setPreviousResponse(response);
      return null;
    };
    setDisabledPage(true);
    setMessage(null);
    const message = await saveOrGetErrorMessage();
    if (message) setMessage(message);
    // disabled state only happens while saving, otherwise page is always enabled
    setDisabledPage(false);
  };

  useEffect(
    function calculateMessageForUI() {
      if (loadingStatus === LoadingStatus.Pending) {
        return;
      }
      if (!previousResponse) {
        setMessage(null);
        return;
      }
      if (delegateReviewers === undefined) {
        return;
      }
      if (!delegateReviewersRef.current) {
        return;
      }

      const delegates = delegateReviewersRef.current.getDelegates();
      const currentDelegate = getCurrentDelegateToReviewer(userContext.user.alias, delegateReviewers).delegatedToAlias;
      const expectedDelegate = delegates.delegateTo.toUpperCase();

      if (currentDelegate === expectedDelegate) {
        setMessage({
          message: "Saved/Updated successfully",
          type: MessageBarType.success
        });
        return;
      } else {
        setMessage(
          Object.assign(
            {},
            {
              message: `Failed to update delegate reviewer to ${delegates.delegateTo}.`,
              type: MessageBarType.error
            }
          )
        );
      }
    },
    [previousResponse, delegateReviewers, loadingStatus]
  );

  return (
    <div className={PageStyles.rootDiv}>
      <PageHeading>
        <h2>Delegate Reviewers</h2>
      </PageHeading>
      <div className="ms-Grid" dir="ltr">
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12">
            <DelegateReviewersActions onAction={onAction} disabledPage={disabledPage}></DelegateReviewersActions>
          </div>
        </div>
        {message !== null ? (
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12">
              <MessageBar
                messageBarType={message.type}
                isMultiline={false}
                onDismiss={() => setMessage(null)}
                dismissButtonAriaLabel="Close"
              >
                {message.message}
              </MessageBar>
            </div>
          </div>
        ) : null}
        <DelegateReviewersForm
          ref={delegateReviewersRef}
          loadingStatus={loadingStatus}
          currentUserAlias={userContext.user.alias.toUpperCase()}
          currentDelegatedToAlias={delegate.delegatedToAlias.toUpperCase()}
          startDate={delegate.delegateFrom}
          endDate={delegate.delegateUpto}
        ></DelegateReviewersForm>
      </div>
    </div>
  );
};

DelegateReviewers.displayName = "DelegateReviewers";
export { DelegateReviewers };
