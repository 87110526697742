import React from "react";
import { useFormik } from "formik";
import { PrimaryButton, Stack, TextField } from "@fluentui/react";

export interface JECreateExistingValues {
  refGuid: string;
}

export interface JECreateExistingFormProps {
  onSubmit: (values: JECreateExistingValues) => void;
}

const initialState: JECreateExistingValues = {
  refGuid: ""
};

const JECreateExistingForm: React.FC<JECreateExistingFormProps> = (props) => {
  const formik = useFormik({
    initialValues: initialState,
    onSubmit: (values) => {
      props.onSubmit(values);
    }
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack tokens={{ childrenGap: 15 }}>
        <TextField label="JE refGuid" {...formik.getFieldProps("refGuid")} />
        <PrimaryButton type="submit" text="Submit" />
      </Stack>
    </form>
  );
};

export { JECreateExistingForm };
