import React, { forwardRef, useImperativeHandle, useState } from "react";
import { ActionButton, Label, useTheme } from "@fluentui/react";
import { css } from "@emotion/css";
import { For } from "react-loops";
import { CSSTransition } from "react-transition-group";

import { DropdownContextualMenu } from "../DropdownContextualMenu/DropdownContextualMenu";
import { DropdownSearchContextualMenu } from "../DropdownContextualMenu/DropdownSearchContextualMenu";
import { FilterPicker } from "./FilterPicker";
import { ObjectKeys } from "../../utilities";

// eslint-disable-next-line
const toCamelCase = require("lodash/camelCase");

function filtersApplied(filters: { [key: string]: string }, filterState: Filters, savedFilters?: Filters) {
  if (savedFilters) {
    filterState = savedFilters;
  }
  const selectedStateFilters = Object.keys(filterState)
    .filter((key) => {
      // contains Selected in the key name
      // and the value is not null
      // and the available filters is not undefined
      const availableName = `${key.split("Selected")[0]}Available`;
      return key.indexOf("Selected") !== -1 && filterState[key] !== null && filterState[availableName] !== undefined;
    })
    .filter((key) => filterState[key as keyof Filters] !== null)
    .map((key) => {
      // find in filters
      const filterName = key.replace("Selected", "");
      const uiName = filters[filterName as keyof Filters];
      return {
        name: filterName,
        filterNameForUI: uiName
      };
    });

  return selectedStateFilters;
}

export interface Filters {
  statusAvailable: string[];
  [key: string]: string[] | null;
}

export interface IDashboardFilterBarProps {
  filters: string[];
  singleSelectionFilters: string[];
  searchEnabledFilters: string[];
  hidden: boolean;
  savedFilters?: Filters;
  onStateChange?: (name: string, newStatus: string | null) => void;
}

export interface IDashboardFilterBarRef {
  updateFilter: (newCriteria: Filters) => void;
  updateTile: (newCurrentTile: string | null) => void;
  getFilterCriteria: () => Filters;
}

const DashboardFilterBar = forwardRef<IDashboardFilterBarRef, IDashboardFilterBarProps>((props, ref) => {
  const theme = useTheme();
  const nodeRef = React.useRef(null);
  const [filterState, setFilterState] = useState<Filters>(() => {
    const newFilters = props.filters.reduce((ctr, filterName) => {
      const camelCaseFilterName = toCamelCase(filterName);
      ctr[`${camelCaseFilterName}Available`] = null;
      ctr[`${camelCaseFilterName}Selected`] = null;
      return ctr;
    }, {} as Filters);
    return newFilters;
  });

  useImperativeHandle(ref, () => ({
    updateFilter(newCriteria: Filters) {
      setFilterState(newCriteria);
    },
    updateTile(newCurrentTile) {
      setFilterState((p) => {
        const newState = {
          ...p,
          statusSelected: newCurrentTile ? [newCurrentTile] : null
        };
        return newState;
      });
    },
    getFilterCriteria() {
      return filterState;
    }
  }));

  const dropdownSelectionChanged = (name: string) => (selection: string[] | null) => {
    setFilterState((p) => {
      return {
        ...p,
        [`${name}Selected`]: selection
      };
    });
    if (props.onStateChange) {
      props.onStateChange(name, selection ? selection[0] : null);
    }
  };

  const filterMapping: { [key: string]: string } = {};
  props.filters.forEach((filterName) => {
    const camelCaseFilterName: string = toCamelCase(filterName);
    filterMapping[camelCaseFilterName] = filterName;
  });

  const appliedFilters = filtersApplied(filterMapping, filterState, props.savedFilters);
  return (
    <div
      className={css`
        display: flex;
        flex-direction: column;
      `}
    >
      <CSSTransition in={!props.hidden} timeout={300} unmountOnExit nodeRef={nodeRef}>
        {() => (
          <React.Fragment>
            <div
              ref={nodeRef}
              className={css`
                display: flex;
                flex-direction: column;

                &.enter {
                  opacity: 0;
                  transform-origin: top;
                  transform: scaleX(1) scaleY(0);

                  &.enter-active {
                    opacity: 1;
                    transform: scaleY(1);
                    transition: opacity 400ms, transform 400ms;
                  }
                }

                &.exit {
                  opacity: 1;

                  &.exit-active {
                    opacity: 0;
                    transform: scaleX(1) scaleY(0.1);
                    transform-origin: top;
                    transition: opacity 400ms, transform 400ms;
                  }
                }
              `}
              data-testid="dashboard-filter-bar"
            >
              <div
                className={css`
                  background-color: ${theme.palette.neutralLighter};
                  margin: 12px 0;
                `}
              >
                <For of={ObjectKeys(filterMapping)}>
                  {(ccFilterName) => {
                    const camelCaseFilterName = ccFilterName as string;
                    const filterName = filterMapping[camelCaseFilterName];
                    const key = `${camelCaseFilterName}Available`;
                    const isSingleSelection = props.singleSelectionFilters.indexOf(filterName) !== -1;
                    const isSearchEnabled = props.searchEnabledFilters.indexOf(filterName) !== -1;
                    const currentFilter = filterState[key];
                    return (
                      <>
                        {currentFilter && currentFilter.length !== 0 ? (
                          isSearchEnabled ? (
                            <DropdownSearchContextualMenu
                              available={filterState[key]}
                              selected={filterState[`${camelCaseFilterName}Selected`]}
                              name={filterName}
                              onSelectionChange={dropdownSelectionChanged(camelCaseFilterName)}
                              useIconButton={false}
                            />
                          ) : (
                            <DropdownContextualMenu
                              available={filterState[key]}
                              selected={filterState[`${camelCaseFilterName}Selected`]}
                              name={filterName}
                              singleSelection={isSingleSelection}
                              onSelectionChange={dropdownSelectionChanged(camelCaseFilterName)}
                              useIconButton={false}
                            />
                          )
                        ) : null}
                      </>
                    );
                  }}
                </For>
              </div>
            </div>
          </React.Fragment>
        )}
      </CSSTransition>
      {appliedFilters.length === 0 ? null : (
        <div
          className={css`
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 4px 0;
          `}
        >
          <Label>Filters applied:</Label>
          <FilterPicker
            availableFilters={appliedFilters}
            onFilterRemoved={function (fName: string): void {
              const filterName = `${fName}Selected`;
              setFilterState((p) => {
                const newState = Object.assign(p);
                newState[filterName] = null;
                return newState;
              });
              if (props.onStateChange) {
                props.onStateChange("status", null);
              }
            }}
          />
          <ActionButton
            onClick={() => {
              setFilterState((p) => {
                const newState = Object.assign(p);
                for (const key of Object.keys(newState)) {
                  if (key.indexOf("Selected") !== -1) {
                    newState[key] = null;
                  }
                }
                return newState;
              });
              if (props.onStateChange) {
                props.onStateChange("status", null);
              }
            }}
          >
            Clear all
          </ActionButton>
        </div>
      )}
    </div>
  );
});

DashboardFilterBar.displayName = "DashboardFilterBar";

export { DashboardFilterBar };
