import {
  BatchQueryStringParameters,
  FatalError,
  GLBatchActionsEnum,
  GLBatchForm,
  GLBatchProps,
  JEMContext,
  LoadingStatus,
  LoggingContext,
  ObjectKeys,
  UserContext
} from "@jem/components";
import FileDownload from "js-file-download";
import React, { useContext, useEffect, useState } from "react";
import { createSearchParams, useLocation, useNavigate, useSearchParams } from "react-router-dom";

const initialQueryStringValues: BatchQueryStringParameters = {
  RefGuid: "",
  Edit: "1",
  Review: "1"
};

function getQueryStringValues(searchParams: URLSearchParams): BatchQueryStringParameters {
  const initialQueryString = { ...initialQueryStringValues };
  for (const keyName of ObjectKeys(initialQueryString)) {
    let value = searchParams.get(keyName);
    value = !value || value.length === 0 ? null : value;
    if (searchParams.has(keyName) && keyName.toLowerCase() === "refguid" && initialQueryString.RefGuid !== value) {
      initialQueryString.RefGuid = value || "";
    }
    if (searchParams.has(keyName) && keyName.toLowerCase() === "edit" && initialQueryString.Edit !== value) {
      initialQueryString.Edit = value || "1";
    }
    if (searchParams.has(keyName) && keyName.toLowerCase() === "review" && initialQueryString.Review !== value) {
      initialQueryString.Review = value || "1";
    }
  }
  return initialQueryString;
}

const GLBatch: React.FC<GLBatchProps> = (props) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { appInsights } = useContext(LoggingContext);
  const location = useLocation();
  // string - a valid refguid
  // null - no refguid in search params (new batch)
  // undefined - still loading either null or string from search params (initial load)
  const [refGuid, setRefGuid] = useState<string | null | undefined>(undefined);
  if (!appInsights) {
    throw new FatalError("Please use a LoggingContext Provider.");
  }
  const userContext = useContext(UserContext);
  if (!userContext) {
    throw new FatalError("Please use a UserContext Provider.");
  }
  const domainContext = useContext(JEMContext);
  if (!domainContext) {
    throw new FatalError("Please use a JEMContext Provider.");
  }

  useEffect(
    function getBatchFromEndpoint() {
      if (
        domainContext.initInfo.status === LoadingStatus.Resolved ||
        domainContext.initInfo.status === LoadingStatus.Rejected
      ) {
        const queryString = getQueryStringValues(searchParams);

        if (queryString.RefGuid.trim() !== "") {
          setRefGuid(queryString.RefGuid);
        } else {
          setRefGuid(null);
        }
      }
    },
    [searchParams, domainContext.initInfo.values]
  );

  return (
    <>
      <GLBatchForm
        refGuid={refGuid}
        configuration={props.configuration}
        attachmentsConfiguration={props.attachmentsConfiguration}
        domainData={domainContext.initInfo.values}
        onNavigate={(refGuid, navigateBack) => {
          if (navigateBack) {
            const from = location.state && (location.state as any).from ? (location.state as any).from : undefined;
            if (from) {
              navigate(from, { replace: true });
            } else {
              navigate("/gl/dashboard", { replace: true });
            }
          } else {
            const initialQueryString = { ...initialQueryStringValues };
            initialQueryString.RefGuid = refGuid;
            const searchParams = ObjectKeys(initialQueryString).reduce((ctr, key) => {
              ctr[`${key}`] = `${initialQueryString[key]}`;
              return ctr;
            }, {} as Record<string, string>);
            navigate(
              {
                search: `?${createSearchParams(searchParams)}`
              },
              { replace: true }
            );
          }
        }}
        onDownloadTemplate={async (templateName) => {
          const fileNameFn = (extension: "xml" | "xlsx") => `Batch_Excel_Template.${extension}`;
          const pathToAssetFn = (extension: "xml" | "xlsx", fileName: (extension: "xml" | "xlsx") => string) =>
            `/static/GeneralLedger/BatchTemplates/${fileName(extension)}`;
          const extension = templateName === GLBatchActionsEnum.DownloadExcel ? "xlsx" : "xml";
          const path = pathToAssetFn(extension, fileNameFn);
          const fileName = fileNameFn(extension);
          const response = await fetch(path, {
            headers: {
              "Content-Type": "text/csv"
            }
          });
          const blob = await response.blob();
          FileDownload(blob, fileName);
        }}
      ></GLBatchForm>
    </>
  );
};

GLBatch.displayName = "GLBatch";

export default GLBatch;
