import { SelectionMode } from "@fluentui/react";
import {
  DashboardGrid,
  exportToExcel,
  IDashboardGridRef,
  IExtendedColumn,
  LoadingSpinner,
  LoadingStatus
} from "@jem/components";
import React, { useContext, useRef } from "react";

import { IHCCPODetailsContext } from "../../../contexts/IHCCPODetailsContext/IHCCPODetailsContext";
import { BulkDetailsItem } from "../../../contexts/IHCCPODetailsContext/IHCCPODetailsContext.types";

interface BulkDetailsColumn extends IExtendedColumn {
  fieldName: keyof BulkDetailsItem;
}

function getColumns() {
  const cols: BulkDetailsColumn[] = [
    {
      key: "parentPONum",
      name: "PO Number",
      fieldName: "poNumber",
      minWidth: 80,
      type: "string"
    },
    {
      key: "coCode",
      name: "Company Code",
      fieldName: "companyCode",
      minWidth: 100,
      type: "string"
    },
    {
      key: "fiscalYear",
      name: "Fiscal Year",
      fieldName: "fiscalYear",
      minWidth: 40,
      type: "string"
    },
    {
      key: "fiscalPeriod",
      name: "Fiscal Period",
      fieldName: "fiscalPeriod",
      minWidth: 50,
      type: "string"
    }
  ];

  return cols;
}

const bulkDetailsConfigurationName = "bulkDetailsConfigurationName";
const OtherDetailsTab: React.FC = () => {
  const poData = useContext(IHCCPODetailsContext);
  const bulkModel = poData.bulkDetails;
  const dashboardGridRef = useRef<IDashboardGridRef>(null);

  return (
    <React.Fragment>
      <div className="ms-Grid" dir="ltr">
        <div className="ms-Grid-row">
          {bulkModel.status !== LoadingStatus.Resolved ? (
            <LoadingSpinner label="Loading Bulk Details" />
          ) : (
            <DashboardGrid
              idForLocalStorage={bulkDetailsConfigurationName}
              customRef={dashboardGridRef}
              columnGenerator={getColumns}
              items={bulkModel.values || []}
              isSortedIndex={1}
              height={`300px`}
              isSortedDescending={true}
              selectionMode={SelectionMode.multiple}
              onExport={(rows) => {
                exportToExcel({
                  sheetName: "PaymentOrderOtherDetails",
                  rowsWithHeader: rows,
                  fileName: "paymentOrderOtherDetails.xlsx"
                });
              }}
            ></DashboardGrid>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

OtherDetailsTab.displayName = "OtherDetailsTab";
export default OtherDetailsTab;
