import { OpsDashboardApi, IUserProviderState, getValidUrl, getRequest, APIAnomalyError } from "@jem/components";

export async function fetchAndParseReviewers(
  configuration: OpsDashboardApi,
  getTokenFn: IUserProviderState["accessToken"]
): Promise<string[]> {
  const endpoint = `${configuration.baseApiUrl}${configuration.endpoints.reviewers}`;
  const endpointUrl = getValidUrl(endpoint);
  const payload = await getRequest<string[]>(endpointUrl, getTokenFn);
  if (!payload || payload.length === 0) {
    throw new APIAnomalyError("Received empty Array for Action Lists");
  }
  return payload;
}
