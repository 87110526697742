import { AuthenticationResult } from "@azure/msal-browser";
import {
  ActionTypes,
  ErrorHelper,
  ActionResult,
  postRequest,
  ErrorMessages,
  ApiError,
  JemConfiguration,
  ActionEndpointMap,
  UserContext,
  getValidUrl
} from "@jem/components";
import { useContext } from "react";

import { SanitizedTreasuryRow } from "../../components/TreasuryDashboard/TreasuryDashboard.types";

const makeCommonTreasuryPayload = (items: SanitizedTreasuryRow[]) => {
  const treasuryPayload = items.map((i) => {
    return {
      refGuid: i.refGuid,
      rowVer: i.rowVer,
      poId: i.poId
    };
  });

  return treasuryPayload;
};
interface TresuryDashboardResponse {
  isSuccess: boolean;
  exceptionMessage: null | string;
  exceptionCode: null | string;
}

async function responseTreasuryHandler(
  actionType: ActionTypes,
  endpoint: string,
  payload: any,
  getTokenFn: () => Promise<AuthenticationResult | undefined>
): Promise<ActionResult> {
  const payloadEntires: string[] = [];
  payload.forEach((x: { refGuid: string }) => {
    payloadEntires.push(x.refGuid);
  });
  try {
    const response = await postRequest<TresuryDashboardResponse>(endpoint, payload, getTokenFn);
    if (!response) {
      return {
        actionType,
        notification: {
          subjectHeader: "IHCC API Error",
          summaryBodyText: "Action failed to perform.",
          type: "Error",
          affectedEntries: [],
          payloadEntries: payloadEntires
        }
      } as ActionResult;
    } else if (!response.isSuccess) {
      const message = ErrorHelper.getMessage(response.exceptionCode as keyof typeof ErrorMessages);
      return {
        actionType,
        notification: {
          subjectHeader: `Treasury Dashboard: ${actionType}`,
          summaryBodyText: message === "No message provided" ? "Action failed to perform." : message,
          type: "Error",
          affectedEntries: [],
          payloadEntries: payloadEntires
        }
      } as ActionResult;
    } else {
      const message =
        actionType === ActionTypes.sendBackFromTreasury
          ? "PO(s) status is changed to awaiting clarification successfully."
          : "PO(s) successfully released to SAP.";
      return {
        actionType,
        notification: {
          subjectHeader: `Treasury Dashboard: ${actionType}`,
          summaryBodyText: message,
          type: "Success",
          affectedEntries: payloadEntires,
          payloadEntries: payloadEntires
        }
      } as ActionResult;
    }
  } catch (e) {
    if (e instanceof ApiError) {
      const message = (e as ApiError).message;
      return {
        actionType,
        notification: {
          subjectHeader: `Treasury Dashboard: ${actionType}`,
          summaryBodyText: message,
          type: "Error"
        }
      } as ActionResult;
    } else {
      const err = e as Error;
      return {
        actionType,
        notification: {
          subjectHeader: "Treasury Dahboard API Reported an Error",
          summaryBodyText: err.message || "No data available",
          type: "Error",
          affectedEntries: [],
          payloadEntries: payloadEntires
        }
      } as ActionResult;
    }
  }
}

export function useTreasuryDashboardActions(
  configuration: JemConfiguration["IhccApi"]
): ActionEndpointMap<SanitizedTreasuryRow, ActionResult> {
  const { accessToken } = useContext(UserContext);

  const makeUrl = (endpoint: string) => {
    const base = configuration.baseApiUrl;
    return getValidUrl(`${base}${endpoint}`);
  };

  return {
    [ActionTypes.saveToSAP]: async (items) => {
      const endpoint = makeUrl(configuration.endpoints.actionReleaseToSAP);
      const payload = Object.assign(makeCommonTreasuryPayload(items));
      return responseTreasuryHandler(ActionTypes.saveToSAP, endpoint, payload, accessToken);
    },
    [ActionTypes.sendBackFromTreasury]: async (items) => {
      const endpoint = makeUrl(configuration.endpoints.actionSendBackFromTreasury);
      const payload = Object.assign(makeCommonTreasuryPayload(items));
      return responseTreasuryHandler(ActionTypes.sendBackFromTreasury, endpoint, payload, accessToken);
    }
  };
}
