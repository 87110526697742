/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from "react";
import { css } from "@emotion/css";
import Downshift, { StateChangeOptions, ControllerStateAndHelpers } from "downshift";
import AutosizeInput from "react-input-autosize";
import { Label, IconButton } from "@fluentui/react";
import { matchSorter } from "match-sorter";

const tagStyle = css`
  background-color: rgb(243, 242, 241);
  border-radius: 2px;
  border: 1px solid rgb(161, 159, 157);
  color: var(--main-font-color, black);
  display: inline-block;
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto",
    "Helvetica Neue", sans-serif;
  font-size: 1em;
  line-height: 1em;
  font-weight: 400;
  margin-top: 0px;
  margin-bottom: 1px;
  margin-left: 5px;
  padding: 1px;

  & .tag-value--button {
    margin-left: 3px;
    color: inherit;
    padding-top: 1px;
    cursor: pointer;
  }
`;

interface Item {
  value: string;
  index: number;
}

interface TagValueProps {
  onRemove: (value: Item) => void;
  tag: Item;
}

const TagValue = (props: TagValueProps) => {
  const [value, setValue] = useState(props.tag.value);

  useEffect(() => {
    setValue(props.tag.value);
  }, [props.tag]);

  const onRemove = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.preventDefault();
    e.stopPropagation();
    if (props.onRemove) {
      props.onRemove({ ...props.tag, value });
    }
  };
  if (!value) {
    return null;
  } else {
    return (
      <div className={tagStyle} onDoubleClick={onRemove}>
        {value}
        <button className="tag-value--button" onClick={onRemove}>
          x
        </button>
      </div>
    );
  }
};

const MultiInternalAutocompleteStyles = (disabled: boolean) => css`
  margin: 0;
  padding: 0;
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto",
    "Helvetica Neue", sans-serif;
  font-size: 1em;
  font-weight: 400;
  min-height: 33px;
  height: 100%;
  box-sizing: border-box;
  border: 1px solid rgb(138, 136, 134);
  border-radius: 2px;
  position: relative;
  color: var(--main-font-color, gray);
  ${disabled ? "pointer-events: none;" : ""}
  ${disabled ? "background: lightgray;" : "background: rgb(255, 255, 255) none repeat scroll 0% 0%;"}

  & .autocomplete-tags {
    width: calc(100% - 32px);
    line-height: 30px;
    outline: none;
    ${disabled ? "display: none;" : ""}
  }

  & .autocomplete-input {
    border: none;
    outline: none;
    cursor: inherit;
    backgroundcolor: transparent;
    margin-left: 4px;
  }
`;

interface MultiInternalAutocompleteProps {
  label: string;
  required: boolean;
  selectedItems: any;
  disabled?: boolean;
  onChangedState?: (options: StateChangeOptions<any>, stateAndHelpers: ControllerStateAndHelpers<any>) => void;
  onChange: (selectedItem: string | null, stateAndHelpers?: ControllerStateAndHelpers<string>) => void;
  onRemoveItem: (theItem: Item) => void;
  items: string[];
  itemToString?: (item: any) => string;
}

const MultiInternalAutocomplete = (props: MultiInternalAutocompleteProps) => {
  const [inputValue, setInputValue] = useState("");
  const _inputRef = useRef<AutosizeInput | null>(null);
  const _inputWrapperRef = useRef<HTMLDivElement | null>(null);

  const handleStateChange = (
    changes: StateChangeOptions<string>,
    downshiftStateAndHelpers: ControllerStateAndHelpers<string>
  ) => {
    if (!downshiftStateAndHelpers.isOpen) {
      setInputValue("");
    }

    if (props.onChangedState) {
      props.onChangedState(changes, downshiftStateAndHelpers);
    }
  };

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const onRemoveTag = (item: Item) => {
    props.onRemoveItem(item);
  };

  const onInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const currentValue = (event.target as HTMLInputElement).value;
    switch (event.keyCode) {
      case 8: // backspace
        if (!currentValue) {
          event.preventDefault();
          popValue();
        }
        return;
      case 9: // tab
        if (inputValue) {
          props.onChange(inputValue);
          setInputValue("");
          event.preventDefault();
          event.stopPropagation();
        }
        return;
      case 46: // backspace
        if (!inputValue) {
          event.preventDefault();
          popValue();
        }
        return;
      case 188: // comma
        if (!inputValue) {
          event.preventDefault();
        } else {
          props.onChange(inputValue);
          setInputValue("");
        }
        break;
      case 13: // enter
        if (!inputValue) {
          event.preventDefault();
        }
        break;
      default:
        return;
    }
    event.preventDefault();
  };

  const popValue = () => {
    if (props.onRemoveItem) {
      props.onRemoveItem({
        value: props.selectedItems[props.selectedItems.length - 1],
        index: props.selectedItems.length - 1
      });
    }
  };

  const onWrapperClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (_inputWrapperRef.current || _inputRef.current) {
      if (_inputWrapperRef.current === e.target || _inputRef.current === (e.target as any)) {
        focusOnInput();
        e.stopPropagation();
        e.preventDefault();
      }
    }
  };

  // eslint-disable-next-line
  const focusOnInput = () => {
    if (_inputRef.current !== null) {
      if ("focus" in _inputRef.current) {
        (_inputRef.current as any).focus();
      }
      if (typeof _inputRef.current.getInput === "function") {
        _inputRef.current.getInput().focus();
      }
    }
  };

  const setInputRef = (instance: AutosizeInput | null) => {
    _inputRef.current = instance;
  };

  return (
    <Downshift
      onStateChange={handleStateChange}
      itemToString={props.itemToString}
      selectedItem={props.selectedItems}
      onChange={props.onChange}
    >
      {({
        getLabelProps,
        getInputProps,
        getMenuProps,
        getToggleButtonProps,
        getItemProps,
        isOpen,
        selectedItem,
        highlightedIndex,
        getRootProps
      }) => {
        const tagItems = Array.isArray(selectedItem)
          ? selectedItem.map((item, index) => {
              const v = { value: item, index };
              return v;
            })
          : [];

        return (
          <div
            className={css`
              margin: auto;
              position: relative;
            `}
          >
            <Label {...getLabelProps()} required={props.required}>
              {props.label}
            </Label>
            <div
              className={MultiInternalAutocompleteStyles(!!props.disabled)}
              {...getRootProps(undefined, { suppressRefError: true })}
            >
              <div className="autocomplete-tags" ref={_inputWrapperRef} onClick={onWrapperClick} tabIndex={-1}>
                {tagItems.map((tag) => (
                  <TagValue key={`Tag-${tag.index}`} onRemove={onRemoveTag} tag={tag} />
                ))}
                <AutosizeInput
                  style={{ border: "none", outline: "none" }}
                  inputClassName="autocomplete-input"
                  ref={setInputRef}
                  {...(getInputProps({
                    value: inputValue,
                    onChange: onInputChange,
                    onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => {
                      onInputKeyDown(event);
                    }
                  }) as any)}
                />
              </div>
              <IconButton
                className={css`
                  color: currentColor;
                  background-color: transparent;
                  border: none;
                  position: absolute;
                  right: 0;
                  top: 0;
                  cursor: pointer;
                  display: flex;
                  flex-direction: column;
                  height: 100%;
                  justify-content: center;
                  align-items: center;
                `}
                {...getToggleButtonProps()}
                iconProps={{ iconName: isOpen ? "ChevronUp" : "ChevronDown" }}
                title={isOpen ? "Expand" : "Collapse"}
              />
            </div>
            {!isOpen ? null : (
              <ul
                {...getMenuProps({
                  style: {
                    maxHeight: 300,
                    overflowY: "scroll"
                  }
                })}
                className={css`
                  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont,
                    Roboto, "Helvetica Neue", sans-serif;
                  -webkit-font-smoothing: antialiased;
                  font-size: 14px;
                  font-weight: 400;
                  position: absolute;
                  top: 100%;
                  left: 0;
                  right: 0;
                  box-sizing: border-box;
                  box-shadow: rgba(0, 0, 0, 0.133) 0px 6.4px 14.4px 0px, rgba(0, 0, 0, 0.11) 0px 1.2px 3.6px 0px;
                  border-radius: 2px;
                  margin: 0;
                  padding: 0;
                  list-style-type: none;
                  background-color: rgb(255, 255, 255);
                  z-index: 10;
                `}
              >
                {props.items.map((item, index) => (
                  <li
                    className={css`
                      display: flex;
                      align-items: center;
                      position: relative;
                      font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system,
                        BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
                      -webkit-font-smoothing: antialiased;
                      font-size: 1em;
                      font-weight: 400;
                      box-sizing: border-box;
                      cursor: pointer;
                      padding: 0 4;
                      height: 40px;
                      color: var(--main-font-color, gray);
                      background-color: transparent;
                      user-select: none;
                      outline: transparent;
                      border: 1px solid transparent;
                      border-image: initial;
                      text-decoration: none;
                      border-radius: 2px;
                      padding-left: 8px;
                    `}
                    key={`item-${index}`}
                    {...getItemProps({
                      item,
                      index,
                      style: {
                        backgroundColor: index === highlightedIndex ? "var(--secondary-bg-color,lightgray)" : "",
                        fontWeight: selectedItem === item ? "bold" : "normal"
                      }
                    })}
                  >
                    {item}
                  </li>
                ))}
              </ul>
            )}
          </div>
        );
      }}
    </Downshift>
  );
};

interface MultiAutocompleteProps {
  label: string;
  required: boolean;
  disabled?: boolean;
  items: string[];
  itemToString?: (item: any) => string;
  transformSelection?: (item: any) => string;
}

export interface MultiAutocompleteRef {
  getSelectedItems: () => string[];
  setInitialSelectedItems: (theInitialSelected: string[]) => void;
  reset: () => void;
}

const MultiAutocomplete = forwardRef((props: MultiAutocompleteProps, ref: React.Ref<MultiAutocompleteRef>) => {
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [items, setItems] = useState<any[]>(props.items);

  useImperativeHandle(ref, () => ({
    getSelectedItems() {
      return selectedItems;
    },
    setInitialSelectedItems(theInitialSelected: string[]) {
      setSelectedItems(theInitialSelected);
    },
    reset() {
      setSelectedItems([]);
    }
  }));

  const handleStateChange = (changes: StateChangeOptions<any>) => {
    // eslint-disable-next-line no-prototype-builtins
    if (changes.hasOwnProperty("inputValue")) {
      setItems(getItems(changes.inputValue));
    } else if (changes.isOpen) {
      setItems(getItems());
    }
    // handle stuff here if you need to
    // this is especially useful if you need
    // to controll some of the internal state yourself
  };

  const getItems = (value?: string | null) => {
    return value && value !== ""
      ? matchSorter(props.items, value, { threshold: matchSorter.rankings.STARTS_WITH })
      : props.items;
  };

  useEffect(() => {
    setItems(props.items);
  }, [props.items, selectedItems]);

  const onItemAdd = (selectedItem: string | null) => {
    if (selectedItem) {
      if (props.items.indexOf(selectedItem) !== -1) {
        if (props.transformSelection) {
          const transformed = props.transformSelection(selectedItem);
          setSelectedItems([...selectedItems, transformed]);
        } else {
          setSelectedItems([...selectedItems, selectedItem]);
        }
      }
    }
  };

  const onRemoveItem = (item: Item) => {
    const copy = [...selectedItems];
    copy.splice(item.index, 1);
    setSelectedItems(copy);
  };

  return (
    <MultiInternalAutocomplete
      label={props.label}
      required={props.required}
      disabled={props.disabled}
      selectedItems={selectedItems}
      onChangedState={handleStateChange}
      onChange={onItemAdd}
      onRemoveItem={onRemoveItem}
      items={items}
      itemToString={props.itemToString}
    />
  );
});

MultiAutocomplete.displayName = "MultiAutocomplete";

export { MultiAutocomplete };
