import { css } from "@emotion/css";
import { ITheme } from "@fluentui/react";

export interface FlexGridStylesOptions {
  className: string;
  height: number;
}

export const FlexGridStyles = (opts: FlexGridStylesOptions) => {
  const height = opts.height || 500;
  return `${opts.className} ${css`
    --grid-li-light-color: var(--grid-light-color, white);
    --grid-li-dark-color: var(--grid-dark-color, lightblue);
    --grid-li-header-color: var(--grid-header-color, darkblue);
    --grid-li-disabled-dark-color: var(--grid-dark-color, lightgray);
    --grid-li-disabled-light-color: var(--grid-light-color, white);
    --grid-li-text-color: var(--grid-text-color, black);
    --grid-li-disabled-text-color: var(--grid-text-color, darkgray);

    --grid-li-font-family: var(--grid-font-family, sans-serif);
    --grid-li-font-size: var(--grid-font-size, 16px);
    --grid-li-font-weight: var(--grid-font-weight, 400);

    .wj-flexgrid {
      max-height: ${height}px;
    }

    .wj-header {
      font-family: var(--grid-font-family);
      font-size: var(--grid-font-size);
      font-weight: var(--grid-font-weight);
    }

    .wj-control.wj-flexgrid.wj-state-readonly {
      pointer-events: none;
    }

    // Disabled state
    .wj-control.wj-flexgrid.wj-state-readonly .wj-cells .wj-cell:not(.wj-alt) {
      background-color: var(--grid-li-disabled-light-color);
      color: var(--grid-li-disabled-text-color);
    }
    .wj-control.wj-flexgrid.wj-state-readonly .wj-cells .wj-cell {
      background-color: var(--grid-li-disabled-dark-color);
      color: var(--grid-li-disabled-text-color);
    }
    .wj-control.wj-flexgrid.wj-state-readonly .wj-cell.wj-header {
      background-color: var(--grid-li-disabled-dark-color);
      color: var(--grid-li-disabled-text-color);
    }
    // Loading state
    & .loading {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.5);
      z-index: 100;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .wj-cell.wj-state-invalid {
      background-color: var(--grid-error-color) !important;
    }
  `}`;
};

export function FlexGridParentProps(
  theme: ITheme,
  opts: FlexGridStylesOptions
): Pick<JSX.IntrinsicElements["div"], "className" | "style"> {
  const flexGridStyles = FlexGridStyles(opts);
  return {
    className: flexGridStyles,
    style: {
      "--grid-light-color": theme.palette.themeLighter,
      "--grid-dark-color": theme.palette.themeTertiary,
      "--grid-header-color": theme.palette.themePrimary,
      "--grid-disabled-dark-color": theme.palette.neutralLighter,
      "--grid-disabled-light-color": theme.palette.neutralLighterAlt,
      "--grid-disabled-text-color": "transparent",
      "--grid-text-color": theme.palette.neutralPrimary,
      "--grid-font-family": theme.fonts.medium.fontFamily,
      "--grid-font-size": theme.fonts.medium.fontSize,
      "--grid-font-weight": theme.fonts.medium.fontWeight,
      "--grid-error-color": "#ff4c42"
    }
  };
}
