import { DefaultButton, Stack } from "@fluentui/react";
import {
  CompanyCodeMultiSelector,
  CompanyCodeMultiSelectorRef,
  DomainDataEnum,
  DomainDataObjects
} from "@jem/components";
import React, { useEffect, useRef } from "react";
import FiscalYearFiscalPeriodDropdown, {
  FiscalYearFiscalPeriodDropdownRef
} from "../../../Shared/components/FiscalYearFiscalPeriodDropdown/FiscalYearFiscalPeriodDropdown";

export interface JEMChecklistsCompanyCodeSelectorProps {
  domainData: Pick<DomainDataObjects, DomainDataEnum.FiscalPeriodsBPO | DomainDataEnum.JeCompanyCodes> | null;
  onUpdateClicked: (
    fyfp: ReturnType<FiscalYearFiscalPeriodDropdownRef["getFiscalPeriod"]>,
    companyCodes: string[]
  ) => void;
  currentSettings:
    | {
        fiscalYear: string;
        fiscalMonth: string;
        companyCodes: string[];
      }
    | null
    | undefined;
}

const JEMChecklistsCompanyCodeSelector: React.FC<JEMChecklistsCompanyCodeSelectorProps> = (props) => {
  const fyfpRef = useRef<FiscalYearFiscalPeriodDropdownRef>(null);
  const companyCodeSelector = useRef<CompanyCodeMultiSelectorRef>(null);

  useEffect(() => {
    if (props.currentSettings === null && fyfpRef.current) {
      props.onUpdateClicked(fyfpRef.current.getFiscalPeriod(), []);
    }
  }, [props.currentSettings]);

  return (
    <Stack styles={{ root: { margin: "10px 0 20px 0 " } }} horizontal tokens={{ childrenGap: 20 }}>
      <Stack.Item align="end">
        <FiscalYearFiscalPeriodDropdown
          domainData={props.domainData}
          customRef={fyfpRef}
          value={
            props.currentSettings
              ? {
                  fiscalYear: parseInt(props.currentSettings.fiscalYear, 10),
                  fiscalMonth: parseInt(props.currentSettings.fiscalMonth, 10)
                }
              : undefined
          }
        ></FiscalYearFiscalPeriodDropdown>
      </Stack.Item>
      <Stack.Item align="end">
        <CompanyCodeMultiSelector
          customRef={companyCodeSelector}
          selectedCompanies={props.currentSettings ? props.currentSettings.companyCodes.map(Number) : []}
        ></CompanyCodeMultiSelector>
      </Stack.Item>
      <Stack.Item align="end">
        <DefaultButton
          text="Update"
          onClick={() => {
            if (!fyfpRef.current) {
              return;
            }
            const userSelectedCompanyCodes = companyCodeSelector.current
              ? companyCodeSelector.current.getCompanyCodes()
              : [];
            props.onUpdateClicked(fyfpRef.current.getFiscalPeriod(), userSelectedCompanyCodes.map(String));
          }}
        />
      </Stack.Item>
    </Stack>
  );
};

JEMChecklistsCompanyCodeSelector.displayName = "JEMChecklistsCompanyCodeSelector";

export default JEMChecklistsCompanyCodeSelector;
